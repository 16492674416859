import { PayloadAction } from '@reduxjs/toolkit';
import { CurrentEvent, SortTable } from 'Types/index';
import { IActivity } from 'Interfaces/Activity/index';
import {createSlice} from '@reduxjs/toolkit'
import { EDIT_MACROS, EDIT_NOTES, EDIT_SMS, VIEW_CHECK_INS, VIEW_FEEDBACK, VIEW_IMAGES, VIEW_IMAGE_DETAIL, VIEW_MACRO_CALCULATOR, VIEW_NOTES, VIEW_NUTRITION, VIEW_PROFILE, VIEW_QUESTIONNAIRE, VIEW_SMS } from 'vars';
import React from 'react';


interface InitialState {
    activityType: CurrentEvent | null,
    tableSettings: ITableSettings,
    currentEvent: CurrentEvent | null,
    lastUpdate: number,
    totalCount: number,
    activityInfoData: IActivity[],
    activityStats: IActivityStats,
    activityData: IActivityData | null,
};

export interface IActivityData {
    clientId?: string,
    instant?: boolean,
}

const initialState = {
    activityType: null,
    activityData: {},
    totalCount: 0,
    activityInfoData: [],
    currentEvent: null,
    activityStats: {},
    tableSettings: {
        pageSize: 50,
        page: 1,
        s_field: null,
        s_direction: null,
        f_actorId: null,
        f_clientId: null,
        f_action: null,
        f_startDate: null,
        f_endDate: null,
    },
    lastUpdate: Date.now()
} as InitialState;

interface IActivityStats_Info {
    duration: string,
    count: number,
}

export type IActivityStats = {
    [VIEW_PROFILE]?: IActivityStats_Info;
    [VIEW_CHECK_INS]?: IActivityStats_Info;
    [VIEW_FEEDBACK]?: IActivityStats_Info;
    [VIEW_IMAGES]?: IActivityStats_Info;
    [VIEW_IMAGE_DETAIL]?: IActivityStats_Info;
    [VIEW_NOTES]?: IActivityStats_Info;
    [EDIT_NOTES]?: IActivityStats_Info;
    [VIEW_SMS]?: IActivityStats_Info;
    [VIEW_NUTRITION]?: IActivityStats_Info;
    [EDIT_SMS]?: IActivityStats_Info;
    [VIEW_MACRO_CALCULATOR]?: IActivityStats_Info;
    [EDIT_MACROS]?: IActivityStats_Info;
    [VIEW_QUESTIONNAIRE]?: IActivityStats_Info;
};


interface ISetActivity {
    activityType: CurrentEvent | null,
    activityData: IActivityData | null
};

interface ISetActivityInfoData {
    activities: IActivity[],
    stats: IActivityStats,
    totalCount: number,
};

export type ITableSettings = {
    pageSize?: number,
    page?: number,
    s_field?: string | React.Key | null,
    s_direction?: SortTable | null,
    f_actorId?: string | string[] | null;
    f_clientId?: string | string[] | null;
    f_action?: string | string[] | null;
    f_startDate?:  string | null,
    f_endDate?: string | null;
};

const activityReducer = createSlice({
    name: 'activityReducer',
    initialState,
    reducers: {
        setActivity: (state, action: PayloadAction<ISetActivity>) => {
            state.activityType = action.payload.activityType
            state.activityData = action.payload.activityData
        },
        setActivityInfoData: (state, action: PayloadAction<ISetActivityInfoData>) => {
            state.activityInfoData = action.payload.activities
            state.activityStats = action.payload.stats
            state.totalCount = action.payload.totalCount
        },
        setTableSettings: (state, action: PayloadAction<ITableSettings>) => {
            state.tableSettings = {...state.tableSettings, ...action.payload}
        },
        setLastUpdate: (state, action: PayloadAction<number>) => {
            state.lastUpdate = action.payload
        },
        activityReducerReset: state => initialState
    }
})

export const {setActivity, setActivityInfoData, setTableSettings,setLastUpdate, activityReducerReset} = activityReducer.actions
export default activityReducer.reducer


