import React, {useEffect, useState} from "react";
import { useAppSelector } from "redux/store";
import {Select} from "antd";
import withPermission from "HOCs/withPermission";
import {getSubstringText} from "utils/helpers";
import { Canceler } from "axios";

interface Props {
    isLoading: boolean,
    extraOptions: {label: string, value: string}[],
    candidatesApiCancel: Canceler,
    onChange: (value: string) => void,
    getActiveCoaches: () => Promise<void>
};

function DashboardPageHeaderSelect(props: Props) {
    const {
        isLoading,
        onChange,
        candidatesApiCancel,
        getActiveCoaches,
        extraOptions = [{label: '', value: ''}]
    } = props

    const [loading, setLoading] = useState<boolean>(true)
    const candidatesData = useAppSelector(state => state.candidates.candidatesData)
    const selectedCoachId = useAppSelector(state => state.dashboard.selectedCoachId)

    const options: {label: string, value: string}[] = [...extraOptions, ...candidatesData.map((coach) => ({
        label: getSubstringText(coach.fullName),
        value: coach.id
    }))]

    useEffect(() => {
        let cleanupFunction = false
        const fetchData = async () => {
            setLoading(true)
            await getActiveCoaches()
            if(!cleanupFunction) {
                setLoading(false)
            }
        }

        fetchData();

        return () => {
            cleanupFunction = true
            candidatesApiCancel()
        }

    }, [])
    return (
        !loading ? <Select placeholder="" value={selectedCoachId || undefined} className={'dashboard_header_select'}
                                    onChange={onChange} options={options}
                                    disabled={isLoading}/> : null
    )
}

export default withPermission(DashboardPageHeaderSelect)