import React, {useState} from 'react';
import ReactDiffViewer from "react-diff-viewer";
import {Button, Popover, Switch, Tag} from "antd";
import {DeleteOutlined, EditOutlined, LeftOutlined} from "@ant-design/icons";
import moment from "moment";
import {admin} from "vars";
import { IUser } from 'Interfaces/User';
import { IMessageTemplate } from 'Interfaces/MessageTemplates';
import { IFeedback } from 'Interfaces/Feedback';
import { ActivationsOptions } from 'Interfaces/UserClassification';
import { IEditFeedback, IPatchFeedback } from 'services/feedback-service';


interface Props {
    authUser: IUser | null,
    messageTemplatesData: IMessageTemplate[],
    setEditFeedback: (note: IFeedback) => void,
    setCurrentFeedbackDelete: (note: IFeedback) => void,
    onPatchFeedback: (note: IPatchFeedback) => Promise<IFeedback | undefined>
}


const useFeedbackActions = (props: Props) => {
    const {
        authUser,
        messageTemplatesData,
        setEditFeedback,
        setCurrentFeedbackDelete,
        onPatchFeedback
    } = props;
    const [isMoreTemplates, setIsMoreTemplates] = useState(false);


    const feedbackActionsTemplate = (note: IFeedback) => {
        if(note?.selectedTemplates.length > 0){
            const filteredTemplates = messageTemplatesData.filter(
                (template) => note.selectedTemplates.find(({messageTemplateId}) => messageTemplateId === template.id));

            if(authUser?.roles === admin){
                const templatesText = note.selectedTemplates.map(({text}) => text).join('\n');
                const content = <ReactDiffViewer oldValue={templatesText} newValue={note.notes} />
                if(filteredTemplates.length > 3){
                    return (
                        <>
                            {
                                !isMoreTemplates ? (
                                    <>
                                        <Popover placement="left" content={content} title="Changes">
                                            {
                                                filteredTemplates.slice(0,3).map((template) => (
                                                    <Tag color="geekblue" key={template.name}>{template.name}</Tag>
                                                ))
                                            }
                                        </Popover>
                                        <span onClick={() => setIsMoreTemplates(true)}>...</span>
                                    </>
                                ) : (
                                    <>
                                        <Popover placement="left"  content={content} title="Changes">
                                            {
                                                filteredTemplates.map((template) => (
                                                    <Tag color="geekblue" key={template.name}>{template.name}</Tag>
                                                ))
                                            }
                                        </Popover>
                                        <LeftOutlined onClick={() => setIsMoreTemplates(false)} />
                                    </>
                                )
                            }
                        </>
                    )
                }
                return (
                    <Popover placement="left" content={content} title="Changes">
                        {
                            filteredTemplates.map((template) => {
                                    return (
                                        <Tag color="geekblue" key={template.name}>{template.name}</Tag>                                    )
                                }
                            )
                        }
                    </Popover>
                )
            }
                if(filteredTemplates.length > 3){
                    return (
                        <>
                            {
                                !isMoreTemplates ? (
                                    <>
                                        {
                                            filteredTemplates.slice(0,3).map((template) => (
                                                <Tag color="geekblue" key={template.name}>{template.name}</Tag>                                            ))
                                        }
                                        <span onClick={() => setIsMoreTemplates(true)}>...</span>
                                    </>
                                ) : (
                                    <>
                                        {
                                            filteredTemplates.map((template) => (
                                                <Tag color="geekblue" key={template.name}>{template.name}</Tag>                                            ))
                                        }
                                        <LeftOutlined onClick={() => setIsMoreTemplates(false)} />
                                    </>
                                )
                            }
                        </>
                    )
                }
                return (
                    <>
                        {
                            filteredTemplates.map((template) => {
                                    return (
                                        <Tag color="geekblue" key={template.name}>{template.name}</Tag>                                    )
                                }
                            )
                        }
                    </>
                )
        }
    };
    const activationsOptions = [
        {value: 'activation_high', label: 'High'},
        {value: 'activation_steady', label: 'Steady'},
        {value: 'activation_low', label: 'Low'},
        {value: 'activation_ensure', label: 'Ensure'}
    ]

    const feedbackActionsActivation = (note: IFeedback) => {
        const {userStateClassification} = note;
        const [userClassification] = userStateClassification;
        const activation = !!userClassification && activationsOptions.filter((item) => userClassification[item.value as ActivationsOptions]).map(({label}) => label)[0];

        return activation && (
            <>
                <Tag color="purple">Activation:</Tag>
                <Tag color="purple">{activation}</Tag>
            </>
        )
    };

    const feedbackActionsCheckIn = (note: IFeedback) => {
        const {CheckIn} = note

        if(CheckIn){
            return (
                <>
                    <Tag>Training: {+CheckIn.trainingScore}</Tag>
                    <Tag>Food: {+CheckIn.foodScore}</Tag>
                    <Tag>Water: {+CheckIn.waterScore}</Tag>
                    <Tag>Stress: {+CheckIn.stressScore}</Tag>
                    <Tag>Sleep: {+CheckIn.sleepScore}</Tag>
                    {CheckIn.sick &&
                        <Tag color="green">Sick</Tag>
                    }
                    {CheckIn.menstruating &&
                        <Tag color="green">Menstruating</Tag>
                    }
                </>
            )
        }
    }

    const feedbackActionsButtons = (note: IFeedback) => {
        const now = moment(new Date());
        const messageDate = moment(note.date);
        const duration = moment.duration(now.diff(messageDate));
        const minutes = duration.asMinutes();
        return (
            <div className="feedback_actions_buttons">
                { !note.autoGenerated && !!note.feedbackFromId && !!note.checkInId &&            
                    <Button
                        onClick={async () => await onPatchFeedback({ isMessage: !note.isMessage, id: note.id})}
                        type={note.isMessage ? 'primary' : 'ghost'}
                    >
                        {note.isMessage ? <>Mark as <b>feedback</b></> : <>Mark as <b>message</b></>}
                    </Button>
                }
                {(authUser?.roles === admin || (minutes < 15 && note.feedbackFromId === authUser?.id)) &&
                    (<div>
                        { minutes < 15 && note.feedbackFromId === authUser?.id &&
                            <Button
                                onClick={() => setEditFeedback(note)}
                                data-testid="edit-feedback"
                                shape="circle"
                                type="primary"
                                icon={<EditOutlined />}
                            />
                        }
                        <Button
                            data-testid="delete-feedback"
                            shape="circle"
                            type="primary"
                            danger
                            onClick={() => setCurrentFeedbackDelete({...note, id: note.id, date: note.date})}
                            icon={<DeleteOutlined />}
                        />
                    </div>)
                }
            </div>
        )
    }

    return {
        feedbackActionsButtons,
        feedbackActionsCheckIn,
        feedbackActionsActivation,
        feedbackActionsTemplate
    }
};


export {useFeedbackActions};