import React, { useCallback, useState } from 'react';
import { Space, Switch, Table, Tag } from 'antd';
import { ColumnProps, ColumnsType } from 'antd/es/table';
import { ITask, StatusTask } from 'Interfaces/TasksCategories';
import useTasksCategories from 'services/tasksCategories-service';
import { getFormatDate, getLocaleCompareSort, getPopoverText, sortByDate } from 'utils/helpers';
import { DATE_FORMAT } from 'vars';
import moment from 'moment';
import NoData from 'components/no-data/NoData';

const getDueInTwoWeeksDate = (date: string | null) => {
    if(!date){
        return false;
    }

    return moment(date).isBefore(moment().add({ week: 2 }).toISOString())
}

const getDueInTwoWeeksTasks = (tasks: ITask[]) => tasks.filter((task) => getDueInTwoWeeksDate(task.dueDate));

const DashboardTasks = () => {
    const { categories, tasks } = useTasksCategories();
    const [isAllTasks, setIsAllTasks] = useState<boolean>(false); 

    const filtersCategoriesOptions: ColumnProps<ITask>['filters'] = categories.map((category) => ({
        text: <Tag color={category.color}>{getPopoverText(category.name, 30)}</Tag>,
        value: category.id,
        title: category.name,
    }))

    const renderDateCellWithClassOnDateBefore = useCallback((dateString: string, conditionalClassName: string) => {
        const date = moment(dateString);
        if(!date.isValid()) return "";
        return {
            props: { className: `${date.isSameOrBefore(moment(), 'day') ? conditionalClassName : ''} center-text` },
            children: moment(date).format(DATE_FORMAT)
        };
    }, []);

    const renderStatus: ColumnProps<ITask>['render'] = (status: StatusTask) => {
        if(status === StatusTask.opened){
            return <Tag color="blue">{status.toUpperCase()}</Tag>
        };
        if(status === StatusTask.completed){
            return <Tag color="green">{status.toUpperCase()}</Tag>
        };
        if(status === StatusTask.archived){
            return <Tag color="orange">{status.toUpperCase()}</Tag>
        }
    } 

    const filtersStatusOptions: ColumnProps<ITask>['filters'] = [
        {
            value: StatusTask.opened,
            text: <Tag color="blue">{StatusTask.opened.toUpperCase()}</Tag>
        },
        {
            value: StatusTask.completed,
            text: <Tag color="green">{StatusTask.completed.toUpperCase()}</Tag>
        },
        {
            value: StatusTask.archived,
            text: <Tag color="orange">{StatusTask.archived.toUpperCase()}</Tag>
        },
    ]

    const columns: ColumnsType<ITask> = [
    {
        title: "Name",
        dataIndex: "name",
        sorter: (a,b) => getLocaleCompareSort(a.name,b.name)
    },
    // {
    //     title: "Description",
    //     dataIndex: "description",
    //     sorter: (a,b) => getLocaleCompareSort(a.description, b.description)
    // },
    {
        title: "Category",
        filters: filtersCategoriesOptions,
        render: (_, {taskCategory}) => <Tag color={taskCategory.color} className="task-table-category" title={taskCategory.description}>{getPopoverText(taskCategory.name, 50)}</Tag>,
        onFilter: (value, record) => record.taskCategoryId.indexOf(value as string) === 0,
        sorter: (a,b) => getLocaleCompareSort(a.taskCategory.name, b.taskCategory.name),
        width:150,
        align: "center"
    },
    // {
    //     title: "Created Date",
    //     dataIndex: "createdAt",
    //     render: getFormatDate,
    //     sorter: (a,b) => getLocaleCompareSort(a.createdAt, b.createdAt)
    // },
    // {
    //     title: "Reminder Date",
    //     dataIndex: "reminderDate",
    //     render: (date) => renderDateCellWithClassOnDateBefore(date, 'yellow-table-cell'),
    //     sorter: (a,b) => sortByDate(a.reminderDate, b.reminderDate)
    // },
    {
        title: "Due Date",
        dataIndex: "dueDate",
        render: (date) => renderDateCellWithClassOnDateBefore(date, 'red-table-cell'),
        sorter: (a,b) => sortByDate(a.dueDate, b.dueDate),
        width:120,
        align: "center"
    },
    {
        title: "Status",
        render: renderStatus,
        dataIndex: "status",
        filters: filtersStatusOptions,
        onFilter: (value, record) => record.status.indexOf(value as string) === 0,
        sorter: (a,b) => getLocaleCompareSort(a.status, b.status),
        width:100,
        align: "center"
    }
];

    return (
        tasks.length > 0 ?
       ( <Space className={'dashboard_chart_wrapper dashboard_chart_bar_wrapper'}>
                <div className="dashboard_task_header">
                    <div className="dashboard_header_title">
                        <h3>Clients tasks</h3>
                    </div>
                    <div>
                        <Switch checked={isAllTasks} onChange={setIsAllTasks} unCheckedChildren="All" checkedChildren="All" />
                    </div>
                </div>
            <Table
                size="small"
                scroll={{
                    x: 'auto'
                }}
                pagination={{
                    pageSize: 6
                }}
                className="dashboard_coach_table"
                columns={columns}
                dataSource={isAllTasks ? tasks : getDueInTwoWeeksTasks(tasks)}
        />
        </Space> ) : (
            <NoData description="Clients tasks (No Data)" />
        )
    )


};

export default DashboardTasks;