import React, {useEffect, useState} from "react";
import { useAppDispatch, useAppSelector } from 'redux/store';
import { 
    ActivationsOptions, 
    IUserStateClassification, 
    IUserStateClassificationTableFilters, 
    PartsOptions 
} from 'Interfaces/UserClassification';
import usePersistedState from "hooks/usePersistedState";
import useApi from "hooks/useApi";
import { IUser } from 'Interfaces/User';
import {
    changeSortDirectionTitle,
    getFormatDateWithHours, 
    getPopoverText, 
    getPopoverTextWithIcon, 
    getSubstringText,
    isFiltersChange,
    Logger
} from "utils/helpers";
import {
    setClassifications,
    setCoaches,
    setSelectedRowKeys
} from "redux/reducers/classificationsReducer";
import {
    activationsOptions,
    DATE_FORMAT,
    partsOptions,
} from "vars";
import {Tag} from "antd";
import ActivationPartsContent from "pages/client/ActivationPartsContent";
import { TableProps } from "antd/es/table";
import { SorterResult } from "antd/es/table/interface";
import { RangePickerProps } from "antd/lib/date-picker";
import { ColumnProps } from "antd/lib/table";
import { SortTable } from "Types";

const logger = Logger("useClassificationApiWithRedux");

interface IUserStateClassifications_Resp {
    userStateClassifications: IUserStateClassification[],
    totalCount: number,
}

interface InitialClassificationParams extends IUserStateClassificationTableFilters {
    pageSize?: number,
    page?: number,
    s_direction?: SortTable | "ASC" | "DESC" | null,
    f_dateAfter?: string | null,
    f_dateBefore?: string | null,
    s_field?: string | null | React.Key,
    f_coachId?: string[] | null,
};


export const useClassificationApiWithRedux = () => {
    const {API} = useApi();
    const dispatch = useAppDispatch();
    const [initialClassification, setInitialClassification] = usePersistedState<InitialClassificationParams>(
        "classifications",
        {
            pageSize: 50,
            page: 1,
            s_field: null,
            s_direction: "DESC",
            f_dateAfter: null,
            f_dateBefore: null,
            f_coachId: [],
            f_activation_high: null,
            f_activation_low: null,
            f_activation_steady: null,
            f_part_fight: null,
            f_part_flight: null,
            f_part_freeze: null,
            f_part_attach: null,
            f_part_submit: null,
            coachFullName: [],
        }
    );
    const {s_direction,f_dateAfter,f_dateBefore, s_field, f_coachId,
        f_activation_high,f_activation_low,f_activation_steady,
        f_part_fight, f_part_flight, f_part_freeze, f_part_attach, f_part_submit
    } = initialClassification;    
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const authUser = useAppSelector((state) => state.auth.user);
    const coachesOptions = useAppSelector((state) => state.classifications.coaches);
    const userClassificationsData = useAppSelector((state) => state.classifications.userStateClassification);
    const totalCount = useAppSelector((state) => state.classifications.totalCount);
    const selectedRowKeys = useAppSelector((state) => state.classifications.selectedRowKeys);

  const transformTimezone = (timezone: number) => {
    if (timezone < 0) {
      let d = Math.abs(timezone) % 1 === 0.5 ? ":30" : Math.abs(timezone) % 1 === 0.75 ? ":45" : ":00";
      let t = -Math.floor(Math.abs(timezone));
      return t > -10 ? "-0" + Math.abs(t) + d : String(t) + d;
    } else {
      let d = Math.abs(timezone) % 1 === 0.5 ? ":30" : Math.abs(timezone) % 1 === 0.75 ? ":45" : ":00";
      let t = Math.floor(timezone);
      return t < 10 ? "%2b0" + t + d : "%2b" + String(t) + d;
    }
  };

  const getCurrentTimezone = () => {
    let timezoneValue = (new Date().getTimezoneOffset() / 60) * -1;
    return transformTimezone(timezoneValue);
  };

    const onDownloadCsv = () => {
        const coachParams = f_coachId ? '&'+ f_coachId.map(id=>`f_coachId[]=${id}`).join('&') : '';
        const dateAfterParam = f_dateAfter ? `&f_dateAfter=${f_dateAfter}` : '';
        const dateBeforeParam = f_dateBefore ? `&f_dateBefore=${f_dateBefore}` : '';
        const fieldParam = s_field ? `&s_field=${s_field}` : '';
        const directionParam = s_direction ? `&s_direction=${s_direction}` : '';
        const timezone = `&timezone=${getCurrentTimezone()}`;
        const partFightParam = f_part_fight ? f_part_fight.map(p => `&f_part_fight[]=${p}`).join('') : '';
        const partFlightParam = f_part_flight ? f_part_flight.map(p => `&f_part_flight[]=${p}`).join('') : '';
        const partAttachParam = f_part_attach ? f_part_attach.map(p => `&f_part_attach[]=${p}`).join('') : '';
        const partSubmitParam = f_part_submit ? f_part_submit.map(p => `&f_part_submit[]=${p}`).join('') : '';
        const partFreezeParam = f_part_freeze ? f_part_freeze.map(p => `&f_part_freeze[]=${p}`).join('') : '';
        const activationHighParam = f_activation_high ? f_activation_high.map(a => `&f_activation_high[]=${a}`).join('') : '';
        const activationSteadyParam = f_activation_steady ? f_activation_steady.map(a => `&f_activation_steady[]=${a}`).join('') : '';
        const activationLowParam = f_activation_low ? f_activation_low.map(a => `&f_activation_low[]=${a}`).join('') : '';
        // const activationParams = f_activations ? '&'+f_activations.map(activation => `f_activations[]=${activation}`).join('&') : ''
        // const partsParams = f_parts ? '&'+f_parts.map(part => `f_parts[]=${part}`).join('&') : ''

       
      // console.log(`/api/user-state-classification/csv?${dateBeforeParam}${dateAfterParam}${coachParams}${directionParam}${fieldParam}${timezone}${activationParams}${partsParams}`)

       window.location.href = `/api/user-state-classification/csv?${dateBeforeParam}${dateAfterParam}${coachParams}${directionParam}${fieldParam}${partFightParam}${partFlightParam}${partAttachParam}${partSubmitParam}${partFreezeParam}${activationHighParam}${activationLowParam}${activationSteadyParam}${timezone}`
    }

    const getCoaches = async () => {
        try {
            const response = await API.get<IUser[]>('/api/user/coach-candidates');
            const data = response.data
                .map(({fullName, id}) => ({
                    text: getSubstringText(fullName),
                    value: id
                }));

            const authUserData = {
                text: getSubstringText(authUser?.fullName as string),
                value: authUser?.id as string
            }

            dispatch(setCoaches([
                authUserData,...data
                ]))
        }catch (e) {
            if(e instanceof Error)
            console.log(e.message);
        }
    }

    const onChangeTable: TableProps<IUserStateClassification>['onChange'] = 
        (pagination, 
        filters, 
        sorter
        ) => {
        const {pageSize, current} = pagination;
        const {columnKey, order} = sorter as SorterResult<IUserStateClassification>;
        



        setInitialClassification({
            ...initialClassification,
            ...filters,
            f_coachId: filters['coachFullName'] as string[],
            pageSize,
            page: isFiltersChange({
                prevFilters: initialClassification,
                currentFilters: filters
            }) ? 1 :current,
            // f_coachId: !!filters['coachFullName'] ? filters['coachFullName'].map(id => id) : [],
            s_field: !!columnKey && !!order ? (columnKey as string).startsWith("f_")
                    ? (columnKey as string).slice("f_".length) : columnKey : null,
            s_direction: !!order ? (changeSortDirectionTitle(order) as SortTable) : null
        })
    }

    const onChangeDate: RangePickerProps['onChange'] = (date): void => {  
            setInitialClassification({
                ...initialClassification,
                f_dateAfter: !!date ? date[0]?.parseZone().format(DATE_FORMAT) : null,
                f_dateBefore: !!date ? date[1]?.parseZone().format(DATE_FORMAT) : null,
                page: 1
            })
        
    }

    const getClassifications = async () => {
        try {
            const {data} = await API.get<IUserStateClassifications_Resp>('/api/user-state-classification', {
                params: initialClassification
            });

            const usersFilterOptions = [...new Map(data.userStateClassifications.map((item) => [item.User.id, item])).values()]
                .map(({User: {fullName, id}}) => ({
                    text: fullName,
                    value: id
                })).sort((a,b) => a.text.localeCompare(b.text));

            const response = {
                userStateClassifications: data.userStateClassifications,
                totalCount: data.totalCount,
                users: usersFilterOptions
            }
            dispatch(setClassifications(response));
        }catch (e) {
            if(e instanceof Error){
                console.log(e.message);
                logger.error(e.message);
            }
        }
    }

    const options = [{text: 'Yes', value: true}, {text: 'No', value: false}];

    const columns: ColumnProps<IUserStateClassification>[] = [
        {
            title: 'Date',
            key: 'date',
            dataIndex: 'date',
            render: getFormatDateWithHours,
            width: 100,
            // className: 'table-column-title-no-break',
            sorter: true,
            sortOrder: s_field === 'date' ? (changeSortDirectionTitle(s_direction) as SortTable) : null
        },
        {
            title: 'Full Name',
            key: 'userFullName',
            dataIndex: ['User', 'fullName'],
            render: (_,r) => getPopoverTextWithIcon(r.User.fullName),
            sorter: true,
            sortOrder: s_field === 'userFullName' ? (changeSortDirectionTitle(s_direction) as SortTable) : null
        },
        {
            title: 'Feedback Text',
            key: 'notes',
            width: 200 ,
            dataIndex: ['Feedback', 'notes'],
        },
        {
            title: 'Coach',
            width: 150,
            key: 'coachFullName',
            dataIndex: ['Coach', 'fullName'],
            render: (_,r) => getPopoverText(r.Coach?.fullName),
            sorter: true,
            sortOrder: s_field === 'coachFullName' ? (changeSortDirectionTitle(s_direction) as SortTable) : null,
            filters: coachesOptions,
            filteredValue: f_coachId
        },
        {
            title: 'Author',
            key: 'f_authorId',
            dataIndex: ['Author', 'fullName'],
            render: (_,r) => getPopoverText(r.Author?.fullName),
            sortOrder: s_field === 'author' ? (changeSortDirectionTitle(s_direction) as SortTable) : null,
        },
        {
            title: 'Activation Low',
            dataIndex: 'activation_low',
            filterMultiple: false,
            filteredValue: f_activation_low,
            key: 'f_activation_low',
            filters: options,
            render: (_,r) => r['activation_low'] ?
                <Tag color="green">Yes</Tag> :
                <Tag color="red">No</Tag>
        },
        {
            title: 'Activation Steady',
            filteredValue: f_activation_steady,
            filterMultiple: false,
            key: 'f_activation_steady',
            dataIndex: 'activation_steady',
            filters:options,
            render: (_,r) => r['activation_steady'] ?
                <Tag color="green">Yes</Tag> :
                <Tag color="red">No</Tag>
        },
        {
            title: 'Activation High',
            dataIndex: 'activation_high',
            filteredValue: f_activation_high,
            filterMultiple: false,
            key: 'f_activation_high',
            filters: options,
            render: (_,r) => r['activation_high'] ?
                <Tag color="green">Yes</Tag> :
                <Tag color="red">No</Tag>
        },
        {
            title: 'Part Fight',
            key: 'f_part_fight',
            filterMultiple: false,
            filteredValue: f_part_fight,
            dataIndex: 'part_fight',
            filters:options,
            render: (_,r) => r['part_fight'] ?
                <Tag color="green">Yes</Tag> :
                <Tag color="red">No</Tag>
        },
        {
            title: 'Part Flight',
            filteredValue: f_part_flight,
            filterMultiple: false,
            key: 'f_part_flight',
            dataIndex: 'part_flight',
            filters: options,
            render: (_,r) => r['part_flight'] ?
                <Tag color="green">Yes</Tag> :
                <Tag color="red">No</Tag>
        },
        {
            title: 'Part Freeze',
            filteredValue: f_part_freeze,
            filterMultiple: false,
            dataIndex: 'part_freeze',
            key: 'f_part_freeze',
            filters: options,
            render: (_,r) => r['part_freeze'] ?
                <Tag color="green">Yes</Tag> :
                <Tag color="red">No</Tag>
        },
        {
            title: 'Part Attach',
            key: 'f_part_attach',
            filterMultiple: false,
            filteredValue: f_part_attach,
            filters: options,
            dataIndex: 'part_attach',
            render: (_,r) => r['part_attach'] ?
                <Tag color="green">Yes</Tag> :
                <Tag color="red">No</Tag>
        },
        {
            filters: options,
            title: 'Part Submit',
            filterMultiple: false,
            filteredValue: f_part_submit,
            key: 'f_part_submit',
            dataIndex: 'part_submit',
            render: (_,r) => r['part_submit'] ?
                <Tag color="green">Yes</Tag> :
                <Tag color="red">No</Tag>
        },
        {
            title: 'Antidote',
            key: 'antidote',
            render: (_,data) => {
                const parts = partsOptions.filter((item) => data[item.value as PartsOptions]).map(({value}) => value);
                const activation = activationsOptions.filter((item) => data[item.value as ActivationsOptions]).map(({value}) => value);
                return <ActivationPartsContent withKeys={["Antidote"]} parts={parts} activations={activation} />
            }
        }
    ];



    const onSelectChange = (selected: string[]) => dispatch(setSelectedRowKeys({
            selectedRowKeys: selected
        }))


    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange
    };

    const fetchData = [getClassifications, getCoaches];

    const getData = () => {
        setIsLoading(true);
        Promise.all(fetchData.map(fn => fn())).then(() => {
            setIsLoading(false);
        }).catch(e => {
            logger.error(e.message);
            setIsLoading(false);
            setInitialClassification({
                pageSize: 50,
                page: 1,
                s_field: null,
                s_direction: null,
                f_coachId: null,
                f_dateAfter: null,
                f_dateBefore: null
            });
        })
    }

    const onLoad = () => getData();


    useEffect(() => {
       getData();
    }, [initialClassification])

    return {
        getClassifications,
        columns,
        isLoading,
        totalCount,
        onChangeTable,
        onLoad,
        onChangeDate,
        onDownloadCsv,
        rowSelection,
        userClassificationsData,
        initialClassification,
    }
};


