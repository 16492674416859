import React, { FC, useEffect } from "react"
import usersTableConfig from "../user/users_page/users_table/users_table_config"
import { CLIENT } from "../../vars"
import {
  getDateRange,
  getQueryParams,
  tablePagination,
} from "utils/helpers"
import { Table, TablePaginationConfig, TableProps } from "antd"
import { useAppSelector } from "redux/store"
import { FilterValue, SorterResult } from "antd/lib/table/interface"
import { IClient } from "Interfaces/User"

interface Props {
  isLoading: boolean,
  f_coachId: [string | null],
  handleTableChange: (pagination: TablePaginationConfig, filters: Record<string, FilterValue | null>, sorter: SorterResult<IClient> | SorterResult<IClient>[]) => void,
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
  onSelectUsersChange: (selectedUsers: React.Key[]) => void;
  getUsersCoach: (query?: {}) => Promise<void>,
  
};

const DashboardPageUsersTable: FC<Props> = (props) => {
  const { getUsersCoach, handleTableChange, onSelectUsersChange, isLoading, setIsLoading, f_coachId} = props

  const usersData = useAppSelector((state) => state.users.usersData)
  const dateRangeName = useAppSelector((state) => state.dashboard.dateRangeName)
  const totalCount = useAppSelector((state) => state.users.totalCount)
  const lastUpdated = useAppSelector((state) => state.users.lastUpdated)
  const selectedUsers = useAppSelector((state) => state.users.selectedUsers)
  const usersTableSettings = useAppSelector((state) => state.users.usersTableSettings)

  const { pageSize, page } = usersTableSettings

  const { getUsersColumns } = usersTableConfig({ path: "dashboard" })

  const onSelectChange = (selectedUsers: React.Key[]) => onSelectUsersChange(selectedUsers)

  const rowSelection: TableProps<IClient>['rowSelection'] = {
    selectedRowKeys: selectedUsers,
    onChange: onSelectChange,
  }

  useEffect(() => {
    let cleanupFunction = false
    const fetchData = async () => {
      setIsLoading(true)
      const { f_dateBefore, f_dateAfter } = getDateRange(dateRangeName)
      await getUsersCoach({
        ...getQueryParams(usersTableSettings),
        f_coachId,
        f_dateBefore,
        f_dateAfter,
      })

      if (!cleanupFunction) {
        setIsLoading(false)
      }
    }
    fetchData()

    return () => {(cleanupFunction = true)}
  }, [lastUpdated])

  return (
    <Table
      key={CLIENT}
      pagination={tablePagination({
        pageSize,
        page,
        totalCount,
        showSizeChanger: true,
      }) as TablePaginationConfig}
      columns={getUsersColumns}
      dataSource={usersData}
      loading={isLoading}
      onChange={handleTableChange}
      rowKey={(e) => e.id}
      scroll={{ x: "auto" }}
      rowSelection={rowSelection}
    />
  )
}

export default DashboardPageUsersTable