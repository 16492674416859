import React from 'react';
import { useAppSelector } from 'redux/store';
import DashboardPageChartsBar from "./DashboardPageChartsBar";
import NoData from "components/no-data/NoData";
import {shallowEqual} from "react-redux";
import {checkDashboardData, getSubstringText} from "utils/helpers";
import { ChartConfiguration } from 'chart.js';


const DashboardPageMiaBar = () => {
    const lastContactedMiaData = useAppSelector(state => state.dashboard.lastContactedMiaData, shallowEqual)

    const MiaBarData = {
        labels: lastContactedMiaData.map(({fullName}) => getSubstringText(fullName, 30)),
        datasets:
            [
                {
                    label: 'Coach MIA',
                    data: lastContactedMiaData.map(({value}) => Math.floor(+value)),
                    backgroundColor: '#c2ec9d'
                },
            ]
    }

    const options: ChartConfiguration<'bar'>['options'] = {
        plugins: {
            tooltip: {
                callbacks: {
                    label: function (miaData) {
                        const {dataset: {label, data}, dataIndex} = miaData
                        return `${label}: ${!!data && data[dataIndex] > 0 ? data[dataIndex] + ' days' : ''} `
                    }
                }
            },
        },
        scales: {
            yAxes:
                {
                    ticks: {
                        callback: function (value) {
                            return `${value} days`;
                        }
                    },
                    max: 100,
                },
        }
    };
    const miaBarProps: ChartConfiguration<'bar'> = {
        type: 'bar',
        data: MiaBarData,
        options
    }
    return (
        checkDashboardData(MiaBarData.datasets) ?
            <DashboardPageChartsBar title={"Last contacted an MIA"} {...miaBarProps}/>
        : <NoData description="Last contacted an MIA (No Data)" />
    )
};


export default DashboardPageMiaBar;