import React from 'react';
import { useAppSelector } from 'redux/store';
import {checkDashboardData} from "utils/helpers";
import NoData from "components/no-data/NoData";
import {Card, Col, Statistic, Tag, Row} from "antd";
import DashboardPageChartsBar from "./DashboardPageChartsBar";
import { ChartConfiguration } from 'chart.js';
import { Props as BarProps } from 'react-chartjs-2/dist/types';




const DashboardPageStatusBar = () => {
    const clientsStatusWeeks = useAppSelector(state => state.dashboard.clientsStatusWeeks)
    const [firstWeek,,,,,,,lastWeek] = clientsStatusWeeks;
    const labels = clientsStatusWeeks.map(({label}) => label)

    const statusData: ChartConfiguration<'bar'>['data']= {
        labels,
        datasets: [
            {
                label: 'Checked in',
                data: clientsStatusWeeks.map(({activeCheckinedNotLate}) => activeCheckinedNotLate),
                backgroundColor: "#a3f06c",
                stack: 'Stack 0'
            },
            {
                label: 'Late',
                data: clientsStatusWeeks.map(({activeCheckinedLate}) => activeCheckinedLate),
                backgroundColor: "#b6b5b5",
                stack: 'Stack 0'
            },
            {
                label: 'Missed 1',
                data: clientsStatusWeeks.map(({inactiveMissedOneWeek}) => inactiveMissedOneWeek),
                backgroundColor: "#e66060",
                stack: 'Stack 0'
            },
            {
                label: 'Missed 2+',
                data: clientsStatusWeeks.map(({inactiveMissedMoreThanTwoWeeks}) => inactiveMissedMoreThanTwoWeeks),
                backgroundColor: "#cc2727",
                stack: 'Stack 0'
            },
        ]
    }

    const options: ChartConfiguration<'bar'>['options'] = {
        // scale: {
        //     reverse: false,
        //     pointLabels: {
        //         fontStyle: "italic",
        //         fontSize: 27
        //     },
        // },
        plugins: {
            title: {
                display: true,
                position: 'bottom',
                text: '',
            },
        },
        scales: {
            x:{
                stacked: true
            },
            y:{
                stacked: true
            },
        }
    }

    const props: BarProps = {
        type: 'bar',
        data: statusData,
        options
    }

    const activePercentFirstWeek = (+(firstWeek?.activeCheckinedNotLate /
        (firstWeek?.activeCheckinedNotLate + firstWeek?.activeCheckinedLate + firstWeek?.inactiveMissedOneWeek + firstWeek?.inactiveMissedMoreThanTwoWeeks)
    ) * 100).toFixed(1);

    const activePercentLastWeek = (+(lastWeek?.activeCheckinedNotLate /
        (lastWeek?.activeCheckinedNotLate + lastWeek?.activeCheckinedLate + lastWeek?.inactiveMissedOneWeek + lastWeek?.inactiveMissedMoreThanTwoWeeks)
    ) * 100).toFixed(1);

    const percentBetweenLastAndFirstWeek = parseFloat((+activePercentLastWeek - +activePercentFirstWeek).toFixed(1));


    const getPercentBetweenFirstAndLastWeek = (value: number) => {

        if(value > 0){
            return <Tag color="green">+{value}%</Tag>
        }
        if(value < 0){
            return <Tag color="red">{value}%</Tag>
        }
        if(value === 0){
            return <Tag>{value}%</Tag>
        }
        return null;
    }

    return checkDashboardData(statusData.datasets) ? (
            <>
                <DashboardPageChartsBar {...props} title="Clients status weeks" />
                {
                    clientsStatusWeeks.length > 0 && (
                        <Row gutter={[8,8]}>
                            <Col span={12}>
                                <Card>
                                    <Statistic
                                       title={firstWeek.label.join(' ')}
                                       value="Active"
                                       suffix={<>
                                       <Tag>{parseFloat(activePercentFirstWeek) || 0}%</Tag>
                                       </>}
                                    />
                                </Card>
                            </Col>
                            <Col span={12}>
                                <Card>
                                    <Statistic
                                    title={lastWeek.label.join(' ')}
                                    value="Active"
                                    suffix={<>
                                    <Tag>{parseFloat(activePercentLastWeek) || 0}%</Tag>
                                    {getPercentBetweenFirstAndLastWeek(percentBetweenLastAndFirstWeek)}
                                    </>}
                                    />
                                </Card>
                            </Col>
                        </Row>
                    )
                }
            </>
            )
            :
            <NoData description="Clients status weeks (No Data)" />

};



export default DashboardPageStatusBar;