import { PayloadAction } from '@reduxjs/toolkit';
import {createSlice} from '@reduxjs/toolkit'
import { IClientStatusWeek, ICoachOverview, IContactedMIA, IClientProgress, ICoachClientsTableSettings } from 'Interfaces/Dashboard';
import { ITypeValueQuantity, ITotalClients, ITypeValue, IComplianceDaily } from 'Interfaces/Dashboard/CheckIn';
import {THIS_WEEK, LAST_MONTH, LAST_WEEK} from "vars";

type DateRangeName = typeof THIS_WEEK | typeof LAST_WEEK | typeof LAST_MONTH;
interface InitialState {
    dateRangeName: DateRangeName,
    selectedCoachId: string | null,
    chartsPieClientsData: ITypeValueQuantity[],
    chartsPieCompletedData: ITypeValueQuantity[],
    totalData: ITypeValue | null,
    totalClients: ITotalClients | null,
    feedbackGiven: ITypeValue | null,
    activeClients: ITypeValue | null,
    assignedCoaches: ITypeValue | null,
    averageFeedbackTime: ITypeValue | null,
    coachClientsTotalCount: ITypeValue | null,
    miaAverageTotal: ITypeValue | null,
    averageCompanyFeedbackGiven: ITypeValue | null,
    averageCompanyClients: ITypeValue | null,
    averageCompanyFeedbackTime: ITypeValue | null,
    averageCompanyCheckinCompleted: ITypeValueQuantity[],
    clientsStatusWeeks: IClientStatusWeek[],
    averageCompanyCompliance: ITypeValueQuantity[],
    averageCompanyFeedbackOutStanding: ITypeValue | null,
    averageCompanyStats: ITypeValue[],
    clientsProgress: IClientProgress[],
    statsCheckins: ITypeValue[],
    complianceDailyData: IComplianceDaily | null,
    lastContactedMiaData: IContactedMIA[],
    checkInOverdue: ITypeValue | null,
    feedbackOutstanding: ITypeValue | null,
    coachClientsData: ICoachOverview[],
    coachClientsTableSettings: ICoachClientsTableSettings,
    averageCompanyMiaTotal: ITypeValue | null,
    averageCompanyCheckinOverdue: ITypeValue | null,
    completedDailyData: IComplianceDaily | null
};

const initialState = {
    dateRangeName: THIS_WEEK,
    selectedCoachId: null,
    chartsPieClientsData: [],
    chartsPieCompletedData: [],
    totalData: null,
    totalClients: null,
    // coachesOverview: {},
    feedbackGiven: null,
    activeClients: null,
    assignedCoaches: null,
    averageFeedbackTime: null,
    coachClientsTotalCount: null,
    miaAverageTotal: null,
    // averageMia: {},
    averageCompanyFeedbackGiven: null,
    averageCompanyCheckinOverdue: null,
    averageCompanyFeedbackTime: null,
    averageCompanyClients: null,
    averageCompanyCheckinCompleted: [],
    // setAverageCompanyCheckinCompleted: [],
    clientsStatusWeeks: [],
    averageCompanyCompliance: [],
    averageCompanyFeedbackOutStanding: null,
    averageCompanyStats: [],
    clientsProgress: [],
    averageCompanyMiaTotal: null,
    statsCheckins: [],
    coachClientsData: [],
    complianceDailyData: null,
    // averageCompliance: [],
    completedDailyData: null,
    lastContactedMiaData: [],
    checkInOverdue: null,
    feedbackOutstanding: null,
    coachClientsTableSettings: {
        pageSize: 10,
        page: 1,
        totalCount: 0,
        s_field: null,
        s_direction: null,
        f_actorId: null,
    },
} as InitialState;

const dashboardReducer = createSlice({
    name: 'dashboardReducer',
    initialState,
    reducers: {
        setDateRangeName: (state, action: PayloadAction<DateRangeName>) => {
            state.dateRangeName = action.payload
        },
        setChartsPieClientsData: (state, action: PayloadAction<{chartsPieClientsData: ITypeValueQuantity[]}>) => {
            state.chartsPieClientsData = action.payload.chartsPieClientsData
        },
        setChartsPieCompletedData: (state, action: PayloadAction<{chartsPieCompletedData: ITypeValueQuantity[]}>) => {
            state.chartsPieCompletedData = action.payload.chartsPieCompletedData
        },
        setTotalData: (state, action: PayloadAction<{totalData: ITypeValue}>) => {
            state.totalData = action.payload.totalData
        },
        setTotalClients: (state, action: PayloadAction<ITotalClients>) => {
            state.totalClients = action.payload
        },
        setFeedbackGiven: (state, action: PayloadAction<{feedbackGiven: ITypeValue}>) => {
            state.feedbackGiven = action.payload.feedbackGiven
        },
        // setCoachesOverview: (state, action) => {

        // },
        setStatsCheckins: (state, action: PayloadAction<{statsCheckins: ITypeValue[]}>) => {
            state.statsCheckins = action.payload.statsCheckins
        },
        setAverageCompanyCompliance: (state,action: PayloadAction<ITypeValueQuantity[]>) => {
            state.averageCompanyCompliance = action.payload
        },
        setAverageCompanyMiaTotal: (state, action: PayloadAction<ITypeValue>) => {
          state.averageCompanyMiaTotal = action.payload
        },
        setAverageCompanyFeedbackGiven: (state, action: PayloadAction<ITypeValue>) => {
            state.averageCompanyFeedbackGiven = action.payload
        },
        setAverageCompanyFeedbackTime: (state, action: PayloadAction<ITypeValue>) => {
            state.averageCompanyFeedbackTime = action.payload
        },
        setAverageCompanyCheckinOverdue: (state, action: PayloadAction<ITypeValue>) => {
            state.averageCompanyCheckinOverdue = action.payload
        },
        setAverageCompanyCheckinCompleted: (state, action: PayloadAction<ITypeValueQuantity[]>) => {
            state.averageCompanyCheckinCompleted = action.payload
        },
        setAverageCompanyFeedbackOutStanding: (state, action: PayloadAction<ITypeValue>) => {
            state.averageCompanyFeedbackOutStanding = action.payload
        },
        setAverageCompanyStats: (state, action: PayloadAction<ITypeValue[]>) => {
           state.averageCompanyStats = action.payload
        },
        setAverageCompanyClients: (state, action: PayloadAction<ITypeValue>) => {
            state.averageCompanyClients = action.payload
        },
        setActiveClients: (state, action: PayloadAction<{activeClients: ITypeValue}>) => {
            state.activeClients = action.payload.activeClients
        },
        setCoachClients: (state, action: PayloadAction<{coachClientsData: ICoachOverview[], coachClientsTotalCount: ITypeValue}>) => {
            state.coachClientsData = action.payload.coachClientsData
            state.coachClientsTotalCount = action.payload.coachClientsTotalCount
        },
        setAverageFeedbackTime: (state, action: PayloadAction<{averageFeedbackTime: ITypeValue}>) => {
            state.averageFeedbackTime = action.payload.averageFeedbackTime
        },
        setComplianceDaily: (state, action: PayloadAction<{complianceDailyData: IComplianceDaily}>) => {
            state.complianceDailyData = action.payload.complianceDailyData
        },
        setLastContactedMia: (state, action: PayloadAction<{lastContactedMiaData: IContactedMIA[]}>) => {
            state.lastContactedMiaData = action.payload.lastContactedMiaData
        },
        setSelectCoachId: (state, action: PayloadAction<{selectedCoachId: string | null}>) => {
            state.selectedCoachId = action.payload.selectedCoachId
        },
        setAssignedCoaches: (state, action: PayloadAction<ITypeValue>) => {
            state.assignedCoaches = action.payload
        },
        setCompletedDailyDataData: (state, action: PayloadAction<{completedDailyData: IComplianceDaily }>) => {
            state.completedDailyData = action.payload.completedDailyData
        },
        setClientsProgress: (state, action: PayloadAction<IClientProgress[]>) => {
            state.clientsProgress = action.payload
        },
        setCheckInOverdue: (state, action: PayloadAction<{checkInOverdue: ITypeValue}>) => {
            state.checkInOverdue = action.payload.checkInOverdue
        },
        setFeedbackOutstanding: (state, action: PayloadAction<{feedbackOutstanding: ITypeValue}>) => {
            state.feedbackOutstanding = action.payload.feedbackOutstanding
        },
        setMiaAverageTotal: (state, action: PayloadAction<{miaAverageTotal: ITypeValue}>) => {
            state.miaAverageTotal = action.payload.miaAverageTotal
        },
        setClientsStatusWeeks: (state, action: PayloadAction<IClientStatusWeek[]>) => {
          state.clientsStatusWeeks = action.payload
        },
        dashboardReducerReset: state => initialState
    }
})

export const {
    setDateRangeName,
    setChartsPieClientsData,
    setChartsPieCompletedData,
    setTotalData,
    setFeedbackGiven,
    setActiveClients,
    setCoachClients,
    setStatsCheckins,
    setAverageCompanyCheckinCompleted,
    setAverageCompanyCheckinOverdue,
    setAverageCompanyFeedbackOutStanding,
    setAverageCompanyMiaTotal,
    setAverageCompanyFeedbackTime,
    setAverageCompanyFeedbackGiven,
    setAverageCompanyStats,
    setClientsProgress,
    setAverageFeedbackTime,
    setAverageCompanyCompliance,
    setAverageCompanyClients,
    setComplianceDaily,
    setSelectCoachId,
    setAssignedCoaches,
    setTotalClients,
    setClientsStatusWeeks,
    setLastContactedMia,
    setCompletedDailyDataData,
    setCheckInOverdue,
    setFeedbackOutstanding,
    setMiaAverageTotal,
    dashboardReducerReset,
} = dashboardReducer.actions
export default dashboardReducer.reducer


