import { DATE_FORMAT } from './../vars';
import { SorterResult } from 'antd/lib/table/interface';
import { CheckInTableSettings } from './../redux/reducers/checkInsReducer';
import { ICoach, IUser } from 'Interfaces/User';
import { ICheckIn, ICheckInChartValues } from 'Interfaces/CheckIn';
import React, {useEffect} from "react"
import useApi from "../hooks/useApi"
import {
    checkInsReducerReset,
    setCheckInCoachFilterOptions,
    setCheckIns, setCheckInsChart,
    setCheckInSelectedRow,
    setCheckInTableSettings,
    setCheckInTableUpdate, 
    setCurrentCheckIn, 
    setIsLoadingCheckIns, 
    setUpdateFeedbackGivenColumn,
} from "../redux/reducers/checkInsReducer"
import {
    changeSortDirectionTitle, 
    getAutoGeneratedParam, 
    getSubstringText,
    isFiltersChange,
    Logger,
    getChartDataWithOrderWeeks,
    getTwoEntitiesWithBiggestTimeDiff,
} from "../utils/helpers"
import {useHistory, useLocation, useParams} from "react-router-dom";
import {manager} from "../vars";
import moment from "moment";
import { useAppDispatch, useAppSelector } from "redux/store"
import { TablePaginationConfig } from 'antd/es/table';
import { FixMeLater, SortTable } from 'Types';
import { CheckInsCountData } from 'Interfaces/CheckIn';

const logger = Logger("CheckInsApiWithRedux")
interface ICheckIn_Response {
    checkIns: ICheckIn[],
    totalCount: number,
};

interface ICoachData_Response {
    users: ICoach[],
    totalCount: number,
}

interface ILowestCheckIn_Response {
    highestHips: number,
    highestLowerBelly: number,
    highestMidQuad: number,
    highestUpperQuad: number,
    highestWaist: number,
    highestWeight: number,
    lowestHips: number,
    lowestLowerBelly: number,
    lowestMidQuad: number,
    lowestUpperQuad: number,
    lowestWaist: number,
    lowestWeight: number,
    userId: string,
    avg_weight: number, 
    avg_hips: number, 
    avg_waist: number, 
    sd_weight: number, 
    sd_hips: number, 
    sd_waist: number,
};

interface IChartData {
    [key: string | number]: (number)[]
};

function useCheckInsApiWithRedux() {
    const {API, cancel} = useApi()
    const dispatch = useAppDispatch()
    const authUser = useAppSelector(state => state.auth.user);
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const checkInId = params.get('checkInId');
    const history = useHistory();
    const {pathname} = useLocation();
    const {id} = useParams<{id: string | undefined}>();
    const checkInTableSettings = useAppSelector((state) => state.checkIns.checkInTableSettings)
    const selectedRowKeys = useAppSelector((state) => state.checkIns.checkInSelectedRowKeys)
    const checkInData = useAppSelector((state) => state.checkIns.checkInData)

    const getCheckInData = async (query: CheckInTableSettings) => {
        dispatch(setIsLoadingCheckIns(true));
        try {
            if(checkInId){
                const response = await API.get<ICheckIn_Response>(`/api/checkin/${checkInId}`);
                return {
                    checkIns: [response.data] || [],
                    totalCount: 1,
                };
            }

            // we want to sort by date, when we sorting by week on the table
            if (query.s_field === 'week') query.s_field = 'date';
            const response = await API.get<ICheckIn_Response>(`/api/checkin`, {params: {...query}})
            return response.data
        } catch (e) {
            if(e instanceof Error){
                logger.error(e.message)
                dispatch(setIsLoadingCheckIns(false));
            }
        }
    }

    const saveCheckin = async (data: ICheckIn) => {
        const response = await API.post<ICheckIn>("/api/checkin/save", data)
        return response.data
    }

    const getCoachData = async () => {
        try {
            if(authUser?.roles === manager){
                const response = await API.get<ICoachData_Response>("/api/user", {
                    params: {
                        f_roles: 'coach',
                        f_managerId: authUser.id
                    }
                })
                return response.data.users
            }
            const response = await API.get<IUser[]>("/api/user/coach-candidates")
            return response.data
        } catch (e) {
            if(e instanceof Error)
            logger.error(e.message);
        }
    }

    const getLowestCheckInData = async (userId: string) => {
        try {
            const response = await API.get<ILowestCheckIn_Response>('/api/dashboard/user-stats', {
                params: {
                    f_userId: userId
                }
            });
            return response.data 
        } catch (e) {
            if(e instanceof Error)
            logger.error(e.message)  
        }
    }

    const getCheckInsCountBetweenTwoDates = async (userId: string, dates: [string, string] ) => {
        try {
            return (await API.get<CheckInsCountData>(`/api/user/${userId}/checkin/count`, { params: { dates } })).data;
        } catch (e) {
            console.error(e);
        }
    }

        // Generate missing check-ins into the list for each of the given weeks.  Week is year * 52 + week number.
    // Latest week is first, latest check-in is first.
    const generateMissingCheckIns = (list: ICheckIn[], yearweeks: number[]) =>{
        // for each week
        // check if a check-in is done in that week
        // if not, add a check-in with details of the previous valid check-in
        yearweeks.map((yearweek, i) =>{
            let checkIn = list.find(e=>(((e.year * 52) + e.week) === yearweek))
            console.log("Weeks - Checking", yearweek, yearweek % 52, checkIn)
            if(checkIn && checkIn.week === yearweek % 52){
                console.log("Weeks - OK")
            }else{
                let latest = list.find(e=>(e.year * 52 + e.week) < yearweek)!
                let checkInToAdd = {...latest, autoGenerated:true, week: yearweek % 52}
                console.log("Weeks - Adding Missed", checkInToAdd)
                list.splice(i+1,1, checkInToAdd)
            } 
        })
    }

    //Replace outliers (3sd) with average
    const replaceBadValues = (list: ICheckIn[], userData: Partial<ILowestCheckIn_Response>) =>{
            const S = String;
            let weight_min = parseFloat(S(userData.avg_weight)) - (3*parseFloat(S(userData.sd_weight)))
            let weight_max = parseFloat(S(userData.avg_weight)) + (3*parseFloat(S(userData.sd_weight)))
            let waist_min = parseFloat(S(userData.avg_waist)) - (3*parseFloat(S(userData.sd_waist)))
            let waist_max = parseFloat(S(userData.avg_waist)) + (3*parseFloat(S(userData.sd_waist)))
            let hips_min = parseFloat(S(userData.avg_hips)) - (3*parseFloat(S(userData.sd_hips)))
            let hips_max = parseFloat(S(userData.avg_hips)) + (3*parseFloat(S(userData.sd_hips)))

            // console.log("UserData", userData)
            // console.log("UserData Weights", weight_min, weight_max)
            
            list.map(checkIn=>{
                if(+checkIn.weight > weight_max || +checkIn.weight < weight_min){
                    checkIn.weight = userData.avg_weight!
                    checkIn.autoGenerated = true
                }

                if(+checkIn.waist > waist_max || +checkIn.waist < waist_min){
                    checkIn.waist = userData.avg_waist!
                    checkIn.autoGenerated = true
                }

                if(+checkIn.hips > hips_max || +checkIn.hips < hips_min){
                    checkIn.hips = userData.avg_hips!
                    checkIn.autoGenerated = true
                }
            })
    }


    const getCheckInChart = (userId: string) => async () => {
        dispatch(setIsLoadingCheckIns(true));
        const f_endDate = moment().format(DATE_FORMAT);
        const f_startDate = moment().subtract(16, 'weeks').format(DATE_FORMAT);

        const Data = async () => await getCheckInData({f_userId: [userId], f_endDate, f_startDate, page: 1, pageSize: 25, s_field: 'date', s_direction: 'DESC' as SortTable}) as ICheckIn_Response;
        const FirstCheckInData = async () => await getCheckInData({f_userId: [userId], page: 1, pageSize: 1, s_field: 'date', s_direction: 'ASC' as SortTable}) as ICheckIn_Response;
        const LowestCheckInData = async () => await getLowestCheckInData(userId) as ILowestCheckIn_Response;
        const [data, firstCheckInData, lowestCheckInData] = await Promise.all([Data(), FirstCheckInData(), LowestCheckInData()]);
         
        const weeks = new Array(8).fill(1).map((_, i) => {
            let date = moment()
            date.subtract(i, 'weeks')
            return (date.year() * 52) + date.isoWeek()
        });
        const {avg_hips, avg_waist, avg_weight, sd_hips, sd_waist, sd_weight} = lowestCheckInData
        const userData = {avg_weight: avg_weight, avg_hips: avg_hips, avg_waist: avg_waist, sd_weight:sd_weight, sd_waist:sd_waist, sd_hips:sd_hips}

        replaceBadValues(data.checkIns, userData)
        generateMissingCheckIns(data.checkIns, weeks)

        const getChartValues = (data: ICheckIn_Response, name: keyof ICheckIn) => {
            const dataWeeks = [...new Set(data.checkIns.map(({week}) => week))].slice(0,8);
            const dataWeeksValues = dataWeeks.reduce((acc, week) => ({
                ...acc,
                [week]: data.checkIns.filter((checkIn) => checkIn.week === week)
                    .map((checkIn) => checkIn.autoGenerated ? null : checkIn[name]),
            }), {});
            const dataKeysName = Object.entries(dataWeeksValues as IChartData);
            return dataKeysName.map(([,values]) => values[0] ? values[0] : null).reverse();
        };
        const getChartValuesAutoGenerated = (data: ICheckIn_Response, name: keyof ICheckIn) => {
            const dataWeeks = [...new Set(data.checkIns.map(({week}) => week))].slice(0,8);
            const dataWeeksValues = dataWeeks.reduce((acc, week) => ({
                ...acc,
                [week]: data.checkIns.filter((checkIn) => checkIn.week === week)
                    .map((checkIn) => !checkIn.autoGenerated ? checkIn[name] : null),
            }), {})
            const dataKeysName = Object.entries(dataWeeksValues as IChartData);
            return dataKeysName.map(([,values]) => values[0] ? values[0] : null).reverse();

        };


        const getAllValues = (data: ICheckIn_Response, name: keyof ICheckIn) => {
            const dataWeeks = [...new Set(data.checkIns.map(({week}) => week))].slice(0,8);
            const dataWeeksValues = dataWeeks.reduce((acc, week) => ({
                ...acc,
                [week]: data.checkIns.filter((checkIn) => checkIn.week === week)
                    .map((checkIn) => checkIn[name]),
            }), {})
            const dataKeysName = Object.entries(dataWeeksValues as IChartData);
            return dataKeysName.map(([,values]) => values[0]);
        };

        const dataLabels = [...new Set(data.checkIns.map(({week}) => week))].slice(0,8);
        const dataWeight = getChartValues(data, 'weight');
        const dataHips = getChartValues(data, 'hips');
        const dataWaist = getChartValues(data, 'waist');
        const dataWeightAutoGenerated = getChartValuesAutoGenerated(data, 'weight')
        const dataHipsAutoGenerated = getChartValuesAutoGenerated(data, 'hips')
        const dataWaistAutoGenerated = getChartValuesAutoGenerated(data, 'waist');


        //LineRegression_OrderWeeks
        const getChartValuesWithOrderWeeksAuto = (data: ICheckInChartValues[]) => data.map((checkIn) => checkIn.autoGenerated ? checkIn : null );
        const getChartValuesWithOrderWeeksNotAuto = (data: ICheckInChartValues[]) => data.map((checkIn) => checkIn.autoGenerated ? null : checkIn );
        const getChartValuesWithOrderWeeks = (data: {checkIns: ICheckIn[]}, name: "weight" | "waist" | "hips") => {
            const dataWeeks = [...new Set(data.checkIns?.map(({week}) => week))].slice(0,8);
            const dataWeeksValues = dataWeeks.reduce((acc, week) => ({
                ...acc,
                [week]: data.checkIns.filter((checkIn) => checkIn.week === week)
                    .map((checkIn) => ({x: +checkIn.week, y: +checkIn[name], autoGenerated: checkIn.autoGenerated})),
            }), {} as {[key: number]: ICheckInChartValues[]})
            const dataKeysName = Object.entries(dataWeeksValues);
            return dataKeysName.map(([,values]) => values[0]);
        };
        const allWeight = getAllValues(data, 'weight').reverse();
        const allWaist = getAllValues(data, 'waist').reverse();
        const allHips = getAllValues(data, 'hips').reverse();
        const weightWithOrder = getChartDataWithOrderWeeks(getChartValuesWithOrderWeeks(data, 'weight'));
        const waistWithOrder = getChartDataWithOrderWeeks(getChartValuesWithOrderWeeks(data, 'waist'));
        const hipsWithOrder = getChartDataWithOrderWeeks(getChartValuesWithOrderWeeks(data, 'hips'));
        const waistWithOrderNotAuto =  getChartValuesWithOrderWeeksNotAuto(waistWithOrder);
        const waistWithOrderAuto = getChartValuesWithOrderWeeksAuto(waistWithOrder);
        const waistLabelsOrder = waistWithOrder.map(({x}) => x);
        const hipsWithOrderNotAuto = getChartValuesWithOrderWeeksNotAuto(hipsWithOrder);
        const hipsWithOrderAuto = getChartValuesWithOrderWeeksAuto(hipsWithOrder);
        const hipsLabelsOrder = hipsWithOrder.map(({x}) => x);
        const weightWithOrderNotAuto = getChartValuesWithOrderWeeksNotAuto(weightWithOrder);
        const weightWithOrderAuto = getChartValuesWithOrderWeeksAuto(weightWithOrder);
        const weightLabelsOrder = weightWithOrder.map(({x}) => x);
               


        dispatch(setCheckInsChart({
            checkIns: data.checkIns,
            hipsDataset: dataHips,
            waistDataset: dataWaist,
            weightDataset: dataWeight,
            waistWithOrderNotAuto,
            waistWithOrderAuto,
            waistLabelsOrder,
            hipsLabelsOrder,
            hipsWithOrderAuto,
            hipsWithOrderNotAuto,
            weightLabelsOrder,
            weightWithOrderAuto,
            weightWithOrderNotAuto,
            hipsDatasetAutoGenerated: dataHipsAutoGenerated,
            allWeight,
            allWaist,
            allHips,
            waistDatasetAutoGenerated: dataWaistAutoGenerated,
            weightDatasetAutoGenerated: dataWeightAutoGenerated,
            labels: dataLabels,
            lowestWaist: lowestCheckInData.lowestWaist,
            lowestWeight: lowestCheckInData.lowestWeight,
            lowestHips: lowestCheckInData.lowestHips,
            hipsStarted: +firstCheckInData?.checkIns[0]?.hips || 0,
            weightStarted: +firstCheckInData?.checkIns[0]?.weight || 0,
            waistStarted: +firstCheckInData?.checkIns[0]?.waist || 0
        }));
    }

    const handleChangeAutoGenerated = (autoGenerated: boolean) => {
        dispatch(
            setCheckInTableSettings({
                f_autoGenerated: getAutoGeneratedParam(autoGenerated)
            })
        );
        checkInTableUpdate();
      };

    const getCheckIn = async (query: CheckInTableSettings = {}) => {
        const response = await getCheckInData(query) as ICheckIn_Response;

        if (Array.isArray(response?.checkIns)) {
            const sortedWeightCheckIns = [...response?.checkIns].sort((a,b) => +a.weight - +b.weight);
            const sortedHipsCheckIns = [...response.checkIns].sort((a,b) => +a.hips - +b.hips);
            const sortedWaistCheckIns = [...response.checkIns].sort((a,b) => +a.waist - +b.waist);
            const [smallestWeightCheckIn] = sortedWeightCheckIns.filter((checkIn) => checkIn?.weight === sortedWeightCheckIns[0]?.weight).sort((a, b) => new Date(a.date).getTime() + new Date(b.date).getTime());
            const [smallestHipsCheckIn] = sortedHipsCheckIns.filter((checkIn) => checkIn?.hips === sortedHipsCheckIns[0]?.hips).sort((a,b) => new Date(a.date).getTime() + new Date(b.date).getTime());
            const [smallestWaistCheckIn] = sortedHipsCheckIns.filter((checkIn) => checkIn?.waist === sortedWaistCheckIns[0]?.waist).sort((a,b) => new Date(a.date).getTime() + new Date(b.date).getTime());

            const smallestWeightCheckInData = response.checkIns.map((checkIn) => checkIn?.id === smallestWeightCheckIn?.id ? ({
                ...checkIn,
                smallestWeight: true
            }) : checkIn);

            const smallestHipsCheckInData = smallestWeightCheckInData.map((checkIn) => checkIn.id === smallestHipsCheckIn?.id ? ({
                ...checkIn,
                smallestHips: true,
            }) : checkIn);

            const smallestWaistCheckInData = smallestHipsCheckInData.map((checkIn) => checkIn.id === smallestWaistCheckIn?.id ? ({
                ...checkIn,
                smallestWaist: true
            }) : checkIn);
            const clientCheckinsData = {
                ...response,
                checkIns: smallestWaistCheckInData
            }
            const data = id ? clientCheckinsData : response;
            if(data){
                dispatch(setCheckIns({...data}))
            }
        } else {
            if(response){
                dispatch(setCheckIns(response))
            }
        }
    
    }
    const getUserCheckIn = async (query = {}) => {
        const data = await getCheckInData(query) as ICheckIn_Response;
        const checkInToCompare = getTwoEntitiesWithBiggestTimeDiff(data.checkIns);

        let counts = null;
        if (checkInToCompare)
            counts = await getCheckInsCountBetweenTwoDates(
                checkInToCompare[0].userId,
                [checkInToCompare[0].date, checkInToCompare[1].date]
            ) ?? null;

        if (!!data) {
            dispatch(setCheckIns(data));
            dispatch(
                setCheckInSelectedRow({
                    checkInSelectedRowKeys: data.checkIns.map((checkIn) => checkIn.id),
                    checkInSelectedRowData: data.checkIns,
                    counts
                })
            )
        }
    }

    const changeSearchValue = (value: string) => {
        dispatch(
            setCheckInTableSettings({
                f_clientName: !!value.trim() ? value : null,
            })
        )}
        
    const checkInTableUpdate = (callback = () => {}) => {
        if(pathname.includes('checkins')){
            callback();
        }
        dispatch(setCheckInTableUpdate({lastUpdate: Date.now()}))
    }

    const checkInTableUpdateOnLoad = () => {
        if(checkInId){
            dispatch(setCurrentCheckIn(null));
            history.push('/checkins');
        }
        dispatch(setCheckInTableUpdate({lastUpdate: Date.now()}))
    }

    const handleCheckInsTableChange = <T>(pagination: TablePaginationConfig, filters: CheckInTableSettings, sorter: SorterResult<T> | SorterResult<T>[]): void => {
        const {pageSize, current} = pagination
        const {columnKey, order} = sorter as SorterResult<T>;
        
        dispatch(
            setCheckInTableSettings({
                ...filters,
                pageSize,
                page: isFiltersChange({
                    prevFilters: checkInTableSettings,
                    currentFilters: filters,
                })
                    ? 1
                    : current,
                s_field:
                    !!columnKey && !!order
                        ? (columnKey as string).startsWith("f_")
                            ? (columnKey as string).slice("f_".length)
                            : columnKey
                        : null,
                s_direction: !!order ? (changeSortDirectionTitle(order) as SortTable) : null,
            })
        )
        checkInTableUpdate()
    }

    const dateChange = (date: FixMeLater) => {
        dispatch(
            setCheckInTableSettings({
                f_startDate: !!date ? date[0]?.format(DATE_FORMAT) : null,
                f_endDate: !!date ? date[1]?.format(DATE_FORMAT) : null,
                page: 1,
            })
        )
        checkInTableUpdate(() => history.push('/checkins'))
    }
    const initCheckInTableFilters = (data: CheckInTableSettings) =>
        dispatch(setCheckInTableSettings({...data}))

    const onSelectChange = async (selected: ICheckIn[]) => {
        const checkInToCompare = getTwoEntitiesWithBiggestTimeDiff(selected);

        let counts = null;
        if (checkInToCompare)
            counts = await getCheckInsCountBetweenTwoDates(
                checkInToCompare[0].userId,
                [checkInToCompare[0].date, checkInToCompare[1].date]
            ) ?? null;

        dispatch(
            setCheckInSelectedRow({
                checkInSelectedRowKeys: selected.map((checkIn) => checkIn.id) as React.Key[],
                checkInSelectedRowData: selected as ICheckIn[],
                counts
            })
        )
    }

    const onUpdateFeedbackGivenColumn = (selected: React.Key[]) => {
        dispatch(setUpdateFeedbackGivenColumn(selected))
    }

    const updateCheckins = (value: boolean): Promise<ICheckIn[]> => {
        
        const requests = checkInData.reduce((arr: ICheckIn[], checkIn) => {
            if (selectedRowKeys.includes(checkIn.id)) {
                return [...arr, { ...checkIn, feedbackGiven: value }];
            }
            return arr;
        }, [])

            return Promise.all(requests.map((data) => saveCheckin(data)))
    }

    const getCoaches = async () => {
        const coachData = await getCoachData()
        if (Array.isArray(coachData)) {
            const coachFilterOptions = coachData
                .map((coach) => ({
                    text: getSubstringText(coach.fullName),
                    value: coach.id,
                }))
                .sort((a, b) => a.text.localeCompare(b.text))
            if(authUser?.roles === manager){
                const coachFilterWithManager = [ {text: getSubstringText(authUser.fullName), value: authUser.id},...coachFilterOptions]
                dispatch(setCheckInCoachFilterOptions({coachFilterOptions: coachFilterWithManager}))
            } else {
                dispatch(setCheckInCoachFilterOptions({coachFilterOptions}))
            }
        }
    }

    const handleCheckinSearch = () => {
        if(pathname.includes('/checkins')){
            history.push('/checkins');
        }
        dispatch(setCheckInTableSettings({page: 1}))
        checkInTableUpdate()
    }

    useEffect(() => () => {dispatch(checkInsReducerReset())}, [])

    return {
        checkInsApiCancel: cancel,
        getCheckInsCountBetweenTwoDates,
        getCheckInChart,
        saveCheckin,
        checkInTableUpdate,
        getCheckIn,
        dateChange,
        changeSearchValue,
        handleCheckInsTableChange,
        initCheckInTableFilters,
        onSelectChange,
        updateCheckins,
        getCoaches,
        checkInTableUpdateOnLoad,
        handleCheckinSearch,
        onUpdateFeedbackGivenColumn,
        getUserCheckIn,
        handleChangeAutoGenerated,
    }
}

export default useCheckInsApiWithRedux
