import React, { FC } from 'react';
import {Col, Row, Typography} from "antd";
import {activationValues, partsValues} from "vars";
import activation_part from "activation_part_2.json";

interface Props {
    parts: string[],
    activations: string[],
    withKeys: string[],
}

const ActivationPartsContent: FC<Props> = ({parts = [],activations = [], withKeys = []}) => (
        <Row gutter={[16, 16]}>
            {
                activations.length > 0 && activations.map((activation) =>
                    parts.map((part) => (
                        <Col key={part} span={24}>
                            <Typography.Title level={5}>
                                {`${activationValues[activation][0].toUpperCase() + activationValues[activation].substring(1)} - ${partsValues[part][0].toUpperCase() + partsValues[part].substring(1)}`}
                            </Typography.Title>
                            {
                                Object.entries((activation_part as any)[partsValues[part]][activationValues[activation]])
                                    .filter(([key]) => withKeys.includes(key)).map(([title, text]) => (
                                    <Row key={text as React.Key} gutter={[16, 16]}>
                                        <Col span={24} key={title}>
                                            <Typography.Paragraph>
                                                {`${title[0].toUpperCase() + title.substring(1)}: ${text}`}
                                            </Typography.Paragraph>
                                        </Col>
                                    </Row>
                                ))
                            }
                        </Col>))
                )
            }
        </Row>
)

export default ActivationPartsContent;