import React, { FC, useEffect } from 'react';
import { useAppSelector } from 'redux/store';
import { Form, Select } from 'antd';
import {CloseOutlined} from '@ant-design/icons';
import { Modal } from 'components/Antd/Modal';
import { admin } from 'vars';

interface Props {
    onFinish: (data: {
        managerIdList: React.Key[];
    }) => Promise<void>,
    onCancel: () => void,
    selectedRowKeys: React.Key[]
}; 

const AssignManagerModal: FC<Props> = ({onCancel, onFinish, selectedRowKeys}) => {
    const assignManagerModalVisible = useAppSelector((state) => state.modals.assignManagerModalVisible);
    const managersData = useAppSelector((state) => state.coaches.managersData);
    const roles = useAppSelector((state) => state.auth.user!.roles);
    const coachesData = useAppSelector((state) => state.coaches.coachesData);
    const [form] = Form.useForm<{managerIdList: React.Key[]}>();

    const coachManagers = coachesData.filter((coach) => selectedRowKeys.includes(coach.id));

    const defaultSelectValue = selectedRowKeys.length === 1 &&
    managersData.filter((manager) => 
    coachManagers.find((coachManager) => 
        coachManager.managers.find((assignedManager) => 
        assignedManager.id === manager.id)
    )).map(({id}) => id) || [];

    const onClose = () => {
        onCancel();
        form.resetFields();
    }

    useEffect(() => {
        form.setFieldsValue({
            managerIdList: defaultSelectValue
        });
    }, [selectedRowKeys])

    return (
        <Modal 
        title="Assign Manager"
        centered
        onCancel={onClose}
        onOk={() => {
            form
            .validateFields()
            .then((values) => {
                onFinish(values)
                onCancel();
            })
        }}
        visible={assignManagerModalVisible}
        >
            <Form
            form={form}
            >
                <Form.Item
                name="managerIdList"
                rules={[{
                            required: true,
                            message: 'Please choose a manager'
                }]}       
                >
                    <Select
                        removeIcon={roles === admin ? <CloseOutlined /> : null}
                        mode="multiple"
                        defaultValue={defaultSelectValue}
                        placeholder="Choose a manager"
                    >
                        {
                            managersData.length > 0 && 
                            managersData.map(({fullName, id}) => (
                                <Select.Option value={id}>{fullName}</Select.Option>
                            ))
                        }
                    </Select>
                </Form.Item>
            </Form>   
        </Modal>
    )

};


export default AssignManagerModal;