import React, { useEffect } from 'react';
import { useAppSelector } from 'redux/store';
import { Input, PageHeader, Space, Table, Tag, Button } from 'antd';
import { useManagersApiWithRedux } from 'services/managers-service';
import {AuditOutlined, BarsOutlined} from '@ant-design/icons';
import { useParams } from 'react-router-dom';
import { admin } from 'vars';
import {getFormatDate} from "utils/helpers";
import { ColumnProps } from 'antd/es/table';
import { ICoach } from 'Interfaces/User';
import { ColumnType } from 'antd/lib/table';
import { SearchProps } from 'antd/lib/input';

const ManagerMainCoachList = () => {
    const roles = useAppSelector((state) => state.auth.user!.roles);
    const coachesData = useAppSelector((state) => state.managers.coachesData);
    const filteredCoachesData = useAppSelector((state) => state.managers.filteredCoaches);
    const {getCoachesData, onRedirectToCoachPage, onRedirectToCheckIns, onFilterCoaches} = useManagersApiWithRedux();
    const {id} = useParams() as {id: string};

    const statusRender: ColumnType<ICoach>['render'] = (disabled: boolean = true) => (
        <Tag
        color={disabled ? "volcano" : "green"}
        >
            {disabled ? "DISABLED" : "ACTIVE"}
        </Tag>
    );

    const onSearchCoaches: SearchProps['onSearch'] = (value) => {
        onFilterCoaches({coachesData, searchValue:value})
    }

    const extra = (
        <Space size="large" direction="horizontal" align="end">
            <Input.Search 
            placeholder="Search" 
            enterButton
            onSearch={onSearchCoaches}
            />
        </Space>
    );
    
    const actionsRender: ColumnType<ICoach>['render'] = (coach: ICoach) => (
        <Space size="middle">
            <Button
            shape="circle"
            type="primary"
            icon={<BarsOutlined />}
            onClick={() => onRedirectToCheckIns(coach)}
            />
            <Button
            shape="circle"
            type="primary"
            icon={<AuditOutlined />}
            onClick={() => onRedirectToCoachPage(coach)}
            />
        </Space>
    )

    const disabledFilterOptions = [{text: 'DISABLED', value: true}, {text: 'ACTIVE', value: false}];
    const disabledStatusFilter: ColumnType<ICoach>['onFilter'] = (value, record) => value === record.disabled;

    const columns: ColumnProps<ICoach>[] =[
        {
            title: "Full Name",
            dataIndex: "fullName",
            sorter: (a,b) => a.fullName.localeCompare(b.fullName)
        },
        {
            title: "Email",
            dataIndex: "email",
            sorter: (a,b) => a.email.localeCompare(b.email)
        },
        {
            title: "Start Date",
            dataIndex: "startDate",
            render: getFormatDate
        },
        {
            title: "Competing",
            dataIndex: "competing",
            className: "table-column-title-no-break"
        },
        {
            title: "Status",
            dataIndex: "disabled",
            render: statusRender,
            onFilter: disabledStatusFilter,
            filters: disabledFilterOptions
        },
        {
            title: "Action",
            fixed: "right",
            render: actionsRender
        },
    ]

    useEffect(() => {
        if(roles === admin){
            getCoachesData(id);
        }
    }, [id]);

    return (
        <>
        <PageHeader
        className="site-layout-background"
        extra={extra}
        />
        <Table
            dataSource={filteredCoachesData.length > 0 ? filteredCoachesData : coachesData}
            rowKey={rw => rw.id}
            columns={columns}
        />
        </>
    )
};


export default ManagerMainCoachList;