import React from 'react';
import {Space} from "antd";
import {Bar} from "react-chartjs-2";
import { ChartConfiguration } from 'chart.js';
// import type { Props } from 'react-chartjs-2/dist/types'; 

interface BarProps extends ChartConfiguration<'bar'> {
    title: React.ReactNode
}

export default function DashboardPageChartsBar({title = "", ...props}: BarProps){
    return (
        <Space className={'dashboard_chart_wrapper dashboard_chart_bar_wrapper'}>
            <h3>{title}</h3>
            <Bar {...props} />
        </Space>
    )
}