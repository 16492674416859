import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import { ICheckIn, ICheckInChartValues, CheckInsCountData } from 'Interfaces/CheckIn';
import React from 'react';
import { SortTable } from 'Types';

interface InitialState {
    checkInData: ICheckIn[],
    totalCount: number,
    checkInDataChart: ICheckInDataChart,
    checkInSelectedRowKeys: React.Key[],
    checkInSelectedRowData: ICheckIn[],
    checkInSelectedCounts: CheckInsCountData | null,
    isLoading: boolean,
    currentCheckIn: ICheckIn | null,
    coachFilterOptions: ICoachFilterOptions[],
    lastUpdate: number,
    checkInTableSettings: CheckInTableSettings,
};
interface ICoachFilterOptions {
    text: string,
    value: string,
};

interface ICheckInDataChart {
    hipsDataset: (number | null)[],
    checkIns: ICheckIn[],
    weightDataset: (number | null)[],
    waistDataset: (number | null)[],
    waistWithOrderNotAuto: (ICheckInChartValues | null)[],
    waistWithOrderAuto: (ICheckInChartValues | null)[],
    waistLabelsOrder: number[],
    hipsLabelsOrder: number[],
    hipsWithOrderAuto: (ICheckInChartValues | null)[],
    hipsWithOrderNotAuto: (ICheckInChartValues | null)[],
    weightLabelsOrder: number[],
    weightWithOrderAuto:(ICheckInChartValues | null)[],
    weightWithOrderNotAuto: (ICheckInChartValues | null)[],
    hipsDatasetAutoGenerated: (number | null)[],
    waistDatasetAutoGenerated: (number | null)[],
    weightDatasetAutoGenerated: (number | null)[],
    allWeight: (number)[],
    allWaist: (number)[],
    allHips: (number)[],
    hipsStarted: number,
    weightStarted: number,
    waistStarted: number,
    lowestWeight: number,
    lowestHips: number,
    lowestWaist: number,
    labels: number[]
};

export type CheckInTableSettings = {
    pageSize?: number,
    page?: number,
    s_field?: string | null | React.Key,
    s_direction?: SortTable | null,
    f_clientName?: string | null,
    f_coachId?: string[] | null,
    f_startDate?: string | null,
    f_endDate?: string | null,
    f_menstruating?: boolean[] | null,
    f_lowest?: string[] | null,
    f_userId?: string[] | null,
    f_sick?: boolean[] | null,
    f_feedbackGiven?: boolean[] | null,
    f_late?: boolean[] | null,
    f_program?: string[] | null,
    f_autoGenerated?: boolean[] | null,
    f_pause?: boolean[] | null,
    delta?: boolean
};

const initialState = {
    checkInData: [],
    checkInDataChart: {
        checkIns: [],

        waistWithOrderNotAuto: [],
        waistWithOrderAuto: [],
        waistLabelsOrder: [],
        hipsLabelsOrder: [],
        hipsWithOrderAuto: [],
        hipsWithOrderNotAuto: [],
        weightLabelsOrder: [],
        weightWithOrderAuto: [],
        weightWithOrderNotAuto: [],


        hipsDatasetAutoGenerated: [],
        waistDatasetAutoGenerated: [],
        weightDatasetAutoGenerated: [],
        allHips: [],
        allWaist: [],
        allWeight: [],
        hipsStarted: 0,
        weightStarted: 0,
        waistStarted: 0,
        hipsDataset: [],
        weightDataset: [],
        waistDataset: [],
        lowestWeight: 0,
        lowestHips: 0,
        lowestWaist: 0,
        labels: [],
    },
    checkInSelectedRowKeys:[],
    checkInSelectedRowData:[],
    checkInSelectedCounts: null,
    currentCheckIn: null,
    coachFilterOptions: [],
    totalCount: 0,
    isLoading: true,
    checkInTableSettings: {
        pageSize: 100,
        page: 1,
        s_field: null,
        s_direction: null,
        f_clientName: null,
        f_startDate: null,
        f_endDate: null,
        f_menstruating: null,
        f_sick: null,
        f_feedbackGiven: null,
        f_lowest: null,
        f_late: null,
        f_userId: null,
        f_program: null,
        f_coachId: null,
        f_autoGenerated: null,
    },
    lastUpdate: Date.now()
} as InitialState;


export interface ISetCheckins {
    checkIns: ICheckIn[],
    totalCount: number,
};

interface ISetCurrentCheckIn {
    checkInSelectedRowKeys: React.Key[],
    checkInSelectedRowData: ICheckIn[],
    counts: CheckInsCountData | null,
};



const checkInsReducer = createSlice({
    name: 'checkInsReducer',
    initialState,
    reducers: {
        setCheckIns: (state, action: PayloadAction<ISetCheckins>) => {
            state.isLoading = false;
            state.checkInData = action.payload.checkIns
            state.totalCount = action.payload.totalCount
        },
        setCurrentCheckIn: (state, action: PayloadAction<ICheckIn | null>) => {
          state.currentCheckIn = action.payload;
        },
        setCheckInSelectedRow: (state, action: PayloadAction<ISetCurrentCheckIn>) => {
            state.checkInSelectedRowKeys  = action.payload.checkInSelectedRowKeys;
            state.checkInSelectedRowData  = action.payload.checkInSelectedRowData;
            state.checkInSelectedCounts  = action.payload.counts;
        },
        setCheckInTableSettings: (state, action: PayloadAction<CheckInTableSettings>) => {
            state.checkInTableSettings = {...state.checkInTableSettings, ...action.payload}
        },
        setCheckInCoachFilterOptions: (state, action: PayloadAction<{coachFilterOptions: ICoachFilterOptions[]}>) => {
            state.coachFilterOptions = action.payload.coachFilterOptions
        },
        setCheckInTableUpdate: (state, action: PayloadAction<{lastUpdate: number}>) => {
            state.lastUpdate = action.payload.lastUpdate
        },
        setIsLoadingCheckIns: (state, action: PayloadAction<boolean>) => {
          state.isLoading = action.payload;
        },
        setCheckInsChart: (state, action: PayloadAction<ICheckInDataChart>) => {
            state.checkInDataChart = action.payload;
            state.isLoading = false;
        },
        setUpdateFeedbackGivenColumn: (state, action: PayloadAction<React.Key[]>) => {
          state.checkInData = state.checkInData.map((checkIn) => action.payload.find(id => id === checkIn.id) ? ({
              ...checkIn,
              feedbackGiven: true
          }) : checkIn)
        },
        checkInsReducerReset: (state) => {state = initialState}
    }
})

export const {setCheckIns, setIsLoadingCheckIns, setUpdateFeedbackGivenColumn, setCheckInsChart, setCurrentCheckIn, setCheckInSelectedRow, setCheckInTableSettings, setCheckInCoachFilterOptions, setCheckInTableUpdate, checkInsReducerReset} = checkInsReducer.actions
export default checkInsReducer.reducer


