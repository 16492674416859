import {useEffect} from "react";
import {useDispatch} from "react-redux";
import useApi from "../hooks/useApi";
import {Logger} from "../utils/helpers";
import {
    messageTemplatesReducerReset,
    setMessageTemplates,
    setSelectedTemplates
} from "../redux/reducers/messageTemplatesReducer";
import {IMessageTemplate} from "../Interfaces/MessageTemplates";

const logger = Logger("MessageTemplatesApiWithRedux")

function useMessageTemplatesApiWithRedux() {
    const {API, cancel} = useApi()
    const dispatch = useDispatch()

    const getMessageTemplatesData = async () => {
        try {
            const response = await API.get<IMessageTemplate[]>('/api/message_templates/list');
            return response.data
        } catch (e) {
            if(e instanceof Error){
                logger.error(e.message)
            }
        }
    }

    const getMessageTemplates = async () => {
        const data = await getMessageTemplatesData()
        if (Array.isArray(data)) {
            dispatch(setMessageTemplates({messageTemplatesData: data}))
            const activeData = data.filter(message => !!message.active)
            const formatedData = activeData.map(message => ({label: message.name, value: message.id}))
            return formatedData.sort((a, b) => a.label.localeCompare(b.label))
        }
        return []
    }
    
    const setTemplates = (templates: string[]) => {
        if (templates) {
            dispatch(setSelectedTemplates({selectedTemplates: templates}))
        }

    }

    useEffect(() => () => {dispatch(messageTemplatesReducerReset())}, [])

    return {
        messageTemplatesApiCancel: cancel,
        getMessageTemplates,
        setTemplates
    }
}

export default useMessageTemplatesApiWithRedux;