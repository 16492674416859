import React from 'react';
import {Radio, RadioGroupProps} from "antd";
import {LAST_MONTH, LAST_WEEK, THIS_WEEK} from "vars";
import { DateRange } from 'Types';

interface Props {
    isLoading: boolean,
    value: DateRange,
    onChange: RadioGroupProps['onChange']
};

export default function DateRangeButtonGroup({isLoading, value, onChange}: Props){
    return (
            <Radio.Group value={value} buttonStyle="solid" onChange={onChange}
                         disabled={isLoading}>
                <Radio.Button value={THIS_WEEK}>This Week</Radio.Button>
                <Radio.Button value={LAST_WEEK}>Last Week</Radio.Button>
                <Radio.Button value={LAST_MONTH}>Last Month</Radio.Button>
            </Radio.Group>
    )
}