import React, { useMemo } from 'react';
import { useAppSelector } from 'redux/store';
import {Tag} from "antd";
import {
    complianceFilterOptions,
    lateFilterOptions,
    programFilterOptions,
    yesNoFilterOptions
} from "./check_in_table_config_options";
import {
    ActionsRender,
    coachRender,
    feedbackRender,
    lowestRender,
    pausedNotificationsRender,
    programRender
} from "./check_in_table_config_render";
import {
    changeSortDirectionTitle,
    getFormatDateWithHours,
    getPopoverTextWithIcon
} from "utils/helpers";
import { ColumnProps } from 'antd/lib/table';
import { ICheckIn } from 'Interfaces/CheckIn';
import { SortTable } from 'Types';

const CheckInTableConfig = () => {
    const coachFilterOptions = useAppSelector(state=>state.checkIns.coachFilterOptions);
    const clientTypesData = useAppSelector((state) => state.clientTypes.clientTypes);

    const clientTypesFilterOptions = useMemo(() => clientTypesData.filter(({enabled}) => enabled).map(({id, name}) => ({
        value: id,
        text: name
    })), [clientTypesData]);
    const {f_menstruating,f_sick,f_feedbackGiven,f_late, f_program, f_lowest,f_coachId, s_field, s_direction, f_pause} = useAppSelector(state => state.checkIns.checkInTableSettings)
    const getColumns: ColumnProps<ICheckIn>[] = [
            {
                title: 'Date',
                key: 'date',
                dataIndex:"date",
                render: getFormatDateWithHours,
                sorter:true,
                sortOrder: s_field === 'date'? (changeSortDirectionTitle(s_direction) as SortTable): null
            },
            {
                title: 'Full Name',
                dataIndex: ["User", "fullName"],
                key: 'fullName',
                render: (_,r) => getPopoverTextWithIcon(r.User.fullName),
                sorter:true,
                sortOrder: s_field === 'fullName'?(changeSortDirectionTitle(s_direction) as SortTable): null
            },
            {
                title: 'Compliance',
                dataIndex: "lowest",
                key: 'f_lowest',
                render:(lowest) => lowestRender(lowest),
                filters:complianceFilterOptions,
                filteredValue:f_lowest,
            },
            {
                title: 'Menstruating',
                dataIndex: 'menstruating',
                key: 'f_menstruating',
                render:(text, record) => !!record.menstruating && <Tag color="red">Yes</Tag>,
                filters:yesNoFilterOptions,
                filteredValue: f_menstruating
            },
            {
                title: 'Sick',
                dataIndex: 'sick',
                key: 'f_sick',
                render: (text, record) => !!record.sick && <Tag color="red">Yes</Tag>,
                filters:yesNoFilterOptions,
                filteredValue:f_sick
            },
            {
                title: 'Feedback',
                dataIndex: 'feedbackGiven',
                key: 'f_feedbackGiven',
                render: feedbackRender,
                filters:yesNoFilterOptions,
                filteredValue:f_feedbackGiven
            },
            {
                title: 'Last Updated',
                dataIndex: "lastUpdated",
                key: 'lastUpdated',
                render: getFormatDateWithHours,
                sorter:true,
                sortOrder: s_field === 'lastUpdated'?(changeSortDirectionTitle(s_direction) as SortTable) : null
            },
            {
                title: 'Late',
                dataIndex: "late",
                key: 'f_late',
                render: (text, record)=>!!record.late?<Tag color="volcano">Late</Tag>:<Tag color="green">OK</Tag>,
                filters:lateFilterOptions,
                filteredValue:f_late,
                sorter:true,
                sortOrder: s_field === 'late'? (changeSortDirectionTitle(s_direction) as SortTable) : null
            },
            {
                title: 'Notifications',
                dataIndex: 'pauseNotifications',
                key: 'f_pause',
                render: pausedNotificationsRender,
                filters: [{text: 'Yes', value: false}, {text: 'No', value: true}],
                filteredValue: f_pause,
            },
            {
                title: 'Program',
                dataIndex: "program",
                key: 'f_program',
                render: programRender,
                filters:[{text: 'Competing', value: 'Competing'}, ...clientTypesFilterOptions],
                filteredValue:f_program
            },
            {
                title: 'Coach',
                dataIndex: "Coach",
                key: 'f_coachId',
                render: (text, record) => getPopoverTextWithIcon(coachRender(text, record)),
                filters:coachFilterOptions,
                filteredValue:f_coachId
            },
            {
                fixed:"right",
                title: 'Action',
                dataIndex: "action",
                key: 'Action',
                render: ActionsRender,
            },
        ]


    return {getCheckInColumns: getColumns}
};

export default CheckInTableConfig
