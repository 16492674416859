import React, { FC } from 'react';
import { Typography } from 'antd';
import { Modal } from 'components/Antd/Modal';
import { useAppSelector } from 'redux/store';

interface DeleteTaskModalProps {
    onCancel: () => void,
    onFinish:  () => Promise<void>
}

const DeleteTaskModal: FC<DeleteTaskModalProps> = ({ onCancel, onFinish }) => {
    const isVisible = useAppSelector((state) => state.modals.removeTaskModalVisible);
    return (
        <Modal 
            visible={isVisible}
            centered
            onCancel={onCancel}
            onOk={onFinish}
            title="Remove Task"
        >
            <Typography.Paragraph>
                Are you sure you want to delete the task?
            </Typography.Paragraph>
        </Modal>
    )

};

export default DeleteTaskModal;
