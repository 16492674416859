import {Col, ColProps, Row} from "antd";
import { ComponentType } from "react";

interface Props extends ColProps {

}

const withDashboardCol = <P extends {}>(Component: ComponentType<P>): ComponentType<P & Props> => (props) => (
    <Col xl={props.xl || 12} md={props.md || 24} xs={props.xs || 24}>
        <Row className={"dashboard_widget_column_row"}>
            <Col span={24}>
                <Component {...props} />
            </Col>
        </Row>
    </Col>
)

export default withDashboardCol