import React from 'react';
import {Space, DatePicker, PageHeader, Table, Button} from "antd";
import {ArrowLeftOutlined, ArrowRightOutlined} from "@ant-design/icons";
import useFoodDiaryService from "./FoodDiary-service";
import moment from "moment";
import { DATE_FORMAT } from 'vars';


const FoodDiary = () => {

    const {
        Columns,
        onChangeDate,
        dateValue, 
        isPrimaryForward, 
        isPrimaryBack,
        onChangeBack,
        onChangeForward, 
        foodDiaryData, 
        isLoading
    } = useFoodDiaryService();
    const DateRanger = (
        <Space size={0}>
        <Button
            style={{width: 40}}
            disabled={isLoading}
            type={isPrimaryBack}
            onClick={onChangeBack}
            icon={<ArrowLeftOutlined />}
        />
        <DatePicker
            value={dateValue}
            disabled={isLoading}
            allowClear={false}
            onChange={onChangeDate}
            // disabledDate={(current) => current.isAfter(moment().subtract(0,"day"))}
            format={DATE_FORMAT}
        />
        <Button
            style={{width: 40}}
            type={isPrimaryForward}
            disabled={isLoading}
            onClick={onChangeForward}
            icon={<ArrowRightOutlined />}
        />
        </Space>
    )


    return (
        <Space direction="vertical">
            <PageHeader
            style={{padding: 0}}
            title="Food Diary"
            extra={DateRanger}
            />
           <Table
           loading={isLoading}
           pagination={false}
           columns={Columns}
           dataSource={foodDiaryData}
           />
        </Space>
    )

};


export default FoodDiary;