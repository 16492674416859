import {TOGGLE_SLIDER_MENU, CLOSE_SLIDER_MENU, CURRENT_PAGE, OPEN_SLIDER_MENU} from '../actions/commonActions'


const initialState = {isSliderMenuOpen: false, currentPage: '/'}

export const commonReducer = (state = initialState, {type, payload = {}}: {type: string, payload: any}) => {
  switch (type) {
    case TOGGLE_SLIDER_MENU:
      return {...state, isSliderMenuOpen: !state.isSliderMenuOpen}

    case CLOSE_SLIDER_MENU:
      return {...state, isSliderMenuOpen: false}

    case OPEN_SLIDER_MENU:
      return {...state, isSliderMenuOpen: !state.isSliderMenuOpen}

    case CURRENT_PAGE:
      return {...state, currentPage: payload.pathname, isSliderMenuOpen: false}

    default:
      return state
  }
}
