import React from "react";
import { useAppSelector } from "redux/store";
import { Typography } from 'antd';
import antNotificationComponent from "../../utils/notification-ant-utils";
import { ISuccessStatus } from "Interfaces/Auth";
import { Modal } from "components/Antd/Modal";
const { Text } = Typography;

export interface IRemoveCoachModalProps {
    title?: string,
    message?: string,
    onCancel: () => void,
    handleOk: () => Promise<ISuccessStatus | undefined>
};

function RemoveCoachModal({handleOk, onCancel, title ='', message = ''}: IRemoveCoachModalProps): JSX.Element{
    const removeCoachModalVisible = useAppSelector(state => state.modals.removeCoachModalVisible)

    const onOk = async () => {
        const data = await handleOk()
        if (!!data) {
            onCancel()
            antNotificationComponent({type: "success", message: 'Saved'})
        }else {
            antNotificationComponent({type: "error"})
        }
    }

    return(
        <Modal
            centered
            title="Remove coach"
            visible={removeCoachModalVisible}
            onOk={onOk}
            onCancel={onCancel}
        >
            <Text>{title}</Text>
            <p>{message}</p>
        </Modal>
    )
}

export default RemoveCoachModal

