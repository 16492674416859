import React, { FC } from "react";
import {List} from "antd";
import ActivityFilterCheckbox from "./ActivityFilterCheckbox";
import {getSubstringText} from "../../utils/helpers";
import { IClient } from "Interfaces/User";

interface Props {
    dataSource: IClient[],
    checkedData: string[],
    setCheckedData: React.Dispatch<React.SetStateAction<string[]>>
}

const ActivityUsersFilterComponent: FC<Props> = ({
                                          checkedData,
                                          setCheckedData,
                                          dataSource,
                                      }) => {
    function handleChange(checked: boolean, value: string) {
        if (checked) {
            return setCheckedData(state => [...state.filter((id) => id !== value)])
        }
        return setCheckedData(state => [...state, value]);
    }


    return (

        <>
            <div className="activity_client_filter_list_wrapper">
                <List
                    className="activity_client_filter_list"
                    size="small"
                    bordered
                    dataSource={dataSource}
                    renderItem={user => (
                        <List.Item className={'ant-dropdown-menu-item'}>
                            <ActivityFilterCheckbox
                                checked={checkedData.some((id) => id === user.id)} label={getSubstringText(user.fullName)}
                                onChange={handleChange} value={user.id}/>
                        </List.Item>
                    )}
                />
            </div>
        </>
    )
}

export default ActivityUsersFilterComponent