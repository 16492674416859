import React, {useEffect} from 'react';
import {withRouter, Redirect} from "react-router";
import {connect, ConnectedProps} from 'react-redux';
import {compose, Logger} from '../utils/helpers';
import {checkAuth} from "../services/authService";
import {Route} from 'react-router-dom';
import LoadingSpin from "../components/loading-spin/LoadingSpin";
import { RootState } from 'redux/store';
import { FixMeLater } from 'Types';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from '@reduxjs/toolkit';
import {Location} from "history"


const logger = Logger("HOCs/ProtectedRoute.js")

interface Props {
    component: typeof React.Component | React.ElementType,
    path: string,
    location: Location,
    checkAuth: () => void,
    isLoading: boolean,
    prevLocation: string,
    isAuth: boolean,
};

const ProtectedRoute = (props: Props) => {
    const {component: Component, path, location: {pathname}, checkAuth, isLoading, prevLocation, isAuth} = props

    useEffect(() => {
        checkAuth()
    }, [pathname])

    const isRouterChange = () => prevLocation !== pathname

    logger.debug("Rendering isAuth: ", isAuth);

    if (isLoading || isRouterChange()) {
        return (
            <LoadingSpin/>
        )
    }



    return (
        <Route exact path={path}>
            {!isAuth ? <Redirect to="login"/> : <Component {...props}/>}
        </Route>
    )
}

const mapStateToProps = ({auth: {isLoading, prevLocation, isAuth}}: RootState) => {
    return {isLoading, prevLocation, isAuth};
};

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, {}, AnyAction>, {location}: FixMeLater) => {
    return {
        checkAuth: () => dispatch(checkAuth(location.pathname))
    }
}

const connector = connect(mapStateToProps, mapDispatchToProps);

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(ProtectedRoute);

