import React from 'react';
import DashboardPageChartsPie from "./DashboardPageChartsPie";
import {shallowEqual} from "react-redux";
import NoData from "components/no-data/NoData";
import {getChartLabelPercentage} from "utils/helpers";
import {SHOW_PERCENTAGE_CHART} from "vars";
import { useAppSelector } from 'redux/store';
import Chart, { ChartConfiguration, TooltipItem, TooltipModel } from 'chart.js/auto';
import { ChartData, ChartOptions } from 'chart.js';
import { Props as PieProps } from 'react-chartjs-2/dist/types';

export default function DashboardPageComplianceOverallChartsPie() {
    const chartsPieClientsData = useAppSelector(state => state.dashboard.chartsPieClientsData, shallowEqual)


    const getChartsColor = (type: string) => {
        switch (type) {
            case 'Training': {
                return '#ffb8e9'
            }
            case 'Food': {
                return '#fdd7af'
            }
            case 'Sleep': {
                return '#9ea3f5'
            }
            case 'Water': {
                return '#98e3ef'
            }
            case 'Stress': {
                return '#ffb6b6'
            }
            case 'Ok': {
                return '#c2ec9d'
            }
            case 'Late': {
                return '#b6b5b5'
            }
            case 'Not checkin': {
                return '#e66060'
            }
            default: {
                return '#' + (Math.random().toString(16) + '000000').substring(2, 8).toUpperCase()
            }
        }
    }

    const getChartsOffset = ({type, value}: {type: string, value: string | number}) => {
        switch (type) {
            case 'Ok':
            case 'Late': {
                return +value < 100?60:0
            }
            default: {
                return 0
            }
        }
    }

    const complianceOverallPieOptions: ChartConfiguration<'pie'>['options'] = {
        responsive: true,
        plugins: {
            tooltip: {
                callbacks: {
                    label: getChartLabelPercentage
                }
            },
            legend: {
                position: 'bottom',
                align: 'start',
                labels: {padding: 20},
            },
            datalabels: {
                color: '#000',
                // rotation: -45,
                formatter: function (_, {dataIndex}) {
                    return chartsPieClientsData[dataIndex].value >= SHOW_PERCENTAGE_CHART ? chartsPieClientsData[dataIndex].value + '%' : null
                },

            },

        },
    }

    const complianceOverallPieData: PieProps['data'] = {
        labels: chartsPieClientsData.map((elem) => elem.type),
        datasets: [
            {
                label: chartsPieClientsData.map((elem) => elem.type),
                data: chartsPieClientsData.map((elem) => Number(elem.quantity)),
                backgroundColor: chartsPieClientsData.map(d => getChartsColor(d.type)),
                borderColor: 'none',
                borderWidth: 0,
                spacing: 0,
                offset: chartsPieClientsData.map(({type, value}) => getChartsOffset({type, value})),
            },
        ],
    };

    const complianceOverallPieProps: PieProps = {
        type: 'pie',
        data: complianceOverallPieData,
        options: complianceOverallPieOptions,
        width: 410,
        height: 410,
    }

    return (chartsPieClientsData.every(elem => !elem.value) ?
        <NoData description="Compliance overall (No Data)"/> :
        <DashboardPageChartsPie title={'Compliance overall'} {...complianceOverallPieProps}/>)
}