import React, { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Tabs, Spin } from 'antd';
import { Line } from 'react-chartjs-2';
import { linearRegression, linearRegressionLine } from 'simple-statistics';
import { calculate_R2_PValue } from 'utils/helpers';
import { useAppSelector } from 'redux/store';
import { ChartConfiguration, ChartData, ChartOptions, ScatterDataPoint } from 'chart.js';
import { ICheckInChartValues } from 'Interfaces/CheckIn';

const getOptionsChart: ChartOptions<'line'> = {
    scales: {
        x: {
            grid: {
                display: false
            }
        },
        y: {
            grid: {
                display: false
            }
        }
    },
    plugins: {
        legend:{
            display: false
        },
        tooltip: {
            callbacks: {
                label: ({dataset: {label, data}, dataIndex}) => {
                    if(label === "Regression"){  
                                return `${label}: ${(data[dataIndex] as ScatterDataPoint).y || 0}`
                    }
                    return `${label}: ${data[dataIndex] || 0}`
                },
            }
        },
    }
};

const getLinearRegressionData = (data: number[] = [], labels: number[] = []) => {
    if(!data.length){
        return {
            R2: "",
            pValue: "",
            linearRegressionData: [],
        };
    };
    const XAxis = [1, 2, 3, 4, 5, 6, 7, 8]; 
    const YAxis = data.map((y, index) => [ XAxis[index], y || 0 ]);
    const _linearRegression = linearRegression(YAxis);
    const _linearRegressionLine = linearRegressionLine(_linearRegression);
    const {R2, pValue} = calculate_R2_PValue(data, XAxis);
    const linearRegressionData = [
        { x: labels[0], y: _linearRegressionLine(YAxis[0][0]) },
        { x: labels[labels.length - 1], y: _linearRegressionLine(YAxis[YAxis.length - 1][0]) }
    ];
    return {
        R2,
        pValue,
        linearRegressionData,
    };
};

interface ILinearReggresionOrderWeeksProps {
    getCheckInChart: () => Promise<void>
}

const LinearReggresionOrderWeeks: FC<ILinearReggresionOrderWeeksProps> = ({getCheckInChart}) => {
    const checkInsData = useAppSelector(state => state.checkIns.checkInDataChart);
    const isLoading = useAppSelector((state) => state.checkIns.isLoading);
    
    const regressionWaist = getLinearRegressionData(checkInsData.allWaist, checkInsData.waistLabelsOrder);
    const dataWaistWithOrder: ChartData<'line'> = {
        labels: checkInsData.weightLabelsOrder,
        datasets: [
            {
                type: 'line',
                label: 'Waist',
                data: checkInsData.waistWithOrderNotAuto.map((i) => i?.y || null),
                borderColor: 'rgb(99,255,125)',
                backgroundColor: 'rgb(99,255,125)',
                showLine: false
            },
            {
                type: 'line',
                label: 'Waist-Auto',
                data: checkInsData.waistWithOrderAuto.map((i) => i?.y || null),
                borderColor: 'rgb(255, 99, 132)',
                backgroundColor: 'rgb(255, 99, 132)',
                showLine: false
            },
            {
                type: 'line',
                label: 'Started Waist',
                data: new Array(checkInsData.labels.length).fill(+checkInsData.waistStarted),
                borderColor: '#848484',
                backgroundColor: '#848484',
                borderWidth: 1,
                pointRadius:0
            },
            {
                type: 'line',
                label: 'Waist Smallest',
                data: new Array(checkInsData.labels.length).fill(+checkInsData.lowestWaist),
                borderColor: '#848484',
                backgroundColor: '#848484',
                borderWidth: 1,
                pointRadius:0
            },
            {
                type: 'line',
                label: 'Regression',
                data: regressionWaist.linearRegressionData,
                borderColor: 'rgb(15,52,79)',
                backgroundColor:  'rgb(15,52,79)',
                order: -1,
                borderWidth: 2,
                pointRadius:0
            },
        ]
    }

    const regressionHips = getLinearRegressionData(checkInsData.allHips, checkInsData.hipsLabelsOrder);
    const dataHipsWithOrder: ChartData<'line'> = {
        labels: checkInsData.hipsLabelsOrder,
        datasets: [
            {
                type: 'line',
                label: 'Hips',
                data: checkInsData.hipsWithOrderNotAuto.map((i) => i?.y || null),
                borderColor: 'rgb(99,255,125)',
                backgroundColor: 'rgb(99,255,125)',
                showLine: false
            },
            {
                type: 'line',
                label: 'Hips-Auto',
                data: checkInsData.hipsWithOrderAuto.map((i) => i?.y || null),
                borderColor: 'rgb(255, 99, 132)',
                backgroundColor: 'rgb(255, 99, 132)',
                showLine: false
            },
            {
                type: 'line',
                label: 'Started Hips',
                data: new Array(checkInsData.labels.length).fill(+checkInsData.hipsStarted),
                borderColor: '#848484',
                backgroundColor: '#848484',
                borderWidth: 1,
                pointRadius:0
            },
            {
                type: 'line',
                label: 'Hips Smallest',
                data: new Array(checkInsData.labels.length).fill(checkInsData.lowestHips),
                borderColor: '#848484',
                backgroundColor: '#848484',
                borderWidth: 1,
                pointRadius:0
            },
            {
                type: 'line',
                label: 'Regression',
                data: regressionHips.linearRegressionData,
                borderColor: 'rgb(15,52,79)',
                backgroundColor:  'rgb(15,52,79)',
                order: -1,
                borderWidth: 2,
                pointRadius:0
            },
        ]
    }

    const regressionWeight = getLinearRegressionData(checkInsData.allWeight, checkInsData.weightLabelsOrder);
    const dataWeightWithOrder: ChartData<'line'> = {
        labels: checkInsData.weightLabelsOrder,
        datasets: [
            {
                type: 'line',
                label: 'Weight',
                data: checkInsData.weightWithOrderNotAuto.map((i) => i?.y || null),
                borderColor: 'rgb(99,255,125)',
                backgroundColor: 'rgb(99,255,125)',
                showLine: false
            },
            {
                type: 'line',
                label: 'Weight-Auto',
                data: checkInsData.weightWithOrderAuto.map((i) => i?.y || null),
                borderColor: 'rgb(255, 99, 132)',
                backgroundColor: 'rgb(255, 99, 132)',
                showLine: false
            },
            {
                type: 'line',
                label: 'Started Weight',
                data: new Array(checkInsData.labels.length).fill(+checkInsData.weightStarted),
                borderColor: '#848484',
                backgroundColor: '#848484',
                borderWidth: 1,
                pointRadius:0,
            },
            {
                type: 'line',
                label: 'Weight Smallest',
                data: new Array(checkInsData.labels.length).fill(+checkInsData.lowestWeight),
                borderColor: '#848484',
                backgroundColor: '#848484',
                borderWidth: 1,
                pointRadius:0
            },
            {
                type: 'line',
                label: 'Regression',
                data: regressionWeight.linearRegressionData,
                borderColor: 'rgb(15,52,79)',
                backgroundColor:  'rgb(15,52,79)',
                borderWidth: 2,
                pointRadius:0
            },
        ]
    }

    useEffect(() => {
        getCheckInChart()
    }, [])

    if(isLoading){
        return <div className="loader-container"><Spin /></div>
    }

    return (
        <Tabs tabPosition="right" defaultActiveKey="Weight">
            <Tabs.TabPane tab="Weight" key="Weight">
                <Line 
                    options={getOptionsChart}
                    data={dataWeightWithOrder} 
                />
            </Tabs.TabPane>
            <Tabs.TabPane tab="Waist" key="Waist">
                <Line 
                    options={getOptionsChart} 
                    data={dataWaistWithOrder} 
                />
            </Tabs.TabPane>
            <Tabs.TabPane tab="Hips" key="Hips">
                <Line 
                    options={getOptionsChart} 
                    data={dataHipsWithOrder} 
                />
            </Tabs.TabPane>
        </Tabs>
    )
};

export default LinearReggresionOrderWeeks;