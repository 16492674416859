import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import CoachDateRangeRow from "./CoachDateRangeRow";
import CoachInfoRow from "./CoachInfoRow";
import CoachClientsWidgetCardsRow from "./CoachClientsWidgetCardsRow";
import CoachTableSwitchRow from "./CoachTableSwitchRow";
import CoachMainSwitchList from "./CoachMainSwitchList";
import CoachInfoCardsRow from "./CoachInfoCardsRow";
import {dataNotification} from "../../utils/notification-ant-utils";
import { CHECKIN, CLIENT } from 'vars';
import { useAppSelector } from 'redux/store';
import { AxiosRequestConfig } from 'axios';
import { IUserPage } from 'Interfaces/User';
import { RadioChangeEvent } from 'antd';
import { IUpdateCoach } from 'services/coaches-service';
import { SelectedTable } from 'Types';

interface Props {
    updateCoach: (id: string, data: IUpdateCoach, config: AxiosRequestConfig | undefined) => Promise<IUserPage | undefined>,
    getAverageFeedbackTime: () => Promise<void>,
    getCoachCheckIns: () => Promise<void>,
    getCoachFeedbackGiven: () => Promise<void>,
    getCoachAverageCompliance: () => Promise<void>,
    getActiveClients: () => Promise<void>,
    handleChangeCoachDateRange: (e: RadioChangeEvent) => void,
    handleChangeTable: (e: RadioChangeEvent) => void
};

export default function CoachMainBody(props: Props) {
    const params = useParams() as {id: string };
    const selectedCoach = useAppSelector(state => state.coaches.selectedCoach) as IUserPage;
    const coachDateRangeName = useAppSelector(state => state.coaches.coachDateRangeName);
    const coachActiveClients = useAppSelector(state => state.coaches.coachActiveClients!)
    const selectedTable = useAppSelector(state => state.coaches.selectedTable);
    const averageCompliance = useAppSelector(state => state.coaches.averageCompliance);
    const coachAverageFeedbackTime = useAppSelector(state => state.coaches.coachAverageFeedbackTime);
    const coachCheckIns = useAppSelector(state => state.coaches.coachCheckIns);
    const coachFeedbackSent = useAppSelector(state => state.coaches.coachFeedbackSent);
    const [loading, setLoading] = useState<boolean>(true);

    const {
        updateCoach,
        getAverageFeedbackTime,
        getCoachCheckIns,
        getCoachFeedbackGiven,
        getCoachAverageCompliance,
        getActiveClients,
        handleChangeCoachDateRange,
        handleChangeTable
    } = props

    const handleChangeBio = async (value: string) => {
        if (selectedCoach?.bio !== value) {
            const data = await updateCoach(params.id, {bio: value}, undefined);
            dataNotification(data)
        }
    }

    const handleChangePhoto = async (value: string | null, config: AxiosRequestConfig | undefined, message?: string) => {
        if (selectedCoach?.photo !== value) {
            const data = await updateCoach(params.id, {photo: value}, config)
            dataNotification(data, message, 'Failed to Delete')
        }
    }

    const handleChangeSpecificArea = async (value: string) => {
        if (selectedCoach.specificArea !== value) {
            const data = await updateCoach(params.id, {specificArea: value}, undefined)
            dataNotification(data)
        }
    }

    const coachMainSwitchRows: {value: SelectedTable, text: string}[] = [
        {
            value: CLIENT,
            text: "Client List"
        },
        {
            value: CHECKIN,
            text: "Check In List"
        }
    ];

    const fetchCardData = [
        getAverageFeedbackTime,
        getCoachCheckIns,
        getCoachFeedbackGiven,
        getCoachAverageCompliance,
        getActiveClients
    ]

    useEffect(() => {
        let cleanupFunction = false;

        const fetchData = async () => {
            setLoading(true)
            await Promise.all(fetchCardData.map(fun => fun()))

            if (!cleanupFunction) {
                setLoading(false)
            }
        }

        if (!!selectedCoach?.id) {
            fetchData()
        }

        return () => {cleanupFunction = true}

    }, [selectedCoach, coachDateRangeName]);

    return (
        <>
            <CoachInfoRow
                fullName={selectedCoach?.fullName}
                alt="Coach photo"
                src={selectedCoach.photo !== null ? selectedCoach.photo : ''}
                text={selectedCoach.bio !== null ? selectedCoach.bio : ''}
                onChangeBio={handleChangeBio}
                onChangePhoto={handleChangePhoto}/>
            <CoachInfoCardsRow
                text={selectedCoach.specificArea !== null ? selectedCoach.specificArea : ''}
                onChange={handleChangeSpecificArea}
                joinedDate={!!selectedCoach.startDate ? selectedCoach.startDate : 'unknown'}
                activeClients={coachActiveClients}
            />
            <CoachDateRangeRow 
                isLoading={loading} 
                onChange={handleChangeCoachDateRange} 
                value={coachDateRangeName}
            />
            <CoachClientsWidgetCardsRow
                coachAverageFeedbackTime={coachAverageFeedbackTime}
                averageCompliance={averageCompliance}
                coachCheckIns={coachCheckIns}
                coachFeedbackSent={coachFeedbackSent}
            />
            <CoachTableSwitchRow 
                list={coachMainSwitchRows} 
                value={selectedTable} 
                onChange={handleChangeTable}
            />
            <CoachMainSwitchList 
                selectedTable={selectedTable}
            />
        </>
    )
}