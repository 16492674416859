import React, {FC, useEffect, useState} from 'react';
import {FETCH_CHAT_DELAY, FETCH_CHAT_PAGE_SIZE} from "vars";
import {ConversationList} from "@chatscope/chat-ui-kit-react";
import Conversation from "../Conversation";
import { IChat, IChatParams } from 'Interfaces/Chat';

export interface ContactListProps {
    currentUser: IChat | null,
    chatList: IChat[],
    totalCount: number,
    isLoading: boolean,
    onSetCurrentUser: (user: IChat) => void,
    updateReadCount: (user: IChat) => Promise<void>,
    putUnreadUserMessages: (user: IChat) => Promise<void>,
    getConversationList: (params?: IChatParams, loading?: boolean) => Promise<void>
    getMoreConversationList: (params: IChatParams) => Promise<void>
};

const ConversationList_ChatList: FC<ContactListProps> = ({
    getMoreConversationList, 
    getConversationList, 
    putUnreadUserMessages, 
    updateReadCount, 
    chatList, 
    totalCount, 
    isLoading, 
    currentUser, 
    onSetCurrentUser
}) => {
    const [page, setPage] = useState(1);
    const [isLoadingMore, setIsLoadingMore] = useState(false);

    const onYReachEnd = () => {
       return setIsLoadingMore(true)
    };

    const getMoreLoadPage = async () => {
        await getMoreConversationList({page: page + 1, pageSize: FETCH_CHAT_PAGE_SIZE});
        setTimeout(() => {
            setIsLoadingMore(false);
            setPage(p => p + 1);
        }, 500)
    };

    useEffect(() => {
        if(isLoadingMore && totalCount !== chatList.length){
            getMoreLoadPage();
        }
    }, [isLoadingMore]);


    useEffect(() => {
        getConversationList({page: 1, pageSize: FETCH_CHAT_PAGE_SIZE},true);
        const getIntervalConversation = setInterval(() => {
            getConversationList({page: 1, pageSize: FETCH_CHAT_PAGE_SIZE},false);
        }, FETCH_CHAT_DELAY);

        return () => {
            clearInterval(getIntervalConversation)
        }
    }, []);

    return (
        <ConversationList
            loading={isLoading}
            loadingMore={totalCount !== chatList.length ? isLoadingMore : false}
            scrollable
            onYReachEnd={onYReachEnd}
        >
            {chatList?.map((user, index) =>
                <Conversation
                    key={index}
                    user={user}
                    currentUser={currentUser}
                    putUnreadUserMessages={putUnreadUserMessages}
                    onSetCurrentUser={onSetCurrentUser}
                    updateReadCount={updateReadCount}
                />
            )}
        </ConversationList>
    )
};

export default ConversationList_ChatList;