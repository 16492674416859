import React from 'react';
import {Col, Row} from "antd";
import DateRangeButtonGroup from "../dashboard/DateRangeButtonGroup";
import { DateRange } from 'Types';
import { RadioButtonProps } from 'antd/lib/radio/radioButton';

interface Props {
    isLoading: boolean,
    value: DateRange
    onChange: RadioButtonProps['onChange']
};

export default function CoachDateRangeRow(props: Props){
    return (
        <Row className={'coach_main_body_row'}>
            <Col span={24} className={'coach_main_body_column_radio'}>
                <DateRangeButtonGroup {...props}/>
            </Col>
        </Row>
    )
}