import React from 'react';
import {MenuClickEventHandler} from "rc-menu/lib/interface"
import {useHistory, useLocation} from "react-router-dom";
import {Layout, Menu, MenuItemProps} from 'antd';
import {TeamOutlined, UserOutlined} from '@ant-design/icons';
import './coach.css'
import {getPopoverText} from "../../utils/helpers";
import { useAppSelector } from 'redux/store';

export default function CoachSidebar() {
    const {Sider} = Layout;
    const history = useHistory()
    const {pathname} = useLocation()
    const coachesData = useAppSelector(state => state.coaches.coachesData)

    const handleMenuClick: MenuClickEventHandler = ({key}) => history.push(`${key}`)

    const MenuItem = (prop: MenuItemProps) => {
        return (
            <Menu.Item {...prop}>
                {prop.title}
            </Menu.Item>
        )
    }
    return (
        <Sider theme={'light'} className={'coach_sidebar'}>
            <Menu selectedKeys={[pathname]} theme="light" mode="inline" onClick={handleMenuClick}>
                <MenuItem key="/coaches" icon={<TeamOutlined/>} title={'All'}/>
                {coachesData.length > 0 && coachesData.map((coach) => <MenuItem key={`/coach/${coach.id}`} icon={<UserOutlined/>}
                                                      title={getPopoverText(coach.fullName)}/>)}
            </Menu>
        </Sider>
    );
}