import React from 'react';
import {Form, Input} from "antd";
import {isOnlySpaces} from "../../utils/helpers";
import { Rule } from 'antd/lib/form';

const {TextArea} = Input

interface Props {
    key: React.Key | null | undefined
}

export default function NotesForm() {
 
    const noteRules: Rule[] = [
        {
            required: true,
            message: 'This field is required',
        },
        ({getFieldValue}) => ({
            validator(_, value) {
                if (!value || !isOnlySpaces(getFieldValue('notes'))) {
                    return Promise.resolve();
                }
                return Promise.reject(new Error('This field is required'));
            },
        }),]

    return (
        <Form.Item name="notes" label="Note" rules={noteRules}>
            <TextArea placeholder="Enter the note text here"
                      className="add_notes_textarea"
                      autoSize={{ minRows: 4, maxRows: 25 }}
            />
        </Form.Item>
    )
}