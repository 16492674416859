import {useEffect} from "react";
import useApi from "hooks/useApi";
import {
    dashboardReducerReset,
    setActiveClients,
    setAverageFeedbackTime,
    setChartsPieClientsData,
    setChartsPieCompletedData,
    setCheckInOverdue,
    setCoachClients,
    setCompletedDailyDataData,
    setComplianceDaily,
    setDateRangeName,
    setFeedbackGiven,
    setFeedbackOutstanding,
    setAverageCompanyCheckinCompleted,
    setAverageCompanyCheckinOverdue,
    setSelectCoachId,
    setStatsCheckins,
    setTotalData,
    setLastContactedMia,
    setTotalClients,
    setMiaAverageTotal,
    setAverageCompanyCompliance,
    setAverageCompanyStats,
    setAverageCompanyFeedbackGiven,
    setAverageCompanyFeedbackTime,
    setAverageCompanyMiaTotal,
    setAverageCompanyFeedbackOutStanding,
    setAverageCompanyClients,
    setAssignedCoaches,
    setClientsProgress,
    setClientsStatusWeeks
} from "redux/reducers/dashboardReducer";
import { getDateRange, Logger } from "utils/helpers";
import { useAppDispatch, useAppSelector } from "redux/store";
import { IUser } from "Interfaces/User";
import { IComplianceDaily, ITotalClients, ITypeValue, ITypeValueQuantity } from "Interfaces/Dashboard/CheckIn";
import { IClientProgress, IClientStatusWeek, ICoachOverview, IContactedMIA, IDashboardQueryParams, IGetCoachClient } from "Interfaces/Dashboard";
import { DateRange } from "Types";
import {coach, LAST_MONTH, LAST_WEEK, manager, THIS_WEEK} from "../vars";

const logger = Logger("DashboardApiWithRedux")

interface IDashboardDataQuery {
    f_dateBefore?: string,
    f_dateAfter?: string,
    f_coachId?: string[]
    managerId?: string[],
};

interface IDashboardDataParams {
    path: string,
    query?: IDashboardDataQuery | null,
    dateRange?: DateRange
};

function useDashboardApiWithRedux() {
    const {API, cancel} = useApi()
    const dispatch = useAppDispatch()
    const dateRangeName = useAppSelector(state => state.dashboard.dateRangeName)
    const selectedCoachId = useAppSelector(state => state.dashboard.selectedCoachId)
    const user = useAppSelector(state => state.auth.user!)

    const getDashboardData = async <T>({path, query = null}: IDashboardDataParams) => {
        const {f_dateBefore, f_dateAfter} = getDateRange(dateRangeName)
        let params: IDashboardDataQuery = query === null ? {f_dateBefore, f_dateAfter} : {...query}

        if (!!selectedCoachId) {
            params = {...params, f_coachId: [selectedCoachId]}
        }

        if (user.roles === coach) {
            params = {...params, f_coachId: [user.id]}
        }

        try {
            const response = await API.get<T>(`api/dashboard/${path}`, {params});
            return response.data
        } catch (e) {
            if(e instanceof Error){
                logger.error(e.message)
            }
        }
    }

    const getTotalClients = async () => {
        const response = await getDashboardData<ITotalClients>({path: 'total-clients'});
        if(!!response){
            dispatch(setTotalClients(response));
        }
    }

    const getAssignedCoaches = async () => {
              const response = await getDashboardData<ITypeValue>({path: 'active-coaches', query:{
                  managerId: [selectedCoachId as string]
                  }})
              if(!!response){
                  dispatch(setAssignedCoaches(response))
              }
    }

    const getTotal = async () => {
        const response = await getDashboardData<ITypeValue>({path: 'checkin/total'})
        if (!!response) {
            response.type = 'Check Ins Received'
            dispatch(setTotalData({totalData: response}))
        }
    }

    const getFeedbackGiven = async () => {
        const response = await getDashboardData<ITypeValue>({path: 'checkin/feedback-given'})
        if (!!response) {
            dispatch(setFeedbackGiven({feedbackGiven: response}))
        }
    }

    const getMiaAverageTotal = async () => {
        const response = await getDashboardData<ITypeValue>({path: 'checkin/mia-average-total'})
        if (!!response) {
            dispatch(setMiaAverageTotal({miaAverageTotal: response}))
        }
    }

    const getStatsCheckins = async () => {
        const response = await getDashboardData<ITypeValue[]>({path: 'checkin/stats'})
        if(!!response){
            dispatch(setStatsCheckins({statsCheckins: response}))
        }
    }

    const getCoachesOverview = async () => {
          const response = await getDashboardData({path: 'checkin/coach-overview'});
          if(!!response){

          }
    };

    const getActiveClients = async () => {
        const response = await getDashboardData<ITypeValue>({path: 'active-clients'})
        if (!!response) {
            dispatch(setActiveClients({activeClients: response}))
        }
    }

    const getLastContactedMia = async () => {
        const response = await getDashboardData<IContactedMIA[]>({path: 'checkin/mia-average'})
        if (!!response) {
            dispatch(setLastContactedMia({lastContactedMiaData: response}))
        }
    }

    const getChartClientsData = async () => {
        const response = await getDashboardData<ITypeValueQuantity[]>({path: 'checkin/compliance-overall'})
        if (!!response) {
            dispatch(setChartsPieClientsData({chartsPieClientsData: response}))
        }
    }

    const getAverageCompanyComplianceAverage = async () => {
      const response = await getDashboardData<ITypeValueQuantity[]>({path: 'checkin/average-compliance-overall'});
      if(!!response){
          dispatch(setAverageCompanyCompliance(response));
      }
    };

    const getChartCompletedData = async () => {
        const response = await getDashboardData<ITypeValueQuantity[]>({path: 'checkin/completed'})
        if (!!response) {
            dispatch(setChartsPieCompletedData({chartsPieCompletedData: response}))
        }
    }
    const getCoachClientsData = async () => {
        const response = await getDashboardData<{coaches: IGetCoachClient[], totalCount: number}>({path: 'coach-clients', query: {}})
        const responseOverview = await getDashboardData<ICoachOverview[]>({path: 'checkin/coach-overview'});

        const data = response?.coaches.map((coachClient) => {
            const idCoach = coachClient.Coach?.id || null;
            const nameCoach = coachClient.name || null;
            const findCoach = responseOverview?.find(({id}) => idCoach === id);

            if(!idCoach){
                const findCoachWithoutId = responseOverview?.find(({fullName}) => fullName === nameCoach);
                return {
                    ...findCoachWithoutId,
                    ...coachClient,
                }
            }

            if(findCoach){
            
                return {
                    ...findCoach,
                    count: coachClient.count
                }
            }
            return coachClient;
        }) as ICoachOverview[];
        if (!!response) {
            dispatch(setCoachClients({
                coachClientsData: data,
                coachClientsTotalCount: {type: 'Assigned Coaches', value: response.totalCount}
            }))
        }
    }

    const getAverageFeedbackTime = async () => {
        const response = await getDashboardData<ITypeValue>({path: 'checkin/average-feedback-time'})
        if (!!response) {
            response.type = 'Average Feedback Time'
            dispatch(setAverageFeedbackTime({averageFeedbackTime: response}))
        }
    }

    const getComplianceDaily = async () => {
        const response = await getDashboardData<IComplianceDaily>({path: 'checkin/compliance-daily'})
        if (!!response) {
            dispatch(setComplianceDaily({complianceDailyData: response}))
        }
    }

    const getCompletedDaily = async () => {
        const response = await getDashboardData<IComplianceDaily>({path: 'checkin/completed-daily'})
        if (!!response) {
            dispatch(setCompletedDailyDataData({completedDailyData: response}))
        }
    }
    const getCheckInOverdue = async () => {
        const response = await getDashboardData<ITypeValue>({path: 'checkin/overdue'})
        if (!!response) {
            dispatch(setCheckInOverdue({checkInOverdue: response}))
        }
    }
    const getFeedbackOutstanding = async () => {
        const response = await getDashboardData<ITypeValue>({path: 'checkin/feedback-outstanding'})
        if (!!response) {
            dispatch(setFeedbackOutstanding({feedbackOutstanding: response}))
        }
    }
    const handleChangeDateRange = (value: DateRange) => {dispatch(setDateRangeName(value))}
    const handleChangeCoachId = (value: string | null) => {dispatch(setSelectCoachId({selectedCoachId: value}))}

    const initDashboardFilters = ({selectedCoachId, dateRangeName}: IDashboardQueryParams)=>{
        !!dateRangeName && handleChangeDateRange(dateRangeName)
        !!selectedCoachId && handleChangeCoachId(selectedCoachId)
    }

    const getAverageCompanyMiaTotal = async () => {
        const response = await getDashboardData<ITypeValue>({path: 'checkin/average-mia-total'});
        if(!!response){
            dispatch(setAverageCompanyMiaTotal(response))
        }
    }

    const getAverageCompanyFeedbackGiven = async () => {
        const response = await getDashboardData<ITypeValue>({path: 'checkin/average-feedback-given'});
        if(!!response){
            dispatch(setAverageCompanyFeedbackGiven(response))
        }
    }

    const getAverageCompanyFeedbackTime = async () => {
        const response = await getDashboardData<ITypeValue>({path: 'checkin/company-average-feedback-time'});
        if(!!response){
            dispatch(setAverageCompanyFeedbackTime(response))
        }
    }

    const getAverageCompanyCheckinCompleted = async () => {
        const response = await getDashboardData<ITypeValueQuantity[]>({path: 'checkin/average-completed'});
        if(!!response){
            dispatch(setAverageCompanyCheckinCompleted(response))
        }
    }

    const getAverageCompanyCheckinOverdue = async () => {
        const response = await getDashboardData<ITypeValue>({path: 'checkin/average-overdue'})
        if(!!response){
            dispatch(setAverageCompanyCheckinOverdue(response))
        }
    }

    const getAverageCompanyFeedbackOutStanding = async () => {
        const response = await  getDashboardData<ITypeValue>({path: 'checkin/average-feedback-outstanding'})
        if(!!response){
            dispatch(setAverageCompanyFeedbackOutStanding(response))
        }
    }

    const getAverageCompanyStats = async () => {
        const response = await getDashboardData<ITypeValue[]>({path: 'checkin/average-stats'});
        if(!!response){
            dispatch(setAverageCompanyStats(response));
        }
    }

    const getAverageCompanyClients = async () => {
        const response = await getDashboardData<ITypeValue>({path: 'average-active-clients'});
        if(response){
            dispatch(setAverageCompanyClients(response))
        }
    }

    const getClientsProgress = async () => {
        const response = await getDashboardData<IClientProgress[]>({ path: 'checkin/client-progress', dateRange: dateRangeName === LAST_MONTH ? LAST_WEEK : dateRangeName })
        if(response){
            dispatch(setClientsProgress(response))
        }
    }


    const getClientsStatusWeeks = async () => {
        const response = await getDashboardData<IClientStatusWeek[]>({path: 'checkin/client-status', dateRange: THIS_WEEK})
        if(response){
            dispatch(setClientsStatusWeeks(response))
        }
    }

    useEffect(() => () => {dispatch(dashboardReducerReset())}, [])

    return {
        dashboardApiCancel:cancel,
        getTotal,
        getTotalClients,
        getFeedbackOutstanding,
        getCheckInOverdue,
        getCompletedDaily,
        getAssignedCoaches,
        getComplianceDaily,
        getAverageFeedbackTime,
        getAverageCompanyFeedbackGiven,
        getAverageCompanyCheckinOverdue,
        getAverageCompanyCheckinCompleted,
        getAverageCompanyFeedbackOutStanding,
        getAverageCompanyStats,
        getAverageCompanyFeedbackTime,
        getAverageCompanyMiaTotal,
        getStatsCheckins,
        getCoachClientsData,
        getAverageCompanyClients,
        getAverageCompanyComplianceAverage,
        getChartCompletedData,
        getChartClientsData,
        getClientsProgress,
        getActiveClients,
        getCoachesOverview,
        getClientsStatusWeeks,
        getFeedbackGiven,
        getMiaAverageTotal,
        handleChangeDateRange,
        handleChangeCoachId,
        initDashboardFilters,
        getLastContactedMia
    }
}

export default useDashboardApiWithRedux;