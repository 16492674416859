import React from 'react';
import {PageHeader, Space} from "antd";
import DateRangeButtonGroup from "../dashboard/DateRangeButtonGroup";
import withPermission from "HOCs/withPermission";
import {admin, manager} from "vars";
import DashboardPageHeaderSelect from "../dashboard/DashboardPageHeaderSelect";
import { useAppSelector } from 'redux/store';
import { DateRange } from 'Types';
import { IDashboardQueryParams } from 'Interfaces/Dashboard';
import { Canceler } from 'axios';


interface Props {
    initialDashboard: IDashboardQueryParams,
    setInitialDashboard: React.Dispatch<IDashboardQueryParams>,
    handleChangeDateRange: (value: DateRange) => void,
    handleChangeCoachId: (value: string | null) => void,
    isLoading: boolean,
    candidatesApiCancel: Canceler,
    getActiveCoaches: () => Promise<void>
}

export default function CoachDashboardPageHeader(props: Props) {
    const {handleChangeDateRange, handleChangeCoachId, initialDashboard, setInitialDashboard} = props
    const dateRangeName = useAppSelector(state => state.dashboard.dateRangeName)
    const user = useAppSelector((state) => state.auth.user!)

    const HeaderItemTitle = ({text}: {text: React.ReactNode}) => <b>{text}</b>
    const CoachTitleWithPermission = (withPermission(HeaderItemTitle))



    const onChangeDateRange = (value: DateRange) => {
        setInitialDashboard({
            ...initialDashboard,
            dateRangeName: value
        })
        handleChangeDateRange(value)
    }

    const onChangeCoachId = (value: string | null) => {
        setInitialDashboard({
            ...initialDashboard,
            selectedCoachId: value
        })
        handleChangeCoachId(value)
    }

    const extras = (
        <Space size="large" align="end" className={'dashboard_header_space'}>
            <CoachTitleWithPermission text={'Coach:'} roles={[admin, manager]}/>
            <DashboardPageHeaderSelect
                {...props}
                onChange={onChangeCoachId}
                roles={[admin, manager]}
                extraOptions={!user.showAsCoach ? [{ label: 'Me', value: user.id }] : []}            
            />
            <HeaderItemTitle text={'Date Range:'}/>
            <DateRangeButtonGroup 
                value={dateRangeName} 
                onChange={(e) => onChangeDateRange(e.target.value)}
                {...props}
            />
        </Space>
    )

    return (
        <PageHeader className="site-layout-background" title="Dashboard"
                    onBack={() => window.history.back()} extra={extras}/>
    )
}