import { PayloadAction } from '@reduxjs/toolkit';
import { DateRange } from 'Types';
import { IClient, IManager, ICoach } from 'Interfaces/User';
import {createSlice} from '@reduxjs/toolkit'
import {THIS_WEEK, VIEW_PROFILE} from "../../vars";
import { CurrentEvent, MacroType } from 'Types';
import { IClientType } from 'Interfaces/ClientType';

export type UserData =  IClient | ICoach | IManager;

interface IRatio {
    LowMacro: number,
    HighMacro: number,
    StandardMacro: number,
    AverageMacro: number,
    Calculator: number
};

export interface IFoodDiary {
    nutritional_contents: {
        energy: {
            value: number
        },
        protein: number,
        fat: number,
        carbohydrates: number
    }
};

export interface INutritionSummary {
    date: string,
    goalFat: number,
    goalCalories: number,
    goalProtein: number,
    goalCarbs: number,
    nutritional_contents: {
        fat: number,
        energy: number,
        proteint: number,
        carbohydrates: number
    },
    data: any[]
};

interface InitialState {
    userData: UserData | null,
    initials: string | null,
    activeTabKey: string,
    ratio: IRatio,
    currentEvent: CurrentEvent | null,
    macroModalToggleCount: number,
    dateRangeSummary: DateRange,
    clientsType: IClientType[],
    lastUpdate: number,
    foodDiaryData: IFoodDiary[],
    nutritionSummaryData: INutritionSummary[],
    pausedNotifications: {
        blockUntil: string,
        userId: string,
        reasonMessage: string,
    } | null
};

const initialState = {
    userData: null,
    initials: null,
    activeTabKey: '1',
    foodDiaryData: [],
    ratio: {
        LowMacro: 0,
        HighMacro: 0,
        StandardMacro: 0,
        AverageMacro: 0,
        Calculator: 0,
    },
    currentEvent: VIEW_PROFILE,
    nutritionSummaryData: [],
    clientsType: [],
    macroModalToggleCount: 0,
    dateRangeSummary: THIS_WEEK,
    pausedNotifications: null,
    lastUpdate: Date.now()
} as InitialState;

const userReducer = createSlice({
    name: 'userReducer',
    initialState,
    reducers: {
        setUser: (state, action: PayloadAction<{userData: UserData}>) => {
            state.userData = action.payload.userData
        },
        setUserInitials: (state, action: PayloadAction<{initials: string}>) => {
            state.initials = action.payload.initials
        },
        setActiveTabKey: (state, action: PayloadAction<{activeTabKey: string}>) => {
            state.activeTabKey = action.payload.activeTabKey
        },
        setCurrentEvent: (state, action: PayloadAction<{currentEvent: CurrentEvent | null}>) => {
            state.currentEvent = action.payload.currentEvent
        },
        incrementMacroModalToggleCount: (state,) => {
            state.macroModalToggleCount = state.macroModalToggleCount + 1
        },
        setUserLastUpdate: (state, action: PayloadAction<{lastUpdate: number}>) => {
            state.lastUpdate = action.payload.lastUpdate
        },
        setCreateClientsType: (state, action: PayloadAction<IClientType>) => {
            state.clientsType =  [...state.clientsType, action.payload]
                .sort((a,b) => a.name.localeCompare(b.name));
        },
        setEditClientsType: (state, action: PayloadAction<IClientType>) => {
            state.clientsType = [...state.clientsType.map((type) => type.id === action.payload.id ? action.payload : type)]
                .sort((a,b) => a.name.localeCompare(b.name))
        },
        setClientsType: (state, action: PayloadAction<IClientType[]>) => {
          state.clientsType = action.payload;
        },
        setDeleteClientsType: (state, action: PayloadAction<string>) => {
            state.clientsType = [...state.clientsType.filter(({id}) => id !== action.payload)]
                .sort((a,b) => a.name.localeCompare(b.name));
        },
        setDateRangeSummary: (state, action: PayloadAction<DateRange>) =>  {
            state.dateRangeSummary = action.payload;
        },
        setFoodDiaryData: (state, action) => {
            state.foodDiaryData = action.payload;
        },
        setNutritionSummaryData: (state, action) => {
            state.nutritionSummaryData = action.payload;
        },
        setRatio: (state, action: PayloadAction<{currentMacroType: MacroType, ratio: number}>) => {
            state.ratio = {...state.ratio, [action.payload.currentMacroType]: action.payload.ratio};
        },
        setPauseNotificationDate: (state, action) => {
            state.pausedNotifications = action.payload;
        },
        userReducerReset: () => initialState,
    }
})

export const {
    setUser,
    setUserInitials,
    setNutritionSummaryData,
    setActiveTabKey,
    setCurrentEvent,
    setDateRangeSummary,
    incrementMacroModalToggleCount,
    userReducerReset,
    setUserLastUpdate,
    setClientsType,
    setCreateClientsType,
    setEditClientsType,
    setDeleteClientsType,
    setFoodDiaryData,
    setRatio,
    setPauseNotificationDate
} = userReducer.actions

export default userReducer.reducer


