import React from 'react';
import DashboardPageChartsPie from "./DashboardPageChartsPie";
import {shallowEqual, useSelector} from "react-redux";
import NoData from "components/no-data/NoData";
import {getChartLabelPercentage} from "utils/helpers";
import {SHOW_PERCENTAGE_CHART} from "vars";
import { useAppSelector } from 'redux/store';
import { Props as PieProps } from 'react-chartjs-2/dist/types';
import { ChartConfiguration } from 'chart.js';


export default function DashboardPageCompletedChartsPie() {
    const chartsPieCompletedData = useAppSelector(state => state.dashboard.chartsPieCompletedData, shallowEqual)

    const getChartsColor = (type: string) => {
        switch (type) {
            case 'Not Completed': {
                return '#ffb6b6'
            }
            case 'Completed': {
                return '#c2ec9d'
            }
            default: {
                return '#' + (Math.random().toString(16) + '000000').substring(2, 8).toUpperCase()
            }
        }
    }

    const getChartsOffset = ({type, value}: {type: string, value: string | number}) => {
        switch (type) {
            case 'Not Completed': {
                return +value < 100 ? 60 : 0
            }
            default: {
                return 0
            }
        }
    }


    const checkinsDivisionPieOptions: ChartConfiguration<'pie'>['options'] = {
        plugins: {
            tooltip: {
                callbacks: {
                    label: getChartLabelPercentage,
                }
            },
            legend: {
                position: 'bottom',
                align: 'start',
                labels: {padding: 40},
            },
            datalabels: {
                color: '#000',
                formatter: function (value, {dataIndex}) {
                    return chartsPieCompletedData[dataIndex].value >= SHOW_PERCENTAGE_CHART ? chartsPieCompletedData[dataIndex].value + '%' : null
                },

            },

        },
    }

    const checkinsDivisionPieData: PieProps['data'] = {
        labels: chartsPieCompletedData.map((elem) => elem.type),
        datasets: [
            {
                label: chartsPieCompletedData.map((elem) => elem.type),
                data: chartsPieCompletedData.map((elem) => Number(elem.quantity)),
                backgroundColor: chartsPieCompletedData.map(d => getChartsColor(d.type)),
                borderColor: 'none',
                borderWidth: 0,
                spacing: 0,
                offset: chartsPieCompletedData.map(({type, value}) => getChartsOffset({type, value})),
            },
        ],
    };

    const checkinsDivisionPieProps: PieProps = {
        type: 'pie',
        data: checkinsDivisionPieData,
        options: checkinsDivisionPieOptions,
        width: 410,
        height: 410,
    }

    return (chartsPieCompletedData.every(elem => !elem.value) ?
        <NoData description="Check-ins completed (No Data)" /> :
        <DashboardPageChartsPie title={'Check-ins completed'} {...checkinsDivisionPieProps}/>)
}