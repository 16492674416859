import React, {useEffect, useState} from 'react';
import { useAppSelector } from 'redux/store';
import {Table, TablePaginationConfig, TableProps} from "antd";
import checkInTableConfig from "./check_in_table/check_in_table_config";
import {getQueryParams, tablePagination} from "utils/helpers";
import {CHECKIN, coach} from "vars";
import useScrollTo from "hooks/useScrollTo";
import { ICheckIn } from 'Interfaces/CheckIn';
import { SorterResult } from 'antd/lib/table/interface';
import { CheckInTableSettings } from 'redux/reducers/checkInsReducer';
import { FilterValue } from 'antd/es/table/interface';



interface Props {
    handleTableChange: <T>(pagination: TablePaginationConfig, filters: Record<string, FilterValue | null>, sorter: SorterResult<T> | SorterResult<T>[]) => void
    onSelectChange: (selected: ICheckIn[]) =>  Promise<void>,
    getCheckIn: (query?:CheckInTableSettings) => Promise<void>
    resetSelectedRows: () => void,
    getCoaches: () => Promise<void>
}

export default function CheckinListTable(props: Props) {
    const {
        onSelectChange,
        resetSelectedRows,
        handleTableChange,
        getCheckIn,
        getCoaches
    } = props

    const [loading, setLoading] = useState(true);
    const checkInData = useAppSelector(state => state.checkIns.checkInData);
    const selectedRowKeys = useAppSelector(state => state.checkIns.checkInSelectedRowKeys);
    const checkInTableSettings = useAppSelector(state => state.checkIns.checkInTableSettings);
    const userRoles = useAppSelector(state => state.auth.user!.roles);
    const totalCount = useAppSelector(state => state.checkIns.totalCount);
    const lastUpdate = useAppSelector(state => state.checkIns.lastUpdate);

    const {getCheckInColumns} = checkInTableConfig();

    const {pageSize, page} = checkInTableSettings

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true)
            await getCoaches()
        }

        if (userRoles !== coach) {
            fetchData();
        }
    }, []);


    useScrollTo({dependency:[pageSize, page]})

    useEffect(() => {
        let cleanupFunction = false;
        const fetchData = async () => {
            setLoading(true)

            await getCheckIn(getQueryParams(checkInTableSettings))

            if (!cleanupFunction) {
                setLoading(false)
            }
        }

        fetchData();

        return () => {
            resetSelectedRows()
            cleanupFunction = true
        }
    }, [lastUpdate]);

    const rowSelection: TableProps<ICheckIn>['rowSelection'] = {
        selectedRowKeys,
        onChange: (_, checkIn) => onSelectChange(checkIn),
    }

    return (
        <Table
            key={CHECKIN}
            pagination={tablePagination({pageSize, page, totalCount, showSizeChanger: true,}) as TablePaginationConfig}
            columns={userRoles !== coach ? getCheckInColumns : getCheckInColumns.filter((column) => column.title !== 'Coach')}
            rowClassName={rw => rw.autoGenerated ? 'checkin-row-autogenerated' : ''}
            dataSource={checkInData}
            loading={loading}
            onChange={handleTableChange}
            rowKey={(e) => e.id}
            scroll={{x: 'auto'}}
            rowSelection={rowSelection}
        />
    )
}