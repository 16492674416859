import React, { FC, useEffect } from 'react';
import { ModalForm } from '@ant-design/pro-form';
import { Form, Space } from 'antd';
import { ITask, ITaskForm } from 'Interfaces/TasksCategories';
import { useAppSelector } from 'redux/store';
import antNotificationComponent from 'utils/notification-ant-utils';
import TaskForm from './TaskForm';

interface EditTaskFormProps {
    onCancel: () => void,
    onUpdate: () => void,
    onFinish: (task: ITaskForm) => Promise<ITask>,
}

const EditTaskForm: FC<EditTaskFormProps> = ({ onCancel, onFinish, onUpdate }) => {
    const isVisible = useAppSelector((state) => state.modals.editTaskModalVisible);
    const currentTask = useAppSelector((state) => state.tasks.currentTask);
    const [form] = Form.useForm<ITaskForm>();

    const handleCancel = () => {
        form.resetFields();
        onCancel();
    }

    const handleFinish = async ({dueDate, reminderDate,...formData}: ITaskForm) => {
        const response = await onFinish({ ...formData, dueDate: dueDate ?? null, reminderDate: reminderDate ?? null });
        if(response){
            handleCancel();
            onUpdate();
            antNotificationComponent({ type: "success", message: "Saved" });
        }
    }

    useEffect(() => {
        if(currentTask){
            form.setFieldsValue(currentTask);
        };
    }, [isVisible]);

    return (
        <ModalForm
            title="Edit Task"
            form={form}
            submitter={{
                render: (_, dom) => {
                    return [
                    <Space>
                        {dom.map(btn => btn)}
                    </Space>
                    ]
                }
            }}
            onFinish={handleFinish}
            visible={isVisible}
            modalProps={{
                onCancel: handleCancel,
                centered: true
            }}
        >
            <TaskForm currentTask={currentTask!}  />
        </ModalForm>
    )
};

export default EditTaskForm;