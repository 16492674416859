import {useEffect} from "react";
import { useAppDispatch, useAppSelector } from "redux/store";
import useApi from "hooks/useApi";
import {Logger} from "utils/helpers";
import {candidatesReducerReset, setCandidates} from "redux/reducers/candidatesReducer";
import { coach, manager } from "vars";
import { IClient, IUser } from "Interfaces/User";

const logger = Logger("CoachCandidatesApiWithRedux")

function useCoachCandidatesApiWithRedux() {
    const {API, cancel} = useApi()
    const dispatch = useAppDispatch()
    const authUser = useAppSelector((state) => state.auth.user) as IUser;
    const getCoachesData = async () => {
        try {
            const response = await API.get<IUser[]>("/api/user/coach-candidates");
            return response.data
        } catch (e) {
            if(e instanceof Error){
                logger.error(e.message)
            }
        }
    }

    const getActiveCoachesData = async () => {
        try {
            if(authUser.roles === manager){
                const response = await API.get<{totalCount: number, users: IClient[]}>('/api/user/', {
                    params: {
                        f_roles: coach,
                        f_coachId: authUser.id
                    }
                })
                return response.data.users;
            }
            const response = await API.get<IUser>("/api/user/active-coaches");
            return response.data
        } catch (e) {
            if(e instanceof Error){
                logger.error(e.message)
            }
        }
    }

    const getCoaches = async ()=>{
        const coachData = await getCoachesData()
        if (Array.isArray(coachData)) {
            dispatch(setCandidates({candidates: coachData}))
        }
    }

    const getActiveCoaches = async ()=>{
        const coachData = await getActiveCoachesData()
        if (Array.isArray(coachData)) {
            dispatch(setCandidates({candidates: coachData}))
        }
    }


    useEffect(() => () => {dispatch(candidatesReducerReset())}, [])

    return {
        candidatesApiCancel:cancel,
        getCoaches,
        getActiveCoaches
    }
}

export default useCoachCandidatesApiWithRedux;