import { PayloadAction } from '@reduxjs/toolkit';
import {createSlice} from '@reduxjs/toolkit'
import { IFeedback, IFeedback_UserStateClassification, PaginatedFeedbacks } from 'Interfaces/Feedback';
import {DefaultPageSizes, user} from "../../vars";

interface InitialState {
    paginatedFeedbacks: PaginatedFeedbacks,
    currentFeedback: IFeedback | null,
    lastFeedbackFromClient: IFeedback | null | undefined,
    lastUpdate: number,
}

const initialState = {
    paginatedFeedbacks: { totalCount: 0, items: [], page: 1, pageSize: DefaultPageSizes.FEEDBACK },
    currentFeedback: null,
    lastFeedbackFromClient: null,
    lastUpdate: Date.now()
} as InitialState;

const feedbackReducer = createSlice({
    name: 'feedbackReducer',
    initialState,
    reducers: {
        setFeedbackData: (state, action: PayloadAction<PaginatedFeedbacks>) => {
            state.paginatedFeedbacks = action.payload
            state.lastFeedbackFromClient = action.payload.items.find(feedback => feedback.Author?.roles === user);
        },
        setFeedbackUpdate: (state, action: PayloadAction<{lastUpdate: number}>) => {
            state.lastUpdate = action.payload.lastUpdate
        },
        setCurrentFeedback: (state, action: PayloadAction<IFeedback | null>) => {
            state.currentFeedback = action.payload;
        },
        setUpdateFeedback: (state, action: PayloadAction<IFeedback>) => {
            state.paginatedFeedbacks.items = state.paginatedFeedbacks.items.map((feedback) => {
                    if(feedback.id === action.payload.id){
                        return action.payload;
                    }
                    return feedback;
                });
        },
        setUpdateActivationsParts: (state, action: PayloadAction<{id: string, data: IFeedback_UserStateClassification}>) => {
            state.paginatedFeedbacks.items = state.paginatedFeedbacks.items.map((feedback) => feedback.id === action.payload.id ? ({
                ...feedback,
                userStateClassification: [action.payload.data]
            }) : feedback);
        },
        setDeleteFeedback: (state, action: PayloadAction<{id: string}>) => {
            state.paginatedFeedbacks.items = state.paginatedFeedbacks.items.filter((feedback) => feedback.id !== action.payload.id);
            state.paginatedFeedbacks.totalCount = state.paginatedFeedbacks.totalCount - 1;
        },
        feedbackReducerReset: state => initialState
    }
})

export const {setFeedbackData, setFeedbackUpdate, setCurrentFeedback, setUpdateFeedback, setUpdateActivationsParts, setDeleteFeedback, feedbackReducerReset} = feedbackReducer.actions
export default feedbackReducer.reducer


