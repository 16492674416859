import React from 'react';
import {Row, Col, Image, Tag} from "antd";
import WidgetPageCard, { IWidgetPageCardProps } from "./WidgetPageCard";
import {shallowEqual} from "react-redux";
import {checkFormatDuration} from "utils/helpers";
import nounOutstanding from 'img/noun-outstanding.svg';
import nounOverall from 'img/noun-overall.svg';
import nounOverdue from 'img/noun-overdue.svg';
import nounClient from 'img/noun-client.svg';
import nounTick from 'img/noun-tick.svg';
import nounClient2 from 'img/noun-client2.svg';
import nounFeedback from 'img/noun-feedback.svg';
import nounLate from 'img/noun-late.svg';
import nounCoach from 'img/noun-coach.svg';
import nounCheckin from 'img/noun-checkin.svg';
import nounWarningWhite from 'img/noun-warning-white.svg';
import nounWarningBlack from 'img/noun-warning-black.svg';

import {admin, coach, manager} from "vars";
import withPermission from "HOCs/withPermission";
import { useAppSelector } from 'redux/store';

function DashboardPageCards() {
    const feedbackGiven = useAppSelector(state => state.dashboard.feedbackGiven, shallowEqual)
    const activeClients = useAppSelector(state => state.dashboard.activeClients!)
    const [activeCheckedIn, activeLate] = useAppSelector(state => state.dashboard.statsCheckins, shallowEqual)
    const coachClientsTotalCount = useAppSelector(state => state.dashboard.coachClientsTotalCount, shallowEqual)
    const averageFeedbackTime = useAppSelector(state => state.dashboard.averageFeedbackTime!, shallowEqual)
    const chartsPieCompletedData = useAppSelector(state => state.dashboard.chartsPieCompletedData, shallowEqual)
    const chartsPieClientsData = useAppSelector(state => state.dashboard.chartsPieClientsData, shallowEqual)
    const averageCompanyCompliance = useAppSelector(state => state.dashboard.averageCompanyCompliance)
    const checkInOverdue = useAppSelector(state => state.dashboard.checkInOverdue, shallowEqual)
    const feedbackOutstanding = useAppSelector(state => state.dashboard.feedbackOutstanding, shallowEqual)
    const miaAverageTotal = useAppSelector(state => state.dashboard.miaAverageTotal, shallowEqual)
    const selectedCoachId = useAppSelector(state => state.dashboard.selectedCoachId)
    const [,, companyInactiveMissedOne, companyInactiveMissedMia] = useAppSelector(state => state.dashboard.averageCompanyStats);
    const averageComplianceOverall = averageCompanyCompliance.find(({type}) => type === 'Ok');

    const missedOneCheckIn = {
        type: 'Missed 1 Check In',
        value: companyInactiveMissedOne?.value || 0
    };

    const missedMiaCheckIn = {
        type: 'Missed 2+ (MIA)',
        value: companyInactiveMissedMia?.value || 0
    };

    const complete = chartsPieCompletedData.find(elem => elem.type === 'Completed')
    const complianceOverdue = chartsPieClientsData
        .filter(elem => elem.type !== 'Late' && elem.type !== 'Ok')
        .reduce((quantitySum, {value}) => quantitySum + Number(value), 0)

    const WidgetPageCardWithWrapper = (props: IWidgetPageCardProps) => (
        <Col span={12}>
            <WidgetPageCard {...props}/>
        </Col>)


    
    const WidgetPageCardWithPermission = (withPermission(WidgetPageCardWithWrapper));



    return (
        <Row>
            <WidgetPageCardWithPermission 
                data={{
                type: 'Active Clients', value: +activeCheckedIn?.value + +activeLate?.value || 0
                }}
                children={
                    <Image src={nounClient2} width={60} preview={false} />
                }
                suffix={<>
                    <Tag>{activeClients?.value || 0}</Tag>
                    <Tag color="purple">{Math.ceil((+activeCheckedIn?.value + +activeLate?.value) / +activeClients?.value * 100) || 0}%</Tag>
                    </>
                    }
                roles={[admin]}
            />
            {
                selectedCoachId && 
                    <WidgetPageCardWithPermission 
                        data={coachClientsTotalCount!}
                        children={<Image src={nounCoach} width={60} preview={false} />}
                        roles={[admin]}
                    />
            }
            <WidgetPageCardWithWrapper data={{
                type: 'Check Ins Received', value: activeCheckedIn?.value || 0
            }}
                                       children={<Image src={nounCheckin} width={60} preview={false} />}
                                       suffix={<>
                                           <Tag>{+activeClients?.value || 0}</Tag>
                                           <Tag color="purple">{Math.ceil(+activeCheckedIn?.value / +activeClients?.value * 100) || 0}%</Tag>
                                       </>}
            />

            <WidgetPageCardWithPermission data={{type: 'Check Ins Late', value: activeLate?.value || 0}}
                                          children={<Image src={nounLate} width={60} preview={false} />}
                                          suffix={<>
                                              <Tag>{+activeCheckedIn.value + +activeLate.value || 0}</Tag>
                                              <Tag color="purple">{Math.ceil(+activeLate?.value / (+activeCheckedIn?.value + +activeLate?.value) * 100) || 0}%</Tag>
                                          </>}
                                          roles={[coach, manager, admin]}/>

            <WidgetPageCardWithPermission data={missedOneCheckIn}
                                          suffix={<>
                                              <Tag>{+activeClients?.value || 0}</Tag>
                                              <Tag color="purple">{Math.ceil(+missedOneCheckIn?.value / +activeClients?.value * 100) || 0}%</Tag>
                                          </>}
                                          children={<Image src={nounWarningWhite} width={60} preview={false} />}
                                          roles={[admin, manager, coach]}
            />
            <WidgetPageCardWithPermission data={missedMiaCheckIn}
                                          suffix={<>
                                              <Tag>{+activeClients?.value || 0}</Tag>
                                              <Tag color="purple">{Math.ceil(+missedMiaCheckIn?.value / +activeClients?.value * 100) || 0}%</Tag>
                                          </>}
                                          children={<Image src={nounWarningBlack} width={60} preview={false} />}
                                          roles={[admin, coach, manager]}
            />
            <WidgetPageCardWithWrapper data={{type: 'MIA Avg Last Contact', value: miaAverageTotal?.value || 0 }}
                                       suffix={<Tag>Day(s)</Tag>}
                                       children={<Image src={nounLate} width={60} preview={false}
            />}
            />
            <WidgetPageCardWithPermission data={{type: 'Compliance Overall', value: averageComplianceOverall?.value || 0 }}
                                          suffix="%"
                                          children={<Image src={nounOverall} preview={false} />}
                                          roles={[manager, admin, coach]}

            />
            <WidgetPageCardWithPermission data={checkInOverdue!}
                                          children={<Image src={nounOutstanding} width={60} preview={false} />}
                                          roles={[coach, manager, admin]}/>

            <WidgetPageCardWithPermission data={feedbackGiven!}
                                          suffix={<Tag>{activeClients?.value || 0}</Tag>}
                                          children={<Image src={nounFeedback} width={60} preview={false} />}
                                          roles={[admin]}/>

            <WidgetPageCardWithWrapper
                data={{type: 'Check-In Complete', value: complete?.quantity}}
                children={<Image src={nounTick} width={60} preview={false}/>}
            />

            <WidgetPageCardWithPermission data={{type: 'Feedback Outstanding', value: feedbackOutstanding?.value || 0}}
                                          children={<Image src={nounOverdue} width={60} preview={false} />}
                                          roles={[coach, manager]}/>

            <WidgetPageCardWithPermission data={{type: 'Compliance Overall', value: complianceOverdue.toFixed(2)}}
                                          children={<Image src={nounOverall} width={60} preview={false} />}
                                          suffix={'%'}
                                          roles={[coach, manager]}/>

            <WidgetPageCardWithPermission
                data={{...averageFeedbackTime!, value: checkFormatDuration(averageFeedbackTime?.value)}}
                children={<Image src={nounClient} width={60} preview={false} />}
                roles={[admin]}/>
        </Row>
    )
}


export default DashboardPageCards