import { useAppSelector, useAppDispatch } from 'redux/store';
import {Logger} from "../utils/helpers";
import useApi from "../hooks/useApi";
import {useEffect, useState} from "react";
import {
  setClientTypes,
  setCurrentClientType,
  setFilteredTypes,
  setUpdateClientTypes
} from "../redux/reducers/clientTypesReducer";
import usePersistedState from "../hooks/usePersistedState";
import {Button, Space, Tag} from "antd";
import useModals from "./modals-service";
import {DeleteOutlined, EditOutlined} from "@ant-design/icons";
import antNotificationComponent from "../utils/notification-ant-utils";
import { IClientType } from 'Interfaces/ClientType';
import { ColumnType, ColumnsType, TableProps } from 'antd/lib/table';
import { SearchProps } from 'antd/lib/input';
import { CommonFormProps } from '@ant-design/pro-form/lib/BaseForm';

interface IClientTypesParams {
  f_enabled?: boolean[] | null,
};


export interface IClientTypeForm {
  name: string,
  welcomePackUrl: string,
  enabled: boolean,
  description: string,
};

const logger = Logger('useClientTypesApiWithRedux')

const useClientTypesApiWithRedux = () => {
  const dispatch = useAppDispatch();
  const {API} = useApi();
  const getUpdate = () => new Date().getTime();
  const {setClientTypeVisible, setRemoveClientTypeVisible} = useModals();
  const [isLoading, setIsLoading] = useState(true);
  const lastUpdate = useAppSelector((state) => state.clientTypes.lastUpdate);
  const clientTypesData = useAppSelector((state) => state.clientTypes.clientTypes);
  const currentType = useAppSelector((state) => state.clientTypes.currentType);
  const filteredTypesData = useAppSelector((state) => state.clientTypes.filteredTypes);
  const [initialClientTypes , setInitialClientTypes] = usePersistedState<IClientTypesParams>(
      "clientTypes",
      {f_enabled: null}
  )

  const {f_enabled} = initialClientTypes;

  const getClientsTypes = async (params: IClientTypesParams): Promise<void> => {
    setIsLoading(true);
    try {
      const response = await API.get<IClientType[]>('/api/client-type', {
        params: params || initialClientTypes
      });
      dispatch(setClientTypes(response.data));
    }catch (e) {
      if(e instanceof Error){
        console.log(e.message);
        logger.error(e.message);
      }
    }finally {
      setIsLoading(false);
    }
  };

  const onCreateClientType: CommonFormProps['onFinish'] = async ({name, ...rest}) => {
    const requestFormData = { ...rest, name: name.trim() };
    try {
      await API.post<IClientType>('/api/client-type', requestFormData);
      dispatch(setUpdateClientTypes(getUpdate()));
      dispatch(setCurrentClientType(null));
      setClientTypeVisible(false);
      antNotificationComponent({type: 'success', message: 'Saved'});
    } catch (e) {
      if(e instanceof Error){
        logger.error(e.message);
      }
    }
  }

  const onEditClientType = async ({name, ...rest}: IClientTypeForm): Promise<void> => {

    const requestFormData = { ...rest, name: name.trim() };
    try {
      await API.put<IClientType>(`/api/client-type/${currentType?.id}`, requestFormData);
      dispatch(setUpdateClientTypes(getUpdate()));
      dispatch(setCurrentClientType(null));
      setClientTypeVisible(false);
      antNotificationComponent({type: 'success', message: 'Updated'});
    } catch (e) {
      if(e instanceof Error){
        logger.error(e.message);
      }
    }
  }

  const onFinish = currentType ? onEditClientType : onCreateClientType;

  const onDeleteClientType = async (): Promise<void> => {
    try {
      await API.delete(`/api/client-type/${currentType?.id}`);
      dispatch(setUpdateClientTypes(getUpdate()));
      dispatch(setCurrentClientType(null));
      setRemoveClientTypeVisible(false);
    }catch (e) {
      if(e instanceof Error){
        logger.error(e.message);
      }
    }
  }

  const onOpenCreateModal = () => {
    dispatch(setCurrentClientType(null));
    setClientTypeVisible(true);
  }

  const onOpenEditModal = (currentType: IClientType) => {
    dispatch(setCurrentClientType(currentType));
    setClientTypeVisible(true);
  }

  const onOpenDeleteModal = (currentType: IClientType) => {
    dispatch(setCurrentClientType(currentType));
    setRemoveClientTypeVisible(true)
  }

  const onCloseDeleteModal = () => {
    dispatch(setCurrentClientType(null));
    setRemoveClientTypeVisible(false)
  }

  const onCloseModal = () => {
    dispatch(setCurrentClientType(null));
    setClientTypeVisible(false);
  }

  const onSearchTypes: SearchProps['onSearch'] = (value: string) => {
    const filteredTypes = clientTypesData.filter((type) => type?.name.toLowerCase().includes(value.trim().toLowerCase()));
    dispatch(setFilteredTypes(filteredTypes));
  }

  const renderEnabled: ColumnType<IClientType>['render'] = (_,{enabled}) => {
    switch (enabled){
      case true:
        return <Tag color="green">ACTIVE</Tag>
      case false:
        return <Tag color="red">DISABLED</Tag>
      default:
        return <Tag>{enabled}</Tag>
    }
  }

  const renderActions = (currentType: IClientType) => (
      <Space size="middle">
        <Button
        shape="circle"
        type="primary"
        icon={<EditOutlined />}
        onClick={() => onOpenEditModal(currentType)}
        />
        {currentType.count === 0 && <Button
            shape="circle"
            type="primary"
            danger
            icon={<DeleteOutlined />}
            onClick={() => onOpenDeleteModal(currentType)}
        />}
      </Space>
  )

  const onTableChange: TableProps<IClientType>['onChange'] = (_,filters) => {
    setInitialClientTypes({
      ...initialClientTypes,
      ...filters
    })
  };

  const filterEnabledOptions = [
    {
      text: "Active",
      value: true
    },
    {
      text: "Disabled",
      value: false
    }
  ]

  const getColumns = (): ColumnsType<IClientType> => [
    {
      title: 'Type',
      key: 'name',
      dataIndex: 'name',
      sorter: (a,b) => a.name.localeCompare(b.name)
    },
    {
      title: 'Description',
      key: 'description',
      dataIndex: 'description',
    },
    {
      title: 'Welcome Pack URL',
      key: 'welcomePackUrl',
      dataIndex: 'welcomePackUrl',
    },
    {
      title: 'Number Of Clients',
      key: 'count',
      dataIndex: 'count',
      sorter: (a,b) => a.count - b.count,
    },
    {
      title: 'Enabled',
      key: 'f_enabled',
      dataIndex: 'enabled',
      render: renderEnabled,
      filters: filterEnabledOptions,
      filteredValue: f_enabled
    },
    {
      title: 'Action',
      width: 100,
      fixed: 'right',
      render: renderActions
    }
  ]


  useEffect(() => {
    getClientsTypes(initialClientTypes);
  },[lastUpdate,initialClientTypes]);

  return {
    clientTypesData: filteredTypesData?.length > 0 ? filteredTypesData : clientTypesData,
    currentType,
    onFinish,
    getClientsTypes,
    isLoading,
    columns: getColumns(),
    onSearchTypes,
    onOpenCreateModal,
    onCreateClientType,
    onEditClientType,
    onDeleteClientType,
    onCancel: onCloseModal,
    onCloseDeleteModal,
    onTableChange
  }
};

export default useClientTypesApiWithRedux;