import React, {useEffect, useState} from 'react';
import {Space, Table} from "antd";
import moment from "moment";
import { useAppSelector } from 'redux/store';
import { Modal } from 'components/Antd/Modal';
import { IAssignmentHistory } from 'Interfaces/User';
import { ColumnProps } from 'antd/lib/table';
import { DATE_FORMAT_HOURS_MINUTES } from 'vars';

export interface IAssignmentsHistoryPopupProps {
    onCancel: () => void,
    getUserAssignmentsHistory: ({ userId, authId }: {
        userId: string;
        authId: string;
    }) => Promise<IAssignmentHistory[] | undefined>
}


export default function AssignmentsHistoryPopup({onCancel, getUserAssignmentsHistory}: IAssignmentsHistoryPopupProps) {
    const id = useAppSelector(state => state.user.userData!.id)
    const authUser = useAppSelector(state => state.auth.user)!;
    const assignmentsHistoryPopupVisible = useAppSelector(state => state.modals.assignmentsHistoryPopupVisible)

    const [loading, setLoading] = useState(true)
    const [assignmentsHistoryData, setAssignmentsHistoryData] = useState<IAssignmentHistory[]>([])

    const columns: ColumnProps<IAssignmentHistory>[] = [{
        title: 'Coach',
        render: (dom, record) => (
            <Space>
                {`${record.coachFirstName} ${record.coachLastName}`}
            </Space>
        ),
    }, {
        title: 'Assignment Date',
        render: (dom, record) => (
            <Space>
                {record.startDate ? moment(record.startDate).format(DATE_FORMAT_HOURS_MINUTES) : '-'}
            </Space>
        ),
    }, {
        title: 'Finish Date',
        render: (dom, record) => (
            <Space>
                {record.endDate ? moment(record.endDate).format(DATE_FORMAT_HOURS_MINUTES) : '-'}
            </Space>
        ),
    }]


    useEffect(() => {
        let cleanupFunction = false;

        async function fetchData() {
            setLoading(true)
            const data = await getUserAssignmentsHistory({userId: id, authId: authUser.id})
            if (!cleanupFunction && data) {
                setAssignmentsHistoryData(data)
                setLoading(false)
            }
        }

        if (assignmentsHistoryPopupVisible && !cleanupFunction) {
            fetchData()
        }
        return () => {cleanupFunction = true}
    }, [assignmentsHistoryPopupVisible])

    return (
        <Modal centered width={'70%'} title="Assignments History" visible={assignmentsHistoryPopupVisible} onCancel={onCancel} footer={null}>
            <Table loading={loading} dataSource={assignmentsHistoryData} columns={columns}
                   pagination={{
                       showQuickJumper: true,
                   }}
                   rowKey={(e) => e.id}

            />
        </Modal>
    )
}