import useApi from "hooks/useApi";
import { useAppDispatch, useAppSelector } from "redux/store";
import { ICategory, ITask, ITaskForm, ICategoryForm, StatusTask, IFullCategory } from "Interfaces/TasksCategories";
import {
    setCategories,
    setCurrentCategory,
    setCurrentTask, setSearchTasks,
    setTasks,
    setUpdateTask,
    setUpdateCategories,
    setUpdateCategoriesList,
    setUpdateTasks,
    setDeleteTask,
} from "redux/reducers/tasksReducer";
import antNotificationComponent from "utils/notification-ant-utils";
import useModals from "./modals-service";
import { useParams } from "react-router-dom";
import { trimStringInObject } from 'utils/helpers';

export interface ITaskParams{
    userId?: string,
    coachId?: string,
    managerId?: string,
    dueDateBefore?: string,
    categoryIds?: string[]
}

const baseTaskLink = '/api/task';

const useTasksCategories = () => {
    const {id} = useParams() as {id: string};
    const {API} = useApi();
    const dispatch = useAppDispatch();
    const { setEditTaskVisible, setRemoveTaskVisible, setRemoveCategoryVisible } = useModals();
    const tasks = useAppSelector((state) => state.tasks.tasks);
    const categories = useAppSelector((state) => state.tasks.categories);
    const searchCategories = useAppSelector((state) => state.tasks.searchCategories);
    const currentTask = useAppSelector((state) => state.tasks.currentTask);
    const currentCategory = useAppSelector((state) => state.tasks.currentCategory);
    const updateCategories = useAppSelector((state) => state.tasks.updateCategories);

    const getUserTasks = async (params: Partial<ITaskParams>) => {
        const response = await API.get<ITask[]>(baseTaskLink, {
            params
        });
        return response.data;
    };

    const onUpdateUserTask = async (formData: Partial<ITask>) => {
        const id = currentTask?.id || formData.id;
        const response = await API.put<ITask>(`${baseTaskLink}/${id}`, trimStringInObject(formData));
        return response.data;
    };

    const onCreateUserTask = async (formData: ITaskForm) => {
        const response = await API.post<ITask>(baseTaskLink, trimStringInObject({...formData, userId: id}));
        return response.data;
    };

    const onChangeTaskPin = async (id: string) => {
        const response = await API.patch<ITask>(`${baseTaskLink}/${id}/update-pin`);
        dispatch(setUpdateTask(response.data));
        return response.data;
    };

    const setTaskStatus = async (id: string, status: StatusTask = StatusTask.completed) => {
        const response = await API.patch<ITask>(`${baseTaskLink}/${id}/update-status`, { status });
        dispatch(setUpdateTask(response.data));
        return response.data;
    };

    const onCreateCategory = async (formData: ICategoryForm) => {
        const response = await API.post<ICategory>(`${baseTaskLink}/category`, trimStringInObject(formData));
        return response.data;
    };

    const onUpdateCategory = async (formData: Partial<ICategory>) => {
        const id = currentCategory?.id;
        const response = await API.put<ICategory>(`${baseTaskLink}/category/${id}`, trimStringInObject(formData));
        return response.data;
    }

    const onDeleteCategory = async (id: string) => {
        const response = await API.delete(`${baseTaskLink}/category/${id}`);
        return response.data;
    };

    const onDeleteTask = async (id: string) => {
        const response = await API.delete(`${baseTaskLink}/${id}`);
        dispatch(setDeleteTask(id));
        return response.data;
    }

    const getCategories = async () => {
        const response = await API.get<IFullCategory[]>(`${baseTaskLink}/category`);
        return response.data;
    };

    const setCategoriesData = async () => {
        const categories = await getCategories();
        dispatch(setCategories(categories));
    };

    const changeIsCategoryArchived = async (categoryId: string) => {
        const response = await API.patch<IFullCategory>(`${baseTaskLink}/category/${categoryId}/update-archived`);
        dispatch(setUpdateCategoriesList(response.data));
    };

    const getUserTasksData = async (params: ITaskParams) => {
        const userTasks = await getUserTasks(params);
        dispatch(setTasks(userTasks));
    };

    const handleSetCurrentCategory = (category: ICategory | null) => {
        dispatch(setCurrentCategory(category));
    }

    const handleSetCurrentTask = (task: ITask | null) => {
        dispatch(setCurrentTask(task));
    };

    const handleVisibleOpenRemoveCurrentTask = (task: ITask) => {
        setRemoveTaskVisible(true);
        handleSetCurrentTask(task);
    };

    const handleVisibleCloseRemoveCurrentTask = () => {
        setRemoveTaskVisible(false);
        handleSetCurrentTask(null);
    }

    const handleRemoveCurrentTask = async () => {
        const id = currentTask?.id!;
        const response = await onDeleteTask(id);
        if(response){
            onUpdateTasks();
            setRemoveTaskVisible(false);
            handleSetCurrentTask(null);
            setEditTaskVisible(false);
            antNotificationComponent({ type: "success", message: "Deleted" })
        };
    };

    const handleRemoveCurrentCategory = async () => {
        const id = currentCategory?.id!;
        const response =  await onDeleteCategory(id);
        if(response){
            handleSetCurrentCategory(null);
            setRemoveCategoryVisible(false);
            antNotificationComponent({ type: "success", message: "Deleted" });
            onUpdateCategories();
        };
    };

    const handleSearchCategories = (value: string) => {
        const searchCategories = categories.filter((category) => category.name.toLowerCase().includes(value.toLowerCase()));
        if(value.length > 0){
            dispatch(setSearchTasks(searchCategories));
        } else {
            dispatch(setSearchTasks([]));
        };
    };

    const handleUpdateTask = (task: ITask) => {
        dispatch(setUpdateTask(task));
    }

    const onUpdateCategories = () => {
        dispatch(setUpdateCategories(new Date().getTime()));
    }

    const onUpdateTasks = () => {
        dispatch(setUpdateTasks(new Date().getTime()));
    }

    return {
        tasks,
        categories: searchCategories.length > 0 ? searchCategories : categories,
        currentTask,
        currentCategory,
        handleSearchCategories,
        handleSetCurrentTask,
        handleSetCurrentCategory,
        handleVisibleOpenRemoveCurrentTask,
        handleVisibleCloseRemoveCurrentTask,
        handleRemoveCurrentTask,
        handleRemoveCurrentCategory,
        handleUpdateTask,
        onCreateUserTask,
        onDeleteTask,
        onChangeTaskPin,
        setTaskStatus,
        onCreateCategory,
        onUpdateUserTask,
        onUpdateCategory,
        setCategoriesData,
        getUserTasksData,
        updateCategories,
        changeIsCategoryArchived,
        onUpdateCategories,
        onUpdateTasks
    }
};

export type UseTasksCategoriesTypes = ReturnType<typeof useTasksCategories>;
export default useTasksCategories;