import React, {useState, useEffect} from "react"
import {Space, Button} from "antd"
import FeedbackModal from "./feedbackModal"
import CheckinListTable from "./CheckinListTable"
import CheckinPageHeader from "./CheckinPageHeader"
import withSidebarNavigation from "../../HOCs/withSidebarNavigation"
import antNotificationComponent, {
    antNotificationClose,
} from "utils/notification-ant-utils"
import useCheckInsApiWithRedux from "services/check-ins-service"
import useFeedbackApiWithRedux from "services/feedback-service"
import useModals from "../../services/modals-service"
import moment from "moment"
import useMessageTemplatesApiWithRedux from "services/message-templates-service"
import usePersistedState from "hooks/usePersistedState"
import {changeSortDirectionTitle, isFiltersChange} from "utils/helpers"
import { useAppSelector } from "redux/store"
import { TablePaginationConfig } from "antd/es/table"
import { SorterResult } from "antd/lib/table/interface"
import { CheckInTableSettings } from "redux/reducers/checkInsReducer"
import { FixMeLater, UserType } from "Types"
import useClientTypesApiWithRedux from "services/clientTypes-service"
import { DATE_FORMAT } from "vars"

//NOTES: Based on a demo table, added a pop-up editor via modal and form.
//I used functions here rather than classes/extend React.Component as this causes issues getting a reference to the form in order to populate it.
//Looking to upgrade table to ProTable (has back-end linkages and search and stuff... but I was having troubles getting it to work)
//Data is populated locally, but shouldn't be much of a hassle to change to using a fetch to get the objects.

export interface ISaveFeedback {
    notes: string,
    Author: UserType,
    selectedTemplates: string[],
    templates: string[]
};


function CheckInList() {
    const selectedRowKeys = useAppSelector((state) => state.checkIns.checkInSelectedRowKeys)
    const checkInData = useAppSelector((state) => state.checkIns.checkInData)
    const checkInTableSettings = useAppSelector((state) => state.checkIns.checkInTableSettings)
    const selectedTemplates = useAppSelector((state) => state.messageTemplates.selectedTemplates);
    const messageTemplatesData = useAppSelector(state => state.messageTemplates.messageTemplatesData);
    const [loading, setLoading] = useState<boolean>(true)

    const {setFeedbackVisible} = useModals()
    const [initialCheckInData, setInitialCheckInData] = usePersistedState("checkInList", {});
    const checkInsProps = useCheckInsApiWithRedux()

    const {
        checkInsApiCancel,
        initCheckInTableFilters,
        checkInTableUpdate,
        onSelectChange,
        updateCheckins,
        handleCheckInsTableChange,
        dateChange,
        onUpdateFeedbackGivenColumn,
    } = checkInsProps

    const feedbackProps = useFeedbackApiWithRedux()
    const {addFeedbacks, setCurrentFeedbackDeleteClear,feedbackUpdate} = feedbackProps
    const {clientTypesData} = useClientTypesApiWithRedux();


    const messageTemplatesProps = useMessageTemplatesApiWithRedux()

    const resetSelectedRows = async () => onSelectChange([])

    const handleDateChange = (date: FixMeLater) => {
        setInitialCheckInData({
            ...initialCheckInData,
            f_startDate: !!date ? date[0]?.format(DATE_FORMAT) : null,
            f_endDate: !!date ? date[1]?.format(DATE_FORMAT) : null,
            page: 1,
        })
        dateChange(date);
    }

    const handleTableChange = <T,>(pagination: TablePaginationConfig, filters: CheckInTableSettings, sorter: SorterResult<T> | SorterResult<T>[]) => {
        const {pageSize, current} = pagination
        const {columnKey, order} = sorter as SorterResult<T>;
        setInitialCheckInData({
            ...initialCheckInData,
            ...filters,
            pageSize,
            page: isFiltersChange({
                prevFilters: checkInTableSettings,
                currentFilters: filters,
            })
                ? 1
                : current,
            s_field:
                !!columnKey && !!order
                    ? (columnKey as string).startsWith("f_")
                        ? (columnKey as string).slice("f_".length)
                        : columnKey
                    : null,
            s_direction: !!order ? changeSortDirectionTitle(order) : null,
        })

        handleCheckInsTableChange<T>(pagination, filters, sorter)
    }

    const saveFeedback = async (values: ISaveFeedback) => {
        let notes = values.notes
        let Author = values.Author
        const selectedTemplatesId = messageTemplatesData
            .filter((template) => selectedTemplates.find(templateName => template.name === templateName || template.id === templateName))
            .map(({id}) => id);
        if (selectedRowKeys.length > 0 && checkInData.length > 0) {
            //Create check-in feedback records & save
            const requests = selectedRowKeys.map((id) => {
                let checkIn = checkInData.find((e) => e.id === id)
                let feedback = {
                    notes,
                    checkInId: id,
                    selectedTemplates: selectedTemplatesId,
                    Author,
                    userId: checkIn?.userId,
                    date: moment().parseZone().format(),
                    internal: false,
                    // ...values.partsRequest,
                    // ...values.activationRequest,
                }
                return {feedback, notify: true, user: checkIn!.User}
            })
            const data = await addFeedbacks(requests)

            if (Array.isArray(data)) {
                if (data.every((elem) => !!elem)) {
                    antNotificationComponent({
                        type: "success",
                        message: (
                            <Space direction="vertical">
                                Feedback saved. Do you want to mark the check ins as complete?
                                <Button type="primary" onClick={() => completeSelected(true)}>
                                    Yes Please
                                </Button>
                            </Space>
                        ),
                        key: "feedbackSuccess",
                    })
                    onUpdateFeedbackGivenColumn(selectedRowKeys);
                    await resetSelectedRows()
                }
                return data
            }
        }
    }

    const completeSelected = async (value: boolean) => {
        antNotificationClose("feedbackSuccess")
        const data = await updateCheckins(value)
        if (data.every((elem) => !!elem)) {
            antNotificationComponent({type: "success", message: "Saved"})
            resetSelectedRows()
            checkInTableUpdate()
        }
    }

    useEffect(() => {
        let cleanupFunction = false
        const fetchData = async () => {
            setLoading(true)
            initCheckInTableFilters({
                ...initialCheckInData,
            })

            if (!cleanupFunction) {
                setLoading(false)
            }
        }
        fetchData()
        return () => {
            cleanupFunction = true
            checkInsApiCancel()
        }
    }, [])

    return (
        <Space className={"page_wrapper_style"} direction="vertical">
            <CheckinPageHeader
                {...checkInsProps}
                completeSelected={completeSelected}
                showFeedback={setFeedbackVisible}
                handleDateChange={handleDateChange}
            />
            {!loading ? (
                <CheckinListTable
                    {...checkInsProps}
                    resetSelectedRows={resetSelectedRows}
                    handleTableChange={handleTableChange}
                />
            ) : null}
            <FeedbackModal
                {...messageTemplatesProps}
                setCurrentFeedbackDeleteClear={setCurrentFeedbackDeleteClear}
                addFeedback={saveFeedback}
                setVisible={setFeedbackVisible}
                modal={true}
            />
        </Space>
    )
}

export default withSidebarNavigation(CheckInList, "/checkins")
