import React, { useEffect } from "react"
import { useParams } from "react-router-dom"
import { useAppDispatch, useAppSelector } from "redux/store"
import useApi from "hooks/useApi"
import {
  changeSortDirectionTitle, 
  getSubstringText,
  isFiltersChange,
  Logger,
} from "utils/helpers"
import {
  IUsersTableSettings,
  setCoachesFilterOptions,
  setSelectedUsers,
  setUsers,
  setUsersTableSettings,
  setUserTableUpdated,
  usersReducerReset,
} from "redux/reducers/usersReducer"
import { admin, user } from "vars";
import { FilterValue, SorterResult, TablePaginationConfig } from 'antd/lib/table/interface';
import { IClient, ICoach, IUser } from "Interfaces/User";
import { ISuccessStatus } from "Interfaces/Auth";
import { F_extra } from "Types";


const logger = Logger("UsersApiWithRedux")

export interface IUserForm {
  firstName: string,
  lastName: string,
  email: string,
  password?: string,
  confirmPassword?: string,
  clientType: string,
  disabled?: boolean,
  competing?: boolean,
  testAccount?: boolean,
  coachId: string | null,
  phoneNumber?: string | null
};



function useUsersApiWithRedux() {
  const { API, cancel } = useApi()
  const dispatch = useAppDispatch()
  const {id} = useParams() as {id: string}
  const authUser = useAppSelector((state) => state.auth.user) as IUser;
  const usersTableSettings = useAppSelector((state) => state.users.usersTableSettings)
  const { f_extra, s_field } = usersTableSettings

  const getUsersData = async (query: IUsersTableSettings) => {
    const params = { ...query, f_roles: user }

    if (!!id) {
      params.f_coachId = [id]
    }
    try {
      const response = await API.get<{users: IClient[], totalCount: number}>("/api/user", { params })
      return response.data
    } catch (e) {
      if(e instanceof Error){
        logger.error(e.message)
      }
    }
  }

  const getAllUsersData = async (params: IUsersTableSettings) => {
    try {
      const response = await API.get<IClient[]>("/api/user/list", { params })
      return response.data
    } catch (e) {
      if(e instanceof Error){
        logger.error(e.message)
      }
    }
  }

  const getUsersCoachData = async (query: IUsersTableSettings) => {
    const params = { ...query, f_roles: "user" }

    if (!!id) {
      params.f_coachId = [id]
    }

    try {
      const response = await API.get("/api/user/coach", { params })
      return response.data
    } catch (e) {
      if(e instanceof Error){
        logger.error(e.message)
      }
    }
  }

  const getCoachData = async () => {
    try {
      if(authUser.roles === admin){
        const response = await API.get<IUser[]>("/api/user/coach-candidates")
        return response.data
      }
      const response = await API.get<{users: ICoach[], totalCount: number}>('/api/user', {
        params: {
          f_roles: 'coach',
          f_managerId: authUser.id
        }
      })
      return  [authUser,...response.data.users];
    } catch (e) {
      if(e instanceof Error){
        logger.error(e.message)
      }
    }
  }

  const userUpdateData = async (data: Partial<IUser>) => {
    try {
      const response = await API.post<IClient>("/api/user", data)
      return response.data
    } catch (e) {
      if(e instanceof Error){
        logger.error(e.message)
      }
    }
  }

  const usersRemoveCoach = async (data: {id: React.Key}[]) => {
    try {
      const response = await API.put<ISuccessStatus>("/api/user/remove-coach", { users: data })
      return response.data
    } catch (e) {
      if(e instanceof Error){
        logger.error(e.message)
      }
    }
  }

  const usersAssignCoach = async (data: {coachId: string, users: {id: string | React.Key}[] }) => {
    try {
      const response = await API.put<ISuccessStatus>("/api/user/assign-coach", data)
      return response.data
    } catch (e) {
      if(e instanceof Error){
        logger.error(e.message)
      }
    }
  }

  const getUsers = async (query = {}) => {
    const data = await getUsersData(query)
    if(data){
      dispatch(setUsers(data ?? { totalCount: 0, users: [] }))
    }
  }

  const getAllUsers = async (query = {}) => {
    const data = await getAllUsersData(query)
    if(data){
      dispatch(setUsers({ users: data, totalCount: data.length }))
    }
  }

  const getUsersCoach = async (query = {}) => {
    const data = await getUsersCoachData(query)
    dispatch(setUsers(data))
  }

  const usersTableUpdate = () => {
    dispatch(setUserTableUpdated({ lastUpdated: Date.now() }))
  }

  const getSort = ({ columnKey, order, column }: SorterResult<IClient>) => {
    if (!column) {
      return { s_field: null, s_direction: null }
    }

    return {
      s_field: (columnKey as string)?.startsWith("f_")
        ? (columnKey as string)?.slice("f_".length)
        : columnKey,
      s_direction: !!order ? changeSortDirectionTitle(order) : null,
    }
  }

  const handleUsersTableChange = (pagination: TablePaginationConfig, filters: Record<string, FilterValue | null>, sorter: SorterResult<IClient> | SorterResult<IClient>[]) => {
    const { pageSize, current } = pagination
    dispatch(
      setUsersTableSettings({
        ...filters,
        pageSize,
        page: isFiltersChange({
          prevFilters: usersTableSettings,
          currentFilters: filters,
        })
          ? 1
          : current,
        ...getSort(sorter as SorterResult<IClient>),
      })
    )
    usersTableUpdate()
  }

  const initUsersTableFilters = (data: IUsersTableSettings) => {
    dispatch(setUsersTableSettings({ ...data }))
  }

  const handleUsersSearch = () => {
    dispatch(setUsersTableSettings({ page: 1 }))
    usersTableUpdate()
  }

  const handleRemoveCoach = async (data: {id: React.Key}[]) => {
    const removedData = await usersRemoveCoach(data)
    usersTableUpdate()
    return removedData
  }

  const handleAssignCoach = async (coachId: string, users: {id: string | React.Key}[] = []) => {
    const data = await usersAssignCoach({ coachId, users })
    if (!!data) {
      usersTableUpdate()
    }
    return data
  }

  const onSelectUsersChange = (selectedUsers: React.Key[]) => {
    dispatch(
      setSelectedUsers({
        selectedUsers,
      })
    )
  }

  const changeSearchValue = (value: string) => {
    dispatch(
      setUsersTableSettings({
        f_clientFullNameOrEmail: !!value.trim() ? value : null,
      })
    )
  }

  const getCoachesFilterOptions = async () => {
    const coachData = await getCoachData()
    if (Array.isArray(coachData)) {
      const coachFilterOptions = coachData
        .map((coach) => ({
          text: getSubstringText(coach.fullName),
          value: coach.id,
        }))
        .sort((a, b) => a.text.localeCompare(b.text))

      const managerCoaches = [
          ...coachFilterOptions
      ];

      const coaches = [
        { text: "No Coach", value: (null) as unknown as string },
        {
          text: "Has Coach",
          value: "has",
        },
        ...coachFilterOptions,
      ]

      dispatch(setCoachesFilterOptions({ coaches: authUser.roles === admin ? coaches : managerCoaches }));
    }
  }
  const getCoachesSelectOptions = async () => {
    const coachData = await getCoachData()
    if (Array.isArray(coachData)) {
      const sortedCoachData = coachData.sort((a, b) =>
        a.fullName.localeCompare(b.fullName)
      )
      return sortedCoachData.map((coach) => {
        return { label: getSubstringText(coach.fullName), value: coach.id }
      })
    }
  }

  const userUpdate = async <T extends Partial<IUser>> (object: T) => {
    const data = await userUpdateData({ ...object, roles: user})
    if (!!data) {
      usersTableUpdate()
    }
    return data
  }

  const setExtraUserFilter = (value: F_extra) => {
    if (s_field === 'missedWeekCount' && value !== 'mia') {
      dispatch(
        setUsersTableSettings({
          s_field: null
        })
      )
    };

    dispatch(
      setUsersTableSettings({
        f_extra: f_extra !== value ? value : null,
        page: 1,
      })
    )
    usersTableUpdate()
  }

  const setExtraUserSort = (value: string) => {
    dispatch(
      setUsersTableSettings({
        s_field: s_field !== value ? value : null,
        s_direction: s_field !== value ? "ASC" : null,
      })
    )
    usersTableUpdate()
  }



  useEffect(() => () => {dispatch(usersReducerReset())}, [])

  return {
    usersApiCancel: cancel,
    getUsers,
    getAllUsers,
    getUsersCoach,
    getCoachesFilterOptions,
    handleUsersTableChange,
    changeSearchValue,
    handleUsersSearch,
    usersRemoveCoach,
    handleRemoveCoach,
    userUpdate,
    usersTableUpdate,
    getCoachesSelectOptions,
    handleAssignCoach,
    setExtraUserFilter,
    setExtraUserSort,
    onSelectUsersChange,
    usersAssignCoach,
    initUsersTableFilters,
  }
}

export default useUsersApiWithRedux
