import {useEffect} from "react";
import { useAppDispatch, useAppSelector } from "redux/store";
import {IActivityData, setActivity} from "redux/reducers/activityReducer";
import {Logger} from "utils/helpers";
import {useParams} from 'react-router-dom'
import { ISuccessStatus } from "Interfaces/Auth";
import useApi from "./useApi";
import { CurrentEvent } from "Types";
import {VIEW_PROFILE} from "../vars";


const logger = Logger("hooks/useActivityLog.js")

export default function useActivityLog() {
    const activity = useAppSelector(state => state.activity)
    const {API} = useApi();
    const params = useParams() as {id: string};
    const dispatch = useAppDispatch()
    const dispatchSetActivity = async ({activityType, activityData}: {activityType: CurrentEvent | null, activityData: IActivityData}) => {
        const data = {action: activityType || VIEW_PROFILE, ...activityData};
        logger.debug("Send request to api/activity/start: ", data);
        await API.post('api/activity/start', data)
        dispatch(setActivity({activityType, activityData}))
    }

    const checkActivity = async () => {
        try{
            const response = await API.post<ISuccessStatus>('/api/user/refresh');
            return response.data.success;
        }catch (e) {
            if(e instanceof Error){
                logger.error(e.message)
            }
        }
    }

    // const dispatchUnsetActivity = async (sendStop = true) => {
    //     if (sendStop) {
    //         await API.post('api/activity/stop')
    //     }

    //     const data = {action: null, activityData: null};
    //     dispatch(setActivity(data))
    // }

    useEffect(() => {
        async function whenInFocus() {
            logger.info("Focus window event happened. Starting activity...");
            await dispatchSetActivity({activityType: activity.activityType, activityData: {clientId: params.id}});
        }
        async function whenBlur() {
            logger.info("Blur window event happened. Stopping activity...");
            await API.post('api/activity/stop');
        }
        window.onfocus = whenInFocus;
        window.onblur = whenBlur;

        return ()=>{
            window.onfocus = null
            window.onblur = null
        }

    }, [])

    useEffect(() => {
        const unloadEvent = (e: Event) => {
            logger.info("Onload event");
            e.preventDefault();
            return API.post('api/activity/stop')
        };
        document.addEventListener("beforeunload", unloadEvent);
        return () => {
            document.removeEventListener("beforeunload", unloadEvent);
            API.post('api/activity/stop')
        }
    }, [])

    return {dispatchSetActivity, checkActivity}
}