import React, { FC } from 'react';
import {Typography} from "antd";
import { useAppSelector } from 'redux/store';
import { Modal } from 'components/Antd/Modal';
const {Text} = Typography;

interface Props {
    title?: React.ReactNode,
    message: React.ReactNode,
    onCancel: () => void,
    handleOk: (id: string) => Promise<void>
}

const FeedbackModalRemove: FC<Props> = ({handleOk, onCancel, title, message}) => {
    const removeFeedbackModalVisible = useAppSelector(state => state.modals.removeFeedbackModalVisible);
    const currentFeedback = useAppSelector(state => state.feedback.currentFeedback!);

    return  <Modal
        title="Remove Feedback"
        visible={removeFeedbackModalVisible}
        centered
        onOk={() => handleOk(currentFeedback.id)}
        onCancel={onCancel}
    >
        <Text>{title}</Text>
        <Text>{message}</Text>
    </Modal>

}

export default FeedbackModalRemove;