import React, {useEffect, useState} from 'react'
import moment, { Moment } from 'moment'
import {
    getFormatDuration,
    getWednesdayThisWeek,
    getLastWeekDate,
    Logger,
    getActivityTitle,
    getSubstringText, getPopoverTextWithIcon
} from 'utils/helpers'
import {DatePicker, Input,} from 'antd';
import {actionData, DATE_FORMAT_HOURS_MINUTES, DATE_FORMAT_HOURS_MINUTES_SECONDS} from "vars";
import ActivityListFooter from "./ActivityListFooter";
import ActivityUsersFilterComponent from "./ActivityUsersFilterComponent";
import {SearchOutlined, FilterFilled} from "@ant-design/icons";
import { useAppSelector } from 'redux/store';
import { ColumnProps } from 'antd/lib/table';
import { IActivity } from 'Interfaces/Activity';
import { ITableSettings } from 'redux/reducers/activityReducer';
import { IClient } from 'Interfaces/User';

const logger = Logger("activity/activity_table_config.js")

interface Props {
    handleSelect: (params: ITableSettings) => void
}

const TableConfig= ({handleSelect}: Props) => {
    const candidates = useAppSelector(state => state.candidates.candidatesData)
    const users = useAppSelector(state => state.users.usersData)
    const tableSettings = useAppSelector(state => state.activity.tableSettings)
    const {f_startDate, f_endDate, f_clientId, f_actorId, f_action} = tableSettings

    const [checkedData, setCheckedData] = useState<string[]>([])
    const [dataSource, setDataSource] = useState<IClient[]>([])
    const [searchValue, setSearchValue] = useState<string>('')
    // const [validValue, setValidValue] = useState(true);

    const handleResetUserFilters = () => {
        setCheckedData([])
        setSearchValue('')
        handleSelect({f_clientId:null})
    }

    const onChangeSearchValue = (value: string, checkedData: string[]) => {
        // setValidValue(value.length > 2)
        if (value.length > 2) {
            const newData = users.filter(user => user.fullName.toLowerCase().includes(value.toLowerCase()) || checkedData.some(id => id === user.id))
            setDataSource(newData)
        } else {
            setDataSource(users.filter(user => checkedData.some(id => id === user.id)))
        }
    }

    useEffect(() => {
        logger.info("Loading checked data")
        if (!!f_clientId?.length) {
            setCheckedData(f_clientId as string[])
        }
    }, [f_clientId?.length])

    useEffect(() => {
        onChangeSearchValue(searchValue, checkedData)
    }, [checkedData.length, searchValue, users.length])

    const {RangePicker} = DatePicker;

    const getColumns: ColumnProps<IActivity>[] = [
        {
            title: 'Start Date',
            key: 'f_startDate',
            width: '20%',
            sorter: true,
            render: (_, data) => `${!!data.startDate ? moment(data.startDate).format(DATE_FORMAT_HOURS_MINUTES) : ''} - ${!!data.endDate ? moment(data.endDate).format(DATE_FORMAT_HOURS_MINUTES) : ''}`,
            filterIcon: <FilterFilled className={`${!!f_startDate ? "activity_active_element_blue_color" : ""}`}/>,
            filterDropdown: ({confirm}) => {
                return <RangePicker
                    defaultValue={[!!f_startDate ? moment(f_startDate, DATE_FORMAT_HOURS_MINUTES_SECONDS) : null, !!f_endDate ? moment(f_endDate, DATE_FORMAT_HOURS_MINUTES_SECONDS) : null]}
                    ranges={{
                        'This Week': [getWednesdayThisWeek(moment()) as Moment, moment().parseZone()],
                        'Last Week': getLastWeekDate(moment(), moment()) as [Moment, Moment],
                    }}
                    showTime
                    format={DATE_FORMAT_HOURS_MINUTES_SECONDS}
                    onChange={(date) => {
                        handleSelect({
                            f_startDate: !!date && !!date[0] ? date[0].format(DATE_FORMAT_HOURS_MINUTES) : null,
                            f_endDate: !!date && !!date[1] ? date[1].format(DATE_FORMAT_HOURS_MINUTES) : null
                        })
                        confirm({closeDropdown: true});
                    }}/>
            },
            // filteredValue: f_startDate,
        },
        {
            title: 'Coach',
            dataIndex: 'actorFullName',
            key: 'f_actorId',
            sorter: true,
            render: (_,r) => getPopoverTextWithIcon(r.actorFullName),
            filters: [...candidates].sort((a,b) => a.fullName.localeCompare(b.fullName)).map(candidate => ({text: getSubstringText(candidate.fullName), value: candidate.id})),
            filteredValue: f_actorId as string[],
            width: '20%',
        },
        {
            title: 'Client',
            dataIndex: 'clientFullName',
            key: 'f_clientId',
            render: (_,r) => getPopoverTextWithIcon(r.clientFullName),
            sorter: true,
            width: '20%',
            filterIcon: <SearchOutlined
                className={`${checkedData.length > 0 ? "activity_active_element_blue_color" : ""}`}/>,
            filterDropdown: ({confirm}) => (
                <div className="activity_client_filter_wrapper">
                    <div className="activity_client_filter_body">
                        <Input type={'text'} value={searchValue} placeholder="Enter three letters"
                               onChange={(e) => setSearchValue(e.target.value)}/>
                        {/*<div*/}
                        {/*    className="activity_client_filter_validation_text">{!validValue ? 'Enter three letters.' : null}</div>*/}
                    </div>
                    <ActivityUsersFilterComponent
                        dataSource={dataSource}
                        checkedData={checkedData}
                        setCheckedData={setCheckedData}/>
                    <ActivityListFooter 
                        confirm={confirm} 
                        checkedData={checkedData}
                        handleResetUserFilters={handleResetUserFilters}
                        handleSelect={handleSelect}/>
                </div>
            ),
            filteredValue: f_clientId as string[],
        },
        {
            title: 'Action',
            sorter: true,
            dataIndex: 'action',
            key: 'f_action',
            filters: actionData,
            filteredValue: f_action as string[],
            render: (text) => (getActivityTitle(text))
        },
        {
            title: 'Duration',
            dataIndex: 'duration',
            key: 'duration',
            sorter: true,
            render: (duration) => {
                if (!duration && duration !== 0) {
                    return null;
                }
                return getFormatDuration(duration)
            }
        },
    ]

    return {getColumns}
};

export default TableConfig
