import React from 'react';
import {Col, Image, Row, Tag} from "antd";
import nounClient2 from "../../img/noun-client2.svg";
import {admin, coach, manager} from "../../vars";
import nounCoach from "../../img/noun-coach.svg";
import nounCheckin from "../../img/noun-checkin.svg";
import nounLate from "../../img/noun-late.svg";
import nounWarningWhite from "../../img/noun-warning-white.svg";
import nounWarningBlack from "../../img/noun-warning-black.svg";
import {shallowEqual} from "react-redux";
import WidgetPageCard, { IWidgetPageCardProps } from "./WidgetPageCard";
import withPermission from "../../HOCs/withPermission";
import { useAppSelector } from 'redux/store';



const DashboardPageCardFirstCol = () => {
    const activeClients = useAppSelector(state => state.dashboard.activeClients!)
    const totalClients = useAppSelector(state => state.dashboard.averageCompanyClients!, shallowEqual);
    const [activeCheckedIn, activeLate] = useAppSelector(state => state.dashboard.statsCheckins!, shallowEqual)
    const miaAverageTotal = useAppSelector(state => state.dashboard.miaAverageTotal!, shallowEqual)
    const assignedCoaches = useAppSelector(state => state.dashboard.assignedCoaches!, shallowEqual);
    const managersData = useAppSelector(state => state.managers.managersData);
    const coachesClients = useAppSelector(state => state.dashboard.coachClientsData);
    const selectedCoachId = useAppSelector(state => state.dashboard.selectedCoachId)
    const averageCompanyMiaTotal = useAppSelector(state => state.dashboard.averageCompanyMiaTotal!);
    const [companyActiveCheckedIn, companyActiveLate, companyInactiveMissedOne, companyInactiveMissedMia] = useAppSelector(state => state.dashboard.averageCompanyStats);
    const selectedUser = managersData.find(({id}) => id === selectedCoachId);



    const missedOneCheckIn = {
        type: 'Missed 1 Check In',
        value: coachesClients.filter(({id}) => id)
            .reduce((prev, {inactiveMissedOneWeek}) => prev + inactiveMissedOneWeek, 0) || 0
    };

    const missedMiaCheckIn = {
        type: 'Missed 2+ (MIA)',
        value: coachesClients.filter(({id}) => id)
            .reduce((prev, {inactiveMissedMoreThanTwoWeeks}) => prev + +inactiveMissedMoreThanTwoWeeks, 0) || 0
    };

    const WidgetPageCardWithWrapper = (props: IWidgetPageCardProps) => (
        <Col span={24}>
            <WidgetPageCard {...props}/>
        </Col>)

    const WidgetPageCardsWithWrapper = ({user, company}: {user: IWidgetPageCardProps, company: IWidgetPageCardProps}) => (
        <>
            <Col span={12}>
                <WidgetPageCard {...user} />
            </Col>
            <Col span={12}>
                <WidgetPageCard {...company} />
            </Col>
        </>
    )
    const WidgetPageCardWithPermission = (withPermission(WidgetPageCardWithWrapper));
    const WidgetPageCardsWithPermission = (withPermission(WidgetPageCardsWithWrapper))



    return (
        <Row>
            <WidgetPageCardsWithPermission
                user={{
                    data: {type: 'Active Clients', value: +activeCheckedIn?.value + +activeLate?.value || 0},
                    suffix: <>
                        <Tag>{activeClients?.value || 0}</Tag>
                        <Tag color="purple">{Math.ceil((+activeCheckedIn?.value + +activeLate?.value) / +activeClients?.value * 100) || 0}%</Tag>
                    </>,
                    children: <Image src={nounClient2} preview={false} />,
                }}
                company={{
                    data: {type:'Active Clients', value: +companyActiveCheckedIn?.value + +companyActiveLate?.value || 0},
                    suffix: <>
                        <Tag>{totalClients?.value || 0}</Tag>
                        <Tag color="purple">{Math.ceil((+companyActiveCheckedIn?.value + +companyActiveLate?.value) / +totalClients?.value * 100) || 0}%</Tag>
                    </>,
                    children: <>
                        <Image src={nounClient2} preview={false} />
                        <Tag className="dashboard_kpi_card_label" color="purple">Company</Tag>
                    </>,
                }}
                roles={[admin, coach, manager]}
            />
            {
                selectedUser?.roles === manager && <WidgetPageCardWithPermission
                    data={{type: 'Assigned Coaches', value: assignedCoaches?.value || 0}}
                    children={<Image src={nounCoach} width={60} preview={false} />}
                    roles={[admin]}
                />
            }
            <WidgetPageCardsWithPermission
                user={{
                    data: {type: 'Check Ins Received', value: activeCheckedIn?.value || 0},
                    suffix: <>
                        <Tag>{activeClients?.value || 0}</Tag>
                        <Tag color="purple">{Math.ceil(+activeCheckedIn?.value / +activeClients?.value * 100) || 0 }%</Tag>
                    </>,
                    children:<Image src={nounCheckin} preview={false} />
                }}
                company={{
                    data: {type: 'Check Ins Received', value: companyActiveCheckedIn?.value || 0},
                    suffix:<>
                        <Tag>{totalClients?.value || 0}</Tag>
                        <Tag color="purple">{Math.ceil(+companyActiveCheckedIn?.value / +totalClients?.value * 100) || 0}%</Tag>
                    </>,
                    children: <>
                        <Image src={nounCheckin} preview={false} />
                        <Tag className="dashboard_kpi_card_label" color="purple">Company</Tag>
                    </>
                }}
                roles={[coach, admin, manager]}
            />
            <WidgetPageCardsWithPermission
                user={{
                    data: {type: 'Check Ins Late (Clients)', value: activeLate?.value || 0},
                    suffix: <>
                        <Tag>{+activeCheckedIn?.value + +activeLate?.value || 0}</Tag>
                        <Tag color="purple">{Math.ceil(+activeLate?.value / (+activeCheckedIn?.value + +activeLate?.value) * 100) || 0}%</Tag>
                    </>,
                    children: <Image src={nounLate} preview={false} />
                }}
                company={{
                    data: {type: 'Check Ins Late (Clients)', value: companyActiveLate?.value || 0},
                    suffix: <>
                        <Tag>{+companyActiveCheckedIn?.value + +companyActiveLate?.value || 0}</Tag>
                        <Tag color="purple">{Math.ceil(+companyActiveLate?.value / (+companyActiveCheckedIn?.value + +companyActiveLate?.value) * 100 || 0)}%</Tag>
                    </>,
                    children: <>
                        <Image src={nounLate} preview={false} />
                        <Tag className="dashboard_kpi_card_label" color="purple">Company</Tag>
                    </>
                }}
                roles={[admin, coach, manager]}
            />
            <WidgetPageCardsWithPermission
                user={{
                    data: {type: 'Missed 1 Check In', value: missedOneCheckIn?.value || 0},
                    suffix: <>
                        <Tag>{activeClients?.value || 0}</Tag>
                        <Tag color="purple">{Math.ceil(missedOneCheckIn?.value / +activeClients?.value * 100) || 0}%</Tag>
                    </>,
                    children: <Image src={nounWarningWhite} preview={false} />
                }}
                company={{
                    data: {type: 'Missed 1 Check In', value: companyInactiveMissedOne?.value || 0},
                    suffix: <>
                        <Tag>{totalClients?.value || 0}</Tag>
                        <Tag color="purple">{Math.ceil(+companyInactiveMissedOne?.value / +totalClients?.value * 100) || 0}%</Tag>
                    </>,
                    children:<>
                        <Image src={nounWarningWhite} preview={false} />
                        <Tag className="dashboard_kpi_card_label" color="purple">Company</Tag>
                    </>
                }}
                roles={[admin, coach, manager]}
            />
            <WidgetPageCardsWithPermission
                user={{
                    data: {type: 'Missed 2+ (MIA)', value: missedMiaCheckIn?.value || 0},
                    children: <Image src={nounWarningBlack} preview={false} />,
                    suffix: <>
                        <Tag>{activeClients?.value || 0}</Tag>
                        <Tag color="purple">{Math.ceil(missedMiaCheckIn?.value / +activeClients?.value * 100) || 0}%</Tag>
                    </>,
                }}
                company={{
                    data: {type: 'Missed 2+ (MIA)', value: companyInactiveMissedMia?.value || 0},
                    suffix: <>
                        <Tag>{totalClients?.value || 0}</Tag>
                        <Tag color="purple">{Math.ceil(+companyInactiveMissedMia?.value / +totalClients?.value * 100) || 0}%</Tag>
                    </>,
                    children: <>
                        <Image src={nounWarningBlack} preview={false} />
                        <Tag className="dashboard_kpi_card_label" color="purple">Company</Tag>                    </>
                }}
                roles={[admin, coach, manager]}
            />
            <WidgetPageCardsWithPermission
                user={{
                    data: {type: 'MIA Last Contact', value: Math.floor(+miaAverageTotal?.value) || 0},
                    suffix: <Tag>Day(s)</Tag>,
                    children:<Image src={nounLate} preview={false} />
                }}
                company={{
                    data: {type: 'MIA Last Contact', value: Math.floor(+averageCompanyMiaTotal?.value) || 0},
                    suffix: <Tag>Day(s)</Tag>,
                    children: <>
                        <Image src={nounLate} preview={false} />
                        <Tag className="dashboard_kpi_card_label" color="purple">Company</Tag>
                    </>
                }}
                roles={[admin, coach, manager]}
            />
        </Row>

    )


};


export default DashboardPageCardFirstCol;