import React, {useEffect} from 'react'
import {setCurrentPage} from "../redux/actions/commonActions";
import { useAppDispatch } from 'redux/store';

// type ComponentType = typeof React.Component | React.ElementType;

function withSidebarNavigation<P extends {}>(Component: React.ComponentType<P>, path: string) {
  return function(props: P){
    const dispatch = useAppDispatch();
    useEffect(() => {
        dispatch(setCurrentPage(path))
    }, []);
    return <Component {...props} />
  }
}

// const withSidebarNavigation = (Component: ComponentType, path: string) => {
//     return (props) => {
//         const dispatch = useAppDispatch()
//         useEffect(()=>{
//             dispatch(setCurrentPage(path))
//         }, [])
//         return <Component {...props}/>
//     }
// }

export default withSidebarNavigation