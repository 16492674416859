import React, {useEffect, useState} from 'react';
import { useAppSelector } from 'redux/store';
import {Input, PageHeader, Space, Table, TablePaginationConfig} from "antd";
import usersTableConfig from "../user/users_page/users_table/users_table_config";
import {getQueryParams, tablePagination} from "utils/helpers";
import {CLIENT} from "../../vars";
import useUsersApiWithRedux from "services/users-service";
import useScrollTo from "hooks/useScrollTo";


export default function CoachMainUsersTable() {
    const {Search} = Input;
    const usersData = useAppSelector(state => state.users.usersData)
    const totalCount = useAppSelector(state => state.users.totalCount)
    const lastUpdated = useAppSelector(state => state.users.lastUpdated)
    const usersTableSettings = useAppSelector(state => state.users.usersTableSettings)
    const [loading, setLoading] = useState<boolean>(true)
    const {getUsersColumns} = usersTableConfig({path:'coach'})
    const {
        getUsers,
        handleUsersTableChange,
        changeSearchValue,
        handleUsersSearch
    } = useUsersApiWithRedux()

    const {
        pageSize,
        page,
        f_clientFullNameOrEmail,
    } = usersTableSettings

    const extras = (
        <Space size="large" direction="horizontal" align="end">
            <Search placeholder="Search" value={f_clientFullNameOrEmail || ""}
                    onChange={(e) => changeSearchValue(e.target.value)} onSearch={handleUsersSearch} enterButton/>
        </Space>
    )

    useEffect(() => {
        let cleanupFunction = false;
        const fetchData = async () => {
            setLoading(true)
            await getUsers(getQueryParams(usersTableSettings))
            if (!cleanupFunction) {
                setLoading(false)
            }
        }
        fetchData();
        return () => { cleanupFunction = true }
    }, [lastUpdated])

    const {scrollRef} = useScrollTo<HTMLDivElement>({dependency:[pageSize, page], offset:20})

    return (
        <>
            <PageHeader className="site-layout-background" extra={extras}/>
            <div ref={scrollRef}/>
            <Table key={CLIENT}
                   pagination={tablePagination({pageSize, page, totalCount, showSizeChanger: true,}) as TablePaginationConfig}
                   columns={getUsersColumns}
                   dataSource={usersData}
                   loading={loading}
                   onChange={handleUsersTableChange}
                   rowKey={(e) => e.id}
                   scroll={{x: 'auto'}}
            />
        </>
    )
}