import React, {FC, useEffect, useState} from 'react'
import {Button,Card, Form, Popover, Space} from "antd";
import {getRequestClassification} from "utils/helpers";
import {
    ACTIVATION_PART_ERROR,
    activationsOptions,
    allActivations,
    allParts,
    partsOptions,
} from "vars";
import "_ant-override-styles.less";
import ActivationPartsContent from "./ActivationPartsContent";
import { IActiovationParts } from 'services/feedback-service';
import { IFeedback_UserStateClassification } from 'Interfaces/Feedback';
import { TooltipPlacement } from 'antd/lib/tooltip';
interface Props {
    id: string,
    onSubmitActivationParts: ({ id, request }: any) => Promise<IFeedback_UserStateClassification | undefined>,
    userStateClassification: IFeedback_UserStateClassification[],
}

const ActivationPartsForm: FC<Props> = ({id,onSubmitActivationParts,userStateClassification}) => {

    const [userClassification] = userStateClassification;
    const [isDisabled, setIsDisabled] = useState(false);
    const [selectedPartsTags, setSelectedPartsTags] = useState<string[]>([]);
    const [selectedActivationTags, setSelectedActivationTags] = useState<string[]>([]);

    const onChangeParts = async (tag: string) => {
        const tags = !selectedPartsTags.includes(tag) ? [...selectedPartsTags, tag] : selectedPartsTags.filter(t => t !== tag)
        const request = {
            ...getRequestClassification({tags, options: allParts}),
            ...getRequestClassification({tags: selectedActivationTags[0], options: allActivations})
        };
        try {
            setIsDisabled(true);
            const response = await onSubmitActivationParts({id, request});
            if(response){
                setSelectedPartsTags(tags);
                setIsDisabled(false);
            }
        }catch (e) {
            if(e instanceof Error){
                if(e.message !== ACTIVATION_PART_ERROR){
                    setIsDisabled(false)
                }
                console.log(e.message)
            }
        }
    }

    const onChangeActivation = async (tag: string) => {
        const tags = selectedActivationTags.includes(tag) ? [] : [tag];
        const request = {
            ...getRequestClassification({tags: tags[0], options: allActivations}),
            ...getRequestClassification({tags: selectedPartsTags, options: allParts})
        };
        try {
          setIsDisabled(true);
          const response = await onSubmitActivationParts({id, request});
          if(response){
              setIsDisabled(false);
              setSelectedActivationTags(tags)
          }
        }catch (e) {
             if(e instanceof Error){
                if(e.message !== ACTIVATION_PART_ERROR){
                    setIsDisabled(false)
                }
                console.log(e.message)
            }
        }
    }

    useEffect(() => {
            if(userClassification){
                const parts = partsOptions.filter((item) => userClassification[item.value]).map(({value}) => value);
                const activation = activationsOptions.filter((item) => userClassification[item.value]).map(({value}) => value);
                setSelectedPartsTags(parts || []);
                setSelectedActivationTags(activation || []);
            }
    },[])

    return (
                <Card style={{maxWidth: 425}}>
                        <Form layout="vertical">
                        <Form.Item label="Activation:" className="activation_parts_buttons">
                            <Space size={[3,3]} wrap>
                                {
                                     activationsOptions.map((item) => (
                                        <Button
                                            key={item.value}
                                            disabled={isDisabled}
                                            onClick={() => onChangeActivation(item.value)}
                                            type={!!selectedActivationTags.includes(item.value) ? 'primary' : undefined}
                                        >
                                            {item.label}
                                        </Button>
                                    ))
                                }
                            </Space>
                        </Form.Item>
                        <Form.Item label="Parts:" className="activation_parts_buttons">
                            <Space size={[3,16]} wrap>
                                {
                                    partsOptions.map((item, index) => (
                                        <Popover
                                            overlayStyle={{maxWidth: 450}}
                                            placement={item.placement as TooltipPlacement}
                                            key={item.value}
                                            content={
                                                selectedActivationTags.length > 0 ?
                                                    selectedActivationTags.map((value) => <ActivationPartsContent
                                                        key={value}
                                                        withKeys={["Emotion","Thoughts", "Behaviours"]}
                                                        parts={[item.value]}
                                                        activations={[value]}
                                                    />)
                                                    : (activationsOptions.map(({value}) => <ActivationPartsContent
                                                    key={value}
                                                    withKeys={["Emotion","Thoughts", "Behaviours"]}
                                                    parts={[item.value]}
                                                    activations={[value]}
                                                />)
                                                )}
                                        >
                                            <Button
                                                disabled={isDisabled}
                                                onClick={() =>  onChangeParts(item.value)}
                                                type={selectedPartsTags.includes(item.value) ? 'primary' : undefined}
                                            >
                                                {item.label}
                                            </Button>
                                        </Popover>
                                    ))
                                }
                            </Space>
                        </Form.Item>
                        </Form>
                            {
                                selectedPartsTags.length > 0 && selectedActivationTags && <ActivationPartsContent
                                    withKeys={["Antidote"]}
                                    parts={selectedPartsTags}
                                    activations={selectedActivationTags}
                                />
                            }
                </Card>
    )
};


export default ActivationPartsForm;