import React from 'react';
import withPermission from "HOCs/withPermission";
import withSidebarNavigation from "HOCs/withSidebarNavigation";
import NotFoundPage from "../not-found/NotFoundPage";
import {Button, Input, PageHeader, Space, Table} from "antd";
import useClientTypesApiWithRedux, {IClientTypeForm} from "services/clientTypes-service";
import ClientTypeForm from "./ClientTypeForm";
import ClientTypeDeleteModal from "./ClientTypeDeleteModal";

const {Search} = Input


const ClientTypes = () => {

    const {
        columns,
        clientTypesData,
        isLoading,
        currentType,
        onFinish,
        onOpenCreateModal,
        onTableChange,
        onSearchTypes,
        onCancel,
        onDeleteClientType,
        onCloseDeleteModal
    } = useClientTypesApiWithRedux();

    const extras = (
        <Space size="large">
            <Button type="primary" onClick={onOpenCreateModal}>New</Button>
            <Search placeholder="Search" onSearch={onSearchTypes} enterButton />
        </Space>
    )

    const initialValues: IClientTypeForm = {
        name: currentType?.name || '',
        welcomePackUrl: currentType?.welcomePackUrl || '',
        enabled: currentType?.enabled || false,
        description: currentType?.description || ''
    };

    return (
        <Space className="page_wrapper_style" direction="vertical">
            <PageHeader
                className="site-layout-background"
                title="Program"
                extra={extras}
                onBack={() => window.history.back()}
            />
            <Table
            onChange={onTableChange}
            rowKey={rw => rw.id}
            scroll={{x: 'auto', scrollToFirstRowOnChange: true}}
            
            dataSource={clientTypesData}
            columns={columns}
            loading={isLoading}
            />
            <ClientTypeForm
            title={currentType ? "Edit Type" : "Create Type"}
            onFinish={onFinish}
            initialValues={initialValues}
            onCancel={onCancel}
            />
            <ClientTypeDeleteModal
                onCancel={onCloseDeleteModal}
                onFinish={onDeleteClientType}
                message={`Are you sure you want to delete the type?`}
            />
        </Space>
    )

};



export default withPermission(withSidebarNavigation(ClientTypes, '/client-types'), NotFoundPage);