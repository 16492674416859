import { ColumnsType } from "antd/lib/table/interface";
import { ICoachOverview } from "Interfaces/Dashboard";
import {getPopoverTextWithIcon} from "utils/helpers";

const DashboardPageCoachClientsTableConfig = () => {
    const getColumns: ColumnsType<ICoachOverview> =
    [
            {
                title: 'Name',
                key: 'fullName',
                render: (item) =>getPopoverTextWithIcon(item.fullName),
            },
            {
                title: 'Total',
                dataIndex: "count",
                key: 'count',
            },
            {
                title: 'Active',
                dataIndex: 'activeTotal',
                key: 'activeTotal'
            },
            {
                title: 'Inactive',
                dataIndex: 'inactiveTotal',
                key: 'inactiveTotal'
            },
            {
                title: 'Checked in',
                dataIndex: 'activeCheckinedNotLate',
                key: 'activeCheckinedNotLate',
            },
            {
                title: 'Late',
                dataIndex: 'activeCheckinedLate',
                key: 'activeCheckinedLate',
            },
            {
                title: 'Missed 1',
                dataIndex: 'inactiveMissedOneWeek',
                key: 'inactiveMissedOneWeek',
            },
            {
                title: 'Missed 2+',
                dataIndex: 'inactiveMissedMoreThanTwoWeeks',
                key: 'inactiveMissedMoreThanTwoWeeks',
            },
    ]

    return {getCoachClientsColumns: getColumns}
};

export default DashboardPageCoachClientsTableConfig
