import React, {useEffect, useState} from 'react';
import {Image, Space} from "antd";
import logo from "../../img/smp-logo.png";
import Form from "antd/lib/form";
import Input from "antd/lib/input";
import {LockOutlined} from "@ant-design/icons";
import Button from "antd/lib/button";
import {useHistory, useParams} from "react-router-dom";
import antNotificationComponent from "../../utils/notification-ant-utils";
import LoadingSpin from "../../components/loading-spin/LoadingSpin";
import {validatePassword, validateRequired} from "../../utils/helpers";
import { IMessageStatus } from 'Interfaces/Auth';
import useApi from 'hooks/useApi';

interface IResetForm {
    password: string,
    passwordRepeat: string,
};

const ResetPasswordValidate = () => {
    const {API} = useApi();
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(true);
    const [validLink, setValidLink] = useState(false);
    const [resetForm] = Form.useForm<IResetForm>();
    const {token} = useParams() as {token: string};


    const validateMatchPasswords = async () => {
        const newPassword = resetForm.getFieldValue('password');
        const repeatPassword = resetForm.getFieldValue('passwordRepeat');
        if(newPassword !== repeatPassword){
            return Promise.reject('Passwords do not match');
        }
        return Promise.resolve()
    };

    const onResetPassword = async ({password, passwordRepeat}: IResetForm) => {
        try {
            const response = await API.post<IMessageStatus>("api/auth/reset-password/confirm", {
                token,
                password: password,
                confirmPassword: passwordRepeat
            });
            if(response.data.message === 'Success'){
                antNotificationComponent({
                    type: 'success',
                    message: `Password has been changed`
                });

                return setTimeout(() => {
                    history.push("/login");
                }, 2000);
            }
        }catch (e) {
            if(e instanceof Error){
                antNotificationComponent({
                    type: 'error',
                    message: e.message
                })
            }
        }
    };

    useEffect(() => {
        (async () => {
            try {
                const checkToken = await API.get<IMessageStatus>('api/auth/reset-password/validate', {
                    params: {
                        token
                    }
                });
                if(checkToken.data.message === 'Token is valid'){
                    setIsLoading(false);
                    return setValidLink(true);
                }
            } catch(e) {
                setIsLoading(false);
                return setValidLink(false);
            }
        })();
    }, [token]);


    if(isLoading){
        return <LoadingSpin />
    }

    if(!validLink){
        return (
            <div className="login__form">
                <Space align={"center"} style={{width: '100%', justifyContent: 'center'}}>
                    <Image width={200} src={logo} preview={false}/>
                </Space>
                <div
                    style={{
                        textAlign: 'center'
                    }}
                    className="container__fluid-sm">
                       <h1>This Link is invalid</h1>
                    <Button
                        className="btn btn_mb25"
                        onClick={() => history.push('/login')}
                    >
                        Back to Login
                    </Button>
                    <Button
                        className="btn"
                        onClick={() => history.push('/reset-password')}
                    >
                        Back to Reset
                    </Button>
                </div>
            </div>
        )
    }


return (
    <div className="login__form">
        <Space align={"center"} style={{width: '100%', justifyContent: 'center'}}>
            <Image width={200} src={logo} preview={false}/>
        </Space>

        <div className="container-fluid-sm">
            <h1>Reset password:</h1>
            <Form name="normal_login" form={resetForm} className="login-form" initialValues={{password: '', passwordRepeat: ''}} onFinish={onResetPassword}>
                <Form.Item
                    name="password"
                    validateTrigger={['onBlur']}
                    rules={[() => ({
                        async validator(_, value) {
                            await validateRequired(value, 'Enter your new password');
                            await validatePassword(value)
                            return Promise.resolve(value);
                        },
                    }),]}
                >
                    <Input.Password
                        size="large"
                        prefix={<LockOutlined className="site-form-item-icon"/>}
                        type="password"
                        placeholder="Password"/>
                </Form.Item>
                <Form.Item
                    name="passwordRepeat"
                    validateTrigger={['onBlur']}
                    rules={[() => ({
                        async validator(_, value) {
                            await validateRequired(value, 'Enter your new password again');
                            await validateMatchPasswords()
                            return Promise.resolve(value);
                        },
                    }),]}
                >
                    <Input.Password
                        size="large"
                        prefix={<LockOutlined className="site-form-item-icon"/>}
                        type="password"
                        placeholder="Repeat password"
                    />
                </Form.Item>
                <Form.Item>
                    <Button htmlType="submit" className="btn">Reset Password</Button>
                </Form.Item>
            </Form>
        </div>
    </div>
)



};



export default ResetPasswordValidate;

