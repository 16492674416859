import React, {memo, useEffect, useState} from 'react';
import {MessageList, MessageInput, ChatContainer, ConversationHeader, Avatar} from "@chatscope/chat-ui-kit-react";
import useMessagesApi from "./useMessagesApi";
import _Message from "./_Message";
import useResize from "hooks/useResize";
import {getLastOnlineDate, getOnlineStatus, isMobileDevice} from "utils/helpers";
import {Typography} from "antd";
import {useHistory} from "react-router-dom";
import {FETCH_CHAT_PAGE_SIZE} from "vars";
import chat_avatar from "../../chat_avatar.png"
import {setSendSuccessMessage, setSendSuccessMessageLate} from "redux/reducers/chatReducer";
import { IChat } from 'Interfaces/Chat';
import { ResponseTypeChat } from 'Types';

interface MessageListProps {
    currentUser: IChat,
    responseType: ResponseTypeChat | null,
    searchList: IChat[]
}

const _MessageList = ({currentUser, responseType, searchList}: MessageListProps) => {
    const {messagesList, isLoading, totalCount, onSetClearCurrentUser, onSendMessage, getMoreUserMessages, messagesListWithError} = useMessagesApi({responseType, searchList});
    const {width} = useResize();
    const {push} = useHistory();
    const [isLoadingMore, setIsLoadingMore] = useState(false);
    const [page, setPage] = useState(1);
    const [value, setValue] = useState("");
    const onChange = (message: string) => setValue(message);

    const sendMessage = async (_: string, __: string, message: string) => {
        const onSendMessageCallback = responseType || searchList.length > 0 ? setSendSuccessMessageLate : setSendSuccessMessage;
        await onSendMessage(message, onSendMessageCallback);
        setValue("");
    }
    const onYReachStart = () => setIsLoadingMore(true);

    const getMoreLoadPage = async () => {
        await getMoreUserMessages({page: page + 1, pageSize: FETCH_CHAT_PAGE_SIZE});
            setTimeout(() => {
                setIsLoadingMore(false);
                setPage(p => p + 1);
            }, 500);
    };

    useEffect(() => {
        if(isLoadingMore && totalCount !== messagesList.length){
            getMoreLoadPage();
        } else {
            setIsLoadingMore(false);
        }
    }, [isLoadingMore]);

    useEffect(() => {
        setIsLoadingMore(false);
        setPage(1);
    }, [currentUser]);


    return (
        <ChatContainer>
            <ConversationHeader>
                {width < 768 && <ConversationHeader.Back onClick={onSetClearCurrentUser} />}
                <ConversationHeader.Content>
                    <a href={`/client/${currentUser.userId}`} rel="noreferrer" target="_blank" style={{display: 'flex'}}>
                        <Avatar status={getOnlineStatus(currentUser.lastOnline)} src={chat_avatar} />
                        <div style={{display: 'flex', flexDirection: 'column'}}>
                            <Typography.Title
                                style={{cursor: 'pointer', marginLeft: '0.4em', marginBottom: 0}}
                                // onClick={onRedirectToClientPage}
                                level={5}>{currentUser.userName}
                            </Typography.Title>
                            <Typography.Link style={{fontSize: '12px', marginLeft: '0.5em', color: 'rgba(111, 112, 116, 0.61)'}}>{getLastOnlineDate(currentUser?.lastOnline)}</Typography.Link>
                        </div>
                    </a>
                </ConversationHeader.Content>
            </ConversationHeader>
                <MessageList
                    disableOnYReachWhenNoScroll
                    loadingMore={isLoadingMore}
                    loading={isLoading}
                    onYReachStart={onYReachStart}
                >
                    {
                        [...messagesList].
                            reverse()
                            .map((message) => message.notes &&
                                <_Message key={message.id} {...message} />
                            )
                    }
                    {
                        [...messagesListWithError]?.
                        reverse()
                            .map((message) => message.notes &&
                                <_Message key={message.id} {...message} />
                            )
                    }
                </MessageList>
                <MessageInput
                    sendOnReturnDisabled={isMobileDevice()}
                    onBlur={() => window.scroll(0,0)}
                    attachButton={false}
                    onChange={onChange}
                    value={value}
                    onSend={sendMessage}
                    onAttachClick={(e) => {
                        debugger;
                    }}
                />
        </ChatContainer>
    )
};

export default memo(_MessageList);