import React from 'react';
import {
    activeRender,
    LateRender,
    macroRender,
    roleRender,
    UsersActionsRender,
    YesNoRender, CompleteRender, ActionsRender
} from "./users_table_config_render";
import {disabledFilterOptions, lowFilterOptions} from "./users_table_config_options";
import {
    changeSortDirectionTitle,
    getPhysiqueGoalText,
    getPopoverTextWithIcon,
} from "utils/helpers";
import moment from "moment";
import useClientTypesApiWithRedux from "services/clientTypes-service";
import { useAppSelector } from 'redux/store';
import { ColumnsType } from 'antd/lib/table';
import { IClient } from 'Interfaces/User';
import { SortTable } from 'Types';
import { DATE_FORMAT_HOURS_MINUTES } from './../../../../vars';

interface Props<T> {
    path?: string,
    actions?: {
        name: string,
        callback: (user: IClient) => void
    }[],
}

const getWeekLastCheckIn = (missedWeekCount: number | null) => missedWeekCount ? `${missedWeekCount}` : 'No checkIns';

const UsersTableConfig = <Actions>({path, actions}: Props<Actions>) => {
    const coachesFilterOptions = useAppSelector(state => state.users.coachesFilterOptions)
    const usersTableSettings = useAppSelector(state => state.users.usersTableSettings)
    const {clientTypesData} = useClientTypesApiWithRedux();
    const clientTypes = clientTypesData.map(({name}) => ({text: name, value: name}))

    const {
        f_ClientType,
        f_LowMacro,
        f_disabled,
        s_field,
        s_direction,
        f_coachId,
        f_isLateLastCheckIn,
        f_lastCheckInCompleted,
        f_isFeedbackDue,
        f_extra
    } = usersTableSettings

    const getColumns = (): ColumnsType<IClient> => {
        let columns: ColumnsType<IClient> = [
            {
                title: 'Full Name',
                key: 'fullName',
                dataIndex: "fullName",
                render: (_,r) => getPopoverTextWithIcon(r.fullName),
                sorter: true,
                sortOrder: s_field === 'fullName' ? (changeSortDirectionTitle(s_direction) as SortTable) : null
            },
            {
                title: 'Email',
                dataIndex: "email",
                key: 'email',
                sorter: true,
                sortOrder: s_field === 'email' ? (changeSortDirectionTitle(s_direction) as SortTable) : null
            },
            {
              title: 'Phone Number',
              dataIndex: 'phoneNumber',
              key: 'phoneNumber',
            },
            {
                title: 'Role',
                dataIndex: 'roles',
                key: 'roles',
                render: roleRender,
            },
            {
                title: 'Coach',
                dataIndex: 'Coach',
                key: 'f_coachId',
                render: (Coach) => !!Coach?.id ? getPopoverTextWithIcon(Coach.fullName) : '',
                filters: coachesFilterOptions,
                filteredValue: f_coachId
            },
            {
                title: 'Client Type',
                dataIndex: 'ClientType',
                key: 'f_ClientType',
                render: (clientType) => clientType?.name,
                filters: clientTypes,
                filteredValue: f_ClientType,
            },
            {
                title: 'Start Date',
                dataIndex: 'startDate',
                key: 'startDate',
            },
            {
                title: 'Physique Goal',
                dataIndex: 'physiqueGoal',
                key: 'physiqueGoal',
                render: (text) => !!text ? getPhysiqueGoalText(text) : ''
            },
            {
                title: 'Low',
                dataIndex: 'LowMacro',
                key: 'f_LowMacro',
                render: (t, r, i) => macroRender(t, r, i, "LowMacro", "lowMacroId"),
                filters: lowFilterOptions,
                filteredValue: f_LowMacro,
            },
            {
                title: 'Mid',
                dataIndex: 'StandardMacro',
                key: 'StandardMacro',
                render: (t, r, i) => macroRender(t, r, i, "StandardMacro", "standardMacroId"),
            },
            {
                title: 'High',
                dataIndex: 'HighMacro',
                key: 'HighMacro',
                render: (t, r, i) => macroRender(t, r, i, "HighMacro", "highMacroId"),
            },
            {
                title: '# Missed',
                dataIndex: 'missedWeekCount',
                key: 'missedWeekCount',
                render: getWeekLastCheckIn,
                className: 'table-column-title-no-break',
                sorter: true,
                sortOrder: s_field === 'missedWeekCount' ? (changeSortDirectionTitle(s_direction) as SortTable) : null
            },
            {
                title: 'Last Check-in Date',
                dataIndex: 'lastCheckInDate',
                key: 'lastCheckInDate',
                render: (text) => text && moment(text).format(DATE_FORMAT_HOURS_MINUTES),
                sorter: true,
                sortOrder: s_field === 'lastCheckInDate' ? (changeSortDirectionTitle(s_direction) as SortTable) : null
            },
            {
                title: 'Late Last Check-in',
                dataIndex: 'isLateLastCheckIn',
                key: 'f_isLateLastCheckIn',
                render: LateRender,
                filters: lowFilterOptions,
                filteredValue: f_isLateLastCheckIn,
            },
            {
                title: 'Last Check-in Complete',
                dataIndex: 'lastCheckInCompleted',
                key: 'f_lastCheckInCompleted',
                render: CompleteRender,
                filters: lowFilterOptions,
                filteredValue: f_lastCheckInCompleted,
            },
            {
                title: 'Last Client Communication',
                dataIndex: 'lastCheckInCommunicationDate',
                key: 'lastCheckInCommunicationDate',
                render: (text) => text && moment(text).format(DATE_FORMAT_HOURS_MINUTES),
                sorter: true,
                sortOrder: s_field === 'lastCheckInCommunicationDate' ? (changeSortDirectionTitle(s_direction) as SortTable) : null
            },
            {
                title: 'Last Feedback Given',
                dataIndex: 'lastFeedbackDate',
                key: 'lastFeedbackDate',
                render: (text) => text && moment(text).format(DATE_FORMAT_HOURS_MINUTES),
                sorter: true,
                sortOrder: s_field === 'lastFeedbackDate' ? (changeSortDirectionTitle(s_direction) as SortTable) : null
            },
            {
                title: 'Feedback Due',
                dataIndex: 'isFeedbackDue',
                key: 'f_isFeedbackDue',
                render: YesNoRender,
                filters: lowFilterOptions,
                filteredValue: f_isFeedbackDue,
            },
            {
                title: 'Status',
                dataIndex: "disabled",
                key: 'f_disabled',
                render: activeRender,
                filters: disabledFilterOptions,
                filteredValue: f_disabled,
            },
            {
                fixed: "right",
                title: 'Action',
                key: 'Action',
                render: ActionsRender,
            },
            {
                fixed: "right",
                title: 'Action',
                key: 'userAction',
                render: (t, r, i) => UsersActionsRender(t, r, i, actions),
            },
        ]

        let columnsFilter = ['lastCheckInDate', 'f_isLateLastCheckIn', 'f_lastCheckInCompleted', 'lastCheckInCommunicationDate', 'lastFeedbackDate', 'f_isFeedbackDue', 'missedWeekCount']

        switch (path) {
            case 'user': {
                columnsFilter.push('Action',  
                )
                return columns.filter((column) => !columnsFilter.includes(column.key as string))
            }
            case 'coach': {
                columnsFilter.push('f_coachId', 'userAction')
                return columns.filter((column) => !columnsFilter.includes(column.key as string))
            }
            case 'dashboard': {
                columnsFilter = [...columnsFilter, 'fullName', 'physiqueGoal', 'f_ClientType', 'Action']
                if (f_extra === 'mia') columnsFilter.push('missedWeekCount')
                return columns.filter((column) => columnsFilter.includes(column.key as string))
            }
            default: {
                return columns
            }
        }
    }

    return {getUsersColumns: getColumns()}
};

export default UsersTableConfig
