import {useEffect, useState} from "react";
import { useAppDispatch } from "redux/store";
import useApi from "../hooks/useApi";
import {Logger} from "../utils/helpers";
import {
    feedbackReducerReset,
    setCurrentFeedback, 
    setDeleteFeedback,
    setFeedbackData,
    setFeedbackUpdate, 
    setUpdateActivationsParts
} from "../redux/reducers/feedbackReducer";
    // @ts-ignore
import vader from "vader-sentiment";
import {setRemoveFeedbackModalVisible} from "../redux/reducers/modalsReducer";
import antNotificationComponent from "../utils/notification-ant-utils";
import moment from "moment";
import {setSelectedTemplates} from "../redux/reducers/messageTemplatesReducer";
import usePersistedStateWithoutIdKey from "../hooks/usePersistedStateWithoutIdKey";
import {useParams} from "react-router-dom";
// import {allActivations, allParts, user} from "../vars";
import { IFeedback, IFeedback_UserStateClassification, PaginatedFeedbacks } from "Interfaces/Feedback";
import { IUser } from "Interfaces/User";
import { IPartsClassification } from "Interfaces/UserClassification";
import { IMessageStatus } from "Interfaces/Auth";
import { UserType } from "Types";
import { ISendFeedbackModal } from "pages/checkin/feedbackModal";
import { AxiosError } from "axios";

const logger = Logger("FeedbackApiWithRedux")



export interface ISendFeedback {
    feedback: {
        Author: UserType,
        checkInId?: string | React.Key,
        date?: string,
        internal?: boolean,
        notes?: string,
        userId?: string,
        selectedTemplates?: string[],
        templates?: string[]
    },
    notify: boolean,
    user: UserType
};

export interface IEditFeedback {
    notes?: string,
    id?: string,
    selectedTemplates?: string[],
    text?: string,
    selectedCheckins?: string[],
    template?: string[],
    isMessage?: boolean,
    Author?: IUser,
}

export interface IPatchFeedback {
    id: string,
    isMessage?: boolean
}

export interface ICachedFeedback {
    id: string,
    notes: string,
    selectedTemplates: string[]
};

export interface IActiovationParts {
    id: string,
    request: IPartsClassification & IActiovationParts
}

export type FeedbackQueryParams = {
    isAutoGenerated?: boolean,
    isFeedback?: boolean,
    isMessage?: boolean,
    page: number,
    pageSize?: number
}

function useFeedbackApiWithRedux() {
    const {API, cancel} = useApi();
    const dispatch = useAppDispatch();
    const [isLoadingFeedbacks, setIsLoadingFeedbacks] = useState(true);

    const {id} = useParams() as {id: string};

    const [savedFeedbacksByIdToStorage, setSavedFeedbacksByIdToStorage] = usePersistedStateWithoutIdKey<ICachedFeedback[]>('feedbacks', []);

    const getUserFeedback = async (id: string, params: FeedbackQueryParams) => {
        try {
            const response = await API.get<PaginatedFeedbacks>(`/api/user/${id}/feedback`,{
                params
            });
            return response.data;
        } catch (e) {
            if(e instanceof Error){
                logger.error(e.message);
            }
            
        }
    }

    const setEditFeedback = (note: IFeedback) => {
        const now = moment(new Date());
        const messageDate = moment(note.date);
        const duration = moment.duration(now.diff(messageDate));
        const minutes = duration.asMinutes();
        if(minutes > 15){
            return antNotificationComponent({
                type: 'error',
                message: `It is forbidden to edit feedback after 15 minutes period`
            })
        }
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
                if(note.selectedTemplates.length > 0){
                    const selectedTemplatesName = note.selectedTemplates.map(({MessageTemplate}) => MessageTemplate.id);
                    dispatch(setSelectedTemplates({selectedTemplates: selectedTemplatesName}));
                }
        dispatch(setCurrentFeedback(note));
    }

    const saveFeedback_dashboard = async (data: ISendFeedbackModal) => {
      try {
          const response = await API.post<IMessageStatus>('/api/feedback', data);
          return response.data;
      }  catch (e) {
            if(e instanceof Error){
                logger.error(e.message);
            }
      }
    };

    const onSubmitActivationParts = async ({id, request}: IActiovationParts) => {
        try {
            const response = await API.put<IFeedback_UserStateClassification>(`/api/feedback/${id}/user-state-classification`,request);
            dispatch(setUpdateActivationsParts({id, data: response.data}))
            return response.data
        }catch (e) {
            if(e instanceof Error){
                logger.error(e.message)
            }
        }
    }


    const onEditFeedback = async ({text, id, selectedTemplates, selectedCheckins, ...rest}: IEditFeedback) => {
        try {
            const feedbackData = await editFeedback({text, id, selectedTemplates, selectedCheckins, ...rest});
            if(!!feedbackData){
                antNotificationComponent({
                    type: 'success',
                    message: 'Updated'
                })
                feedbackUpdate();
                dispatch(setCurrentFeedback(null));
                return feedbackData;
            }
        }catch (e) {
            if(e instanceof Error){
                antNotificationComponent({
                    type: 'error',
                    message: e.message
                })
            }
        }
    };

    const onPatchFeedback = async (values: IPatchFeedback) => {
        try {
            const feedbackData = await patchFeedback(values);
            feedbackUpdate();
            dispatch(setCurrentFeedback(null));
            return feedbackData;
        } catch (e) {
            if(e instanceof Error)
                antNotificationComponent({
                    type: 'error',
                    message: e?.message
                })
        }
    }

    const clearFeedback = () => {
        dispatch(setCurrentFeedback(null));
    }

    const setCurrentFeedbackDeleteClear = () => {
        dispatch(setRemoveFeedbackModalVisible(false));
        dispatch(setCurrentFeedback(null));
    };

    const removeFeedback = async (id: string) => {
        try {
            const response = await API.delete(`/api/feedback/${id}`);
            return response.data;
        }
        catch (e) {
            if(e instanceof Error) logger.error(e.message);
        }
    }

    const setCurrentFeedbackDelete = (note: IFeedback) => {
        dispatch(setRemoveFeedbackModalVisible(true));
        dispatch(setCurrentFeedback(note));
    };

    const onDeleteFeedback = async (id: string) => {
        dispatch(setRemoveFeedbackModalVisible(false));
        const feedbackData = await removeFeedback(id);
        if(!!feedbackData){
            dispatch(setDeleteFeedback({id}));
            dispatch(setCurrentFeedback(null));
            antNotificationComponent({
                type: 'success',
                message: 'Deleted'
            });
        }
    }

    const editFeedback = async ({notes, id, selectedTemplates}: IEditFeedback) => {
        try {
            const response = await API.put<IFeedback>(`/api/feedback/${id}`, {
                notes,
                selectedTemplates,
            });
            return response.data;
        }catch (e) {
            if(e instanceof Error) logger.error(e.message);
        }

    }

    const patchFeedback = async ({ id, isMessage }: IPatchFeedback) => {
        try {
            const response = await API.patch<IFeedback>(`/api/feedback/${id}`, { isMessage });
            return response.data;
        }catch (e) {
            if(e instanceof Error) logger.error(e.message);
        }
    }

    const saveFeedback = async (data: ISendFeedback) => {
        try {
            const response = await API.post<IFeedback>('/api/feedback/save', data)
            if(id){
              await API.put(`/api/chat/read-until/${response.data.id}/${response.data.userId}`);
            }
            const cachedFeedbacks = savedFeedbacksByIdToStorage.filter(feedback => feedback.id !== id);
            setSavedFeedbacksByIdToStorage(cachedFeedbacks);
            return response.data
        } catch (e) {
            if(e instanceof Error){
                logger.error(e.message)
            }
            if(id){
                const cachedFeedbacks = savedFeedbacksByIdToStorage.filter(feedback => feedback.id !== id)
                return setSavedFeedbacksByIdToStorage([...cachedFeedbacks, {
                    id,
                    notes: data.feedback.notes as string,
                    selectedTemplates: data.feedback.selectedTemplates as string[]
                }])
            }
        }
    }

    const feedbackUpdate = () => dispatch(setFeedbackUpdate({lastUpdate: Date.now()}))

    const addFeedbacks = (requests: ISendFeedback[]) => {
       return Promise.all(requests.map(data => saveFeedback(data)))
    }

    const userFeedback = async (id: string, params: FeedbackQueryParams) => {
        setIsLoadingFeedbacks(true);
        const data = await getUserFeedback(id, params);
        if (!!data && Array.isArray(data?.items)) {
            const feedbacks = data.items.map((feedback) => {
                if (!!feedback?.notes) {
                    return {...feedback, sentiment: vader.SentimentIntensityAnalyzer.polarity_scores(feedback?.notes)}
                }
                return feedback;
            })
            dispatch(setFeedbackData({ ...data, items: feedbacks }));
        }
        setIsLoadingFeedbacks(false);
    }

    const addFeedback = async (data: ISendFeedback & ISendFeedbackModal) => {
        const feedbackData = await saveFeedback(data);
        if (!!feedbackData) {
            feedbackUpdate();
            return feedbackData;
        }
    }

    useEffect(() => () => {dispatch(feedbackReducerReset())}, []);

    return {
        feedbackApiCancel: cancel,
        removeFeedback,
        onDeleteFeedback,
        clearFeedback,
        onSubmitActivationParts,
        onEditFeedback,
        onPatchFeedback,
        setCurrentFeedbackDelete,
        setCurrentFeedbackDeleteClear,
        setEditFeedback,
        feedbackUpdate,
        editFeedback,
        saveFeedback_dashboard,
        isLoadingFeedbacks,
        addFeedbacks,
        addFeedback,
        userFeedback
    }
}

export default useFeedbackApiWithRedux;