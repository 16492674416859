import React from "react";
import {Result} from "antd";
import Button from "antd/lib/button";
import {useHistory} from "react-router-dom";

export default function NotFoundPage(){
    const history = useHistory()
    const backToHomePage =()=> history.push('/')
    return (
        <Result
            status="404"
            title="404"
            subTitle="Sorry, the page you visited does not exist."
            extra={<Button type="primary" onClick={backToHomePage}>Back Home</Button>}
        />
    )
}