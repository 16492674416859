import React from 'react';
import {Table} from "antd";
import { useAppSelector } from 'redux/store';
import { ICheckIn } from 'Interfaces/CheckIn';
import { Modal } from 'components/Antd/Modal';

// weight, waist, food, training, stress, sleep, water

const {Column} = Table;

export interface IAverageCheckinsModalProps {
    selectedData: ICheckIn[],
    onCancel: () => void
}

const AverageCheckinsModal = ({onCancel, selectedData}: IAverageCheckinsModalProps) => {
    const averageVisible = useAppSelector(state => state.modals.averageCheckInsModalVisible);

    const {weight, waist, foodScore, trainingScore, stressScore, sleepScore, waterScore} = 
    (selectedData.reduce((p,c) => ({
        weight: +p.weight + +c.weight,
        waist: +p.waist + +c.waist,
        foodScore: +p.foodScore + +c.foodScore,
        trainingScore: +p.trainingScore + +c.trainingScore,
        stressScore: +p.stressScore + +c.stressScore,
        sleepScore: +p.sleepScore + +c.sleepScore,
        waterScore: +p.waterScore + +c.waterScore,
    }), {
        weight: 0,
        waist: 0,
        foodScore: 0,
        stressScore: 0,
        trainingScore: 0,
        sleepScore: 0,
        waterScore: 0,
    }));

    const data = [{
        weight: (weight / selectedData.length).toFixed(2),
        waist: (waist / selectedData.length).toFixed(2),
        food: (foodScore / selectedData.length).toFixed(2),
        training: (trainingScore / selectedData.length).toFixed(2),
        stress: (stressScore / selectedData.length).toFixed(2),
        sleep: (sleepScore / selectedData.length).toFixed(2),
        water: (waterScore / selectedData.length).toFixed(2)
    }];

    return (
        <Modal className="modal-average-table" footer={null} centered onCancel={onCancel} title="Average check ins" visible={averageVisible}>
           <Table dataSource={data} pagination={false}>
                <Column title="Weight" dataIndex="weight" />
               <Column title="Waist" dataIndex="waist" />
               <Column title="Food" dataIndex="food" />
               <Column title="Training" dataIndex="training" />
               <Column title="Stress" dataIndex="stress" />
               <Column title="Sleep" dataIndex="sleep" />
               <Column title="Water" dataIndex="water" />
           </Table>
        </Modal>
    )
}

export default AverageCheckinsModal