import moment, { Moment } from "moment";
import {actionData, DATE_FORMAT, DATE_FORMAT_HOURS_MINUTES, DATE_FORMAT_HOURS_MINUTES_SECONDS, goalOptions, LAST_MONTH, LAST_WEEK, PAGE_SIZE_OPTIONS, THIS_WEEK} from "../vars";
import {notification, Popover, TablePaginationConfig, Tag, Typography} from "antd";
import nodeEmoji from "node-emoji";
import emojiRegex from "emoji-regex";
 //@ts-ignore
import {jStat} from "jstat";
import { DateRange, FixMeLater, UserType } from "Types";
import { ChartConfiguration, ChartDataset, ChartTypeRegistry, TooltipItem } from "chart.js";
import { IconType, NotificationPlacement } from "antd/lib/notification";
import { IMacro } from "Interfaces/User";
import { UserStatus } from "@chatscope/chat-ui-kit-react";
import React from "react";
import { DistributiveArray } from "chart.js/types/utils";
import { green, geekblue, grey, red, lime, yellow, blue, volcano, gold, orange, cyan, magenta, purple } from "@ant-design/colors";
import { ICheckIn, WithWeekAndYear } from "Interfaces/CheckIn";

const emojiReg = emojiRegex();
const regexpEmoji = new RegExp(emojiReg, 'g');

export const getUrlParam = (param: string) => {
    const urlParams = new URLSearchParams(window.location.search)
    return urlParams.get(param)
}


export const getMacroTag = (v: number, field: keyof IMacro, Macro: IMacro[]) => {
    if(!v) return 0;
        const enabledMacro = Macro.filter(({inUse}) => inUse);
        const MacroFieldValue = enabledMacro.reduce((acc, macro) => +acc + +(macro[field] as string | number), 0);
        const diff = v - MacroFieldValue / enabledMacro.length;
        if(diff >= 0 && diff <= 5) return <Tag color="green">{v}</Tag>;
        if(diff < 0) return <Tag color="blue">{v}</Tag>;
        if(diff > 5) return <Tag color="red">{v}</Tag>;
    return v;
}

export const getMacroCalories = (v: number, Macro: IMacro[]) => {
    if(!v) return 0;
        const enabledMacro = Macro.filter(({inUse}) => inUse);
        const MacroFieldValue = enabledMacro.reduce((acc, {protein, carb, fat}) => ({
            ...acc,
            protein: +acc.protein + +protein,
            carb: +acc.carb + +carb,
            fat: +acc.fat + +fat,
        }), {
            protein: 0,
            carb: 0,
            fat: 0
        });
        const {protein, carb, fat} = MacroFieldValue;
        const averageProtein = protein / enabledMacro.length;
        const averageCarb = carb / enabledMacro.length;
        const averageFat = fat / enabledMacro.length;
        const calories = (averageProtein ? averageProtein : 0) * 4 + (averageCarb ? carb : 0) * 4 + (averageFat ? averageFat : 0) * 9;
        const percent = (v / calories) * 100;
        if(percent > 110) return <Tag color="red">{v}</Tag>
        if(percent < 90) return <Tag color="blue">{v}</Tag>
        if(percent >= 90 && percent <= 110) return <Tag color="green">{v}</Tag>
    return v;
}


export const getPopoverTextWithIcon = (text = '', length = 50) => text?.length > length ?
    <Popover content={text}>
        <Typography.Paragraph
            ellipsis={{
                // suffix: <InfoCircleOutlined style={{fontSize: 14, position: 'absolute'}} />
            }}
        >
            {text.substring(0,length)}...
        </Typography.Paragraph>
    </Popover> :
    text;

export const getPopoverText = (text = '', length = 50) => text?.length > length ?
    <Popover zIndex={9999} content={text}>{text.substring(0,length)}...</Popover> : text;
export const getSubstringText = (text = '', length = 50) => text?.length > length ? `${text.substring(0,length)}...` : text;
export const PopoverWithChildren = ({text = '', children, length}: {text: string, children: React.ReactNode, length: number}) => text?.length > length ?
    <Popover content={text}>{children}</Popover> : children

export const expPhone =  /\(?([0-9]{3})\)?([ .-]?)([0-9]{4})\2([0-9]{4})/;
export const expMail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;


export const changeSortDirectionTitle = (title: string | null | undefined) => {
    if (title === 'ascend') {
        return 'ASC'
    } else if (title === 'descend') {
        return 'DESC'
    } else if (title === 'ASC') {
        return 'ascend'
    } else if (title === 'DESC') {
        return 'descend'
    } else {
        return null
    }
}

export const sortNullableStrings = (currentValue?: string, nextValue?: string) => {
    if (!currentValue && !nextValue) return 0;
    if (!currentValue) return 1;
    if (!nextValue) return -1;
    return currentValue.localeCompare(nextValue);
}

export function isOnline() {
    return navigator.onLine;
}

export const compose = (...funcs: any[]) => (comp: any) => {
    return funcs.reduceRight(
        (wrapped, f) => f(wrapped), comp);
};

export function Logger(moduleName: string) {
    let lastTimestamp: number;

    const getTimestampOffset = () => {
        if (!lastTimestamp) {
            lastTimestamp = Date.now();
            return "+0ms"
        }
        let newTimestamp = Date.now();
        let offset = newTimestamp - lastTimestamp;
        lastTimestamp = newTimestamp;

        return `+${offset}ms`;
    }

    return {
        debug: (message: string, ...args: any[]) => {
            console.debug(`%c ${moduleName} ${getTimestampOffset()}: %c ${message}`, 'background: lightgray', 'color: green', ...args);
        },

        info: (message: string, ...args: any[]) => {
            console.info(`%c ${moduleName} ${getTimestampOffset()}: %c ${message}`, 'background: lightgray', 'color: blue', ...args);
        },
        warn: (message: string, ...args: any[]) => {
            console.warn(`%c ${moduleName} ${getTimestampOffset()}: %c ${message}`, 'background: lightgray', 'color: orange', ...args);
        },
        error: (message: string, ...args: any[]) => {
            console.error(`%c ${moduleName} ${getTimestampOffset()}: %c ${message}`, 'background: lightgray', 'color: red', ...args);
        },
    }
}

export const getFormatDuration = (duration: string | number) => {
    const tempTime = moment.duration(duration);
    const hours = tempTime.hours() > 0 ? tempTime.hours() + ' Hours ' : '';
    const minutes = tempTime.minutes() > 0 ? tempTime.minutes() + ' Minutes ' : '';
    const seconds = tempTime.seconds() > 0 ? tempTime.seconds() + ' Seconds' : !hours && !minutes ? 'Less than a second' : ''
    return hours + minutes + seconds
}

export const checkFormatDuration = (duration: string | number) => Number(duration) > 0 ? getFormatDuration(duration) : 'unknown'

export const getWednesdayThisWeek = (currentDate: Moment) => {
    if (currentDate.isoWeekday() >= 3) {
        return currentDate.startOf('week').day(+3)
    }
    return currentDate.subtract(1, 'weeks').startOf('week').day(+3)
}

export const getLastWeekDate = (startDate: Moment, endDate: Moment) => {
    if (startDate.isoWeekday() >= 3) {
        return [startDate.subtract(1, 'weeks').startOf('week').day(+3), endDate.startOf('week').day(+2).hours(23).minutes(59).seconds(59)]
    }
    return [startDate.subtract(2, 'weeks').startOf('week').day(+3), endDate.subtract(1, 'weeks').startOf('week').day(+2).hours(23).minutes(59).seconds(59)]

}

export const getLastMonthDate = (startDate: Moment, endDate: Moment) => {
    return [startDate.subtract(1, 'months').startOf('month'), endDate.subtract(1, 'months').endOf('month')]
}

export const getDashboardThisWeek = (currentDate: Moment) => {
    if (currentDate.day() < 7) {
        return moment().startOf('week').day(0).hours(0).minutes(0).seconds(0)
    } else {
        return currentDate.hours(0).minutes(0).seconds(0)
    }
}

export const getDashboardLastWeekDate = (startDate: Moment, endDate: Moment) => {
    if (startDate.day() === 7) {
        return [startDate.subtract(1, 'weeks').startOf('week').day(+7).hours(0).minutes(0).seconds(0), endDate.startOf('week').day(+6).hours(23).minutes(59).seconds(59)]
    }
    return [startDate.subtract(2, 'weeks').startOf('week').day(+7).hours(0).minutes(0).seconds(0), endDate.subtract(1, 'weeks').startOf('week').day(+6).hours(23).minutes(59).seconds(59)]
}

export const getDateRange = (dateRangeName: DateRange, skipLastMonthFilter?: boolean): {f_dateAfter: string, f_dateBefore: string} => {
    let date = null

    if (dateRangeName === LAST_MONTH && !skipLastMonthFilter) date = getLastMonthDate(moment(), moment());

    if (dateRangeName === LAST_WEEK || dateRangeName === LAST_MONTH && skipLastMonthFilter) {
        date = getDashboardLastWeekDate(moment(), moment())
    }

    if (dateRangeName === THIS_WEEK) date = [getDashboardThisWeek(moment()), moment().parseZone()];

    if (!!date) {
        return {
            f_dateAfter: date[0].format(DATE_FORMAT_HOURS_MINUTES_SECONDS),
            f_dateBefore: date[1].format(DATE_FORMAT_HOURS_MINUTES_SECONDS)
        }
    }
    return {
        f_dateAfter: "",
        f_dateBefore: ""
    }
}

export const getQueryParams = <T extends Record<string, any> = {}> (obj: T) => {
    const query: Partial<T> = {}
    Object.keys(obj).forEach((key) => {
        if (!!obj[key]) {
            query[key as keyof T] = obj[key]
        }
    })
    return query
}

export const validateLink = async (value: string) => {
    if(value){
        const regexp = /^https?:\/\/(.*)/;
        if(!regexp.test(value)){
            return Promise.reject(new Error('It should be valid URL'))
        }
    }
}

export const isOnlySpaces = (string: string) => {
    const regexp = /^\s*$/
    return regexp.test(String(string).toLowerCase())
}

export const validateNoSpaces = async (value: string) => {
    const regexp = /^\S+$/
    if(!regexp.test(value)){
        return Promise.reject(new Error('This field is required'))
    }
}

export const validateAlphabet = async (value: string) => {
    if (value && !Array.isArray(value)) {
        const regexp = /^[A-Za-z0-9!"#$%&'()*+,\-./:;<>=?@[\]{}\\^_`~\s|]+$/gm
        if (!regexp.test(String(value).toLowerCase())) {
            return Promise.reject(new Error('Available characters are latin alphabet, digits and special symbols'))
        }
    }
}

export const validateRequired = async (value: any, message?: string) => {
    if (!value || isOnlySpaces(value)) {
        return Promise.reject(new Error(message || 'This field is required'));
    }
}

export const validateWithoutNull = async (field: string, value: any, setFieldsValue: (param: any) => void) => {
    if (!value || isOnlySpaces(value)) {
        const fieldArr = field.split('.')
        if (!!fieldArr[0] && !!fieldArr[1]) {
            setFieldsValue({[fieldArr[0]]: {[fieldArr[1]]: 0}})
        } else if (!!fieldArr[0] && !fieldArr[1]) {
            setFieldsValue({[fieldArr[0]]: 0})
        }
    }
}

export const validateSumOfDays_Switch = async (values: IMacro[] = [], inUse: boolean) => {
    if(!inUse){
        return Promise.resolve();
    }
    const sumOfDays = values.filter(({inUse}) => inUse).reduce((acc, curr) => +acc + +curr.days, 0);

    if(sumOfDays > 7){
        return Promise.reject();
    }
}

export const validateEnableSwitchDays = async (value: boolean) => {
  if(!value){
      return Promise.resolve(value);
  }
};

export const validateSumOfDays = async (values: IMacro[] = [], inUse: boolean) => {
    if(!inUse){
        return Promise.resolve();
    }
    const sumOfDays = values.filter(({inUse}) => inUse).reduce((acc, curr) => +acc + +curr.days, 0);
    if(sumOfDays > 7){
        return Promise.reject(new Error(`The sum of days can't be more than 7 days`));
    }
}

export const validateLength = async (value: string | number, valueLength = 4) => {
    if (value && Number(value).toFixed(0).length > valueLength) {
        return Promise.reject(new Error('The value is too long'));
    }
}

export const validatePositive = async (value: number) => {
    if (value && !isNaN(Math.sign(value)) && Math.sign(value) === -1) {
        return Promise.reject(new Error('The value must be positive'));
    }
}

export const validatePassword = async (value: string) => {
    if (value) {
        const exp = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/;
        if(value.length > 20){
            return Promise.reject(new Error('Password must be shorter than or equal to 20 characters'));
        }

        if (!exp.test(value)) {
            return Promise.reject(new Error('Password must contain at least 8 symbols, 1 digit, 1 lowercase letter and 1 uppercase letter!'));
        }
    }
}


export const validateOnlyLetters = async (value: string) => {
    if(value){
        const exp = /^[a-zA-Z]*$/;
        if(!exp.test(value)){
            return Promise.reject(new Error('Available characters are latin alphabet'))
        }
    }
}

export const validatePhoneNumber = async (value: string) => {
    if(value){
        const exp = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
        if(!exp.test(value)){
            return Promise.reject('Incorrect mobile format');
        }
    }
}

export const validateEmail = async (value: string) => {
    if (value) {
        if (!expMail.test(value)) {
            return Promise.reject('Invalid email format');
        }
        return Promise.resolve(value)
    }
}

export const validateConfirmField = async (value: string | number, confirmValue: string | number) => {
    if (confirmValue !== value) {
        return Promise.reject({message: 'Passwords do not match'});
    }
}

export const openNotification = (message: string, type: IconType, placement: NotificationPlacement = 'topRight') => {
    notification[type]({
        message: message,
        description: '',
        duration: 3,
        placement
    });
};

export const isPlatform = (OS: string) => navigator.platform.toUpperCase().indexOf(OS.toUpperCase()) >= 0

export const isBoolean = (val: boolean) => 'boolean' === typeof val;

export const isFiltersChange = <T  extends {}>({prevFilters = {}, currentFilters = {}}: {prevFilters: Partial<T>, currentFilters:  Partial<T>}) => {
    return Object.keys(currentFilters).map(key => {
        if (Array.isArray(currentFilters[key as keyof T])) {
            if (!prevFilters[key as keyof T]) {
                return true
            }
            // if(currentFilters instanceof Array){
                return (currentFilters[key as keyof T] as Array<any>).map((elem, index) => elem !== ( ( prevFilters[key as keyof T] as Array<keyof typeof prevFilters> )[index] )).some(elem => elem)
            // }
        }
        return currentFilters[key as keyof T] !== prevFilters[key as keyof T]
    }).some(elem => elem)
}

export const tablePagination = ({pageSize, page, totalCount, ...extra}: TablePaginationConfig & {page?: number, totalCount?: number}) => (
    {
        pageSize: Number(pageSize),
        current: Number(page),
        pageSizeOptions: PAGE_SIZE_OPTIONS,
        total: totalCount,
        position: ['bottomRight'],
        ...extra
    })

export const getPhysiqueGoalText = (physiqueGoal: string) => {
    const goalObj = goalOptions.find((goal) => goal.value === physiqueGoal)
    if (!!goalObj) {
        return goalObj.label
    }
}

export const ucFirst = (str = '') => {
    str = str.toLowerCase()
    return str.charAt(0).toUpperCase() + str.slice(1);
}

export const getActivityTitle = (text: string) => {
    const action = actionData.find((action) => action.value === text)
    return !!action ? action.text : text
}

export const getRandomArbitrary = (min: number, max: number) => (Math.random() * (max - min) + min).toFixed(0);

export const checkDashboardData = <T extends keyof ChartTypeRegistry>(data: ChartConfiguration<T>['data']['datasets'] = []) => {
    const reducer = (accumulator: number, {data}: ChartDataset<T, DistributiveArray<ChartTypeRegistry[T]["defaultDataPoint"]>>) => {
        for (let number of data) {
            accumulator += (number as number)
        }
        return accumulator
    }
    const count = data.reduce(reducer, 0)
    return count > 0
}

export const getTwoEntitiesWithBiggestTimeDiff = <T extends { date: string }>(entities: T[]): [T, T] | null => {
    if (entities.length < 2) return null;

    const sortedCheckIns = [...entities]
        .sort((currCheckIn, nextCheckIn) => new Date(nextCheckIn.date).getTime() - new Date(currCheckIn.date).getTime());
    return [sortedCheckIns[0], sortedCheckIns[sortedCheckIns.length - 1]];
}

export const getCheckInsWeeksDifferenceCount = (checkIns: [WithWeekAndYear, WithWeekAndYear]) => {
    const weekCount = checkIns[0].week - checkIns[1].week + (checkIns[0].year - checkIns[1].year) * 52;
    return weekCount >= 0 ? weekCount : -1 * weekCount;
}

export const getFormatDate = (date: string) => date ? moment(date).format(DATE_FORMAT) : "";

export const getFormatDateWithHours = (date: string) => date ? moment(date).format(DATE_FORMAT) : "";

export const getRequestClassification = ({tags, options}: {tags: string | string[], options: string[]}) =>
    options.reduce((acc,current) => ({
    ...acc,
    [current]: !!tags?.includes(current) || false
}),{})

export const isMobileDevice = () => {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true;
    };
    return false;
};

export const getLastOnlineDate = (date: string) => {
    if (!date) {
        return '';
    }
    const isCurrentMinutes = moment(date).isSame(new Date(), 'minute');
    const isCurrentHour = moment(date).isSame(new Date(), 'hour');
    const isCurrentDay = moment(date).isSame(new Date(), 'day');
    const isCurrentYear = moment(date).isSame(new Date(), 'year');

    if(isCurrentMinutes){
        return 'Online';
    }
    if (isCurrentHour) {
        return moment(new Date(date), 'YYYYMMDD').fromNow();
    }
    if (isCurrentDay) {
        return moment(date).format('HH:mm');
    }
    if (isCurrentYear) {
        return moment(date).format('MM/DD HH:mm');
    }
    return moment(date).format(DATE_FORMAT_HOURS_MINUTES);
};

export const getDateMessage = (date: string) => {
    if (!date) {
        return '';
    }
    // const isCurrentMinutes = moment(date).isSame(new Date(), 'minute');
    const isCurrentHour = moment(date).isSame(new Date(), 'hour');
    const isCurrentDay = moment(date).isSame(new Date(), 'day');
    const isCurrentYear = moment(date).isSame(new Date(), 'year');

    // if(isCurrentMinutes){
    //     return 'Online';
    // }
    if (isCurrentHour) {
        return moment(new Date(date), 'YYYYMMDD').fromNow();
    }
    if (isCurrentDay) {
        return moment(date).format('HH:mm');
    }
    if (isCurrentYear) {
        return moment(date).format('MM/DD HH:mm');
    }
    return moment(date).format('YYYY/MM/DD HH:mm');
};

export const getDateChatList = (date: string) => {
    if (!date) {
        return '';
    }
    const isCurrentHour = moment(date).isSame(new Date(), 'hour');
    const isCurrentDay = moment(date).isSame(new Date(), 'day');
    const isCurrentYear = moment(date).isSame(new Date(), 'year');
    if (isCurrentHour) {
        return moment(new Date(date), 'YYYYMMDD').fromNow();
    }
    if (isCurrentDay) {
        return moment(date).format('HH:mm');
    }
    if (isCurrentYear) {
        return moment(date).format('MM/DD HH:mm');
    }
    return moment(date).format('YYYY/MM/DD HH:mm');
};

export const getSortDirectionQueryParams = <T extends {}>(query: T) => {

}

export const getOnlineStatus = (date: string): UserStatus => {
    if(!date){
        return "unavailable";
    }
    const isCurrentMinutes = moment(date).isSame(new Date(), 'minute');
    if(isCurrentMinutes){
        return 'available'
    }
    return "unavailable";
}

export const unEmojifyMessage = (message = '') =>  nodeEmoji.unemojify(message).replace(regexpEmoji, (match) => `[${match.codePointAt(0)?.toString(16)}]`);

export const emojifyMessage = (message = '') => nodeEmoji.emojify(message).replace(regexpEmoji, (match) => {
    try {
        const unicode = match.slice(1, -1);
        const smile = String.fromCodePoint(parseInt(unicode, 16));
        if (smile.match(emojiReg)) {
            return smile;
        }
        return match;
    } catch (e) {
        return match;
    }
});


export const urlBase64ToUint8Array = (base64String: string) => {
    const padding = '='.repeat((4 - base64String.length % 4) % 4);
    const base64 = (base64String + padding)
        .replace(/\-/g, '+')
        .replace(/_/g, '/');

    const enc = window.btoa(base64);
    const rawData = window.atob(enc);
    let outputArray = new Uint8Array(rawData.length);
    for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
}

export const getChartLabelPercentage = ({dataIndex, dataset: {label, data}}: TooltipItem<'bar' | 'pie'>) => {
    const sumData = data.reduce((acc: number, current: number) => acc + +current, 0) || 0;
    const percentage = (data[dataIndex] / sumData) * 100;
    return `${label![dataIndex]}: ${data[dataIndex]} (${parseFloat(percentage.toFixed(1)) + '%' || ''})`;
}

export const getChartLabelPercentageMultiply = (labels: string[]) => ({dataIndex, dataset: {data}}: TooltipItem<'bar' | 'pie'>) => {
    const sumData = data.reduce((acc: number, current: number) => +acc + +current, 0) || 0;
    const percentage = (data[dataIndex] / sumData) * 100;
    return `${labels[dataIndex]}: ${data[dataIndex]} (${parseFloat(percentage.toFixed(1))  + '%' || ''})`;
}


export const calculate_R2_PValue = (data: (number | null)[] = [], labels: number[] = []) => {
    const isEveryTrue =  data.every(e => e);

    if(data.length === 8 && isEveryTrue){
        const jsStatData = data.map((e) => [1, e || 0]);
        let R2 = 0
        let pValue=0
        try{
            R2 = jStat.models.ols(labels,jsStatData).R2;
            pValue = jStat.models.ols(labels,jsStatData).t.p[1];
        }catch(e){
            
        }
        
        return {
            R2: `R-Squared: ${+R2.toFixed(2)}`,
            pValue: `P-Value: ${+pValue.toFixed(2)}`
        }
    }
    return {
        R2: '',
        pValue: '',
    }
};

export const getAutoGeneratedValue = (f_autoGenerated: FixMeLater) => f_autoGenerated && f_autoGenerated.includes(true);
export const getAutoGeneratedParam = (f_autoGenerated: boolean) => f_autoGenerated ? [true, false] : [false];

export const getUserFormData = ({ formData: {firstName, lastName, disabled, ...rest}, fields = {} }: {formData: Partial<UserType>, fields?: Partial<UserType>}) => ({
    ...rest,
    firstName: firstName?.trim() || "",
    lastName: lastName?.trim() || "",
    disabled: !!disabled,
    ...fields,
});

export const getChartDataWithOrderWeeks = (checkIns: {x: number, y: number, autoGenerated: boolean}[] = []) => {
    //When the new year comes, we need to sort the weeks. And after sorting by values, the old week is 51 and the new one is 1 and we need to move the old weeks to the beginning of the array
    const checkInsOldWeeks = checkIns
        .filter((item, index) => (+item.x - +checkIns[index + 1]?.x !== -1 && !!(checkIns[index + 1])))
        .map((_, index) => checkIns[(checkIns.length - 1) - index]);

    return [
        ...checkInsOldWeeks,
        ...checkIns.slice(0, checkIns.length - checkInsOldWeeks.length)
    ];
};

export const getColorByHex = (hex = "") => {
    if(green.includes(hex)){
        return "Green"
    };
    if(red.includes(hex)){
        return "Red"
    };
    if(blue.includes(hex)){
        return "Blue"
    };
    if(volcano.includes(hex)){
        return "Volcano"
    };
    if(gold.includes(hex)){
        return "Gold"
    };
    if(orange.includes(hex)){
        return "Orange"
    };
    if(yellow.includes(hex)){
        return "Yellow"
    };
    if(lime.includes(hex)){
        return "Lime"
    };
    if(cyan.includes(hex)){
        return "Cyan"
    };
    if(volcano.includes(hex)){
        return "Volcano"
    };
    if(geekblue.includes(hex)){
        return "Geekblue"
    };
    if(magenta.includes(hex)){
        return "Magenta"
    };
    if(purple.includes(hex)){
        return "Purple"
    };
    if(grey.includes(hex)){
        return "Grey"
    };
    return "No color"
};

export const trimStringInObject = <T extends {}>(data: T) => Object.entries(data).reduce((acc, [key, value]) => {
    if(typeof value === 'string'){
        return {
            ...acc,
            [key]: value.trim()
        }
    };
    return {
        ...acc,
        [key]: value
    }
}, {} as T);

export const getLocaleCompareSort = (a: string | null, b: string | null) => {
    if(!a && b) return 1;
    if(a && !b) return -1;
    if(a && b) return a.localeCompare(b);
    return 0;
};

export const sortByDate = (firstDate: string | null, nextDate: string | null) => {
    if (!firstDate && nextDate) return 1;
    if (firstDate && !nextDate) return -1;
    if (firstDate && nextDate) return new Date(firstDate).getTime() - new Date(nextDate).getTime();
    return 0;
}