import React from 'react';
import {Col, Image, Row} from "antd";
import {EditFilled} from "@ant-design/icons";
import InfoCard from "./InfoCard";
import WidgetPageCard from "../dashboard/WidgetPageCard";
import activeClientsIcon from 'img/noun-client.svg';
import { ITypeValue } from 'Interfaces/Dashboard/CheckIn';

interface Props {
    text: string,
    joinedDate: string,
    activeClients: ITypeValue,
    onChange: (value: string) => Promise<void>
}


export default function CoachInfoCardsRow({joinedDate, activeClients, text, onChange}: Props) {
    return (
        <>
            <Row className={'coach_main_body_row'}>
                <Col xl={6} md={24}>
                    <InfoCard title={"Date Joined"} text={joinedDate}/>
                </Col>
                <Col xl={18} md={24}>
                    <Row className={"coach_main_info_cards_right_row"}>
                        <Col xl={{span: 8, offset: 1}} lg={12} md={24}>
                            <WidgetPageCard 
                                data={activeClients} 
                                className={'coach_main_info_card'} 
                                valueStyle={{fontSize:'16px'}}
                                children={<Image src={activeClientsIcon} 
                                width={60}
                                preview={false} 
                            />
                        }
                            />
                        </Col>
                        <Col xl={{span: 8, offset: 1}} lg={12} md={24}>
                            <InfoCard 
                                title={"Specific Area/Goals"} 
                                text={text} 
                                editable={{
                                    icon: <EditFilled className={'coach_main_body_column_info_icon'}/>,
                                    tooltip: 'Click to edit text',
                                    onChange: onChange,
                                    autoSize: { minRows: 3 }
                                }}
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
    )
}