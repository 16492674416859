import React, {useEffect, useState} from 'react';
import { useAppSelector } from 'redux/store';
import {Button, DatePicker, MenuItemProps, TablePaginationConfig, TabsProps, Menu, Popover, Space, Switch, Tabs} from "antd";
import {DownOutlined} from "@ant-design/icons";
import ClientInfoTab from "./client_info_tab";
import MacroTab from "./macros_tab";
import QuestionnaireList from "./questionnaire";
import Notes, { INotesProps } from "./notes";
import CheckInList from "./checkin";
import Feedback, { PropsFeedbackClient } from "./feedback";
import Photos from "./photos";
import {admin, manager, VIEW_IMAGE_DETAIL, VIEW_IMAGES, VIEW_MACRO_CALCULATOR} from "vars";
import withPermission from "HOCs/withPermission";
import usePhotosApiWithRedux from "services/photo-service";
import {useHistory} from "react-router-dom";
import Nutrition from "./Nutrition_Tab/Nutrition";
import { Dropdown } from 'components/Antd/DropDown';
import {MenuClickEventHandler} from "rc-menu/lib/interface"
import { IFeedback, IFeedback_UserStateClassification } from 'Interfaces/Feedback';
import { IActiovationParts, IEditFeedback, IPatchFeedback } from 'services/feedback-service';
import { CurrentEvent } from 'Types';
import { Canceler } from 'axios';
import { ModalType } from 'services/modals-service';
import { ICheckIn } from 'Interfaces/CheckIn';
import { IActivityData } from 'redux/reducers/activityReducer';
import { IClientNote, IClientNoteEmoji } from 'Interfaces/ClientNote';
import { INoteQuery } from 'services/notes-service';
import { IMessageStatus } from 'Interfaces/Auth';
import { SorterResult } from 'antd/lib/table/interface';
import { CheckInTableSettings } from 'redux/reducers/checkInsReducer';
import TasksTable from 'pages/tasks/TasksTable';

export interface IClientBodyProps {
    onDeleteFeedback: (id: string) => Promise<void>,
    setCurrentFeedbackDeleteClear: () => void,
    setEditFeedback: (note: IFeedback) => void,
    setCurrentFeedbackDelete: (note: IFeedback) => void,
    onEditFeedback: (note: IEditFeedback) => Promise<IFeedback | undefined>,
    onPatchFeedback: (feedback: IPatchFeedback) => Promise<IFeedback | undefined>,
    setUserActivity: (activityType: CurrentEvent) => Promise<void>,
    dispatchSetActivity: (data: {
        activityType: CurrentEvent | null;
        activityData: IActivityData;
    }) => Promise<void>,
    saveFeedback: (values: any) => Promise<IFeedback | undefined>,
    checkInsProps: {
        getUserCheckIn: (query?: {}) => Promise<void>,
        saveCheckin: (data: ICheckIn) => Promise<ICheckIn | undefined>,
        onSelectChange: (selected: ICheckIn[]) => Promise<void>,
        handleCheckInsTableChange:(pagination: TablePaginationConfig,
        filters: CheckInTableSettings,
        sorter: SorterResult<ICheckIn> | SorterResult<ICheckIn>[]
        ) => void,
        getCheckIn: (query?: CheckInTableSettings) => Promise<void>,
        handleChangeAutoGenerated: (autoGenerated: boolean) => void
    },
    onSubmitActivationParts: (data: IActiovationParts) => Promise<IFeedback_UserStateClassification | undefined>
    userProps: {
        setActiveTab: (key: string) => void,
        setCurrentEventData: (key: string) => void,
        setCurrentEventWithCount: (currentEvent: string) => void,

    },
    modalsProps: {
        setAverageVisible: ModalType,
        setFeedbackVisible: ModalType,
        setMacroVisible: ModalType,
        setAssignmentsHistoryVisible: ModalType,
        setChangeGoalVisible: ModalType,
        setRemoveCoachVisible: ModalType,
        setAddNotesVisible: ModalType,
        setAssignCoachVisible: ModalType,
        setPauseNotificationsVisible: ModalType,
        setAddTaskVisible: ModalType
    },
    messageTemplatesProps: {
        messageTemplatesApiCancel: Canceler;
    getMessageTemplates: () => Promise<{
        label: string;
        value: string;
    }[]>;
    setTemplates: (templates: string[]) => void;
    },
    notesProps: {
        notesApiCancel: Canceler,
        getNotes: (query: INoteQuery) => Promise<void>
        addNote: (id: string, values: {
            notes: string;
        }) => Promise<IClientNote | undefined>,
        notesPageUpdate: (page: number) => void,
        setActions: ({ action, notesId, actionDetail }: {
            action: string;
            actionDetail: string;
            notesId: string;
        }) => Promise<IClientNoteEmoji | undefined>,
        deleteActions: ({ actionId }: {
            actionId: string;
        }) => Promise<IMessageStatus | undefined>

    },
    feedbackProps: PropsFeedbackClient,
    questionnaireProps: {
        questionnaireApiCancel: Canceler,
        getQuestionnaire: () => Promise<void>,
        getQuestionnaireAnswer: (key: string) => Promise<void>
    }
};

export default function ClientBody({
                                       onDeleteFeedback,
                                       setCurrentFeedbackDeleteClear,
                                       setEditFeedback,
                                       setCurrentFeedbackDelete,
                                       onEditFeedback,
                                       onPatchFeedback,
                                       saveFeedback,
                                       dispatchSetActivity,
                                       setUserActivity,
                                       checkInsProps,
                                       onSubmitActivationParts,
                                       userProps,
                                       modalsProps,
                                       notesProps,
                                       feedbackProps,
                                       questionnaireProps,
                                       messageTemplatesProps
                                   }: IClientBodyProps) {

    const history = useHistory()

    const {getUserCheckIn, saveCheckin, handleChangeAutoGenerated} = checkInsProps

    const {setActiveTab, setCurrentEventData, setCurrentEventWithCount} = userProps


    const {
        setAverageVisible,
        setFeedbackVisible,
        setMacroVisible,
        setAssignmentsHistoryVisible,
        setChangeGoalVisible,
        setRemoveCoachVisible,
        setAddNotesVisible,
        setAssignCoachVisible,
        setPauseNotificationsVisible,
        setAddTaskVisible,
    } = modalsProps

    const photosProps = usePhotosApiWithRedux()
    const {handleVisibleGroupPreview} = photosProps

    const {TabPane} = Tabs;
    const user = useAppSelector(state => state.user.userData!)
    const activeTabKey = useAppSelector(state => state.user.activeTabKey)
    const groupPreview = useAppSelector(state => state.photos.groupPreview)
    const selectedRows = useAppSelector(state => state.checkIns.checkInSelectedRowKeys)
    const areCheckInsLoading = useAppSelector(state => state.checkIns.isLoading);
    const [isShowAutoGenerated, setIsShowAutoGenerated] = useState(false);
    const [isCheckedAuto, setIsCheckedAuto] = useState(false);

    const handleChangeCheckedAuto = (value: boolean) => {
        setIsCheckedAuto(value);
        handleChangeAutoGenerated(value);
    };


    const setImgActivity = (flag: boolean) => {
        const data: {activityType: CurrentEvent, activityData: {clientId: string}} = {activityType: flag ? VIEW_IMAGE_DETAIL : VIEW_IMAGES, activityData: {clientId: user.id}}
        dispatchSetActivity(data);
    }

    const setImgPreviewGroup = (flag: boolean) => {
        setImgActivity(flag)
        handleVisibleGroupPreview(flag)
    }

    const handleViewActivities = (userId: string) => {
        history.push(`/activity?f_clientId[]=${[userId]}`)
    }


    const handleMenuClick: MenuClickEventHandler = (key) => {
        switch (key.key) {
            case "0":
                setAverageVisible(true)
                break;
            case "1":
                setCurrentEventWithCount(VIEW_MACRO_CALCULATOR)
                setMacroVisible(true)
                break;
            case "2":
                setChangeGoalVisible(true)
                break
            case "3":
                setAddNotesVisible(true)
                break
            case "4":
                setFeedbackVisible(true)
                break
            case "5":
                setAssignCoachVisible(true)
                break
            case "6":
                setRemoveCoachVisible(true)
                break
            case "7":
                setAssignmentsHistoryVisible(true)
                break
            case "8":
                handleViewActivities(user.id)
                break
            case "9":
                setImgPreviewGroup(true)
                break
            case "10":
                setPauseNotificationsVisible(true)
                break
            case "11":
                setAddTaskVisible(true)
                break

        }
    }

    const MenuItem = (prop: MenuItemProps) => {
        return (
            <Menu.Item  {...prop}>
                {prop.title}
            </Menu.Item>
        )
    }

    const MenuItemWithPermission = (withPermission(MenuItem))

    const menu = (
        <Menu onClick={handleMenuClick}>
            <MenuItem disabled={selectedRows.length < 1} key="0" title={'Average check ins'} />
            <MenuItem key="1" title={'Change macros'}/>
            <MenuItem key="2" title={'Change goal'}/>
            <MenuItem key="3" title={'Add notes'}/>
            <MenuItem key="4" title={'Add feedback'}/>
            <MenuItemWithPermission key="5" title={'Assign coach'} roles={[admin, manager]}/>
            {user?.coachId ? <MenuItemWithPermission key="6" title={'Remove coach'} roles={[admin, manager]}/> : null}
            <MenuItem key="7" title={'Assignments history'}/>
            <MenuItemWithPermission key="8" title={'View activities'} roles={[admin]}/>
            <MenuItem disabled={groupPreview.length < 1} key="9" title={'Compare photo'}/>
            <MenuItem disabled={user.disabled} key="10" title={'Disable notifications'}/>
            <MenuItem disabled={user.disabled} key="11" title="Add task" />
        </Menu>
    )


    const extraContent = (
        <Space>
            { isShowAutoGenerated &&
                <Switch unCheckedChildren="Auto" disabled={areCheckInsLoading} checkedChildren="Auto" onChange={handleChangeCheckedAuto} checked={isCheckedAuto} />}
            <Dropdown trigger={['click']} overlay={menu}>
                <Button>Actions <DownOutlined/></Button>
            </Dropdown>
        </Space>
    )

    const onUserTabClick: TabsProps['onTabClick'] = (key) => {
        setActiveTab(key);
        setCurrentEventData(key);
        if(key === "4"){
            setIsShowAutoGenerated(true)
        } else {
            setIsShowAutoGenerated(false);
        };
    }

    useEffect(() => {
        const fetchData = async () => {
            await getUserCheckIn({delta: true, f_userId: [user.id], page: 1, pageSize: 3})
        }
        fetchData();
    }, [])

    const feedbackPropsData = {...feedbackProps, ...messageTemplatesProps}

    return (
        <Tabs activeKey={activeTabKey} onTabClick={onUserTabClick} tabBarExtraContent={extraContent}>
            <TabPane tab="Client Info" key="1">
                <ClientInfoTab/>
                <TasksTable />
                <MacroTab/>
            </TabPane>
            <TabPane tab="Questionnaire" key="2">
                <QuestionnaireList {...questionnaireProps}/>
            </TabPane>
            <TabPane tab="Client Notes" key="3">
                <Notes {...notesProps} setUserActivity={setUserActivity}/>
            </TabPane>
            <TabPane tab="Check Ins" key="4">
                <CheckInList
                    {...checkInsProps}
                />
            </TabPane>
            <TabPane tab="Feedback" key="5">
                <Feedback
                    {...feedbackPropsData}
                    setVisible={setFeedbackVisible}
                    onDeleteFeedback={onDeleteFeedback}
                    onSubmitActivationParts={onSubmitActivationParts}
                    setEditFeedback={setEditFeedback}
                    onEditFeedback={onEditFeedback}
                    onPatchFeedback={onPatchFeedback}
                    setCurrentFeedbackDelete={setCurrentFeedbackDelete}
                    setCurrentFeedbackDeleteClear={setCurrentFeedbackDeleteClear}
                    addFeedback={saveFeedback}
                    saveCheckin={saveCheckin}
                />
            </TabPane>
            <TabPane tab="Photos" key="6">
                <Photos
                    {...photosProps}
                    setImgActivity={setImgActivity}
                    setImgPreviewGroup={setImgPreviewGroup}
                />
            </TabPane>
            {
                user?.mfp ? (
                    <TabPane tab="Nutrition" key="7">
                        <Nutrition />
                    </TabPane>
                ) : null
            }
        </Tabs>
    )
}