import React, { FC } from 'react';
import { DropDownProps, Dropdown as D } from 'antd';

interface IDropDownProps extends DropDownProps {
    children?: React.ReactNode,
}


export const Dropdown: FC<IDropDownProps> = ({children, ...props}) => (
    <D {...props}>
        {children}
    </D>
);
