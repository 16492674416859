import React from 'react';
import { useAppSelector } from 'redux/store';
import MacroCard from "./macro_card"
import {Space} from 'antd';

export default function MacroTab() {
    const user = useAppSelector(state => state.user.userData!)
    return (
        <Space wrap={true}>
            <MacroCard title="Low" macro={user?.LowMacro}/>
            <MacroCard title="Mid" macro={user?.StandardMacro}/>
            <MacroCard title="High" macro={user?.HighMacro}/>
        </Space>
    )
}