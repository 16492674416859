import React from 'react';
import FoodDiary from "./FoodDiary";
import {Space} from "antd";
import NutritionSummary from "./NutritionSummary";

const Nutrition = () => {

    return (
        <Space direction="vertical" size={32}>
            <NutritionSummary />
            <FoodDiary />
        </Space>
    )
};

export default Nutrition;