import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, PageHeader, Popover, Space, Table, Tag } from 'antd';
import { ColumnProps, ColumnsType } from 'antd/lib/table';
import { ITask, StatusTask } from 'Interfaces/TasksCategories';
import { EditOutlined, PushpinOutlined, CheckCircleOutlined, DeleteOutlined } from '@ant-design/icons';
import useTasksCategories from 'services/tasksCategories-service';
import useModals from 'services/modals-service';
import { useAppSelector } from 'redux/store';
import { getFormatDate, getPopoverText, sortByDate } from 'utils/helpers';
import moment from 'moment';
import { DATE_FORMAT } from 'vars';

import './tasks.css';
import { useParams } from 'react-router';

const TasksTable = () => {
    const { id } = useParams() as {id: string};
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const {
        handleSetCurrentTask,
        onDeleteTask,
        onChangeTaskPin,
        setTaskStatus,
        getUserTasksData,
        tasks,
        categories,
        handleVisibleOpenRemoveCurrentTask,
    } = useTasksCategories();
    const { setEditTaskVisible } = useModals();
    const lastUpdated = useAppSelector((state) => state.tasks.updateTasks);

    const renderActions: ColumnProps<ITask>['render'] = useCallback((task: ITask) => {
        const onEditTask = () => {
            handleSetCurrentTask(task);
            setEditTaskVisible(true);
        };

        const onRemoveTask = () => {
            handleVisibleOpenRemoveCurrentTask(task);
        };

        const isCompleted = task.status === StatusTask.completed;
        return (
            <Space>
                <Popover content={isCompleted ? 'Re-open task': 'Complete task'} placement="top">
                    <Button
                        onClick={() => isCompleted ? setTaskStatus(task.id, StatusTask.opened) : setTaskStatus(task.id)}
                        size="small"
                        type={isCompleted ? "primary" : "ghost"}
                        shape="circle"
                        icon={<CheckCircleOutlined/>}
                    />
                </Popover>
                <Popover content={task.pin ? 'Unpin task': 'Pin task'} placement="top">
                    <Button size="small" onClick={() => onChangeTaskPin(task.id)} type={task.pin ? "primary" : "ghost"} shape="circle" icon={<PushpinOutlined/>}/>
                </Popover>
                <Button type="primary" size="small" shape="circle" onClick={onEditTask} icon={<EditOutlined />}/>
                <Button type="primary" size="small" shape="circle" onClick={onRemoveTask} icon={<DeleteOutlined/>} danger/>
            </Space>
        )
    }, []) 

    const renderStatus: ColumnProps<ITask>['render'] = (status: StatusTask) => {
        if(status === StatusTask.opened){
            return <Tag color="blue">{status.toUpperCase()}</Tag>
        };
        if(status === StatusTask.completed){
            return <Tag color="green">{status.toUpperCase()}</Tag>
        };
        if(status === StatusTask.archived){
            return <Tag color="orange">{status.toUpperCase()}</Tag>
        }
    } 

    const filtersStatusOptions: ColumnProps<ITask>['filters'] = [
        {
            value: StatusTask.opened,
            text: <Tag color="blue">{StatusTask.opened.toUpperCase()}</Tag>
        },
        {
            value: StatusTask.completed,
            text: <Tag color="green">{StatusTask.completed.toUpperCase()}</Tag>
        },
        {
            value: StatusTask.archived,
            text: <Tag color="orange">{StatusTask.archived.toUpperCase()}</Tag>
        },
    ]

    const filtersCategoriesOptions: ColumnProps<ITask>['filters'] = categories.map((category) => ({
        text: <Tag color={category.color}>{getPopoverText(category.name, 30)}</Tag>,
        value: category.id,
        title: category.name,
    }))

    // apply additional className to the cell container, when the date is the same day or before the current date
    const renderDateCellWithClassOnDateBefore = useCallback((dateString: string, conditionalClassName: string) => {
        const date = moment(dateString);
        if(!date.isValid()) return "";
        return {
            props: { className: `${date.isSameOrBefore(moment(), 'day') ? conditionalClassName : ''} center-text` },
            children: moment(date).format(DATE_FORMAT)
        };
    }, []);

    const columns: ColumnsType<ITask> = [
        {
            title: "Name",
            dataIndex: "name"
        },
        {
            title: "Description",
            dataIndex: "description",
            width:350
        },
        {
            title: "Category",
            render: (_, {taskCategory}) => <Tag color={taskCategory.color} className="task-table-category" title={taskCategory.description}>{getPopoverText(taskCategory.name, 50)}</Tag>,
            filters: filtersCategoriesOptions,
            onFilter: (value, record) => record.taskCategoryId.indexOf(value as string) === 0,
            align:'center'
        },
        {
            title: "Created Date",
            dataIndex: "createdAt",
            render: getFormatDate,
            sorter: (a,b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime(),
            width:120,
            align:'center'
        },
        {
            title: "Reminder Date",
            dataIndex: "reminderDate",
            render: (date) => renderDateCellWithClassOnDateBefore(date, 'yellow-table-cell'),
            sorter: (a,b) => sortByDate(a?.reminderDate, b?.reminderDate),
            width:120,
            align:'center'
        },
        {
            title: "Due Date",
            dataIndex: "dueDate",
            render: (date) => renderDateCellWithClassOnDateBefore(date, 'red-table-cell'),
            sorter: (a,b) => sortByDate(a?.dueDate, b?.dueDate),
            width:120,
            align:'center'
        },
        {
            title: "Status",
            dataIndex: "status",
            render: renderStatus,
            filters: filtersStatusOptions,
            onFilter: (value, record) => record.status.indexOf(value as string) === 0,
            width: 120,
            align:'center'
        },
        // {
        //     title: "Pinned",
        //     dataIndex: "pin",
        //     render: (pinned) => pinned && <Tag color="green">Pinned</Tag>
        // },
        {
            title: "Actions",
            fixed: "right",
            className: "actions-column",
            render: renderActions,
            width: 200,
        }
    ]

 
    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            await getUserTasksData({ userId: id });
            setIsLoading(false);
        };
        fetchData();
    }, [lastUpdated])

    return (
        <>
            <PageHeader title="Tasks" />
            <Table
                scroll={{
                    x: 'auto'
                }}
                size="small"
                loading={isLoading}
                columns={columns}
                dataSource={tasks}
            />
        </>
    )

};


export default TasksTable;