import React, { FC } from 'react';
import { ModalForm } from '@ant-design/pro-form';
import { useAppSelector } from 'redux/store';
import TaskForm from './TaskForm';
import { ITask, ITaskForm } from 'Interfaces/TasksCategories';
import { Form } from 'antd';
import antNotificationComponent from 'utils/notification-ant-utils';

interface AddTaskFormProps {
    onCancel: () => void,
    onFinish: (formData: ITaskForm) => Promise<ITask>,
    onUpdate: () => void
}

const AddTaksForm: FC<AddTaskFormProps> = ({ onCancel, onFinish, onUpdate }) => {
    const isVisible = useAppSelector((state) => state.modals.addTaskModalVisible);
    const [form] = Form.useForm();

    const handleCancel = () => {
        onCancel();
        form.resetFields();
    }

    const handleFinish = async (formData: ITaskForm) => {
        const response = await onFinish(formData);
        if(response){
            antNotificationComponent({ type: "success", message: "Saved" });
            handleCancel();
            onUpdate();
        }
    }

    return (
        <ModalForm 
            title="Add Task"
            form={form}
            visible={isVisible}
            onFinish={handleFinish}
            modalProps={{
                onCancel: handleCancel,
                centered: true
            }}
        >
            <TaskForm currentTask={null} />
        </ModalForm>
    )

};

export default AddTaksForm;