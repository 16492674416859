import {useEffect} from "react";
import { useAppDispatch } from 'redux/store';
import useApi from "../hooks/useApi";
import {Logger} from "../utils/helpers";
import {
    notesReducerReset, 
    setLastUpdateNotes, 
    setNotes, 
    setNotesPage
    } from "../redux/reducers/notesReducer";
import { IClientNote, IClientNoteEmoji, IGetClientNote } from "Interfaces/ClientNote";
import { IMessageStatus } from "Interfaces/Auth";

const logger = Logger("NotesApiWithRedux")

export interface INoteQuery {
    page?: number,
    userId?: string
};

interface IPostNote {
    notes: string,
    userId: string
};

function useNotesApiWithRedux() {
    const {API, cancel} = useApi()
    const dispatch = useAppDispatch()

    const getNotesData = async (query: INoteQuery = {}) => {
        const params = {...query}
        try {
            const response = await API.get<IGetClientNote>(`/api/client-note/`, {params});
            return response.data
        } catch (e) {
            if(e instanceof Error){
                logger.error(e.message)
            }
        }
    }

    const setNote = async (data: IPostNote) => {
        try {
            const response = await API.post<IClientNote>('/api/client-note/', {...data});
            return response.data
        } catch (e) {
            if(e instanceof Error){
                logger.error(e.message)
            }
        }
    }

    const setNoteActions = async (data: {action: string, actionDetail: string, notesId: string}) => {
        try {
            const response = await API.post<IClientNoteEmoji>((`/api/client-note-action/`), {...data})
            return response.data
        } catch (e) {
            if(e instanceof Error){
                logger.error(e.message)
            }
        }
    }

    const deleteNoteActions = async (id: string) => {
        try {
            const response = await API.delete<IMessageStatus>((`/api/client-note-action/${id}`))
            return response.data
        } catch (e) {
            if(e instanceof Error){
                logger.error(e.message)
            }
        }
    }

    const notesUpdate = () => dispatch(setLastUpdateNotes({lastUpdate: Date.now()}))

    const getNotes = async (query: INoteQuery)=>{
        const data = await getNotesData(query)
        if(!!data) {
            dispatch(setNotes({...data}))
        }
    }

    const addNote = async (id: string, values: {notes: string}) => {
      const data = await setNote({...values, userId:id})
        if(!!data){
            notesUpdate()
            return data
        }
    }

    const setActions = async ({action, notesId, actionDetail}: {action: string, actionDetail: string, notesId: string}) => {
        const data = await setNoteActions({action, notesId, actionDetail})
        if(!!data){
            notesUpdate()
            return data
        }
    }

    const deleteActions = async ({actionId}: {actionId: string}) => {
        const data = await deleteNoteActions(actionId)
        if(!!data){
            notesUpdate()
            return data;
        }
    }

    const notesPageUpdate = (page: number) => {
        dispatch(setNotesPage({page}))
        notesUpdate()
    }

    useEffect(() => () => {dispatch(notesReducerReset())}, [])

    return {
        notesApiCancel:cancel,
        getNotes,
        addNote,
        setActions,
        deleteActions,
        notesPageUpdate,
        notesUpdate,
    }
}

export default useNotesApiWithRedux;