import {useCallback, useEffect, useState} from "react";
import { useAppDispatch, useAppSelector } from "redux/store";
import useApi from "hooks/useApi";
import moment from "moment";
import {
    setCurrentUser,
    setCurrentUserChatList,
    setMoreMessagesList, 
    setSendErrorMessage, 
    setSendMessage,
} from "redux/reducers/chatReducer";
import {unEmojifyMessage} from "utils/helpers";
import {CHAT_SEARCH_USERS, FETCH_CHAT_DELAY, FETCH_CHAT_PAGE_SIZE} from "vars";
import { ResponseTypeChat } from "Types";
import { IChat, IChatParams, IChat_Message } from "Interfaces/Chat";
import { IUser } from "Interfaces/User";
import { ActionCreatorWithPayload } from "@reduxjs/toolkit";

interface Props {
    responseType: ResponseTypeChat | null,
    searchList: IChat[]
};

const useMessagesApi = ({responseType, searchList}: Props) => {
    const {API} = useApi();
    const dispatch = useAppDispatch();
    const currentUser = useAppSelector((state) => state.chat.currentUser);
    const messagesList = useAppSelector((state) => state.chat.currentUserChatList);
    const messagesListWithError = useAppSelector((state) => state.chat.currentUserChatListWithError);
    const authUser = useAppSelector((state) => state.auth.user!);
    const totalCount = useAppSelector((state) => state.chat.currentUserTotalChats);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const getMoreUserMessages = async (params: IChatParams) => {
        try {
            const response = await API.get(`/api/chat/${currentUser?.userId}`,{
                params
            });
            dispatch(setMoreMessagesList(response.data));
        }catch (e) {
            if(e instanceof Error){
                console.log(e.message);
            }
        }
    };

    const getResponseTypeAction = () => {
        if(!responseType && !searchList?.length){
            return null;
        };
        if(searchList.length > 0){
            return CHAT_SEARCH_USERS
        }
        return responseType;
    }

    const updateReadCount = async () => {
        if(!currentUser) return;
        await API.put(`/api/chat/read-until/${messagesList[0]?.id || currentUser.id}/${currentUser.userId}`);
    }

    const getUserMessages = useCallback(async (params = {}, loading = false) => {
        if(!currentUser) return;
        setIsLoading(loading);
        try {
            const response = await API.get(`/api/chat/${currentUser.userId}`,{
                params
            });
                dispatch(setCurrentUserChatList({
                    messages: response.data.messages,
                    totalCount: response.data.totalCount,
                    responseType: getResponseTypeAction(),
                }));
            setIsLoading(false);
        }catch (e) {
            console.log(e)
        }
    }, [currentUser]);

    const onSendMessage = async (notes: string, callback: ActionCreatorWithPayload<{
        currentUser: IChat | null,
        data: IChat_Message,
        responseType: ResponseTypeChat | null
    }>) => {
        if(!notes.trim()) return;
        const data = {
            notes: unEmojifyMessage(notes).trim(),
            userId: currentUser?.userId
        };
        const date = moment().format();
        try {
            dispatch(setSendMessage({notes: notes.trim(), isSendingMessage: true, date, authorName: authUser.fullName, authorRole: authUser.roles} as IChat_Message));
            const response = await API.post<IChat_Message>('/api/chat', data);
            dispatch(callback({currentUser, data: response.data, responseType: getResponseTypeAction()}));
        }catch (e) {
            dispatch(setSendErrorMessage({
                ...currentUser, 
                notes: notes.trim(), 
                isSendingMessage: false, 
                isSendingMessageError: true, 
                date, 
                authorName: authUser.fullName, 
                authorRole: authUser.roles,
                checkInId: null,
                isNotAnsweredForDay: false,
                isNotAnsweredForTwoDays: false,
                updated: null,
                internal: false,
                isRead: false,
                usersSeen: []
            } as IChat_Message));
                if(e instanceof Error){
                    console.log(e.message);
                }
        }
    };

    useEffect(() => {
        getUserMessages({page: 1, pageSize: FETCH_CHAT_PAGE_SIZE}, true);
        const getIntervalMessages = setInterval(() => {
            getUserMessages({page: 1, pageSize: FETCH_CHAT_PAGE_SIZE}, false);
        }, FETCH_CHAT_DELAY);
        return () => {
            clearInterval(getIntervalMessages);
        }
    },[getUserMessages])

    useEffect(() => {
        if(messagesList.length > 0){
            updateReadCount()
        }
    }, [messagesList])

    const onSetClearCurrentUser = useCallback(() => {
        dispatch(setCurrentUserChatList({
            messages: [],
            firstPage: [],
            totalCount: 0,
            responseType: responseType
        }));
        dispatch(setCurrentUser(null));
        setIsLoading(true);
    }, []);

    return {
        messagesList,
        onSetClearCurrentUser,
        getMoreUserMessages,
        messagesListWithError,
        totalCount,
        isLoading,
        onSendMessage,
    }
};

export default useMessagesApi;