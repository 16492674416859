import React, { useEffect } from 'react';
import { useAppSelector } from 'redux/store';
import withPermission from "HOCs/withPermission";
import withSidebarNavigation from "HOCs/withSidebarNavigation";
import NotFoundPage from "../not-found/NotFoundPage";
import ManagerSidebar from './ManagerSidebar';
import { Button, Input, Menu, PageHeader, Space, Table, Layout, Tag } from 'antd';
import {DownOutlined, EditOutlined, AuditOutlined, DeleteOutlined} from '@ant-design/icons'
import { useHistory } from 'react-router-dom';
import AddManagerForm from './AddManagerForm';
import useModals from 'services/modals-service';
import { useManagersApiWithRedux } from 'services/managers-service';
import EditManagerForm from './EditManagerForm';
import DeleteManagerModal from './DeleteManagerModal';
import DeleteManagersModal from './DeleteManagersModal';
import {getPopoverTextWithIcon} from "utils/helpers";
import { IManager } from 'Interfaces/User';
import { Dropdown } from 'components/Antd/DropDown';
import { ColumnType } from 'antd/es/table';
import { SearchProps } from 'antd/lib/input';
import { ColumnProps, TableProps } from 'antd/lib/table';

const ManagerList = () => {
    const {goBack} = useHistory();
    const {setAssignManagerVisible, setEditManagerVisible, setRemoveManagerVisible, setRemoveManagersVisible} = useModals();
    const {
        getManagersData,
        setSelectedRowManager,
        setCurrentManager,
        onCreateManager,
        onFilterManagers,
        onEditManager,
        onRedirectToActivityPage,
        onRemoveManager,
        onInitMacros
    } = useManagersApiWithRedux();
    const updateList = useAppSelector((state) => state.managers.lastUpdate);
    const managersData = useAppSelector((state) => state.managers.managersData);
    const currentManager = useAppSelector((state) => state.managers.currentManager);
    const filteredManagers = useAppSelector((state) => state.managers.filteredManagers);
    const selectedManagers = useAppSelector((state) => state.managers.selectedManagers);




    const selectedManagersFullName = () => managersData
    .filter((dataItem) => selectedManagers.includes(dataItem.id))
    .map(({fullName}) => fullName).join(', ');

    const onEditManagerVisible = (manager: IManager | null) => {
        setCurrentManager(manager);
        setEditManagerVisible(true);
    };

    const onRemoveManagerVisible = (manager: IManager | null) => {
        setCurrentManager(manager);
        setRemoveManagerVisible(true);
    };

    const onRemoveManagerUnvisible = () => {
        setCurrentManager(null);
        setRemoveManagerVisible(false);
    }

    const onCancelEditManagerVisible = () => {
        setEditManagerVisible(false);
        setCurrentManager(null);
    };

    const initMacros = async () => {
        const filteredManagers = managersData.filter((manager) => selectedManagers.includes(manager.id));
        const dataManagers = filteredManagers.map((manager) => ({
            ...manager,
            createMacros: true
        }));
        await onInitMacros(dataManagers);
    }

    const onRemoveManagers = () => onRemoveManager(selectedManagers);

    const menuExtra = (
        <Menu>
            <Menu.Item
                onClick={initMacros}
                key="1">
                Init macros
            </Menu.Item>
            <Menu.Item 
            onClick={() => setRemoveManagersVisible(true)}
            key="2">
                Remove users
            </Menu.Item>
        </Menu>
    )
    
    const handleFilterManagers: SearchProps['onSearch'] = (value) => {
        onFilterManagers({searchValue: value, managersData})
    };

    const extra = (
        <Space size="large" align="end">
            <Button 
            type="primary"
            onClick={() => setAssignManagerVisible(true)}
            >
                New
                </Button>
            <Input.Search 
            enterButton 
            onSearch={handleFilterManagers}
            placeholder="Search" 
            />
            <Dropdown 
            overlay={menuExtra}
             trigger={["click"]} 
             disabled={selectedManagers.length === 0}
             >
                <Button>Actions <DownOutlined /></Button>
            </Dropdown>
        </Space>
    )

    const statusRender: ColumnType<IManager>['render'] = (disabled: boolean = true) => (
        <Tag
        color={disabled ? "volcano" : "green"}
        >
            {disabled ? "DISABLED" : "ACTIVE"}
        </Tag>
    );


    const actionsRender: ColumnType<IManager>['render'] = (manager: IManager) => (
        <Space size="middle">
            <Button
            shape="circle"
            type="primary"
            icon={<EditOutlined />}
            onClick={() => onEditManagerVisible(manager)}
            />
            <Button
            shape="circle"
            type="primary"
            icon={<AuditOutlined />}
            onClick={() => onRedirectToActivityPage(manager)}
            />
            <Button
            shape="circle"
            type="primary"
            danger
            onClick={() => onRemoveManagerVisible(manager)}
            icon={<DeleteOutlined />}
            />
        </Space>
    );
    
    const filterStatus = [
        {
            text: 'DISABLED', value: true
        },
        {
            text: 'ACTIVE', value: false
        }
    ];

    const handleFilterStatus: ColumnType<IManager>['onFilter'] = (value, row) => value === row.disabled;

    const rowSelection: TableProps<IManager>['rowSelection'] = {
        selectedRowKeys: selectedManagers,
        onChange: setSelectedRowManager
    };

    useEffect(() => {
         getManagersData();
    }, [updateList]);

    const columns: ColumnProps<IManager>[] = [
        {
            title: "Full Name",
            dataIndex: "fullName",
            render: (_,r) => getPopoverTextWithIcon(r.fullName),
            sorter: (a,b) => a.fullName.localeCompare(b.fullName)
        },
        {
            title: "Email",
            dataIndex: "email",
            sorter: (a,b) => a.email.localeCompare(b.fullName),
        },
        {
            title: "Start Date",
            dataIndex: "startDate",
            className: "table-column-title-no-break"
        },
        {
            title: "Competing",
            dataIndex: "competing",
            className: "table-column-title-no-break"
        },
        {
            title: "Status",
            dataIndex: "disabled",
            onFilter: handleFilterStatus,
            filters: filterStatus,
            render: statusRender
        },
        {
            title: "Action",
            fixed: "right",
            render: actionsRender
        },
    ];

    return (
        <Layout className="coach_main_layout">
             <ManagerSidebar data={managersData} />
            <Layout>
                <Space className="page_wrapper_style" direction="vertical">
                    <PageHeader 
                    title="Managers" 
                    onBack={goBack} 
                    extra={extra} 
                    className="site-layout-background" 
                    />
                    <Table
                        rowSelection={rowSelection}
                        dataSource={filteredManagers.length > 0 ? filteredManagers : managersData}
                        rowKey={rw => rw.id} 
                        scroll={{x: 'auto', y: '62vh', scrollToFirstRowOnChange: true}}
                        pagination={{position: ['bottomRight']}}
                        columns={columns}
                    />
                    
                </Space>
                <AddManagerForm
                onFinish={onCreateManager}
                onCancel={setAssignManagerVisible}
                />
                <EditManagerForm
                onFinish={onEditManager}
                currentManager={currentManager as IManager}
                onCancel={onCancelEditManagerVisible}
                />
                <DeleteManagerModal
                onOk={onRemoveManager}
                onCancel={onRemoveManagerUnvisible}
                currentManager={currentManager as IManager}
                />
                <DeleteManagersModal
                selectedManagersFullName={selectedManagersFullName}
                onOk={onRemoveManagers}
                onCancel={() => setRemoveManagersVisible(false)}
                />
            </Layout>
        </Layout>
    )
};



export default withPermission(withSidebarNavigation(ManagerList, '/managers'), NotFoundPage);