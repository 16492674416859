import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import { useAppSelector } from 'redux/store';
import {DatePicker, Input, Switch, PageHeader, Space, Table, TablePaginationConfig} from "antd";
import { RangePickerProps } from "antd/lib/date-picker";
import checkInTableConfig from "../checkin/check_in_table/check_in_table_config";
import moment from "moment";
import {getAutoGeneratedValue, getQueryParams, tablePagination,} from "../../utils/helpers";
import {CHECKIN, DATE_FORMAT} from "../../vars";
import useCheckInsApiWithRedux from "services/check-ins-service";
import useScrollTo from "hooks/useScrollTo";

export default function CoachMainCheckInsTable() {

    const {RangePicker} = DatePicker;
    const {Search} = Input;
    const {id} = useParams() as {id: string};
    const checkInData = useAppSelector(state => state.checkIns.checkInData)
    const totalCount = useAppSelector(state => state.checkIns.totalCount);
    const lastUpdate = useAppSelector(state => state.checkIns.lastUpdate);
    const checkInTableSettings = useAppSelector(state => state.checkIns.checkInTableSettings)
    const [loading, setLoading] = useState(true)
    const {getCheckInColumns} = checkInTableConfig()
    const {
        checkInsApiCancel,
        handleCheckinSearch,
        getCheckIn,
        dateChange,
        changeSearchValue,
        handleCheckInsTableChange,
        handleChangeAutoGenerated
    } = useCheckInsApiWithRedux()
    const [isCheckedAuto, setIsCheckedAuto] = useState(false);

    const handleChangeCheckedAuto = (value: boolean) => {
        setIsCheckedAuto(value);
        handleChangeAutoGenerated(value);
    };

    const {
        pageSize,
        page,
        f_clientName,
        f_startDate,
        f_endDate,
        f_autoGenerated
    } = checkInTableSettings

    const ranges: RangePickerProps['ranges'] = {
        "This Week": [moment().weekday(-1), moment()],
        "Last Week": [moment().weekday(-7), moment().weekday(-1)]
    }

    const extras = (
        <Space size="large" direction="horizontal" align="center">
            <Switch checkedChildren="Auto" unCheckedChildren="Auto" disabled={loading} checked={isCheckedAuto} onChange={handleChangeCheckedAuto} />
            <Search
                placeholder="Search"
                onChange={(e) => changeSearchValue(e.target.value)}
                value={f_clientName || ""}
                onSearch={handleCheckinSearch}
                enterButton
            />
            <RangePicker
                defaultValue={!!f_startDate && !!f_endDate ? [moment(f_startDate), moment(f_endDate)] : null}
                onChange={dateChange}
                format={DATE_FORMAT}
                ranges={ranges}
            />
        </Space>
    )

    const {scrollRef} = useScrollTo<HTMLDivElement>({dependency:[pageSize, page], offset:20})

    useEffect(() => {
        let cleanupFunction = false;
        const fetchData = async () => {
            setLoading(true)
            await getCheckIn({...getQueryParams(checkInTableSettings), delta:false, f_coachId: [id]})

            if (!cleanupFunction) {
                setLoading(false)
            }
        }

        fetchData();

        return () => {
            checkInsApiCancel()
            cleanupFunction = true
        }
    }, [lastUpdate])

    return (
        <>
            <PageHeader className="site-layout-background" extra={extras}/>
            <div ref={scrollRef}/>
            <Table key={CHECKIN}
                   pagination={tablePagination({pageSize, page, totalCount, showSizeChanger: true,}) as TablePaginationConfig}
                   columns={getCheckInColumns.filter((column) => column.title !== 'Coach')}
                   dataSource={checkInData}
                   loading={loading}
                   rowClassName={({autoGenerated}) => autoGenerated ? 'checkin-row-autogenerated' : ''}
                   onChange={handleCheckInsTableChange}
                   rowKey={(e) => e.id}
                   scroll={{x: 'auto'}}
            />
        </>
    )
}