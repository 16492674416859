import React, { FC, useCallback } from 'react';
import { Button, Popover, Space, Tag } from 'antd';
import {InfoCircleOutlined} from "@ant-design/icons";

import { useAppSelector } from 'redux/store';
import moment from 'moment';
import antNotificationComponent from 'utils/notification-ant-utils';
import type { ModalType } from 'services/modals-service';
import { DATE_FORMAT_HOURS_MINUTES } from 'vars';
import './styles/client-header.css';

export type NotificationsBlockProps = {
    setPauseNotificationsVisible: ModalType;
    reenableNotifications: (userId: string) => Promise<void>;
}

const NotificationsBlock: FC<NotificationsBlockProps> = ({ setPauseNotificationsVisible, reenableNotifications }) => {
    const pausedNotifications = useAppSelector(state => state.user.pausedNotifications);
    const user = useAppSelector(state => state.user.userData!)

    const handleReenableNotifications = useCallback(async () => {
        try {
            await reenableNotifications(user.id);
            antNotificationComponent({message: "Notifications enabled", type: 'success'})
        } catch (err) {
            antNotificationComponent({message: "Failed to enable notification", type: 'error'})
        }
    }, [])

  return pausedNotifications ?
    (<Space align="end" direction="vertical">
        <Space>
            <Tag color="warning" className="notifications-disabled-tag">
                Notifications disabled until <b>{moment(pausedNotifications.blockUntil).format(DATE_FORMAT_HOURS_MINUTES)}</b>
            </Tag>
            {pausedNotifications.reasonMessage &&
                (<Popover content={pausedNotifications.reasonMessage} placement="left">
                    <Tag color="orange" className="pause-reason-tag">
                        <InfoCircleOutlined/>
                    </Tag>
                </Popover>)
            }
        </Space>
        <Space size="middle" className="notification-buttons-block">
            <Button type="primary" ghost onClick={handleReenableNotifications}>Reenable</Button>
            <Button onClick={() => setPauseNotificationsVisible(true)} danger>Extend</Button>
        </Space>
    </Space>)
    : null;
};

export default NotificationsBlock;
