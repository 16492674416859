import React, {useState} from 'react';
import {Comment, Tooltip, Avatar, Button, Row, Col, Typography} from "antd";
import {SmileOutlined} from "@ant-design/icons";
import moment from "moment";
import 'emoji-mart/css/emoji-mart.css'
import {Emoji} from 'emoji-mart';
import Picker, { SkinTones } from 'emoji-picker-react';
import {admin} from "vars";
import { useAppSelector } from 'redux/store';
import { Modal } from 'components/Antd/Modal';
import { UserType } from 'Types';
import { IUser } from 'Interfaces/User';
import { IMessageStatus } from 'Interfaces/Auth';
import { IClientNoteEmoji } from 'Interfaces/ClientNote';

export interface IEmojiData {
    unified?: string;
    originalUnified?: string;
    names?: Array<string>;
    emoji?: string;
    activeSkinTone?: SkinTones;
  }
interface IEmojiActions {
    action: string,
    actionDetail: string,
    users: {
        actionId: string,
        actorFullName: string,
        actorId: string
    }[]
}

interface IEmojiActionsClick {
    action?: string,
    actionDetail?: string,
    users?: {
        actionId: string,
        actorFullName: string,
        actorId: string
    }[]
}

interface IEmojiDataClick extends IEmojiActionsClick, IEmojiData{}
interface Props {
    Coach: IUser,
    notes: string,
    date: string,
    id: string,
    actions: IEmojiActions[],
    deleteActions: (actions: {actionId: string}) => Promise<IMessageStatus | undefined>,
    setActions: (actions: {
        action: string;
        actionDetail: string;
        notesId: string;
    }) => Promise<IClientNoteEmoji | undefined>
}


export default function Note({Coach, notes, date, actions, id, deleteActions, setActions}: Props) {
    const authUserId = useAppSelector(state => state.auth.user!.id)
    const [showEmoji, setShowEmoji] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const emojiClick = async ({emoji, actions}: {emoji: IEmojiDataClick, actions: IEmojiActions[]}) => {
        setIsLoading(true);

        const currentAction = actions.find((action) => action.action === emoji.action);
        const currentActionPicker = actions.find((action) => action.action === emoji.unified);
        
        if(currentAction){
            const userAction = currentAction.users.find((user) => user.actorId === authUserId);
            if(userAction){
                const data = await deleteActions({actionId: userAction.actionId});
                if(data){
                   return setIsLoading(false)
                }
                return null
            }
                const data = await setActions({action: currentAction.action, actionDetail: currentAction.actionDetail, notesId: id})
                if(data){
                   return setIsLoading(false)
                }
        }
        if(currentActionPicker){
            const userActionPicker = currentActionPicker.users.find((user) => user.actorId === authUserId);
            if(userActionPicker){
                const data = await deleteActions({actionId: userActionPicker.actionId});
                if(data){
                    return setIsLoading(false)
                }
                return null
            }
            const data = await setActions({action: currentActionPicker.action, actionDetail: currentActionPicker.actionDetail, notesId: id})
                if(data){
                    return setIsLoading(false)
                }
        }
        const setNameEmoji = () => {
            if(emoji?.names!?.length > 1){
                return emoji.names![emoji.names!?.length - 1]
            }
            return emoji.names![0];
        };

        const setEmoji = async () => {
            setIsLoading(true)
            const skinToneSplit = emoji.activeSkinTone;
            if(skinToneSplit === 'neutral' || !skinToneSplit){
                return setNameEmoji();
            }

            switch (skinToneSplit.slice(-1)){
                case 'f': {
                    return `:${setNameEmoji()}::skin-tone-6:`;
                }
                case 'e': {
                    return `:${setNameEmoji()}::skin-tone-5:`
                }
                case 'd': {
                    return `:${setNameEmoji()}::skin-tone-4:`
                }
                case 'c': {
                    return `:${setNameEmoji()}::skin-tone-3:`
                }
                case 'b': {
                    return `:${setNameEmoji()}::skin-tone-2:`
                }
                default:
                    return ''
            }
        }
         const data = await setActions({action: emoji.unified as string, actionDetail: await setEmoji(), notesId: id});
        if(data){
            setIsLoading(false)
        }
    }

    const renderContentEmoji = (emoji: IEmojiActions) => {
        return (
            <div
                className="notes_list_emoji_content"
            >
                <Emoji
                    emoji={emoji.actionDetail}
                    size={40}
                />
                {emoji.users.map((user) => <span key={user.actorId}>{user.actorFullName}</span>)}
            </div>
        )
    }

    const renderListEmoji = (actions: IEmojiActions[]) => {
        const emojiActions = actions.map((emoji) => {
            const selectedEmoji = emoji.users.some((user) => user.actorId === authUserId);
            return (
                <Col span={1.5} key={emoji.actionDetail}>
                    <Tooltip
                        color={'#000'}
                        title={renderContentEmoji(emoji)}
                    >
                        <Button type="text" style={{
                            display: 'flex',
                            alignItems: 'center',
                            borderRadius: 20,
                            border: '1px solid #239CD0',
                            borderColor: selectedEmoji ? '#239CD0' : '#b6b6b6' ,
                            backgroundColor: selectedEmoji ? '#E2EFF4' : '#F6F6F6'
                        }}
                                // loading={isLoading}
                                onClick={() => !isLoading && emojiClick({emoji, actions})}>
                            <Emoji
                                emoji={emoji.actionDetail}
                                size={18}
                            />
                            <span
                                className="notes_list_emoji_counter"
                                style={{color: selectedEmoji ? '#1264A3' : '#000'}}
                            >
                            {emoji.users.length}
                        </span>
                        </Button>
                    </Tooltip>
                </Col>
            )
        })

        const modalEmoji = (
           <Button
           onClick={() => setShowEmoji(true)}
           type="text"
           >
               <SmileOutlined />
           </Button>
        )
        return [...emojiActions, modalEmoji]
    }

    const avatarRender = (Author: UserType) => (
        <Avatar
            style={{
                color: Author.roles === admin ? '#531dab' : '#096dd9',
                backgroundColor: Author.roles === admin ? '#f9f0ff' : '#e6f7ff'
            }}>
            {Author.firstName[0]}{Author.lastName[0]}
        </Avatar>
    )

    return (
        <>
            <Comment
                className={'client_page_word_wrap notes_list_note_emoji_wrapper'}
                // actions={renderListEmoji(actions)}
                author={!!Coach?.fullName ? Coach?.fullName : 'SMP'}
                avatar={avatarRender(Coach)}
                content={<>
                    <Typography.Text>{notes}</Typography.Text>
                    <Row style={{paddingTop: 25}} gutter={[12,12]}>
                        {renderListEmoji(actions)}
                    </Row>
                </>}
                datetime={moment(date).fromNow()}
            />
            <Modal
                centered
                mask={false}
                footer={null}
                closable={false}
                destroyOnClose
                bodyStyle={{
                    padding: 0
                }}
                visible={showEmoji}
                onCancel={() => setShowEmoji(false)}
            >
                <Picker
                    onEmojiClick={(_,emoji) => {
                        emojiClick({emoji, actions});
                        setShowEmoji((state) => !state);
                    }}
                />
            </Modal>
        </>
    )
}