import { PayloadAction } from '@reduxjs/toolkit';
import { IMessageTemplate } from 'Interfaces/MessageTemplates';
import {createSlice} from '@reduxjs/toolkit'

interface IninitialState {
    messageTemplatesData: IMessageTemplate[],
    selectedTemplates: string[],
    lastUpdate: number
}

const initialState = {
    messageTemplatesData: [],
    selectedTemplates: [],
    lastUpdate: Date.now()
} as IninitialState;

const messageTemplatesReducer = createSlice({
    name: 'messageTemplatesReducer',
    initialState,
    reducers: {
        setMessageTemplates: (state, action: PayloadAction<{messageTemplatesData: IMessageTemplate[]}>) => {
            state.messageTemplatesData = action.payload.messageTemplatesData
        },
        setSelectedTemplates: (state, action: PayloadAction<{selectedTemplates: string[]}>) => {
            state.selectedTemplates = action.payload.selectedTemplates
        },
        setMessageTemplatesUpdate: (state, action: PayloadAction<{lastUpdate: number}>) => {
            state.lastUpdate = action.payload.lastUpdate
        },
        messageTemplatesReducerReset: state => initialState,
    }
})

export const {
    setMessageTemplates,
    setMessageTemplatesUpdate,
    setSelectedTemplates,
    messageTemplatesReducerReset
} = messageTemplatesReducer.actions
export default messageTemplatesReducer.reducer


