import React from 'react';
import DashboardPageChartsBar from "./DashboardPageChartsBar";
import {shallowEqual} from "react-redux";
import {checkDashboardData, getSubstringText} from "utils/helpers";
import NoData from "components/no-data/NoData";
import { useAppSelector } from 'redux/store';
import { ChartConfiguration } from 'chart.js';


const DashboardPagePerformanceCoach = () => {

    const coachClientsData = useAppSelector(state => state.dashboard.coachClientsData, shallowEqual);

    // const filteredClientsData =  coachClientsData.filter(({Coach}) => Coach);

    const labels = coachClientsData.map(({fullName}) => getSubstringText(fullName, 30));

    const performanceBarData: ChartConfiguration<'bar'>['data'] = {
        labels,
        datasets: [
            // {
            //     label: 'Active',
            //     data: filteredClientsData.map(({activeCheckinedLate, activeCheckinedNotLate}) => activeCheckinedLate + activeCheckinedNotLate),
            //     backgroundColor: "#c2ec9d",
            //     stack: 'Stack 0'
            // },
            // {
            //     label: 'Inactive',
            //     data: filteredClientsData.map(({inactiveMissedOneWeek, inactiveMissedMoreThanTwoWeeks}) => inactiveMissedOneWeek + inactiveMissedMoreThanTwoWeeks),
            //     backgroundColor: "#c94444",
            //     stack: 'Stack 0'
            // },
            {
                label: 'Checked in',
                data: coachClientsData.map(({activeCheckinedNotLate}) => activeCheckinedNotLate),
                backgroundColor: "#a3f06c",
                stack: 'Stack 0'
            },
            {
                label: 'Late',
                data: coachClientsData.map(({activeCheckinedLate}) => activeCheckinedLate),
                backgroundColor: "#b6b5b5",
                stack: 'Stack 0'
            },
            {
                label: 'Missed 1',
                data: coachClientsData.map(({inactiveMissedOneWeek}) => inactiveMissedOneWeek),
                backgroundColor: "#e66060",
                stack: 'Stack 0'
            },
            {
                label: 'Missed 2+',
                data: coachClientsData.map(({inactiveMissedMoreThanTwoWeeks}) => inactiveMissedMoreThanTwoWeeks),
                backgroundColor: "#cc2727",
                stack: 'Stack 0'
            },
        ]
    }

    const options: ChartConfiguration<'bar'>['options'] = {
        plugins: {
            title: {
                display: true,
                position: 'bottom',
                text: '',
            },
        },
        scales: {
            x:{
                stacked: true
            },
            y:{
                stacked: true
            },
        }
    };


    const performanceBarProps: ChartConfiguration<'bar'> = {
        type: 'bar',
        data: performanceBarData,
        options: options,
        // height: 410,
        // width: 410
    }
    return checkDashboardData(performanceBarData.datasets) ?
    <DashboardPageChartsBar
        title="Coach client breakdown"
        {...performanceBarProps}
    /> :
    <NoData description="Coach client breakdown (No Data)" />


}



export default DashboardPagePerformanceCoach;
