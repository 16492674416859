import React, {FC, useEffect} from 'react';
import {ModalForm, ProFormSwitch, ProFormText} from "@ant-design/pro-form";
import {Form} from "antd";
import {isOnlySpaces, validateLink} from "utils/helpers";
import { useAppSelector } from 'redux/store';
import { CommonFormProps } from '@ant-design/pro-form/lib/BaseForm';
import { IClientTypeForm } from 'services/clientTypes-service';


interface Props {
    initialValues: IClientTypeForm,
    title: React.ReactNode,
    onFinish: CommonFormProps<IClientTypeForm>['onFinish']
    onCancel: () => void
};


const ClientTypeForm: FC<Props> = ({onFinish, onCancel, title, initialValues}) => {
    const [form] = Form.useForm<IClientTypeForm>();
    const visible = useAppSelector((state) => state.modals.assignClientTypeModalVisible);


    useEffect(() => {
        form.setFieldsValue(initialValues);
        return () => {form.resetFields()};
    },[visible])

    return <ModalForm
    initialValues={initialValues}
    form={form}
    onFinish={onFinish}
    visible={visible}
    modalProps={{
        onCancel,
        centered: true
    }}
    title={title}
    >
        <ProFormText
            label="Client Type"
            name="name"
            placeholder="Client Type"
            rules={[
            {
                required: true,
                message: 'This field is required',
            },
            ({getFieldValue}) => ({
                validator(_, value) {
                    if (!value || !isOnlySpaces(getFieldValue('name'))) {
                        return Promise.resolve();
                    }
                    return Promise.reject(new Error('This field is required'));
                },
            })]}
        />
        <ProFormText
            label="Description"
            name="description"
            placeholder="Description"
            rules={[
                {
                    required: true,
                    message: 'This field is required',
                },
                ({getFieldValue}) => ({
                    validator(_, value) {
                        if (!value || !isOnlySpaces(getFieldValue('description'))) {
                            return Promise.resolve();
                        }
                        return Promise.reject(new Error('This field is required'));
                    },
                })]}
        />
        <ProFormText
            label="Welcome Pack URL"
            name="welcomePackUrl"
            placeholder="https://"
            rules={[
                {
                    required: true,
                    message: 'This field is required',
                },
                ({getFieldValue}) => ({
                   async validator(_, value) {
                        if (!value || !isOnlySpaces(getFieldValue('welcomePackUrl'))) {
                            await validateLink(value);
                            return Promise.resolve();
                        }
                        return Promise.reject(new Error('This field is required'));
                    },
                })]}
        />
        <ProFormSwitch
            name="enabled"
            label="Enabled"
        />
    </ModalForm>
}

export default ClientTypeForm;