import React, {useEffect, useState} from 'react';
import { useAppSelector } from 'redux/store';
import {useParams} from "react-router-dom";
import {Button, Space} from "antd";
import LoadingSpin from "components/loading-spin/LoadingSpin";
import ClientHeader from "./ClientHeader";
import useUserApiWithRedux from "services/user-service";
import useCheckInsApiWithRedux from "services/check-ins-service";
import useUsersApiWithRedux from "../../services/users-service";
import ClientBody from "./ClientBody";
import ClientModals from "./ClientModals";
import useActivityLog from "hooks/useActivityLog";
import useModals from "services/modals-service";
import withSidebarNavigation from "HOCs/withSidebarNavigation";
import useFeedbackApiWithRedux from "services/feedback-service";
import antNotificationComponent, {antNotificationClose} from "utils/notification-ant-utils";
import moment from "moment";
import useNotesApiWithRedux from "services/notes-service";
import useQuestionnaireApiWithRedux from "services/questionnaire-service";
import useMessageTemplatesApiWithRedux from "services/message-templates-service";
import useSMSApiWithRedux from "services/sms-service";
import { CurrentEvent } from 'Types';
import { ICheckIn } from 'Interfaces/CheckIn';
import { ISendFeedbackModal } from 'pages/checkin/feedbackModal';
import useTasksCategories from 'services/tasksCategories-service';
import "./styles/client.css";

function ClientMain() {
    const params = useParams() as {id: string}
    const user = useAppSelector(state => state.user.userData!)
    const authUser = useAppSelector(state => state.auth.user!);
    const checkInData = useAppSelector(state => state.checkIns.checkInData)
    const currentEvent = useAppSelector(state => state.user.currentEvent)
    const lastUpdate = useAppSelector(state => state.user.lastUpdate)
    const macroModalToggleCount = useAppSelector(state => state.user.macroModalToggleCount)
    const selectedRowKeys = useAppSelector((state) => state.checkIns.checkInSelectedRowKeys);
    const activeTabKey = useAppSelector(state => state.user.activeTabKey)
    const selectedTemplates = useAppSelector(state => state.messageTemplates.selectedTemplates);
    const selectedRowData = useAppSelector(state => state.checkIns.checkInSelectedRowData);
    const messageTemplatesData = useAppSelector(state => state.messageTemplates.messageTemplatesData);
    const [loading, setLoading] = useState<boolean>(true)

    const {dispatchSetActivity, checkActivity} = useActivityLog()

    const messageTemplatesProps = useMessageTemplatesApiWithRedux()

    const questionnaireProps = useQuestionnaireApiWithRedux()

    const notesProps = useNotesApiWithRedux()

    const smsProps = useSMSApiWithRedux()

    const checkInsProps = useCheckInsApiWithRedux()
    const {saveCheckin, getCheckInChart} = checkInsProps

    const usersProps = useUsersApiWithRedux()

    const userProps = useUserApiWithRedux()
    const {getUser, setCurrentEventWithCount, reenableClientNotifications} = userProps

    const feedbackProps = useFeedbackApiWithRedux()
    const {addFeedback,onDeleteFeedback, editFeedback ,setCurrentFeedbackDeleteClear, setEditFeedback, setCurrentFeedbackDelete, onEditFeedback,onSubmitActivationParts, onPatchFeedback} = feedbackProps

    const modalsProps = useModals()

    const tasksCategoriesProps = useTasksCategories();
    const { getUserTasksData, setCategoriesData } = tasksCategoriesProps
    const setUserActivity = async (activityType: CurrentEvent) => {
        const data = {activityType, activityData: {clientId: params.id, instant: true}};
        await dispatchSetActivity(data);
        setCurrentEventWithCount(activeTabKey)
    }

    const saveFeedback = async (values: ISendFeedbackModal) => {
        //Add feedback to each selected row
        const {...rest } = values;
        const checkInId = selectedRowKeys.length === 1 ? selectedRowKeys[0] : checkInData.length > 0 ? checkInData[0].id : ''
        const selectedTemplatesId: string[] = messageTemplatesData
            .filter((template) => selectedTemplates.find(templateName => template.name === templateName || template.id === templateName))
            .map(({id}) => id);
        //Create check-in feedback records & save (apply to latest check-in)
        let feedback = {
            ...rest,
            checkInId,
            user,
            // Author,
            userId: user.id,
            date: moment().parseZone().format(),
            internal: false
        }
        //Save
        const feedbackData = await addFeedback({
            feedback, notify: true, user: user, selectedTemplates: selectedTemplatesId,
            Author: authUser,
            notes: '',
            templates: []
        }); // FixMeLater
        if (!!feedbackData) {
            antNotificationComponent({
                type: "success",
                message: checkInData.length > 0 ?<Space direction="vertical">
                    Feedback saved. Do you want to mark the latest check in as complete?
                    <Button type="primary" onClick={() => completeSelected(true)}>Yes Please</Button>
                </Space>:'Saved',
                key: 'feedbackSuccess',
            })

        } else {
            antNotificationComponent({
                type: "error", message: 'Failed to save',
            })
        }
        return feedbackData
    }

    const completeSelected = async (value: boolean) => {
        antNotificationClose('feedbackSuccess')
        const checkIn = checkInData.length > 0 ? checkInData[0] : {}
        const updatedRow = {...checkIn, feedbackGiven: value}
        const data = await saveCheckin(updatedRow as ICheckIn)
        if (!!data) {
            antNotificationComponent({type: "success", message: 'Saved'})
        } else {
            antNotificationComponent({type: "error"})
        }
    }

    useEffect(() => {
        checkActivity();
        const timer = setInterval(checkActivity, 10000);
        return () => clearInterval(timer);
    }, [])

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true)
            await getUser(params.id);
            setLoading(false);
            await setCategoriesData();
        }
        fetchData();
    }, [lastUpdate]);

    useEffect(() => {
        const data = {activityType: currentEvent, activityData: {clientId: params.id}};
        dispatchSetActivity(data)
    }, [activeTabKey, macroModalToggleCount])

    const props = {
        dispatchSetActivity,
        setUserActivity,
        checkActivity,
        editFeedback,
        saveFeedback,
        onDeleteFeedback,
        setEditFeedback,
        setCurrentFeedbackDelete,
        onSubmitActivationParts,
        setCurrentFeedbackDeleteClear,
        onEditFeedback,
        onPatchFeedback,
        checkInsProps,
        selectedRowData,
        smsProps,
        usersProps,
        userProps,
        modalsProps,
        feedbackProps,
        notesProps,
        questionnaireProps,
        messageTemplatesProps,
        tasksCategoriesProps,
    }

    return (
        !loading ? (
            <Space className={'page_wrapper_style'} direction="vertical">
                <ClientHeader
                    setPauseNotificationsVisible={modalsProps.setPauseNotificationsVisible}
                    reenableNotifications={reenableClientNotifications}
                    getCheckInChart={getCheckInChart}
                    activeTabKey={activeTabKey}
                />
                <ClientBody {...props}/>
                <ClientModals {...props}/>
            </Space>
        ) : <LoadingSpin/>
    )
}

export default withSidebarNavigation(ClientMain, '/users')