import React from 'react';
import { useAppSelector } from 'redux/store';
import {Descriptions} from 'antd';
import moment from 'moment'
import {getPhysiqueGoalText, getPopoverTextWithIcon} from "utils/helpers";

export default function ClientInfoTab() {
    const user = useAppSelector(state => state.user.userData!)

    const getWeeks = (data: string | null | undefined) => {
        if (!data) {
            return "-"
        }

        let start = moment(data)
        let now = moment(Date.now())
        return now.diff(start, 'weeks') + 1
    }

    return (
        <Descriptions title="Key Info" bordered column={4}>
            <Descriptions.Item label="Start Date" span={2}>{user?.startDate}</Descriptions.Item>
            <Descriptions.Item label="Week #">{getWeeks(user?.startDate)}</Descriptions.Item>
            <Descriptions.Item label="Client Type">{user?.ClientType?.name}</Descriptions.Item>
            <Descriptions.Item label="Current Coach"
                               span={2}> {user?.coachId ? getPopoverTextWithIcon(user?.Coach?.fullName) : ''}</Descriptions.Item>
            <Descriptions.Item
                label="Physique Goal">{!!user?.physiqueGoal ? getPhysiqueGoalText(user?.physiqueGoal) : ''}</Descriptions.Item>
            <Descriptions.Item label="Competing">{user?.competing ? "Yes" : "No"}</Descriptions.Item>
            <Descriptions.Item label="Current Goal" style={{whiteSpace: 'pre-wrap'}} span={3}>{user?.currentGoal}</Descriptions.Item>
        </Descriptions>
    )
}