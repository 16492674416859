import { useAppDispatch } from "redux/store";
import useApi from "hooks/useApi";
import {Logger} from "utils/helpers";
import {useParams} from "react-router-dom";
import {setSMSMessages, setUpdateSMS} from "redux/reducers/smsReducer";
import { ISMS } from "Interfaces/SMS";

const logger = Logger("SMSApiWithRedux")

const useSMSApiWithRedux = () => {

    const {API} = useApi();
    const {id} = useParams() as {id: string};
    const dispatch = useAppDispatch();


    const onSendSMS = async ({message}: {message: string}) => {
        try {
            const response = await API.post<ISMS>(`/api/sms`, { recipientId: id, message });
            dispatch(setUpdateSMS(new Date().getTime()))
            return response.data
        }catch (e) {
            if(e instanceof Error){
                logger.error(e.message)
            }
        }
    }

    const getSMSMessages = async () => {
        try {
            const response = await API.get<ISMS[]>('/api/sms', {
                params: {
                    f_userId: id
                }
            });
            dispatch(setSMSMessages(response.data))
        }catch (e) {
            if(e instanceof Error){
                logger.error(e.message)
            }
        }
    }

    return {
        onSendSMS,
        getSMSMessages
    }
}

export default useSMSApiWithRedux