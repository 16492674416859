import React ,{ useState } from "react";
import { useAppSelector } from "redux/store";

function usePersistedState<T>(key: string, initialValue: T): [T, React.Dispatch<T>] {
  const id = useAppSelector(state => state.auth.user?.id)

  const [storedValue, setStoredValue] = useState<T>(() => {
    try {
      const item = window.localStorage.getItem(id + key);
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      return initialValue;
    }
  });

  const setValue = (value: T) => {
    try {
      const valueToStore: T = value instanceof Function ? value(storedValue) : value;
      setStoredValue(valueToStore);
      window.localStorage.setItem(id + key, JSON.stringify(valueToStore as T));
    } catch (error) {
    }
  };

  return [storedValue, setValue];
}

export default usePersistedState;
