import React from 'react';
import { useAppSelector } from 'redux/store';
import RatioGauge from "./RatioGauge";


const RatioGauge_Standard = () => {
    const ratio = useAppSelector((state => state.user.ratio.StandardMacro));

    return (
        <RatioGauge ratio={ratio} />
    )
};

export default RatioGauge_Standard;