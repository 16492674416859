import React, { FC, ReactNode } from 'react';
import { Typography } from 'antd';
import MiniCard from './MiniCard';

const Title = Typography.Title;

type DifferenceMiniCardProps = {
  title: ReactNode;
  values: [string | number, string | number];
  weeksDifference: number;
  withPercentageDifference?: boolean;
  photoView?: boolean;
};

const DifferenceMiniCard: FC<DifferenceMiniCardProps> = ({ values, weeksDifference, title, withPercentageDifference,photoView }) => {

  const differenceCount = Number(values[0]) - Number(values[1]);

  const differencePerWeek = weeksDifference > 0 ? (differenceCount / weeksDifference) : differenceCount;

  const getArrowDirection = () => {
    if (differenceCount === 0) return;
    if (differenceCount > 0) return 'up';
    else return 'down';
  }

  return (
    <MiniCard title={title}
      content={differenceCount.toFixed(1)}
      footer={photoView ? "" :
        `${differencePerWeek.toFixed(1)}/w
        ${!!withPercentageDifference
          ? ` (${Number(values[1]) === 0 ? '100' : (100 * (differencePerWeek / Number(values[1]))).toFixed(1)}%)`
          : ''}`
      }
      arrow={getArrowDirection()}
    />
  )
}

export default DifferenceMiniCard;
