import React from 'react';
import { Card, Statistic, Space} from 'antd';
import "./styles/macro_card.less"
import { IMacro } from 'Interfaces/User';

interface Props {
  macro: IMacro,
  title: React.ReactNode
}

export default function MacroCard(props: Props){

  const formatData = (data: string | number) =>{
    if(!data || isNaN(data as number)){
      return "-"
    }

    return parseFloat(data as string).toFixed(0)
  }

  const formatBool = (data: boolean) =>{
    if(data === undefined){
      return "-"
    }
    
    if(data){
      return "Y"
    }else{
      return "N"
    }
  }

  return (
    <Card title={props.title}>
      <Space>
        <Statistic title="Protein" value={formatData(props?.macro?.protein)} />
        <Statistic title="Carb" value={formatData(props?.macro?.carb)} />
        <Statistic title="Fat" value={formatData(props?.macro?.fat)} />
        <Statistic title="Days" value={formatData(props?.macro?.days)} />
        <Statistic title="Enabled" value={formatBool(props?.macro?.inUse)}/>
      </Space>
    </Card>
  )
}