export const complianceFilterOptions = [
    {text: 'OK', value: "Ok"},
    {text: 'Food', value: "Food"},
    {text: 'Training', value: "Training"},
    {text: 'Stress', value: "Stress"},
    {text: 'Water', value: "Water"},
    {text: 'Sleep', value: "Sleep"}
]

export const programFilterOptions = [
    {text: 'Diamond', value: 'Diamond'},
    {text: 'Elite', value: 'Elite'},
    {text: 'Competing', value: 'Competing'}
]

export const yesNoFilterOptions = [{text: 'Yes', value: true}, {text: 'No', value: false}]
export const lateFilterOptions = [{text: 'Late', value: true}, {text: 'OK', value: false}]