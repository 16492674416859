import React, {useState} from "react";
import { AxiosRequestConfig } from "axios";
import {Upload, Progress, Button, message, Row, Col, UploadProps} from 'antd';
import type { RcFile } from "antd/lib/upload";
import {DeleteOutlined, UploadOutlined} from '@ant-design/icons';
import {MAX_FILE_SIZE} from "vars";
import withConfirmModal from "HOCs/withConfirmModal";
import ButtonComponent from "components/ButtonComponent";

interface Props {
    src: string,
    fullName: string,
    onChange: (value: string | null, config: AxiosRequestConfig | undefined, message: string) => Promise<void>
};

export default function CoachInfoUploadImg({onChange, src, fullName}: Props) {
    // const fullName = useSelector(state => state.coaches.selectedCoach.fullName)
    const [progress, setProgress] = useState(0);

    function getBase64(img: Blob, callback: (data: string | ArrayBuffer | null) => void) {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    }

    const uploadImage: UploadProps['customRequest'] = async (options) => {
        const {file} = options;

        const config: AxiosRequestConfig = {
            onUploadProgress: (event: ProgressEvent) => {
                const percent = Math.floor((event.loaded / event.total) * 100);
                setProgress(percent);
                if (percent === 100) {
                    setTimeout(() => setProgress(0), 1000);
                }
            }
        };

        if (file instanceof Blob){
            if (!file.type.startsWith('image/')) {
                return message.error(`${'name' in file ? file.name : 'file'} is not a image file`);
            }
    
            if (file.size > MAX_FILE_SIZE) {
                return message.error(`The ${'name' in file ? file.name : 'file'} file is too large. Please enter a file with a valid file size no larger than ${MAX_FILE_SIZE / 1e+6} MB`);
            }
        }
        
        try {
            getBase64(file as Blob, (data: string | ArrayBuffer | null) => onChange(data as string | null, config, 'Saved'))
            // onSuccess("Ok");
        } catch (err) {
            // onError({err});
        }
    };

    const ButtonWithConfirm = (withConfirmModal(ButtonComponent))

    const DeleteButtonProps = {
        block: true,
        style:{width: 150},
        icon: <DeleteOutlined/>,
        confirm: () => onChange(null,{},'Deleted'),
        confirmTitle: 'Delete Image',
        confirmQuestion: `Are you sure you want to remove photo for ${fullName}?`,
        text: 'Delete Image'
    }

    return (
        <>
            {progress > 0 ? <Progress percent={progress}/> : null}
            <Row gutter={[20, 20]} justify={'center'} className={'coach_main_body_column_info_upload'}>
                <Col sm={{span: 24}} md={{span: 12}} lg={{span: 24}} xxl={{span: 24}}>
                    <Upload className={'coach_main_body_column_info_upload_button'} showUploadList={false}
                            customRequest={uploadImage}>
                        <Button style={{width: 150}} icon={<UploadOutlined/>}>Click to Upload</Button>
                    </Upload>
                </Col>
                {!!src ? <Col sm={{span: 24}} md={{span: 12}} lg={{span: 24}} xxl={{span: 24}}
                              className={'coach_main_body_column_info_upload_button'}>
                    <ButtonWithConfirm {...DeleteButtonProps}/>
                </Col> : null}
            </Row>
        </>
    )
}