import React, {useState, useEffect} from 'react';
import {Button, Card, Checkbox, Col, Image, Row, Skeleton, Space} from 'antd';
import {ReloadOutlined} from '@ant-design/icons';
import {ERROR_IMAGE} from "../../vars";
import moment from "moment";
import { useAppSelector } from 'redux/store';
import { Canceler } from 'axios';
import { IPhoto } from 'services/photo-service';

export interface IPhotoProps{
    isMacOS: boolean,
    photosApiCancel: Canceler,
    photoData: IPhoto,
    getPhoto: (id: string) => Promise<string | undefined>,
    setPhotos: (photos: IPhoto[]) => void,
    setImgActivity: (flag: boolean) => void
}

export default function Photo({photoData, isMacOS, setImgActivity, setPhotos, getPhoto, photosApiCancel}: IPhotoProps) {
    const { src, id } = photoData;
    const groupPreview = useAppSelector(state => state.photos.groupPreview)

    const [loading, setLoading] = useState(true)
    const [data, setData] = useState<string | null>(null)
    const [reload, setReload] = useState(false)

    const generateSrc = async (src?: string | null) => {
        if (src) {
            const data = await getPhoto(src)
            if (data) return data;
        }
        setReload(true)
        return '';
    }

    const onChangeCard = (checked: boolean, img: IPhoto) => {
        if (!loading && !reload) {
            if (groupPreview.length < 3 || checked) {
                setPhotos(!checked ? [...groupPreview, img] : [...groupPreview.filter((elem) => elem.id !== img.id)])
            }
        }
    }

    const onClickCard = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, checked: boolean, img: IPhoto) => {
        if (isMacOS ? e.metaKey : e.ctrlKey) {
            onChangeCard(checked, img)
        }
    }

    const renderTitle = (data: IPhoto, src: string) => (
        <Space direction="vertical" className={'photo_info'}>
            {!loading && !reload ?
                <Checkbox
                    checked={groupPreview.some(elem => elem.id === id)}
                    onChange={() => onChangeCard(groupPreview.some(elem => elem.id === id), {
                        ...photoData,
                        src
                    })}
                    className="photo_checkbox_wrapper"
                />
                : null}
            <span>{moment(data.date).fromNow()}</span>
        </Space>
    )


    useEffect(() => {

        async function loadImage() {
            if (!reload) {
                setLoading(true)
                let data = await generateSrc(src)
                setData(data)
            }
        }

        loadImage()
    }, [reload]);

    useEffect(()=>() => photosApiCancel(), [])
    return (
        <Card
            className={`photos_tab_element_center ${groupPreview.some(elem => elem.id === id) ? 'photo_card_selected' : ''}`}
            headStyle={{width: '100%'}}
            title={renderTitle(photoData, data!)}
            onClick={(e) => onClickCard(e, groupPreview.some(elem => elem.id === id), {
                ...photoData,
                src: data!
            })}
        >
            <Row>
                <Col span={24}>
                    {
                        loading &&
                        <Skeleton className={'photos_tab_element_center'} loading={loading} active
                                  avatar={true} paragraph={false} title={false} />
                    }
                    <Image
                        preview={{
                            onVisibleChange: (visible) => setImgActivity(visible)
                        }}
                        style={{
                            display: loading ? 'none' : 'block'
                        }}
                        width={'50%'}
                        src={data!}
                        onLoad={() => {
                            setLoading(false)
                        }}
                        onError={() => {
                            setLoading(false)
                            setReload(true)
                        }}
                        fallback={ERROR_IMAGE}
                    />
                </Col>
                {reload && !loading ? <Col span={24}><Button icon={<ReloadOutlined/>}
                                                             onClick={() => setReload(false)}>Reload</Button></Col> : null}
            </Row>
        </Card>
    )
}