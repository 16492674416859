export const TOGGLE_SLIDER_MENU = 'TOGGLE_SLIDER_MENU'
export const CLOSE_SLIDER_MENU = 'CLOSE_SLIDER_MENU'
export const CURRENT_PAGE = 'CURRENT_PAGE'
export const OPEN_SLIDER_MENU = 'OPEN_SLIDER_MENU';

export const openSliderMenuAction = () => ({type: OPEN_SLIDER_MENU});
export const toggleSliderMenuAction = () => ({type: TOGGLE_SLIDER_MENU})
export const closeSliderMenuAction = () => ({type: CLOSE_SLIDER_MENU})
export const setCurrentPage = (pathname: string) => ({type: CURRENT_PAGE, payload:{pathname}})

