import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import { ICoach, IManager } from "Interfaces/User";
import React from "react";
import { DateRange, SelectedTable } from "Types";
import { COACH, THIS_WEEK } from "vars";

interface InitialState {
    managersData: IManager[],
    selectedManagers: React.Key[],
    filteredManagers: IManager[],
    filteredCoaches: ICoach[],
    coachesData: ICoach[],
    dateRange: DateRange,
    coachesTotalCount: number,
    currentManagerInfoCards: ICurrentManagerInfoCards | null,
    currentManager: IManager | null,
    selectedTable: SelectedTable,
    activeCoaches: number,
    lastUpdate: number | null,
    totalCount: number,
};

export interface ICurrentManagerInfoCards {
    averageCompliance?: string | number;
    coachActiveClients?: number,
    coachAverageFeedbackTime?: number,
    coachCheckIns?: number,
    coachFeedbackSent?: number,
    managerActiveCoaches?: number
};

const initialState = {
    managersData: [],
    selectedManagers: [],
    filteredManagers: [],
    filteredCoaches: [],
    dateRange: THIS_WEEK,
    currentManagerInfoCards: null,
    coachesData: [],
    coachesTotalCount: 0,
    currentManager: null,
    selectedTable: COACH,
    activeCoaches: 0,
    lastUpdate: null,
    totalCount: 0,
} as InitialState;


const managersReducer = createSlice({
    name: 'managersReducer',
    initialState,
    reducers: {
        setManagers: (state, action: PayloadAction<IManager[]>) => {
            state.managersData = action.payload;
        },
        setSelectedManagers: (state, action: PayloadAction<React.Key[]>) => {
            state.selectedManagers = action.payload;
        },
        setSelectedTable: (state, action: PayloadAction<SelectedTable>) => {
            state.selectedTable = action.payload;
        },
        setManagerInfoCards: (state, action: PayloadAction<ICurrentManagerInfoCards>) => {
            state.currentManagerInfoCards = {
                ...state.currentManagerInfoCards,
                ...action.payload
            };
        },
        setCoachesTable: (state, action: PayloadAction<{users: ICoach[], totalCount: number}>) => {
            state.coachesData = action.payload.users;
            state.coachesTotalCount = action.payload.totalCount;
        },
        setDateRange: (state, action: PayloadAction<DateRange>) => {
            state.dateRange = action.payload;
        },
        setFilteredManagers: (state, action: PayloadAction<IManager[]>) => {
            state.filteredManagers = action.payload;
        },
        setFilteredCoaches: (state, action: PayloadAction<ICoach[]>) => {
            state.filteredCoaches = action.payload
        },
        setManager: (state, action: PayloadAction<IManager | null>) => {
            state.currentManager = action.payload;
        },
        getUpdate: (state, action: PayloadAction<number>) => {
            state.lastUpdate = action.payload;
        },
        resetManagersStore: (state) => {
            state = initialState
        }
    }
});


export const {
    setManagers, 
    setCoachesTable, 
    setSelectedManagers, 
    setSelectedTable, 
    setManagerInfoCards, 
    setManager, 
    resetManagersStore, 
    getUpdate, 
    setFilteredManagers, 
    setDateRange,
    setFilteredCoaches
} = managersReducer.actions;


export default managersReducer.reducer;



