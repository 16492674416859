import {combineReducers, PayloadAction} from '@reduxjs/toolkit'
import {commonReducer} from './commonReducer'
import authReducer from './authReducer'
import activityReducer from "./activityReducer";
import candidatesReducer from './candidatesReducer'
import usersReducer from './usersReducer'
import dashboardReducer from './dashboardReducer'
import coachesReducer from "./coachesReducer";
import checkInsReducer from "./checkInsReducer";
import notesReducer from "./notesReducer";
import messageTemplatesReducer from "./messageTemplatesReducer";
import userReducer from "./userReducer";
import modalsReducer from "./modalsReducer";
import questionnaireReducer from "./questionnaireReducer";
import photosReducer from "./photosReducer";
import feedbackReducer from "./feedbackReducer";
import managersReducer from "./managersReducer";
import smsReducer from "./smsReducer";
import classificationsReducer from "./classificationsReducer";
import clientTypesReducer from "./clientTypesReducer";
import chatReducer from "./chatReducer";
import tasksReducer from './tasksReducer';



const appReducer = combineReducers({
    auth: authReducer,
    common: commonReducer,
    activity: activityReducer,
    candidates: candidatesReducer,
    users: usersReducer,
    managers: managersReducer,
    dashboard: dashboardReducer,
    coaches: coachesReducer,
    checkIns: checkInsReducer,
    user: userReducer,
    userNotes: notesReducer,
    messageTemplates: messageTemplatesReducer,
    modals: modalsReducer,
    questionnaire: questionnaireReducer,
    photos: photosReducer,
    feedback: feedbackReducer,
    sms: smsReducer,
    classifications: classificationsReducer,
    clientTypes: clientTypesReducer,
    chat: chatReducer,
    tasks: tasksReducer
})

const rootReducer = (state: ReturnType<typeof appReducer> | undefined, action: PayloadAction<{type: string}>) => {
    if (action.type === 'Logout') {
        return appReducer(undefined, action)
    }

    return appReducer(state, action)
}

export default rootReducer