import React, {useEffect} from 'react';
import { useAppSelector } from 'redux/store';
import {Row, TablePaginationConfig} from "antd";
import DashboardPageComplianceOverallChartsPie from "./DashboardPageComplianceOverallChartsPie";
import DashboardPageCompletedChartsPie from "./DashboardPageCompletedChartsPie";
import DashboardPageComplianceDailyChartsBar from "./DashboardPageComplianceDailyChartsBar";
import DashboardPageCompletedChartsBar from "./DashboardPageCompletedChartsBar";
import DashboardPageCards from "./DashboardPageCards";
import DashboardPageCoachClientsInfoTable from "./DashboardPageCoachClientsInfoTable";
import LoadingSpin from "components/loading-spin/LoadingSpin";
import withPermission from "HOCs/withPermission";
import {admin, coach, manager} from 'vars'
import withDashboardCol from "HOCs/withDashboardCol";
import DashboardPageUsersTableWrapper from "./DashboardPageUsersTableWrapper";
import DashboardPageCheckInsPie from "./DashboardPageCheckInsPie";
import DashboardPagePerformanceCoach from "./DashboardPagePerformanceCoach";
import DashboardPageMiaBar from "./DashboardPageMiaBar";
import DashboardPageCardsWithTwoCols from "./DashboardPageCardsWithTwoCols";
import DashboardPagePerformanceCoachAverage from "./DashboardPagePerformanceCoachAverage";
import DashboardPageStatusBar from "./DashboardPageStatusBar";
import DashboardPageClientsChangeStatus from "./DashboardPageClientsChangeStatus";
import { F_extra, UserRoles } from 'Types';
import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import { IClient, IManager } from 'Interfaces/User';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';
import { IUsersTableSettings } from 'redux/reducers/usersReducer';
import DashboardTasks from './DashboardTasks';
import { ITaskParams } from 'services/tasksCategories-service';


export interface IDashboardProps {
    isLoading: boolean,
    setLoading: React.Dispatch<boolean>,
    getTotal: () => Promise<void>,
    getFeedbackOutstanding: () => Promise<void>,
    getCheckInOverdue: () => Promise<void>,
    getCompletedDaily: () => Promise<void>,
    getComplianceDaily: () => Promise<void>,
    getStatsCheckins: () => Promise<void>,
    getAssignedCoaches: () => Promise<void>,
    getManagersData: (callback?: ActionCreatorWithPayload<IManager[], "managersReducer/setManagers">) => Promise<void>,
    getTotalClients: () => Promise<void>,
    getAverageCompanyStats: () => Promise<void>,
    getAverageCompanyClients: () => Promise<void>,
    getAverageCompanyFeedbackOutStanding: () => Promise<void>,
    getAverageCompanyCheckinCompleted: () => Promise<void>,
    getAverageCompanyCheckinOverdue: () => Promise<void>,
    getAverageCompanyFeedbackTime: () => Promise<void>,
    getAverageCompanyFeedbackGiven: () => Promise<void>,
    getAverageFeedbackTime: () => Promise<void>,
    getCoachesOverview: () => Promise<void>,
    getAverageCompanyMiaTotal: () => Promise<void>,
    getCoachClientsData: () => Promise<void>,
    getChartCompletedData: () => Promise<void>,
    getAverageCompanyComplianceAverage: () => Promise<void>,
    getChartClientsData: () => Promise<void>,
    getActiveClients: () => Promise<void>,
    getFeedbackGiven: () => Promise<void>,
    getLastContactedMia: () => Promise<void>,
    getMiaAverageTotal: () => Promise<void>,
    getClientsStatusWeeks: () => Promise<void>,
    getClientsProgress: () => Promise<void>,
    handleUsersTableChange: (pagination: TablePaginationConfig, filters: Record<string, FilterValue | null>, sorter: SorterResult<IClient> | SorterResult<IClient>[]) => void,
    setExtraUserFilter: (value: F_extra) => void,
    setInitialUsers: React.Dispatch<IUsersTableSettings>,
    initialUsers: IUsersTableSettings,
    getUsersCoach: () => Promise<void>,
    onSelectUsersChange: (selectedUsers: React.Key[]) => void,
    setCategoriesData: () => Promise<void>,
    getUserTasksData?: () => Promise<void> | null;
}


function DashboardPageBody({isLoading, setLoading, ...props}: IDashboardProps) {

    const {
        getUserTasksData,
        setCategoriesData,
        getTotal,
        getFeedbackOutstanding,
        getCheckInOverdue,
        getCompletedDaily,
        getComplianceDaily,
        getStatsCheckins,
        getAssignedCoaches,
        getManagersData,
        getTotalClients,
        getAverageCompanyStats,
        getAverageCompanyClients,
        getAverageCompanyFeedbackOutStanding,
        getAverageCompanyCheckinCompleted,
        getAverageCompanyCheckinOverdue,
        getAverageCompanyFeedbackTime,
        getAverageCompanyFeedbackGiven,
        getAverageFeedbackTime,
        getCoachesOverview,
        getAverageCompanyMiaTotal,
        getCoachClientsData,
        getChartCompletedData,
        getAverageCompanyComplianceAverage,
        getChartClientsData,
        getActiveClients,
        getFeedbackGiven,
        getLastContactedMia,
        getMiaAverageTotal,
        getClientsStatusWeeks,
        getClientsProgress,
    } = props

    
    const dateRangeName = useAppSelector(state => state.dashboard.dateRangeName)
    const selectedCoachId = useAppSelector(state => state.dashboard.selectedCoachId)
    const user = useAppSelector(state => state.auth.user!)

    const adminFetchData = [
        getUserTasksData,
        setCategoriesData,
        getChartClientsData,
        getTotal,
        getActiveClients,
        getStatsCheckins,
        getCoachClientsData,
        getCoachesOverview,
        getTotalClients,
        getChartCompletedData,
        getCheckInOverdue,
        getComplianceDaily,
        getCompletedDaily,
        getLastContactedMia,
        getMiaAverageTotal
    ]

    const coachFetchData = [
        getUserTasksData,
        setCategoriesData,
        getTotal,
        getChartCompletedData,
        getMiaAverageTotal,
        getChartClientsData,
        getCoachClientsData,
        getActiveClients,
        getStatsCheckins,
        getCheckInOverdue,
        getTotalClients,
        getFeedbackOutstanding,
    ]

    const managerFetchData = [
        getUserTasksData,
        setCategoriesData,
        getTotal,
        getChartCompletedData,
        getCoachClientsData,
        getStatsCheckins,
        getActiveClients,
        getMiaAverageTotal,
        getTotalClients,
        getCoachesOverview,
        getChartClientsData,
        getCheckInOverdue,
        getFeedbackOutstanding,
    ]

    const averageCompanyFetchData =[
        getManagersData,
        getAverageCompanyStats,
        getAverageCompanyFeedbackGiven,
        getAverageCompanyMiaTotal,
        getAverageCompanyClients,
        getAverageCompanyFeedbackTime,
        getAverageCompanyFeedbackOutStanding,
        getAverageCompanyCheckinCompleted,
        getAverageCompanyComplianceAverage,
        getAverageCompanyCheckinOverdue,
        getAssignedCoaches,
        getFeedbackGiven,
        getAverageFeedbackTime,
        getClientsProgress,
        getClientsStatusWeeks
    ];

    const fetchData = (roles: UserRoles)=>{
        switch (roles) {
            case admin:{
                return [...adminFetchData, ...averageCompanyFetchData]
            }
            case manager:{
                return [...managerFetchData, ...averageCompanyFetchData]
            }
            default:{
                return [...coachFetchData, ...averageCompanyFetchData]
            }
        }
    }

    useEffect(() => {
        let cleanupFunction = false;
        setLoading(true)
        Promise.all(fetchData(user.roles).map(fun => fun?.())).then(() => {
            if (!cleanupFunction) {
                setLoading(false)
            }
        })
        return () => {cleanupFunction = true}

    }, [dateRangeName, selectedCoachId])


    const DashboardPageStatusWeeksWithPermission = (withDashboardCol(DashboardPageStatusBar))
    const DashboardPageClientsChangeBarWithPermission = (withDashboardCol(DashboardPageClientsChangeStatus));
    const DashboardPageCoachClientsInfoTableWithCol = withPermission(withDashboardCol(DashboardPageCoachClientsInfoTable))
    const DashboardPageCardsWithCol = selectedCoachId || user.roles === coach || user.roles === manager ? DashboardPageCardsWithTwoCols : withDashboardCol(DashboardPageCards);
    const DashboardPageComplianceOverallChartsPieWithCol = withDashboardCol(DashboardPageComplianceOverallChartsPie)
    const DashboardPageCompletedChartsPieWithCol = withPermission(withDashboardCol(DashboardPageCompletedChartsPie))
    const DashboardPageComplianceDailyChartsBarWithCol = withPermission(withDashboardCol(DashboardPageComplianceDailyChartsBar))
    const DashboardPageCompletedChartsBarWithCol = withPermission(withDashboardCol(DashboardPageCompletedChartsBar))
    const DashboardPageMiaBarWithCol = withPermission(withDashboardCol(DashboardPageMiaBar))
    // const DashboardPageUsersTableWrapperWithPermission = withPermission(DashboardPageUsersTableWrapper)
    const DashboardPageOverviewCheckins = withPermission(withDashboardCol(DashboardPageCheckInsPie));
    const DashboardPagePerformanceCoachWithPermission = withPermission(withDashboardCol(DashboardPagePerformanceCoach));
    // const DashboardPageAverageComplianceOverallWithPermission = withPermission(withDashboardCol(DashboardPageAverageComplianceOverall));
    const DashboardPageCoachPerformanceAverageWithPermission = withPermission(withDashboardCol(DashboardPagePerformanceCoachAverage));
    const DashboardTasksWithCol = withDashboardCol(DashboardTasks);

    if(user.roles === coach){
        return (
            !isLoading ?
                <Row gutter={[20, 20]} className={'dashboard_body_wrapper'}>
                    <DashboardPageStatusWeeksWithPermission />
                    <DashboardPageClientsChangeBarWithPermission  />
                    <DashboardPageOverviewCheckins roles={[coach]} />
                    <DashboardPageCoachClientsInfoTableWithCol roles={[coach]}/>
                    <DashboardPagePerformanceCoachWithPermission roles={[coach]} />
                    <DashboardPageCoachPerformanceAverageWithPermission roles={[coach]} />
                    <DashboardPageCardsWithCol/>
                    <DashboardPageComplianceOverallChartsPieWithCol/>
                    <DashboardTasksWithCol />
                    {/*<DashboardPageAverageComplianceOverallWithPermission roles={[coach]} />*/}
                    <DashboardPageUsersTableWrapper {...props} f_coachId={[user.id]} />
                </Row>
                :
                <LoadingSpin />
        )
    }

    return (
        !isLoading ?
            <Row gutter={[20, 20]} className={'dashboard_body_wrapper'}>
                <DashboardPageStatusWeeksWithPermission  />
                <DashboardPageClientsChangeBarWithPermission  />
                <DashboardPageOverviewCheckins roles={[admin, manager]} />
                <DashboardPageCoachClientsInfoTableWithCol roles={[admin, manager]}/>
                <DashboardPagePerformanceCoachWithPermission roles={[admin, manager]} />
                <DashboardPageCoachPerformanceAverageWithPermission roles={[admin, manager]} />
                <DashboardPageCardsWithCol/>
                <DashboardPageCompletedChartsPieWithCol roles={[admin]}/>
                <DashboardPageComplianceOverallChartsPieWithCol/>
                <DashboardPageComplianceDailyChartsBarWithCol roles={[admin]}/>
                <DashboardPageMiaBarWithCol roles={[admin]}/>
                <DashboardPageCompletedChartsBarWithCol roles={[admin]}/>
                <DashboardTasksWithCol />
                {user.roles !== admin && <DashboardPageUsersTableWrapper {...props} f_coachId={[user.id]} />}
            </Row>
            :
            <LoadingSpin/>
    )
}

export default DashboardPageBody