import React, {useState, useEffect} from 'react';
import {Table, Tag, Space, Button, Form, Input, Menu, PageHeader, MenuProps} from 'antd';
import {
    EditOutlined,
    DeleteOutlined,
    AuditOutlined,
    DownOutlined, 
    SafetyOutlined
} from '@ant-design/icons';
import axios from 'axios';
import { useHistory} from 'react-router-dom'
import withPermission from "HOCs/withPermission";
import withSidebarNavigation from "HOCs/withSidebarNavigation";
import NotFoundPage from "../not-found/NotFoundPage";
import {
    getPopoverTextWithIcon,
    getUserFormData,
    openNotification,
} from "utils/helpers";
import antNotificationComponent from "utils/notification-ant-utils";
import useUserApiWithRedux from "services/user-service";
import { IAdmin, IUser } from 'Interfaces/User';
import { ColumnType } from 'antd/es/table';
import { SearchProps } from 'antd/lib/input';
import { UserRoles } from 'Types';
import { TableRowSelection } from 'antd/es/table/interface';
import AddAdminForm from './Form/AddAdminForm';
import EditAdminForm from './Form/EditAdminForm';
import { ColumnProps } from 'antd/lib/table';
import { Dropdown } from 'components/Antd/DropDown';
import { Modal } from 'components/Antd/Modal';
import { admin } from 'vars';
const {Search} = Input;


export interface IAdminForm {
    firstName: string,
    lastName: string,
    email: string,
    password?: string,
    confirmPassword?: string,
    showAsCoach: boolean,
    pvtEnabled: boolean,
    pvtSuperUser: boolean,
    disabled: boolean,
    apiKey: string,
    roles?: UserRoles,
    id?: string,
};



//NOTES: Based on a demo table, added a pop-up editor via modal and form.
//I used functions here rather than classes/extend React.Component as this causes issues getting a reference to the form in order to populate it.
//Looking to upgrade table to ProTable (has back-end linkages and search and stuff... but I was having troubles getting it to work)
//Data is populated locally, but shouldn't be much of a hassle to change to using a fetch to get the objects.

function AdminList() {
    const history = useHistory();
    const {updateUserData, onGenerateToken, onRemoveToken, onCreateUser} = useUserApiWithRedux()
    const [editing, setEditing] = useState(false)
    const [adding, setAdding] = useState(false)
    const [showDeleteUserModal, setShowDeleteUserModal] = useState(false)
    const [showDeleteUsersModal, setShowDeleteUsersModal] = useState(false)
    const [removeUserData, setRemoveUserData] = useState<IAdmin | null>(null)
    const [editItem, setEditItem] = useState<IAdminForm | null>(null)
    const [lastUpdated, setLastUpdated] = useState(Date.now())
    const [rawData, setRawData] = useState<IAdmin[] | null>(null)
    const [data, setData] = useState<IAdmin[]>([])
    const [loading, setLoading] = useState(true)
    const [selectedRowKeys, setSelectedRows] = useState<React.Key[]>([])
    const [editForm] = Form.useForm<IAdminForm>();
    const [addForm] = Form.useForm<IAdminForm>();
    const [isLoadingToken, setIsLoadingToken] = useState(false);
    const [isLoadingRemove, setIsLoadingRemove] = useState(false);

    const onDeleteToken = async () => {
        const id = editForm.getFieldValue("id");
        setIsLoadingRemove(true);
        try {
            await onRemoveToken(id)
            editForm.setFieldsValue({apiKey: ""})
            setIsLoadingRemove(false)
            setData(state => state.map((user) => user.id === id ? ({...user, apiKey: null}) : user))
        }
        catch (err) {
            console.error(err);
        }
    }

    const onGenerate = async () => {
        const id = editForm.getFieldValue("id");
        setIsLoadingToken(true)
        try {
            const response = await onGenerateToken(id);
            if(response){
                editForm.setFieldsValue(response)
                setIsLoadingToken(false)
                setData(state => state.map((user) => user.id === id ? ({...user, ...response}) : user))
            }
        }catch (e) {
            console.log(e);
        }
    }

    const onCopyToClipboardIcon = (apiKey: IUser['apiKey']) => {
        navigator.clipboard.writeText(apiKey as string).then(() => {
            openNotification("API token copied", "success", "topRight")
        })
    }

    const handleViewActivities=(actorId: IAdmin['id'])=>{
        history.push(`/activity?f_actorId[]=${[actorId]}`)
    }

    const handleMenuClick: MenuProps['onClick'] = ({key}) => {
        switch (key) {
            case "1":
                initMacros()
                break;
            case "2":
                deleteUsersModal()
                break;
        }
    }

    const menu = (
        <Menu onClick={handleMenuClick}>
            <Menu.Item key="1">
                Init macros
            </Menu.Item>
            <Menu.Item key="2">
                Remove users
            </Menu.Item>
        </Menu>
    );

    const boolRender = (value: boolean): JSX.Element => (
            <Tag color={(value === true) ? "volcano" : "green"}>{(value === true) ? "DISABLED" : "ACTIVE"}</Tag>
    )

    const actionsRender: ColumnType<IAdmin>['render'] = (text, record, index) => (
        <Space size="middle">
            <Button shape="circle" type="primary" icon={<EditOutlined/>}
                    onClick={() => editRecord(text, record, index)}/>
            <Button shape="circle" type="primary"
                    onClick={() => handleViewActivities(record.id)} icon={<AuditOutlined />} />
            <Button shape="circle" type="primary" danger icon={<DeleteOutlined/>}
                    onClick={() => showDeleteModal(record)}/>
            {record.apiKey && <Button shape="circle" onClick={() => onCopyToClipboardIcon(record.apiKey)} type="primary" icon={<SafetyOutlined />} />}
        </Space>
    )



    const newRecord = () => {
        setAdding(true)
    }

    const setInitialModalForm = () => addForm.setFieldsValue({
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        confirmPassword: "",
        showAsCoach: false,
        pvtEnabled: false,
        pvtSuperUser: false,
        disabled: false,
        apiKey: "",
        roles: "admin",
        id: '',
    })

    const editRecord = (_: any, record: IAdmin, index: number): void => {
        const {id, firstName, lastName, email, showAsCoach, pvtEnabled, pvtSuperUser, disabled, apiKey, roles } = record;
        setSelectedRows([])
        setEditing(true)
        console.log("Editing", editing)
        setEditItem({id, firstName, lastName, email, showAsCoach, pvtEnabled, pvtSuperUser, disabled, apiKey: apiKey as string, roles })
        // setEditIndex(index)
        editForm.setFieldsValue({id, firstName, lastName, email, showAsCoach, pvtEnabled, pvtSuperUser, disabled, apiKey: apiKey as string, roles })

    }

    const onDeleteUser = async () => {
        try {
            await axios.delete(`/api/user/${removeUserData?.id}`)
            openNotification("Deleted", 'success', 'topRight')
        } catch (e) {
            openNotification("Failed to delete", 'error', 'topRight')
        } finally {
            setRemoveUserData(null)
            setShowDeleteUserModal(false)
            setLastUpdated(Date.now())
        }
    }

    const onCancelDeleteUser = () => {
        setRemoveUserData(null)
        setShowDeleteUserModal(false)
    }

    const showDeleteModal = (user: IAdmin) => {
        setSelectedRows([])
        setRemoveUserData({...user})
        setShowDeleteUserModal(true)
    }

    const deleteUsersModal = () => {
        setShowDeleteUsersModal(true)
    }

    const onDeleteUsers = async () => {
        try {
            await axios.delete(`/api/user/`, {data: {users: [...selectedRowKeys]}})
            setSelectedRows([])
            setShowDeleteUsersModal(false)
            setLastUpdated(Date.now())
            openNotification("Deleted", 'success', 'topRight')
        } catch (e) {
            openNotification("Failed to delete", 'error', 'topRight');
            console.error(e);
        }
    }

    const onCancelDeleteUsers = () => setShowDeleteUsersModal(false)

    const getDeleteUsersFullName = (): IAdmin['fullName'][] => {
        if(rawData?.length && selectedRowKeys.length){
            return selectedRowKeys.map((userId) => {
                const selectedUser = rawData.find((user) => user.id === userId)
                return selectedUser!.fullName
            })
        }
        return []
    }

    const onEditAdmin = async () => {
        const formData: IAdminForm = {
            ...editItem,
            ...editForm.getFieldsValue()
        };
        const requestFormData = getUserFormData({formData})
        const id = requestFormData?.id;

        try {
            const response = await updateUserData<IAdminForm>({id, data: formData});
            if(response?.data){
                setLastUpdated(Date.now());
                openNotification("Saved", 'success', 'topRight')
            }
        } catch (error) {
            openNotification("Failed to save", 'error', 'topRight')
        }
        setEditing(false);
    }

    const doReset = () => setEditing(false);

    const loadData = async (): Promise<IAdmin[]> => {
        const resp = await axios("/api/user/admin/list")
        return resp.data
    }

    const search: SearchProps['onSearch'] = async (value) => {
        //String filter
        if (value !== "") {
            setData(rawData!.filter(it => (it.fullName.includes(value.trim()) || it.email.includes(value.trim()))))
        } else {
            setData(rawData as IAdmin[]);
        }
    }

    const doResetAdding = () => {
        setAdding(false)
        setInitialModalForm()
    }

    const onCreateAdmin = async () => {
        const formData = addForm.getFieldsValue();
        try {
            await onCreateUser<IAdminForm>({ ...formData, roles: admin });
            setAdding(false)
            setInitialModalForm()
            openNotification("Saved", 'success', 'topRight')
            setLastUpdated(Date.now())
        } catch (error) {
            openNotification("Failed to save", 'error', 'topRight')
        }
    };


    const extras = (
        <Space size="large" align="end">
            <Button type="primary" onClick={newRecord}>New</Button>
            <Search placeholder="Search" onSearch={search} enterButton/>
            <Dropdown trigger={['click']} disabled={selectedRowKeys.length == 0}
                      overlay={menu}><Button>Actions <DownOutlined/></Button></Dropdown>
        </Space>
    )

    const onSelectChange = (selected: React.Key[]) => {
        setSelectedRows(selected)
    }

    const rowSelection: TableRowSelection<IAdmin> = {
        selectedRowKeys,
        onChange: onSelectChange,
    }

    const initMacros = async (): Promise<void> => {
        const requests = data.reduce((arr: IAdmin[], user) => {
            if (selectedRowKeys.includes(user.id)) {
                return [...arr, {...user, createMacros: true}]
            }
            return arr
        }, [])
        const responses = await Promise.all(requests.map((data) => updateUserData({
            id: data.id,
            data
        }))).catch((e) => antNotificationComponent({type: 'error', message: "Failed to save"}))
        if (Array.isArray(responses) && responses?.every((response) => response?.statusText === 'OK')) {
            setSelectedRows([])
            setLastUpdated(Date.now())
            return antNotificationComponent({type: 'success', message: "Saved"})
        }
        return antNotificationComponent({type: 'error', message: "Failed to save"})
    }

    const disabledFilterOptions = [{text: 'DISABLED', value: true}, {text: 'ACTIVE', value: false}]

    const disabledFilters: ColumnType<IAdmin>['onFilter'] = (value, record) => value === record.disabled

    const columns: ColumnProps<IAdmin>[] = [
        {
            title: 'Full Name',
            render: (_, r) => getPopoverTextWithIcon(r.fullName),
            dataIndex: 'fullName',
            sorter: (a,b) => a.fullName.localeCompare(b.fullName),
        },
        {
            title: 'Email',
            dataIndex: 'email',
            sorter: (a,b) => a.email.localeCompare(b.email), 
        },
        {
            title: 'Start Date',
            dataIndex: 'startDate'
        },
        {
            title: 'Competing',
            dataIndex: 'competing',
            className: 'table-column-title-no-break'
        },
        {
            title: 'Status',
            dataIndex: 'disabled',
            filters: disabledFilterOptions,
            onFilter: disabledFilters,
            render: boolRender,
        },
        {
            title: 'Action',
            width: 225,
            render: actionsRender,
            fixed: 'right'
        }
    ];


    useEffect(() => {
        let cleanupFunction = false;

        const fetchData = async () => {
            try {
                const adminsData = await loadData()
                if (!cleanupFunction) {
                    setLoading(true)
                    setData(adminsData);
                    setRawData(adminsData);
                    setLoading(false)
                }
            } catch (e) {
                if(e instanceof Error){
                    console.error(e.message);
                }
            }
        }

        fetchData();

        return () => {cleanupFunction = true};
    }, [lastUpdated]);
    

    return (
        <Space className={'page_wrapper_style'} direction="vertical">
            <PageHeader className="site-layout-background" style={{padding: 0}} title="Admins"
                        onBack={() => window.history.back()} extra={extras}/>
            <Table
            scroll={{x: 'auto', y: '62vh', scrollToFirstRowOnChange: true}}
            dataSource={data}
            pagination={{position: ['bottomRight']}}
            loading={loading}
            rowSelection={rowSelection}
            columns={columns}
            rowKey={(u) => u.id}
            />
            <AddAdminForm
                form={addForm}
                visible={adding}
                onFinish={onCreateAdmin}
                modalProps={{
                    onCancel: doResetAdding
                }}
                // onVisibleChange={toggleModal}
                onReset={doResetAdding}
            />
            <EditAdminForm
                form={editForm}
                visible={editing}
                onFinish={onEditAdmin}
                onReset={doReset}
                modalProps={{
                    onCancel: doReset
                }}
                // onVisibleChange={toggleModal}
                onDelete={onDeleteToken}
                onGenerate={onGenerate}
                isLoadingRemove={isLoadingRemove}
                isLoadingToken={isLoadingToken}
            />
            <Modal
                centered
                title="Delete User"
                visible={showDeleteUserModal}
                onOk={onDeleteUser}
                onCancel={onCancelDeleteUser}
            >
                <span>{`Are you sure you want to delete ${removeUserData?.roles} ${removeUserData?.fullName}?`}</span>
            </Modal>
            <Modal
                centered
                title="Delete Users"
                visible={showDeleteUsersModal}
                onOk={onDeleteUsers}
                onCancel={onCancelDeleteUsers}
            >
                <span>Are you sure you want to delete following users?</span>
                <p>{[...getDeleteUsersFullName()].join(', ')}</p>
            </Modal>
        </Space>
    )
}

export default withPermission(withSidebarNavigation(AdminList, '/admins'), NotFoundPage)