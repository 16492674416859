import React from 'react';
import { useAppSelector } from 'redux/store';
import {checkDashboardData, getSubstringText} from "utils/helpers";
import DashboardPageChartsBar from "./DashboardPageChartsBar";
import NoData from "components/no-data/NoData";
import { ChartConfiguration } from 'chart.js';


const DashboardPagePerformanceCoachAverage = () => {
    const coachClientsData = useAppSelector(state => state.dashboard.coachClientsData)
    const filteredClientsData = coachClientsData.filter(({fullName}) => fullName);
    const labels = filteredClientsData.map(({fullName}) => getSubstringText(fullName,30));

    const performanceBarData: ChartConfiguration<'bar'>['data'] = {
        labels,
        datasets: [
            {
                label: 'Checked In',
                data: filteredClientsData.map((coach) => {
                    const {activeCheckinedNotLate, activeCheckinedLate, inactiveMissedOneWeek, inactiveMissedMoreThanTwoWeeks } = coach;
                    const sum = activeCheckinedNotLate + activeCheckinedLate + inactiveMissedOneWeek + inactiveMissedMoreThanTwoWeeks;
                    return Math.round(100 / sum * activeCheckinedNotLate)
                }),
                backgroundColor: "#a3f06c",
                stack: 'Stack 0'
            },
            {
                label: 'Late',
                data: filteredClientsData.map((coach) => {
                    const {activeCheckinedNotLate, activeCheckinedLate, inactiveMissedOneWeek, inactiveMissedMoreThanTwoWeeks } = coach;
                    const sum = activeCheckinedNotLate + activeCheckinedLate + inactiveMissedOneWeek + inactiveMissedMoreThanTwoWeeks;
                    return Math.round(100 / sum * activeCheckinedLate)
                }),
                backgroundColor: "#b6b5b5",
                stack: 'Stack 0'
            },
            {
                label: 'Missed 1',
                data: filteredClientsData.map((coach) => {
                    const {activeCheckinedNotLate, activeCheckinedLate, inactiveMissedOneWeek, inactiveMissedMoreThanTwoWeeks } = coach;
                    const sum = activeCheckinedNotLate + activeCheckinedLate + inactiveMissedOneWeek + inactiveMissedMoreThanTwoWeeks;
                    return Math.round(100 / sum * inactiveMissedOneWeek)
                }),
                backgroundColor: "#e66060",
                stack: 'Stack 0'
            },
            {
                label: 'Missed 2+',
                data: filteredClientsData.map((coach) => {
                    const {activeCheckinedNotLate, activeCheckinedLate, inactiveMissedOneWeek, inactiveMissedMoreThanTwoWeeks } = coach;
                    const sum = activeCheckinedNotLate + activeCheckinedLate + inactiveMissedOneWeek + inactiveMissedMoreThanTwoWeeks;
                    return Math.round(100 / sum * inactiveMissedMoreThanTwoWeeks)
                }),
                backgroundColor: "#cc2727",
                stack: 'Stack 0'
            }
        ]
    }


    const options: ChartConfiguration<'bar'>['options'] = {
            plugins: {
                tooltip: {
                    callbacks: {
                        label: function (completedData) {
                            const {dataset: {label, data}, dataIndex} = completedData
                            return `${label}: (${data[dataIndex]}%)`
                        }
                    }
                },
            title: {
                display: true,
                position: 'bottom',
            },
        },
        scales: {
            yAxes:
                {
                    ticks: {
                        stepSize: 20,
                        callback: function (value) {
                            return `${value}%`;
                        }
                    },
                    max: 100,
                },
        }
    };


    const performanceBarProps: ChartConfiguration<'bar'> = {
        type: 'bar',
        data: performanceBarData,
        options,
    }

    return checkDashboardData(performanceBarData.datasets) ? <DashboardPageChartsBar
        title="Average Coach client breakdown"
        {...performanceBarProps}
    /> : <NoData description="Average Coach client breakdown (No Data)" />
};



export default DashboardPagePerformanceCoachAverage