import React, {useState, ComponentType} from "react";
import { Modal } from "components/Antd/Modal";

type DefaultType = typeof React.Component | React.ElementType | undefined;

interface Props {
    confirmTitle: string,
    confirm: () => void,
    confirmQuestion: string,

}

export default function withConfirmModal<P extends {}>(Component: ComponentType<P>): ComponentType<P & Props> {
    return function ComponentConfirmModal(props){
        const {confirm, confirmQuestion, confirmTitle} = props;
        const [visible, setVisible]= useState(false)

        const onCancel = () => setVisible(false)
        const onOk = () => {
            confirm()
            onCancel()
        }

        return (
            <>
            <Component {...props} onClick={()=>setVisible(true)} />
            <Modal centered key={confirmTitle} visible={visible} title={confirmTitle} onOk={onOk} onCancel={onCancel}>
                {confirmQuestion}
            </Modal>
            </>
        )
    }
}