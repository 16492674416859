import React from 'react';
import { useAppDispatch, useAppSelector } from 'redux/store';
import {useHistory} from "react-router-dom";
import {Layout, Menu, MenuItemProps} from 'antd';
import {
    PieChartOutlined,
    AuditOutlined,
    UserOutlined,
    MessageOutlined,
    BarChartOutlined,
    WechatOutlined, MenuOutlined,
} from '@ant-design/icons';
import withPermission from "HOCs/withPermission";
import {admin, coach, manager} from "vars";
import withLayoutAuth from "HOCs/withLayout";
import {openSliderMenuAction} from "redux/actions/commonActions";
import useResize from "hooks/useResize";
import {MenuClickEventHandler} from "rc-menu/lib/interface"
import { SiderProps } from 'antd/lib/layout';


const {Sider} = Layout;

function Sidebar() {

    let history = useHistory()
    const currentPage = useAppSelector(state => state.common.currentPage)
    const isOpen = useAppSelector((state) => state.common.isSliderMenuOpen);

    const dispatch = useAppDispatch();
    const {width} = useResize();

    const toggleMobileSidebar = () => {
        dispatch(openSliderMenuAction());
    }

    const getPathKey = (pathname: string) => {
        switch (pathname) {
            case "/":
                return '1'
            case "/checkins":
                return '2'
            case "/messageTemplates":
                return '3'
            case "/users":
            case "/client":
                return '4'
            case "/coaches":
            case "/coach":
                return '5'
            case "/admins":
                return '6'
            case "/activity":
                return '7'
            case "/coach-dashboard":
                return '8'
            case "/managers":
            case "/manager":    
                return '9'
            case "/classifications":
                return '10';
            case "/client-types":
                return '11';
            case "/messages":
                return '12';
            case "/categories":
                return '13';
            default:
                return ''        
        }
    }

    const handleMenuClick: MenuClickEventHandler = (key) => {
        switch (key.key) {
            case "1":
                history.push("/")
                break;
            case "2":
                history.push("/checkins")
                break;
            case "3":
                history.push("/messageTemplates")
                break;
            case "4":
                history.push("/users")
                break;
            case "5":
                history.push("/coaches")
                break;
            case "6":
                history.push("/admins")
                break;
            case "7":
                history.push("/activity")
                break;
            case "8":
                history.push("/coach-dashboard")
                break;
            case "9":
                history.push("/managers")
                break;
            case "10":
                history.push("/classifications")
                break;
            case "11":
                history.push("/program");
                break;
            case "12":
                history.push("/messages");
                break;
            case "13":
                history.push("/categories");
                break;
            default:
                return null;        
        }
    }

  
    const MenuItem = (prop: MenuItemProps) => {
        return (
            <Menu.Item {...prop}>
                {prop.title}
            </Menu.Item>
        )
    }

    const mobileSiderProps: SiderProps = width < 768 ? {
        style: {
            overflow: 'auto',
            height: '100%',
            position: 'fixed',
            zIndex: 999,
            width: '100%',
            left: 0,
            top: 0,
            bottom: 0,
        },
        collapsedWidth: 0,
        collapsible: false,
        width: '100%',
        collapsed: !isOpen
    } : {}

    const MenuItemWithPermission = (withPermission(MenuItem))
    return (
        <Sider collapsible collapsed={isOpen} onCollapse={toggleMobileSidebar} {...mobileSiderProps}>
            <div className="logo">
                {
                    width < 768 && isOpen && (
                        <MenuOutlined
                            onClick={toggleMobileSidebar}
                            style={{
                                color: '#fff',
                                fontSize: 32,
                                width: 48
                            }}
                        />
                    )
                }
            </div>
            <Menu selectedKeys={[getPathKey(currentPage)]} theme="dark" mode="inline" onClick={handleMenuClick}>
                <MenuItem key="1" icon={<BarChartOutlined/>} title={'Dashboard'}/>
                <MenuItemWithPermission key="8" icon={<BarChartOutlined/>} title={'Coach Dashboard'} roles={[admin]}/>
                <MenuItem key="2" icon={<PieChartOutlined/>} title={'Check Ins'}/>
                <MenuItem key="4" icon={<UserOutlined/>} title={'Clients'}/>
                <MenuItemWithPermission key="5" icon={<UserOutlined/>} title={'Coaches'} roles={[admin, manager]}/>
                <MenuItemWithPermission key="9" icon={<UserOutlined/>} title={'Managers'} roles={[admin]}/>
                <MenuItemWithPermission key="6" icon={<UserOutlined/>} title={'Admins'} roles={[admin]}/>
                <MenuItemWithPermission key="11" icon={<AuditOutlined />} title={'Program'} roles={[admin]} />
                <MenuItemWithPermission key="10" icon={<AuditOutlined/>} title="Classifications" roles={[admin]} />
                <MenuItemWithPermission key="13" icon={<AuditOutlined />} title="Categories" roles={[admin, manager, coach]} />
                <MenuItemWithPermission key="7" icon={<AuditOutlined/>} title={'Activity'} roles={[admin]}/>
                <MenuItem key="3" icon={<MessageOutlined/>} title={'Message Templates'}/>
                <MenuItem key="12" icon={<WechatOutlined />} title={'Messages'} />
            </Menu>

        </Sider>
    );
}

export default withLayoutAuth(Sidebar)