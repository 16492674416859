import React, {useEffect, useRef, useState} from 'react';
import { useAppDispatch, useAppSelector } from 'redux/store';
import useResize from "../../hooks/useResize";
import ChatMobile from "./Chat-Mobile/ChatMobile";
import ChatDesktop from "./Chat-Desktop/ChatDesktop";
import withSidebarNavigation from "../../HOCs/withSidebarNavigation";
import useConversationApi_Search from "./components/Conversation/SearchList/useConversationApi_Search";
import useConversationApi from "./components/Conversation/useConversationApi";
import {setReset} from "../../redux/reducers/chatReducer";
import useLateConversationApi from "./components/Conversation/LateList/useLateConversationApi";
import {Button} from "antd";
import {ONE_DAYS_MORE_LATE, TWO_DAYS_MORE_LATE} from "../../vars";
import { ResponseTypeChat } from 'Types';


const Messages = () => {
    const [responseType, setResponseType] = useState<ResponseTypeChat | null>(null);
    const {width} = useResize();
    const dispatch = useAppDispatch();
    const searchProps = useConversationApi_Search({responseType});
    const contactProps = useConversationApi();
    const lateListProps = useLateConversationApi({responseType: responseType as ResponseTypeChat});
    const currentUser = useAppSelector((state) => state.chat.currentUser);
    const refOneDay = useRef<HTMLButtonElement>(null);
    const refTwoDay = useRef<HTMLButtonElement>(null);

    const ResponseRadioGroup = (
        <div style={{display: 'flex', width: '100%'}}>
            <Button
                ref={refOneDay}
                type={responseType === ONE_DAYS_MORE_LATE ? 'primary' : undefined}
                name={ONE_DAYS_MORE_LATE}
                title={ONE_DAYS_MORE_LATE}
                onClick={({currentTarget: {title}}) => {
                if(responseType === title){
                    refOneDay.current?.blur();
                    return setResponseType(null)
                }
                setResponseType(ONE_DAYS_MORE_LATE)
                }} 
                block
            >
                Over 24h
            </Button>
            <Button
                ref={refTwoDay}
                type={responseType === TWO_DAYS_MORE_LATE ? 'primary' : undefined}
                title={TWO_DAYS_MORE_LATE}
                onClick={({currentTarget: {title}}) => {
                if(responseType === title){
                    refTwoDay.current?.blur();
                    return setResponseType(null);
                }
                setResponseType(TWO_DAYS_MORE_LATE);
                }} 
                block
            >
                Over 48h
            </Button>
        </div>
    )

    useEffect(() => {
        return () => {
            dispatch(setReset());
        }
    }, [])

    return width > 768
        ?
        <ChatDesktop
            responseType={responseType}
            ResponseRadioGroup={ResponseRadioGroup}
            currentUser={currentUser}
            contactProps={contactProps}
            searchProps={searchProps}
            lateListProps={lateListProps}
        />
        :
        <ChatMobile
            responseType={responseType}
            ResponseRadioGroup={ResponseRadioGroup}
            currentUser={currentUser}
            contactProps={contactProps}
            searchProps={searchProps}
            lateListProps={lateListProps}
        />
};

export default withSidebarNavigation(Messages, '/messages');