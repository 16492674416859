import React from "react";
import { AxiosResponse } from "axios";
import {admin, manager} from "vars";
import { UserType, UserRoles } from "Types";
import withPermission from "HOCs/withPermission";
import antNotificationComponent from "utils/notification-ant-utils";
import {Button, Input, Menu, PageHeader, Space, MenuItemProps} from "antd";
import {DownOutlined} from "@ant-design/icons";
import { IClient } from "Interfaces/User";
import {MenuClickEventHandler} from "rc-menu/lib/interface";
import { Dropdown } from "components/Antd/DropDown";
const {Search} = Input;
interface IUsersPageHeader {
    updateUserData: <T>({ id, data }: {
        id: string;
        data: T;
    }) => Promise<AxiosResponse<UserType> | undefined>,
    userRoles: UserRoles
    usersData: IClient[],
    selectedRowKeys: React.Key[],
    newRecord: () => void,
    changeSearchValue: (value: string) => void,
    handleUsersSearch: () => void,
    onSelectUsersChange: (selectedUsers: React.Key[]) => void,
    usersTableUpdate: () => void,
    setAssignCoachVisible: (flag: boolean) => {
        payload: boolean;
        type: string;
    },
    setRemoveCoachVisible: (flag: boolean) => {
        payload: boolean;
        type: string;
    },
    setClientTypeVisible: (flag: boolean) => {
        payload: boolean;
        type: string;
    },
};

interface IUpdateUserData extends IClient {
    createMacros: boolean,
}

export default function UsersPageHeader({
                                        changeSearchValue,
                                        handleUsersSearch,
                                        newRecord,
                                        selectedRowKeys,
                                        usersData,
                                        setAssignCoachVisible,
                                        setRemoveCoachVisible,
                                        setClientTypeVisible,
                                        userRoles,
                                        updateUserData,
                                        onSelectUsersChange,
                                        usersTableUpdate
                                        }: IUsersPageHeader) {

    const initMacros = async () => {
        try {
            const requests: IUpdateUserData[] = usersData.reduce((arr: IUpdateUserData[], user: IClient) => {
                if (selectedRowKeys.includes(user.id)) {
                    const updatedRow = {...user, createMacros: true}
                    return [...arr, {...updatedRow}]
                }
                return arr
            }, []);

            await Promise.all(requests.map((data) => updateUserData({
                id: data.id,
                data
            })));

            onSelectUsersChange([])
            usersTableUpdate()
            return antNotificationComponent({type: 'success', message: "Saved"})
        } catch (err) {
            console.error(err);
            return antNotificationComponent({type: 'error', message: "Failed to save"});
        }
    }


    const handleMenuClick: MenuClickEventHandler = ({key}) => {
        switch (key) {
            case "0":
                setClientTypeVisible(true)
                break;
            case "1":
                initMacros()
                break;
            case "2":
                setAssignCoachVisible(true)
                break;
            case "3":
                setRemoveCoachVisible(true)
                break    
        }
    }

    const MenuItem = (prop: MenuItemProps) => {
        return (
            <Menu.Item {...prop}>
                {prop.title}
            </Menu.Item>
        )
    }

    const AssignCoachMenuItem = (withPermission(MenuItem))
    const RemoveCoachMenuItem = (withPermission(MenuItem))
    const AssignManagerMenuItem = (withPermission(MenuItem));
    const ClientTypeMenuItem = withPermission(MenuItem);


    const menu = (
        <Menu onClick={handleMenuClick}>
            <MenuItem  key="1" title={'Init macros'}/>
            <AssignCoachMenuItem  key="2" title={'Assign coach'} roles={[admin, manager]}/>
            <RemoveCoachMenuItem  key="3" title={'Remove coach'} roles={[admin, manager]}/>
        </Menu>
    );
    const extras = (
        <Space size="large" align="end">
            {userRoles === admin || userRoles === manager ? <Button type="primary" onClick={newRecord}>New</Button> : null}
            <Search placeholder="Search" onChange={(e) => changeSearchValue(e.target.value)}
                    onSearch={handleUsersSearch} enterButton/>
            <Dropdown 
                disabled={selectedRowKeys.length === 0} 
                trigger={['click']}
                overlay={menu}>
                    <Button>Actions <DownOutlined/></Button>
            </Dropdown>
        </Space>
    )
    return (
        <PageHeader className="site-layout-background" title="Clients"
                    onBack={() => window.history.back()} extra={extras}/>
    )
}