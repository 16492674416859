import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import { useAppSelector } from 'redux/store';
import withPermission from "HOCs/withPermission";
import NotFoundPage from "../not-found/NotFoundPage";
import {Layout, PageHeader, Space} from "antd";
import CoachSidebar from "./CoachSidebar";
import CoachMainBody from "./CoachMainBody";
import './coach.css'
import withSidebarNavigation from "HOCs/withSidebarNavigation";
import useCoachesApiWithRedux from "services/coaches-service";
import {getPopoverTextWithIcon} from "utils/helpers";

function CoachMain() {
    const params = useParams() as {id: string};
    const selectedCoach = useAppSelector(state => state.coaches.selectedCoach)
    const [loading, setLoading] = useState<boolean>(true);

    const props = useCoachesApiWithRedux()
    const {getCoach, getCoaches}=props

    useEffect(() => {
        let cleanupFunction = false;
        const fetchData = async () => {
            setLoading(true)
            await getCoaches()
            await getCoach(params.id)
            if(!cleanupFunction) {
                setLoading(false)
            }
        }
        fetchData();

        return () => { cleanupFunction = true; }
    }, []);

    return (
        <Layout className={'coach_main_layout'}>
            <CoachSidebar/>
            <Layout>
                <Space className={'page_wrapper_style'} direction="vertical">
                    {!loading && !!selectedCoach ? <>
                        <PageHeader className="site-layout-background" title={getPopoverTextWithIcon(selectedCoach?.fullName)}
                                    onBack={() => window.history.back()}/>
                        <CoachMainBody {...props}/>
                    </> : null}
                </Space>
            </Layout>
        </Layout>
    )
}
export default withPermission(withSidebarNavigation(CoachMain, '/coach'), NotFoundPage)