import React from "react";
import {Layout, Menu, Dropdown} from 'antd';
import {UserOutlined, MenuOutlined} from "@ant-design/icons";
import {useDispatch} from "react-redux";
import './header.css'
import {useHistory} from "react-router-dom";
import {logout} from "services/authService";
import withLayoutAuth from "HOCs/withLayout";
import useResize from "hooks/useResize";
import {openSliderMenuAction} from "redux/actions/commonActions";
// import {subscribeUser} from "utils/ServiceWorker_utils";
import { useAppSelector } from "redux/store";
import {MenuClickEventHandler} from "rc-menu/lib/interface"
import "../home/Home.css";


function HeaderElement() {
    const userEmail = useAppSelector(state => state.auth.user?.email)
    const {width} = useResize();
    const dispatch = useDispatch()
    const history = useHistory()


    const signOutHandler: MenuClickEventHandler = async ({key}) => {
        if(key === 'Sign out'){
            dispatch(logout(history))
        }
        if(key === 'notification'){
        //    await subscribeUser();
        }
    }

    const openMobileSidebar = () => {
            dispatch(openSliderMenuAction());
    };


    const menu = () => {
        return (
            <Menu onClick={signOutHandler}>
                <Menu.Item key={'Sign out'} icon={<UserOutlined/>}>Sign out</Menu.Item>
                {/*<Menu.Item key={'notification'} icon={<BellOutlined />}>Enable Notifications</Menu.Item>*/}
            </Menu>
        )
    }

    const {Header} = Layout;

    return (
        <Header className={'mainHeader'}>
            {!!process.env.REACT_APP_BRANCH ? <div className={'header_version_space'}>
                <span>Branch: {process.env.REACT_APP_BRANCH}</span>
            </div> : null}
            <Dropdown.Button className={'headerDropdown'} overlay={menu} trigger={['click']} placement="bottomRight"
                             icon={<UserOutlined style={{fontSize: 20}}/>}>{userEmail || null}</Dropdown.Button>
            {width < 768 &&
            <MenuOutlined
            onClick={openMobileSidebar}
            style={{
                color: '#fff',
                fontSize: 32,
                width: 48
            }}
            />
            }
        </Header>
    )
}
export default withLayoutAuth(HeaderElement)