import React from 'react';
import {DownOutlined} from "@ant-design/icons";
import withPermission from "HOCs/withPermission";
import withSidebarNavigation from "HOCs/withSidebarNavigation";
import NotFoundPage from "../not-found/NotFoundPage";
import {PageHeader, Space, Button, Menu, Table, DatePicker, MenuProps, TablePaginationConfig} from "antd";
import {useClassificationApiWithRedux} from "services/classification-service";
import moment from "moment";
import {tablePagination} from "utils/helpers";
import useScrollTo from "hooks/useScrollTo";
import { RangePickerProps } from "antd/lib/date-picker";
import { Dropdown } from 'components/Antd/DropDown';
import { DATE_FORMAT } from 'vars';

const {RangePicker} = DatePicker;
const Classifications = (): JSX.Element => {
    const {
        columns,
        isLoading,
        userClassificationsData,
        initialClassification,
        totalCount,
        onChangeDate,
        onDownloadCsv,
        onLoad,
        onChangeTable
    } = useClassificationApiWithRedux();
    const {f_dateBefore, f_dateAfter, page, pageSize} = initialClassification;
    const {scrollRef} = useScrollTo<HTMLDivElement>({dependency:[pageSize, page], offset: 0})
    const ranges: RangePickerProps['ranges'] = {
        "This Week": [moment().parseZone().weekday(-1), moment().parseZone()],
        "Last Week": [moment().parseZone().weekday(-7), moment().parseZone().weekday(-1)]
    }

    const onClickMenu: MenuProps['onClick'] = ({key}) => {
        switch (key){
            case "csv":
                return onDownloadCsv();
            default:
                return;
        }
    }

    const menu = (
        <Menu onClick={onClickMenu}>
            <Menu.Item key="csv">Download CSV</Menu.Item>
        </Menu>
    )

    const extras = (
        <Space size="large" direction="horizontal" align="end">
            <RangePicker
                onChange={onChangeDate}
                format={DATE_FORMAT}
                ranges={ranges}
                value={!!f_dateBefore && !!f_dateAfter ? [moment(f_dateBefore), moment(f_dateAfter)] : null}
            />
            <Button onClick={onLoad}>Load</Button>
            <Dropdown trigger={["click"]} placement="bottomRight" overlay={menu}>
                <Button>Actions <DownOutlined/></Button>
            </Dropdown>
        </Space>
    )

    return (
        <Space className="page_wrapper_style"  direction="vertical">
            <PageHeader className="site-layout-background"
                        title="Classifications Summary"
                        onBack={() => window.history.back()}
                        extra={extras}
            />
            <div ref={scrollRef} />
            <Table 
            onChange={onChangeTable}
            pagination={tablePagination({pageSize,page,totalCount, showSizeChanger: true}) as TablePaginationConfig}
            dataSource={userClassificationsData}
            loading={isLoading}
            scroll={{x: 'auto'}}
            columns={columns}
            rowKey={e => e.id}
            />
        </Space>
    )

};


export default withPermission(withSidebarNavigation(Classifications, '/classifications'), NotFoundPage)