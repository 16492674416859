import React from 'react';
import {Card, Typography} from "antd";
import { BlockProps } from 'antd/lib/typography/Base';

interface Props {
    title: string,
    text: string,
    editable?: BlockProps['editable']
};

export default function InfoCard({title, text, editable=false}: Props){
    const { Paragraph, Title } = Typography;
    return(
        <Card className={'coach_main_info_card'}>
            <Title level={5}>{title}</Title>
            <Paragraph
                editable={editable}
            >
                {text}
            </Paragraph>
        </Card>
    )
}