import React from 'react'
import 'antd/dist/antd.css'
import './app.less'
import AppRoutes from "./AppRoutes/AppRoutes";

// "build": "react-app-rewired build",
const App = () => {
    return (
        <AppRoutes/>
    )
}

export default App
