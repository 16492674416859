import React from "react";
import {Redirect, Route, Switch, useLocation} from "react-router-dom";
import {
    AdminList,
    CheckInList,
    ClientMain,
    CoachList, HeaderElement,
    LoginPage,
    ResetPassword,
    ResetPasswordValidate,
    MessageTemplates,
    RegisterPage, Sidebar,
    UserList,
    ManagerList,
    DashboardPage,
    CoachMain,
    ManagerMain,
    ActivityPage,
    ForbiddenPage,
    NotFoundPage,
    CoachDashboardPage,
    ClassificationsList,
    ClientTypes,
    Messages,
    CategoriesPage
} from "../pages";
import ProtectedRoute from "../HOCs/ProtectedRoute";
import {admin, coach, manager} from "../vars";
import {Layout} from "antd";
import { useAppSelector } from "redux/store";


function AppRoutes() {
    const isAuth = useAppSelector((state) => state.auth.isAuth)

    const location = useLocation();

    const {Content}=Layout
    return (
        <div className="wrapper">
            <Layout className="layout">
                <Sidebar/>
                <Layout>
                    <HeaderElement/>
                    <Content className={isAuth?'layout_content':'layout_content_bg'}>
                        <Switch>
                            <ProtectedRoute path="/" component={DashboardPage} exact/>
                            <ProtectedRoute path="/coach-dashboard" component={CoachDashboardPage} roles={[admin]}/>
                            <Route path="/register" component={RegisterPage}/>
                            <ProtectedRoute path="/users" component={UserList}/>
                            <ProtectedRoute path="/coaches" component={CoachList} roles={[admin, manager]}/>
                            <ProtectedRoute path="/classifications" component={ClassificationsList} roles={[admin]} />
                            <ProtectedRoute path="/coach/:id" component={CoachMain} roles={[admin, manager]}/>
                            <ProtectedRoute path="/admins" component={AdminList} roles={[admin]}/>
                            <ProtectedRoute path="/managers" component={ManagerList} roles={[admin]} />
                            <ProtectedRoute path="/manager/:id" component={ManagerMain} roles={[admin]} />
                            <ProtectedRoute path="/program" component={ClientTypes} roles={[admin]} />
                            <ProtectedRoute path="/client/:id" component={ClientMain}/>
                            <ProtectedRoute path="/messageTemplates" component={MessageTemplates}/>
                            <ProtectedRoute path="/checkins" component={CheckInList}/>
                            <ProtectedRoute path="/activity" component={ActivityPage}  roles={[admin]}/>
                            <ProtectedRoute path="/messages" component={Messages} exact />
                            <ProtectedRoute path="/categories" component={CategoriesPage} exact roles={[admin, manager, coach]} />
                            <Route path="/login" component={LoginPage} exact/>
                            <Route path="/reset-password" component={ResetPassword} exact />
                            <Route path="/reset-password/:token" component={ResetPasswordValidate} />
                            <Route path="/forbidden" component={ForbiddenPage} exact/>
                            <Redirect from="/pma" to={location}/>
                            <Route component={NotFoundPage} path="*"/>
                        </Switch>
                    </Content>
                </Layout>
            </Layout>
        </div>
    )
}

export default AppRoutes