import React, { useState } from "react";
import { useAppSelector } from "redux/store";
import {Button, DatePicker, Input, Menu, PageHeader, Space, Switch} from "antd";
import moment from "moment";
import {DownOutlined} from "@ant-design/icons";
import { Dropdown } from "components/Antd/DropDown";
import {MenuClickEventHandler} from "rc-menu/lib/interface"
import {RangeValue} from "rc-picker/lib/interface"
import { ModalType } from "services/modals-service";
import { RangePickerProps } from "antd/lib/date-picker";
import {useSelector} from "react-redux";
import {getAutoGeneratedValue} from "../../utils/helpers";
import { FixMeLater } from "Types";
import { DATE_FORMAT } from "vars";

interface Props {
    handleDateChange: (value: FixMeLater) => void,
    changeSearchValue: (value: string) => void,
    completeSelected: (value: boolean) => Promise<void>,
    showFeedback: ModalType,
    handleCheckinSearch: (value: string) => void,
    checkInTableUpdateOnLoad: () => void,
    handleChangeAutoGenerated: (autoGenerated: boolean) => void
}

export default function CheckinPageHeader(props: Props) {
    const {
        checkInTableUpdateOnLoad,
        showFeedback,
        completeSelected,
        handleCheckinSearch,
        changeSearchValue,
        handleChangeAutoGenerated,
        handleDateChange
    } = props
    const {Search} = Input;
    const {RangePicker} = DatePicker;
    const selectedRowKeys = useAppSelector(state => state.checkIns.checkInSelectedRowKeys)
    const checkInTableSettings = useAppSelector(state => state.checkIns.checkInTableSettings);
    const areCheckInsLoading = useAppSelector(state => state.checkIns.isLoading);
    const {f_startDate, f_endDate, f_clientName, f_autoGenerated} = checkInTableSettings;
    const [isCheckedAuto, setIsCheckedAuto] = useState<boolean>(false);

    const handleChangeCheckedAuto = (value: boolean) => {
        setIsCheckedAuto(value);
        handleChangeAutoGenerated(value);
    };

    const ranges: RangePickerProps['ranges'] = {
        "This Week": [moment().weekday(-1), moment()],
        "Last Week": [moment().weekday(-7), moment().weekday(-1)]
    }

    const handleMenuClick: MenuClickEventHandler = (key) => {
        switch (key.key) {
            case "1":
                showFeedback(true)
                break;
            case "2":
                completeSelected(true)
                break
            case "3":
                completeSelected(false)
                break
            default: {
                break
            }
        }
    }

    const menu = (
        <Menu onClick={handleMenuClick}>
            <Menu.Item key="1">
                Assign feedback
            </Menu.Item>
            <Menu.Item key="2">
                Mark as complete
            </Menu.Item>
            <Menu.Item key="3">
                Mark as incomplete
            </Menu.Item>
            <Menu.Item key="4" disabled={true}>
                Delete check in
            </Menu.Item>
        </Menu>
    );

    const extras = (
        <Space size="large" direction="horizontal"  align="center">
            <Switch unCheckedChildren="Auto" checkedChildren="Auto" disabled={areCheckInsLoading} checked={isCheckedAuto} onChange={handleChangeCheckedAuto} />
            <Search placeholder="Search" onChange={(e) => changeSearchValue(e.target.value)}
                    onSearch={handleCheckinSearch} enterButton/>
            <RangePicker
                value={!!f_startDate && !!f_endDate ? [moment(f_startDate), moment(f_endDate)] : null}
                onChange={handleDateChange} format={DATE_FORMAT} ranges={ranges}/>
            <Button onClick={checkInTableUpdateOnLoad}>Load</Button>
            <Dropdown trigger={['click']} disabled={selectedRowKeys.length === 0} overlay={menu}>
                <Button>Actions <DownOutlined/></Button>
            </Dropdown>
        </Space>
    )
    return (
        <PageHeader className="site-layout-background" title="Check Ins"
                    onBack={() => window.history.back()} extra={extras}/>
    )
}