import { PayloadAction } from '@reduxjs/toolkit';
import {createSlice} from '@reduxjs/toolkit'

type PayloadModalType = PayloadAction<boolean>;

const initialState = {
    feedbackModalVisible: false,
    assignCoachModalVisible: false,
    editManagerModalVisible: false,
    removeManagerModalVisible: false,
    removeManagersModalVisible: false,
    assignManagerModalVisible: false,
    unAssignManagerModalVisible: false,
    removeCoachModalVisible: false,
    changeGoalModalVisible: false,
    addNotesModalVisible: false,
    macroModalVisible: false,
    assignmentsHistoryPopupVisible: false,
    removeFeedbackModalVisible: false,
    averageCheckInsModalVisible: false,
    assignClientTypeModalVisible: false,
    removeClientTypeModalVisible: false,
    pauseNotificationsModalVisible: false,
    addTaskModalVisible: false,
    editTaskModalVisible: false,
    removeTaskModalVisible: false,
    removeCategoryModalVisible: false,
    addCategoryModalVisible: false,
    editCategoryModalVisible: false,
}

const modalsReducer = createSlice({
    name: 'modalsReducer',
    initialState,
    reducers: {
        setEditManagerModalVisible: (state, action: PayloadModalType) => {
            state.editManagerModalVisible = action.payload
        },
        setAverageCheckInsVisible: (state, action: PayloadModalType) => {
            state.averageCheckInsModalVisible = action.payload
        },
        setRemoveManagersModalVisible: (state,action: PayloadModalType) => {
            state.removeManagersModalVisible = action.payload
        },
        setRemoveManagerModalVisible: (state, action: PayloadModalType) => {
            state.removeManagerModalVisible = action.payload
        },
        setFeedbackModalVisible: (state, action: PayloadModalType) => {
            state.feedbackModalVisible = action.payload
        },
        setUnAssignManagerModalVisible: (state, action: PayloadModalType) => {
            state.unAssignManagerModalVisible = action.payload
        },
        setAssignManagerModalVisible: (state,action: PayloadModalType) => {
            state.assignManagerModalVisible = action.payload
        },
        setAssignCoachModalVisible: (state, action: PayloadModalType) => {
            state.assignCoachModalVisible = action.payload
        },
        setRemoveCoachModalVisible: (state, action: PayloadModalType) => {
            state.removeCoachModalVisible = action.payload
        },
        setChangeGoalModalVisible: (state, action: PayloadModalType) => {
            state.changeGoalModalVisible = action.payload
        },
        setAddNotesModalVisible: (state, action: PayloadModalType) => {
            state.addNotesModalVisible = action.payload
        },
        setMacroModalVisible: (state, action: PayloadModalType) => {
            state.macroModalVisible = action.payload
        },
        setAssignmentsHistoryPopupVisible: (state, action: PayloadModalType) => {
            state.assignmentsHistoryPopupVisible = action.payload
        },
        setRemoveFeedbackModalVisible: (state, action: PayloadModalType) => {
            state.removeFeedbackModalVisible = action.payload
        },
        setAssignClientTypeModalVisible: (state, action: PayloadModalType) => {
            state.assignClientTypeModalVisible = action.payload
        },
        setRemoveClientTypeModalVisible: (state, action: PayloadModalType) => {
            state.removeClientTypeModalVisible = action.payload;
        },
        setPauseNotificationsModalVisible: (state, action: PayloadModalType) => {
            state.pauseNotificationsModalVisible = action.payload;
        },
        setAddTaskModalVisible: (state, action: PayloadModalType) => {
            state.addTaskModalVisible = action.payload;
        },
        setEditTaskModalVisible: (state, action: PayloadModalType) => {
            state.editTaskModalVisible = action.payload;
        },
        setAddCategoryModalVisible: (state, action: PayloadModalType) => {
            state.addCategoryModalVisible = action.payload;
        },
        setEditCategoryModalVisible: (state, action: PayloadModalType) => {
            state.editCategoryModalVisible = action.payload;
        },
        setRemoveTaskModalVisible: (state, action: PayloadModalType) => {
            state.removeTaskModalVisible = action.payload;
        },
        setRemoveCategoryModalVisible: (state, action: PayloadModalType) => {
            state.removeCategoryModalVisible = action.payload;
        }
    }
})

export const {
    setAverageCheckInsVisible,
    setEditManagerModalVisible,
    setFeedbackModalVisible,
    setAddNotesModalVisible,
    setAssignCoachModalVisible,
    setAssignManagerModalVisible,
    setAssignmentsHistoryPopupVisible,
    setChangeGoalModalVisible,
    setRemoveCoachModalVisible,
    setMacroModalVisible,
    setRemoveFeedbackModalVisible,
    setRemoveManagerModalVisible,
    setUnAssignManagerModalVisible,
    setRemoveManagersModalVisible,
    setAssignClientTypeModalVisible,
    setRemoveClientTypeModalVisible,
    setPauseNotificationsModalVisible,
    setAddTaskModalVisible,
    setEditTaskModalVisible,
    setAddCategoryModalVisible,
    setRemoveTaskModalVisible,
    setEditCategoryModalVisible,
    setRemoveCategoryModalVisible,
} = modalsReducer.actions
export default modalsReducer.reducer


