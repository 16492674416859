import React, { FC } from 'react';
import { Modal as M, ModalProps } from 'antd';


interface IModal extends ModalProps {
    children?: React.ReactNode
};

export const Modal: FC<IModal> = ({children, ...props}) => (
    <M {...props}>
        {children}
    </M>
)