import {Button, Popover, Space, Tag} from "antd";
import React, {useState} from "react";
import {AuditOutlined, InfoCircleOutlined} from "@ant-design/icons";
import {useHistory} from "react-router-dom";
import moment from "moment";
import { ColumnProps } from "antd/lib/table";
import { ICheckIn } from "Interfaces/CheckIn";
import { NO_REASON_PAUSED_NOTIFICATIONS_TEXT } from "utils/constants";
import { DATE_FORMAT_HOURS_MINUTES } from "vars";

const withPopover = (Component: JSX.Element, lowest: ICheckIn['lowest']) => {
    return function WithOtherProblems() {
        const [show, setShow] = useState(false)
        const changeShow = () => setShow(state => !state)
        return <Popover
            content={() => lowest.map((elem, index) => index !== 0 ?
                <Tag key={index} color="volcano">{elem.type.toUpperCase() + " (" + elem.value + ")"}</Tag> : null)}
            trigger="click"
            visible={show}
            onVisibleChange={changeShow}
            className={'other_problems_popover'}
        >
            {Component}

            <span className={'other_problems_popover_text'} style={{display: 'none'}}>More...</span>
        </Popover>
    }
}

const getComplianceWithFilter = ({record, f_compliance}: {record: ICheckIn, f_compliance: number | number[]}) => {
    if(Array.isArray(f_compliance)){

        if (f_compliance.length === 1) {
            const lowest = [...record.lowest].sort((a, b) => +a.type === f_compliance[0] ? -1 : +b.type === f_compliance[0] ? 1 : 0)
            return {...record, lowest}
        }
    
        if (f_compliance.length > 1) {
            // const withFilterType = [...record.lowest].filter((elem) => f_compliance.some((type) => type === +elem.type)).sort((a, b) => a.checkValue - b.checkValue)
            const withoutFilterType = [...record.lowest].filter((elem) => !f_compliance.some((type) => type === +elem.type))
    
            return {...record, lowest: withoutFilterType}
        }
    }

    return {...record}
}

export const complianceRender: ColumnProps<ICheckIn>['render'] = (text, record, f_compliance) => {
    if (!Array.isArray(record?.lowest)) {
        return
    }

    if (record.menstruating === true) {
        return <div className={'other_problems'}><Tag color="volcano">Menstruating</Tag></div>
    }

    if (!!f_compliance) {
        record = getComplianceWithFilter({record, f_compliance})
    }

    //Handled inverted stress logic
    if (record.lowest[0].type === "stress" && +record.lowest[0].value >= 4) {
        const StressWithPopover = withPopover(<Tag
            color="volcano">{record.lowest[0].type.toUpperCase() + " (" + record.lowest[0].value + ")"}</Tag>, record.lowest)
        return <StressWithPopover/>
    }

    if (record.lowest[0].type !== "stress" && +record.lowest[0].value <= 4) {
        const StressWithPopover = withPopover(<Tag
            color="volcano">{record.lowest[0].type.toUpperCase() + " (" + record.lowest[0].value + ")"}</Tag>, record.lowest)
        return <StressWithPopover/>
    } else {
        return <div className={'other_problems'}><Tag color="green">OK</Tag></div>
    }

}

export const lowestRender = (lowest: {type: string, value: string}) => {
    if (!lowest?.type && !lowest?.value) {
        return null
    }
    return lowest?.type === 'Ok' ? <Tag color="green">OK</Tag> : <Tag
        color="volcano">{lowest?.type.toUpperCase() + " (" + parseFloat(lowest?.value).toFixed(0) + ")"}</Tag>
}

export const programRender: ColumnProps<ICheckIn>['render'] = (text, record, index) => {
    if (!record.User?.ClientType) {
        return "-"
    }
    let value = record.User.ClientType.name
    if (record.User?.competing) {
        value = value + " (c)"
    }
    return value
}

export const dateFormatRender: ColumnProps<ICheckIn>['render'] = (text, record) => {
    if (!!record?.date) {
        return moment(record.date).format(DATE_FORMAT_HOURS_MINUTES)
    }
}

export const ActionsRender: ColumnProps<ICheckIn>['render'] = (text, record) => {
    return (
        <Space size="middle">
            <Button shape="circle" type="primary" icon={<AuditOutlined/>}
                    onClick={() => window.open(`/client/${record.userId}`, "_blank")}/>
        </Space>
    )
}

export const coachRender = (text: any, record: ICheckIn,): string => record.User?.Coach?.fullName || ""

export const feedbackRender: ColumnProps<ICheckIn>['render'] = (text, record) => {
    // if (typeof record.feedbackGiven === "boolean") {
        return record.feedbackGiven ? <Tag color="green">Yes</Tag> : <Tag color="volcano">No</Tag>
    // }
    // return null
}


export const pausedNotificationsRender: ColumnProps<ICheckIn>['render'] = (_,record) =>
    (record.User.pausedNotifications
            ? (
        <>
            <Tag color="red">
                    No
            </Tag>
            {record.User.pausedNotifications?.reasonMessage &&
                <Popover content={record.User.pausedNotifications.reasonMessage}>
                    <Tag color="orange">
                        <InfoCircleOutlined />
                    </Tag>
                </Popover>
            }
        </>)
            : <Tag color="green">Yes</Tag>);
