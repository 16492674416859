import React from 'react'
import ReactDOM from 'react-dom'
import {Provider} from 'react-redux'
import {BrowserRouter} from 'react-router-dom'
import ErrorBoundary from 'components/error-boundry/error-boundry'
import store from 'redux/store'
import App from './App'
import {ConfigProvider} from 'antd'
import enUS from 'antd/lib/locale/en_US'
// import ServiceWorkerWrapper from "./ServiceWorkerWrapper";
// import webPush from "web-push";
// import {urlBase64ToUint8Array} from "./utils/helpers";
// import * as serviceWorker from "./registerServiceWorker";

ReactDOM.render(
    <>
  <Provider store={store}>
      <ErrorBoundary>
        <BrowserRouter>
          <ConfigProvider locale={enUS}>
            <App/>
          </ConfigProvider>
        </BrowserRouter>
      </ErrorBoundary>
  </Provider>
        {/*<ServiceWorkerWrapper />*/}
        </>,
  document.getElementById('root'),
)
// serviceWorker.register();