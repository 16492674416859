import React, { FC } from 'react';
import { FormInstance, ProFormSwitch, ProFormText } from '@ant-design/pro-form';
import ColorCascader from './CategoryColorSelect';
import { ICategoryForm } from 'Interfaces/TasksCategories';
import { Rule } from 'antd/lib/form';
import { validateRequired } from 'utils/helpers';
import { CHAR_STRING_SIZE } from 'vars';

export type CategoryFormProps = {
    onFinish: (formData: ICategoryForm) => Promise<void>,
    onCancel: () => void,
    form: FormInstance
};

const rules: Rule[] = [
    () => ({
        async validator(_, value){
            await validateRequired(value);
            return Promise.resolve(value);
        }
    })
]

type CategoryFormFieldsProps = { includeChangeArchiveStatus?: boolean };

const CategoryForm: FC<CategoryFormFieldsProps> = ({ includeChangeArchiveStatus }) => {
    return (
            <>            
                <ProFormText
                    name="name"
                    label="Name"
                    placeholder="Name"
                    required
                    rules={[...rules, { max: CHAR_STRING_SIZE }]}
                >
                </ProFormText>
                <ProFormText
                    name="shortName"
                    label="Short Name"
                    placeholder="Short Name"
                    required
                    rules={[...rules, { max: CHAR_STRING_SIZE }]}
                >
                </ProFormText>
                <ProFormText
                    name="description"
                    label="Description"
                    placeholder="Description"
                >
                </ProFormText>
                    <ColorCascader
                        name="color"
                        label="Color"
                        placeholder="Color"
                        required
                        rules={rules}
                />
                {includeChangeArchiveStatus && <ProFormSwitch
                    name="archived"
                    label="Disabled"
                />}
            </>
    )
};

export default CategoryForm;
