import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import { IUserStateClassification } from "Interfaces/UserClassification";

interface InitialState {
    userStateClassification: IUserStateClassification[],
    users: Options[],
    coaches: Options[],
    totalCount: number,
    selectedRowKeys: string[],
    selectedRowData: string[],
    lastUpdate: string | null,
}

interface Options {
    text: string,
    value: string,
}

const initialState = {
    userStateClassification: [],
    coaches: [],
    totalCount: 0,
    users: [],
    selectedRowKeys: [],
    selectedRowData: [],
    lastUpdate: null,
} as InitialState;


interface SetSelectedRowKeysPayload {
    selectedRowKeys: string[],
};

interface SetClassificationPayload {
    userStateClassifications: IUserStateClassification[],
    totalCount: number,
    users: Options[],
}

const classificationsReducer = createSlice({
    name: 'classificationsReducer',
    initialState,
    reducers: {
        setClassifications: (state, action: PayloadAction<SetClassificationPayload>) => {
            state.userStateClassification = action.payload.userStateClassifications;
            state.totalCount = action.payload.totalCount;
            state.users = action.payload.users;
        },
        setCoaches: (state, action: PayloadAction<Options[]>) => {
          state.coaches = action.payload;
        },
        setUpdateClassification: (state, action: PayloadAction<string>) => {
            state.lastUpdate = action.payload;
        },
        setSelectedRowKeys: (state, action: PayloadAction<SetSelectedRowKeysPayload>) => {
            state.selectedRowKeys = action.payload.selectedRowKeys;
        }
    }
});


export default  classificationsReducer.reducer;

export const {setClassifications,setCoaches,setSelectedRowKeys} = classificationsReducer.actions;