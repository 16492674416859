import React from 'react';
import NotesForm from "./NotesForm";
import {Form} from "antd";
import {EDIT_NOTES} from "vars";
import antNotificationComponent from "utils/notification-ant-utils";
import { useAppSelector } from 'redux/store';
import { CurrentEvent } from 'Types';
import { IClientNote } from 'Interfaces/ClientNote';
import { Modal } from 'components/Antd/Modal';

export interface INotesProps {
    setUserActivity:(activityType: CurrentEvent) => Promise<void>, 
    onCancel: () => void, 
    addNote: (id: string, values: {
        notes: string;
    }) => Promise<IClientNote | undefined>
}

export default function AddNotesModal({setUserActivity, onCancel, addNote}: INotesProps) {
    const user = useAppSelector(state => state.user.userData!)
    const addNotesModalVisible = useAppSelector(state => state.modals.addNotesModalVisible)

    const [notesForm] = Form.useForm<{notes: string}>();

    const onReset = () => notesForm.resetFields();

    const onCancelNote = () => {
        onReset()
        onCancel()
    }

    const onFinish = async (values: {notes: string}) => {
        const data = await addNote(user.id, values)
        if (!!data) {
            await setUserActivity(EDIT_NOTES)
            onCancelNote()
            antNotificationComponent({message: "Saved", type: 'success'})
        } else {
            antNotificationComponent({message: "Failed to save", type: 'error'})
        }

    }
    const onOk = () => notesForm.validateFields()
        .then((values) => onFinish(values))
        .catch((e) => console.warn(e.errorFields))

    return (
        <Modal centered title="Add Note" visible={addNotesModalVisible} onOk={onOk} onCancel={onCancelNote} okText={'Save Note'}>
            <Form form={notesForm} labelCol={{span: 24}}>
                <NotesForm  />
            </Form>
        </Modal>
    )
}