import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useAppDispatch } from "redux/store";
import { ActionCreatorWithPayload } from "@reduxjs/toolkit";
import useApi from "hooks/useApi";
import usePersistedState from "hooks/usePersistedState";
import { ICoach, IManager } from "Interfaces/User";
import {
    getUpdate,
    resetManagersStore,
    setFilteredManagers,
    setManager,
    setManagers,
    setSelectedManagers,
    setManagerInfoCards,
    setDateRange,
    setSelectedTable,
    setCoachesTable,
    setFilteredCoaches,
    ICurrentManagerInfoCards
} from "redux/reducers/managersReducer";
import { 
    setAssignManagerModalVisible, 
    setEditManagerModalVisible, 
    setRemoveManagerModalVisible, 
    setRemoveManagersModalVisible 
} from "redux/reducers/modalsReducer";
import { getDateRange, getUserFormData, Logger, openNotification } from "utils/helpers";
import { manager, THIS_WEEK } from "vars";
import { RadioChangeEvent } from "antd";
import { FormInstance } from "rc-field-form/lib/interface";
import { IMessageStatus } from "Interfaces/Auth";
import { AxiosRequestConfig } from "axios";
import { DateRange } from "Types";

const logger = Logger('useManagersApiWithRedux');

export interface IEditManager {
    values: IManager,
    editForm: FormInstance<IManager>,
    manager: IManager
};

interface IFilterManagers {
    managersData: IManager[],
    searchValue: string
};

interface IFilterCoaches {
    coachesData: ICoach[],
    searchValue: string
}

interface IAssignManager {
    managerIdList: string[] | React.Key[],
    coachIdList: string[] | React.Key[]
};

export interface ICreateManager {
    firstName: string,
    lastName: string,
    password?: string,
    confirmPassword?: string,
    email: string,
    specificArea?: string,
    bio?: string,
    pvtEnabled?: boolean,
    pvtSuperUser?: boolean,
    disabled?: boolean,
};

const useManagersApiWithRedux = () => {
    const {API} = useApi();
    const dispatch = useAppDispatch();
    const history = useHistory();

    const [,setInitialCheckInData] = usePersistedState(
        "checkInList",
        {}
    )

    const onUpdateManager = async ({id, data}: {id: string, data: IManager}) => {
        try {
            const response = await API.put<IManager>(`/api/user/${id}`, data)
            return response.data
        }catch (error) {
            if(error instanceof Error){
                logger.error(error.message);
            }
        }
    }

    const onInitMacros = async (managers: IManager[]) => {
        try {
            const response = await Promise.all(managers.map((manager) => onUpdateManager({
                id: manager.id,
                data: manager
            })));
            if(Array.isArray(response)){
                dispatch(setSelectedManagers([]));
                dispatch(getUpdate(new Date().getTime()));
                openNotification('Saved', 'success', 'topRight');
            }
        }catch (error){
            if(error instanceof Error){
                logger.error(error.message);
                openNotification('Failed to save', 'error', 'topRight');
            }
        }
    };

    const onUnAssignManagers = async ({managerIdList, coachIdList}: IAssignManager) => {
        const data = {
             managerIdList,
             coachIdList,
        }        
        try {
            const response = await API.put('/api/user/manager/unassign/', data);
            if(response.data){
                openNotification('Deleted', 'success', 'topRight');
            }
        } catch (error) {
            if(error instanceof Error){
                logger.error(error.message);
                openNotification('Failed to delete', 'error', 'topRight')
            }
        }
    };

    const onAssignManagers = async (data: IAssignManager) => {
        try {
            const response = await API.put<IMessageStatus>(`/api/user/manager/assign/`, data); 
            if(response.data){
                openNotification('Saved', 'success', 'topRight');
            }
        } catch (error) {
            if(error instanceof Error){
                logger.error(error.message);
                openNotification('Failed to save', 'error', 'topRight')
            }
        }
    }

    const onRedirectToCheckIns = (coach: ICoach) => {
        history.push('/checkins');
        setInitialCheckInData({
            f_coachId: [coach.id]
        });
        // dispatch(setCheckInTableSettings({ f_coachId: [coach.id] }));
    }

    const onRedirectToCoachPage = (coach: ICoach) => history.push(`/coach/${coach.id}`);

    const onRedirectToActivityPage = (manager: IManager) => history.push({
        pathname: '/activity',
        search: `?f_actorId[]=${manager.id}`
    });

    const getUpdateManager = ({id, key}: {id: string, key: string}) => async (
        data: string | null, 
        config: AxiosRequestConfig | undefined, 
        message: string | undefined) => {
        try {
            const response = await API.put(`/api/user/${id}`, {
                [key]: data
            }, config);
            dispatch(setManager(response.data));
            openNotification(message || 'Updated', 'success', 'topRight');
        } catch (error) {
            if(error instanceof Error){
                logger.error(error.message);
                openNotification('Failed to update', 'error', 'topRight');
            }
        }
    };

    interface IGetInfoCard{
        path: string,
        key: string,
        param?: string,
        dateRange?: DateRange,
        callback: ActionCreatorWithPayload<ICurrentManagerInfoCards>
    }

    const getInfoCard = ({path, callback, key, param, dateRange = THIS_WEEK}: IGetInfoCard) => async (id: string) => {
        const {f_dateBefore, f_dateAfter} = getDateRange(dateRange);
        try {  
            const response = await API.get(`/api/dashboard/${path}`, {
                params:{
                    f_dateBefore,
                    f_dateAfter,
                    [param || 'f_coachId']: [id]
                }
            });
            return dispatch(callback({[key]: response.data.value}));
        } catch (error) {
            if(error instanceof Error){
                logger.error(error.message);
            }
        }
    };

    const getAverageFeedbackTime = getInfoCard({path: 'checkin/average-feedback-time', callback: setManagerInfoCards, key: 'coachAverageFeedbackTime'});
    const getCoachCheckIns = getInfoCard({path: 'checkin/total', callback: setManagerInfoCards, key: 'coachCheckIns'});
    const getCoachFeedbackGiven = getInfoCard({path: 'checkin/feedback-given', callback: setManagerInfoCards, key: 'coachFeedbackSent'});
    const getActiveClients = getInfoCard({path: 'active-clients', callback: setManagerInfoCards, key: 'coachActiveClients'});
    const getActiveCoaches = getInfoCard({path: 'active-coaches', callback: setManagerInfoCards, key: 'managerActiveCoaches', param: 'managerId'});

    const responses = [
        getAverageFeedbackTime,
        getCoachCheckIns,
        getCoachFeedbackGiven,
        getActiveClients,
        getActiveCoaches,
    ];

    const getInfoCards = async (id: string) => {
        try {
            await Promise.all(responses.map(fn => fn(id)));
        }catch (error) {
            if(error instanceof Error){
                logger.error(error.message);
            }
        }
    };

    const getCoachesData = async (id: string) => {
        try {
            const response = await API.get<{users: ICoach[], totalCount: number}>('/api/user', {
                params: {
                    f_roles: 'coach',
                    f_managerId: id
                }
            });
            dispatch(setCoachesTable(response.data));
        } catch (error) {
            if(error instanceof Error){
                logger.error(error.message);
            }
        }
    };


    // const onChangeCoachesFullNameOrEmail = ({target: {value}}) => d

    const getManager = async (id: string) => {
        try {
            const response = await API.get<IManager>(`/api/user/${id}`);
            dispatch(setCurrentManager(response.data));
        } catch (error) {
            if(error instanceof Error){
                logger.error(error.message);
            }
        }
    };

    const getManagersData = async (callback: ActionCreatorWithPayload<IManager[], string> = setManagers) => {
        try {
            const response = await API.get<IManager[]>("/api/user/manager/list");
            dispatch(callback(response.data));
        } catch (error) {
            if(error instanceof Error){
                logger.error(error.message);
            }
        }
    };

    const onChangeSelectedTable = (e: RadioChangeEvent) => {
        dispatch(setSelectedTable(e.target.value))
    };

    const onChangeDateRange = (e: RadioChangeEvent) => {
        dispatch(setDateRange(e.target.value))
    };

    const onFilterManagers = ({managersData, searchValue}: IFilterManagers) => {
        const filteredManagers = managersData.filter((manager) => manager.fullName.includes(searchValue.trim()) || manager.email.includes(searchValue.trim()));
        dispatch(setFilteredManagers(filteredManagers))
    };

    const onFilterCoaches = ({coachesData, searchValue}: IFilterCoaches) => {
        const filteredCoaches = coachesData.filter((coach) => coach.fullName.includes(searchValue.trim()) || coach.email.includes(searchValue.trim()));
        dispatch(setFilteredCoaches(filteredCoaches))
    };

    const onRemoveManager = async (manager: React.Key[] | IManager) => {
        try {
            if(Array.isArray(manager)){
                const response = await API.delete('/api/user/', {
                   data: {
                       users: manager
                   }
                });
                if(response.data){
                    dispatch(setSelectedManagers([]));
                    dispatch(setRemoveManagersModalVisible(false));
                    dispatch(getUpdate(new Date().getTime()));
                    openNotification("Deleted", 'success', 'topRight');
                }
            } else{
                const response = await API.delete(`/api/user/${manager.id}`);
                if(response.data){
                    dispatch(setCurrentManager(null));
                    dispatch(getUpdate(new Date().getTime()));
                    dispatch(setRemoveManagerModalVisible(false));
                    openNotification("Deleted", 'success', 'topRight');
                } 
            }
        } catch (error) {
            if(error instanceof Error){
                openNotification("Failed to delete", 'error', 'topRight');
                logger.error(error.message);
            }
        }
    };

    const onEditManager = async ({values, editForm, manager}: IEditManager) => {
        const requestFormData = getUserFormData({formData: values});
        try {
            const response = await API.put<IManager>(`/api/user/${requestFormData.id}`, requestFormData);
            if(response.data){
                dispatch(getUpdate(new Date().getTime()));
                dispatch(setEditManagerModalVisible(false));
                openNotification("Updated", "success", "topRight");
            }        
        } catch (error) {
            if(error instanceof Error){
                logger.error(error.message);
                editForm.setFieldsValue(manager);
            }
        }
    };

    const onCreateManager = async (values: ICreateManager) => {
        const requestFormData = getUserFormData({formData: values, fields: {roles: manager, showAsManager: true}});
        try {
            const response = await API.post<IManager>('/api/user', requestFormData);
            if(response.data){
                dispatch(getUpdate(new Date().getTime()));
                dispatch(setAssignManagerModalVisible(false));
                openNotification("Saved", "success", "topRight");
            }   
        } catch (error) {
                dispatch(setAssignManagerModalVisible(false));
                openNotification("Failed to save", "error", "topRight");
        }
    }

    const setCurrentManager = (manager: IManager | null) => dispatch(setManager(manager));

    const setSelectedRowManager = (manager: React.Key[]) => dispatch(setSelectedManagers(manager));

    const setEditManager = (manager: IManager | null) => dispatch(setManager(manager));

    useEffect(() => () => {dispatch(resetManagersStore())}, []);

    return {
        setEditManager,
        getManager,
        getInfoCards,
        onChangeSelectedTable,
        getActiveCoaches,
        onInitMacros,
        onFilterManagers,
        getUpdateManager,
        onRedirectToCoachPage,
        onRedirectToCheckIns,
        onUnAssignManagers,
        onChangeDateRange,
        onRemoveManager,
        onEditManager,
        onCreateManager,
        onRedirectToActivityPage,
        getCoachesData,
        getManagersData,
        onFilterCoaches,
        onAssignManagers,
        setSelectedRowManager,
        setCurrentManager,
    }
};

export {useManagersApiWithRedux};