import React, {FC, useCallback} from 'react';
import { useAppSelector } from 'redux/store';
import { Row, Col, Button, FormInstance } from 'antd';
import {CopyOutlined} from '@ant-design/icons';
import { ModalForm, ProFormText, ProFormSelect, ProFormSwitch } from '@ant-design/pro-form';
import { isOnlySpaces, validateRequired, validateEmail, openNotification } from 'utils/helpers';
import { admin } from 'vars';
import { IAdminForm } from '../admin';

interface Props{
    visible: boolean,
    form: FormInstance<IAdminForm>,
    onFinish: (data: IAdminForm) => Promise<void>
    modalProps: {
        onCancel: () => void
    },
    onReset: () => void,
    onGenerate: () => Promise<void>,
    isLoadingToken: boolean,
    isLoadingRemove: boolean,
    onDelete: () => Promise<void>
};

const EditAdminForm: FC<Props> = ({
    form, 
    visible, 
    onFinish, 
    onReset, 
    modalProps,
    onGenerate,
    onDelete,
    isLoadingRemove,
    isLoadingToken
}) => {
    const authUserRole = useAppSelector((state) => state.auth.user!.roles);

    const onCopyToClipboard = useCallback(async () => {
        await navigator.clipboard.writeText(form.getFieldValue('apiKey'));
        openNotification("API token copied", "success", "topRight");
    }, [])



    return (
        <ModalForm 
            form={form} 
            visible={visible} 
            title="Edit User" 
            onFinish={onFinish} 
            onReset={onReset}
            modalProps={modalProps} 
            >
                <ProFormText name="firstName" label="First Name" placeholder="First Name" rules={[
                    {
                        required: true,
                        message: 'This field is required',
                    },
                    ({getFieldValue}) => ({
                        validator(_, value) {
                            if (!value || !isOnlySpaces(getFieldValue('firstName'))) {
                                return Promise.resolve();
                            }
                            return Promise.reject(new Error('This field is required'));
                        },
                    }),]}/>
                <ProFormText name="lastName" label="Last Name" placeholder="Last Name" rules={[
                    {
                        required: true,
                        message: 'This field is required',
                    },
                    ({getFieldValue}) => ({
                        validator(_, value) {
                            if (!value || !isOnlySpaces(getFieldValue('lastName'))) {
                                return Promise.resolve();
                            }
                            return Promise.reject(new Error('This field is required'));
                        },
                    }),]}/>
                <ProFormText name="email" label="Email" placeholder="Email"
                             rules={[() => ({
                                 async validator(_, value) {
                                     await validateRequired(value)
                                     await validateEmail(value)
                                     return Promise.resolve(value);
                                 },
                             }), {required: true, message: ''}]}/>
                <ProFormSelect
                    name="roles"
                    label="Role"
                    valueEnum={{
                        admin: 'Admin',
                        coach: 'Coach',
                        manager: 'Manager',
                    }}
                    placeholder="Please select a role"
                    rules={[{required: true, message: 'This field is required'}]}
                />
                <ProFormSwitch name="showAsCoach" label="Show as coach"/>
                <ProFormSwitch name="pvtEnabled" label="PVT Enable" />
                <ProFormSwitch name="pvtSuperUser" label="PVT Enable (Super)" />
                <ProFormSwitch name="disabled" label="Disabled"/>
                {
                    authUserRole === admin &&
                    <Row align="middle" gutter={[24,0]}>
                        {
                            form.getFieldValue("apiKey") ?
                                (
                                    <>
                                        <Col span={16}>
                                            <ProFormText name="apiKey" disabled label="API Token" fieldProps={{className: 'form_api_token', suffix: <Button style={{height: 20}}  onClick={onCopyToClipboard}  icon={<CopyOutlined style={{fontSize: 12}} />} />}} />
                                        </Col>
                                        <Row gutter={[24,0]}>
                                            <Col span={12} style={{paddingTop: 5}}>
                                                <Button type="primary" onClick={onGenerate} loading={isLoadingToken || isLoadingRemove}>Generate</Button>
                                            </Col>
                                            <Col span={12} style={{paddingTop: 5}}>
                                                <Button loading={isLoadingRemove || isLoadingRemove} type="primary" onClick={onDelete} danger>Delete</Button>
                                            </Col>
                                        </Row>
                                    </>
                                ) :
                                (
                                    <>
                                        <Col span={18}>
                                            <ProFormText name="apiKey" disabled placeholder="API Token" label="API Token" />
                                        </Col>
                                        <Col span={6} style={{paddingTop: 5}}>
                                            <Button type="primary" onClick={onGenerate} loading={isLoadingToken || isLoadingRemove}>Generate</Button>
                                        </Col>
                                    </>
                                )
                        }
                    </Row>
                }
        </ModalForm>
    )
};

export default EditAdminForm