import React, { FC, useMemo } from 'react';
import { Space } from 'antd';
import MiniCard from './MiniCard';
import DifferenceMiniCard from './DifferenceMiniCard';

import { useAppSelector } from 'redux/store';
import LoadingSpin from 'components/loading-spin/LoadingSpin';
import { getCheckInsWeeksDifferenceCount, getTwoEntitiesWithBiggestTimeDiff } from 'utils/helpers';
import { CheckInsCountData, ICheckIn } from 'Interfaces/CheckIn';
import './summary-dashboard.css';

type EnumerableCheckInField = 'weight' | 'waist' | 'lowerBelly' | 'upperQuad' | 'hips';
export type DashboardListItem = { title: string; fieldName: EnumerableCheckInField; withPercentageDifference?: boolean; };

type SummaryMiniDashboardProps = {
  dashboardList?: DashboardListItem[];
  selectedCheckIns: ICheckIn[];
  selectedCheckInsCounts: CheckInsCountData | null;
  placeHolderText: string;
  areCheckInsLoading?: boolean;
  photoView?: boolean;
};

const defaultMiniDashboardList = [
  { title: 'WEIGHT', fieldName: 'weight' as const, withPercentageDifference: true },
  { title: 'WAIST', fieldName: 'waist' as const },
  { title: 'LOWER BELLY', fieldName: 'lowerBelly' as const},
  { title: 'QUAD', fieldName: 'upperQuad' as const},
  { title: 'HIPS', fieldName: 'hips' as const},
];

const SummaryMiniDashboard: FC<SummaryMiniDashboardProps> = ({
  dashboardList,
  selectedCheckIns,
  selectedCheckInsCounts,
  areCheckInsLoading,
  placeHolderText,
  photoView
}) => {
    const dashboardListToRender: DashboardListItem[] = dashboardList ?? defaultMiniDashboardList;

    const comparedCheckIns = useMemo(() => {
        return getTwoEntitiesWithBiggestTimeDiff(selectedCheckIns);
    }, [selectedCheckIns]);

    if (areCheckInsLoading && !selectedCheckIns.length) return <LoadingSpin/>;
    if (!comparedCheckIns) return <p className='placeholder-field'>{placeHolderText}</p>;

    const weeksDiff = getCheckInsWeeksDifferenceCount(comparedCheckIns);

    return (
        <Space direction='horizontal'>
            {dashboardListToRender.map(({ fieldName , ...props }) => 
                <DifferenceMiniCard
                    {...props}
                    weeksDifference={weeksDiff}
                    values={[comparedCheckIns[0][fieldName], comparedCheckIns[1][fieldName]]}
                    key={fieldName}
                    photoView={photoView}
                />
            )}
            {photoView ? "" : <MiniCard
                title='CHECK INS'
                content={
                    `${selectedCheckInsCounts?.on_time_count ?? 0} / ` +
                    `${selectedCheckInsCounts?.late_count ?? 0} / ` +
                    `${selectedCheckInsCounts?.missed_count ?? 0}`
                }
                footer={
                    `on-time / late / missed`
                }
            />}
        </Space>
    )
}

export default SummaryMiniDashboard;
