import React, {useEffect, useState} from "react";
import { useAppDispatch, useAppSelector } from "redux/store";
import ActivityTable from "./ActivityTable";
import LoadingSpin from "components/loading-spin/LoadingSpin";
import useUsersApiWithRedux from "services/users-service";
import useCoachCandidatesApiWithRedux from "services/candidates-service";
import {
    changeSortDirectionTitle,
    getUrlParam,
    getWednesdayThisWeek,
    isFiltersChange
} from "utils/helpers";
import moment from "moment";
import {ITableSettings, setActivityInfoData, setLastUpdate, setTableSettings} from "redux/reducers/activityReducer";
import axios from "axios";
import { TableProps } from "antd";
import { IActivity } from "Interfaces/Activity";
import { SorterResult } from "antd/lib/table/interface";
import { SortTable } from "Types";
import { DATE_FORMAT_HOURS_MINUTES_SECONDS } from "vars";


export default function ActivityTableWrapper() {
    const dispatch = useAppDispatch()

    const tableSettings = useAppSelector(state => state.activity.tableSettings)

    const {getAllUsers} = useUsersApiWithRedux();
    const {getCoaches} = useCoachCandidatesApiWithRedux()

    const [isLoading, setLoading] = useState(true)

    const getActivityData = async (params = {}) => {
        try {
            const resp = await axios.get('/api/activity/page', {params})
            return resp?.data
        } catch (e) {
            if(e instanceof Error){
                console.warn(e.message)
            }
        }

    }

    const getActivity = async (tableSettings: ITableSettings) => {
        const data = await getActivityData(tableSettings)
        if (!!data) {
            dispatch(setActivityInfoData(data))
        }
    }

    const initTableSettings = () => {
        let f_actorId = getUrlParam('f_actorId[]')
        let f_clientId = getUrlParam('f_clientId[]')
        let f_action = getUrlParam('f_action[]')
        let f_startDate = getUrlParam('f_startDate')
        let f_endDate = getUrlParam('f_endDate')
        return {
            f_actorId: !f_actorId ? f_actorId : [f_actorId],
            f_clientId: !f_clientId ? f_clientId : [f_clientId],
            f_action: !f_action ? f_action : [f_action],
            f_startDate: !!f_startDate ? f_startDate : getWednesdayThisWeek(moment()).format(DATE_FORMAT_HOURS_MINUTES_SECONDS),
            f_endDate: !!f_endDate ? f_endDate : moment().parseZone().format(DATE_FORMAT_HOURS_MINUTES_SECONDS)
        }
    }

    const updateTable = () => dispatch(setLastUpdate(Date.now()))

    const handleSelect = async (data: ITableSettings) => {
        dispatch(setTableSettings({...data, page: 1}))
        updateTable()
    }

    const handleTableChange: TableProps<IActivity>['onChange'] = (pagination, filters, sorter) => {
        const {current, pageSize} = pagination
        const {columnKey, order} = sorter as SorterResult<IActivity>
        dispatch(setTableSettings({
            ...filters,
            pageSize,
            page: isFiltersChange({prevFilters: tableSettings, currentFilters: filters}) ? 1 : current,
            s_field: !!columnKey && (columnKey as string).startsWith('f_') ? (columnKey as string).slice('f_'.length) : columnKey,
            s_direction: !!order? (changeSortDirectionTitle(order) as SortTable) : null,
        }))
        updateTable()
    };

    useEffect(() => {
        let cleanupFunction = false;
        const fetchData = async () => {
            try {
                setLoading(true)
                if (!cleanupFunction) {
                    await getCoaches()
                    await getAllUsers()
                    await handleSelect(initTableSettings())
                    setLoading(false)
                }
            } catch (e) {
                if(e instanceof Error){
                    console.error(e.message)
                }
            }
        }

        fetchData();

        return () => {cleanupFunction = true};
    }, [])

    const props = {handleTableChange, handleSelect, getActivity}
    return (
        !isLoading ? <ActivityTable {...props} /> : <LoadingSpin/>
    )
}