import React from 'react';
import DashboardPageChartsBar from "./DashboardPageChartsBar";
import {shallowEqual} from "react-redux";
import NoData from "components/no-data/NoData";
import { useAppSelector } from 'redux/store';
import { IDatasets } from 'Interfaces/Dashboard/CheckIn';
import { ChartConfiguration } from 'chart.js';

export default function DashboardPageCompletedChartsBar() {
    const completedDailyData = useAppSelector(state => state.dashboard.completedDailyData!, shallowEqual)

    const getChartsBarParams = (elem: IDatasets) => {
        switch (elem.label) {
            case 'Not Completed': {
                return {...elem, backgroundColor: '#ffb6b6'};
            }
            case 'Completed': {
                return {...elem, backgroundColor: '#c2ec9d'};
            }
            default: {
                return elem
            }
        }
    }


    const options: ChartConfiguration<'bar'>['options'] = {
        plugins: {
            title: {
                display: true,
                position: 'bottom',
                text: !!completedDailyData.title ? completedDailyData.title : ''
            },
            tooltip: {
                callbacks: {
                    label: function (completedData) {
                        const {dataset: {label, data}, dataIndex} = completedData
                        return `${label}: (${data[dataIndex]}%)`
                    }
                }

            },

        },
        scales: {
            yAxes:
                {
                    ticks: {
                        callback: function (value) {
                            return `${value}%`;
                        }
                    },
                    max: 100,
                },
        }
    };

    const completedDataProps: ChartConfiguration<'bar'> = {
        type: 'bar',
        data: {
            ...completedDailyData,
            datasets: Array.isArray(completedDailyData.datasets) ? completedDailyData.datasets.map(elem => getChartsBarParams(elem)) : []
        },
        options: options,
    }

    const checkData = (data: IDatasets[] = []) => {
        const reducer = (accumulator: number, {data}: IDatasets) => {
            for (let number of data) {
                accumulator += number
            }
            return accumulator
        }
        const count = data.reduce<number>(reducer, 0)
        return count > 0
    }
    return (
        checkData(completedDailyData.datasets) ?
            <DashboardPageChartsBar title={'Check-ins completed (day breakdown)'} {...completedDataProps}/> 
            : <NoData description="Check-ins completed (day breakdown) (No Data)" />
    )
}