import React, { FC, useEffect, useState } from 'react';
import {useParams} from "react-router-dom";
import { useAppSelector } from 'redux/store';
import CoachClientsWidgetCardsRow from 'pages/coach/CoachClientsWidgetCardsRow';
import CoachDateRangeRow from 'pages/coach/CoachDateRangeRow';
import CoachInfoRow from 'pages/coach/CoachInfoRow';
import CoachMainSwitchList from 'pages/coach/CoachMainSwitchList';
import CoachTableSwitchRow from 'pages/coach/CoachTableSwitchRow';
import { CHECKIN, CLIENT, COACH } from 'vars';
import ManagerInfoCardsRow from '../ManagerInfoCardsRow';
import { IManager } from 'Interfaces/User';
import { RadioChangeEvent } from 'antd';
import { DateRange, SelectedTable } from 'Types';
import { ICurrentManagerInfoCards } from 'redux/reducers/managersReducer';
import { AxiosRequestConfig } from 'axios';

interface Props {
    currentManager: IManager | null,
    onChangeSelectedTable: (e: RadioChangeEvent) => void,
    dateRange: DateRange,
    infoCards: ICurrentManagerInfoCards,
    onChangeDateRange: (e: RadioChangeEvent) => void,
    onChangeBio: (data: string | null, config?: AxiosRequestConfig | undefined, message?: string | undefined) => Promise<void>,
    onChangePhoto: (data: string | null, config?: AxiosRequestConfig | undefined, message?: string | undefined) => Promise<void>,
    onChangeSpecificArea: (data: string, config?: AxiosRequestConfig | undefined, message?: string | undefined) => Promise<void>,
    getInfoCards: (id: string) => Promise<void>,

};

const ManagerMainBody: FC<Props> = ({
    currentManager, 
    infoCards,
    onChangeDateRange,
    onChangeBio, 
    onChangePhoto, 
    onChangeSpecificArea, 
    getInfoCards, 
    dateRange,
    onChangeSelectedTable,
}) => {
    const selectedTable = useAppSelector((state) => state.managers.selectedTable);
    const {id} = useParams() as {id: string};
    const [isLoading, setIsLoading] = useState(true);

    const managerMainSwitchRows: {value: SelectedTable, text: string}[] = [
        {
            value: COACH,
            text: "Coach List"
        },
        {
            value: CLIENT,
            text: "Client List"
        },
        {
            value: CHECKIN,
            text: "Check In List"
        }
    ];

    useEffect(() => {
        (async () => {
            setIsLoading(true);
            await getInfoCards(id);
            setIsLoading(false);
        })()
    }, [dateRange])

    return (
        <>
            <CoachInfoRow
                onChangePhoto={onChangePhoto}
                onChangeBio={onChangeBio}
                fullName={currentManager?.fullName || ''}
                alt="Manager photo"
                src={currentManager?.photo || ''}
                text={currentManager?.bio || ''}
            />
            <ManagerInfoCardsRow
                activeCoaches={{type: "Active Coaches", value: infoCards?.managerActiveCoaches || 0}}
                text={currentManager?.specificArea || ''}
                joinedDate={currentManager?.startDate || 'unknown'}
                onChange={onChangeSpecificArea}
                activeClients={{type: "Active Clients", value: infoCards?.coachActiveClients || 0}}
            />
            <CoachDateRangeRow 
                isLoading={isLoading}
                value={dateRange} 
                onChange={onChangeDateRange} 
            />
            <CoachClientsWidgetCardsRow
                averageCompliance={infoCards?.averageCompliance || ""}
                coachAverageFeedbackTime={infoCards?.coachAverageFeedbackTime || ""}
                coachCheckIns={infoCards?.coachCheckIns || ""}
                coachFeedbackSent={infoCards?.coachFeedbackSent || ""}
            />
            <CoachTableSwitchRow
                value={selectedTable}
                onChange={onChangeSelectedTable}
                list={managerMainSwitchRows}
            />
            <CoachMainSwitchList
                selectedTable={selectedTable}
            /> 
        </>
    )

};


export default ManagerMainBody;