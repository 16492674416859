import {useEffect, useRef, useState} from "react";


export default function useScrollTo<T extends HTMLElement>({dependency = [], offset = 1}: {dependency: any[], offset?: number}) {
    const [firstMount, setFirstMount] = useState(true)
    const scrollRef = useRef<T | null>(null)
    useEffect(() => {
        if(!firstMount) {
            window.scrollTo({
                top: scrollRef && scrollRef?.current ? (scrollRef.current.offsetTop * offset) : 0,
                behavior: "smooth"
            });
        }else {
            setFirstMount(state=>!state)
        }
    }, dependency)
    return {scrollRef}
}