import React, { useCallback } from 'react';
import {Avatar, Button, PageHeader, Popover, Space, Tag, Typography, Row, Col} from "antd";
import { useAppSelector } from 'redux/store';
import {getPhysiqueGoalText, getPopoverTextWithIcon} from "../../utils/helpers";
import './styles/client-header.css';
import LinearReggresionOrderWeeks from './LineRegression_OrderWeeks';
import { useParams } from 'react-router';
import PinnedTasks from 'pages/tasks/PinTask';
import NotificationsBlock, { NotificationsBlockProps } from './NotificationBlock';
import { ChosenCheckInsSummary } from './SummaryDashboard';
interface Props extends NotificationsBlockProps {
    activeTabKey: string,
    getCheckInChart: (userId: string) => () => Promise<void>,
};

export default function ClientHeader({activeTabKey, getCheckInChart, setPauseNotificationsVisible, reenableNotifications }: Props){
    const {Title} = Typography;
    const user = useAppSelector(state => state.user.userData!)
    const initials = useAppSelector(state => state.user.initials)
    const {id} = useParams() as {id: string};

    return(
        <PageHeader
            className="client-header"
            title={
                <Space>
                    <Space direction="vertical" align="center">
                        <Avatar size={{xs: 24, sm: 32, md: 40, lg: 64, xl: 80, xxl: 100}}>{initials}</Avatar>
                        {user.testAccount && <Tag color="blue">Test Account</Tag>}
                    </Space>
                    <Space direction="vertical">
                        <Title level={3} className="client-info-text">{getPopoverTextWithIcon(`${user?.firstName} ${user?.lastName}`)}</Title>
                        <Title level={5} className="client-info-text">
                            Goal: {!!user?.physiqueGoal ? getPhysiqueGoalText(user?.physiqueGoal) : ''}
                        </Title>
                    </Space>
                </Space>
            }
            extra={
                <Row justify="space-between" className="client-header-extra">
                    <Col>
                        {
                            activeTabKey === "4"
                                ? <LinearReggresionOrderWeeks getCheckInChart={getCheckInChart(id)}/>
                                : <ChosenCheckInsSummary />
                        }
                    </Col>
                    <Col style={{ marginTop: '20px' }}>
                        <NotificationsBlock
                            setPauseNotificationsVisible={setPauseNotificationsVisible}
                            reenableNotifications={reenableNotifications}
                        />
                    </Col>
                </Row>

            }
            footer={
                <Space className="pinned-tasks-container">
                    <PinnedTasks />
                </Space>
            }
        />
    )
}