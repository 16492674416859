import React from 'react';
import {Pie} from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import {Space} from "antd";
import { ChartConfiguration } from 'chart.js';



interface Props extends ChartConfiguration<'pie'> {
    title: React.ReactNode
}

export default function DashboardPageChartsPie({title = '', ...props}: Props) {
    return (
        <Space className={'dashboard_chart_wrapper'}>
            <h3>{title}</h3>
            <Pie {...props} plugins={[ChartDataLabels]}/>
        </Space>
    )
}