import React, {FC, useEffect, useState} from 'react';
import usersTableConfig from "./users_table/users_table_config";
import UserTable from "./UserTable";
import {changeSortDirectionTitle, isFiltersChange} from "utils/helpers";
import useClientTypesApiWithRedux from "services/clientTypes-service";
import { TableProps } from 'antd';
import { IClient } from 'Interfaces/User';
import { FilterValue, SorterResult, TablePaginationConfig } from 'antd/lib/table/interface';
import { useAppSelector } from 'redux/store';
import { Canceler } from 'axios';
import { IUsersTableSettings } from 'redux/reducers/usersReducer';

interface IUserTableWrapper {
    handleUsersTableChange: (pagination: TablePaginationConfig, filters: Record<string, FilterValue | null>, sorter: SorterResult<IClient> | SorterResult<IClient>) => void,
    getCoachesFilterOptions: () => Promise<void>,
    onEditModal: (client: IClient) => void,
    usersApiCancel: Canceler,
    initUsersTableFilters: (data: IUsersTableSettings) => void,
    setInitialUsers: React.Dispatch<IUsersTableSettings>
    initialUsers: IUsersTableSettings,
    getUsers: (query: IUsersTableSettings) => Promise<void>,
    onSelectUsersChange: (selectedUsers: React.Key[]) => void;
}; 


const UserTableWrapper: FC<IUserTableWrapper> = (props) => {
    const {
        onEditModal,
        getCoachesFilterOptions,
        usersApiCancel,
        initUsersTableFilters,
        handleUsersTableChange,
        setInitialUsers,
        initialUsers,
        getUsers,
        onSelectUsersChange
    } = props

    const [isInit, setInit] = useState<boolean>(true)
    const usersTableSettings = useAppSelector(state => state.users.usersTableSettings)
    const {getClientsTypes} = useClientTypesApiWithRedux()
    const {getUsersColumns} = usersTableConfig({
        path: 'user',
        actions: [{
            name: 'editModal',
            callback: onEditModal
        }]
    })

    const handleTableChange: TableProps<IClient>['onChange'] = (pagination, filters, sorter) => {
        const {pageSize, current} = pagination
        const {columnKey, order} = sorter as SorterResult<IClient>
        setInitialUsers({
            ...initialUsers,
            ...filters,
            pageSize,
            page: isFiltersChange({
                prevFilters: usersTableSettings,
                currentFilters: filters,
            })
                ? 1
                : current,
            s_field:
                !!columnKey && !!order
                    ? (columnKey as string).startsWith("f_")
                        ? (columnKey as string).slice("f_".length)
                        : columnKey
                    : null,
            s_direction: !!order ? changeSortDirectionTitle(order) : null,
        })

        handleUsersTableChange(pagination, filters, sorter as SorterResult<IClient>)
    }

    useEffect(() => {
        let cleanupFunction = false;
        const fetchData = async () => {
            setInit(true)
            await getClientsTypes({});
            await getCoachesFilterOptions()
            initUsersTableFilters({
                ...initialUsers,
            })
            if (!cleanupFunction) {
                setInit(false)
            }
        }
        fetchData();
        return () => {
            cleanupFunction = true
            usersApiCancel()
        }

    }, []);

    if(isInit){
        return null;
    }

    return (
        <UserTable 
        onChange={handleTableChange} 
        getUsers={getUsers} 
        getUsersColumns={getUsersColumns} 
        onSelectUsersChange={onSelectUsersChange}
        />
    )
}

export default UserTableWrapper