import usePersistedState from "../../../hooks/usePersistedState";
import {DATE_FORMAT, LAST_MONTH, LAST_WEEK, THIS_WEEK} from "../../../vars";
import {useEffect, useState} from "react";
import {INutritionSummary, setNutritionSummaryData} from "../../../redux/reducers/userReducer";
import moment from "moment";
import {RadioChangeEvent, Tag, Typography} from "antd";
import { useAppDispatch, useAppSelector } from "redux/store";
import { DateRange } from "Types";
import useApi from "hooks/useApi";
import { ColumnType } from "antd/lib/table";

const useNutritionSummaryService = () => {
    const {API} = useApi();
    const dispatch = useAppDispatch();
    const user = useAppSelector((state) => state.user.userData);
    const nutritionSummaryData = useAppSelector((state) => state.user.nutritionSummaryData);
    const [isLoading, setIsLoading] = useState(true);
    const [{dateRange}, setDateRange] = usePersistedState<{dateRange: DateRange}>(
        'nutritionSummary',
        {
            dateRange: THIS_WEEK
        }
    );

    const getDateRange = (dateRange: DateRange) => {

        if(dateRange === THIS_WEEK){
            return {
                f_dateAfter: moment().startOf('week').format(DATE_FORMAT),
                f_dateBefore: moment().endOf('week').format(DATE_FORMAT)
            };
        }
        if(dateRange === LAST_MONTH){
            return {
                f_dateAfter: moment().subtract(1, 'months').startOf('month').format(DATE_FORMAT),
                f_dateBefore: moment().subtract(1, 'months').endOf('month').format(DATE_FORMAT)
            };
        }
        if(dateRange === LAST_WEEK){
           if(+moment().day === 7){
               return {
                   f_dateAfter: moment().subtract(1, 'weeks').startOf('week').day(+7).format(DATE_FORMAT),
                   f_dateBefore: moment().startOf('week').day(+6).format(DATE_FORMAT)
               };
           }
           return {
               f_dateAfter: moment().subtract(2, 'weeks').startOf('week').day(+7).format(DATE_FORMAT),
               f_dateBefore: moment().subtract(1, 'weeks').startOf('week').day(+6).format(DATE_FORMAT),
           }
        }
    }

    const onChangeDateRange = ({target: {value}}: RadioChangeEvent) => {
        setDateRange({dateRange: value});
    }

    const getGoalValue = (value: number,goal: number) => {
            const sum = value - goal;
        if(!value || value === 0 || sum === 0 || goal === null){
                return ''
        }
        if(sum > 0){
            return <Tag style={{marginLeft: 10}} color="red">(-{sum})</Tag>
        }
        if(sum < 0 || sum === 0 || value === 0){
            return <Tag style={{marginLeft: 10}} color="green">(+{sum * -1})</Tag>
        }

    }

    const Columns: ColumnType<INutritionSummary>[] = [
        {
            title: "Date",
            dataIndex: "date",
            key: "date",
            sorter: (a,b) => moment(a.date).unix() - moment(b.date).unix(),
        },
        {
            title: "Calories",
            key: "calories",
            dataIndex: ["nutritional_contents", "energy"],
            render: (r,v) => <>
                {r}
                {v.goalCalories || v.goalCalories === 0 ? getGoalValue(r,v.goalCalories) : null}
            </>
        },
        {
            title: "Protein",
            key: "protein",
            dataIndex: ["nutritional_contents", "protein"],
            render: (r,v) => <>
                <Typography.Text>{r}</Typography.Text>
                {v.goalProtein || v.goalProtein === 0 ? getGoalValue(r,v.goalProtein) : null}
            </>
        },
        {
            title: "Fat",
            key: "fat",
            dataIndex: ["nutritional_contents", "fat"],
            render: (r,v) => <>
                {r}
                {v.goalFat || v.goalFat === 0 ? getGoalValue(r,v.goalFat) : null}
            </>


        },
        {
            title: "Carbs",
            key: "carbs",
            dataIndex: ["nutritional_contents", "carbohydrates"],
            render: (r,v) => <>
                {r}
                {v.goalCarbs || v.goalCarbs === 0 ? getGoalValue(r,v.goalCarbs) : null}
            </>

        },
    ]


    // const getDifference = (prevSum, currSum) => {
    //     const sum = currSum - prevSum;
    //     if(sum < 0){
    //         return `(${sum})`
    //     }
    //     if(sum > 0){
    //         return `(+${sum})`
    //     }
    //     return ''
    // }

    // const getDifferenceCalories = (prevData,currData) => `${currData.nutritional_contents.energy} ${prevData.nutritional_contents.energy > 0 ? getDifference(prevData.nutritional_contents.energy, currData.nutritional_contents.energy) : ''}`
    // const getDifferenceFat = (prev, curr) => `${curr.nutritional_contents.fat} ${prev.nutritional_contents.fat > 0 ? getDifference(prev.nutritional_contents.fat, curr.nutritional_contents.fat) : ''}`;
    // const getDifferenceCarbs = (prev, curr) => `${curr.nutritional_contents.carbohydrates} ${prev.nutritional_contents.carbohydrates > 0 ? getDifference(prev.nutritional_contents.carbohydrates, curr.nutritional_contents.carbohydrates) : ''}`;
    // const getDifferenceProtein = (prev, curr) => `${curr.nutritional_contents.protein} ${prev.nutritional_contents.protein > 0 ? getDifference(prev.nutritional_contents.protein, curr.nutritional_contents.protein) : ''}`;

    const getNutrition = async () => {
        try {
            setIsLoading(true)
            const response = await API.get<INutritionSummary[]>(`api/mfp/diary/${user?.id}`, {
                params: {
                   ...getDateRange(dateRange)
                }
            });

            const initialNutrition = {
                protein: 0,
                energy: 0,
                carbohydrates: 0,
                fat: 0
            }

            const nutritionSummary = response.data.map(({date,data, goalFat, goalCalories, goalProtein, goalCarbs}) => data.length > 0 ? data.reduce((prev,curr) => {
                return {
                    ...prev,
                    date,
                    goalFat,
                    goalCalories,
                    goalProtein,
                    goalCarbs,
                    nutritional_contents: {
                        carbohydrates: curr.nutritional_contents.carbohydrates + prev.nutritional_contents.carbohydrates,
                        fat:  curr.nutritional_contents.fat + prev.nutritional_contents.fat,
                        protein: curr.nutritional_contents.protein + prev.nutritional_contents.protein,
                        energy:  curr.nutritional_contents.energy.value + prev.nutritional_contents.energy
                    }
                }
            }, {
                nutritional_contents: initialNutrition
            }) : {date, goalFat, goalCalories, goalProtein, goalCarbs, nutritional_contents: initialNutrition});


            // const getNutritionSummaryWithDifferenceCalories = nutritionSummary.map((el, index) => ({
            //     ...el,
            //     nutritional_contents: {
            //         ...el.nutritional_contents,
            //         fat: index > 0 && el.nutritional_contents.fat > 0 ? getDifferenceFat(nutritionSummary[index-1], nutritionSummary[index]) : el.nutritional_contents.fat,
            //         carbohydrates: index > 0 && el.nutritional_contents.carbohydrates > 0 ? getDifferenceCarbs(nutritionSummary[index - 1], nutritionSummary[index]) : el.nutritional_contents.carbohydrates,
            //         protein: index > 0 && el.nutritional_contents.protein > 0 ? getDifferenceProtein(nutritionSummary[index-1], nutritionSummary[index]) : el.nutritional_contents.protein,
            //         energy: index > 0 && el.nutritional_contents.energy > 0 ? getDifferenceCalories(nutritionSummary[index - 1], nutritionSummary[index]) : el.nutritional_contents.energy
            //     }
            // }))

            const getFilteredNutritionSummary = nutritionSummary.filter(({nutritional_contents}) => Object.values(nutritional_contents).every(it => it !== 0));

           dispatch(setNutritionSummaryData(getFilteredNutritionSummary));
            setIsLoading(false);
        }catch (e) {
            if(e instanceof Error){
                console.log(e.message);
            }
            setIsLoading(false);
        }
    }

    useEffect(() => {
        getNutrition();
    }, [dateRange])

    return {
        isLoading,
        Columns,
        nutritionSummaryData,
        dateRange,
        onChangeDateRange,
    }
};


export default useNutritionSummaryService;