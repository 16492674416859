import React, {FC, useEffect, useState} from 'react';
import {FETCH_CHAT_PAGE_SIZE, ONE_DAYS_MORE_LATE, TWO_DAYS_MORE_LATE} from "../../../../../vars";
import {ConversationList} from "@chatscope/chat-ui-kit-react";
import Conversation from "../Conversation";
import { IChat } from 'Interfaces/Chat';
import { ResponseTypeChat } from 'Types';
import { ILateListProps } from './useLateConversationApi';

export interface Props extends ILateListProps {
    currentUser: IChat | null,
    responseType: ResponseTypeChat | null,
    onSetCurrentUser: (user: IChat) => void,
}

const ConversationListLate: FC<Props> = ({
    currentUser, 
    responseType, 
    getLateConversationList, 
    putUnreadUserMessages,
    updateReadCount,
    onSetCurrentUser,
    isLoading, 
    getMoreLateConversationList, 
    totalCount, 
    chatListLate
}) => {
    const [page, setPage] = useState(1);
    const [isLoadingMore, setIsLoadingMore] = useState(false);


    const onYReachEnd = () => {
        return setIsLoadingMore(true);
    }

    const getMoreLoadPage = async () => {
        await getMoreLateConversationList({
            page: page + 1,
            pageSize: FETCH_CHAT_PAGE_SIZE,
            withoutAnswerPerDay: responseType === ONE_DAYS_MORE_LATE,
            withoutAnswerPerTwoDays: responseType === TWO_DAYS_MORE_LATE,
        });
        setTimeout(() => {
            setIsLoadingMore(false);
            setPage(p => p + 1);
        }, 500);
    }

    useEffect(() => {
        if(isLoadingMore && totalCount !== chatListLate.length && !isLoading){
            getMoreLoadPage();
        }
    }, [isLoadingMore]);

    useEffect(() => {
        getLateConversationList({
            page: 1,
            pageSize: FETCH_CHAT_PAGE_SIZE,
            withoutAnswerPerDay: responseType === ONE_DAYS_MORE_LATE,
            withoutAnswerPerTwoDays: responseType === TWO_DAYS_MORE_LATE,
        },true)
        setIsLoadingMore(false);
        setPage(1);
    }, [responseType]);

    return (
        <ConversationList
        loading={isLoading}
        loadingMore={totalCount !== chatListLate.length ? isLoadingMore : false}
        scrollable
        onYReachEnd={onYReachEnd}
        >
            {chatListLate?.map((user) => (
                <Conversation
                key={user.userId}
                user={user}
                currentUser={currentUser}
                putUnreadUserMessages={putUnreadUserMessages}
                onSetCurrentUser={onSetCurrentUser}
                updateReadCount={updateReadCount}
                />
            ))}
        </ConversationList>
    )


};

export default ConversationListLate