import {useEffect} from "react";
import { useAppDispatch, useAppSelector } from "redux/store";
import useApi from "../hooks/useApi";
import {Logger} from "utils/helpers";
import {
    questionnaireReducerReset,
    setQuestionnaireData,
    setQuizAnswersData
} from "redux/reducers/questionnaireReducer";
import { IUser } from "Interfaces/User";
import { IQuestionaire, IQuestionaire_Answer } from "Interfaces/Questionaire";

const logger = Logger("QuestionnaireApiWithRedux")

function useQuestionnaireApiWithRedux() {
    const {API, cancel} = useApi()
    const dispatch = useAppDispatch()
    const user = useAppSelector(state => state.user.userData) as IUser

    const getQuestionnaireData = async () => {
        try {
            const response = await API.get<IQuestionaire[]>("api/quiz");
            return response.data;
        } catch (e) {
            if(e instanceof Error){
                logger.error(e.message);
            }
        }
    }

    const getQuestionnaireAnswerData = async (key: string) => {
        const params = {quizid: key, userid: user.id};
        try {
            const response = await API.get<IQuestionaire_Answer[]>("api/answer", {params});
            return response.data;
        } catch (e) {
            if(e instanceof Error){
                logger.error(e.message);
            }
        }
    }

    const getQuestionnaireAnswer = async (key: string) => {
        const quizAnswersData = await getQuestionnaireAnswerData(key);
        if (Array.isArray(quizAnswersData)) {
            dispatch(setQuizAnswersData({quizAnswersData}));
        }
    }


    const getQuestionnaire = async () => {
        const questionnaireData = await getQuestionnaireData()
        if (Array.isArray(questionnaireData)) {
            dispatch(setQuestionnaireData({questionnaireData}))
            if (questionnaireData.length > 0) {
                await getQuestionnaireAnswer(questionnaireData[0]?.id)
            }
        }
    }

    useEffect(() => () => {dispatch(questionnaireReducerReset())}, [])

    return {
        questionnaireApiCancel:cancel,
        getQuestionnaireAnswer,
        getQuestionnaire
    }
}

export default useQuestionnaireApiWithRedux;