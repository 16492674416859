import React, { useEffect, useState } from 'react';
import { Button, Form, Input, PageHeader, Space, Table, Tag, Popover } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import withPermission from 'HOCs/withPermission';
import withSidebarNavigation from 'HOCs/withSidebarNavigation';
import NotFoundPage from 'pages/not-found/NotFoundPage';
import { ICategoryForm, IFullCategory } from 'Interfaces/TasksCategories';
import { EditOutlined, DeleteOutlined, BookOutlined } from '@ant-design/icons';
import useModals from 'services/modals-service';
import AddCategoryFormModal from './AddCategoryForm';
import EditCategoryFormModal from './EditCategoryForm';
import useTasksCategories from 'services/tasksCategories-service';
import { getColorByHex, sortNullableStrings } from 'utils/helpers';
import RemoveCategoryModal from './RemoveCategoryModal';
import antNotificationComponent from 'utils/notification-ant-utils';
import { admin, colorsHexCategoriesOptions } from 'vars';
import { useAppSelector } from 'redux/store';

const Categories = () => {
    const { 
        categories, 
        handleSetCurrentCategory, 
        handleRemoveCurrentCategory, 
        handleSearchCategories, 
        onUpdateCategories,
        onUpdateCategory,
        onCreateCategory,
        setCategoriesData,
        updateCategories
    } = useTasksCategories();
    const { setAddCategoryVisible, setEditCategoryVisible, setRemoveCategoryVisible } = useModals();
    const authUserRole = useAppSelector((state) => state.auth.user?.roles);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [addForm] = Form.useForm();
    const [editForm] = Form.useForm();

    const renderActive: ColumnProps<IFullCategory>['render'] = (archived) => 
    archived ? <Tag color="red">DISABLED</Tag> : <Tag color="green">ACTIVE</Tag>

    const renderColor: ColumnProps<IFullCategory>['render'] = (color) => <Tag title={color} color={color}>{getColorByHex(color)}</Tag> 

    const renderActions: ColumnProps<IFullCategory>['render'] = (_, category) => {
        const onEditCategory = () => {
            setEditCategoryVisible(true);
            handleSetCurrentCategory(category)
        };
        const onRemoveCategory = () => {
            setRemoveCategoryVisible(true);
            handleSetCurrentCategory(category);
        };
        return (
            <Space>
                <Button type="primary" shape="circle" onClick={onEditCategory} icon={<EditOutlined/>}/>
                <Popover
                    content={!!category.hasTasks ? 'This category has assigned tasks. You can still archive it' : 'Delete task category'}
                    placement="topRight"
                >
                    <Button type="primary" shape="circle" danger disabled={!!category.hasTasks} onClick={onRemoveCategory} icon={<DeleteOutlined/>}/>
                </Popover>
            </Space>
        )
    };

    const filtersColorsOptions: ColumnProps<IFullCategory>['filters'] = colorsHexCategoriesOptions.map(({value, label}) => ({
        value,
        text: label
    }));


    const filtersStatusOptions: ColumnProps<IFullCategory>['filters'] = [
        {
            text: "ACTIVE",
            value: true
        },
        {
            text: "DISABLED",
            value: false
        }
    ] 

    const columns: ColumnProps<IFullCategory>[] = [
        {
            title: "Name",
            dataIndex: "name",
            sorter: (a, b) => a.name.localeCompare(b.name)
        },
        {
            title: "Short Name",
            dataIndex: "shortName",
            sorter: (a, b) => a.shortName.localeCompare(b.shortName)
        },
        {
            title: "Description",
            dataIndex: "description",
            sorter: (a, b) => sortNullableStrings(a.description, b.description)
        },
        {
            title: "Color",
            dataIndex: "color",
            render: renderColor,
            filters: filtersColorsOptions,
            onFilter: (value, category) => category.color.indexOf(value as string) === 0
        },
        {
            title: "Status",
            dataIndex: "archived",
            render: renderActive,
            filters: filtersStatusOptions,
            onFilter: (value, category) => category.archived !== value
        },
    ];

    const columnActions: ColumnProps<IFullCategory>[] =  [{
        title: "Actions",
        fixed: "right",
        width: 150,
        render: renderActions,
    }];

    const columnsAdmin = [...columns, ...columnActions];


    const extraHeader = (
        <Space>
            {authUserRole === admin && <Button onClick={() => setAddCategoryVisible(true)} type="primary">New</Button>}
            <Input.Search onSearch={handleSearchCategories} placeholder="Search" enterButton />
        </Space>
    );

    
    
    const onCancelEditForm = () => {
        editForm.resetFields();
        handleSetCurrentCategory(null);
        setEditCategoryVisible(false);
    };
    
    const onCancelAddForm = () => {
        setAddCategoryVisible(false);
        addForm.resetFields();
    };
    
    const onCancelRemoveCategory = () => {
        handleSetCurrentCategory(null);
        setRemoveCategoryVisible(false);
    };
    
    const onFinishEditForm = async (formData: ICategoryForm): Promise<void> => {
        const response = await onUpdateCategory(formData);
        if(response){
            onUpdateCategories();
            onCancelEditForm();
            antNotificationComponent({ type: "success", message: "Saved" });
        }
    };
    const onFinishAddForm = async (formData: ICategoryForm): Promise<void> => {
        const response = await onCreateCategory(formData);
        if(response){
            onUpdateCategories();
            onCancelAddForm();
            antNotificationComponent({ type: "success", message: "Saved" });
        };
    }; 

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            await setCategoriesData();
            setIsLoading(false);
        };
        fetchData();
    }, [updateCategories])

return (
    <Space className="page_wrapper_style" direction="vertical">
        <PageHeader className="site-layout-background" onBack={() => window.history.back()} title="Categories" extra={extraHeader} />
        <Table
            loading={isLoading}
            dataSource={categories}
            columns={authUserRole === admin ? columnsAdmin : columns}
        />
        <AddCategoryFormModal
            form={addForm}
            onCancel={onCancelAddForm}
            onFinish={onFinishAddForm}
        />

        <EditCategoryFormModal
            form={editForm}
            onCancel={onCancelEditForm}
            onFinish={onFinishEditForm}
        />
        <RemoveCategoryModal 
            onCancel={onCancelRemoveCategory}
            onFinish={handleRemoveCurrentCategory}
        />
    </Space>
)
};

export default withPermission(withSidebarNavigation(Categories, '/categories'), NotFoundPage);