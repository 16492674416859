import React from 'react';
import {PageHeader, Space} from "antd";
import { useAppSelector } from 'redux/store';
import DateRangeButtonGroup from "./DateRangeButtonGroup";
import DashboardPageHeaderSelect from "./DashboardPageHeaderSelect";
import withPermission from "HOCs/withPermission";
import {admin, manager} from "vars";
import { DateRange } from 'Types';
import { IDashboardQueryParams } from 'Interfaces/Dashboard';
import { Canceler } from 'axios';

export interface IDashboardPageHeaderProps {
    handleChangeDateRange: (value: DateRange) => void,
    handleChangeCoachId: (value: string) => void,
    initialDashboard: IDashboardQueryParams,
    setInitialDashboard: React.Dispatch<IDashboardQueryParams>,
    isLoading: boolean,
    getActiveCoaches: () => Promise<void>,
    candidatesApiCancel: Canceler
};

export default function DashboardPageHeader(props: IDashboardPageHeaderProps) {
    const {handleChangeDateRange, handleChangeCoachId,initialDashboard, setInitialDashboard} = props
    const dateRangeName = useAppSelector(state => state.dashboard.dateRangeName)
    const authUser = useAppSelector(state => state.auth.user!);

    const onChangeDateRange = (value: DateRange) => {
        setInitialDashboard({
            ...initialDashboard,
            dateRangeName: value
        })
        handleChangeDateRange(value)
    }


    const extraOptions = authUser.roles === manager ?
        [{label: '', value: ''}, {label: authUser.fullName, value: authUser.id}] :
        [{label: '', value: ''}];

    const HeaderItemTitle = ({text}: {text: React.ReactNode}) => <b>{text}</b>
    const CoachTitleWithPermission = (withPermission(HeaderItemTitle))

    const extras = (
        <Space size="large" align="end" className={'dashboard_header_space'}>
            <CoachTitleWithPermission text={'Coach:'} roles={[admin, manager]}/>
            <DashboardPageHeaderSelect 
                {...props}
                extraOptions={extraOptions}
                onChange={handleChangeCoachId}
                roles={[admin, manager]}
            />
            <HeaderItemTitle text={'Date Range:'}/>
            <DateRangeButtonGroup 
                value={dateRangeName} 
                onChange={(e)=>onChangeDateRange(e.target.value)}
                {...props}
            />
        </Space>
    )
    return (
        <PageHeader className="site-layout-background" title="Dashboard"
                    onBack={() => window.history.back()} extra={extras}/>
    )
}