import React, {useState} from 'react'
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import "../_phoneInput.less"
import {expPhone} from "../utils/helpers";

interface Props {
    value: string,
    onChange: (value: string) => void,
    required: boolean,
    mobileError: string,
    setMobileError: React.Dispatch<React.SetStateAction<string>>,
}

export default function PhoneInputComponent({ value, onChange, setMobileError, mobileError, required = false }: Props) {
    const [focused, setFocuses] = useState(false);

    const onBlur = () => {
        if (value && !expPhone.test(value)) {
            return setMobileError('Incorrect mobile format');
        } else {
            setFocuses(false);
            setMobileError('');
        }
    };

    const onFocus = () => {
        if (!mobileError) {
            setFocuses(true);
        }
        setFocuses(false);
    };

    const setClass = () => {
        if (focused && !mobileError) {
            return "success";
        }
        if (required && mobileError) {
            return "error";
        }
        return "default";
    };

    return (
        <div className={`ant-row ant-form-item ${mobileError && 'ant-form-item-with-help ant-form-item-has-error'}`}>
            <div className="ant-col ant-form-item-label">
                <label
                    className={required ? "ant-form-item-required" : ""}
                >Phone Number</label>
            </div>
            <PhoneInput
                value={value}
                onFocus={onFocus}
                // className="ant-form-item-control-input"
                inputClass={`${setClass()} ant-input`}
                onBlur={required ? onBlur : undefined}
                onChange={onChange}
            />
            {mobileError &&
            <div className="ant-form-item-explain ant-form-item-explain-error phone-number-error">
                <div role="alert">{mobileError}</div>
                <div role="alert" />
            </div>
            }
        </div>
    );
}
