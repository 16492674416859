import React from 'react';
import {Col, Radio, Row} from "antd";
import { SelectedTable } from 'Types';
import { RadioButtonProps } from 'antd/lib/radio/radioButton';

interface Props {
    list: {
        value: SelectedTable;
        text: string;
    }[],
    value: SelectedTable,
    onChange: RadioButtonProps['onChange']
};

export default function CoachTableSwitchRow({value, onChange, list = []}: Props){
    return (
        <Row className={'coach_main_body_row'}>
            <Col span={24} className={'coach_main_body_column_radio'}>
                <Radio.Group value={value} buttonStyle="solid" onChange={onChange}>
                    {
                        list.length > 0 && 
                        list.map(({value, text}) => 
                        <Radio.Button value={value}>{text}</Radio.Button>
                    )}    
                </Radio.Group>
            </Col>
        </Row>
    )
}