import React, {FC, useEffect, useRef} from 'react';
import "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import {
    MainContainer,
    Sidebar,
} from "@chatscope/chat-ui-kit-react";
import '../chat.css';
import ConversationList from "../components/Conversation/ConversationList";
import MessageList from "../components/Messages/_MessageList";
import Search from "../_Search";
import { ChatProps } from '../Chat-Desktop/ChatDesktop';




const ChatMobile: FC<ChatProps> = ({currentUser, ResponseRadioGroup, responseType, searchProps, contactProps, lateListProps}) => {
    const {onSearchUser__debounce, onClearSearch, searchList} = searchProps;
    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
        window.scroll(0, 0);
        document.querySelector<HTMLDivElement>('.layout')?.classList.add('mobile-layout');
        document.querySelector<HTMLDivElement>('.ant-layout')?.classList.add('mobile-layout');
        document.querySelector<HTMLDivElement>('.wrapper')?.classList.add('mobile-layout');
        document.querySelector<HTMLDivElement>('.ant-layout-content')?.classList.add('mobile-layout');
        document.body.style.overflow = 'hidden';
        return () => {
            document.querySelector<HTMLDivElement>('.layout')?.classList.remove('mobile-layout');
            document.querySelector<HTMLDivElement>('.wrapper')?.classList.remove('mobile-layout');
            document.querySelector<HTMLDivElement>('.ant-layout')?.classList.remove('mobile-layout');
            document.querySelector<HTMLDivElement>('.ant-layout-content')?.classList.remove('mobile-layout');
            document.body.style.overflow = 'auto';
        }

    }, [ref])

    return (
        <div
        className="chat-mobile"
        ref={ref}
        style={{
            position: 'relative',
            width: '100%',
            height: document.body.clientHeight - 75
        }}
        >
            <MainContainer
            >
                {
                    !currentUser ? (
                        <Sidebar 
                        style={{
                            maxWidth: '768px',
                            flexBasis: 'auto'
                        }}
                        position={'left'}
                        >
                        <div
                            className="chat-search"
                        >
                            {ResponseRadioGroup}
                            <Search
                                onSearchUser__debounce={onSearchUser__debounce}
                                onClearSearch={onClearSearch}
                            />
                        </div>
                        <ConversationList
                            responseType={responseType}
                            currentUser={currentUser}
                            searchProps={searchProps}
                            lateListProps={lateListProps}
                            contactProps={contactProps}
                        />
                        </Sidebar>
                    ) : <MessageList
                        searchList={searchList}
                        responseType={responseType}
                        currentUser={currentUser}
                    />
                }
            </MainContainer>
        </div>

    )
};

export default ChatMobile;