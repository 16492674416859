import React, { FC } from 'react';
import ConversationListChat, { ContactListProps } from "./ContactList/ConversationList_ChatList";
import ConversationListSearch from "./SearchList/_ConversationList_SearchList";
import ConversationListLate from "./LateList/ConversationListLate";
import { IChat } from 'Interfaces/Chat';
import { ResponseTypeChat } from 'Types';
import { ILateListProps } from './LateList/useLateConversationApi';
import { ISearchListProps } from './SearchList/useConversationApi_Search';

interface Props {
    currentUser: IChat | null,
    responseType: ResponseTypeChat | null,
    searchProps: ISearchListProps,
    contactProps: ContactListProps,
    lateListProps: ILateListProps
}

const ConversationList: FC<Props> = ({currentUser, responseType, searchProps, contactProps, lateListProps}) => {
    const {searchList, searchValue} = searchProps;
    const {onSetCurrentUser} = contactProps;
    const lateProps = {
        ...lateListProps,
        onSetCurrentUser,
    };

    return (
        <>
            {
                searchValue
                && <ConversationListSearch  {...searchProps} />
            }
            {
                !searchList.length && !searchValue && !responseType
                && <ConversationListChat {...contactProps} currentUser={currentUser} />
            }
            {
                responseType && !searchValue && !searchList.length
                && <ConversationListLate {...lateProps} responseType={responseType} currentUser={currentUser} />
            }
        </>
    )

};


export default ConversationList;