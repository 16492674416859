import React from "react";
import {Col, Row} from "antd";
import {CHECKIN, CLIENT, COACH} from "../../vars";
import CoachMainUsersTable from "./CoachMainUsersTable";
import CoachMainCheckInsTable from "./CoachMainCheckInsTable";
import ManagerMainCoachList from "pages/managers/manager/ManagerMainCoachList";
import { SelectedTable } from "Types";

interface Props {
    selectedTable: SelectedTable
}


export default function CoachMainSwitchList({ selectedTable }: Props) {
    return (
        <Row className={'coach_main_body_row'}>
            <Col span={24}>
                {
                    selectedTable === CLIENT && <CoachMainUsersTable/> 
                }
                {
                    selectedTable === CHECKIN &&  <CoachMainCheckInsTable/> 
                }
                {
                    selectedTable === COACH && <ManagerMainCoachList />
                }
            </Col>
        </Row>
    )
}