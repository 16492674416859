import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IUser } from 'Interfaces/User';
import { FixMeLater } from 'Types';
import {checkAuth, logout} from "../../services/authService"

type InitalState =  {
    user: IUser | null,
    prevLocation: string,
    isLoading: boolean,
    isAuth: boolean,
}

const initialState: InitalState = {
    user: null,
    prevLocation: '',
    isLoading: true,
    isAuth: false
};

const crudSlice = createSlice({
    name: 'authReducer',
    initialState: initialState,
    reducers: {},
    extraReducers: {
        [checkAuth?.pending.type]: (state) => {
            state.isLoading = true
        },
        [checkAuth?.fulfilled.type]: (state, action) => {
            state.user = action.payload.user
            state.prevLocation = action.payload.prevLocation
            state.isLoading = false
            state.isAuth = true
        },
        [logout?.pending.type]: (state) => {
            state.isLoading = true
        },
        [logout?.fulfilled.type] : (state) => {
            state.user = null
            state.prevLocation = ''
            state.isLoading = false
            state.isAuth = false
        },
    },
});




export default crudSlice.reducer
