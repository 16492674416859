import React, {useEffect, useState} from "react";
import { useAppSelector } from "redux/store";
import {FormInstance, ModalForm, ProFormSelect, ProFormSwitch, ProFormText} from "@ant-design/pro-form";
import {
    expPhone,
    validateConfirmField,
    validateEmail,
    validatePassword, 
    validateRequired
} from "utils/helpers";
import {manager} from "vars";
import 'react-phone-input-2/lib/bootstrap.css'
import PhoneInputComponent from "components/PhoneInputComponent";
import { IUserForm } from "services/users-service";
import { IUserFormSubmit } from "../user";
import { ICoach } from "Interfaces/User";
import useApi from "hooks/useApi";
import { IClientType } from "Interfaces/ClientType";

interface IAddUserForm {
    form: FormInstance<Omit<IUserForm, 'phoneNumber'>>
    visible: boolean,
    modalProps: {
        onOk: (data: IUserFormSubmit) => Promise<void>,
        onCancel:  () => void,
    }
    title: string,
}    

export default function AddUserForm({form, visible, modalProps, title}: IAddUserForm): JSX.Element {
    const {API} = useApi();
    const authUser = useAppSelector((state) => state.auth.user);
    const {onOk, onCancel} = modalProps
    const [mobileValue, setMobileValue] = useState('');
    const [mobileError, setMobileError] = useState('');

    useEffect(() => {
        setMobileValue('')
    }, [form,visible])

    return (
        <ModalForm form={form}
                   onFinishFailed={() => {
                       if(mobileValue && !expPhone.test(mobileValue)){
                           return setMobileError('Incorrect mobile format')
                       }
                       setMobileError('');
                   }}
                   visible={visible} 
                   title={title} 
                   onFinish={async (values) => {
            if(mobileValue && !expPhone.test(mobileValue)){
                return setMobileError('Incorrect mobile format')
            }
            setMobileError('');
            const data = {...values, phoneNumber: mobileValue || null};
            onOk(data)
            }} 
            onReset={onCancel}
            modalProps={{
                onCancel
            }}
        >
            <ProFormText name="firstName" validateTrigger={['onBlur']} label="First Name" placeholder="First Name" rules={[() => ({
                async validator(_, value) {
                    await validateRequired(value)
                    return Promise.resolve(value);
                },
            }), {required: true, message: ''}]}/>
            <ProFormText name="lastName" label="Last Name" validateTrigger={['onBlur']} placeholder="Last Name" rules={[() => ({
                async validator(_, value) {
                    await validateRequired(value)
                    return Promise.resolve(value);
                },
            }), {required: true, message: ''}]}/>
            <ProFormText validateTrigger={['onBlur']}  name="email" label="Email" placeholder="Email"
                         rules={[() => ({
                             async validator(_, value) {
                                 await validateRequired(value)
                                 await validateEmail(value)
                                 return Promise.resolve(value);
                             },
                         }), {required: true, message: ''}]}
            />
            <PhoneInputComponent
                required={false}
                value={mobileValue}
                setMobileError={setMobileError}
                mobileError={mobileError}
                onChange={setMobileValue}
            />
            <ProFormSelect
                request={async () => {
                    const response = await API.get<IClientType[]>('api/client-type', {params: {f_enabled: [true]}})
                    const clientTypes = response.data;
                    return clientTypes.map(type => {
                        return {label: type.name, value: type.id}
                    })
                }}
                rules={[() => ({
                    async validator(_, value) {
                        await validateRequired(value)
                        return Promise.resolve(value);
                    },
                }), {required: true, message: ''}]}
                name="clientType"
                label="Client Type"
                placeholder="Client Type"
            />
            <ProFormText.Password validateTrigger={['onBlur']} name="password" label="Password" placeholder="Password" rules={[() => ({
                async validator(_, value) {
                    await validateRequired(value)
                    await validatePassword(value)
                    return Promise.resolve(value);
                },
            }), {required: true, message: ''}]} hasFeedback/>
            <ProFormText.Password validateTrigger={['onBlur']} name="confirmPassword" label="Confirm Password" placeholder="Confirm Password"
                                  dependencies={['password']} hasFeedback rules={[
                {
                    required: true,
                    message: 'This field is required',
                },
                ({getFieldValue}) => ({
                    async validator(_, value) {
                        await validateConfirmField(value, getFieldValue('password'))
                        return Promise.resolve(value);
                    },
                }),
            ]}/>
            <ProFormSelect
            request={async () => {
                if(authUser?.roles === manager){
                    const response = await API.get<{users: ICoach[]}>('/api/user', {
                        params: {
                            f_roles: 'coach',
                            f_managerId: authUser.id
                        }
                    })
                    const users = response.data.users;
                    return [{label: authUser.fullName, value: authUser.id}, ...users.map((coach) => ({
                        label: coach.fullName,
                        value: coach.id
                    }))];
                }
                const response = await API.get<ICoach[]>('/api/user/coach-candidates');
                return response.data.map((coach) => ({
                    label: coach.fullName,
                    value: coach.id
                }));
            }}
            name="coachId"
            label="Assign Coach"
            placeholder="Assign Coach"
            rules={[() => ({
                async validator(_, value) {
                    await validateRequired(value)
                    return Promise.resolve(value);
                },
            }), {required: true, message: ''}]}
            />
            <ProFormSwitch name="disabled" label="Disabled"/>
            <ProFormSwitch name="competing" label="Competing"/>
            <ProFormSwitch name="testAccount" label="Test Account"/>
        </ModalForm>
    )
}