import React, {FC, useEffect, useRef} from 'react';
import {
    MainContainer,
    Sidebar,
} from "@chatscope/chat-ui-kit-react";
import "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import MessageList from "../components/Messages/_MessageList";
import ConversationList from "../components/Conversation/ConversationList";
import Search from "../_Search";
import { IChat } from 'Interfaces/Chat';
import { ResponseTypeChat } from 'Types';
import { ContactListProps } from '../components/Conversation/ContactList/ConversationList_ChatList';
import { ILateListProps } from '../components/Conversation/LateList/useLateConversationApi';

export interface ChatProps {
    currentUser: IChat | null,
    responseType: ResponseTypeChat | null,
    searchProps: {
        onSearchUser__debounce: (...args: any[]) => void,
        onClearSearch: () => void,
        searchList: IChat[],
        onSetCurrentUser: (user: IChat) => void,
        putUnreadUserMessages: (user: IChat) => Promise<void>,
        updateReadCount: (actionMenuUser: IChat) => Promise<void>,
        isLoading: boolean,
        searchValue: string,
        currentUser: IChat | null,
        setSearchValue: React.Dispatch<React.SetStateAction<string>>
    },
    ResponseRadioGroup: JSX.Element,
    contactProps: ContactListProps,
    lateListProps: ILateListProps
}

const ChatDesktop: FC<ChatProps> = ({currentUser, responseType, ResponseRadioGroup, searchProps, contactProps, lateListProps}) => {
    const {onSearchUser__debounce, onClearSearch, searchList} = searchProps;
    const ref = useRef<HTMLDivElement>(null);
    useEffect(() => {
        window.scroll(0, 0);
        document.querySelector<HTMLDivElement>('.layout')!.style.minHeight = '0px';
        document.querySelector<HTMLDivElement>('.wrapper')!.style.minHeight = '0px';
        return () => {
            document.querySelector<HTMLDivElement>('.layout')!.style.minHeight = '100vh';
            document.querySelector<HTMLDivElement>('.wrapper')!.style.minHeight = '100vh';
        }
    }, [ref])


    return (
        <div
            className="chat-desktop"
            ref={ref}
            style={{
                position: "relative",
                height: '90vh',
                width: '100%',
            }}
        >
            <MainContainer>
                <Sidebar position="left">
                    <div
                    className="chat-search"
                    >
                        {ResponseRadioGroup}
                        <Search
                            onSearchUser__debounce={onSearchUser__debounce}
                            onClearSearch={onClearSearch}
                        />
                    </div>
                    <ConversationList
                        responseType={responseType}
                        currentUser={currentUser}
                        searchProps={searchProps}
                        contactProps={contactProps}
                        lateListProps={lateListProps}
                    />
                </Sidebar>
                {
                    currentUser &&
                    <MessageList
                    searchList={searchList}
                    responseType={responseType}
                    currentUser={currentUser}
                    />
                }
            </MainContainer>
        </div>
    )
};



export default ChatDesktop;