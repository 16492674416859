import PropTypes from 'prop-types';
import {notification} from "antd";
import React from 'react';
import { IconType, NotificationPlacement } from 'antd/lib/notification';

interface Props {
    type?: IconType,
    message?: React.ReactNode,
    description?: string,
    duration?: number,
    placement?: NotificationPlacement,
    key?: string | undefined,
    destroy?: boolean | undefined,
    icon?: React.ReactSVGElement | null
}

function antNotificationComponent({
                                      type = 'success',
                                      message = type[0].toUpperCase() + type.slice(1),
                                      description,
                                      duration,
                                      placement,
                                      destroy,
                                      icon,
                                      key,
                                  }: Props) {
    if (destroy) {
        notification.destroy()
    }
    notification[type]({
        message, description, duration, placement,key,
        style: {}
    });
}

antNotificationComponent.defaultProps = {
    type: "success",
    message: 'Saved',
    description: "Message",
    duration: 2000,
    placement: "topRight",
};

antNotificationComponent.propTypes = {
    type: PropTypes.string,
    description: PropTypes.string,
    duration: PropTypes.number,
    placement: PropTypes.string,
    destroy: PropTypes.bool
}

export const antNotificationClose = (key: string)=>notification.close(key)

export const dataNotification = (data: any, success?: string, error?: string): void => {
    !!data ?
        antNotificationComponent({type: 'success', message: success || "Saved"}) :
        antNotificationComponent({type: 'error', message:  error || "Failed to save"})
}

export default antNotificationComponent