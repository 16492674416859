import React, {useState, useEffect} from 'react';
import { useAppSelector } from 'redux/store';
import {Table, TableProps, Tabs } from 'antd';
import { IQuestionaire_Answer } from 'Interfaces/Questionaire';
import './styles/questionnaire.css'
import { Canceler } from 'axios';
import { ColumnProps, ColumnsType } from 'antd/lib/table';

const {TabPane} = Tabs;

export interface IQuestionnaireListProps {
    questionnaireApiCancel: Canceler,
    getQuestionnaire: () => Promise<void>,
    getQuestionnaireAnswer: (key: string) => Promise<void>
};

export default function QuestionnaireList({questionnaireApiCancel, getQuestionnaire, getQuestionnaireAnswer}: IQuestionnaireListProps) {
    const questionnaireData = useAppSelector(state => state.questionnaire.questionnaireData)
    const quizAnswersData = useAppSelector(state => state.questionnaire.quizAnswersData)

    const [loading, setLoading] = useState<boolean>(true)

    const setTableRowClassName: TableProps<IQuestionaire_Answer>['rowClassName'] = (_, index) => {
        return index % 2 !== 0 ? 'questionnaire_table_row_background' : ''
    }

    const render: ColumnProps<IQuestionaire_Answer>['render'] = (data) => data ? data : null;  

    const columns: ColumnsType<IQuestionaire_Answer> = [
        {
            title: 'Question',
            dataIndex: 'question',
            render
        },
        {
            title: 'User Answer',
            dataIndex: 'userAnswer',
            render
        }
    ];


    useEffect(() => {
        let cleanupFunction = false;
        const fetchData = async () => {
            setLoading(true)
            await getQuestionnaire()

            if (!cleanupFunction) {
                setLoading(false)
            }
        }
        fetchData();

        return () => {
            questionnaireApiCancel()
            cleanupFunction = false;
        }
    }, []);

    return (
            <Tabs onTabClick={getQuestionnaireAnswer} tabPosition={'left'}>
                {questionnaireData.map((question) => (<TabPane tab={question.name} key={question.id}>
                    <Table 
                        className="questionnaire_table_hover_rows pre_line_text" 
                        rowClassName={setTableRowClassName}
                        scroll={{x: 'auto'}} 
                        dataSource={quizAnswersData} 
                        columns={columns}
                        pagination={false} 
                        loading={loading}
                        rowKey={(answer) => answer.id} 
                    />
                </TabPane>))}
            </Tabs>
    )
}