import React from 'react';
import { ProFormSelect } from '@ant-design/pro-form';
import { ProFormSelectProps } from '@ant-design/pro-form/lib/components/Select';
import { colorsHexCategoriesOptions } from 'vars';

const ColorCascader = (props: ProFormSelectProps) => {

    return (
        <ProFormSelect
            {...props}
            options={colorsHexCategoriesOptions}
        />
    )
};

export default ColorCascader