import React, { FC } from 'react';
import { DatePicker, Form } from 'antd';
import antNotificationComponent from 'utils/notification-ant-utils';
import { useAppSelector } from 'redux/store';
import { ModalForm, ProFormText } from '@ant-design/pro-form';
import { DATE_FORMAT_HOURS_MINUTES } from 'vars';
import moment from 'moment';

export interface IPauseNotificationForm {
  reasonMessage: string,
  blockUntil: string,
}
interface IPauseNotificationsModalProps {
  onCancel: () => void,
  initialData?: IPauseNotificationForm | null,
  handleDisableNotification: (data: IPauseNotificationForm) => Promise<{userId: string, blockUntil: string, reasonMessage: string} | undefined>,
  clientFullName: string,
}

const PauseNotificationsModal: FC<IPauseNotificationsModalProps> = ({ handleDisableNotification, onCancel, initialData, clientFullName}) => {
  const visible = useAppSelector(state => state.modals.pauseNotificationsModalVisible)
  const [form] = Form.useForm<IPauseNotificationForm>();

  const onPauseNotificationsConfirm = async () => {
    const {reasonMessage, blockUntil} = form.getFieldsValue();
    const data = await handleDisableNotification({reasonMessage: reasonMessage?.trim() || "", blockUntil})

    if (!!data) {
      onCancel()
      antNotificationComponent({message: "Notifications paused", type: 'success'})
    } else {
      antNotificationComponent({message: "Failed to pause notifications", type: 'error'})
    }
  }
    return (
      <ModalForm
        visible={visible}
        onFinish={onPauseNotificationsConfirm}
        width={600}
        modalProps={{ onCancel }}
        form={form}
        onReset={onCancel}
        title={`Pause notifications for ${clientFullName}`}
        initialValues={initialData ?? { blockUntil: moment().add({ days: 7 }) }}
      >
        <Form.Item 
          label="Pause until"
          name="blockUntil"
          tooltip="Choose the date you want to pause notifications until"
          rules={[{ required: true, message: 'This field is required' }]}
        >
          <DatePicker
            style={{width: '100%'}}
            showTime={{ format: 'HH:mm' }}
            format={DATE_FORMAT_HOURS_MINUTES}
            allowClear={false}
          /> 
        </Form.Item>
        <ProFormText 
          label="Reason"
          name="reasonMessage"  
          placeholder="Reason"
          rules={[
            { max: 255, message: 'The reason cannot be longer than 255 symbols' },
          ]}
        />
    </ModalForm>
    )
}

export default PauseNotificationsModal;