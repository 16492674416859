import React from "react";
import FeedbackModal from "../checkin/feedbackModal";
import AssignCoachModal from "./assignCoachModal";
import RemoveCoachModal from "./removeCoachModal";
import ChangeGoalModal from "./ChangeGoalModal";
import AssignmentsHistoryPopup from "./AssignmentsHistoryPopup";
import AddNotesModal from "./AddNotesModal";
import MacroModal from "./macroModal";
import {admin, manager} from "vars";
import withPermission from "HOCs/withPermission";
import AverageCheckinsModal from "./AverageCheckinsModal";
import { IFeedback } from "Interfaces/Feedback";
import { IEditFeedback } from "services/feedback-service";
import { CurrentEvent, UserType } from "Types";
import { useAppSelector } from "redux/store";
import { ICheckIn } from "Interfaces/CheckIn";
import { ISuccessStatus } from "Interfaces/Auth";
import { AxiosResponse, Canceler } from "axios";
import { ISendFeedbackModal } from "../checkin/feedbackModal";
import { UserData } from "redux/reducers/userReducer";
import { IAssignmentHistory } from "Interfaces/User";
import { IClientNote } from "Interfaces/ClientNote";
import PauseNotificationsModal, { IPauseNotificationForm } from "./PauseNotificationsModal";
import AddTaksForm from "pages/tasks/AddTaskForm";
import EditTaskForm from "pages/tasks/EditTaskForm";
import { UseTasksCategoriesTypes } from "services/tasksCategories-service";
import DeleteTaskModal from "pages/tasks/DeleteTaskModal";
import {UseModalsProps} from 'services/modals-service'
interface Props {
    saveFeedback: (data: ISendFeedbackModal) => Promise<void | IFeedback | undefined>,
    setCurrentFeedbackDeleteClear: () => void,
    setUserActivity: (activityType: CurrentEvent) => Promise<void>,
    editFeedback: (data: IEditFeedback) => Promise<IFeedback | undefined>
    selectedRowData: React.Key[] | ICheckIn[]
    modalsProps: UseModalsProps,
    checkInsProps: {
        checkInTableUpdate: (callback?: () => void) => void
    },
    userProps: {
        updateUser: (id: string, data: UserType) => Promise<AxiosResponse<UserType> | undefined>,
        setCurrentEventWithCount: (currentEvent: string) => void,
        getUser: (id: string) => Promise<void>,
        getUserAssignmentsHistory: ({ userId, authId }: {
            userId: string;
            authId: string;
        }) => Promise<IAssignmentHistory[] | undefined>,
        handleChangeGoal: (id: string, data: UserData) => Promise<AxiosResponse<UserType> | undefined>,
        pauseClientNotifications: (pauseNotificationsData: IPauseNotificationForm & {userId: string}) => Promise<{
            userId: string;
            reasonMessage: string,
            blockUntil: string;
        } | undefined>
    },
    usersProps: {
        usersRemoveCoach: (data: {
            id: React.Key;
        }[]) => Promise<ISuccessStatus | undefined>,
        usersAssignCoach: (data: {
            coachId: string;
            users: {
                id: string | React.Key;
            }[];
        }) => Promise<ISuccessStatus | undefined>,
        getCoachesSelectOptions: () => Promise<{
            label: string;
            value: string;
        }[] | undefined>
    },
    notesProps: {
        // setUserActivity:(activityType: CurrentEvent) => Promise<void>, 
        // onCancel: () => void, 
        addNote: (id: string, values: {
            notes: string;
        }) => Promise<IClientNote | undefined>
    },
    messageTemplatesProps: {
        messageTemplatesApiCancel: Canceler,
        getMessageTemplates: () => Promise<{
            label: string;
            value: string;
        }[]>,
        setTemplates: (templates: string[]) => void;
    },
    tasksCategoriesProps: UseTasksCategoriesTypes
}


export default function ClientModals({
                                         editFeedback,
                                         setCurrentFeedbackDeleteClear,
                                         saveFeedback,
                                         setUserActivity,
                                         modalsProps,
                                         checkInsProps,
                                         selectedRowData,
                                         userProps,
                                         usersProps,
                                         notesProps,
                                         messageTemplatesProps,
                                         tasksCategoriesProps,
                                     }: Props) {
    const {
        setAverageVisible,
        setAssignmentsHistoryVisible,
        setAssignCoachVisible,
        setAddNotesVisible,
        setChangeGoalVisible,
        setMacroVisible,
        setRemoveCoachVisible,
        setFeedbackVisible,
        setPauseNotificationsVisible,
        setAddTaskVisible,
        setEditTaskVisible,
    } = modalsProps

    const { checkInTableUpdate} = checkInsProps

    const {usersRemoveCoach, usersAssignCoach} = usersProps
    const {getUser, pauseClientNotifications} = userProps

    const { onCreateUserTask, onUpdateUserTask, onUpdateTasks, handleRemoveCurrentTask, handleVisibleCloseRemoveCurrentTask } = tasksCategoriesProps;

    const user = useAppSelector(state => state.user.userData!)
    const pausedNotifications = useAppSelector(state => state.user.pausedNotifications);
    const {id} = user

    const handleOnRemoveCoach = async () => {
        const data = await usersRemoveCoach([{id}])
        if (data) {
            await getUser(id)
        }
        return data
    }

    const handleOnAssignCoach = async (coachId: string, users: {id: React.Key}[] = []) => {
        const data = await usersAssignCoach({coachId, users})
        if (data) {
            await getUser(id)
        }
        return data
    }

    const handlePauseClientNotifications = (formData: IPauseNotificationForm) => pauseClientNotifications({ userId: id, ...formData })

    const AssignCoachWithPermission =  withPermission(AssignCoachModal)
    const RemoveCoachWithPermission =  withPermission(RemoveCoachModal)

    const feedbackPropsData = { ...messageTemplatesProps, addFeedback:saveFeedback, setCurrentFeedbackDeleteClear, editFeedback}

    return (
        <>
            <AverageCheckinsModal 
                onCancel={() => setAverageVisible(false)} 
                selectedData={selectedRowData as ICheckIn[]}  
            />
            <FeedbackModal 
                {...feedbackPropsData} 
                setVisible={setFeedbackVisible}
                modal={true} 
            />

            <AssignCoachWithPermission 
                {...usersProps} 
                assignCoach={handleOnAssignCoach}
                onAssignCoachCancel={() => setAssignCoachVisible(false)}
                users={[{id}]}
                initialCoach={user?.Coach || null} roles={[admin, manager]}
            />
            <RemoveCoachWithPermission 
                onCancel={() => setRemoveCoachVisible(false)}
                handleOk={handleOnRemoveCoach} title={`Are you sure you want to remove coach for ${user.fullName}?`} 
                roles={[admin, manager]}
            />
            <ChangeGoalModal 
                {...userProps} 
                checkInTableUpdate={checkInTableUpdate}
                onCancel={() => {setChangeGoalVisible(false)}}
            />
            <AssignmentsHistoryPopup 
                {...userProps}
                onCancel={() => setAssignmentsHistoryVisible(false)}
            />
            <AddNotesModal 
                {...notesProps} 
                setUserActivity={setUserActivity} 
                onCancel={() => setAddNotesVisible(false)}
            />
            <MacroModal 
                {...userProps} 
                setUserActivity={setUserActivity} 
                setMacroVisible={setMacroVisible}
            />
            <PauseNotificationsModal
                onCancel={() => setPauseNotificationsVisible(false)}
                initialData={pausedNotifications}
                handleDisableNotification={handlePauseClientNotifications}
                clientFullName={user.fullName}
            />
            <AddTaksForm
                onCancel={() => setAddTaskVisible(false)}
                onFinish={onCreateUserTask}
                onUpdate={onUpdateTasks}
            />
            <EditTaskForm 
                onCancel={() => setEditTaskVisible(false)} 
                onFinish={onUpdateUserTask}
                onUpdate={onUpdateTasks}
            />
            <DeleteTaskModal
                onCancel={() => handleVisibleCloseRemoveCurrentTask()}
                onFinish={handleRemoveCurrentTask}
            />
        </>
    )
}