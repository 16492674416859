import {Tag} from "antd";
import {CaretDownOutlined, CaretUpOutlined, WarningFilled} from "@ant-design/icons";
import React from "react";
import moment from "moment";
import { ColumnProps } from "antd/es/table";
import { ICheckIn } from "Interfaces/CheckIn";


const muscle_gain = {
    waist: {upIsGreen: true, limit: 1},
    weight: {upIsGreen: true, limit: 0},
    hips: {upIsGreen: true, limit: 1},
    lowerBelly: {upIsGreen: true, limit: 1},
    upperQuad: {upIsGreen: true, limit: 0},
    midQuad: {upIsGreen: true, limit: 0}
}
const weight_loss = {
    waist: {upIsGreen: false, limit: 1},
    weight: {upIsGreen: false, limit: 0},
    hips: {upIsGreen: false, limit: 1},
    lowerBelly: {upIsGreen: false, limit: 1},
    upperQuad: {upIsGreen: false, limit: 0},
    midQuad: {upIsGreen: false, limit: 0}
}
const maintenance = {
    waist: {upIsGreen: false, limit: 1},
    weight: {upIsGreen: false, limit: 0},
    hips: {upIsGreen: false, limit: 1},
    lowerBelly: {upIsGreen: false, limit: 1},
    upperQuad: {upIsGreen: true, limit: 0},
    midQuad: {upIsGreen: true, limit: 0}
}
const recomp = {
    waist: {upIsGreen: false, limit: 1},
    weight: {upIsGreen: false, limit: 0},
    hips: {upIsGreen: false, limit: 1},
    lowerBelly: {upIsGreen: false, limit: 1},
    upperQuad: {upIsGreen: true, limit: 0},
    midQuad: {upIsGreen: true, limit: 0}
}

type PropertyType = keyof typeof muscle_gain | keyof typeof weight_loss | keyof typeof maintenance | keyof typeof recomp;

const physiqueLookup = (physiqueGoal: string) => {
    switch (physiqueGoal) {
        case "MUSCLE_GAIN":
            return muscle_gain
        case "WEIGHT_LOSS":
            return weight_loss
        case "RECOMP":
            return recomp
        case "MAINTENANCE":
            return maintenance
        default:
            return null
    }

}

const deltaTagRender = ({delta, upIsGreen}: {upIsGreen: boolean, delta: number}) => {
    if (upIsGreen) {
        return delta > 0 ? <Tag color="green"><CaretUpOutlined/>{delta}</Tag> :
            <Tag color="red"><CaretDownOutlined/> {delta}</Tag>
    }

    return delta < 0 ? <Tag color="green"><CaretDownOutlined/>{delta}</Tag> :
        <Tag color="red"> <CaretUpOutlined/>{delta}</Tag>
}


export const deltaRender = ({
    current, 
    delta, 
    property, 
    user, 
    smallest
}: {
    current: number,
    user: ICheckIn['User'],
    delta: number,
    property: PropertyType
    smallest: boolean | null
}) => {
    const physique = physiqueLookup(user.physiqueGoal)
    if(+current === 0){
        return <span>{current} <Tag color="orange"><WarningFilled/></Tag></span>
    }
    return <span>
        {smallest ? <Tag style={{marginBottom: 10}} color="gold">{current}</Tag> : current}
        {!!delta && !!physique ? deltaTagRender({
        delta: delta,
        upIsGreen: physique[property]?.upIsGreen
    }) : null}
    </span>
}

export const scoreRender = (text: string)=> (!!text || typeof text === 'number'?
    parseFloat(text).toFixed(0) : <Tag color="orange"><WarningFilled/></Tag>)


export const dateFormat = (value: string) => !!value && moment(value).format('DD-MM-YYYY')
    // new Intl.DateTimeFormat('en-AU').format(Date.parse(value))

export const flagRender: ColumnProps<ICheckIn>['render'] = (text, record) => {
    if (!text) {
        return null
    }

    return text === "OK" ? <Tag color={"green"}>{record.flag}</Tag> : <Tag color={"red"}>{record.flag}</Tag>
}

