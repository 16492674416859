import React from "react";
import { useAppSelector } from "redux/store";
import NoData from "components/no-data/NoData";
import {shallowEqual} from "react-redux";
import {Table} from "antd";
import DashboardPageCoachClientsTableConfig from "./DashboardPageCoachClientsTableConfig";

export default function DashboardPageCoachClientsInfoTable() {
    const coachClientsData = useAppSelector(state => state.dashboard.coachClientsData, shallowEqual)

    const {getCoachClientsColumns} = DashboardPageCoachClientsTableConfig()
    //{count,activeTotal,inactiveTotal,activeCheckinedNotLate,activeCheckinedLate,inactiveMissedOneWeek,inactiveMissedMoreThanTwoWeeks}
    const [total] = coachClientsData?.filter(({fullName}) => fullName === 'Total');
    const withNoCoachClientsData = coachClientsData?.filter(({fullName}) => fullName === 'No coach');
    const noTotalCoachClientsData = coachClientsData?.filter(({fullName}) => fullName !== 'Total');
    const noCoachClientsData = noTotalCoachClientsData?.filter(({fullName}) => fullName !== 'No coach');

   const data = [...withNoCoachClientsData, ...noCoachClientsData];

    const filteredDataByAllClients = data?.filter((column) => column?.name !== 'No coach'
        ? column :
        column?.count > 0 ? column : false
    )

    return (
        filteredDataByAllClients.length > 0 ? (
                    <Table
                        className="dashboard_coach_table"
                        scroll={{
                            x: '15vw',
                        }}
                        columns={getCoachClientsColumns}
                        dataSource={filteredDataByAllClients}
                        rowKey={(item) => !!item?.Coach ? item.Coach.fullName : item.name}
                        summary={(e) => {

                            return (
                                <Table.Summary.Row>
                                    <Table.Summary.Cell index={0}>Totals</Table.Summary.Cell>
                                    <Table.Summary.Cell index={1}>{total?.count}</Table.Summary.Cell>
                                    <Table.Summary.Cell index={2}>{total?.activeTotal}</Table.Summary.Cell>
                                    <Table.Summary.Cell index={3}>{total?.inactiveTotal}</Table.Summary.Cell>
                                    <Table.Summary.Cell index={4}>{total?.activeCheckinedNotLate}</Table.Summary.Cell>
                                    <Table.Summary.Cell index={5}>{total?.activeCheckinedLate}</Table.Summary.Cell>
                                    <Table.Summary.Cell index={6}>{total?.inactiveMissedOneWeek}</Table.Summary.Cell>
                                    <Table.Summary.Cell index={7}>{total?.inactiveMissedMoreThanTwoWeeks}</Table.Summary.Cell>
                                </Table.Summary.Row>
                            )
                        }}
                    />
            ) :
            <NoData description="Coach clients (No Data)"/>
    )
}