import {useCallback, useEffect, useState} from "react";
import { useAppDispatch, useAppSelector } from "redux/store";
import {
    setCurrentUser,
    setReadLateMessage,
    setSearchUsers,
    setUnreadLateMessage
} from "redux/reducers/chatReducer";
import {debounce} from "hooks/useResize";
import useApi from "hooks/useApi";
import { 
    CHAT_SEARCH_USERS, 
    ONE_DAYS_MORE_LATE, 
    TWO_DAYS_MORE_LATE  
} from "vars";
import { IChat, IContactList, IChatRead } from "Interfaces/Chat";
import { ResponseTypeChat } from "Types";

interface Props {
    responseType: ResponseTypeChat | null
};

const useConversationApi_Search = ({responseType}: Props) => {
    const {API} = useApi();
    const dispatch = useAppDispatch();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const searchList = useAppSelector((state) => state.chat.searchUsers);
    const currentUser = useAppSelector((state) => state.chat.currentUser);
    const [searchValue, setSearchValue] = useState<string>("");

    const onClearSearch = () => {
        dispatch(setSearchUsers({contacts: [], totalCount: 0, searchValue: ""}));
        setSearchValue("");
    };

    const onSearchUser = async (f_clientFullNameOrEmail: string) => {
        if(!f_clientFullNameOrEmail.trim()){
            return onClearSearch();
        }
        try {
            setIsLoading(true);
            const response = await API.get<IContactList>('/api/chat/contacts', {
                params: {
                    f_clientFullNameOrEmail,
                    withoutAnswerPerDay: responseType === ONE_DAYS_MORE_LATE,
                    withoutAnswerPerTwoDays: responseType === TWO_DAYS_MORE_LATE,
                }
            })
        
            dispatch(setSearchUsers({...response.data, searchValue: f_clientFullNameOrEmail}));
            setIsLoading(false);
        }catch (e) {
            if(e instanceof Error){
                setIsLoading(false);
                console.log(e.message);
            }
        }
    };



    const putUnreadUserMessages = async (user: IChat) => {
        try {
            const response = await API.put<IChatRead>(`/api/chat/unread/${user.userId}`);
            dispatch(setUnreadLateMessage({currentUser: response.data, responseType: CHAT_SEARCH_USERS}));
        }catch (e) {
            if(e instanceof Error){
                console.log(e.message)
            }
        }
    }

    const updateReadCount = useCallback(async (actionMenuUser: IChat) => {
        if(!actionMenuUser && !currentUser) return;
        try {
            const lastMessageId = actionMenuUser?.id  || currentUser?.id;
            const lastMessageUserId = actionMenuUser?.userId || currentUser?.userId;
            dispatch(setReadLateMessage({currentUser: actionMenuUser, responseType: CHAT_SEARCH_USERS}));
            await API.put(`/api/chat/read-until/${lastMessageId}/${lastMessageUserId}`)
        }catch (e) {
            if(e instanceof Error){
                console.log(e.message);
            }
        }

    }, [currentUser]);


    const onSearchUser__debounce = debounce((f_clientFullNameOrEmail: string) => setSearchValue(f_clientFullNameOrEmail), 300);

    const onSetCurrentUser = useCallback((user: IChat) => {
        dispatch(setCurrentUser(user))
    },[])

    useEffect(() => {
        onSearchUser(searchValue);
    }, [searchValue, responseType]);

    return {
        onSearchUser__debounce,
        onSetCurrentUser,
        isLoading,
        onClearSearch,
        searchList,
        updateReadCount,
        setSearchValue,
        putUnreadUserMessages,
        searchValue,
        currentUser,
    }
};

export interface ISearchListProps {
    onSearchUser__debounce: (...args: any[]) => void,
    onSetCurrentUser: (user: IChat) => void,
    isLoading: boolean,
    onClearSearch: () => void,
    searchList: IChat[],
    updateReadCount: (user: IChat) => Promise<void>,
    setSearchValue: React.Dispatch<React.SetStateAction<string>>,
    putUnreadUserMessages: (user: IChat) => Promise<void>,
    searchValue: string,
    currentUser: IChat | null
}



export default useConversationApi_Search;