import { IUser } from 'Interfaces/User';
import {createSlice, PayloadAction} from '@reduxjs/toolkit'

interface InitialState {
    candidatesData: IUser[],
    lastUpdate: number,
}

const initialState = {
    candidatesData: [],
    lastUpdate: Date.now()
} as InitialState;

const candidatesReducer = createSlice({
    name: 'candidatesReducer',
    initialState,
    reducers: {
        setCandidates: (state, action: PayloadAction<{candidates: IUser[]}>) => {
            state.candidatesData = action.payload.candidates
        },
        setCandidatesUpdate: (state, action: PayloadAction<{lastUpdate: number}>) => {
            state.lastUpdate = action.payload.lastUpdate
        },
        candidatesReducerReset: state => initialState,
    }
})

export const {setCandidates, setCandidatesUpdate, candidatesReducerReset} = candidatesReducer.actions
export default candidatesReducer.reducer


