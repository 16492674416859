import React, { FC, ReactNode } from 'react';
import { Card, Space, Typography } from 'antd';
import { ArrowUpOutlined, ArrowDownOutlined, CaretUpOutlined, CaretDownOutlined} from '@ant-design/icons';

const Title = Typography.Title;

type MiniDashboardCardProps = {
  title: ReactNode;
  content: ReactNode;
  footer?: ReactNode;
  arrow?: 'up' | 'down';
};

const MiniDashboardCard: FC<MiniDashboardCardProps> = ({ title, content, footer, arrow}) => {
    return (
      <Card className='mini-dashboard-card'>
        <p className='card-text'>{title}</p>
      <Space direction='horizontal'>
        {arrow &&
          (arrow === 'up'
            ? <CaretUpOutlined className='arrow-icon' />
            : <CaretDownOutlined className='arrow-icon' />
          )
        }
        <Title level={3}>{content}</Title>
      </Space>
        <div className='card-footer'>
          <span className='card-text'>
            {footer}
          </span>
        </div>
      </Card>
    )
}

export default MiniDashboardCard;
