import {useDispatch} from "react-redux";
import {ACTIVITY_REDUCER, COACHES_REDUCER, DASHBOARD_REDUCER, CHECK_IN_REDUCER,USERS_REDUCER, USER_NOTES_REDUCER} from "../vars";
import {activityReducerReset} from "../redux/reducers/activityReducer";
import {dashboardReducerReset} from "../redux/reducers/dashboardReducer";
import {coachesReducerReset} from "../redux/reducers/coachesReducer";
import {checkInsReducerReset} from "../redux/reducers/checkInsReducer";
import {usersReducerReset} from "../redux/reducers/usersReducer";
import {notesReducerReset} from "../redux/reducers/notesReducer";


const useResetStore = (reducerName: string) => {
    const dispatch = useDispatch()

    switch (reducerName) {
        case ACTIVITY_REDUCER: {
            return () => dispatch(activityReducerReset())
        }
        case DASHBOARD_REDUCER: {
            return () => dispatch(dashboardReducerReset())
        }
        case COACHES_REDUCER: {
            return () => dispatch(coachesReducerReset())
        }
        case CHECK_IN_REDUCER: {
            return () => dispatch(checkInsReducerReset())
        }
        case USERS_REDUCER: {
            return () => dispatch(usersReducerReset())
        }
        case USER_NOTES_REDUCER: {
            return () => dispatch(notesReducerReset())
        }
        default: 
        return () => {}
    }
}

export default useResetStore