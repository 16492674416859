import React, {useEffect, useState} from 'react';
import { useAppSelector } from 'redux/store';
import {Form, Select} from 'antd';
import antNotificationComponent from "../../utils/notification-ant-utils";
import { ISuccessStatus } from 'Interfaces/Auth';
import { ICoach } from 'Interfaces/User';
import { Modal } from 'components/Antd/Modal';
import { UserData } from 'redux/reducers/userReducer';


export interface IAssignCoachModal {
    users: {id: React.Key}[],
    onAssignCoachCancel: () => void,
    assignCoach: (coachId: string, users?: {
        id: React.Key;
    }[]) => Promise<ISuccessStatus | undefined>,
    getCoachesSelectOptions: () => Promise<{
        label: string;
        value: string;
    }[] | undefined>,
    initialCoach: UserData | null
};

function AssignCoachModal({
                              users,
                              onAssignCoachCancel,
                              assignCoach,
                              getCoachesSelectOptions,
                              initialCoach = null,
                          }: IAssignCoachModal): JSX.Element {

    const assignCoachModalVisible = useAppSelector(state => state.modals.assignCoachModalVisible)

    const [coachId, setCoachId] = useState( !!initialCoach ? initialCoach.id : '')
    const [options, setOptions] = useState<{label: string, value: string}[]>([])
    const [loading, setLoading] = useState(true)


    const onFinish = async (coachId: string) => {
        if (coachId) {
            const data = await assignCoach(coachId, [...users])
            if (!!data) {
                onAssignCoachCancel()
                antNotificationComponent({message: "Coach assigned successfully", type: "success"})
            } else {
                antNotificationComponent({type: "error"})
            }
        }
    }

    useEffect(() => {
        let cleanupFunction = false;
        async function fetchData() {
            setLoading(true)
            const data = await getCoachesSelectOptions()
            if (!cleanupFunction) {
                if (data?.length) {
                    setOptions(data)
                }
                setLoading(false)
            }
        }

        if (assignCoachModalVisible && !cleanupFunction) {
            fetchData()
        }
        return () => {cleanupFunction = true}
    }, [assignCoachModalVisible])


    return (
        <Modal visible={assignCoachModalVisible} onCancel={onAssignCoachCancel} onOk={() => onFinish(coachId)}
               title="Select Coach" okText={"Save Coach"}>
            {!loading ?
                <Form initialValues={{coachId}}>
                    <Form.Item name={'coachId'} rules={[{required: true, message: 'Please choose coach'}]}>
                        <Select size={'large'} value={coachId} placeholder={''} options={options}
                                onChange={(value: string) => setCoachId(value)}/>
                    </Form.Item>
                </Form>:null}
        </Modal>
    )

}

export default AssignCoachModal