import React, { FC } from 'react';
import {Search} from "@chatscope/chat-ui-kit-react";

interface SearchProps {
    onClearSearch: () => void,
    onSearchUser__debounce: (value: string) => void
}

const _Search: FC<SearchProps> = ({onClearSearch, onSearchUser__debounce}) => {
    return (
        <Search
            placeholder="Search"
            onChange={onSearchUser__debounce}
            onClearClick={onClearSearch}
        />
    )

};

export default _Search;