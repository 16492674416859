import React, {useEffect} from "react";
import { PageHeader, Space} from "antd";
import withPermission from "HOCs/withPermission";
import NotFoundPage from "../not-found/NotFoundPage";
import useResetStore from "hooks/useResetStore";
import './activity.css'
import {ACTIVITY_REDUCER} from "vars";
import withSidebarNavigation from "HOCs/withSidebarNavigation";
import ActivityCards from "./ActivityCards";
import ActivityTableWrapper from "./ActivityTableWrapper";

function ActivityPage() {
    const reset = useResetStore(ACTIVITY_REDUCER)

    useEffect(() => () => {reset()}, [])

    return (
        <Space className={"page_wrapper_style"} direction="vertical">
            <PageHeader className="site-layout-background activity_page_header" title="Activity"
                        onBack={() => window.history.back()}/>

            <ActivityCards />
            <ActivityTableWrapper/>
        </Space>
    )
}

export default withPermission(withSidebarNavigation(ActivityPage, "/activity"), NotFoundPage)