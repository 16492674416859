
export enum StatusTask {
    opened = 'opened',
    completed = 'completed',
    archived = 'archived',
    deleted = 'deleted',
};
export interface ITask {
    name: string,
    description: string | null,
    dueDate: string | null,
    reminderDate: string | null,
    pin: boolean,
    status: keyof typeof StatusTask,
    statusChangedAt: string | null,
    createdAt: string,
    id: string,
    taskCategoryId: string,
    userId: string,
    coachId: string,
    taskCategory: ICategory
};

export interface ITaskForm {
    name: string,
    status: keyof typeof StatusTask,
    category: string,
    pin: boolean,
    description?: string | null,
    dueDate?: string | null,
    reminderDate?: string | null,
}

export interface ICategory {
    id: string,
    name: string,
    description?: string,
    createById: string,
    color: string,
    archived: boolean,
    shortName: string,
};

export interface IFullCategory extends ICategory { hasTasks: 0 | 1 };

export interface ICategoryForm {
    name: string,
    shortName: string,
    description?: string,
    color: string,
    archived?: boolean
}