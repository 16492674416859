import React from "react";
import {Result} from "antd";
import Button from "antd/lib/button";
import {useHistory} from "react-router-dom";

export default function ForbiddenPage(){
    const history = useHistory()
    const backToHomePage =()=> history.push('/')
    return (
        <Result
            status="403"
            title="403"
            subTitle="Forbidden"
            extra={<Button type="primary" onClick={backToHomePage}>Back Home</Button>}
        />
    )
}