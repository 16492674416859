import React, { useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useAppSelector } from 'redux/store';
import withPermission from 'HOCs/withPermission';
import withSidebarNavigation from 'HOCs/withSidebarNavigation';
import { Layout, PageHeader, Space } from 'antd';
import NotFoundPage from '../../not-found/NotFoundPage';
import ManagerSidebar from '../ManagerSidebar';
import { useManagersApiWithRedux } from 'services/managers-service';
import ManagerMainBody from './ManagerMainBody';
import {getPopoverTextWithIcon} from "utils/helpers";


const ManagerMain = (): JSX.Element => {
    const {goBack} = useHistory();
    const {id} = useParams() as {id: string};
    const {
        getManager, 
        getManagersData, 
        getUpdateManager, 
        getInfoCards, 
        onChangeSelectedTable,
        onChangeDateRange
    } = useManagersApiWithRedux();
    const managersData = useAppSelector((state) => state.managers.managersData);
    const dateRange = useAppSelector((state) => state.managers.dateRange)
    const currentManager = useAppSelector((state) => state.managers.currentManager);
    const infoCards = useAppSelector((state) => state.managers.currentManagerInfoCards);
    const onChangeBio = getUpdateManager({id, key: 'bio'});
    const onChangePhoto = getUpdateManager({id, key: 'photo'});
    const onChangeSpecificArea = getUpdateManager({id, key: 'specificArea'});

    useEffect(() => {
        getManager(id);
        getManagersData();
    }, [id])

    return (
        <Layout className="coach_main_layout">
            <ManagerSidebar
            data={managersData}
            />
            <Layout>
                <Space className="page_wrapper_style" direction="vertical">
                    <PageHeader
                    className="site-layout-background" 
                    onBack={goBack} 
                    title={getPopoverTextWithIcon(currentManager?.fullName)}
                    />
                    <ManagerMainBody
                    onChangeSelectedTable={onChangeSelectedTable}
                    infoCards={infoCards!}
                    dateRange={dateRange}
                    getInfoCards={getInfoCards}
                    onChangeDateRange={onChangeDateRange}
                    onChangeSpecificArea={onChangeSpecificArea}
                    onChangeBio={onChangeBio}
                    onChangePhoto={onChangePhoto}
                    currentManager={currentManager}
                    />
                </Space>
            </Layout>
        </Layout>
    )
};



export default withPermission(withSidebarNavigation(ManagerMain, '/manager'), NotFoundPage);