import React, { useState, useEffect, useCallback } from 'react';
import { useAppSelector } from 'redux/store';
import {
    Table, 
    Tag, 
    Space, 
    Button,
    Form, 
    Input, 
    Menu, 
    PageHeader, 
    Layout, 
    Typography,
    MenuItemProps
} from 'antd';
import {
    EditOutlined,
    DeleteOutlined,
    AuditOutlined,
    DownOutlined
} from '@ant-design/icons';
import axios from 'axios';
import { useHistory} from 'react-router-dom'
import {admin, coach} from "vars";
import withPermission from "HOCs/withPermission";
import NotFoundPage from "../not-found/NotFoundPage";
import {
    getPopoverTextWithIcon,
    getUserFormData,
    openNotification,
} from "utils/helpers";
import { useManagersApiWithRedux } from 'services/managers-service';
import { setManagers } from 'redux/reducers/coachesReducer';
import CoachSidebar from "../coach/CoachSidebar";
import useCoachesApiWithRedux from "services/coaches-service";
import useUserApiWithRedux from "services/user-service";
import useModals from 'services/modals-service';
import antNotificationComponent from "utils/notification-ant-utils";
import AssignManagerModal from 'pages/coach/assignManagerModal';
import UnAssignManagerModal from 'pages/managers/unAssignManagerModal';
import withSidebarNavigation from "HOCs/withSidebarNavigation";
import {MenuClickEventHandler} from "rc-menu/lib/interface"
import { UserRoles, UserType } from 'Types';
import { ColumnProps, TableProps } from 'antd/lib/table';
import { ICoach } from 'Interfaces/User';
import { Dropdown } from 'components/Antd/DropDown';
import { SearchProps } from 'antd/lib/input';
import { ColumnType } from 'antd/es/table';
import AddCoachForm from './Form/AddCoachForm';
import EditCoachForm from './Form/EditCoachForm';
import { Modal } from 'components/Antd/Modal';

export interface ICoachForm {
    firstName: string,
    lastName: string,
    email: string,
    pvtEnabled: boolean,
    pvtSuperUser: boolean,
    disabled: boolean,
    roles: UserRoles,
    specificArea: string,
    id: string,
    bio: string,
    password: string,
    showAsCoach: boolean,
    confirmPassword: string,

};

//NOTES: Based on a demo table, added a pop-up editor via modal and form.
//I used functions here rather than classes/extend React.Component as this causes issues getting a reference to the form in order to populate it.
//Looking to upgrade table to ProTable (has back-end linkages and search and stuff... but I was having troubles getting it to work)
//Data is populated locally, but shouldn't be much of a hassle to change to using a fetch to get the objects.

function CoachList() {
    const {Search} = Input;
    const history = useHistory();
    const {updateUserData, onDeleteUsers} = useUserApiWithRedux()
    const {getCoaches, getFilteredCoaches, onCreateCoach}=useCoachesApiWithRedux()
    const {setAssignManagerVisible, setUnAssignManagerVisible} = useModals();
    const {onAssignManagers, getManagersData, onUnAssignManagers} = useManagersApiWithRedux()
    const roles = useAppSelector((state) => state.auth.user?.roles);
    const coachesData = useAppSelector((state) => state.coaches.coachesData);
    const filteredCoachesData = useAppSelector((state) => state.coaches.filteredCoaches);
    const [editing, setEditing] = useState(false)
    const [adding, setAdding] = useState(false)
    const [showDeleteUserModal, setShowDeleteUserModal] = useState(false)
    const [showDeleteUsersModal, setShowDeleteUsersModal] = useState(false)
    const [removeUserData, setRemoveUserData] = useState<ICoach | null>(null)
    const [editItem, setEditItem] = useState({})
    const [lastUpdated, setLastUpdated] = useState(Date.now())
    const [loading, setLoading] = useState(true)
    const [selectedRowKeys, setSelectedRows] = useState<React.Key[]>([])


    const [editForm] = Form.useForm();
    const [addForm] = Form.useForm<ICoachForm>();

   

    const handleMenuClick: MenuClickEventHandler = ({key}) => {
        switch (key) {
            case "1":
                initMacros()
                break;
            case "2":
                deleteUsersModal()
                break;
            case "3":
                setAssignManagerVisible(true)
                break;
            case "4":
                setUnAssignManagerVisible(true)
                break;    
            default:
                return null;    
        }
    }
    const handleViewActivities = (actorId: string) => {
        history.push(`/activity?f_actorId[]=${[actorId]}`)
    }

    const onUnAssignManager = async ({managerIdList}: {managerIdList: React.Key[]}) => {
        await onUnAssignManagers({
            coachIdList: selectedRowKeys,
            managerIdList,
        })
        setLastUpdated(Date.now())
        setSelectedRows([]);
    }

    const onAssignManager = async ({managerIdList}: {managerIdList: React.Key[]}) => {
        await onAssignManagers({
            coachIdList: selectedRowKeys,
            managerIdList
        });
        setLastUpdated(Date.now())
        setSelectedRows([]);
    }

    const MenuItem = (prop: MenuItemProps) => {
        return (
            <Menu.Item {...prop}>
                {prop.title}
            </Menu.Item>
        )
    }

    const AssignManagerMenuItem= withPermission(MenuItem)
    const UnAssignManagerMenuItem = withPermission(MenuItem)
    const RemoveCoachesMenuItem = withPermission(MenuItem)

    const menu = (
        <Menu onClick={handleMenuClick}>
            <Menu.Item key="1">
                Init macros
            </Menu.Item>
            <AssignManagerMenuItem key="3" title="Assign Manager" roles={[admin]} />
            <UnAssignManagerMenuItem key="4" title="Unassign manager" roles={[admin]} />
            <RemoveCoachesMenuItem title="Remove users" key="2" roles={[admin]}  />
        </Menu>
    );

    const boolRender = (value: boolean) => (
            <Tag color={(value == true) ? "volcano" : "green"}>{(value == true) ? "DISABLED" : "ACTIVE"}</Tag>
    )

    const actionsRender: ColumnProps<ICoach>['render'] = (text, record, index) => (
        <Space size="middle">
            <Button shape="circle" type="primary" icon={<EditOutlined/>}
                    onClick={() => editRecord(text, record, index)}/>
            {
                roles === admin &&  
                <>    
                <Button shape="circle" type="primary"
                onClick={() => handleViewActivities(record.id)} icon={<AuditOutlined/>}/>
                <Button shape="circle" type="primary" danger icon={<DeleteOutlined/>}
                onClick={() => showDeleteModal(record)}/>
                </>
            }        
        </Space>
    )

    const newRecord = () => {
        setAdding(true)
    }

    const setInitialModalForm = () => addForm.resetFields();

    const editRecord = (text: any, record: ICoach, index: number) => {
        setSelectedRows([])
        setEditing(true)
        console.log("Editing", editing)
        setEditItem(record)
        // setEditIndex(index)
        editForm.setFieldsValue(record)
    }

    const onDeleteUser = async () => {
        try {
            await axios.delete(`/api/user/${removeUserData?.id}`)
            openNotification("Deleted", 'success', 'topRight')
        } catch (e) {
            openNotification("Failed to delete", 'error', 'topRight')
        } finally {
            setRemoveUserData(null)
            setShowDeleteUserModal(false)
            setLastUpdated(Date.now())
        }
    }

    const onCancelDeleteUser = () => {
        setRemoveUserData(null)
        setShowDeleteUserModal(false)
    }

    const showDeleteModal = (user: ICoach) => {
        setSelectedRows([])
        setRemoveUserData(user)
        setShowDeleteUserModal(true)
    }

    const deleteUsersModal = () => {
        setShowDeleteUsersModal(true)
    }

    const deleteUsers = async () => {
        try {
            const response = await onDeleteUsers(selectedRowKeys);
            if(response?.success){
                setSelectedRows([]);
                setShowDeleteUsersModal(false);
                setLastUpdated(Date.now());
                openNotification("Deleted", 'success', 'topRight');
            }
        } catch (error) {
            openNotification("Failed to delete", 'error', 'topRight');
        }
    }

    const onCancelDeleteUsers = () => setShowDeleteUsersModal(false)

    const getDeleteUsersFullName = useCallback((): string[] => {
        return selectedRowKeys.map((userId) => {
            const selectedUser = coachesData.find((user) => user.id === userId)
            return selectedUser!.fullName
        })
    }, [selectedRowKeys]);

    const onEditCoach = async () => {
        //Do save
        const formData = editForm.getFieldsValue();
        const requestFormData = getUserFormData({formData: {...editItem, ...formData}});
        try {
            await axios.put(`/api/user/${requestFormData.id}`, requestFormData)
            setLastUpdated(Date.now())
            setEditing(false)
            openNotification("Saved", 'success', 'topRight')

            return true
        } catch (err) {
            openNotification("Failed to save", 'error', 'topRight')
            console.warn(err)
            return false
        }
    }

    const doReset = () => {
        setEditing(false)
    }

    const search: SearchProps['onSearch'] = (searchValue) => {
        const filteredCoaches = searchValue.trim() ? 
        coachesData.filter((coach) => coach.fullName.includes(searchValue.trim()) || coach.email.includes(searchValue.trim())) : [];
        getFilteredCoaches(filteredCoaches)
    };
    const onSearch = useCallback(search, [coachesData]);

    const doResetAdding = () => {
        setAdding(false)
        setInitialModalForm()
    }

    const onRequestCoach = async () => {
        const formData = addForm.getFieldsValue();
        const requestFormData = getUserFormData({formData, fields: {showAsCoach: true, roles: coach}})
        try {
            const response = await onCreateCoach(requestFormData);
            if(response){
                setAdding(false)
                setInitialModalForm()
                openNotification("Saved", 'success', 'topRight')
                setLastUpdated(Date.now())
            }
        } catch (error) {
            openNotification("Failed to save", 'error', 'topRight')
            console.warn(error)
        }
    }


    const extras = (
        <Space size="large" align="end">
            {
              roles === admin &&  <Button type="primary" onClick={newRecord}>New</Button>
            }
            <Search placeholder="Search" onSearch={onSearch} enterButton/>
            <Dropdown trigger={['click']} disabled={selectedRowKeys.length === 0}
                      overlay={menu}><Button>Actions <DownOutlined/></Button></Dropdown>
        </Space>
    )

    const onSelectChange = (selected: React.Key[]) => {
        setSelectedRows(selected)
    }

    const rowSelection: TableProps<ICoach>['rowSelection'] = {
        selectedRowKeys,
        onChange: onSelectChange,
    }

    const initMacros = async () => {
        const requests = coachesData.reduce((arr: ICoach[], user) => {
            if (selectedRowKeys.includes(user.id)) {
                const updatedRow = {...user, createMacros: true}
                return [...arr, {...updatedRow}]
            }
            return arr
        }, [])
        try {
            await Promise.all(requests.map((data) => updateUserData({
                id: data.id,
                data
            })));
            setSelectedRows([])
            setLastUpdated(Date.now())
            antNotificationComponent({type: 'success', message: "Saved"})
        } catch (error) {
            antNotificationComponent({type: 'error', message: "Failed to save"})
        }
    }


    const disabledFilterOptions = [{text: 'DISABLED', value: true}, {text: 'ACTIVE', value: false}]

    const disabledFilters: ColumnType<ICoach>['onFilter'] = (value, record) => value === record.disabled

    const managersSelectColumn = (managers = []) => managers.length > 0 && 
    managers.map(({fullName}) => (
        <Typography.Paragraph>{getPopoverTextWithIcon(fullName)}</Typography.Paragraph>
    ))

    const columns: ColumnProps<ICoach>[] = [
        {
            title: 'Full Name',
            dataIndex: 'fullName',
            render: (_,r) => getPopoverTextWithIcon(r.fullName),
            sorter: (a,b) => a.fullName.localeCompare(b.fullName),
        },
        {
            title: 'Email',
            dataIndex: 'email',
            sorter: (a,b) => a.email.localeCompare(b.fullName),
        },
        {
            title: 'Start Date',
            dataIndex: 'startDate'
        },
        {
            title: 'Competing',
            className: 'table-column-title-no-break',
            dataIndex: 'competing'
        },
        {
            title: 'Managers',
            dataIndex: 'managers',
            render: managersSelectColumn,
            className: 'table-column-title-no-break',
        },
        {
            title: 'Status',
            dataIndex: 'disabled',
            filters: disabledFilterOptions,
            onFilter: disabledFilters,
            render: boolRender
        },
        {
            title: 'Action',
            fixed: 'right',
            render: actionsRender
        }
    ];


    useEffect(() => {
        let cleanupFunction = false;

        const fetchData = async () => {
            try {
                console.log("Loading Data - Coach Panel")
                if (!cleanupFunction) {
                    setLoading(true);
                    await getCoaches();
                    await getManagersData(setManagers);
                    setLoading(false)
                }
            } catch (e) {
                if(e instanceof Error){
                    console.error(e.message)
                }
            }
        }

        fetchData();

        return () => {
            cleanupFunction = true
        };
    }, [lastUpdated]);


    return (
        <Layout className={'coach_main_layout'}>
            <CoachSidebar/>
            <Layout>
                <Space className={'page_wrapper_style'} direction="vertical">
                    <PageHeader 
                        className="site-layout-background" 
                        title="Coaches"
                        onBack={window.history.back} 
                        extra={extras}
                    />
                    <Table 
                        scroll={{x: 'auto', y:'62vh', scrollToFirstRowOnChange:true}} 
                        dataSource={filteredCoachesData.length ? filteredCoachesData : coachesData} 
                        columns={columns}
                        pagination={{position: ['bottomRight']}} 
                        loading={loading}
                        rowSelection={rowSelection}
                        rowKey={(u) => u.id}
                        />
                    <AddCoachForm
                        form={addForm}
                        visible={adding}
                        onFinish={onRequestCoach}
                        onReset={doResetAdding}
                        modalProps={{
                            onCancel: doResetAdding
                        }}
                    />
                    <EditCoachForm
                        form={editForm}
                        visible={editing}
                        onFinish={onEditCoach}
                        onReset={doReset}
                        modalProps={{
                            onCancel: doReset
                        }}
                    />
                    <AssignManagerModal
                    onFinish={onAssignManager}
                    selectedRowKeys={selectedRowKeys}
                    onCancel={() => {setAssignManagerVisible(false)}}
                    />
                    <UnAssignManagerModal
                    onFinish={onUnAssignManager}
                    onCancel={() => setUnAssignManagerVisible(false)}
                    selectedRowKeys={selectedRowKeys}
                    />
                    <Modal
                        centered
                        title="Delete User"
                        visible={showDeleteUserModal}
                        onOk={onDeleteUser}
                        onCancel={onCancelDeleteUser}
                    >
                        <span>{`Are you sure you want to delete ${removeUserData?.roles} ${removeUserData?.fullName}?`}</span>
                    </Modal>
                    <Modal
                        centered
                        title="Delete Users"
                        visible={showDeleteUsersModal}
                        onOk={deleteUsers}
                        onCancel={onCancelDeleteUsers}
                    >
                        <span>Are you sure you want to delete following users?</span>
                        <p>{[...getDeleteUsersFullName()].join(', ')}</p>
                    </Modal>
                </Space>
            </Layout>
        </Layout>
    )
}
export default withPermission(withSidebarNavigation(CoachList, '/coaches'), NotFoundPage)