import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ITypeValue } from 'Interfaces/Dashboard/CheckIn';
import { ICoach, IManager, IUserPage } from 'Interfaces/User';
import {CHECKIN, CLIENT, COACH, LAST_MONTH, LAST_WEEK, THIS_WEEK} from "vars";

type CoachDateRangeName = typeof THIS_WEEK | typeof LAST_WEEK | typeof LAST_MONTH;
type SelectedTable = typeof CLIENT | typeof COACH | typeof CHECKIN;
interface InitialState {
    coachesData: ICoach[],
    filteredCoaches: ICoach[],
    coachDateRangeName: CoachDateRangeName,
    selectedCoach: IUserPage | null,
    selectedTable: SelectedTable,
    managersData: IManager[],
    coachActiveClients: ITypeValue | null,
    averageCompliance: number | string,
    coachAverageFeedbackTime: number | string,
    coachCheckIns: number | string,
    coachFeedbackSent: number | string,
};

const initialState = {
    coachesData: [],
    filteredCoaches: [],
    coachDateRangeName: THIS_WEEK,
    selectedTable: CLIENT,
    selectedCoach: null,
    managersData: [],
    coachActiveClients: null,
    averageCompliance: 0,
    coachAverageFeedbackTime: 0,
    coachCheckIns: 0,
    coachFeedbackSent: 0,
} as InitialState;


const coachesReducer = createSlice({
    name: 'coachesReducer',
    initialState,
    reducers: {
        setCoaches: (state, action: PayloadAction<{coachesData: ICoach[]}>) => {
            state.coachesData = action.payload.coachesData
        },
        setFilteredCoaches: (state, action: PayloadAction<ICoach[]>) => {
            state.filteredCoaches = action.payload;
        },
        setCoachDateRangeName: (state, action: PayloadAction<{coachDateRangeName: CoachDateRangeName}>) => {
            state.coachDateRangeName = action.payload.coachDateRangeName
        },
        setSelectCoach: (state, action: PayloadAction<{selectedCoach: IUserPage | null}>) => {
            state.selectedCoach = action.payload.selectedCoach
        },
        setManagers: (state, action: PayloadAction<IManager[]>) => {
            state.managersData = action.payload
        },
        setCoachAverageFeedbackTime: (state, action: PayloadAction<{coachAverageFeedbackTime: number | string}>) => {
            state.coachAverageFeedbackTime = action.payload.coachAverageFeedbackTime
        },
        setCoachAverageCompliance: (state, action: PayloadAction<{averageCompliance: number | string}>) => {
            state.averageCompliance = action.payload.averageCompliance
        },
        setCoachCheckIns: (state, action: PayloadAction<{coachCheckIns: number | string}>) => {
            state.coachCheckIns = action.payload.coachCheckIns
        },
        setCoachFeedbackSent: (state, action: PayloadAction<{coachFeedbackSent: number | string}>) => {
            state.coachFeedbackSent = action.payload.coachFeedbackSent
        },
        setCoachTableSelected: (state, action: PayloadAction<{selectedTable: SelectedTable}>) => {
            state.selectedTable = action.payload.selectedTable
        },
        setCoachActiveClients: (state, action: PayloadAction<{coachActiveClients: ITypeValue}>) => {
            state.coachActiveClients = action.payload.coachActiveClients
        },
        coachesReducerReset: state => initialState
    }
})

export const {
    setCoaches,
    setFilteredCoaches,
    setCoachDateRangeName,
    setSelectCoach,
    setCoachAverageCompliance,
    setCoachAverageFeedbackTime,
    setCoachCheckIns,
    setCoachFeedbackSent,
    setCoachTableSelected,
    setCoachActiveClients,
    setManagers,
    coachesReducerReset,
} = coachesReducer.actions
export default coachesReducer.reducer


