import React, {useEffect, useState} from "react";
import {ModalForm, ProFormSelect, ProFormSwitch, ProFormText} from "@ant-design/pro-form";
import {expPhone, isOnlySpaces, openNotification, validateEmail, validateRequired} from "../../../utils/helpers";
import PhoneInputComponent from "../../../components/PhoneInputComponent";
import { FormInstance } from "antd/es/form/Form";
import { IClient } from "Interfaces/User";
import { IUserForm } from "services/users-service";
import useApi from "hooks/useApi";
import { IClientType } from "Interfaces/ClientType";

interface IEditUserForm {
    form: FormInstance<IUserForm>
    currentUser: IClient | null,
    visible: boolean,
    title: string,
    modalProps: {
        onOk: (data: IUserForm) => Promise<boolean>,
        onCancel: () => void
    }
};

//TODO make 1 modal for editing and creating
export default function EditUserForm({form, visible, modalProps, title, currentUser }: IEditUserForm): JSX.Element{
    const {API} = useApi();
    const [mobileValue, setMobileValue] = useState<string>('');
    const [mobileError, setMobileError] = useState<string>('');
   
    useEffect(() => {
        setMobileValue(form.getFieldValue('phoneNumber'))
        setMobileError('');
    }, [visible, form])

    return (
        <ModalForm form={form}
                   visible={visible} title={title}
                   onFinish={async (values) => {
                       if(mobileValue && !expPhone.test(mobileValue)){
                           return setMobileError('Incorrect mobile format')
                       }
                       setMobileError('');
                       modalProps.onOk({...values, phoneNumber: mobileValue})
                   }}
                   onFinishFailed={() => {
                       if(mobileValue && !expPhone.test(mobileValue)){
                           return setMobileError('Incorrect mobile format')
                       }
                       setMobileError('');
                   }}
                   onReset={modalProps.onCancel}
                   modalProps={{
                    onCancel: modalProps.onCancel
                   }}>
            <ProFormText name="firstName" label="First Name" placeholder="First Name" rules={[
                {
                    required: true,
                    message: 'This field is required',
                },
                ({getFieldValue}) => ({
                    validator(_, value) {
                        if (!value || !isOnlySpaces(getFieldValue('firstName'))) {
                            return Promise.resolve();
                        }
                        return Promise.reject(new Error('This field is required'));
                    },
                }),
            ]}/>
            <ProFormText name="lastName" label="Last Name" placeholder="Last Name" rules={[
                {
                    required: true,
                    message: 'This field is required',
                },
                ({getFieldValue}) => ({
                    validator(_, value) {
                        if (!value || !isOnlySpaces(getFieldValue('lastName'))) {
                            return Promise.resolve();
                        }
                        return Promise.reject(new Error('This field is required'));
                    },
                }),
            ]}/>
            <ProFormText name="email" label="Email" placeholder="Email"
                         rules={[() => ({
                             async validator(_, value) {
                                 await validateRequired(value)
                                 await validateEmail(value)
                                 return Promise.resolve(value);
                             },
                         }), {required: true, message: ''}]}/>
            <PhoneInputComponent
                value={mobileValue}
                setMobileError={setMobileError}
                mobileError={mobileError}
                onChange={setMobileValue}
                required={false}
                // form={form}
            />
            <ProFormSelect
                request={async () => {
                    const {ClientType} = currentUser as IClient;
                    const response = await API.get<IClientType[]>('api/client-type', {
                        params: {f_enabled: [true]}
                    })
                    const clientTypes = [ClientType, ...response.data.filter(({name}) => ClientType.name !== name)];
                    return clientTypes.map(type=>{
                        return {label: type.name, value: type.id}
                    })
                }}
                rules={[{required: true, message: 'This field is required'}]}
                name="clientType"
                label="Client Type"
                placeholder="Client Type"
            />
            <ProFormText.Password name="password" label="Password" placeholder="Password" rules={[
                ({getFieldValue}) => ({
                    validator(_, value) {
                        //TODO we dont need check password if it empty
                        if(!value) {
                            return Promise.resolve();
                        }
                        if (!value || getFieldValue('password') === value) {
                                return Promise.resolve();
                        }
                    },
                }),
            ]} hasFeedback/>
            <ProFormText.Password name="confirmPassword" label="Confirm Password" placeholder="Confirm Password"
                                  dependencies={['password']} hasFeedback rules={[
                ({getFieldValue}) => ({
                    validator(_, value) {
                        //TODO we dont need check password if it empty
                        if(!value) {
                            return Promise.resolve();
                        }
                        if (!value || getFieldValue('password') === value) {
                            return Promise.resolve();
                        }

                        return Promise.reject({message: 'Passwords do not match'});
                    },
                }),
            ]}/>
            <ProFormSwitch name="disabled" label="Disabled"/>
            <ProFormSwitch name="competing" label="Competing"/>
            <ProFormSwitch name="testAccount" label="Test Account"/>
        </ModalForm>
    )
}