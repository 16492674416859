import React, {useEffect, useState} from 'react';
import { useAppSelector } from 'redux/store';
import {Space} from 'antd';
import './dashboard.css'
import DashboardPageBody from "./DashboardPageBody";
import DashboardPageHeader from "./DashboardPageHeader";
import withSidebarNavigation from "HOCs/withSidebarNavigation";
import useDashboardApiWithRedux from "services/dashboard-service";
import useCoachCandidatesApiWithRedux from "services/candidates-service";
import useUsersApiWithRedux from "services/users-service";
import usePersistedState from "hooks/usePersistedState";
import LoadingSpin from "components/loading-spin/LoadingSpin";
import {useManagersApiWithRedux} from "services/managers-service";
import { IDashboardQueryParams } from 'Interfaces/Dashboard';
import { IUsersTableSettings } from 'redux/reducers/usersReducer';
import useTasksCategories from 'services/tasksCategories-service';

function DashboardPage() {
    const [isLoading, setLoading] = useState(true)
    const [isInit, setInit] = useState(true)
    const user = useAppSelector((state) => state.auth.user!)
    const selectedCoachId = useAppSelector((state) => state.dashboard.selectedCoachId)!
    const coachCandidatesProps = useCoachCandidatesApiWithRedux()
    const {getManagersData} = useManagersApiWithRedux();
    const dashboardProps = useDashboardApiWithRedux();
    const { setCategoriesData, getUserTasksData } = useTasksCategories();
    const {dashboardApiCancel, initDashboardFilters} = dashboardProps

    const usersProps = useUsersApiWithRedux()
    const {usersApiCancel, initUsersTableFilters} = usersProps

    const [initialDashboard, setInitialDashboard] = usePersistedState<IDashboardQueryParams>(
        "DashboardPage/Dashboard",
        {}
    )
    const [initialUsers, setInitialUsers] = usePersistedState<IUsersTableSettings>(
        "DashboardPage/Users",
        {}
    )

    const getTasksData = () => {
        if (selectedCoachId || user.roles === 'coach')
            return getUserTasksData({ coachId: selectedCoachId || user.id });
        else if (user.roles === 'manager') return getUserTasksData({ managerId: user.id });

        return null;
    };

    useEffect(() => {
        let cleanupFunction = false
        const fetchData = () => {
            setInit(true)
            initUsersTableFilters({
                ...initialUsers,
            })
            initDashboardFilters({
                ...initialDashboard,
            })
            if (!cleanupFunction) {
                setInit(false)
            }
        }
        if (!!user.id) {
            fetchData()
        }

        return () => {
            cleanupFunction = true
            dashboardApiCancel()
            usersApiCancel()
        }
    }, [])


    const headerProps = {
        ...dashboardProps,
        ...coachCandidatesProps,
        isLoading,
        setLoading,
        initialDashboard,
        setInitialDashboard
    }
    const bodyProps = {
        ...dashboardProps,
        ...usersProps,
        getManagersData,
        isLoading,
        setLoading,
        initialUsers,
        setInitialUsers,
        setCategoriesData,
        getUserTasksData: getTasksData
    }
    return (
        !isInit ? <Space className={'page_wrapper_style'} direction="vertical">
            <DashboardPageHeader {...headerProps}/>
            <DashboardPageBody {...bodyProps}/>
        </Space> : <LoadingSpin/>
    )
}

export default withSidebarNavigation(DashboardPage, '/')