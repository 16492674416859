import { DATE_FORMAT } from './../../../vars';
import {useEffect, useState} from "react";
import moment, { Moment } from "moment";
import {useDispatch, useSelector} from "react-redux";
import {IFoodDiary, setFoodDiaryData} from "../../../redux/reducers/userReducer";
import { useAppDispatch, useAppSelector } from "redux/store";
import useApi from "hooks/useApi";
import { ColumnsType } from "antd/lib/table";
import { FixMeLater } from "Types";

const useFoodDiaryService = () => {
    const {API} = useApi();
    const dispatch = useAppDispatch();
    const user = useAppSelector((state) => state.user.userData);
    const foodDiaryData = useAppSelector((state) => state.user.foodDiaryData);
    const getToday = () => moment();
    const [isPrimaryBack, setIsPrimaryBack] = useState<'primary' | undefined>(undefined);
    const [isLoading, setIsLoading] = useState(true);
    const [dateValue, setDateValue] = useState<moment.Moment | null>(getToday());
    const [isPrimaryForward, setIsPrimaryForward] = useState<'primary'  | undefined>(undefined);
    const isToday = (date: Moment) => dateValue ? date.isSame(new Date(), 'day') : true;

    const resetDate = () => {
        setDateValue(getToday());
        setIsPrimaryForward(undefined);
        setIsPrimaryBack(undefined)
    };

    const getDifference = ({diff,date}: {diff: number, date: moment.Moment | null}) => {
        if(diff > 0){
            setDateValue(date);
            setIsPrimaryForward('primary');
            setIsPrimaryBack(undefined);
        }
        if(diff < 0){
            setDateValue(date);
            setIsPrimaryForward(undefined);
            setIsPrimaryBack('primary');
        }
        if(!diff){
            resetDate();
        }
    };

    const onChangeBack = () => {
        const date = moment(dateValue || new Date()).add(-1, 'day')
        const diff = moment(date || new Date()).diff(new Date(), 'minutes');
        getDifference({date, diff});
    }

    const onChangeForward = () => {
        const date = moment(dateValue || new Date()).add(1, 'day')
        const diff = moment(date || new Date()).diff(new Date(), 'minutes');
        getDifference({date,diff})
    }


    const onChangeDate = (date: FixMeLater) => {
        const diff = moment(date || new Date()).diff(new Date(), 'minutes', true);
       getDifference({date,diff});
    }

    const Columns: ColumnsType<IFoodDiary> = [
        {
            title: "Diary Meal",
            dataIndex: "diary_meal",
            key: "diary_meal",
        },
        {
            title: "Calories",
            dataIndex: ["nutritional_contents", "energy", "value"],
            key: "calories",
        },
        {
            title: "Protein",
            dataIndex: ["nutritional_contents", "protein"],
            key: "protein"
        },
        {
            title: "Fat",
            dataIndex: ["nutritional_contents", "fat"],
            key: "fat"
        },
        {
            title: "Carbs",
            dataIndex: ["nutritional_contents", "carbohydrates"],
            key: "carbs"
        }
    ]

    const getFoodDiaryData = async () => {
        setIsLoading(true);
        const f_dateAfter = moment(dateValue || new Date()).format(DATE_FORMAT)
       if(user?.id){
           try {
               const response= await API.get(`api/mfp/diary/${user.id}`, {
                   params: {
                       f_dateAfter,
                   }
               });
               dispatch(setFoodDiaryData(response.data));
               setIsLoading(false);
           }catch (e) {
            if(e instanceof Error){
                console.log(e.message)
            }
               setIsLoading(false);
           }
       }
    }

    useEffect(() => {
        getFoodDiaryData();
    }, [dateValue])

    return {
        isToday,
        isLoading,
        foodDiaryData,
        onChangeBack,
        onChangeForward,
        isPrimaryForward,
        isPrimaryBack,
        dateValue,
        onChangeDate,
        Columns
    }
};

export default useFoodDiaryService;