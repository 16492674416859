import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ICategory, IFullCategory, ITask } from "Interfaces/TasksCategories";
interface InitialState {
    tasks: ITask[],
    searchCategories: IFullCategory[],
    currentTask: ITask | null,
    categories: IFullCategory[],
    currentCategory: ICategory | null,
    pinTasks: ITask[],
    currentPinTask: ITask | null,
    updateCategories: number,
    updateTasks: number
}

const initialState: InitialState = {
    tasks: [],
    searchCategories: [],
    categories: [],
    pinTasks: [],
    currentTask: null,
    currentCategory: null,
    currentPinTask: null,
    updateCategories: 0,
    updateTasks: 0,
}

const taskCategoriesSlice = createSlice({
    name: "tasks",
    initialState,
    reducers: {
        setTasks: (state, action: PayloadAction<ITask[]>) => {
            state.tasks = action.payload;
        },
        setSearchTasks: (state, action: PayloadAction<IFullCategory[]>) => {
            state.searchCategories = action.payload;
        },
        setCategories: (state, action: PayloadAction<IFullCategory[]>) => {
            state.categories = action.payload;
        },
        setCurrentCategory: (state, action: PayloadAction<ICategory | null>) => {
            state.currentCategory = action.payload;
        },
        setCurrentTask: (state, action: PayloadAction<ITask | null>) => {
            state.currentTask = action.payload;
        },
        setPinTasks: (state, action: PayloadAction<ITask[]>) => {
            state.pinTasks = action.payload;
        },
        setUpdateTask: (state, action: PayloadAction<ITask>) => {
            state.tasks = state.tasks.map((task) => task.id === action.payload.id ? action.payload : task);
        },
        setDeleteTask: (state, action: PayloadAction<string>) => {
            state.tasks = state.tasks.filter(task => task.id !== action.payload);
        },
        setCurrentPinTask: (state, action: PayloadAction<ITask | null>) => {
            state.currentPinTask = action.payload;
        },
        setUpdateCategoriesList: (state, { payload }: PayloadAction<IFullCategory>) => {
            state.categories = state.categories.map((category) => payload.id === category.id ? payload : category);
        },
        setUpdateCategories: (state, action: PayloadAction<number>) => {
            state.updateCategories = action.payload
        },
        setUpdateTasks: (state, action: PayloadAction<number>) => {
            state.updateTasks = action.payload;
        },
    } 
});

export const {
    setCategories, 
    setCurrentTask, 
    setTasks, 
    setCurrentCategory,
    setPinTasks,
    setSearchTasks,
    setUpdateCategories,
    setUpdateCategoriesList,
    setUpdateTask,
    setDeleteTask,
    setUpdateTasks,
} = taskCategoriesSlice.actions;

export default taskCategoriesSlice.reducer;