import React, {useEffect, useState} from "react";
import {Table, TablePaginationConfig, TableProps} from "antd";
import {tablePagination} from "utils/helpers";
import tableConfig from "./activity_table_config";
import useScrollTo from "hooks/useScrollTo";
import { useAppSelector } from "redux/store";
import { IActivity } from "Interfaces/Activity";
import { ITableSettings } from "redux/reducers/activityReducer";

interface Props {
    handleTableChange: TableProps<IActivity>['onChange'],
    handleSelect: (params: ITableSettings) => void,
    getActivity: (params: ITableSettings) => Promise<void>
}

export default function ActivityTable({handleTableChange, handleSelect, getActivity}: Props) {
    const [isLoading, setLoading] = useState<boolean>(true)
    const activityInfoData = useAppSelector(state => state.activity.activityInfoData)
    const pageSize = useAppSelector(state => state.activity.tableSettings.pageSize)
    const tableSettings = useAppSelector(state => state.activity.tableSettings)
    const page = useAppSelector(state => state.activity.tableSettings.page)
    const totalCount = useAppSelector(state => state.activity.totalCount)
    const lastUpdate = useAppSelector(state => state.activity.lastUpdate)

    const {getColumns} = tableConfig({handleSelect})
    useScrollTo({dependency: [pageSize, page]})

    useEffect(() => {
        let cleanupFunction = false;

        const fetchData = async () => {
            setLoading(true)
            await getActivity(tableSettings)
            if (!cleanupFunction) {
                setLoading(false)
            }
        }
        fetchData();

        return () => {cleanupFunction = true};
    }, [lastUpdate, tableSettings, getActivity]);

    return (
        <Table pagination={tablePagination({pageSize, page, totalCount, showSizeChanger: true,}) as TablePaginationConfig}
               columns={getColumns}
               dataSource={activityInfoData}
               onChange={handleTableChange}
               loading={isLoading}
               rowKey={(a) => a.id}
               scroll={{x: 'auto'}}
        />
    )
}