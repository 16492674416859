import React from "react";
import {Row} from "antd";
import ActivityCard from "./ActivityCard";
import {getActivityTitle, getFormatDuration} from "../../utils/helpers";
import { useAppSelector } from "redux/store";
import { CurrentEvent } from "Types";

export default function ActivityCards() {
    const activityStats = useAppSelector(state => state.activity.activityStats)

    return (
        <Row gutter={[16, 16]} className={"activity-cards-wrapper"}>
            {Object.keys(activityStats).map((eventKey) => getActivityTitle(eventKey) === 'null' ? null :
                <ActivityCard
                    key={eventKey}
                    title={getActivityTitle(eventKey)}
                    value={eventKey.startsWith('VIEW') ?
                        getFormatDuration(activityStats[eventKey as CurrentEvent]!?.duration)
                        : `${activityStats[eventKey as CurrentEvent]?.count} Times`}/>)}
        </Row>
    )
}