import React, {ComponentType} from 'react'
import { useAppSelector } from 'redux/store';
const withLayoutAuth = (Component: ComponentType) => {
    const NewComponent = () => {
        const isAuth = useAppSelector((state) => state.auth.isAuth)
        if(!isAuth) {
            return null;
        }
        return <Component/>
    }
    return NewComponent
}
export default withLayoutAuth