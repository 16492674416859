import React from 'react';
import DashboardPageChartsBar from "./DashboardPageChartsBar";
import {shallowEqual, useSelector} from "react-redux";
import NoData from "components/no-data/NoData";
import {checkDashboardData} from "utils/helpers";
import { Props as BarProps } from 'react-chartjs-2/dist/types';
import { useAppSelector } from 'redux/store';
import { ChartConfiguration } from 'chart.js';
import { IDatasets } from 'Interfaces/Dashboard/CheckIn';


export default function DashboardPageComplianceDailyChartsBar() {
    const complianceDailyData = useAppSelector(state => state.dashboard.complianceDailyData!, shallowEqual)
    const getChartsBarParams = (elem: IDatasets) => {
        switch (elem.label) {
            case 'Late': {
                return {...elem, backgroundColor: '#b6b5b5', stack: 2};
            }
            case 'Ok': {
                return {...elem, backgroundColor: '#c2ec9d', stack: 2};
            }
            case 'Training': {
                return {...elem, backgroundColor: '#ffb8e9', stack: 1};
            }
            case 'Food': {
                return {...elem, backgroundColor: '#fdd7af', stack: 1};
            }
            case 'Sleep': {
                return {...elem, backgroundColor: '#9ea3f5', stack: 1};
            }
            case 'Water': {
                return {...elem, backgroundColor: '#98e3ef', stack: 1};
            }
            case 'Stress': {
                return {...elem, backgroundColor: '#ffb6b6', stack: 1};
            }
            case 'Not checkin': {
                return {...elem, backgroundColor: '#e66060', stack: 2};
            }
            default:{
                return elem
            }
        }
    }

    const options: ChartConfiguration<'bar'>['options'] = {
        plugins: {
            title: {
                display: true,
                position: 'bottom',
                text: !!complianceDailyData?.title ? complianceDailyData.title : ''
            },
            tooltip: {
                callbacks: {
                    // label: function (complianceData) {
                    //     const {dataset: {value,label, data}, dataIndex} = complianceData
                    //     debugger;
                    //     return `${label}: ${!!value && value[dataIndex] > 0 ? value[dataIndex] : ''} (${data[dataIndex]}%)`
                    // }
                    label: function (complianceData) {
                        const {dataset: {label, data}, dataIndex} = complianceData
                        return `${label}: ${!!data && data[dataIndex] > 0 ? data[dataIndex] : ''} (${data[dataIndex]}%)`
                    }
                }

            },
        },
        scales: {
            yAxes:
                {
                    ticks: {
                        // beginAtZero: true,
                        callback: function (value) {
                            return `${value}%`;
                        }
                    },
                    max: 100,
                },
        }
    };

    const timeframesBarProps: BarProps = {
        type: 'bar',
        data: {
            ...complianceDailyData,
            datasets: Array.isArray(complianceDailyData?.datasets) ? complianceDailyData.datasets.map(elem => getChartsBarParams(elem)) : []
        },
        options: options,
    }

    return (
        checkDashboardData(complianceDailyData?.datasets) ?
            <DashboardPageChartsBar title={'Compliance overall (day breakdown)'} {...timeframesBarProps}/> : <NoData description="Compliance overall (day breakdown) (No Data)"/>
    )
}