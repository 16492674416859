import React, { FC, useEffect } from 'react';
import { useAppSelector } from 'redux/store';
import {Form, Select} from 'antd';
import { Modal } from 'components/Antd/Modal';
interface Props {
    selectedRowKeys: React.Key[],
    onCancel: () => void,
    onFinish: (data: {
        managerIdList: React.Key[];
    }) => Promise<void>

}

const UnAssignManagerModal: FC<Props> = ({onCancel, onFinish, selectedRowKeys}) => {
    const unAssignManagerModalVisible = useAppSelector((state) => state.modals.unAssignManagerModalVisible);
    const managersData = useAppSelector((state) => state.coaches.managersData);
    const coachesData = useAppSelector((state) => state.coaches.coachesData);
    const [form] = Form.useForm<{managerIdList: string[]}>();


    const coachManagers = coachesData.filter((coach) => selectedRowKeys.includes(coach.id));

    const defaultSelectValue = selectedRowKeys.length === 1 &&
    managersData.filter((manager) => 
    coachManagers.find((coachManager) => 
        coachManager.managers.find((assignedManager) => assignedManager.id === manager.id)
    )).map(({id}) => id) || [];

    const onClose = () => {
        onCancel();
        form.resetFields();
    }


    useEffect(() => {
        form.setFieldsValue({
            managerIdList: defaultSelectValue
        })
    }, [selectedRowKeys])

    return (
        <Modal 
        title="Unassign Manager"
        centered
        onCancel={onClose}
        onOk={() => {
            form
            .validateFields()
            .then((values) => {
                onFinish(values)
                onCancel();
            })
        }}
        visible={unAssignManagerModalVisible}
        >
            <Form
            form={form}
            >
                <Form.Item
                name="managerIdList"
                rules={[{
                      validator: (_ , managerIdList) => {
                        if(managerIdList.length === 0){
                            return Promise.reject('Please choose a manager');
                        }
                        return Promise.resolve();
                  }
                }]}       
                >
                    <Select
                    mode="multiple"
                    defaultValue={defaultSelectValue}
                    placeholder="Choose a manager"
                    >
                        {
                            managersData.length > 0 && 
                            managersData.map(({fullName, id}) => (
                                <Select.Option value={id}>{fullName}</Select.Option>
                            ))
                        }
                    </Select>
                </Form.Item>
            </Form>   
        </Modal>
    )

};



export default UnAssignManagerModal;