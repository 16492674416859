import React, {memo} from 'react';
import moment from "moment";
import {Message} from "@chatscope/chat-ui-kit-react";
import {emojifyMessage, getDateMessage} from "utils/helpers";
import {FormOutlined, LoadingOutlined, CheckOutlined, ExclamationOutlined} from "@ant-design/icons";
import {Space, Tag, Typography} from "antd";
import {DATE_FORMAT, user} from "vars";
import {Popover} from "antd";
import { useAppSelector } from 'redux/store';
import { IChat_Message } from 'Interfaces/Chat';

const _Message = ({
                    authorName,
                    notes,
                    date,
                    checkInId,
                    authorRole,
                    usersSeen,
                    isRead,
                    isSendingMessage = false,
                    isSendingMessageError = false,
                    isNotAnsweredForDay = false,
                    isNotAnsweredForTwoDays = false,
                  }: IChat_Message) => {
  const onOpenCurrentCheckin = () => window.open(`/checkins?checkInId=${checkInId}`);
  const authUser = useAppSelector((state) => state.auth.user!);

    return (
        <Message
            className={`${(authorName === authUser.fullName) || authorName === null ? 'chat-mobile-author-message' : ''} 
            ${authorRole !== user && authorName !== authUser.fullName && authorName !== null ? 'chat-mobile-outgoing-message' : ''}`}
            model={{
                sentTime: moment(date).format(DATE_FORMAT),
                // message: notes,
                sender: authorName,
                direction: authorRole !== user ? 'outgoing' : 'incoming',
                position: 'single',
            }}
        >
            <Message.CustomContent>
                <Message.Header
                style={{
                    justifyContent: authorName === authUser.fullName ? 'flex-end' : 'space-between'
                }}
                >
                    {!authorName && 'System'}
                    {authorName && authorName !== authUser.fullName && authorName}
                    {checkInId && <FormOutlined onClick={onOpenCurrentCheckin} style={{color: '#227d93', cursor: 'pointer'}} />}
                </Message.Header>
                {emojifyMessage(notes)}
                <Message.Footer sentTime={getDateMessage(date)} >
                        <div>
                            {isNotAnsweredForDay && <Tag color="red">24h</Tag>}
                            {isNotAnsweredForTwoDays && <Tag color="red">48h</Tag>}
                            {isSendingMessage && !isSendingMessageError && <LoadingOutlined/>}
                            {/*<EyeInvisibleOutlined />*/}
                        </div>
                        <div>
                            <Typography.Text style={{marginLeft: 5}}>
                                {getDateMessage(date)}
                            </Typography.Text>
                        </div>
                         <div>
                        {!isSendingMessage && !isSendingMessageError && usersSeen.length > 0 &&
                        <Popover
                            destroyTooltipOnHide
                            placement="bottomLeft"
                            overlayInnerStyle={{
                                backgroundColor: authorName === authUser.fullName ? '#EEFFDE' : '#c6e3fa'
                            }}
                            content={<Space direction="vertical">
                                {
                                    usersSeen?.map((user) => (
                                        <div key={user.userId} style={{display: 'flex', justifyContent: 'space-between', fontSize: 12}}>
                                            <div style={{marginRight: 5}}>{user.userName === authUser.fullName ? 'Me:' : `${user.userName}:`}</div>
                                            <div>{getDateMessage(user.date)}</div>
                                        </div>
                                    ))
                                }
                            </Space>
                            }
                        >
                            <CheckOutlined
                                style={{
                                    color: usersSeen.length > 0 ? '#109cbb' : '#5e5a5a',
                                    fontSize: 16,
                                    marginLeft: 5,
                                    marginRight: 0,
                                    cursor: 'pointer'
                                }}
                            />
                        </Popover>}
                        {!isSendingMessage && !isSendingMessageError && !usersSeen.length &&
                        <CheckOutlined
                            style={{ color: isRead ? '#109cbb' : '#5e5a5a', fontSize: 16, marginLeft: 5, marginRight: 0}}
                        />}
                        {!isSendingMessage && isSendingMessageError &&
                        <ExclamationOutlined style={{color: '#bb1717', fontSize: 16, marginLeft: 5, marginRight: 0}}/>}
                        </div>
                </Message.Footer>
            </Message.CustomContent>
        </Message>
    )
};

export default memo(_Message)