import React, {useEffect, useState} from 'react';
import { useAppSelector } from 'redux/store';
import {Space, Card, Form, Button,} from 'antd';
import NotesForm from "./NotesForm";
import NotesList from "./NotesList";
import './styles/notes.css'
import {EDIT_NOTES} from "vars";
import antNotificationComponent from "utils/notification-ant-utils";
import usePersistedStateWithoutIdKey from "hooks/usePersistedStateWithoutIdKey";
import {useParams} from "react-router-dom";
import { Canceler } from 'axios';
import { IClientNote, IClientNoteEmoji } from 'Interfaces/ClientNote';
import { INoteQuery } from 'services/notes-service';
import { CurrentEvent } from 'Types';
import { IMessageStatus } from 'Interfaces/Auth';


interface INoteCache {
    id: string,
    notes: string
}

interface INoteForm {
    notes: string
}

export interface INotesProps {
    notesApiCancel: Canceler,
    getNotes: (query: INoteQuery) => Promise<void>
    addNote: (id: string, values: {
        notes: string;
    }) => Promise<IClientNote | undefined>,
    setUserActivity: (activityType: CurrentEvent) => Promise<void>
    notesPageUpdate: (page: number) => void,
    deleteActions: ({ actionId }: {
        actionId: string;
    }) => Promise<IMessageStatus | undefined>,
    setActions: ({ action, notesId, actionDetail }: {
        action: string;
        actionDetail: string;
        notesId: string;
    }) => Promise<IClientNoteEmoji | undefined>
}

export default function Notes(props: INotesProps) {
    const {setUserActivity, notesApiCancel, getNotes, addNote} = props
    const {id} = useParams() as {id: string};
    const page = useAppSelector(state => state.userNotes.page)
    const lastUpdate = useAppSelector(state => state.userNotes.lastUpdate)
    const [notesForm] = Form.useForm<INoteForm>();
    const [loading, setLoading] = useState<boolean>(true)
    const [savedNotesByIdToStorage,setSavedNotesByIdToStorage] = usePersistedStateWithoutIdKey<INoteCache[]>('notes', []);
    const onReset = () => notesForm.resetFields();

    const onFinish = async (values: INoteForm) => {
        const data = await addNote(id, values)
        if (!!data) {
            await setUserActivity(EDIT_NOTES)
            antNotificationComponent({message: "Saved", type: 'success'})
            const cachedNotes = savedNotesByIdToStorage.filter(feedback => feedback.id !== id);
            setSavedNotesByIdToStorage(cachedNotes);
            onReset()
        } else {
            antNotificationComponent({message: "Failed to save", type: 'error'})
            const cachedNotes = savedNotesByIdToStorage.filter(note => note.id !== id);
             setSavedNotesByIdToStorage([...cachedNotes, {
                id,
                notes: values.notes,
            }])
        }

    }

    useEffect(() => {
        let cleanupFunction = false;
        const fetchData = async () => {
            setLoading(true)
            await getNotes({page, userId: id})
            if (!cleanupFunction) {
                setLoading(false)
            }
        }
        fetchData();

        return () => {cleanupFunction = true};
    }, [lastUpdate])

    useEffect(() => () => notesApiCancel(), [])


    useEffect(() => {
        if(id){
            if(savedNotesByIdToStorage.length){
                const {notes} = savedNotesByIdToStorage.length > 0 ?
                savedNotesByIdToStorage
                    .filter((feedback) => feedback.id === id)
                    .reduce((prev, {notes}) => ({
                        notes,
                    }), {notes: ""}) : {notes: ""};
                    if(notes){
                        notesForm.setFieldsValue({
                            notes,
                        })

                    }
            }
           
        }
    }, [id])

    const notesListProps = {
        loading,
        userId: id,
        ...props
    }

    return (
        <Space direction="vertical">
            <Card title="Add Notes" size="small" className={'noBg'}>
                <Form form={notesForm} onFinish={onFinish} labelCol={{span: 24}}>
                    <NotesForm />
                    <Form.Item>
                        <Space>
                            <Button onClick={onReset}>Reset</Button>
                            <Button htmlType={'submit'} type="primary">Save Note</Button>
                        </Space>
                    </Form.Item>
                </Form>
            </Card>
            <NotesList {...notesListProps}/>
        </Space>
    )
}