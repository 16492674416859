import React, { FC } from 'react';
import { useAppSelector } from 'redux/store';
import { Typography } from 'antd';
import { Modal } from 'components/Antd/Modal';

interface Props {
    selectedManagersFullName: () => string,
    onOk: () => Promise<void>,
    onCancel: () => {
        payload: boolean;
        type: string;
    }
}

const DeleteManagersModal: FC<Props> = ({selectedManagersFullName, onOk, onCancel}) => {
    const removeManagersModalVisible = useAppSelector((state) => state.modals.removeManagersModalVisible);


    return (
        <Modal
        visible={removeManagersModalVisible}
        title="Delete managers"
        onCancel={onCancel}
        onOk={onOk}
        centered
        >
             <Typography.Paragraph>
                Are you sure you want to delete following managers?
            </Typography.Paragraph>
            <Typography.Text>
                {selectedManagersFullName()}
            </Typography.Text>
        </Modal>
    )

};



export default DeleteManagersModal;