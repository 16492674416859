import React from 'react';
import DashboardPageChartsPie from "./DashboardPageChartsPie";
import {checkDashboardData, getChartLabelPercentageMultiply} from "../../utils/helpers";
import NoData from "../../components/no-data/NoData";
import {shallowEqual} from "react-redux";
import {SHOW_PERCENTAGE_CHART} from "../../vars";
import { ChartConfiguration } from 'chart.js';
import { Props as PieProps } from 'react-chartjs-2/dist/types';
import { useAppSelector } from 'redux/store';

const DashboardPageCheckInsPie = () => {
    const checkinsStatsData = useAppSelector(state => state.dashboard.statsCheckins, shallowEqual);
    const [activeCheckined, activeCheckinedLate, inactiveMissedOne, inactiveMissedTwoPlus] = checkinsStatsData;
    const labels = ['Active', 'Inactive', 'Checked-in', 'Late', 'Missed 1', 'Missed 2+'];

    const checkinsPieData: ChartConfiguration<'pie'>['data'] = {
        labels,
        datasets: [
            {
                backgroundColor: ['#c2ec9d','#ffb6b6','#a3f06c','#b6b5b5','#e66060','#cc2727'],
                data: [0,0, +activeCheckined?.value || 0,+activeCheckinedLate?.value || 0, +inactiveMissedOne?.value || 0, +inactiveMissedTwoPlus?.value || 0],
                label: 'Active',
            },
            {
                data: [+activeCheckined?.value + +activeCheckinedLate?.value || 0, +inactiveMissedOne?.value + +inactiveMissedTwoPlus?.value || 0],
                label: 'Inactive',
                backgroundColor: ['#c2ec9d','#ffb6b6']
            },
        ],
    }

    const checkinsPieOptions: ChartConfiguration<'pie'>['options'] = {
            plugins: {
                tooltip: {
                    callbacks: {
                        label: getChartLabelPercentageMultiply(labels)
                    }
                },
                legend: {
                    // display: true,
                    position: 'bottom',
                    align: 'start',
                    labels: {
                        padding: 20,
                    },
                },
                datalabels: {
                    formatter: (value, {dataset: {data}}) => {
                        const sumData = data.reduce((acc: number, curr) => +acc + +curr!, 0);
                        const percentage = (value / +sumData) * 100;
                        return percentage >= SHOW_PERCENTAGE_CHART ? value : null;
                    }
                }
            },
    }
    const checkinsPieProps: PieProps = {
        type: 'pie',
        data: checkinsPieData,
        options: checkinsPieOptions,
        width: 410,
        height: 410
    }

    return (
        checkDashboardData(checkinsPieData.datasets) ?
        <DashboardPageChartsPie title="Client summary" {...checkinsPieProps} /> :
        <NoData description="Client summary (No Data)" />
    )
};


export default DashboardPageCheckInsPie;