import React, {useEffect, useState} from 'react';
import {Image, Row, Col, Space, Spin, Typography, Empty} from 'antd';
import InfiniteScroll from 'react-infinite-scroll-component';
import Photo from "./photo"
import './styles/photos.css'
import moment from "moment";
import {isPlatform} from "../../utils/helpers";
import { useAppSelector } from 'redux/store';
import { ICheckIn } from 'Interfaces/CheckIn';
import { IPhoto } from 'services/photo-service';
import { Modal } from 'components/Antd/Modal';
import { Canceler } from 'axios';
import { DATE_FORMAT_HOURS_MINUTES } from 'vars';
import { SummaryMiniDashboard } from './SummaryDashboard';
import NoData from 'components/no-data/NoData';

interface Props {
    photosApiCancel: Canceler,
    getPhoto: (id: string) => Promise<string | undefined>,
    setPhotos: (photos: IPhoto[]) => void,
    setImgActivity: (flag: boolean) => void,
    setImgPreviewGroup: (flag: boolean) => void,
}

export default function Photos(props: Props) {
    const {setImgPreviewGroup, setPhotos} = props
    const checkInSelectedRowData = useAppSelector(state => state.checkIns.checkInSelectedRowData)
    const visibleGroupPreview = useAppSelector(state => state.photos.visibleGroupPreview)
    const groupPreview = useAppSelector(state => state.photos.groupPreview)
    const groupPreviewCheckInCounts = useAppSelector(state => state.photos.groupPreviewCheckInCounts.data)
    const checkInCountsLoading = useAppSelector(state => state.photos.groupPreviewCheckInCounts.isLoading)
    const activeTabKey = useAppSelector(state => state.user.activeTabKey)

    const [photoComponents, setPhotoComponents] = useState<(JSX.Element)[]>([])
    const [hasMore, setHasMore] = useState(true)
    const [photosCount, setPhotosCount] = useState(0)

    const isMacOS = isPlatform('MAC')

    const loadLatestCheckIns = (data: ICheckIn[])=> {
        return data.reduce<IPhoto[]>((acc , {date, weight, waist, hips, ...checkInData}, index) => {
            const photoCheckInInfo = { date, weight, waist, hips, checkInId: checkInData.id };
            return [
                ...acc,
                {
                    id: `${date}-i:${index}front`,
                    type: 'front',
                    src: checkInData.frontPhotoId,
                    ...photoCheckInInfo
                },
                {
                    id: `${date}-i:${index}back`,
                    type: 'back',
                    src: checkInData.backPhotoId,
                    ...photoCheckInInfo
                },
                {
                    id: `${date}-i:${index}profile`,
                    type: 'profile',
                    src: checkInData.profilePhotoId,
                    ...photoCheckInInfo
                }
            ]
        }, [])
    }

    const photoComponent = (data: IPhoto) => (
        <Col span={8} key={data.id}>
            <Photo key={data.id} photoData={data}
                isMacOS={isMacOS}
                {...props}/>
        </Col>
    )

    const fetchMoreData = (currentCount: number, dataLength: number) => {
        const data = []
        for (let i = 0; i < currentCount + 3; i++) {
            if (i > dataLength) {
                setHasMore(false)
            } else {
                setHasMore(true)
                !!checkInSelectedRowData[i] && data.push(checkInSelectedRowData[i])
                setPhotosCount(i)
            }
        }

        const photos = loadLatestCheckIns(data).map((elem: IPhoto) => photoComponent(elem));
        setPhotoComponents(photos)
    }

    useEffect(() => {
        if (activeTabKey === '6') {
            fetchMoreData(photosCount, checkInSelectedRowData.length)
        }
        setPhotos([])
    }, [activeTabKey])

    if (!photoComponents.length) return <NoData style={{ justifyContent: 'center' }}/>; 

    return (
        <>
            <InfiniteScroll
                className="photos_scroll_component"
                dataLength={photoComponents.length}
                next={() => fetchMoreData(photosCount + 1, checkInSelectedRowData.length)}
                hasMore={hasMore}
                height={'66vh'}
                scrollThreshold={0.99}
                loader={<Row gutter={[24, 24]} justify="center"><Col span={2} style={{margin: '10px 0'}}><Spin
                    size={'small'}/></Col></Row>}
            >
                <Row gutter={[24, 24]} justify="start" wrap>
                    {photoComponents.length > 0 && photoComponents}
                </Row>
            </InfiniteScroll>
            <div style={{display: 'none'}}>
                <Modal centered wrapClassName={'photos_modal_preview_wrapper'} visible={visibleGroupPreview}
                       onCancel={() => setImgPreviewGroup(false)} footer={null} width={'100%'}>
                    <Row justify="center">
                        <SummaryMiniDashboard
                            selectedCheckIns={
                                checkInSelectedRowData
                                    .filter((checkIn) => !!groupPreview.find(({ checkInId }) => checkInId === checkIn.id))
                            }
                            selectedCheckInsCounts={groupPreviewCheckInCounts}
                            areCheckInsLoading={checkInCountsLoading}
                            photoView={true}
                            placeHolderText='Choose photos of at least 2 different check ins to see that stats'
                        />
                    </Row>
                    <Row gutter={[24, 24]} justify="center">
                        {groupPreview.map(({id, src, date}) => (
                                <Col span={8} key={id}
                                     className={'photos_modal_preview_content'}>
                                    <Space className={'photos_modal_preview_text'}>
                                        {!!date && <span>{moment(date).format(DATE_FORMAT_HOURS_MINUTES)}</span>}
                                    </Space>
                                    <Image preview={false} src={src ?? undefined}
                                           className={'photos_modal_preview_image'}/>
                                </Col>
                            )
                        )}
                    </Row>
                </Modal>
            </div>
        </>
    )
}