import React from 'react';
import {Card, Statistic, StatisticProps} from "antd";


export interface IWidgetPageCardProps extends StatisticProps {
    data: {
        type: string | number,
        value: string | number | undefined,
    } | null,
    className?: string,
    children: React.ReactNode
};


export default function WidgetPageCard({
                                           data,
                                           className = 'dashboard_kpi_card_white',
                                           valueStyle = {color: '#000'},
                                           suffix = "",
                                           children = null,
                                           prefix = ''
                                       }: IWidgetPageCardProps) {
    return (
        <Card className={`dashboard_kpi_card ${className}`}>
            <Statistic
                title={data?.type || 0}
                value={data?.value || 0}
                valueStyle={valueStyle}
                suffix={suffix}
                prefix={prefix}
            />
            {children}
        </Card>)
}