import React, { FC } from 'react';
import {Modal as M, Typography} from "antd";
import { useAppSelector } from 'redux/store';
import { ModalProps } from 'antd';
const {Text} = Typography;


interface IModalProps extends ModalProps {
    children: React.ReactNode
}

const Modal:FC<IModalProps> = ({children, ...rest}) => {
    return <M
    {...rest}
    />
}; 

interface Props {
    onFinish: ModalProps['onOk'],
    onCancel: ModalProps['onCancel'],
    title?: string,
    message?: string,
}

const ClientTypeDeleteModal: FC<Props> = ({onFinish, onCancel, title, message}) => {

    const visible = useAppSelector((state) => state.modals.removeClientTypeModalVisible);

    return <Modal
    title="Remove Type"
    onOk={onFinish}
    centered
    visible={visible}
    onCancel={onCancel}
    >
        <Text>{title}</Text>
        <Text>{message}</Text>
    </Modal>
};


export default ClientTypeDeleteModal;