import React from 'react';
import {changeSortDirectionTitle, getMacroCalories, getMacroTag, getPopoverTextWithIcon} from "utils/helpers";
import {dateFormat, deltaRender, flagRender, scoreRender} from "./check_in_tab_table_config_render";
import {Tag} from "antd";
import { ICheckIn } from 'Interfaces/CheckIn';
import { ColumnsType } from 'antd/es/table';
import { SortTable } from 'Types';
import { UserData } from 'redux/reducers/userReducer';
import moment from "moment";

interface IClientCheckInTabTableConfigParams {
    s_field?: string | null | React.Key,
    s_direction?: SortTable | null,
    user: UserData
}

const ClientCheckInTabTableConfig = ({s_field, s_direction, user}: IClientCheckInTabTableConfigParams) => {

    const {LowMacro, StandardMacro, HighMacro} = user;

    const Macro = [LowMacro, StandardMacro, HighMacro];

    const getColumns: ColumnsType<ICheckIn> = [
            {
                title: 'Date',
                key: 'date',
                dataIndex: 'date',
                className: 'table-column-title-no-break',
                render: (text) => dateFormat(text),
                sorter: true,
                sortOrder: s_field === 'date' ? changeSortDirectionTitle(s_direction) as SortTable : null
            },
            {
                title: 'Week',
                key: 'week',
                dataIndex: 'week',
                className: 'table-column-title-no-break',
                sorter: true,
                sortOrder: s_field === 'week' ? (changeSortDirectionTitle(s_direction) as SortTable) : null
            },
            {
                title: 'Coach',
                dataIndex: ['User','Coach', 'fullName'],
                className: 'table-column-title-no-break',
                key: 'coach',
                render: (_,r) => getPopoverTextWithIcon(r?.User?.Coach?.fullName)
            },
            {
                title: 'Goal',
                dataIndex: 'physiqueGoal',
                key: 'goal',
                // render: (_,r) => getPopoverTextWithIcon(r?.User?.currentGoal, 30)
            },
            {
                title: 'Weight',
                key: 'weight',
                dataIndex: "weight",
                render: (text, record) => deltaRender({
                    current: text,
                    delta: record.weightDifference,
                    property: "weight",
                    user: record.User,
                    smallest: record.smallestWeight
                }),
                sorter: true,
                sortOrder: s_field === 'weight' ? (changeSortDirectionTitle(s_direction) as SortTable) : null
            },
            {
                title: 'Waist',
                key: 'waist',
                dataIndex: "waist",
                render: (text, record) => deltaRender({
                    current: text,
                    delta: record.waistDifference,
                    property: "waist",
                    user: record.User,
                    smallest: record.smallestWaist
                }),
                sorter: true,
                sortOrder: s_field === 'waist' ? (changeSortDirectionTitle(s_direction) as SortTable) : null
            },
            {
                title: 'Menstruating',
                key: 'menstruating',
                dataIndex: "menstruating",
                render: (text, record) => !!record.menstruating && <Tag color="red">Yes</Tag>,
                sorter: true,
                sortOrder: s_field === 'menstruating' ? (changeSortDirectionTitle(s_direction) as SortTable) : null
            },
            {
                title: 'Actual Cals',
                key: 'actualCalories',
                dataIndex: "actualCalories",
                className: 'table-column-title-no-break',
                sorter: true,
                sortOrder: s_field === 'actualCalories' ? (changeSortDirectionTitle(s_direction) as SortTable) : null
            },
            {
                title: 'Flag',
                key: 'flag',
                dataIndex: "flag",
                render: flagRender,
            },
            {
                title: 'Assessment',
                key: 'guidance',
                dataIndex: "guidance",
            },
            {
                title: 'Sick',
                key: 'sick',
                dataIndex: "sick",
                render: (t, r) => !!r.sick && <Tag color="red">Yes</Tag> ,
                sorter: true,
                sortOrder: s_field === 'sick' ? (changeSortDirectionTitle(s_direction) as SortTable) : null
            },
            {
                title: 'Calories',
                key: 'averageCalories',
                dataIndex: 'averageCalories',
                render: (v) => getMacroCalories(v, Macro),
                sorter: true,
                sortOrder: s_field === 'averageCalories' ? (changeSortDirectionTitle(s_direction) as SortTable) : null
            },
            {
                title: 'Protein',
                key: 'averageProtein',
                dataIndex: 'averageProtein',
                render: (v) => getMacroTag(v, 'protein', Macro),
                sorter: true,
                sortOrder: s_field === 'averageProtein' ? (changeSortDirectionTitle(s_direction) as SortTable) : null

            },
            {
                title: 'Carbohydrates',
                key: 'averageCarbohydrates',
                dataIndex: 'averageCarbohydrates',
                render: (v) => getMacroTag(v, 'carb', Macro),
                sorter: true,
                sortOrder: s_field === 'averageCarbohydrates' ? (changeSortDirectionTitle(s_direction) as SortTable) : null
            },
            {
                title: 'Fat',
                key: 'averageFat',
                dataIndex: 'averageFat',
                render: (v) => getMacroTag(v, 'fat', Macro),
                sorter: true,
                sortOrder: s_field === 'averageFat' ? (changeSortDirectionTitle(s_direction) as SortTable) : null
            },
            {
                title: 'Hips',
                key: 'hips',
                dataIndex: "hips",
                render: (text, record) => deltaRender({
                    current: text,
                    delta: record.hipsDifference,
                    property: "hips",
                    user: record.User,
                    smallest: record.smallestHips
                }),
                sorter: true,
                sortOrder: s_field === 'hips' ? (changeSortDirectionTitle(s_direction) as SortTable) : null
            },
            {
                title: 'Lower Belly',
                key: 'lowerBelly',
                dataIndex: "lowerBelly",
                render: (text, record) => deltaRender({
                    current: text,
                    delta: record.lowerBellyDifference,
                    property: "lowerBelly",
                    user: record.User,
                    smallest: null
                }),
                sorter: true,
                sortOrder: s_field === 'lowerBelly' ? (changeSortDirectionTitle(s_direction) as SortTable) : null
            },
            {
                title: 'Upper Quad',
                key: 'upperQuad',
                dataIndex: "upperQuad",
                render: (text, record) => deltaRender({
                    current: text,
                    delta: record.upperQuadDifference,
                    property: "upperQuad",
                    user: record.User,
                    smallest: null
                }),
                sorter: true,
                sortOrder: s_field === 'upperQuad' ? (changeSortDirectionTitle(s_direction) as SortTable) : null
            },
            {
                title: 'Mid Quad',
                key: 'midQuad',
                dataIndex: "midQuad",
                render: (text, record) => deltaRender({
                    current: text,
                    delta: record.midQuadDifference,
                    property: "midQuad",
                    user: record.User,
                    smallest: null

                }),
                sorter: true,
                sortOrder: s_field === 'midQuad' ?(changeSortDirectionTitle(s_direction) as SortTable) : null
            },
            {
                title: 'Training',
                key: 'trainingScore',
                dataIndex: "trainingScore",
                sorter: true,
                sortOrder: s_field === 'trainingScore' ? (changeSortDirectionTitle(s_direction) as SortTable) : null
            },
            {
                title: 'Food',
                key: 'foodScore',
                dataIndex: "foodScore",
                render: (text) => scoreRender(text),
                sorter: true,
                sortOrder: s_field === 'foodScore' ? (changeSortDirectionTitle(s_direction) as SortTable) : null
            },
            {
                title: 'Water',
                key: 'waterScore',
                dataIndex: "waterScore",
                render: (text) => scoreRender(text),
                sorter: true,
                sortOrder: s_field === 'waterScore' ? (changeSortDirectionTitle(s_direction) as SortTable) : null
            },
            {
                title: 'Stress',
                key: 'stressScore',
                dataIndex: "stressScore",
                render: (text) => scoreRender(text),
                sorter: true,
                sortOrder: s_field === 'stressScore' ? (changeSortDirectionTitle(s_direction) as SortTable) : null
            },
            {
                title: 'Sleep',
                key: 'sleepScore',
                dataIndex: "sleepScore",
                render: (text) => scoreRender(text),
                sorter: true,
                sortOrder: s_field === 'sleepScore' ? (changeSortDirectionTitle(s_direction) as SortTable) : null
            },
        ]

    return {getClientCheckInColumns: getColumns}
};

export default ClientCheckInTabTableConfig
