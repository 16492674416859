import React from 'react';
import { useAppSelector } from 'redux/store';
import RatioGauge from "./RatioGauge";


const RatioGauge_Average = () => {
const ratio = useAppSelector((state => state.user.ratio.AverageMacro));

return (
    <RatioGauge ratio={ratio} />
)
};

export default RatioGauge_Average;