import React from "react";
import {admin, coach, DATE_FORMAT_HOURS_MINUTES, user} from "../../../../vars";
import {Button, Space, Tag} from "antd";
import {AuditOutlined, EditOutlined} from "@ant-design/icons";
import {useHistory} from "react-router-dom";
import moment from "moment";
import { ColumnProps } from "antd/lib/table";
import { ICheckIn } from "Interfaces/CheckIn";
import { MacroType } from "Types";
import { IClient, IMacro } from "Interfaces/User";

export const roleRender: ColumnProps<IClient>['render'] = (role) => {
    if (role === admin) {
        return <Tag color={"purple"}>{role ? role.toUpperCase() : ""}</Tag>
    }

    if (role === coach) {
        return <Tag color={"blue"}>{role ? role.toUpperCase() : ""}</Tag>
    }

    if (role === user) {
        return <Tag color={"green"}>{role ? role.toUpperCase() : ""}</Tag>
    }

    return null
}

export const macroRender = (t: any, r: IClient, i: number, prop: MacroType, id: keyof IClient)=> {
    if (r[prop]) {
        return <>{Math.trunc(+r[prop].protein) + "p " + Math.trunc(+r[prop].fat) + "f " + Math.trunc(+r[prop].carb) + "c"}</>
    }
    if (r[id]) {
        return <>Y</>
    }

    return <>-</>
}

export const activeRender: ColumnProps<IClient>['render'] = (active) => <Tag color={!!active ? "volcano" : "green"}>{!!active ? "Disabled" : "Active"}</Tag>

export const ActionsRender: ColumnProps<IClient>['render'] = (text, record) => {

    const history = useHistory()

    return (
        <Space size="middle">
            <Button shape="circle" type="primary"
                    onClick={() => window.open(`/client/${record.id}`, "_blank")} icon={<AuditOutlined/>}/>
        </Space>
    )
}

export const UsersActionsRender= (text: any, record: IClient, _: number, actions?: {name: string, callback: (user: IClient) => void}[]) => {
    const history = useHistory()

    const extraActions = () => {
        return actions?.map((action, index) => {
            if (action.name === 'editModal') {
                return <Button key={index}
                               shape="circle"
                               type="primary"
                               icon={<EditOutlined/>}
                               onClick={() => action.callback(record)}/>
            }
            return null
        })
    }

    return (
        <Space size="middle">
            {extraActions()}
            <Button shape="circle" type="primary"
                    onClick={() => history.push("/client/" + record.id)} icon={<AuditOutlined/>}/>
            {/*{record.apiKey && authUser.roles === admin && <Button shape="circle" onClick={() => onCopyToClipboard(record.apiKey)} type="primary" icon={<SafetyOutlined />} />}*/}
        </Space>
    )
}


export const LateRender: ColumnProps<IClient>['render'] = (text)  => {
    if (typeof text === "boolean" || typeof text === "number") {
        return text ? <Tag color="volcano">Late</Tag> : null
    }
    return null
}

export const CompleteRender: ColumnProps<IClient>['render'] = (text)  => {
    if (typeof text === "boolean" || typeof text === "number") {
        return text ? <Tag color="green">Yes</Tag> : null
    }
    return null
}

export const YesNoRender: ColumnProps<IClient>['render'] = (text)  => {
    if (typeof text === "boolean" || typeof text === "number") {
        return text ? <Tag color="green">Yes</Tag> : <Tag color="volcano">No</Tag>
    }
    return null
}

export const dateFormatRender: ColumnProps<IClient>['render'] = (date) => {
    if (!!date) {
        return moment(date.date).format(DATE_FORMAT_HOURS_MINUTES)
        // return new Intl.DateTimeFormat('en-AU', {
        //     year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric'
        // }).format(Date.parse(date))
    }
    return null
}