import React from 'react';
import {Col, Row, Image} from "antd";
import WidgetPageCard from "../dashboard/WidgetPageCard";
import {checkFormatDuration} from "utils/helpers";
import feedbackTimeIcon from 'img/noun-late.svg';
import feedbackSentIcon from 'img/noun-feedback.svg';
import checkInsIcon from 'img/noun-checkin.svg';
import averageComplianceIcon from 'img/noun-outstanding.svg';

interface Props {
    averageCompliance: string | number,
    coachAverageFeedbackTime: string | number,
    coachCheckIns: string | number,
    coachFeedbackSent: string | number
}

export default function CoachClientsWidgetCardsRow({
    averageCompliance, 
    coachAverageFeedbackTime, 
    coachCheckIns, 
    coachFeedbackSent
}: Props) {
    return (
        <Row className={'coach_main_body_row'}>
            <Col xl={6} lg={12} md={24}>
                <WidgetPageCard 
                    data={{type: "Avg. Compliance", value: averageCompliance}} 
                    suffix={'%'}
                    children={
                    <Image 
                        src={averageComplianceIcon} 
                        width={60} 
                        preview={false} 
                    />}
                />
            </Col>
            <Col xl={6} lg={12} md={24}>
                <WidgetPageCard 
                    data={{type: "Check Ins", value: coachCheckIns}}
                    children={
                        <Image 
                        src={checkInsIcon} 
                        width={60} 
                        preview={false} 
                    />}
                />
            </Col>
            <Col xl={6} lg={12} md={24}>
                <WidgetPageCard 
                    data={{type: "Feedback Sent", value: coachFeedbackSent}}
                    children={
                        <Image 
                            src={feedbackSentIcon} 
                            width={60} 
                            preview={false} 
                        />}
                />
            </Col>
            <Col xl={6} lg={12} md={24}>
                <WidgetPageCard 
                    data={{type: "Avg. Time for Feedback", value: checkFormatDuration(coachAverageFeedbackTime)}}
                    children={
                        <Image 
                        src={feedbackTimeIcon} 
                        width={60} 
                        preview={false} 
                    />}
                />
            </Col>
        </Row>
    )
}