import React, { FC } from 'react';
import SummaryMiniDashboard, { DashboardListItem } from './SummaryMiniDashboard';

import { useAppSelector } from 'redux/store';

type ChosenCheckInsSummaryProps = {
  dashboardList?: DashboardListItem[];
}

const ChosenCheckInsSummary: FC<ChosenCheckInsSummaryProps> = ({ dashboardList }) => {
    const areCheckInsLoading = useAppSelector(state => state.checkIns.isLoading);
    const checkInSelectedRowData = useAppSelector(state => state.checkIns.checkInSelectedRowData);
    const checkInSelectedCounts = useAppSelector(state => state.checkIns.checkInSelectedCounts);

    return (
      <SummaryMiniDashboard
        selectedCheckIns={checkInSelectedRowData}
        selectedCheckInsCounts={checkInSelectedCounts}
        areCheckInsLoading={areCheckInsLoading}
        placeHolderText='Choose at least 2 checkIns in Check Ins tab to see the summary'
        dashboardList={dashboardList}
      />
    )
}

export default ChosenCheckInsSummary;
