import React, {useState} from 'react';
import { useAppSelector } from 'redux/store';
import { Space, Form} from 'antd';
import AssignCoachModal from "pages/client/assignCoachModal";
import {admin, manager, user} from "vars";
import AddUserForm from "./Form/AddUserForm";
import UsersPageHeader from "./users_page/UsersPageHeader";
import withSidebarNavigation from "HOCs/withSidebarNavigation";
import useUsersApiWithRedux, { IUserForm } from "services/users-service";
import antNotificationComponent from "utils/notification-ant-utils";
import EditUserForm from "./Form/EditUserForm";
import useUserApiWithRedux from "services/user-service";
import useModals from "services/modals-service";
import withPermission from "HOCs/withPermission";
import usePersistedState from "hooks/usePersistedState";
import UserTableWrapper from "./users_page/UserTableWrapper";
import { IClient } from 'Interfaces/User';
import RemoveCoachModal from 'pages/client/removeCoachModal';
import { getUserFormData } from 'utils/helpers';


//NOTES: Based on a demo table, added a pop-up editor via modal and form.
//I used functions here rather than classes/extend React.Component as this causes issues getting a reference to the form in order to populate it.
//Looking to upgrade table to ProTable (has back-end linkages and search and stuff... but I was having troubles getting it to work)
//Data is populated locally, but shouldn't be much of a hassle to change to using a fetch to get the objects.


export interface IUserFormSubmit extends IUserForm  {
    phoneNumber?: string | null
    // setMobileError: React.Dispatch<React.SetStateAction<string>>
};

function UserList() {
    const props = useUsersApiWithRedux();
    const {updateUserData} = useUserApiWithRedux()
    const {setRemoveCoachVisible, setAssignCoachVisible, setClientTypeVisible} = useModals()
    const {handleRemoveCoach, changeSearchValue, handleUsersSearch, handleUsersTableChange, getUsers, onSelectUsersChange, initUsersTableFilters, userUpdate, usersTableUpdate, handleAssignCoach, getCoachesFilterOptions, usersApiCancel, getCoachesSelectOptions} = props
    const selectedRowKeys = useAppSelector(state => state.users.selectedUsers)
    const userRoles = useAppSelector(state => state.auth.user!.roles)
    const usersData = useAppSelector(state => state.users.usersData)
    const [editing, setEditing] = useState(false)
    const [adding, setAdding] = useState(false)
    const [editItem, setEditItem] = useState<IClient | null>(null)
    const [initialUsers, setInitialUsers] = usePersistedState(
        "UsersPage/Users",
        {}
    )


    const [addForm] = Form.useForm<Omit <IUserForm, 'phoneNumber'>>();
    const [editForm] = Form.useForm<IUserForm>();

    const resetAddForm = () => addForm.resetFields()
    const resetEditForm = () => editForm.resetFields()

    const handleHideRemoveCoachModal = () => {
        setRemoveCoachVisible(false)
    };


    const handleOnOkRemoveCoachModal = async () =>  await handleRemoveCoach(selectedRowKeys.map((id) => ({id})))

    const getRemoveCoachProps = (selected: React.Key[] = [], data: IClient[] = []) => {
       const usersFullName = selected.reduce((arr: IClient['fullName'][], userId) => {
            const selectedUser: IClient | undefined = data.find((user) => user.id === userId)
            return !!selectedUser ? [...arr, selectedUser.fullName] : arr
        }, [])

        if(!usersFullName.length ){
            return {}
        }

        if(usersFullName.length > 1){
            return {
                title:'Are you sure you want to remove coach for the following users?',
                message: usersFullName.join(', ')
            }
        }else {
           return  {title: `Are you sure you want to remove coach for ${usersFullName[0]}?`}
        }
    }

    const newRecord = () => {
        setAdding(true)
        resetAddForm()
    }

    function onEditModal(record: IClient) {
        const {firstName, lastName, phoneNumber, competing, disabled, email, testAccount, clientType, coachId} = record;
        setEditItem(record)
        editForm.setFieldsValue({firstName, lastName, phoneNumber, competing, disabled, email, testAccount, clientType, coachId})
        setEditing(true)
    }

    const onCreateUser = async ({phoneNumber}: IUserForm) => {

        const phone = phoneNumber ? `+${phoneNumber}` : null;
        const {competing ,...values} = addForm.getFieldsValue()
        const requestFormData = getUserFormData({formData: values, fields: {competing: !!competing, roles: user, phoneNumber: phone}});
        // Do save
        const responseUserData = await userUpdate(requestFormData)
        if (!!responseUserData) {
            doCancelAdding()
            antNotificationComponent({type: 'success', message: "Saved"})
        }
    }

    const onEditUser = async ({phoneNumber}: IUserForm) => {
        const phone = phoneNumber ? `+${phoneNumber.replace(/\+/g, '')}` : null;
        const formData = editForm.getFieldsValue();
        const requestFormData = getUserFormData({formData: {...editItem, ...formData}, fields: {phoneNumber: phone}});
        try {
            await updateUserData({id: editItem?.id, data: requestFormData})
            doCancelEditing()
            antNotificationComponent({type: 'success', message: "Updated"})
            usersTableUpdate()

            return true;
        }
        catch (e) {
            antNotificationComponent({ type: 'error', message: "Failed to save" });
            console.warn(e);
            return false;
        }
    }

    const doCancelEditing = () => {
        resetEditForm()
        setEditing(false)
        setEditItem(null)
    }

    const doCancelAdding = () => {
        resetAddForm()
        setAdding(false)
    }

    const onAssignCoachCancel = () => {
        setAssignCoachVisible(false)
    }

    const AssignCoachWithPermission =  withPermission(AssignCoachModal)
    const RemoveCoachWithPermission =  withPermission(RemoveCoachModal)
    
    

    return (
        <Space className={'page_wrapper_style'} direction="vertical">
            <UsersPageHeader
                changeSearchValue={changeSearchValue}
                handleUsersSearch={handleUsersSearch}
                updateUserData={updateUserData}
                newRecord={newRecord}
                selectedRowKeys={selectedRowKeys}
                setAssignCoachVisible={setAssignCoachVisible}
                setRemoveCoachVisible={setRemoveCoachVisible}
                setClientTypeVisible={setClientTypeVisible}
                usersData={usersData}
                userRoles={userRoles}
                usersTableUpdate={usersTableUpdate}
                onSelectUsersChange={onSelectUsersChange}
            />
            <UserTableWrapper
                getCoachesFilterOptions={getCoachesFilterOptions}
                usersApiCancel={usersApiCancel}
                onEditModal={onEditModal}
                initialUsers={initialUsers}
                setInitialUsers={setInitialUsers}
                initUsersTableFilters={initUsersTableFilters}
                getUsers={getUsers}
                onSelectUsersChange={onSelectUsersChange}
                handleUsersTableChange={handleUsersTableChange}
            />
            <AddUserForm
                form={addForm}
                visible={adding}
                title="Add User"
                modalProps={{onCancel: doCancelAdding, onOk: onCreateUser}}
            />

            <EditUserForm form={editForm}
                          currentUser={editItem}
                          visible={editing}
                          title={"Edit User"}
                          modalProps={{onCancel: doCancelEditing, onOk: onEditUser}}
                          
            />
            <AssignCoachWithPermission
                getCoachesSelectOptions={getCoachesSelectOptions}
                onAssignCoachCancel={onAssignCoachCancel}
                users={selectedRowKeys.map(id => ({id}))}
                assignCoach={handleAssignCoach}
                roles={[admin, manager]}
                initialCoach={null}
            />
            <RemoveCoachWithPermission
                onCancel={handleHideRemoveCoachModal}
                handleOk={handleOnOkRemoveCoachModal}
                {...getRemoveCoachProps(selectedRowKeys, usersData)}
                roles={[admin, manager]}
            />
        </Space>

    )
}

export default withSidebarNavigation(UserList, '/users')