import React, { FC } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Menu, Layout } from 'antd';
import {TeamOutlined, UserOutlined} from '@ant-design/icons';
import {getPopoverText} from "utils/helpers";
import {MenuClickEventHandler} from "rc-menu/lib/interface"
import { IManager } from 'Interfaces/User';

interface Props {
    data: IManager[]
}

const ManagerSidebar: FC<Props> = ({data}) => {
    const {Sider} = Layout
    const {pathname} = useLocation();
    const history = useHistory();

    const handleMenuClick: MenuClickEventHandler = ({key}) => history.push(key);

    const MenuItem = ({id, fullName, roles}: IManager) => (
            <Menu.Item key={`/${roles}/${id}`} icon={<UserOutlined />}>
                {getPopoverText(fullName)}
            </Menu.Item>
        )

    return (
        <Sider theme="light" className="coach_sidebar">
            <Menu 
            theme="light" 
            mode="inline" 
            onClick={handleMenuClick} 
            selectedKeys={[pathname]}
            >
                <Menu.Item 
                key="/managers" 
                icon={<TeamOutlined />}
                >
                    All 
                </Menu.Item>
                {
                    data.length > 0 && 
                    data.map(MenuItem)
                }
            </Menu>
        </Sider>
    )

};


export default ManagerSidebar;