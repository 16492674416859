import { IClient } from 'Interfaces/User';
import {createAsyncThunk} from '@reduxjs/toolkit'
import store from "../redux/store";
import API from "./api-service";
import axios from "axios";
import { ILoginForm } from 'pages/login/login';
import { ILogin_Response } from 'Interfaces/Auth';
import {History} from 'history';


export const checkAuth = createAsyncThunk(
    'checkAuth',
    async (prevLocation: string) => {
            const user = await API.post('api/auth/check') as {status: boolean, User: IClient | null}
            return {user: user.User, prevLocation, isAuth: true}
    },
)

export const login = async ({history, userData}: {history: History, userData: ILoginForm}) => {
    try {
        const data = await API.post('api/auth/login', userData) as ILogin_Response
        store.dispatch({type: 'LOG_IN', payload: {user: data.user, prevLocation: '/login', isAuth: true}})
        if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
            history.push('/messages')
        } else {
            history.push('/')
        }   
    } catch (error) {
        history.push('login')
    }
}

export const logout = createAsyncThunk(
    `Logout`,
    async (history: History, {dispatch} ) => {
        try {
            await axios.post('/api/auth/logout')
            dispatch({type:'Logout'})
            history.push('/login')
        } catch (err) {
            console.error(err);
        }
    }
)
