import React, {FC, useEffect, useState} from 'react';
import ActivationPartsContent from "./ActivationPartsContent";
import {activationsOptions, partsOptions} from "vars";
import {Card} from "antd";
import { ActivationsOptions, IUserStateClassification, PartsOptions } from 'Interfaces/UserClassification';
import { IFeedback_UserStateClassification } from 'Interfaces/Feedback';

interface Props {
    userStateClassification: IFeedback_UserStateClassification[]
}

const ActivationPartsList: FC<Props> = ({userStateClassification}) => {
    const [selectedPartsTags, setSelectedPartsTags] = useState<PartsOptions[]>([])
    const [selectedActivationTags, setSelectedActivationTags] = useState<ActivationsOptions[]>([])
    const [userClassification] = userStateClassification;
    useEffect(() => {
        if(userClassification){
            const parts = partsOptions.filter((item) => userClassification[item.value]).map(({value}) => value);
            const activation = activationsOptions.filter((item) => userClassification[item.value]).map(({value}) => value);
            setSelectedPartsTags(parts);
            setSelectedActivationTags(activation);
        }
    }, [])

    return selectedPartsTags.length > 0 && selectedActivationTags ? (
        <Card>
            <ActivationPartsContent withKeys={['Antidote']} parts={selectedPartsTags} activations={selectedActivationTags} />
        </Card>
    ) : null
};

export default ActivationPartsList