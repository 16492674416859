import React, { FC } from 'react';
import { ModalForm, ProFormText, ProFormTextArea, ProFormSelect, ProFormSwitch } from '@ant-design/pro-form';
import { isOnlySpaces, validateRequired, validateEmail } from 'utils/helpers';
import { admin } from 'vars';
import { FormInstance } from 'antd/es/form/Form';
import { ICoachForm } from '../coach';
import { useAppSelector } from 'redux/store';

interface Props {
    form: FormInstance<ICoachForm>,
    visible: boolean,
    onFinish: (data: ICoachForm) => Promise<boolean>,
    modalProps: {
        onCancel: () => void
    },
    onReset: () => void
};

const EditCoachForm: FC<Props> = ({form, visible, onFinish, modalProps, onReset}) => {
    const roles = useAppSelector((state) => state.auth.user?.roles);


    return (
        <ModalForm 
            form={form} 
            visible={visible} 
            title="Edit User" 
            onFinish={onFinish} 
            onReset={onReset}
            modalProps={modalProps}>
                <ProFormText name="firstName" label="First Name" placeholder="First Name" rules={[
                    {
                        required: true,
                        message: 'This field is required',
                    },
                    ({getFieldValue}) => ({
                        validator(_, value) {
                            if (!value || !isOnlySpaces(getFieldValue('firstName'))) {
                                return Promise.resolve();
                            }
                            return Promise.reject(new Error('This field is required'));
                        },
                    }),]}/>
                <ProFormText name="lastName" label="Last Name" placeholder="Last Name" rules={[
                    {
                        required: true,
                        message: 'This field is required',
                    },
                    ({getFieldValue}) => ({
                        validator(_, value) {
                            if (!value || !isOnlySpaces(getFieldValue('lastName'))) {
                                return Promise.resolve();
                            }
                            return Promise.reject(new Error('This field is required'));
                        },
                    }),]}/>
                <ProFormText name="email" label="Email" placeholder="Email"
                                rules={[() => ({
                                    async validator(_, value) {
                                        await validateRequired(value)
                                        await validateEmail(value)
                                        return Promise.resolve(value);
                                    },
                                }), {required: true, message: ''}]}/>
                <ProFormText name="specificArea" label="Specific Area/Goals" placeholder="Specific Area/Goals"/>
                <ProFormTextArea name="bio" label="BIO" placeholder="BIO"/>
                {
                    roles === admin && <ProFormSelect
                    name="roles"
                    label="Role"
                    valueEnum={{
                        admin: 'Admin',
                        coach: 'Coach',
                        manager: 'Manager',
                    }}
                    placeholder="Please select a role"
                    rules={[{required: true, message: 'This field is required'}]}
                />
                }
                <ProFormSwitch name="pvtEnabled" label="PVT Enable" />
                <ProFormSwitch name="pvtSuperUser" label="PVT Enable (Super)" />
                <ProFormSwitch name="disabled" label="Disabled"/>
                    
        </ModalForm>
    )

};

export default EditCoachForm;