import React, {ComponentType} from "react";
import { useAppSelector } from "redux/store";
import { UserRoles } from "Types";

interface Props {
    roles: UserRoles[]
}
type DefaultType = typeof React.Component | React.ElementType | undefined;

export default function withPermission<P extends {}>(Component: ComponentType<P>, DefaultComponent?: DefaultType): ComponentType<P & Props>{
    return function ComponentWithPermission(props){
        const {roles = []} = props
        const userRoles = useAppSelector(state => state.auth.user!.roles)
        if(roles.includes(userRoles)){
            return <Component {...props}/>
        }
        return (
            DefaultComponent ? <DefaultComponent /> : null
        )
    }
}