import React, { FC } from 'react';
import { ModalForm } from '@ant-design/pro-form';
import { useAppSelector } from 'redux/store';
import CategoryForm, { CategoryFormProps } from './CategoryForm';

const AddCategoryForm: FC<CategoryFormProps> = ({ form, onCancel, onFinish }) => {
    const isVisible = useAppSelector(state => state.modals.addCategoryModalVisible);
    return (
        <ModalForm
            title="Add Category"
            visible={isVisible}
            modalProps={{
                onCancel,
                centered: true
            }}
            onFinish={onFinish}
            form={form}
        >
            <CategoryForm />
        </ModalForm>
    )
};

export default AddCategoryForm;