import {useCallback, useState} from "react";
import {
    setChatListLate,
    setMoreChatListLate,
    setReadLateMessage,
    setUnreadLateMessage
} from "redux/reducers/chatReducer";
import useApi from "hooks/useApi";
import { ResponseTypeChat } from "Types";
import { useAppDispatch, useAppSelector } from "redux/store";
import { IChat, IChatList, IChatParams, IChatRead } from "Interfaces/Chat";

interface Props {
    responseType: ResponseTypeChat | null
};

const useLateConversationApi = ({responseType}: Props) => {
    const dispatch = useAppDispatch();
    const {API} = useApi();
    const chatListLate = useAppSelector((state) => state.chat[responseType as ResponseTypeChat]);
    const totalCount = useAppSelector((state) => state.chat.chatListLateTotalCount);
    const currentUser = useAppSelector((state) => state.chat.currentUser);
    const [isLoading, setIsLoading] = useState<boolean>(true);


    const getLateConversationList = async (params: IChatParams, loading = false) => {
        setIsLoading(loading);
        try {
            const response = await API.get<IChatList>('/api/chat', {
                params
            });
            dispatch(setChatListLate({
                chats: response.data.chats,
                totalCount: response.data.totalCount,
                responseType
            }));
            setIsLoading(false);
        }catch (e) {
            if(e instanceof Error){
                console.log(e.message);
                setIsLoading(false)
            }
        }
    };

    const getMoreLateConversationList = async (params: IChatParams) => {
        try {
            const response = await API.get<IChatList>('/api/chat', {
                params
            });
            dispatch(setMoreChatListLate({
                chats: response.data.chats,
                totalCount: response.data.totalCount,
                responseType
            }));
        }catch (e) {
            if(e instanceof Error){
                console.log(e.message);
            }
        }
    };

    const putUnreadUserMessages = async ({userId}: IChat) => {
        try {
            const response = await API.put<IChatRead>(`/api/chat/unread/${userId}`);
            dispatch(setUnreadLateMessage({currentUser: response.data, responseType}));
        }catch (e) {
            if(e instanceof Error){
                console.log(e.message)
            }
        }
    }

    const updateReadCount = useCallback(async (actionMenuUser: IChat) => {
        if(!currentUser && !actionMenuUser || !responseType) return;
        try {
            const lastMessageId = actionMenuUser?.id || currentUser?.id;
            const lastMessageUserId = actionMenuUser?.userId || currentUser?.userId;
            dispatch(setReadLateMessage({currentUser: actionMenuUser || currentUser, responseType}));
            await API.put(`/api/chat/read-until/${lastMessageId}/${lastMessageUserId}`)
        }catch (e) {
            if(e instanceof Error){
                console.log(e.message);
            }
        }

    }, [currentUser, responseType]);

    return {
        isLoading,
        chatListLate,
        totalCount,
        updateReadCount,
        putUnreadUserMessages,
        getLateConversationList,
        getMoreLateConversationList,
    }
};

export interface ILateListProps {
    isLoading: boolean,
    chatListLate: IChat[],
    totalCount: number,
    updateReadCount: (user: IChat) => Promise<void>,
    putUnreadUserMessages: (user: IChat) => Promise<void>,
    getLateConversationList: (params: IChatParams, loading?: boolean) => Promise<void>,
    getMoreLateConversationList: (params: IChatParams) => Promise<void>
}

export default useLateConversationApi;