import {Logger} from "../utils/helpers";
import {
    setAddNotesModalVisible,
    setAssignCoachModalVisible,
    setAssignmentsHistoryPopupVisible, setAverageCheckInsVisible,
    setChangeGoalModalVisible,
    setFeedbackModalVisible,
    setMacroModalVisible,
    setAssignManagerModalVisible,
    setRemoveCoachModalVisible,
    setEditManagerModalVisible,
    setRemoveManagerModalVisible,
    setRemoveManagersModalVisible,
    setUnAssignManagerModalVisible, 
    setAssignClientTypeModalVisible, 
    setRemoveClientTypeModalVisible, 
    setPauseNotificationsModalVisible,
    setAddTaskModalVisible,
    setEditTaskModalVisible,
    setEditCategoryModalVisible,
    setAddCategoryModalVisible,
    setRemoveTaskModalVisible,
    setRemoveCategoryModalVisible,
} from "../redux/reducers/modalsReducer";
import { useAppDispatch } from "redux/store";

export type ModalType = (flag: boolean) => {
    payload: boolean;
    type: string;
};

const logger = Logger("useModals")

function useModals() {
    const dispatch = useAppDispatch()
    const setRemoveManagersVisible = (flag: boolean) => dispatch(setRemoveManagersModalVisible(flag))
    const setRemoveManagerVisible = (flag: boolean) => dispatch(setRemoveManagerModalVisible(flag))
    const setEditManagerVisible = (flag: boolean) => dispatch(setEditManagerModalVisible(flag)) 
    const setAverageVisible = (flag: boolean) => dispatch(setAverageCheckInsVisible(flag));
    const setUnAssignManagerVisible = (flag: boolean) => dispatch(setUnAssignManagerModalVisible(flag))
    const setAssignManagerVisible = (flag: boolean) => dispatch(setAssignManagerModalVisible(flag))
    const setFeedbackVisible = (flag: boolean) => dispatch(setFeedbackModalVisible(flag))
    const setAddNotesVisible = (flag: boolean) => dispatch(setAddNotesModalVisible(flag))
    const setAssignCoachVisible = (flag: boolean) => dispatch(setAssignCoachModalVisible(flag))
    const setAssignmentsHistoryVisible = (flag: boolean) => dispatch(setAssignmentsHistoryPopupVisible(flag))
    const setChangeGoalVisible = (flag: boolean) => dispatch(setChangeGoalModalVisible(flag))
    const setRemoveCoachVisible = (flag: boolean) => dispatch(setRemoveCoachModalVisible(flag))
    const setMacroVisible = (flag: boolean) => dispatch(setMacroModalVisible(flag))
    const setRemoveFeedbackVisible = (flag: boolean) => dispatch(setRemoveCoachModalVisible(flag))
    const setClientTypeVisible = (flag: boolean) => dispatch(setAssignClientTypeModalVisible(flag));
    const setRemoveClientTypeVisible = (flag: boolean) => dispatch(setRemoveClientTypeModalVisible(flag));
    const setPauseNotificationsVisible = (flag: boolean) => dispatch(setPauseNotificationsModalVisible(flag));
    const setAddTaskVisible = (flag: boolean) => dispatch(setAddTaskModalVisible(flag));
    const setEditTaskVisible = (flag: boolean) => dispatch(setEditTaskModalVisible(flag));
    const setEditCategoryVisible = (flag: boolean) => dispatch(setEditCategoryModalVisible(flag));
    const setAddCategoryVisible = (flag: boolean) => dispatch(setAddCategoryModalVisible(flag));
    const setRemoveTaskVisible = (flag: boolean) => dispatch(setRemoveTaskModalVisible(flag));
    const setRemoveCategoryVisible = (flag: boolean) => dispatch(setRemoveCategoryModalVisible(flag));
    return {
        setRemoveManagersVisible,
        setEditManagerVisible,
        setRemoveManagerVisible,
        setFeedbackVisible,
        setUnAssignManagerVisible,
        setAddNotesVisible,
        setAssignManagerVisible,
        setAssignCoachVisible,
        setAssignmentsHistoryVisible,
        setChangeGoalVisible,
        setRemoveCoachVisible,
        setMacroVisible,
        setRemoveFeedbackVisible,
        setAverageVisible,
        setClientTypeVisible,
        setRemoveClientTypeVisible,
        setPauseNotificationsVisible,
        setAddTaskVisible,
        setEditTaskVisible,
        setEditCategoryVisible,
        setAddCategoryVisible,
        setRemoveTaskVisible,
        setRemoveCategoryVisible
    }
}

export type UseModalsProps = ReturnType<typeof useModals>;
export default useModals;