import React, { FC } from 'react';
import { ModalForm, ProFormText, ProFormTextArea, ProFormSwitch } from '@ant-design/pro-form';
import { isOnlySpaces, validateRequired, validateEmail } from 'utils/helpers';
import { FormInstance } from 'antd/es/form/Form';
import { ICoachForm } from '../coach';

interface Props {
    visible: boolean,
    form: FormInstance<ICoachForm>,
    onFinish: () => Promise<void>,
    modalProps: {
        onCancel: () => void
    },
    onReset: () => void
};


const AddCoachForm: FC<Props> = ({form, visible, onFinish, onReset, modalProps}) => {


    return (
        <ModalForm 
            form={form} 
            visible={visible} 
            title="Add Coach" 
            onFinish={onFinish}
            onReset={onReset}
            modalProps={modalProps} 
        >

            <ProFormText name="firstName" label="First Name" placeholder="First Name" rules={[
                {
                    required: true,
                    message: 'This field is required',
                },
                ({ getFieldValue }) => ({
                    validator(_, value) {
                        if (!value || !isOnlySpaces(getFieldValue('firstName'))) {
                            return Promise.resolve();
                        }
                        return Promise.reject(new Error('This field is required'));
                    },
                }),
            ]} />
            <ProFormText name="lastName" label="Last Name" placeholder="Last Name" rules={[
                {
                    required: true,
                    message: 'This field is required',
                },
                ({ getFieldValue }) => ({
                    validator(_, value) {
                        if (!value || !isOnlySpaces(getFieldValue('lastName'))) {
                            return Promise.resolve();
                        }
                        return Promise.reject(new Error('This field is required'));
                    },
                }),
            ]} />
            <ProFormText name="email" label="Email" placeholder="Email"
                rules={[() => ({
                    async validator(_, value) {
                        await validateRequired(value);
                        await validateEmail(value);
                        return Promise.resolve(value);
                    },
                }), { required: true, message: '' }]} />
            <ProFormText.Password name="password" label="Password" placeholder="Password" rules={[
                {
                    required: true,
                    message: 'This field is required',
                },
                ({ getFieldValue }) => ({
                    validator(_, value) {
                        if (!value || getFieldValue('password') === value) {
                            const exp = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/;
                            if (exp.test(value)) {
                                return Promise.resolve();
                            }
                        }

                        return Promise.reject(new Error(' Password must contain at least 8 symbols, 1 digit, 1 lowercase letter and 1 uppercase letter!'));
                    },
                }),
            ]} hasFeedback />
            <ProFormText.Password name="confirmPassword" label="Confirm Password"
                placeholder="Confirm Password"
                dependencies={['password']} hasFeedback rules={[
                    {
                        required: true,
                        message: 'This field is required',
                    },
                    ({ getFieldValue }) => ({
                        validator(_, value) {
                            if (!value || getFieldValue('password') === value) {
                                return Promise.resolve();
                            }

                            return Promise.reject({ message: 'Passwords do not match' });
                        },
                    }),
                ]} />
            <ProFormText name="specificArea" label="Specific Area/Goals" placeholder="Specific Area/Goals" />
            <ProFormTextArea name="bio" label="BIO" placeholder="BIO" />
            <ProFormSwitch name="pvtEnabled" label="PVT Enable" />
            <ProFormSwitch name="pvtSuperUser" label="PVT Enable (Super)" />
            <ProFormSwitch name="disabled" label="Disabled" />
        </ModalForm>
    )
};


export default AddCoachForm;