import { AxiosResponse } from 'axios';
import React, {useEffect} from "react";
import { useAppDispatch } from "redux/store";
import useApi from "hooks/useApi";
import {Logger} from "utils/helpers";
import {
    incrementMacroModalToggleCount,
    setActiveTabKey, 
    setClientsType, 
    setCurrentEvent, 
    setDeleteClientsType, 
    setPauseNotificationDate, 
    setUser, 
    setUserInitials, 
    setUserLastUpdate,
    userReducerReset
} from "redux/reducers/userReducer";
import {
    VIEW_CHECK_INS,
    VIEW_FEEDBACK,
    VIEW_IMAGES,
    VIEW_NOTES, VIEW_NUTRITION,
    VIEW_PROFILE,
    VIEW_QUESTIONNAIRE,
} from "vars";
import {setUserToken} from "redux/reducers/usersReducer";
import antNotificationComponent from "utils/notification-ant-utils";
import { CurrentEvent, UserType } from "Types";
import { IMessageStatus, ISuccessStatus } from "Interfaces/Auth";
import { IAssignmentHistory, IClient } from "Interfaces/User";
import { IClientType } from "Interfaces/ClientType";
import { IPauseNotificationForm } from 'pages/client/PauseNotificationsModal';
 export interface IUpdateUserData extends Omit<IClient, 'fullName'> {
    createMacros: boolean,
}

const logger = Logger("UserApiWithRedux")

function useUserApiWithRedux() {
    const {API, cancel} = useApi()
    const dispatch = useAppDispatch()

    const onCreateUser = async <T>(data: T) => {
        try {
            const response = await API.post<UserType>('/api/user', data);
            return response.data;
        } catch (error) {
            if(error instanceof Error){
                logger.error(error.message)
            }
        }
    }

    const onRemoveToken = async (id: string): Promise<AxiosResponse<IMessageStatus> | undefined> => {
        try {
            const response = await API.delete<IMessageStatus>(`/api/user/${id}/api-key`);
            dispatch(setUserToken({id, apiKey: null}))
            return response;
        }catch (e) {
            if(e instanceof Error){
                logger.error(e.message)
            }
        }
    }

    const onGenerateToken = async (id: string): Promise<{apiKey: string} | undefined> => {
        try {
            const response = await API.post<{apiKey: string}>(`/api/user/${id}/api-key`);
            dispatch(setUserToken({id,apiKey: response.data.apiKey}))
            return response.data
        }catch (e) {
            if(e instanceof Error){
                logger.error(e.message)
            }
        }
    }

    const getUserData = async (id: string): Promise<IClient | undefined> => {
        try {
            const response = await API.get<IClient>(`/api/user/${id}`)
            return response.data
        } catch (e) {
            if(e instanceof Error){
                logger.error(e.message)
            }
        }
    }

    const getUserAnswers = async (id: string) => {
        try {
            const response = await API.get(`/api/user/${id}/answers`)
            return response.data
        } catch (e) {
            if(e instanceof Error){
                logger.error(e.message)
            }
        }
    }

    const updateUserData =  async<T>({id, data}: {id: string | undefined, data: T}): Promise<AxiosResponse<UserType> | undefined> => {
        try {
            const response = await API.put<UserType>(`/api/user/${id}`, data)
            return response
        } catch (e) {
            if(e instanceof Error){
                logger.error(e.message)
            }
        }
    }


    const getUserAssignmentsHistory = async ({userId, authId}: {userId: string, authId: string}): Promise<IAssignmentHistory[] | undefined> => {
        try {
            const response = await API.get<IAssignmentHistory[]>(`/api/user/${userId}/coach-history`, {
                params: {
                    f_actorId: [authId]
                }
            })
            return response.data
        } catch (e) {
            if(e instanceof Error){
                logger.error(e.message)
            }
        }
    }

    const userLastUpdate = () => {
        dispatch(setUserLastUpdate({lastUpdate: Date.now()}))
    }

    function setCurrentEventData(key: string) {
        let currentEvent: CurrentEvent | null;
        switch (key) {
            case "1":
                currentEvent = VIEW_PROFILE;
                break;
            case "2":
                currentEvent = VIEW_QUESTIONNAIRE;
                break;
            case "3":
                currentEvent = VIEW_NOTES;
                break;
            case "4":
                currentEvent = VIEW_CHECK_INS;
                break;
            case "5":
                currentEvent = VIEW_FEEDBACK;
                break;
            case "6":
                currentEvent = VIEW_IMAGES;
                break;
            case "7":
                currentEvent = VIEW_NUTRITION;
                break;
            default:
                currentEvent = null;
        }

        if (currentEvent) {
            dispatch(setCurrentEvent({currentEvent}))
        }
    }

    const setCurrentEventWithCount = (currentEvent: string) => {
        if (currentEvent) {
            setCurrentEventData(currentEvent);
            dispatch(incrementMacroModalToggleCount());
        }
    }

    const setActiveTab = (key: string) => {dispatch(setActiveTabKey({activeTabKey: key}))}

    const getUser = async (id: string) => {
        const data = await getUserData(id)
        if (!!data) {
            dispatch(setPauseNotificationDate(data.pausedNotifications));

            dispatch(setUser({userData: data}))
            dispatch(setUserInitials({initials: data.firstName[0] + data.lastName[0]}))
        }
    }

    // const getAnswers = async (user: IUser | IManager | ICoach | IClient) => {
    //     let userObjUpdate = {}
    //     let answers = await getUserAnswers(user.id)

    //     let weight = answers.find((e: { prompt: string; }) => e.prompt.startsWith("Weight"))
    //     let age = answers.find(e => e.prompt.startsWith("Age"))
    //     let height = answers.find(e => e.prompt.startsWith("Height"))
    //     debugger;
    //     //Only populate weight if not found in check-in
    //     if (!user.weight) {
    //         if (weight?.unitAnswer === "lbs") {
    //             //If quiz answer is LBS, convert to KG for calculator.
    //             userObjUpdate.weight = weight?.userAnswer * 0.453592
    //         } else {
    //             userObjUpdate.weight = weight?.userAnswer
    //         }
    //     }

    //     userObjUpdate.age = age?.userAnswer

    //     //Convert Feet/Inches measurements into cm.
    //     if (height?.unitAnswer === "feet_in") {
    //         //Convert feet/inches to cms
    //         let userHeight = 0

    //         if (height.userAnswer) {
    //             const heightArray = height?.userAnswer?.match(/(\d+)'\s*(\d+.?\d?)(?:''|")/);
    //             if (Array.isArray(heightArray)) {
    //                 const [, feet, inches] = heightArray
    //                 const userAnswerHeight = ((parseFloat(feet) * 12) + parseFloat(inches)) * 2.54;

    //                 if (!isNaN(userAnswerHeight)) {
    //                     userHeight = userAnswerHeight
    //                 }
    //             }
    //         }
    //         userObjUpdate.height = userHeight

    //     } else {
    //         userObjUpdate.height = height?.userAnswer
    //     }
    //     userObjUpdate.Calculator = {protein: 0, fat: 0, carb: 0}

    //     //Find Weight / Age / Height.
    //     //Transform imperial to metric.
    //     dispatch(setUser({userData: {...user, ...userObjUpdate}}))
    //     return {...user, ...userObjUpdate}
    // }

    const updateUser = async (id: string, data: UserType) => {
        const userData = await updateUserData({id, data})
        return userData
    }

    const handleChangeGoal = async (id: string, data: UserType) => {
        const userData = await updateUserData({id, data})
        if (userData) {
            await getUser(id)
        }
        return userData
    }

    // const onCreateClientType = async (values) => {
    //     try {
    //         const response = await API.post<IClientType>('/api/client-type', values);
    //         debugger;
    //         if(response.status === 200){
    //             dispatch(setCreateClientsType(response.data));
    //             antNotificationComponent({type: 'success', message: 'Saved'})
    //         }
    //     }catch (e) {
    //         if(e instanceof Error){
    //             logger.error(e.message)
    //         }
    //     }
    // }

    // const onEditClientType = async ({id,values}) => {
    //     try {
    //         const response = await API.put<IClientType>(`/api/client-type/${id}`, values);
    //         if(response.status === 200){
    //             dispatch(setEditClientsType(response.data));
    //             antNotificationComponent({type: 'success', message: 'Updated'});
    //         }
    //     } catch (e) {
    //         if(e instanceof Error){
    //             logger.error(e.message)
    //         }
    //     }
    // }

    const onDeleteClientType = async (id: string) => {
        try {
            await API.delete<IMessageStatus>(`/api/client-type/${id}`);
            dispatch(setDeleteClientsType(id));
            antNotificationComponent({type: 'success', message: 'Deleted'});
        }catch (e) {
            if(e instanceof Error){
                logger.error(e.message)
            }
        }

    };

    const getClientsType = async () => {
        try {
            const response = await API.get<IClientType[]>('/api/client-type');
            dispatch(setClientsType([...response.data].sort((a,b) => a.name.localeCompare(b.name))));
        }catch (e) {
            if(e instanceof Error){
                logger.error(e.message)
            }
        }
    }

    const pauseClientNotifications = async (pauseNotificationsData: IPauseNotificationForm & {userId: string}) => {
        try {
            const { data } = await API.post<{userId: string, blockUntil: string, reasonMessage: string}>(
                `/api/user/${pauseNotificationsData.userId}/checkin/pause`,
                pauseNotificationsData
            );
            dispatch(setPauseNotificationDate(data));
            return data;
        } catch (e) {
            if(e instanceof Error){
                console.log(e.message)
            }
        }
    }

    const reenableClientNotifications = async (userId: string) => {
        try {
            await API.put(`api/user/${userId}/checkin/enable`)
            dispatch(setPauseNotificationDate(null));
        } catch (e) {
            if(e instanceof Error){
                console.log(e.message);
            }
        }
    }

    const onDeleteUsers = async (users: React.Key[]) => {
        try {
            const response = await API.delete<ISuccessStatus>('/api/user', {data: {users}});
            return response.data;
        } catch (error) {
            if(error instanceof Error){
                console.log(error.message);
            }
        }
    };


    useEffect(() => () => {dispatch(userReducerReset())}, [])

    return {
        userApiCancel: cancel,
        getUser,
        onDeleteUsers,
        onCreateUser,
        updateUser,
        setActiveTab,
        updateUserData,
        onGenerateToken,
        onRemoveToken,
        // getAnswers,
        userLastUpdate,
        getUserAssignmentsHistory,
        handleChangeGoal,
        setCurrentEventData,
        setCurrentEventWithCount,
        reenableClientNotifications,
        pauseClientNotifications,
        // onCreateClientType,
        // onEditClientType,
        getClientsType,
        onDeleteClientType,
    }
}

export default useUserApiWithRedux;