import { PayloadAction } from '@reduxjs/toolkit';
import { IClientNote, IGetClientNote } from './../../Interfaces/ClientNote/index';
import {createSlice} from '@reduxjs/toolkit'

interface InitialState {
    clientNotes: IClientNote[],
    page: number,
    totalCount: number,
    lastUpdate: number,
};

const initialState = {
    clientNotes: [],
    page: 1,
    totalCount: 0,
    lastUpdate: Date.now(),
} as InitialState;

const notesReducer = createSlice({
    name: 'notesReducer',
    initialState,
    reducers: {
        setNotes: (state, action: PayloadAction<IGetClientNote>) => {
            state.clientNotes = action.payload.clientNotes
            state.totalCount = action.payload.totalCount
        },
        setNotesPage: (state, action: PayloadAction<{page: number}>) => {
            state.page = action.payload.page
        },
        setLastUpdateNotes: (state, action: PayloadAction<{lastUpdate: number}>) => {
            state.lastUpdate = action.payload.lastUpdate
        },
        notesReducerReset: state => initialState
    }
})

export const {setNotes, setNotesPage, setLastUpdateNotes, notesReducerReset} = notesReducer.actions
export default notesReducer.reducer


