import React, {useEffect, useState} from 'react';
import {Button, Form, Select, Space, Input} from 'antd';
import {validateAlphabet, validateRequired} from "../../utils/helpers";
import {useParams} from "react-router-dom";
import usePersistedStateWithoutIdKey from "../../hooks/usePersistedStateWithoutIdKey";
import { ModalType } from 'services/modals-service';
import { Canceler } from 'axios';
import { useAppSelector } from 'redux/store';
import { IFeedback } from 'Interfaces/Feedback';
import { ICachedFeedback, IEditFeedback } from 'services/feedback-service';
import { Modal } from 'components/Antd/Modal';
import { IAdmin, ICoach, IManager, IUser } from 'Interfaces/User';
import { ISaveFeedback } from './checkin.list';
import { IMessageStatus } from 'Interfaces/Auth';
//NOTES: Based on a demo table, added a pop-up editor via modal and form.
//I used functions here rather than classes/extend React.Component as this causes issues getting a reference to the form in order to populate it.
//Looking to upgrade table to ProTable (has back-end linkages and search and stuff... but I was having troubles getting it to work)
//Data is populated locally, but shouldn't be much of a hassle to change to using a fetch to get the objects.

export interface ISendFeedbackModal {
    Author: IUser | ICoach | IManager | IAdmin,
    checkInId?: string,
    date?: string,
    internal?: boolean,
    notes: string,
    userId?: string | React.Key[],
    selectedTemplates: string[],
    templates: string[]
}

export interface IFeedbackModalProps {
    setVisible: ModalType,
    modal: boolean,
    messageTemplatesApiCancel: Canceler,
    getMessageTemplates: () => Promise<{
        label: string;
        value: string;
    }[]>
    setCurrentFeedbackDeleteClear: () => void,
    addFeedback: (data: ISaveFeedback | ISendFeedbackModal) 
    => Promise<(void | IFeedback)> | Promise<(void | IFeedback)[] | undefined> | Promise<IMessageStatus | undefined> ,
    setTemplates: (templates: string[]) => void,
    editFeedback?: (data: IEditFeedback) => Promise<IFeedback | undefined | void>
};

const {TextArea} = Input

export default function FeedbackModal(props: IFeedbackModalProps) {
    const {addFeedback, setVisible, modal, messageTemplatesApiCancel, getMessageTemplates, setTemplates, editFeedback, setCurrentFeedbackDeleteClear} = props
    const currentFeedback = useAppSelector(state => state.feedback.currentFeedback);
    const [loading, setLoading] = useState(true);
    const [messageTemplatesOptions, setMessageTemplatesOptions] = useState<{label: string, value: string}[]>([]);
    const [savedFeedbacksById] = usePersistedStateWithoutIdKey<ICachedFeedback[]>('feedbacks', []);

    const {id} = useParams() as {id: string};

    const [messageForm] = Form.useForm();

    const visible = useAppSelector(state => state.modals.feedbackModalVisible)
    const authUser = useAppSelector(state => state.auth.user!)
    const messageTemplatesData = useAppSelector(state => state.messageTemplates.messageTemplatesData)
    const selectedTemplates = useAppSelector(state => state.messageTemplates.selectedTemplates)

    const formChanged = (change: {template: string[]}) => {

        if (!!change?.template && change.template.length !== 0) {
            let currentText = messageForm.getFieldValue("notes")
            if (!currentText) {
                currentText = ""
            }
        
            change.template.map((t) => {
                if (change.template.length > selectedTemplates.length && !selectedTemplates.includes(t)) {
                    let templateText = messageTemplatesData.find(e => e.id === t)
                    if (!!templateText?.text) {
                        currentText = currentText.concat(templateText.text, "\n")
                    }
                } else if (change.template.length < selectedTemplates.length) {
                    let difference = selectedTemplates.filter(x => !change.template.includes(x));
                    difference.map(d=>{
                        let templateText = messageTemplatesData.find(e => e.id === d)
                        if (!!templateText?.text) {
                            currentText = currentText.split(templateText.text + "\n").join('')
                        }
                    })
                }
            })

            messageForm.setFieldsValue({notes: currentText})
            setTemplates(change.template)
        } else if (change.hasOwnProperty('template')) {
            messageForm.setFieldsValue({notes: ""})
            setTemplates(change.template)
        }
    }

    const clearFeedback = () => {
        setCurrentFeedbackDeleteClear()
        setTemplates([])
        messageForm.resetFields()
    }

    const onCancel = () => {
        clearFeedback()
        setVisible(false)
    }

    const onFinish = async (values: {notes: string, templates: string[]}) => {
        const selectedTemplatesId = messageTemplatesData
            .filter((template) => selectedTemplates.find(templateName => template.name === templateName || template.id === templateName))
            .map(({id}) => id);
        if(currentFeedback && typeof editFeedback === 'function'){
            const feedbackData = await editFeedback({...values, Author: authUser, id: currentFeedback.id, selectedTemplates: selectedTemplatesId});
            if(!!feedbackData){
               return onCancel();
            }
        }
        const feedbackData = await addFeedback({...values, Author: authUser, selectedTemplates: selectedTemplatesId})
        if (!!feedbackData) {
            onCancel()
        }
    }


    useEffect(() => {
        let cleanupFunction = false;
        const fetchData = async () => {
            setLoading(true)
            const messageTemplatesData = await getMessageTemplates()
            if (!cleanupFunction && messageTemplatesData?.length) {
                // console.log('datatemplate', messageTemplatesData);
                setMessageTemplatesOptions(messageTemplatesData)
            }
            setLoading(false)
        }
        fetchData();

        return () => {cleanupFunction = true};
    }, [])

    useEffect(() => {
        if(currentFeedback){
            messageForm.setFieldsValue({
                notes: currentFeedback.notes,
                template: [...selectedTemplates].sort((a,b) => a.localeCompare(b))
            })
        }
    }, [currentFeedback])


    useEffect(() => {
        messageForm.setFieldsValue({
            template: [...selectedTemplates].sort((a,b) => a.localeCompare(b))
        })
    }, [selectedTemplates])

    useEffect(() => {
        if(id){
            const {notes, selectedTemplates} = savedFeedbacksById.length > 0 ?
            savedFeedbacksById
                .filter((feedback) => feedback.id === id)
                .reduce((prev, {notes, selectedTemplates}) => ({
                notes,
                selectedTemplates
            }), {notes: '', selectedTemplates: [] as string[]}) : {notes: '', selectedTemplates: []};
            messageForm.setFieldsValue({
                notes,
                template: Array.isArray(selectedTemplates) ? [...selectedTemplates].sort((a,b) => a.localeCompare(b)) : []
            })
        }
    }, [id])


    useEffect(() => () => messageTemplatesApiCancel(), [])

    const initialValues = {
        notes: '',
        selectedTemplates: [],
        parts: [],
        activation: ''
    }

    const onOk = () => messageForm.validateFields()
        .then((values) => onFinish(values))
        .catch((e) => console.warn(e.errorFields))

    if (modal) {
        return (
            <Modal centered visible={visible} onCancel={onCancel} onOk={onOk}
                   title="Add Feedback" okText={"Save Feedback"}>
                {!loading ?
                    <Form 
                        form={messageForm}
                        initialValues={initialValues}
                        onValuesChange={(change) => formChanged(change)} 
                        labelCol={{span: 24}}
                    >
                        <Form.Item name="template" label="Select Template">
                            <Select mode="multiple"
                                    filterOption={true}
                                    optionFilterProp={'label'}
                                    options={messageTemplatesOptions} placeholder="Select Template" allowClear={false}/>
                        </Form.Item>
                        <Form.Item name="notes" label="Text" rules={[() => ({
                            async validator(_, value) {
                                await validateAlphabet(value)
                                await validateRequired(value)
                                return Promise.resolve(value);
                            },
                        }),]}>
                            <TextArea placeholder="Enter the message text here" rows={4}/>
                        </Form.Item>
                    </Form> : null}
            </Modal>
        )
    } else {
        return (
            !loading ? <Form form={messageForm} title="Add Feedback" onFinish={onFinish}
                              initialValues={initialValues}
                              onValuesChange={(change) => formChanged(change)} labelCol={{span: 24}}>
                <Form.Item name="template" label="Select Template">
                    <Select mode="multiple"
                            filterOption={true}
                            optionFilterProp={'label'}
                            options={messageTemplatesOptions} placeholder="Select Template" allowClear={false}/>
                </Form.Item>
                <Form.Item name="notes" label="Text" rules={[() => ({
                    async validator(_, value) {
                        await validateAlphabet(value)
                        await validateRequired(value)
                        return Promise.resolve(value);
                    },
                }),]}>
                    <TextArea placeholder="Enter the message text here" rows={4}/>
                </Form.Item>
                <Form.Item>
                    <Space>
                        <Button onClick={clearFeedback}>Reset</Button>
                        <Button htmlType={'submit'} type="primary">Save Feedback</Button>
                    </Space>
                </Form.Item>
            </Form> : null
        )
    }
}