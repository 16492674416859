import React from 'react';
import {Col, Image, Row, Tag} from "antd";
import nounFeedback from "img/noun-feedback.svg";
import {admin, coach, manager} from "vars";
import nounOutstanding from "img/noun-outstanding.svg";
import nounOverdue from "img/noun-overdue.svg";
import nounOverall from "img/noun-overall.svg";
import {checkFormatDuration} from "utils/helpers";
import nounClient from "img/noun-client.svg";
import {shallowEqual} from "react-redux";
import WidgetPageCard, { IWidgetPageCardProps } from "./WidgetPageCard";
import withPermission from "HOCs/withPermission";
import { useAppSelector } from 'redux/store';

const DashboardPageCardSecondCol = () => {
    const feedbackGiven = useAppSelector(state => state.dashboard.feedbackGiven!, shallowEqual)
    const activeClients = useAppSelector(state => state.dashboard.activeClients!)
    const totalClients = useAppSelector(state => state.dashboard.averageCompanyClients!, shallowEqual);
    const averageFeedbackTime = useAppSelector(state => state.dashboard.averageFeedbackTime!, shallowEqual)
    const chartsPieCompletedData = useAppSelector(state => state.dashboard.chartsPieCompletedData, shallowEqual)
    const chartsPieClientsData = useAppSelector(state => state.dashboard.chartsPieClientsData, shallowEqual)
    const checkInOverdue = useAppSelector(state => state.dashboard.checkInOverdue, shallowEqual)
    const feedbackOutstanding = useAppSelector(state => state.dashboard.feedbackOutstanding, shallowEqual)
    const averageCompanyFeedbackGiven = useAppSelector(state => state.dashboard.averageCompanyFeedbackGiven!);
    const averageCompanyFeedbackTime = useAppSelector(state => state.dashboard.averageCompanyFeedbackTime!)
    const averageCompanyCheckinOverdue = useAppSelector(state => state.dashboard.averageCompanyCheckinOverdue);
    const averageCompanyCheckinCompleted = useAppSelector(state => state.dashboard.averageCompanyCheckinCompleted);
    const averageCompanyFeedbackOutStanding = useAppSelector(state => state.dashboard.averageCompanyFeedbackOutStanding);
    const averageCompanyCompliance = useAppSelector(state => state.dashboard.averageCompanyCompliance)
    const complete = chartsPieCompletedData.find(elem => elem.type === 'Completed')
    const averageCompanyComplete = averageCompanyCheckinCompleted.find(elem => elem.type === 'Completed')
    const complianceOverall = chartsPieClientsData.find(elem => elem.type === 'Ok')
    const averageComplianceOverall = averageCompanyCompliance.find(elem => elem.type === 'Ok')

    const WidgetPageCardsWithWrapper = ({user, company}: {user: IWidgetPageCardProps, company: IWidgetPageCardProps}) => (
        <>
            <Col span={12}>
                <WidgetPageCard {...user} />
            </Col>
            <Col span={12}>
                <WidgetPageCard {...company} />
            </Col>
        </>
    )
    const WidgetPageCardsWithPermission = (withPermission(WidgetPageCardsWithWrapper))

    return (
        <Row>
            <WidgetPageCardsWithPermission
                user={{
                    data: {type: 'Feedback Given', value: feedbackGiven?.value || 0},
                    suffix: <>
                        <Tag>{activeClients?.value || 0}</Tag>
                        <Tag color="purple">{Math.ceil(+feedbackGiven?.value / +activeClients?.value * 100) || 0}%</Tag>
                    </>,
                    children: <Image src={nounFeedback} preview={false} />
                }}
                company={{
                    data: {type: 'Feedback Given', value: averageCompanyFeedbackGiven?.value || 0},
                    suffix: <>
                        <Tag>{totalClients?.value || 0}</Tag>
                        <Tag color="purple">{Math.ceil(+averageCompanyFeedbackGiven?.value / +totalClients?.value * 100) || 0}%</Tag>
                    </>,
                    children: <>
                        <Image src={nounFeedback} preview={false} />
                        <Tag className="dashboard_kpi_card_label" color="purple">Company</Tag>
                    </>
                }}
                roles={[admin,coach, manager]}
            />
            <WidgetPageCardsWithPermission
                user={{
                    data: {type: 'Check Ins Overdue', value: checkInOverdue?.value || 0},
                    children: <Image src={nounOutstanding} preview={false} />
                }}
                company={{
                    data: {type: 'Check Ins Overdue', value: averageCompanyCheckinOverdue?.value || 0},
                    children: <>
                        <Image src={nounOutstanding} preview={false} />
                        <Tag className="dashboard_kpi_card_label" color="purple">Company</Tag>                    </>
                }}
                roles={[admin, coach, manager]}
            />
            <WidgetPageCardsWithPermission
                user={{
                    data: {type: 'Check Ins Complete', value: complete?.quantity || 0},
                    children: <Image src={nounOutstanding} preview={false} />
                }}
                company={{
                    data: {type: 'Check Ins Complete', value: averageCompanyComplete?.quantity || 0},
                    children: <>
                        <Image src={nounOutstanding} preview={false} />
                        <Tag className="dashboard_kpi_card_label" color="purple">Company</Tag>
                    </>
                }}
                roles={[coach, manager, admin]}
            />
            <WidgetPageCardsWithPermission
                user={{
                    data: {type: 'Feedback Outstanding', value: feedbackOutstanding?.value || 0},
                    children: <Image src={nounOverdue} preview={false}/>
                }}
                company={{
                    data: {type: 'Feedback Outstanding', value: averageCompanyFeedbackOutStanding?.value || 0},
                    children: <>
                        <Image src={nounOverdue} preview={false} />
                        <Tag className="dashboard_kpi_card_label" color="purple">Company</Tag>                    </>
                }}
                roles={[admin,coach, manager]}
            />
            <WidgetPageCardsWithPermission
                user={{
                    data: {type: 'Compliance Overall', value: complianceOverall?.value || 0},
                    suffix: '%',
                    children: <Image  src={nounOverall} preview={false}  />
                }}
                company={{
                    data: {type: 'Compliance Overall', value: averageComplianceOverall?.value || 0},
                    suffix: '%',
                    children: <>
                        <Image src={nounOverall} preview={false} />
                        <Tag className="dashboard_kpi_card_label" color="purple">Company</Tag>                    </>
                }}
                roles={[admin,coach, manager]}
            />
            <WidgetPageCardsWithPermission
                user={{
                    data: {type: 'Average Feedback Time', value: checkFormatDuration(averageFeedbackTime?.value)},
                    children: <Image src={nounClient} preview={false} />
                }}
                company={{
                    data: {type: 'Feedback Time', value: checkFormatDuration(averageCompanyFeedbackTime?.value)},
                    children: <>
                        <Image src={nounClient} preview={false} />
                        <Tag className="dashboard_kpi_card_label" color="purple">Company</Tag>                    </>
                }}
                roles={[admin, coach, manager]}
            />
        </Row>
    )

};


export default DashboardPageCardSecondCol;