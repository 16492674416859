import React, { FC } from 'react';
import { Col, Row, Image } from 'antd';
import InfoCard from 'pages/coach/InfoCard';
import WidgetPageCard from 'pages/dashboard/WidgetPageCard';
import {EditFilled} from '@ant-design/icons';
import activeClientsIcon from 'img/noun-client2.svg';
import activeCoachesIcon from 'img/noun-client.svg';
import { ITypeValue } from 'Interfaces/Dashboard/CheckIn';

interface Props {
    onChange: (value: string) => void,
    text: string,
    joinedDate: string,
    activeClients: ITypeValue,
    activeCoaches: ITypeValue
};

const ManagerInfoCardsRow: FC<Props> = ({joinedDate, activeClients, text, onChange, activeCoaches}) => {

    return (
        <Row>
            <Col xl={6} md={24}>
                <InfoCard title="Date joined" text={joinedDate} />
            </Col>
            <Col xl={18} md={24}>
                <Row className={"coach_main_info_cards_right_row"}>
                    <Col xl={{span: 6, offset: 1}} lg={8} md={24}>
                    <WidgetPageCard 
                        data={activeClients} 
                        className={'coach_main_info_card'} 
                        valueStyle={{fontSize:'16px'}}
                        children={
                            <Image 
                                src={activeClientsIcon} 
                                width={60} 
                                preview={false} 
                            />}
                    />
                    </Col>
                    <Col xl={{span: 6, offset: 1}} lg={8} md={24}>
                     <WidgetPageCard 
                        data={activeCoaches} 
                        className={'coach_main_info_card'} 
                        valueStyle={{fontSize:'16px'}}
                        children={
                            <Image 
                                src={activeCoachesIcon}
                                width={60}
                                preview={false} 
                            />}
                    />
                    </Col>
                    <Col xl={{span: 8, offset: 1}} lg={8} md={24}>
                        <InfoCard
                        title="Specific Area/Goals"
                        text={text}
                        editable={{
                            icon: <EditFilled
                            className="coach_main_body_column_info_icon"
                            />,
                            tooltip: "Click to edit text",
                            onChange,
                            autoSize: {minRows: 3}
                        }}
                        />
                    </Col>
                </Row>
            </Col>
        </Row>
    )


};



export default ManagerInfoCardsRow;