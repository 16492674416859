import React, { FC } from 'react';
import { useAppSelector } from 'redux/store';
import { Typography } from 'antd';
import { Modal } from 'components/Antd/Modal';
import { IManager } from 'Interfaces/User';

interface Props {
    currentManager: IManager,
    onOk: (manager: IManager | React.Key[]) => Promise<void>,
    onCancel: () => void,

};

const DeleteManagerModal: FC<Props> = ({currentManager,onOk, onCancel}) => {
    const removeManagerModalVisible = useAppSelector((state) => state.modals.removeManagerModalVisible);
    
    const onRemove = () => onOk(currentManager);

    return (
        <Modal
        title="Delete manager"
        centered
        onCancel={onCancel}
        onOk={onRemove}
        visible={removeManagerModalVisible}
        >
            <Typography.Text>
                Are you sure you want to delete {currentManager?.roles} {currentManager?.fullName}
            </Typography.Text>
        </Modal>
    )
};


export default DeleteManagerModal;