import React from "react";
import {Card, Col, Statistic, StatisticProps} from "antd";

export default function ActivityCard({title, value}: StatisticProps){
    return(
        <Col xs={{span: 24}} md={{span: 12}} xl={{span: 6}}>
            <Card className={"activity-card-item"}>
                <Statistic
                    title={title}
                    value={value}
                    precision={2}
                    valueStyle={{color: '#3f8600'}}
                />
            </Card>
        </Col>
    )
}