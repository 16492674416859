import React, {useEffect, useState} from 'react';
import {Space} from 'antd';
import '../dashboard/dashboard.css'

import CoachDashboardPageBody from "./CoachDashboardPageBody";
import CoachDashboardPageHeader from "./CoachDashboardPageHeader";
import withSidebarNavigation from "HOCs/withSidebarNavigation";
import useDashboardApiWithRedux from "services/dashboard-service";
import useCoachCandidatesApiWithRedux from "services/candidates-service";
import useUsersApiWithRedux from "services/users-service";
import withPermission from "HOCs/withPermission";
import {useSelector} from "react-redux";
import NotFoundPage from "../not-found/NotFoundPage";
import LoadingSpin from "components/loading-spin/LoadingSpin";
import usePersistedState from "hooks/usePersistedState";
import {useManagersApiWithRedux} from "services/managers-service";
import { useAppSelector } from 'redux/store';
import { IDashboardQueryParams } from 'Interfaces/Dashboard';
import { IUsersTableSettings } from 'redux/reducers/usersReducer';
import useTasksCategories from 'services/tasksCategories-service';

function CoachDashboardPage() {
    const [isLoading, setLoading] = useState(true)
    const [isInit, setInit] = useState(true)
    const id = useAppSelector((state) => state.auth.user!.id)
    const selectedCoachId = useAppSelector(state => state.dashboard.selectedCoachId)!
    const coachCandidatesProps = useCoachCandidatesApiWithRedux()
    const { setCategoriesData, getUserTasksData } = useTasksCategories();
    const dashboardProps = useDashboardApiWithRedux()
    const {getManagersData} = useManagersApiWithRedux();
    const {dashboardApiCancel, initDashboardFilters} = dashboardProps

    const usersProps = useUsersApiWithRedux()
    const {usersApiCancel, initUsersTableFilters} = usersProps

    const [initialDashboard, setInitialDashboard] = usePersistedState<IDashboardQueryParams>(
        "CoachDashboardPage/Dashboard",
        {}
    )
    const [initialUsers, setInitialUsers] = usePersistedState<IUsersTableSettings>(
        "CoachDashboardPage/Users",
        {}
    )

    const getCoachTasksData = () => getUserTasksData({ coachId: initialDashboard.selectedCoachId || id })

    useEffect(() => {
        let cleanupFunction = false
        const fetchData =  () => {
            setInit(true)
            initUsersTableFilters({
                ...initialUsers,
            })
            initDashboardFilters({
                ...initialDashboard,
                selectedCoachId: initialDashboard.selectedCoachId || id
            })
            if(!cleanupFunction) {
                setInit(false)
            }
        }
        if(!!id) {
            fetchData()
        }

        return () => {
            cleanupFunction = true
            dashboardApiCancel()
            usersApiCancel()
        }
    }, [])


    const headerProps = {
        ...dashboardProps,
        ...coachCandidatesProps,
        isLoading,
        setLoading,
        initialDashboard,
        setInitialDashboard
    }
    const bodyProps = {
        ...dashboardProps,
        ...usersProps,
        isLoading,
        getManagersData,
        setLoading,
        initialUsers,
        setInitialUsers,
        setCategoriesData,
        getUserTasksData: getCoachTasksData,
    }

    return (
        !isInit && !!selectedCoachId ? <Space className={'page_wrapper_style'} direction="vertical">
            <CoachDashboardPageHeader {...headerProps}/>
            <CoachDashboardPageBody {...bodyProps}/>
        </Space> : <LoadingSpin/>
    )
}

export default withPermission(withSidebarNavigation(CoachDashboardPage, '/coach-dashboard'), NotFoundPage)