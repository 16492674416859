import axios, {AxiosError, AxiosResponse} from 'axios';
import antNotificationComponent from '../utils/notification-ant-utils'

/**
 * Setting up the axios object
 */
 const API = axios.create({
    baseURL: '/',
    responseType: 'json',
    headers: {'cache': 'no-store'},
  })
  
  
  /**
   * Callback that is executed in case of a successful request
   */
  const onFullfiled = (response: AxiosResponse) => {
    return response.data
  }
  
  
  /**
   * Callback that is executed in case of an error
   */
  const onRejected = (error: AxiosError): Promise<AxiosError> => {
    if (error.response?.status === 401) {
      localStorage.clear() //clear all data if the cookie expired
      setTimeout(() => {
            console.log("Window Location:", window.location)
            if(!window.location.href.includes("/login")){
                window.location.replace("/login")
            }           
      }, 2000)
  
    } else if (error.response?.status === 403) {
      window.location.href = 'forbidden-page'
    } else if (error.response?.status === 500) {
        antNotificationComponent({
        type: 'error',
        description: error.response?.statusText || ' Internal server error',
      })
      setTimeout(function () {
        window.location.href = 'server-error'
      }, 2000)
    } else if (error.response?.data?.message) {
        antNotificationComponent({
        type: 'error',
        description: error.response?.data?.message,
      })
    } else {
        antNotificationComponent({
        type: 'error',
        description: 'Oops something went wrong',
      })
    }
  
    return Promise.reject(error)
  }
  
  API.interceptors.response.use(onFullfiled, onRejected)
  
  export default API
  