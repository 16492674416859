import React, {useState, useEffect} from 'react';
import {Table, TablePaginationConfig, TableProps} from 'antd';
import {PAGE_SIZE_OPTIONS} from "../../vars";
import {getQueryParams} from "../../utils/helpers";
import clientCheckInTabTableConfig from "./client_check_in_tab_table/client_check_in_tab_table_config";
import useScrollTo from "../../hooks/useScrollTo";
import { useAppSelector } from 'redux/store';
import { ICheckIn } from 'Interfaces/CheckIn';
import { SorterResult } from 'antd/lib/table/interface';
import { CheckInTableSettings } from 'redux/reducers/checkInsReducer';
import { ChosenCheckInsSummary } from './SummaryDashboard';

//NOTES: Based on a demo table, added a pop-up editor via modal and form.
//I used functions here rather than classes/extend React.Component as this causes issues getting a reference to the form in order to populate it.
//Looking to upgrade table to ProTable (has back-end linkages and search and stuff... but I was having troubles getting it to work)
//Data is populated locally, but shouldn't be much of a hassle to change to using a fetch to get the objects.

export interface ICheckInListProps {
    onSelectChange: (selected: ICheckIn[]) => Promise<void>,
    handleCheckInsTableChange:
        (pagination: TablePaginationConfig,
        filters: CheckInTableSettings,
        sorter: SorterResult<ICheckIn> | SorterResult<ICheckIn>[]
        ) => void,
        getCheckIn: (query?: CheckInTableSettings) => Promise<void>,

}

export default function CheckInList(props: ICheckInListProps) {
    const {onSelectChange, handleCheckInsTableChange, getCheckIn} = props

    const user = useAppSelector(state => state.user.userData!)
    const checkInData = useAppSelector(state => state.checkIns.checkInData);
    const totalCount = useAppSelector(state => state.checkIns.totalCount)
    const lastUpdate = useAppSelector(state => state.checkIns.lastUpdate);
    const selectedRowKeys = useAppSelector(state => state.checkIns.checkInSelectedRowKeys)
    const checkInSelectedRowData = useAppSelector(state => state.checkIns.checkInSelectedRowData)
    const checkInTableSettings = useAppSelector(state => state.checkIns.checkInTableSettings)

    const [loading, setLoading] = useState(true)

    const {getClientCheckInColumns} = clientCheckInTabTableConfig({...checkInTableSettings, user})

    const rowSelection: TableProps<ICheckIn>['rowSelection'] = {
        onSelectAll: async (flag, _, currentSelectedArray) => {
            onSelectChange(flag ?
                [...checkInSelectedRowData, ...currentSelectedArray] :
                checkInSelectedRowData.filter(elem => !currentSelectedArray.some(currentElem => elem.id === currentElem.id)))
        },
        onSelect: async (currentSelectedRow, flag) => {
            onSelectChange(flag ? [...checkInSelectedRowData, currentSelectedRow]
                : checkInSelectedRowData.filter(elem => elem.id !== currentSelectedRow.id))
        },
        selectedRowKeys,
    }

    const {
        pageSize,
        page,
    } = checkInTableSettings

    useScrollTo({dependency:[pageSize, page]})

    useEffect(() => {
        let cleanupFunction = false;
        const fetchData = async () => {
            setLoading(true)

            await getCheckIn({...getQueryParams(checkInTableSettings), delta: true, f_userId: [user.id]})

            if (!cleanupFunction) {
                setLoading(false)
            }
        }

        fetchData();

        return () => {cleanupFunction = true}

    }, [lastUpdate])

    const checkInsTabTablePagination =
        {
            pageSize: Number(pageSize),
            current: Number(page),
            pageSizeOptions: PAGE_SIZE_OPTIONS,
            total: totalCount,
            showSizeChanger: true,
            position: ['bottomRight'],
        }
    return (
        <>
            <ChosenCheckInsSummary/>
            <Table
                pagination={checkInsTabTablePagination as TablePaginationConfig}
                rowSelection={rowSelection}
                dataSource={checkInData}
                rowClassName={({autoGenerated}) => autoGenerated ? 'checkin-row-autogenerated' : ''}
                columns={getClientCheckInColumns}
                loading={loading}
                onChange={handleCheckInsTableChange}
                rowKey={(e) => e.id}
                scroll={{x: 'auto'}}
            />
        </>
    )
}