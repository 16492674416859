import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import { IClient } from 'Interfaces/User';
import React from 'react';
import { F_extra } from 'Types';
import { user } from 'vars';

interface InitialState {
    usersData: IClient[],
    coachesFilterOptions: IFilterOptions[],
    totalCount: number,
    lastUpdated: number,
    selectedUsers: React.Key[],
    usersTableSettings: IUsersTableSettings
};

export interface IUsersTableSettings {
    pageSize?: number,
    page?: number,
    s_field?: string | null | React.Key,
    s_direction?: string | null,
    f_clientFullNameOrEmail?: string | null,
    f_coachId?: string[] | null,
    f_ClientType?: string[] | null,
    f_roles?: string,
    f_LowMacro?: boolean[] | null,
    f_disabled?: boolean[] | null,
    f_extra?: F_extra | null,
    f_isLateLastCheckIn?: boolean[] | null,
    f_lastCheckInCompleted?: boolean[] | null,
    f_isFeedbackDue?: boolean[] | null,
};

interface IFilterOptions {
    text: string,
    value: string | boolean
};

const initialState = {
    usersData: [],
    coachesFilterOptions: [],
    totalCount: 0,
    selectedUsers: [],
    usersTableSettings: {
        pageSize: 10,
        page: 1,
        s_field: null,
        s_direction: null,
        f_clientFullNameOrEmail: null,
        f_LowMacro: null,
        f_disabled: null,
        f_roles: user,
        f_ClientType: null,
        f_coachId: null,
        f_extra: null,
        f_isLateLastCheckIn: null,
        f_lastCheckInCompleted: null,
        f_isFeedbackDue: null
    },
    lastUpdated: Date.now()
} as InitialState;

const usersReducer = createSlice({
    name: 'usersReducer',
    initialState,
    reducers: {
        setUsers: (state, action: PayloadAction<{users: IClient[], totalCount: number}>) => {
            state.usersData = action.payload.users
            state.totalCount = action.payload.totalCount
        },
        setCoachesFilterOptions: (state, action: PayloadAction<{coaches: IFilterOptions[]}>) => {
            state.coachesFilterOptions = action.payload.coaches
        },
        setUserToken: (state, action: PayloadAction<{id: string, apiKey: string | null}>) => {
            state.usersData = state.usersData.map((user) => user.id === action.payload.id ? ({...user, apiKey: action.payload.apiKey}) : user)
        },
        setUsersTableSettings: (state, action: PayloadAction<IUsersTableSettings>) => {
            state.usersTableSettings = {...state.usersTableSettings, ...action.payload}
        },
        setUserTableUpdated: (state, action: PayloadAction<{lastUpdated: number}>) => {
            state.lastUpdated = action.payload.lastUpdated
        },
        setSelectedUsers:(state, action: PayloadAction<{selectedUsers: React.Key[]}>) => {
            state.selectedUsers = action.payload.selectedUsers
        },
        setUpdateIsFeedbackDue: (state, action: PayloadAction<React.Key[]>) => {
            state.usersData = state.usersData.map((user) => {
                const findUser = action.payload.find(actionUser => user.id === actionUser);
                if(findUser){
                    return {
                        ...user,
                        isFeedbackDue: 1,
                    }
                }
                return user;
            })
        },
        usersReducerReset: state => initialState
    }
})

export const {
    setUsers,
    setUsersTableSettings,
    setCoachesFilterOptions,
    setUserTableUpdated,
    usersReducerReset,
    setSelectedUsers,
    setUpdateIsFeedbackDue,
    setUserToken,
} = usersReducer.actions
export default usersReducer.reducer


