import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import { CheckInsCountData } from 'Interfaces/CheckIn';
import { IPhoto } from 'services/photo-service'

interface InitialState {
    groupPreview: IPhoto[],
    visibleGroupPreview: boolean,
    groupPreviewCheckInCounts: {
        data: CheckInsCountData | null;
        isLoading: boolean;
    }
}

const initialState: InitialState = {
    groupPreview: [],
    visibleGroupPreview: false,
    groupPreviewCheckInCounts: {
        data: null,
        isLoading: false
    }
}

const photosReducer = createSlice({
    name: 'photosReducer',
    initialState,
    reducers: {
        setGroupPreview: (state, action: PayloadAction<{groupPreview: IPhoto[]}>) => {
            state.groupPreview = action.payload.groupPreview
        },
        setVisibleGroupPreview: (state, action: PayloadAction<{visibleGroupPreview: boolean}>) => {
            state.visibleGroupPreview = action.payload.visibleGroupPreview
        },
        setIsLoadingGroupPreviewCheckInCounts: (state, action: PayloadAction<boolean>) => {
            state.groupPreviewCheckInCounts.isLoading = action.payload;
        },
        setGroupPreviewCheckInCounts: (state, action: PayloadAction<CheckInsCountData | undefined>) => {
            state.groupPreviewCheckInCounts.data = action.payload ?? null;
            state.groupPreviewCheckInCounts.isLoading = false;
        },
        photosReducerReset: state => initialState
    }
})

export const {
    setGroupPreview,
    setVisibleGroupPreview,
    setIsLoadingGroupPreviewCheckInCounts,
    setGroupPreviewCheckInCounts,
    photosReducerReset
} = photosReducer.actions

export default photosReducer.reducer


