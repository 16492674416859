import {useState, useEffect} from 'react';

export function debounce(func: Function, timeout = 100){
    let timer: any;
    return (...args: any[]) => {
        clearTimeout(timer);
        // @ts-ignore
        timer = setTimeout(() => { func.apply(this,args); }, timeout);
    };
}


const useResize = () => {
    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
    })

    const handleResize = () => {
        setWindowSize({
            width: window.innerWidth,
            height: window.innerHeight
        })
    };

    const debounceHandleResize = debounce(() => handleResize());

    useEffect(() => {

        window.addEventListener('resize', debounceHandleResize);
        return () => {
            window.removeEventListener('resize', debounceHandleResize);
        }
    }, []);
    return windowSize;
};

export default useResize;