import axios, {AxiosError, AxiosResponse} from 'axios';
import antNotificationComponent from "../utils/notification-ant-utils";
import {useHistory} from "react-router-dom";
import {useDispatch} from "react-redux";


function useApi() {
    const history = useHistory()
    const dispatch = useDispatch()

    const source = axios.CancelToken.source();

    const API = axios.create({
        baseURL: '/',
        responseType: 'json',
        headers: {'cache': 'no-store'},
        cancelToken: source.token,
    })

    const onFullfiled = (response: AxiosResponse) => response

    const onRejected = (error: AxiosError) => {
        if (error.response?.status === 401) {
            localStorage.clear() //clear all data if the cookie expired
            dispatch({type:'Logout'})
            history.push('/login')
        } else if (error.response?.status === 403) {
            history.push('/forbidden')
        } else if (error.response?.status === 500) {
            antNotificationComponent({
                type: 'error',
                description: error.response.statusText || ' Internal server error',
            })
        } else if (error.response?.data?.message) {
            antNotificationComponent({
                type: 'error',
                description: error.response.data?.message,
            })
        } else {
            return Promise.reject(error)
        }
    }

    API.interceptors.response.use(onFullfiled, onRejected)
    return {API, cancel:source.cancel}
}

export default useApi;