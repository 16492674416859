import React, { FC } from 'react';
import ProForm, { ProFormText, ProFormSelect, ProFormCheckbox, ProFormTextArea, ProFormDatePicker } from '@ant-design/pro-form';
import { ProFormSelectProps } from '@ant-design/pro-form/lib/components/Select';
import { GroupProps } from '@ant-design/pro-form/lib/interface';
import { Tag } from 'antd';
import { ITask, StatusTask } from 'Interfaces/TasksCategories';
import { useAppSelector } from 'redux/store';
import { DATE_FORMAT, CHAR_STRING_SIZE } from 'vars';
import moment from 'moment';
import { Rule } from 'antd/lib/form';
import { getPopoverText, validateRequired } from 'utils/helpers';
interface ProFormGroupProps extends GroupProps  {
    children: React.ReactNode
}

interface ITaskFormProps {
    currentTask: ITask | null
}

const ProFormGroup: FC<ProFormGroupProps> = ({children,...props}) => <ProForm.Group {...props}>{children}</ProForm.Group>

const capitalizeFirstLetter = (string: string) => string.charAt(0).toUpperCase() + string.slice(1);

const rules: Rule[] = [
    () => ({
        async validator(_, value){
            await validateRequired(value);
            return Promise.resolve(value);
        }
    })
]

const TaskForm: FC<ITaskFormProps> = ({ currentTask }) => {
    const categories = useAppSelector((state) => state.tasks.categories);

    const statusOptions: ProFormSelectProps['options'] = [
        {
            label: <Tag color="blue">{capitalizeFirstLetter(StatusTask.opened)}</Tag>,
            value: StatusTask.opened
        },
        {
            label: <Tag color="green">{capitalizeFirstLetter(StatusTask.completed)}</Tag>,
            value: StatusTask.completed
        },
        {
            label: <Tag color="orange">{capitalizeFirstLetter(StatusTask.archived)}</Tag>,
            value: StatusTask.archived
        },
    ];

    const categoryOptionsOpened: ProFormSelectProps['options'] = categories.filter((category) => !category.archived).map((category) => ({
        label: <Tag color={category.color}>{getPopoverText(category.name, 25)}</Tag>,
        value: category.id,
        title: category.description
    }));

    const categoryOptionsEdited: ProFormSelectProps['options'] = [
        { 
            label: <Tag color={currentTask?.taskCategory.color}>{getPopoverText(currentTask?.taskCategory.name, 25)}</Tag>,
            value: currentTask?.taskCategory.id || '',
            title: currentTask?.taskCategory.description
        },
        ...categoryOptionsOpened.filter((task) => task.value !== currentTask?.taskCategoryId),
    ];

    return (
        <>
            <ProFormGroup>
                <ProFormText required rules={[...rules, { max: CHAR_STRING_SIZE }]} name="name" label="Task Name" placeholder="Task Name" />
                <ProFormSelect
                    required
                    rules={rules}
                    name="status"
                    options={statusOptions}
                    initialValue={statusOptions[0].value}
                    label="Status"
                    placeholder="Status"
                    allowClear={false}
                />
                <ProFormSelect fieldProps={{dropdownMatchSelectWidth: false}} required rules={rules} name="taskCategoryId" options={currentTask ? categoryOptionsEdited : categoryOptionsOpened} placeholder="Category" label="Category" allowClear={false} />
                <ProFormCheckbox name="pin" label=" ">Pin To Top</ProFormCheckbox>
            </ProFormGroup>
            <ProFormTextArea name="description" label="Description" placeholder="Description" />
            <ProFormGroup>
                <ProFormDatePicker
                    fieldProps={{
                        disabledDate: (current) => {
                            let customDate = moment().format(DATE_FORMAT);
                            return current && current < moment(customDate, DATE_FORMAT);
                        }
                    }} 
                    name="reminderDate" 
                    placeholder="Select date" 
                    label="Reminder Date" 
                />
                <ProFormDatePicker
                    fieldProps={{
                        disabledDate: (current) => {
                            let customDate = moment().format(DATE_FORMAT);
                            return current && current < moment(customDate, DATE_FORMAT);
                        }
                    }} 
                    name="dueDate"
                    placeholder="Select date"
                    label="Due Date" 
                />
            </ProFormGroup>
        </>
    )

};

export default TaskForm