import React, { FC } from "react";
import {Button, Space} from "antd";
import { FilterDropdownProps } from "antd/lib/table/interface";
import { ITableSettings } from "redux/reducers/activityReducer";


interface Props {
   confirm: FilterDropdownProps['confirm'],
   checkedData: string[]
   handleResetUserFilters: () => void,
   handleSelect: (params: ITableSettings) => void 
}

const ActivityListFooter: FC<Props> = ({confirm, handleSelect, checkedData, handleResetUserFilters}) => {
    const handleClickOk = () => {
        !!checkedData.length ? handleSelect({f_clientId:checkedData}) : handleSelect({f_clientId:null})
        confirm({closeDropdown: true});
    }
    const handleClickReset = () => {
        handleResetUserFilters()
        confirm({closeDropdown: true});
    }
    return (
        <Space className="activity_space_footer_wrapper">
            <Button onClick={handleClickReset} className={'ant-btn ant-btn-link ant-btn-sm'}>
                Reset
            </Button>
            <Button onClick={handleClickOk} className={'ant-btn ant-btn-primary ant-btn-sm'}>
                OK
            </Button>
        </Space>
    )
}

export default ActivityListFooter