import React, {useState} from 'react';
import {Form, Select} from 'antd';
import antNotificationComponent from "../../utils/notification-ant-utils";
import {goalOptions} from "../../vars";
import { useAppSelector } from 'redux/store';
import { UserData } from 'redux/reducers/userReducer';
import { Modal } from 'components/Antd/Modal';
import { AxiosResponse } from 'axios';
import { UserType } from 'Types';

export interface IChangeGoalModalProps {
    onCancel: () => void,
    handleChangeGoal: (id: string, data: UserData) => Promise<AxiosResponse<UserType> | undefined>,
    checkInTableUpdate: (callback?: () => void) => void
}


export default function ChangeGoalModal({checkInTableUpdate, handleChangeGoal, onCancel}: IChangeGoalModalProps) {
    const user = useAppSelector(state => state.user.userData!)
    const {physiqueGoal} = user
    const changeGoalModalVisible = useAppSelector(state => state.modals.changeGoalModalVisible)
    const [goal, setGoal] = useState(!!physiqueGoal ? physiqueGoal : undefined)

    const onFinish = async (user: UserData, goal: string | undefined) => {
        if (!!goal && physiqueGoal !== goal) {
            const data = await handleChangeGoal(user.id, {...user, physiqueGoal: goal})
            if (!!data) {
                onCancel()
                checkInTableUpdate()
                antNotificationComponent({message: "Updated", type: 'success'})
            } else {
                antNotificationComponent({message: "Failed to update", type: 'error'})
            }
        }
    }

    return (
        <Modal centered visible={changeGoalModalVisible} onCancel={onCancel} onOk={() => onFinish(user, goal)}
               title="Select Goal" okText={"Save Goal"} >
            <Form initialValues={{physiqueGoal}}>
                <Form.Item name={'physiqueGoal'} rules={[{required: true, message: 'Please choose goal'}]}>
                    <Select size={'large'} value={goal} placeholder={''} options={goalOptions}
                            onChange={(value) => setGoal(value)}/>
                </Form.Item>
            </Form>
        </Modal>
    )

}