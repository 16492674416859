import React, {useState} from "react"
import {Col, Row, TablePaginationConfig} from "antd"
import DashboardPageCoachUsersTableHeader from "./DashboardPageCoachUsersTableHeader"
import useScrollTo from "hooks/useScrollTo"
import DashboardPageUsersTable from "./DashboardPageUsersTable"
import {changeSortDirectionTitle, isFiltersChange} from "utils/helpers";
import useModals from "services/modals-service";
import FeedbackModal, { ISendFeedbackModal } from "../checkin/feedbackModal";
import useMessageTemplatesApiWithRedux from "services/message-templates-service";
import useFeedbackApiWithRedux from "services/feedback-service";
import antNotificationComponent from "utils/notification-ant-utils";
import {IUsersTableSettings, setSelectedUsers, setUpdateIsFeedbackDue} from "redux/reducers/usersReducer";
import { useAppDispatch, useAppSelector } from "redux/store"
import { IClient } from "Interfaces/User"
import { FilterValue, SorterResult } from "antd/es/table/interface"
import { F_extra } from "Types"

interface Props {
    handleUsersTableChange: (pagination: TablePaginationConfig, filters: Record<string, FilterValue | null>, sorter: SorterResult<IClient> | SorterResult<IClient>[]) => void
    f_coachId: [string | null],
    setExtraUserFilter: (value: F_extra) => void,
    initialUsers: IUsersTableSettings,
    setInitialUsers: React.Dispatch<IUsersTableSettings>,
    getUsersCoach: () => Promise<void>,
    onSelectUsersChange: (selectedUsers: React.Key[]) => void
};

function DashboardPageUsersTableWrapper(props: Props) {
    const {
        handleUsersTableChange,
        setExtraUserFilter,
        initialUsers,
        setInitialUsers
    } = props
    const dispatch = useAppDispatch();
    const selectedUsers = useAppSelector((state) => state.users.selectedUsers);
    const selectedTemplates = useAppSelector((state) => state.messageTemplates.selectedTemplates);
    const messageTemplatesData = useAppSelector(state => state.messageTemplates.messageTemplatesData);
    const selectedCoachId = useAppSelector((state) => state.dashboard.selectedCoachId)
    const usersTableSettings = useAppSelector(state => state.users.usersTableSettings)
    const {page, pageSize, f_extra} = usersTableSettings
    const [isLoading, setIsLoading] = useState(true)
    const {setFeedbackVisible} = useModals();
    const messageTemplatesProps = useMessageTemplatesApiWithRedux();
    const {saveFeedback_dashboard, setCurrentFeedbackDeleteClear} = useFeedbackApiWithRedux();

    const setExtraFilter = (value: F_extra) => {
        setInitialUsers({
            ...initialUsers,
            f_extra: f_extra !== value ? value : null,
            page: 1,
        })
        setExtraUserFilter(value)
    }

    const handleTableChange = (pagination: TablePaginationConfig, filters: Record<string, FilterValue | null>, sorter: SorterResult<IClient> | SorterResult<IClient>[]) => {
        const {pageSize, current} = pagination
        const {columnKey, order} = sorter as SorterResult<IClient>
        setInitialUsers({
            ...initialUsers,
            ...filters,
            pageSize,
            page: isFiltersChange({
                prevFilters: usersTableSettings,
                currentFilters: filters,
            })
                ? 1
                : current,
            s_field:
                !!columnKey && !!order
                    ? (columnKey as string).startsWith("f_")
                        ? (columnKey as string).slice("f_".length)
                        : columnKey
                    : null,
            s_direction: !!order ? changeSortDirectionTitle(order) : null,
        })

        handleUsersTableChange(pagination, filters, sorter)
    }

    const {scrollRef} = useScrollTo<HTMLDivElement>({dependency: [pageSize, page], offset: 25})

    const saveFeedback = async (values: ISendFeedbackModal) => {
        const selectedTemplatesId = messageTemplatesData
            .filter((template) => selectedTemplates.find(templateName => template.name === templateName || template.id === templateName))
            .map(({id}) => id);

        const requests = {
            ...values,
            userId: selectedUsers,
            notes: values.notes,
            selectedTemplates: selectedTemplatesId
        }

    const data = await saveFeedback_dashboard(requests);
        if(data?.message === 'Success'){
                antNotificationComponent({
                    type: 'success',
                    message: 'Saved',
                    key: "feedbackSuccess",
                })
            setFeedbackVisible(false);
            dispatch(setUpdateIsFeedbackDue(selectedUsers));
            dispatch(setSelectedUsers({selectedUsers: []}));
            return data;
        }
    };

    return (
        <Col span={24}>
            <Row className={"dashboard_widget_column_row"}>
                <Col span={24}>
                    <DashboardPageCoachUsersTableHeader
                        {...props}
                        showFeedback={setFeedbackVisible}
                        selectedUsers={selectedUsers}
                        isLoading={isLoading}
                        setExtraFilter={setExtraFilter}
                    />
                    <div ref={scrollRef}>
                        <DashboardPageUsersTable
                            {...props}
                            f_coachId={[selectedCoachId]}
                            handleTableChange={handleTableChange}
                            isLoading={isLoading}
                            setIsLoading={setIsLoading}
                        />
                    </div>
                </Col>
            </Row>
            <FeedbackModal
                {...messageTemplatesProps}
                setCurrentFeedbackDeleteClear={setCurrentFeedbackDeleteClear}
                addFeedback={saveFeedback}
                setVisible={setFeedbackVisible}
                modal={true}
            />
        </Col>
    )
}

export default DashboardPageUsersTableWrapper
