import React, { FC } from 'react';
import {Avatar, Conversation as ConversationList} from "@chatscope/chat-ui-kit-react";
import {emojifyMessage, getDateChatList, getOnlineStatus} from "utils/helpers";
import chat_avatar from "../../chat_avatar.png"
import {Button, Menu} from "antd";
import {EyeInvisibleOutlined, EyeOutlined, MoreOutlined} from "@ant-design/icons";
import { Dropdown } from 'components/Antd/DropDown';
import {MenuClickEventHandler} from "rc-menu/lib/interface"
import { IChat } from 'Interfaces/Chat';

interface Props {
    currentUser: IChat | null,
    user: IChat,
    onSetCurrentUser: (user: IChat) => void,
    updateReadCount: (user: IChat) => Promise<void>,
    putUnreadUserMessages: (user: IChat) => Promise<void>
}

const Conversation: FC<Props> = ({user, currentUser, onSetCurrentUser, updateReadCount, putUnreadUserMessages }) => {
    const markAsRead = async () => {
        await updateReadCount(user);
    };

    const markAsUnread = async () => {
        await putUnreadUserMessages(user);
    }

    const handleMenuClick: MenuClickEventHandler = ({domEvent, key}) => {
        if(key === 'read'){
            markAsRead();
        }
        if(key === 'unread'){
            markAsUnread();
        }
        domEvent.preventDefault();
        domEvent.stopPropagation();
    }

    const menu = (
        <Menu onClick={handleMenuClick}>
            <Menu.Item disabled={user.unreadCount === 0}  key="read">
                Mark as read <EyeOutlined />
            </Menu.Item>
            <Menu.Item disabled={currentUser?.userId === user.userId || user.unreadCount > 0}
                       key="unread">
               Mark as unread <EyeInvisibleOutlined />
            </Menu.Item>
        </Menu>
    )


    return (<ConversationList
        active={currentUser?.userId === user.userId}
        unreadCnt={user.unreadCount}
        lastActivityTime={getDateChatList(user.date)}
        onClick={() => {
            onSetCurrentUser(user);
            updateReadCount(user);
        }}
        name={user.userName}
        info={emojifyMessage(user.notes)}
    >
        <Avatar status={getOnlineStatus(user.lastOnline)} src={chat_avatar} />
            <ConversationList.Operations
                onClick={(domEvent) => {
                    domEvent.preventDefault();
                    domEvent.stopPropagation();
                }}
                 visible>
                <Dropdown
                    overlay={menu}
                    trigger={['click']}
                >
                    <Button
                    onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                    }}
                    type="link"
                    className="conversation_action_menu_main_button"
                    >
                        <MoreOutlined />
                    </Button>
                </Dropdown>
            </ConversationList.Operations>
    </ConversationList>)
};

export default Conversation;