import React from 'react';
import { useAppSelector } from 'redux/store';
import DashboardPageChartsBar from "./DashboardPageChartsBar";
import Chart, { ChartConfiguration } from 'chart.js/auto';

function DashboardPageClientsChangeStatus() {
    const clientProgress = useAppSelector(state => state.dashboard.clientsProgress);
    const [checkedIn, late, missed1, missed2] = clientProgress

    const data: ChartConfiguration<'bar'>['data'] = {
        labels: clientProgress.map(({label}) => label),
        datasets: [
            {
                label: 'Checked in',
                data: [0, checkedIn?.activeCheckinedLate, checkedIn?.inactiveMissedOneWeek, checkedIn?.inactiveMissedMoreThanTwoWeeks ],
                backgroundColor: "#a3f06c",
                stack: 'Stack 0'
            },
            {
                label: 'Late',
                data: [late?.activeCheckinedNotLate, 0, late?.inactiveMissedOneWeek, late?.inactiveMissedMoreThanTwoWeeks],
                backgroundColor: "#b6b5b5",
                stack: 'Stack 0'
            },
            {
                label: 'Missed 1',
                data: [missed1?.activeCheckinedNotLate, missed1?.activeCheckinedLate ,0, missed1?.inactiveMissedMoreThanTwoWeeks],
                backgroundColor: "#e66060",
                stack: 'Stack 0'
            },
            {
                label: 'Missed 2+',
                data: [missed2?.activeCheckinedNotLate, missed2?.activeCheckinedLate, missed2?.inactiveMissedOneWeek, 0],
                backgroundColor: "#cc2727",
                stack: 'Stack 0'
            },
        ]
    }

    const options: ChartConfiguration<'bar'>['options'] = {
        plugins: {
            title: {
                display: true,
                position: 'bottom',
                text: '',
            },
        },
        scales: {
            x:{
                stacked: true
            },
            y:{
                ticks: {
                    precision: 0
                },
                stacked: true
            },
        }
    }

    const clientsProps: ChartConfiguration<'bar'> = {
        type: 'bar',
        data,
        options
    }

    return (
        // checkDashboardData(data.datasets) ?
            <DashboardPageChartsBar {...clientsProps}  title="Clients change since last week" />
            // :
            // <NoData description="Clients change since last week (No Data)" />
    )
}

export default  DashboardPageClientsChangeStatus;