import React from 'react'
import {Image, Space} from 'antd'
import Form from 'antd/lib/form'
import Input from 'antd/lib/input'
import Button from 'antd/lib/button'
import Checkbox from 'antd/lib/checkbox'
import 'antd/dist/antd.css'
import './_login.less'
import {UserOutlined, LockOutlined} from '@ant-design/icons'
import {Link, Redirect, useHistory} from 'react-router-dom'
import logo from 'img/smp-logo.png'
import {login} from "services/authService";
import {validateNoSpaces, validateRequired} from "utils/helpers";
import { useAppSelector } from 'redux/store'

export interface ILoginForm {
    email: string,
    password: string,
    remember: boolean,
}

const LoginPage = () => {
    const history = useHistory()
    const isAuth = useAppSelector((state) => state.auth.isAuth);
    const prevLocation = useAppSelector((state) => state.auth.prevLocation);
    const onFinish = async (userData: ILoginForm) => login({history, userData})
    // const onFinish = () => {};
    //This component visible only for logged in users

    return isAuth ? <Redirect to={prevLocation || "/"} /> : (
        <div className="login__form">
            <Space align={"center"} style={{width: '100%', justifyContent: 'center'}}>
                <Image width={200} src={logo} preview={false}/>
            </Space>

            <div className="container-fluid-sm">
                <h1>Log In:</h1>
                <Form name="normal_login" className="login-form" initialValues={{remember: true}} onFinish={onFinish}>
                    <Form.Item
                        name="email"
                        validateTrigger={['onBlur']}
                        // rules={[{required: true, message: 'Enter your email'}]}
                        rules={[() => ({
                            async validator(_, value) {
                                await validateRequired(value)
                                await validateNoSpaces(value);
                                // await validateEmail(value)
                                return Promise.resolve(value);
                            },
                        }), {required: true, message: ''}]}
                    >
                        <Input size="large" prefix={<UserOutlined className="site-form-item-icon"/>}
                               placeholder="Email"/>
                    </Form.Item>
                    <Form.Item
                        name="password"
                        validateTrigger={['onBlur']}
                        rules={[{required: true, message: 'This field is required'}]}>
                        <Input.Password
                            size="large"
                            prefix={<LockOutlined className="site-form-item-icon"/>}
                            type="password"
                            placeholder="Password"/>
                    </Form.Item>
                    <Form.Item>
                        <Form.Item name="remember" valuePropName="checked" noStyle>
                            <Checkbox>Remember me</Checkbox>
                        </Form.Item>
                        <Link to="/reset-password">Forgot password</Link>
                    </Form.Item>
                    <Form.Item>
                        <Button htmlType="submit" className="btn">Log in</Button>
                    </Form.Item>
                </Form>
            </div>
        </div>
    )

}

export default LoginPage
