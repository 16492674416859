import React from "react";
import {List, Pagination, PaginationProps} from "antd";
import Note from "./Note";
import useScrollTo from "../../hooks/useScrollTo";
import { useAppSelector } from "redux/store";
import { IMessageStatus } from "Interfaces/Auth";
import { IClientNoteEmoji } from "Interfaces/ClientNote";

interface Props {
    notesPageUpdate: (page: number) => void,
    loading: boolean,
    deleteActions: (action: {actionId: string}) => Promise<IMessageStatus | undefined>
    setActions: ({ action, notesId, actionDetail }: {
        action: string;
        actionDetail: string;
        notesId: string;
    }) => Promise<IClientNoteEmoji | undefined>
}

export default function NotesList(props: Props) {
    const { loading, notesPageUpdate} = props

    const notes = useAppSelector(state => state.userNotes.clientNotes)
    const totalCount = useAppSelector(state => state.userNotes.totalCount)
    const page = useAppSelector(state => state.userNotes.page)
    useScrollTo({dependency:[page]})
    const notesListPagination: PaginationProps =
        {
            pageSize: 10,
            current: Number(page),
            total: totalCount,
            size: "small",
            onChange: (page) => notesPageUpdate(page)
        }

    return (
        <>
            <List
                loading={loading}
                itemLayout="horizontal"
                dataSource={notes}
                renderItem={item => {
                    const itemProps = {...item, ...props}
                    return (
                        <li key={item.id} className={'notes_list_note_wrapper'}>
                            <Note {...itemProps}/>
                        </li>
                    )
                }}
            />
            {!!notes.length && <div className={'notes_list_pagination'}>
                <Pagination {...notesListPagination} />
            </div>}
        </>
    )
}