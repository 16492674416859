import React, { FC } from 'react';
import NoData from "components/no-data/NoData";
import {Conversation as ConversationItem,ConversationList} from "@chatscope/chat-ui-kit-react";
import Conversation from "../Conversation";
import { IChat } from 'Interfaces/Chat';

export interface SearchListProps {
    onSetCurrentUser: (user: IChat) => void,
    putUnreadUserMessages: (user: IChat) => Promise<void>,
    updateReadCount: (actionMenuUser: IChat) => Promise<void>,
    searchList: IChat[],
    isLoading: boolean,
    searchValue: string,
    currentUser: IChat | null
}

const _ConversationList_SearchList: FC<SearchListProps> = ({
    onSetCurrentUser, 
    putUnreadUserMessages, 
    updateReadCount, 
    searchList, 
    isLoading, 
    searchValue, 
    currentUser
}) => {
    return (
        <ConversationList
        loading={isLoading}
        scrollable
        >
            {
                searchValue && searchList.length === 0 &&
                    <div
                    className="cs-chat__noFound"
                    >
                        <ConversationItem>
                            <ConversationItem.Content>
                                <NoData description="No people found" />
                            </ConversationItem.Content>
                        </ConversationItem>
                    </div>
            }
            {
                searchList?.map((user) => (
                    <Conversation
                        key={user.userId}
                        user={user}
                        currentUser={currentUser}
                        putUnreadUserMessages={putUnreadUserMessages}
                        onSetCurrentUser={onSetCurrentUser}
                        updateReadCount={updateReadCount}
                    />
                ))
            }
        </ConversationList>
    )


};

export default _ConversationList_SearchList;