import {createSlice} from "@reduxjs/toolkit";
import { PayloadAction } from "@reduxjs/toolkit";
import {FETCH_CHAT_PAGE_SIZE, ONE_DAYS_MORE_LATE, TWO_DAYS_MORE_LATE, CHAT_SEARCH_USERS} from "vars";
import {IChat, IChat_Message, IChatRead, IChatList} from 'Interfaces/Chat';
import { ResponseTypeChat } from "Types";



interface InitialState {
    currentUser: IChat | null,
    chatList: IChat[],
    currentUserChatList: IChat_Message[]
    currentUserChatListWithError: IChat_Message[],
    [ONE_DAYS_MORE_LATE]: IChat[],
    [TWO_DAYS_MORE_LATE]: IChat[],
    lastUpdate: number | string | null,
    lastMessage: IChat_Message | null,
    searchValue: string,
    searchUsers: IChat[],
    currentUserTotalChats: number,
    chatListTotalCount: number,
    chatListLateTotalCount: number,
    searchUsersTotalCount: number,
}

const initialState = {
    currentUser: null,
    currentUserChatList: [],
    currentUserChatListWithError: [],
    currentUserTotalChats: 0,
    chatListTotalCount: 0,
    chatListLateTotalCount: 0,
    searchUsers: [],
    searchUsersTotalCount: 0,
    searchValue: "",
    chatList: [],
    [ONE_DAYS_MORE_LATE]: [],
    [TWO_DAYS_MORE_LATE]: [],
    lastUpdate: null,
    lastMessage: null,
} as InitialState;

interface ISearchUsers {
    contacts: IChat[],
    totalCount: number,
    searchValue: string,
}

interface IResponseType {
    responseType: ResponseTypeChat | null
}

export interface IMessagesList {
    messages: IChat_Message[],
    totalCount: number,
    firstPage?: IChat_Message[],
    responseType?: ResponseTypeChat | null
};




const chatReducer = createSlice({
    name: 'chatReducer',
    initialState,
    reducers: {
        setChatList: (state, action: PayloadAction<IChatList>) => {
            state.chatList = [...action.payload.chats, ...state.chatList.splice(FETCH_CHAT_PAGE_SIZE, state.chatList.length)];
            state.chatListTotalCount = action.payload.totalCount;
            },
        setChatListLate: (state, action: PayloadAction<IChatList & IResponseType>) => {
            state[action.payload.responseType as ResponseTypeChat] = [...action.payload.chats, ...state[action.payload.responseType as ResponseTypeChat].splice(FETCH_CHAT_PAGE_SIZE, state[action.payload.responseType as ResponseTypeChat].length)];
            state.chatListLateTotalCount = action.payload.totalCount;
        },
        setMoreChatListLate: (state, action: PayloadAction<IChatList & IResponseType>) => {
            state[action.payload.responseType as ResponseTypeChat] = [...state[action.payload.responseType as ResponseTypeChat], ...action.payload.chats];
            state.chatListLateTotalCount = action.payload.totalCount;
        },
        setMoreChatList: (state, action: PayloadAction<IChatList>) => {
          state.chatList = [...state.chatList, ...action.payload.chats];
          state.chatListTotalCount = action.payload.totalCount;
        },
        setMoreMessagesList: (state, action: PayloadAction<IMessagesList>) => {
          state.currentUserChatList = [...state.currentUserChatList, ...action.payload.messages];
          state.currentUserTotalChats = action.payload.totalCount;
        },
        setCurrentUser: (state, action: PayloadAction<IChat | null>) => {
            state.currentUser = action.payload;
            state.currentUserChatList = [];
            state.currentUserTotalChats = 0;
            state.currentUserChatListWithError = [];
        },
        setCurrentUserChatList: (state, action: PayloadAction<IMessagesList>) => {
            const lastMessage = action.payload.messages[0];
            state.currentUserChatList =
                [...action.payload.messages, ...state.currentUserChatList.splice(FETCH_CHAT_PAGE_SIZE, state.currentUserChatList.length)];
                    if(action.payload.responseType){
                        state[action.payload.responseType] = state[action.payload.responseType].map((user) => user.userId === state.currentUser?.userId ? ({
                            ...user,
                            notes: lastMessage.notes || '',
                            date: lastMessage.date || '',
                        }) : user)
                    }
            state.currentUserTotalChats = action.payload.totalCount;
            state.lastMessage = lastMessage;
        },
        setUpdate: (state, action: PayloadAction<number>) => {
            state.lastUpdate = action.payload;
        },
        setUnreadMessage: (state, action: PayloadAction<IChatRead>) => {
            state.chatList = state.chatList.map((user) => action.payload.userId === user.userId ? ({
                ...user,
                unreadCount: action.payload.unreadCount
            }) : user )
        },
        setReadMessage: (state, action: PayloadAction<IChat>) => {
          state.chatList = state.chatList.map((user) => action.payload.userId === user.userId ? ({
              ...user,
              unreadCount: 0
          }) : user )
        },
        setReadLateMessage: (state, action: PayloadAction<{currentUser: IChatRead} & IResponseType>) => {
            state[action.payload.responseType as ResponseTypeChat] = state[action.payload.responseType as ResponseTypeChat].map((user) => action.payload.currentUser.userId === user.userId ? ({
                ...user,
                unreadCount: 0
            }) : user )
        },
        setUnreadLateMessage: (state, action: PayloadAction<{currentUser: IChatRead} & IResponseType>) => {
            state[action.payload.responseType as ResponseTypeChat] = state[action.payload.responseType as ResponseTypeChat].map((user) => action.payload.currentUser.userId === user.userId ? ({
                ...user,
                unreadCount: action.payload.currentUser.unreadCount
            }) : user )
        },
        setSearchUsers: (state, action: PayloadAction<ISearchUsers>) => {
            state.searchUsers = action.payload.contacts;
            state.searchUsersTotalCount = action.payload.totalCount;
            state.searchValue = action.payload.searchValue;
        },
        setSendMessage: (state, action: PayloadAction<IChat_Message>) => {
            state.currentUserChatList = [action.payload, ...state.currentUserChatList]
        },
        setSendSuccessMessage: (state, action: PayloadAction<{
            currentUser: IChat | null,
            data: IChat_Message,
            responseType: ResponseTypeChat | null
        }>) => {
            state.chatList = state.chatList.filter(({userId}) => userId !== action.payload.currentUser?.userId);
            state.lastUpdate = action.payload.currentUser!.userId;
        },
        setSendSuccessMessageLate: (state, action: PayloadAction<{
            currentUser: IChat | null,
            data: IChat_Message,
            responseType: ResponseTypeChat | null
        }>) => {
            if(action.payload.responseType !== CHAT_SEARCH_USERS){
                state[action.payload.responseType as ResponseTypeChat] = state[action.payload.responseType as ResponseTypeChat].filter((user) => user.userId !== action.payload.currentUser?.userId);
            }
            state.chatListLateTotalCount = state.chatListLateTotalCount - 1;
        },
        setSendErrorMessage: (state, action: PayloadAction<IChat_Message>) => {
            state.currentUserChatListWithError = [{...action.payload}, ...state.currentUserChatListWithError];
            state.currentUserChatList = [...state.currentUserChatList].filter(({isSendingMessage}) => !isSendingMessage);
        },
        setReset: () => initialState
    }
});

export const {setChatList, setChatListLate, setSendSuccessMessageLate, setMoreChatListLate, setUnreadMessage, setReadLateMessage, setUnreadLateMessage, setSendErrorMessage, setSendSuccessMessage, setSearchUsers, setSendMessage, setCurrentUser, setReadMessage, setCurrentUserChatList, setUpdate, setReset, setMoreChatList, setMoreMessagesList} = chatReducer.actions;
export default  chatReducer.reducer;