import {useCallback, useEffect, useState} from "react";
import { useAppSelector, useAppDispatch } from "redux/store";
import useApi from "hooks/useApi";
import {
    setChatList,
    setCurrentUser,
    setMoreChatList,
    setReadMessage, setUnreadMessage,
} from "redux/reducers/chatReducer";
import {FETCH_CHAT_PAGE_SIZE} from "vars";
import { IChat, IChatList, IChatParams, IChatRead } from "Interfaces/Chat";

const useConversationApi = () => {
    const {API} = useApi();
    const dispatch = useAppDispatch();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const currentUser = useAppSelector((state) => state.chat.currentUser);
    const chatList = useAppSelector((state) => state.chat.chatList);
    const totalCount = useAppSelector((state) => state.chat.chatListTotalCount);
    const lastUpdate = useAppSelector((state) => state.chat.lastUpdate);
    const user = useAppSelector((state) => state.auth.user);

    const getConversationList = async (params: IChatParams = {page: 1, pageSize: FETCH_CHAT_PAGE_SIZE},loading = false) => {
        setIsLoading(loading);
        try {
            const response = await API.get<IChatList>('/api/chat', {
                params
            });
            dispatch(setChatList(response.data));
            setIsLoading(false);
        }catch (e) {
            if(e instanceof Error){
                console.log(e);
                setIsLoading(false);
            }
        }
    }


    const putUnreadUserMessages = async ({userId}: IChat) => {
        try {
            const response = await API.put<IChatRead>(`/api/chat/unread/${userId}`);
            dispatch(setUnreadMessage(response.data));
        }catch (e) {
            if(e instanceof Error){
                console.log(e.message)
            }
        }
    }

    const getMoreConversationList = async (params: IChatParams) => {
            try {
                const response = await API.get<IChatList>('/api/chat', {
                    params
                });
                dispatch(setMoreChatList(response.data));
            }catch (e) {
                if(e instanceof Error){
                    console.log(e.message);
                }
            }
    };

    const updateReadCount = useCallback(async (actionMenuUser: IChat) => {
        if(!currentUser && !actionMenuUser) return;
        try {
            const lastMessageId = actionMenuUser?.id || currentUser?.id;
            const lastMessageUserId = actionMenuUser?.userId || currentUser?.userId;
            if(lastMessageId && lastMessageUserId){
                dispatch(setReadMessage(actionMenuUser || currentUser));
                await API.put(`/api/chat/read-until/${lastMessageId}/${lastMessageUserId}`)
            }
        }catch (e) {
            if(e instanceof Error){
                console.log(e.message);
            }
        }

    }, [currentUser]);

    const onSetCurrentUser = useCallback((user: IChat) => {
       dispatch(setCurrentUser(user))
    },[])

    useEffect(() => {
        if(lastUpdate){
            getConversationList({page: 1, pageSize: FETCH_CHAT_PAGE_SIZE},false);
        }
    }, [lastUpdate])

    return {
        onSetCurrentUser,
        currentUser,
        totalCount,
        getConversationList,
        updateReadCount,
        chatList,
        getMoreConversationList,
        putUnreadUserMessages,
        isLoading,
        user,
    }
};
export default useConversationApi;