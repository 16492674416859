import React, { useEffect } from "react"
import { useSelector } from "react-redux"
import { Row } from "antd"
import DashboardPageComplianceOverallChartsPie from "../dashboard/DashboardPageComplianceOverallChartsPie"
// import CoachDashboardPageCards from "./CoachDashboardPageCards"
import LoadingSpin from "../../components/loading-spin/LoadingSpin"
import DashboardPageUsersTableWrapper from "../dashboard/DashboardPageUsersTableWrapper"
import withDashboardCol from "HOCs/withDashboardCol";
import DashboardPageCheckInsPie from "../dashboard/DashboardPageCheckInsPie";
import DashboardPagePerformanceCoach from "../dashboard/DashboardPagePerformanceCoach";
import DashboardPageCardsWithTwoCols from "../dashboard/DashboardPageCardsWithTwoCols";
import DashboardPageStatusBar from "../dashboard/DashboardPageStatusBar";
import DashboardPageClientsChangeStatus from "../dashboard/DashboardPageClientsChangeStatus";
import DashboardPageCoachClientsInfoTable from "../dashboard/DashboardPageCoachClientsInfoTable";
import DashboardPagePerformanceCoachAverage from "../dashboard/DashboardPagePerformanceCoachAverage";
import { IDashboardProps } from "pages/dashboard/DashboardPageBody"
import { useAppSelector } from "redux/store"
import DashboardTasks from "pages/dashboard/DashboardTasks"



function CoachDashboardPageBody({isLoading, setLoading, ...props}: IDashboardProps) {
  const {
    setCategoriesData,
    getUserTasksData,
    getTotal,
    getManagersData,
    getAverageCompanyStats,
    getAverageCompanyFeedbackGiven,
    getAverageCompanyMiaTotal,
    getAverageCompanyClients,
    getAverageCompanyFeedbackTime,
    getAverageCompanyFeedbackOutStanding,
    getAverageCompanyCheckinCompleted,
    getAverageCompanyComplianceAverage,
    getAverageCompanyCheckinOverdue,
    getAssignedCoaches,
    getFeedbackGiven,
    getActiveClients,
    getStatsCheckins,
    getCoachClientsData,
    getAverageFeedbackTime,
    getCoachesOverview,
    getTotalClients,
    getComplianceDaily,
    getCompletedDaily,
    getLastContactedMia,
    getMiaAverageTotal,
    getFeedbackOutstanding,
    getCheckInOverdue,
    getChartCompletedData,
    getChartClientsData,
    getClientsProgress,
    getClientsStatusWeeks
  } = props

  const dateRangeName = useAppSelector((state) => state.dashboard.dateRangeName)
  const selectedCoachId = useAppSelector((state) => state.dashboard.selectedCoachId)

  const fetchData = [
    getManagersData,
    getAverageCompanyStats,
    getAverageCompanyFeedbackGiven,
    getAverageCompanyMiaTotal,
    getAverageCompanyClients,
    getAverageCompanyFeedbackTime,
    getAverageCompanyFeedbackOutStanding,
    getAverageCompanyCheckinCompleted,
    getAverageCompanyComplianceAverage,
    getAverageCompanyCheckinOverdue,
    getAssignedCoaches,
    getTotal,
    getChartCompletedData,
    getChartClientsData,
    getCheckInOverdue,
    getFeedbackOutstanding,
    getFeedbackGiven,
    getActiveClients,
    getStatsCheckins,
    getCoachClientsData,
    getAverageFeedbackTime,
    getCoachesOverview,
    getTotalClients,
    getComplianceDaily,
    getCompletedDaily,
    getLastContactedMia,
    getMiaAverageTotal,
    getClientsProgress,
    getClientsStatusWeeks,
    getUserTasksData,
    setCategoriesData,
  ]

  useEffect(() => {
    let cleanupFunction = false
    setLoading(true)
    Promise.all(fetchData.map((fun) => fun?.())).then(() => {
      if (!cleanupFunction) {
        setLoading(false)
      }
    })
    return () => {(cleanupFunction = true)}
  }, [dateRangeName, selectedCoachId])


  const DashboardPageStatusWeeksWithCol = withDashboardCol(DashboardPageStatusBar)
  const DashboardPageClientsChangeBarWithCol = withDashboardCol(DashboardPageClientsChangeStatus);
  const DashboardCheckInsWithCol = withDashboardCol(DashboardPageCheckInsPie)
  const DashboardPagePerformanceCoachWithCol = withDashboardCol(DashboardPagePerformanceCoach)
  const DashboardPageComplianceOverallChartsPieWithCol = withDashboardCol(DashboardPageComplianceOverallChartsPie)
  const DashboardPageAveragePerformanceCoach = withDashboardCol(DashboardPagePerformanceCoachAverage)
  const DashboardPageInfoTable = withDashboardCol(DashboardPageCoachClientsInfoTable)
  const DashboardTasksWithCol = withDashboardCol(DashboardTasks);

  

  return !isLoading ? (
    <Row gutter={[20, 20]} className={"dashboard_body_wrapper"}>
      <DashboardPageStatusWeeksWithCol />
      <DashboardPageClientsChangeBarWithCol />
      <DashboardCheckInsWithCol />
      <DashboardPageInfoTable />
      <DashboardPagePerformanceCoachWithCol/>
      <DashboardPageAveragePerformanceCoach />
      <DashboardPageCardsWithTwoCols />
      <DashboardPageComplianceOverallChartsPieWithCol />
      <DashboardTasksWithCol />
      <DashboardPageUsersTableWrapper {...props} f_coachId={[selectedCoachId]} />
    </Row>
  ) : (
    <LoadingSpin />
  )
}

export default CoachDashboardPageBody
