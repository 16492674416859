import React, { FC } from "react";
import {Button} from "antd";
import { NativeButtonProps } from "antd/lib/button/button";
interface Props extends NativeButtonProps {
    text: React.ReactNode
}

const ButtonComponent: FC<Props> = (props) => {
    return (
        <Button {...props}>
            {props.text}
        </Button>
    )
}

export default ButtonComponent