import { ModalForm } from '@ant-design/pro-form';
import React, { FC, useEffect } from 'react';
import { useAppSelector } from 'redux/store';
import CategoryForm, { CategoryFormProps } from './CategoryForm';

const EditCategoryForm: FC<CategoryFormProps> = ({ onCancel, onFinish, form }) => {
    const isVisible = useAppSelector((state) => state.modals.editCategoryModalVisible);
    const currentCategory = useAppSelector((state) => state.tasks.currentCategory);
    
    useEffect(() => {
            form.setFieldsValue(currentCategory)
    }, [isVisible]);

    return (
        <ModalForm
            title="Edit Category"
            form={form}
            visible={isVisible}
            onFinish={onFinish}
            modalProps={{
                onCancel,
                centered: true
            }}
        >
            <CategoryForm includeChangeArchiveStatus/>
        </ModalForm>
    )

};

export default EditCategoryForm;