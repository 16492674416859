import { RadioChangeEvent } from "antd";
import { AxiosRequestConfig } from "axios";
import { ITypeValue, ITypeValueQuantity } from "Interfaces/Dashboard/CheckIn";
import { ICoach, IUserPage } from "Interfaces/User";
import { ICoachForm } from "pages/user/coach";
import {useEffect} from "react";
import { useAppSelector, useAppDispatch } from "redux/store";
import { manager } from "vars";
import useApi from "../hooks/useApi";
import {
    coachesReducerReset,
    setCoachActiveClients,
    setCoachAverageCompliance,
    setCoachAverageFeedbackTime,
    setCoachCheckIns,
    setCoachDateRangeName,
    setCoaches,
    setCoachFeedbackSent,
    setCoachTableSelected,
    setFilteredCoaches,
    setSelectCoach
} from "../redux/reducers/coachesReducer";
import {getDateRange, Logger} from "../utils/helpers";

const logger = Logger("CoachesApiWithRedux")

interface ICardDataParams {
    f_dateBefore: string,
    f_dateAfter: string,
    f_coachId: string[] | undefined
}

export interface IUpdateCoach {
    specificArea?: string,
    photo?: string | null,
    bio?: string,
}

function useCoachesApiWithRedux() {
    const {API, cancel} = useApi()
    const dispatch = useAppDispatch();
    const authUser = useAppSelector((state) => state.auth.user!);
    const selectedCoach = useAppSelector(state => state.coaches.selectedCoach)
    const coachDateRangeName = useAppSelector(state => state.coaches.coachDateRangeName)

    const getCoachesDataByManager = async () => {
        try {
            const response = await API.get<{users: ICoach[]}>("/api/user", {params: {
                f_roles: 'coach',
                f_managerId: authUser.id
            }});
            return response.data.users;
        } catch (error) {
            if(error instanceof Error)
            logger.error(error.message);
        }
    };

    const getCoachesData = async () => {
        try {
            const response = await API.get<ICoach[]>("/api/user/coach/list");
            return response.data
        } catch (e) {
            if(e instanceof Error)
            logger.error(e.message)
        }
    }

    const getCoachData = async (id: string) => {
        try {
            const response = await API.get<IUserPage>(`/api/user/${id}`);
            return response.data
        } catch (e) {
            if(e instanceof Error)
            logger.error(e.message)
        }
    }

    const getFilteredCoaches = (coaches: ICoach[]) => {
        dispatch(setFilteredCoaches(coaches));
    }

    const getCardData = async <T>({path}: {path: string}) => {
        const {f_dateBefore, f_dateAfter} = getDateRange(coachDateRangeName)
        const params = {f_dateBefore, f_dateAfter} as ICardDataParams
        if (!!selectedCoach) {
            params.f_coachId = [selectedCoach.id]
        }

        try {
            const response = await API.get<T>(`/api/dashboard/${path}`, {params});
            return response.data
        } catch (e) {
            if(e instanceof Error)
            logger.error(e.message)
        }
    }

    const updateCoachData = async (id: string, data: IUpdateCoach, config: AxiosRequestConfig | undefined) => {
        try {
            const response = await API.put<IUserPage>(`/api/user/${id}`, {...selectedCoach, ...data}, config);
            return response.data
        } catch (e) {
            if(e instanceof Error)
            logger.error(e.message)
        }
    }

    const getActiveClients = async () => {
        const data = await getCardData<ITypeValue>({path: 'active-clients'})
        if (!!data) {
            dispatch(setCoachActiveClients({coachActiveClients: {...data}}))
        }
    }

    const getAverageFeedbackTime = async () => {
        const data = await getCardData<ITypeValue>({path: 'checkin/average-feedback-time'})
        if (!!data) {
            dispatch(setCoachAverageFeedbackTime({coachAverageFeedbackTime: data.value}))
        }
    }

    const getCoachCheckIns = async () => {
        const data = await getCardData<ITypeValue>({path: 'checkin/total'})
        if (!!data) {
            dispatch(setCoachCheckIns({coachCheckIns: data.value}))
        }
    }

    const getCoachFeedbackGiven = async () => {
        const data = await getCardData<ITypeValue>({path: 'checkin/feedback-given'})
        if (!!data) {
            dispatch(setCoachFeedbackSent({coachFeedbackSent: data.value}))
        }
    }

    const getCoachAverageCompliance = async () => {
        const data = await getCardData<ITypeValueQuantity[]>({path: 'checkin/compliance-overall'})
        if (Array.isArray(data)) {
            const complianceObj = data.find(item => item.type === "Ok")
            if (!!complianceObj) {
                dispatch(setCoachAverageCompliance({averageCompliance: complianceObj.value}))
            }
        }
    }

    const getCoach = async (id: string) => {
        const data = await getCoachData(id)
        if (!!data) {
            dispatch(setSelectCoach({selectedCoach: data}))
        }
    }

    const updateCoach = async (id: string, object: IUpdateCoach, config: AxiosRequestConfig | undefined) => {
        const data = await updateCoachData(id, object, config)
        if (!!data) {
            dispatch(setSelectCoach({selectedCoach: data}))
        }
        return data
    }

    const getCoaches = async () => {
        const data = authUser.roles === manager ? await getCoachesDataByManager() : await getCoachesData()
        if (!!data) {
            dispatch(setCoaches({coachesData: data}))
            return data
        }
    }

    const onCreateCoach = async <T extends Partial<ICoach>>(data: T) => {
        const response = await API.post<ICoach>('/api/user', data);
        if(response.data){
            return response.data;
        }
    };

    const handleChangeCoachDateRange = (e: RadioChangeEvent) => dispatch(setCoachDateRangeName({coachDateRangeName: e.target.value}))
    const handleChangeTable = (e: RadioChangeEvent) => dispatch(setCoachTableSelected({selectedTable: e.target.value}))

    useEffect(() => () => {
        dispatch(coachesReducerReset())
    }, [])

    return {
        coachesApiCancel:cancel,
        getCoach,
        getCoaches,
        getFilteredCoaches,
        updateCoach,
        onCreateCoach,
        getActiveClients,
        getAverageFeedbackTime,
        getCoachCheckIns,
        getCoachFeedbackGiven,
        getCoachAverageCompliance,
        handleChangeCoachDateRange,
        handleChangeTable,
        getCoachesDataByManager
    }
}

export default useCoachesApiWithRedux;