import React, { FC } from 'react';

interface Props {
    ratio: number
}

const RatioGauge: FC<Props> = ({ratio}) => {
const gaugeWidth = 250;
const calculateRatioWidth = () => {
    if(ratio <= 22){
        return -10
    }

    if(ratio >= 45){
        return gaugeWidth - 10;
    }
    return  ((gaugeWidth / 25) * (ratio - 22)) + 15;
    }

    return (
        <div
        style={{position: 'relative', paddingBottom: 35, marginLeft: 20, width: gaugeWidth + 15}}
        >
            <div>
                {`Ratio:  ${ratio}`}
            </div>
            <div className="arrow-down"
            style={{
                marginLeft: calculateRatioWidth()
            }}
            >
            </div>

            <div className="gauge"
            style={{
                width: gaugeWidth
            }}
            >
                <div className="wloss">
                    {/*<span className="point">22</span>*/}
                    {/*<span className="section-name">Weight Loss</span>*/}
                    {/*<span className="point end-point">25</span>*/}
                </div>
                <div className="gap"></div>
                <div className="maintenance">
                    {/*<span className="point">30</span>*/}
                    {/*<span className="section-name">Maintenance</span>*/}
                    {/*<span className="point end-point">35</span>*/}
                </div>
                <div className="gap"></div>
                <div className="mgain">
                    {/*<span className="point">40</span>*/}
                    {/*<span className="section-name">Muscle Gain</span>*/}
                </div>
            </div>
        </div>
    )

};
export default RatioGauge;