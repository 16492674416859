import React, { FC } from "react";
import {Checkbox} from "antd";

interface Props {
    checked: boolean,
    onChange: (checked: boolean, value: string) => void,
    label: React.ReactNode,
    value: string,
}

const ActivityFilterCheckbox: FC<Props> = ({checked, onChange, label, value}) => {
    return (
        <Checkbox
            className={`activity_client_filter_checkbox ${checked ? 'activity_active_element_blue_color' : ''}`}
            checked={checked}
            onChange={() => {
                onChange(checked, value)
            }
            }
        >{label}</Checkbox>
    )
};

export default ActivityFilterCheckbox