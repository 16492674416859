import React from 'react';
import {Space, PageHeader, Table} from "antd";
import DateRangeButtonGroup from "../../dashboard/DateRangeButtonGroup";
import useNutritionSummaryService from "./NutritionSummary-service";


const NutritionSummary = () => {


    const {Columns, onChangeDateRange, dateRange,nutritionSummaryData, isLoading} = useNutritionSummaryService();

    return (
        <Space direction="vertical">
            <PageHeader
                style={{padding: 0}}
                title="Nutrition Summary"
                extra={<DateRangeButtonGroup 
                value={dateRange} 
                isLoading={isLoading} 
                onChange={onChangeDateRange} 
            />}
            />
            <Table
                loading={isLoading}
                pagination={false}
                dataSource={nutritionSummaryData}
                columns={Columns}
            />
        </Space>
    )


};


export default NutritionSummary;