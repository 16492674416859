import React, { FC } from 'react';
import { useAppSelector } from 'redux/store';
import {
    ModalForm,
    ProFormText,
    ProFormSwitch, 
    ProFormTextArea
} from '@ant-design/pro-form';
import { isOnlySpaces, validateEmail, validateRequired } from 'utils/helpers';
import { Form } from 'antd';
import { ICreateManager } from 'services/managers-service';

interface Props {
    onFinish: (values: ICreateManager) => Promise<void>,
    onCancel: (flag: boolean) => {
        payload: boolean;
        type: string;
    }
}

const AddManagerForm: FC<Props> = ({onCancel, onFinish}) => {
    const assignManagerModalVisible = useAppSelector((state) => state.modals.assignManagerModalVisible);
    const [form] = Form.useForm<ICreateManager>();

    const onSubmit = async (values: ICreateManager) => {
        form.resetFields();
        onFinish(values);
    }

    const onClose = () => {
        onCancel(false);
        form.resetFields();
    }

    return (
        <ModalForm
        form={form}
        onFinish={onSubmit}
        modalProps={{
            onCancel: onClose
        }}
        visible={assignManagerModalVisible}
        title="Add Manager"
        >
                    <ProFormText name="firstName" label="First Name" placeholder="First Name" rules={[
                            {
                                required: true,
                                message: 'This field is required',
                            },
                            ({getFieldValue}) => ({
                                validator(_, value) {
                                    if (!value || !isOnlySpaces(getFieldValue('firstName'))) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(new Error('This field is required'));
                                },
                            }),]}/>
                        <ProFormText name="lastName" label="Last Name" placeholder="Last Name" rules={[
                            {
                                required: true,
                                message: 'This field is required',
                            },
                            ({getFieldValue}) => ({
                                validator(_, value) {
                                    if (!value || !isOnlySpaces(getFieldValue('lastName'))) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(new Error('This field is required'));
                                },
                            }),]}/>
                        <ProFormText name="email" label="Email" placeholder="Email"
                                     rules={[() => ({
                                         async validator(_, value) {
                                             await validateRequired(value)
                                             await validateEmail(value)
                                             return Promise.resolve(value);
                                         },
                                     }), {required: true, message: ''}]}/>
                               <ProFormText.Password name="password" label="Password" placeholder="Password" rules={[
                            {
                                required: true,
                                message: 'This field is required',
                            },
                            ({getFieldValue}) => ({
                                validator(_, value) {
                                    if (!value || getFieldValue('password') === value) {
                                        const exp = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/
                                        if (exp.test(value)) {
                                            return Promise.resolve();
                                        }
                                    }

                                    return Promise.reject(new Error(' Password must contain at least 8 symbols, 1 digit, 1 lowercase letter and 1 uppercase letter!'));
                                },
                            }),
                        ]} hasFeedback/>
                        <ProFormText.Password name="confirmPassword" label="Confirm Password"
                                              placeholder="Confirm Password"
                                              dependencies={['password']} hasFeedback rules={[
                            {
                                required: true,
                                message: 'This field is required',
                            },
                            ({getFieldValue}) => ({
                                validator(_, value) {
                                    if (!value || getFieldValue('password') === value) {
                                        return Promise.resolve();
                                    }

                                    return Promise.reject({message: 'Passwords do not match'});
                                },
                            }),
                        ]}/>
                        <ProFormText name="specificArea" label="Specific Area/Goals" placeholder="Specific Area/Goals"/>
                        <ProFormTextArea name="bio" label="BIO" placeholder="BIO"/>
                        <ProFormSwitch name="pvtEnabled" label="PVT Enable"/>
                        <ProFormSwitch name="pvtSuperUser" label="PVT Enable (Super)" />
                        <ProFormSwitch name="disabled" label="Disabled"/>             
        </ModalForm>
    )
};


export default AddManagerForm;