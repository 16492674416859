import React, {useEffect, useState} from 'react';
import {Table, TableProps} from "antd";
import {getQueryParams, tablePagination} from "utils/helpers";
import useScrollTo from "hooks/useScrollTo";
import { TableRowSelection } from 'antd/lib/table/interface';
import { IClient } from 'Interfaces/User';
import { useAppSelector } from 'redux/store';
import { ColumnsType, TablePaginationConfig } from 'antd/es/table';
import { IUsersTableSettings } from 'redux/reducers/usersReducer';

interface Props {
    getUsersColumns: ColumnsType<IClient>,
    onChange: TableProps<IClient>['onChange'],
    onSelectUsersChange: (selectedUsers: React.Key[]) => void;
    getUsers: (query: IUsersTableSettings) => Promise<void>
};


const UserTable = (props: Props) => {
    const {onChange, getUsersColumns, getUsers, onSelectUsersChange}= props

    const [loading, setLoading] = useState<boolean>(true)
    const usersData = useAppSelector(state => state.users.usersData)
    const totalCount = useAppSelector(state => state.users.totalCount)
    const usersTableSettings = useAppSelector(state => state.users.usersTableSettings)
    const lastUpdated = useAppSelector(state => state.users.lastUpdated)
    const selectedUsers = useAppSelector(state => state.users.selectedUsers)
    const {page, pageSize} = usersTableSettings

    useScrollTo({dependency:[pageSize, page]})

    const onSelectChange = (selectedUsers: React.Key[]) => {
        onSelectUsersChange(selectedUsers)
    }

    const rowSelection: TableRowSelection<IClient> = {
        selectedRowKeys: selectedUsers,
        onChange: onSelectChange,
    }

    useEffect(() => {
        let cleanupFunction = false;
        const fetchData = async () => {
            setLoading(true)
            await getUsers(getQueryParams(usersTableSettings))
            onSelectChange([])

            if (!cleanupFunction) {
                setLoading(false)
            }
        }
        fetchData();

        return () => {cleanupFunction = true}

    }, [lastUpdated])
  return(
      <Table
          pagination={tablePagination({pageSize, page, totalCount, showSizeChanger: true,}) as TablePaginationConfig}
          columns={getUsersColumns}
          dataSource={usersData}
          loading={loading}
          onChange={onChange}
          rowSelection={rowSelection}
          rowKey={(e) => e.id}
          scroll={{x: 'auto'}}/>
  )
}

export default UserTable