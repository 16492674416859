import React, { FC } from 'react';
import { Typography } from 'antd';
import { Modal } from 'components/Antd/Modal';
import { ICategory } from 'Interfaces/TasksCategories';
import { useAppSelector } from 'redux/store';

interface RemoveCategoryModalProps {
    onCancel: () => void,
    onFinish: (task: ICategory) => Promise<void>
}
 
const RemoveCategoryModal: FC<RemoveCategoryModalProps> = ({ onCancel, onFinish }) => {
    const isVisible = useAppSelector((state) => state.modals.removeCategoryModalVisible);
    const currentCategory = useAppSelector((state) => state.tasks.currentCategory);

    const handleOk = async () => {
        await onFinish(currentCategory!);
    };

    return (
        <Modal 
            centered    
            visible={isVisible}
            onCancel={onCancel}
            onOk={handleOk}
            title="Remove Category"
        >
            <Typography.Paragraph>Are you sure you want to delete category?</Typography.Paragraph>
        </Modal>
    )
};

export default RemoveCategoryModal;