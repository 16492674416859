import React, {useEffect, useState} from 'react';
import {Comment, Avatar as AntdAvatar, Space, Popover, List, Card, PageHeader, Switch} from 'antd';
import moment from 'moment';
import FeedbackModal from 'pages/checkin/feedbackModal';
import useScrollTo from "hooks/useScrollTo";
import emojiRegex from 'emoji-regex';
import nodeEmoji from "node-emoji";
import FeedbackModalRemove from "./FeedbackModalRemove";
import {useFeedbackActions} from "hooks/useFeedbackActions";
import {DATE_FORMAT, DefaultPageSizes, user} from "vars";
import ActivationPartsForm from "./ActivationPartsForm";
import ActivationPartsList from "./ActivationPartsList";
import { useAppSelector } from 'redux/store';
import { IFeedback, IFeedback_UserStateClassification } from 'Interfaces/Feedback';
import { ModalType } from 'services/modals-service';
import { FeedbackQueryParams, IActiovationParts, IEditFeedback, IPatchFeedback } from 'services/feedback-service';
import { Canceler } from 'axios';
import { ICheckIn } from 'Interfaces/CheckIn';
import './styles/notes.css';

export interface PropsFeedbackClient {
    isLoadingFeedbacks: boolean,
    userFeedback: (id: string, params: FeedbackQueryParams) => Promise<void>,
    onDeleteFeedback: (id: string) => Promise<void>,
    setEditFeedback: (note: IFeedback) => void,
    setCurrentFeedbackDelete: (note: IFeedback) => void,
    setCurrentFeedbackDeleteClear: () => void,
    onSubmitActivationParts: (data: IActiovationParts) => Promise<IFeedback_UserStateClassification | undefined>,
    editFeedback: (feedback: IEditFeedback) => Promise<IFeedback | undefined>
}

export interface IFeedbackProps extends PropsFeedbackClient {
    saveCheckin: (data: ICheckIn) => Promise<ICheckIn | undefined>
    onEditFeedback: (note: IEditFeedback) => Promise<IFeedback | undefined>,
    onPatchFeedback: (feedback: IPatchFeedback) => Promise<IFeedback | undefined>,
    setVisible: ModalType,
    getMessageTemplates: () => Promise<{
        label: string;
        value: string;
    }[]>,
    setTemplates: (templates: string[]) => void,
    messageTemplatesApiCancel: Canceler,
    addFeedback: (values: any) => Promise<IFeedback | undefined>
}

const emojiReg = emojiRegex();
const rexBrackets = /\[.*?\]/gu;

const getFeedbackDate = (date: string) => {
    if(moment().diff(date, 'weeks') <= 0){
        return `${moment(date).format(DATE_FORMAT)} - ${moment(date).fromNow()}`;
    }
    if(moment().diff(date, 'weeks') === 1){
        return `${moment(date).format(DATE_FORMAT)} - ${moment().diff(date, 'weeks')} week ago`
    }
    return `${moment(date).format(DATE_FORMAT)} - ${moment().diff(date, 'weeks')} weeks ago`;
};

const getSentimentInfo = (score: number) => {

    let avatarBg = "#B7B8B5"
    let avatarText = "#2C2C2C"
    if (score) {
        //Really Bad (Red)
        if (score < -0.65) {
            avatarBg = "#CC332A"
            avatarText = "#EFEFEF"
            return {text: avatarText, bg: avatarBg, guidance: "Strong Negative"}
        }

        //Mild Negative
        if (score < -0.05) {
            avatarBg = "#E68C4C"
            avatarText = "#84502C"
            return {text: avatarText, bg: avatarBg, guidance: "Mild Negative"}
        }

        //Neutral
        if (score < 0.05) {
            avatarBg = "#78D8E6"
            avatarText = "#42777F"
            return {text: avatarText, bg: avatarBg, guidance: "Neutral"}
        }

        //Mild Positive
        if (score < 0.5) {
            avatarBg = "#90E6A3"
            avatarText = "#598E64"
            return {text: avatarText, bg: avatarBg, guidance: "Mild Positive"}
        }

        //Positive
        if (score < 1) {
            avatarBg = "#456C41"
            avatarText = "#A3FE98"
            return {text: avatarText, bg: avatarBg, guidance: "Strong Positive"}
        }
    }

    return {text: avatarText, bg: avatarBg, guidance: "No Sentiment"}
}

const Avatar = (comment: IFeedback) => {
    let sentimentScore = comment?.sentiment?.compound
    let sentimentInfo = getSentimentInfo(sentimentScore)

    if (comment.Author) {
        return (
            <Popover title="Sentiment" content={`${sentimentScore} - ${sentimentInfo.guidance} `}>
                <AntdAvatar style={{  color: sentimentInfo.text, backgroundColor: sentimentInfo.bg }}>
                    {comment.Author.firstName[0]}{comment.Author.lastName[0]}
                </AntdAvatar>
            </Popover>
        )
    } else {
        return (
            <Popover title="Sentiment" content={`${sentimentScore} - ${sentimentInfo.guidance} `}>
                <AntdAvatar style={{color: '#ffffff', backgroundColor: '#D4BC58'}}>SMP</AntdAvatar>
            </Popover>
        )
    }
};


export default function Feedback(props: IFeedbackProps) {
    const {
        isLoadingFeedbacks, 
        userFeedback,
        onDeleteFeedback,
        setEditFeedback,
        onEditFeedback,
        onPatchFeedback,
        setCurrentFeedbackDelete,
        setCurrentFeedbackDeleteClear,
        onSubmitActivationParts, 
        getMessageTemplates 
    } = props
    const [page, setPage] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(DefaultPageSizes.FEEDBACK);
    const [isAutoGenerated, setIsAutoGenerated] = useState<boolean>(true);
    const [isFeedback, setIsFeedback] = useState<boolean>(true);
    const [isMessage, setIsMessage] = useState<boolean>(true);
    const authUser = useAppSelector(state => state.auth.user);
    const userId = useAppSelector(state => state.user.userData!.id)
    const feedbackData = useAppSelector(state => state.feedback.paginatedFeedbacks)
    const messageTemplatesData = useAppSelector(state => state.messageTemplates.messageTemplatesData);
    const lastUpdate = useAppSelector(state => state.feedback.lastUpdate)
    const lastFeedbackFromClient = useAppSelector(state => state.feedback.lastFeedbackFromClient);
    const {
        feedbackActionsTemplate,
        feedbackActionsCheckIn,
        feedbackActionsActivation,
        feedbackActionsButtons
    } = useFeedbackActions({
        authUser,
        messageTemplatesData,
        setEditFeedback,
        setCurrentFeedbackDelete,
        onPatchFeedback
    });

    const handleSetIsFeedbackIncluded = (newIsFeedback: boolean) => {
        if (!newIsFeedback && !isMessage) setIsMessage(true);
        setIsFeedback(newIsFeedback);
    };
    const handleSetIsMessagesIncluded = (newIsMessage: boolean) => {
        if (!newIsMessage && !isFeedback) setIsFeedback(true);
        setIsMessage(newIsMessage);
    };
    const onPaginationChange = (page: number, pageSize?: number) => {
        setPage(page);
        if (pageSize) setPageSize(pageSize);
    };
    const handleDeleteFeedback = async (id: string) => {
        await onDeleteFeedback(id);

        // Set previous page if the only item in current feedbacks is the one we delete
        if (feedbackData.items[0].id === id && feedbackData.items.length === 1 && page > 1)
            setPage(prevValue => prevValue - 1);

        userFeedback(userId, {
            isAutoGenerated: !isAutoGenerated ? false : undefined,
            isFeedback: isFeedback || undefined,
            isMessage: isMessage || undefined,
            page,
            pageSize
        });
    }

    useScrollTo({dependency:[page]});

    useEffect(() => {
        const fetchData = async () => {
            await userFeedback(userId, {
                isAutoGenerated: !isAutoGenerated ? false : undefined,
                isFeedback: isFeedback || undefined,
                isMessage: isMessage || undefined,
                page,
                pageSize
            });
        }
        fetchData();
    }, [lastUpdate, isAutoGenerated, isFeedback, isMessage, page, pageSize])
    return (
        <Space direction="vertical">
            <Card title="Add Feedback" className="noBg" size="small">
                <FeedbackModal 
                    {...props}
                    modal={false}
                    setCurrentFeedbackDeleteClear={setCurrentFeedbackDeleteClear}
                    editFeedback={onEditFeedback}
                    getMessageTemplates={getMessageTemplates}
                />
            </Card>
            <PageHeader
                extra={<>
                    <Switch checked={isAutoGenerated} onChange={setIsAutoGenerated} checkedChildren="Auto" unCheckedChildren="Auto" />
                    <Switch checked={isFeedback} onChange={handleSetIsFeedbackIncluded} checkedChildren="Feedback" unCheckedChildren="Feedback" />
                    <Switch checked={isMessage} onChange={handleSetIsMessagesIncluded} checkedChildren="Message" unCheckedChildren="Message" />
                </>}
            />
            <List
                split={true}
                loading={isLoadingFeedbacks}
                pagination={{ position: 'bottom', onChange: onPaginationChange, current: page, pageSize, total: feedbackData.totalCount }}
                dataSource={feedbackData.items}
                itemLayout="vertical"
                renderItem={(feedback) => {
                    const parseUnicodeNote = nodeEmoji.emojify(feedback.notes)
                    const note = !!parseUnicodeNote && parseUnicodeNote.replace(rexBrackets, (match: string) => {
                        try {
                            const unicode = match.slice(1, -1);
                            const smile = String.fromCodePoint(parseInt(unicode, 16));
                            if (smile.match(emojiReg)) {
                                return smile;
                            }
                            return match;
                        } catch (e) {
                            return match;
                        }
                    });
                    const {Author, date} = feedback
                    const author = !!Author ? Author.fullName : 'SMP'
                    
                    if(Author?.roles === user) {
                        return (
                            <div className="notes_feedback_classification">
                                <Comment className="pre_line_text notes_feedback"
                                    actions={[feedbackActionsTemplate(feedback), feedbackActionsCheckIn(feedback), feedbackActionsActivation(feedback)]}
                                    author={author}
                                    content={note}
                                    datetime={getFeedbackDate(date)}
                                    style={{flex: 1}}
                                    avatar={Avatar(feedback)}
                                />
                                <div className="feedback_actions_container">
                                    <div style={{marginLeft: 5}}>
                                        {feedbackActionsButtons(feedback)}
                                    </div>
                                    {
                                        authUser?.pvtSuperUser && 
                                        <ActivationPartsForm
                                            onSubmitActivationParts={onSubmitActivationParts}
                                            {...feedback}
                                        />
                                    }
                                    {
                                        !authUser?.pvtSuperUser && 
                                        lastFeedbackFromClient?.id === feedback.id && 
                                        authUser?.pvtEnabled ?
                                            <ActivationPartsForm
                                                onSubmitActivationParts={onSubmitActivationParts} {...feedback} />
                                            : <ActivationPartsList {...feedback}  />
                                    }
                                </div>
                            </div>
                        )
                    }

                    return (
                        <Comment className="pre_line_text notes_feedback"
                            actions={[
                                feedbackActionsTemplate(feedback),
                                feedbackActionsCheckIn(feedback),
                                feedbackActionsActivation(feedback),
                                feedbackActionsButtons(feedback)
                            ]}
                            author={author}
                            content={<p style={{ marginTop: '10px' }}>{note}</p>}
                            datetime={getFeedbackDate(date)}
                            avatar={Avatar(feedback)}
                        />
                    )}
                }
            />
            <FeedbackModalRemove
                message="Are you sure you want to delete the feedback?"
                handleOk={handleDeleteFeedback}
                onCancel={setCurrentFeedbackDeleteClear}
            />
        </Space>
    )
}