import React, { FC, useEffect } from 'react';
import { ModalForm, ProFormSelect, ProFormSwitch, ProFormText, ProFormTextArea } from '@ant-design/pro-form';
import { isOnlySpaces, validateEmail, validateRequired } from 'utils/helpers';
import { Form } from 'antd';
import { IManager } from 'Interfaces/User';
import { useAppSelector } from 'redux/store';
import { IEditManager } from 'services/managers-service';

interface Props {
    currentManager: IManager,
    onFinish: ({ values, editForm, manager }: IEditManager) => Promise<boolean | void>,
    onCancel: () => void
};

const EditManagerForm: FC<Props> = ({currentManager, onFinish, onCancel}) => {
    const editManagerModalVisible = useAppSelector((state) => state.modals.editManagerModalVisible);
    const [editForm] = Form.useForm<IManager>();

    const onClose = () => {
        onCancel();
        editForm.resetFields()
    }

    const onEdit = async (values: IManager): Promise<void> => {
          await onFinish({values: {...currentManager, ...values}, manager: currentManager, editForm});
    }

    useEffect(() => {
        editForm.setFieldsValue(currentManager);
    }, [currentManager]);


    return (
        <ModalForm  
        form={editForm}
        visible={editManagerModalVisible} 
        title="Edit manager" 
        onFinish={onEdit}
        modalProps={{
                    onCancel: onClose
        }}
        >
            <ProFormText name="firstName" label="First Name" placeholder="First Name" rules={[
                {
                    required: true,
                    message: 'This field is required',
                },
                ({getFieldValue}) => ({
                    validator(_, value) {
                        if (!value || !isOnlySpaces(getFieldValue('firstName'))) {
                            return Promise.resolve();
                        }
                        return Promise.reject(new Error('This field is required'));
                    },
                }),]}/>
            <ProFormText name="lastName" label="Last Name" placeholder="Last Name" rules={[
                {
                    required: true,
                    message: 'This field is required',
                },
                ({getFieldValue}) => ({
                    validator(_, value) {
                        if (!value || !isOnlySpaces(getFieldValue('lastName'))) {
                            return Promise.resolve();
                        }
                        return Promise.reject(new Error('This field is required'));
                    },
                }),]}/>
            <ProFormText name="email" label="Email" placeholder="Email"
                            rules={[() => ({
                                async validator(_, value) {
                                    await validateRequired(value)
                                    await validateEmail(value)
                                    return Promise.resolve(value);
                                },
                            }), {required: true, message: ''}]}/>
            <ProFormText name="specificArea" label="Specific Area/Goals" placeholder="Specific Area/Goals"/>
            <ProFormTextArea name="bio" label="BIO" placeholder="BIO"/>
            <ProFormSelect
                name="roles"
                label="Role"
                valueEnum={{
                    admin: 'Admin',
                    coach: 'Coach',
                    manager: 'Manager',
                }}
                placeholder="Please select a role"
                rules={[{required: true, message: 'This field is required'}]}
            />
            <ProFormSwitch name="pvtEnabled" label="PVT Enable"/>
            <ProFormSwitch name="pvtSuperUser" label="PVT Enable (Super)" />
            <ProFormSwitch name="disabled" label="Disabled"/>
        </ModalForm>
    )
};

export default EditManagerForm;