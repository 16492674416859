import {useEffect} from "react";
import {useDispatch} from "react-redux";
import {getTwoEntitiesWithBiggestTimeDiff, Logger} from "../utils/helpers";
import {
    photosReducerReset,
    setGroupPreview,
    setGroupPreviewCheckInCounts,
    setIsLoadingGroupPreviewCheckInCounts,
    setVisibleGroupPreview
} from "../redux/reducers/photosReducer";
import useApi from "../hooks/useApi";
import { ICheckIn } from "Interfaces/CheckIn";
import useCheckInsApiWithRedux from "./check-ins-service";
import { useAppSelector } from "redux/store";

const logger = Logger("PhotosApiWithRedux")


export interface IPhoto{
    id: string,
    type: string,
    checkInId: string,
    date: ICheckIn['date'],
    weight: ICheckIn['weight'],
    waist: ICheckIn['waist'],
    hips: ICheckIn['hips'],
    src?: string | null,
}

function usePhotosApiWithRedux() {
    const {API, cancel} = useApi()
    const dispatch = useDispatch()
    const { getCheckInsCountBetweenTwoDates } = useCheckInsApiWithRedux();
    const groupPreview = useAppSelector(state => state.photos.groupPreview);
    const selectedRowCheckIns = useAppSelector(state => state.checkIns.checkInSelectedRowData);

    const getPhoto = async (id: string) => {
        try {
            const response = await API.get<{signedUrl: string}>(`/api/aws/image/${id}`)
            return response.data.signedUrl;
        } catch (e) {
            if(e instanceof Error){
                logger.error(e.message)
                console.error(e.message)
            }
        }
    }

    const handleVisibleGroupPreview = (visible: boolean) => {
        dispatch(setIsLoadingGroupPreviewCheckInCounts(true));
        const groupFields = getTwoEntitiesWithBiggestTimeDiff(groupPreview);
        if (groupFields) getCheckInsCountBetweenTwoDates(selectedRowCheckIns[0].userId, [groupFields[0].date, groupFields[1].date])
            .then(value => dispatch(setGroupPreviewCheckInCounts(value)))
            .catch(() => dispatch(setIsLoadingGroupPreviewCheckInCounts(false)));
        dispatch(setVisibleGroupPreview({visibleGroupPreview: visible}));
    }

    const photoSort = (a: IPhoto, b: IPhoto) => +new Date(b.date) - +new Date(a.date);
    const setPhotos = (photos: IPhoto[]) => {dispatch(setGroupPreview({groupPreview: photos.sort(photoSort)}))}

    useEffect(() => () => {dispatch(photosReducerReset())}, [])

    return {
        photosApiCancel:cancel,
        handleVisibleGroupPreview,
        setPhotos,
        getPhoto,
    }
}

export default usePhotosApiWithRedux;