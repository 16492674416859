import React, {useState, useEffect} from 'react';
import { useAppDispatch, useAppSelector } from 'redux/store';
import {Space, Button, Descriptions, Form} from 'antd';
import antNotificationComponent from "utils/notification-ant-utils"
import {
    ModalForm,
    ProFormText,
    ProFormSelect,
    ProFormSwitch, 
    ProFormDigit
} from '@ant-design/pro-form';
import {
    isBoolean, 
    validateEnableSwitchDays,
    validateLength,
    validatePositive, 
    validateSumOfDays, 
    validateSumOfDays_Switch,
    validateWithoutNull
} from "utils/helpers";
import './styles/macroModal.css'
import {EDIT_MACROS, MACRO_TYPES} from "vars";
import {setRatio, UserData} from "redux/reducers/userReducer";
import RatioGauge_Average from "./Ratio_Gauge/RatioGauge_Average";
import RatioGauge_LowMacro from "./Ratio_Gauge/RatioGauge_Low";
import RatioGauge_Standard from "./Ratio_Gauge/RatioGauge_Standard";
import RatioGauge_High from "./Ratio_Gauge/RatioGauge_High";
import RatioGauge_Calculator from "./Ratio_Gauge/RatioGauge_Calculator";
import { CurrentEvent, FixMeLater, MacroType, UserType } from 'Types';
import { AxiosResponse } from 'axios';
import { IClient, IMacro } from 'Interfaces/User';
import { ModalType } from 'services/modals-service';
import { ProFormDigitProps } from '@ant-design/pro-form/lib/components/Digit';
import { RuleObject } from 'antd/lib/form';
import { ProFormSwitchProps } from '@ant-design/pro-form/lib/components/Switch';
// import Macro_Speedometer from "./Macro_Speedometer";

//NOTES: Based on a demo table, added a pop-up editor via modal and form.
//I used functions here rather than classes/extend React.Component as this causes issues getting a reference to the form in order to populate it.
//Looking to upgrade table to ProTable (has back-end linkages and search and stuff... but I was having troubles getting it to work)
//Data is populated locally, but shouldn't be much of a hassle to change to using a fetch to get the objects.

interface Props {
    getUser: (id: string) => Promise<void>,
    setUserActivity: (activityType: CurrentEvent) => Promise<void>,
    updateUser: (id: string, data: UserType) => Promise<AxiosResponse<UserType> | undefined>,
    setMacroVisible: ModalType,
    setCurrentEventWithCount: (currentEvent: string) => void

};

export default function MacroModal({
                                       setCurrentEventWithCount,
                                       setMacroVisible,
                                       updateUser,
                                       setUserActivity,
                                       getUser
                                   }: Props) {

    const macroModalVisible = useAppSelector(state => state.modals.macroModalVisible)
    const user = useAppSelector(state => state.user.userData!)
    const activeTabKey = useAppSelector(state => state.user.activeTabKey)
    const checkInData = useAppSelector(state => state.checkIns.checkInData)
    const [macroForm] = Form.useForm<UserType>();
    const [submitting, setSubmitting] = useState(false)
    const dispatch = useAppDispatch();
    

    const onReset = () => macroForm.resetFields()

    const macroChangeComplete = async () => {
        console.log("Macro Change Complete")
        onReset()
        setMacroVisible(false)
        await setUserActivity(EDIT_MACROS)
        await getUser(user.id)
    }

    const modalProps = {
        onCancel: () => {
            onReset()
            setCurrentEventWithCount(activeTabKey);
            setMacroVisible(false)
        },
        saveComplete: () => macroChangeComplete(),
        okText: "Save Macros",
        cancelText: "Cancel"
    }


    const onFinish = async (values: UserType) => {

        setSubmitting(true)
        let {LowMacro, StandardMacro, HighMacro} = values
        console.log({LowMacro, StandardMacro, HighMacro})
        const reducer = (previousValue: IMacro, [key, currentValue]: [keyof IMacro, any]) => {
            const obj = !!currentValue || isBoolean(currentValue) ? {[key]: currentValue} : {[key]: 0}
            return {...previousValue, ...obj}
        }

        // LowMacro = Object.entries(LowMacro).reduce(reducer, {})
        LowMacro = Object.entries(LowMacro).reduce((prev, [key, value]) => {
            return {
                ...prev,
                [key]: value
            }
        }, {} as IMacro)
        StandardMacro = Object.entries(StandardMacro).reduce((prev, [key, value]) => {
            return {
                ...prev,
                [key]: value
            }
        }, {} as IMacro)
        HighMacro = Object.entries(HighMacro).reduce((prev, [key, value]) => {
            return {
                ...prev,
                [key]: value
            }
        }, {} as IMacro)


        let userData = await updateUser(user.id, {...user, LowMacro, StandardMacro, HighMacro, updateMacros: true})
        if (!!userData) {
            antNotificationComponent({message: "Macros updated successfully"})
            setSubmitting(false)
            macroChangeComplete()
        }

    }

    // Calculate macros using Miffin St Jeor algo
    // BMR (metric) =(10 x weight in KG ) + (6.25 x HEIGHT in cm) - (5 x age in years ) - 161
    // All figures should be converted to metric at this point

    const getMacroTypeRatio = (currentMacroType: MacroType, data = macroForm.getFieldsValue()) => {
            const {weight} = data;
            const {protein, carb, fat} = data[currentMacroType]!;
            const calories = Math.floor(((+protein * 4) + (+carb * 4) + (+fat * 9)));
            // const calories = Math.floor((((+protein || 0) * 4) + ((+carb || 0) * 4) + ((+fat || 0) * 9) * (+days || 1)));
            const ratio = Math.floor(calories / (+weight || 1));
            const weightLoss = ratio > 0 && ratio <= 20 ? ratio : null;
            const maintenance = ratio > 20 && ratio <= 40 ? ratio : null;
            const muscleGain = ratio > 40 ? ratio : null;
            
            dispatch(setRatio({currentMacroType, ratio}));

            return {
                calories,
                protein,
                carb,
                fat,
                weightLoss,
                maintenance,
                muscleGain,
                ratio,
            }
    };

    const setMacroTypeRatio = (currentMacroType: MacroType) => macroForm.setFieldsValue({
        AverageMacro: getAverageCalories(macroForm.getFieldsValue()),
        calories: getMacroTypeRatio(currentMacroType).calories,
        [currentMacroType]: getMacroTypeRatio(currentMacroType)
    })

    const getMacrosInUse = () => {
      const values = macroForm.getFieldsValue();
        const MacroInUse = MACRO_TYPES.map((macro) => ({...values[macro], macro})).filter(({inUse}) => inUse);
        return MacroInUse.map(({macro}) => [macro, 'days'])
    };


    const getAverageCalories = (data: UserType) => {
     const {weight} = data;
      const MacrosInUse = MACRO_TYPES.map((macro) => data[macro]).filter(({inUse}) => inUse);
      const sumOfDays = MacrosInUse.reduce((acc, curr) => +acc + +curr.days, 0);


      const MacrosTypeCalories = MacrosInUse.map(({protein, fat, carb, days}) =>
          Math.floor(((+protein * 4) + (+carb * 4) + (+fat * 9)) * +days));
        const calories = Math.floor(MacrosTypeCalories.reduce((acc, curr) => +acc + curr, 0) / 7);
        const ratio = Math.floor(calories / (+weight || 1));

        dispatch(setRatio({currentMacroType: 'AverageMacro', ratio}));
        return {
            calories,
            weightLoss: ratio > 0 && ratio <= 20 ? ratio : null,
            maintenance: ratio > 20 && ratio <= 40 ? ratio : null,
            muscleGain: ratio > 40  ? ratio : null,
            ratio
        }
    };

    const copyMacro = (macro: MacroType) => {
        const values = macroForm.getFieldsValue()
        console.log("Copying Macro", values)

        //Setup new object to update form.
        // let user = {}


        const data: any = {
            ...values,
        }

        data[macro].protein = values.Calculator.protein
        data[macro].fat = values.Calculator.fat
        data[macro].carb = values.Calculator.carb
        data[macro].calories = values.Calculator.calories

        macroForm.setFieldsValue(data)
        setMacroTypeRatio(macro)
    }

    const copyToLow = () => {
        copyMacro("LowMacro")
        // getAverageCalories(macroForm.getFieldsValue())
        // macroForm.setFieldsValue({AverageMacro:  getAverageCalories(macroForm.getFieldsValue())})

    }
    const copyToMid = () => {
        copyMacro("StandardMacro")
        // getAverageCalories(macroForm.getFieldsValue())
        // macroForm.setFieldsValue(values)
    }
    const copyToHigh = () => {
        copyMacro("HighMacro")
        // getAverageCalories(macroForm.getFieldsValue())
        // macroForm.setFieldsValue({AverageMacro:  getAverageCalories(macroForm.getFieldsValue())})
    }


    const calculateMacros = () => {
        let values = macroForm.getFieldsValue()

        console.log("Values:", values)
        let user = {}

        const data: UserType = {
            ...values
        }

        Object.assign(user, values)

        let weight = +values.weight
        let height = +values.height
        let age = +values.age
        let sex = values.sex
        let activityLevel = values.activityLevel
        let defecitLevel = values.defecitLevel
        let protein = values.Calculator.protein;
        let carb = values.Calculator.carb;
        let fat = values.Calculator.fat;


        console.log("Data:", weight, height, age, sex, activityLevel, defecitLevel)

        if (weight && height && age && sex) {
            let bmrr = 0
            if (sex == "F") {
                bmrr = (10 * weight) + (6.25 * height) - (5 * age) - 161
            } else {
                bmrr = (88.362) + (13.397 * weight) + (4.799 * height) - (5.677 * age)
            }

            let totalCalories = 0
            totalCalories = bmrr * +activityLevel
            totalCalories = totalCalories * (1 + +defecitLevel)

            //TODO: Adjust to 2.2g/kg
            let proteinCalories = weight * 2.0 * 4
            let fatCalories = weight * 0.8 * 9
            let carbCalories = totalCalories - proteinCalories - fatCalories

            let proteinGrams = 5 * Math.floor(proteinCalories / 4 / 5)
            let fatGrams = 5 * Math.floor(fatCalories / 9 / 5)
            let carbGrams = 5 * Math.floor(carbCalories / 4 / 5)

            const calculateMacro = getMacroTypeRatio('Calculator', {...values ,weight, Calculator: {
                protein: proteinGrams,
                fat: fatGrams,
                carb: carbGrams,
                days: 1,
                id: '',
                userId: '',
                inUse: true,
                calories: totalCalories
                }})

            const calculatorData: any = {
                ...values,
                Calculator: {...calculateMacro}
            }

            console.log("Calculator Data:", calculatorData)
            macroForm.setFieldsValue(calculatorData)
            // console.log("Fields Value:", macroForm.getFieldsValue())
            // macroForm.setFieldsValue(user)
        } else {
            console.log("Missing Data")
        }
    }

    const ProFormDigitWithRules = (props: ProFormDigitProps & {onChange?: () => void}) => {
        return (
            <ProFormDigit key={Array.isArray(props.name) ? props.name.join('') : props.name} placeholder=""
                          min={0}
                          max={99999}
                          rules={[({setFieldsValue}) => ({
                async validator({field}: FixMeLater, value) {
                    await validateLength(value)
                    await validatePositive(value)
                    await validateWithoutNull(field, value, setFieldsValue)
                    return Promise.resolve(value);
                },
            })]}
            {...props}
            />
        )
    }

    const ProFormDigitWithoutRules = (props: ProFormDigitProps & {onChange?: () => void}) => {
        return <ProFormDigit {...props} />
    }

    const ProFormSwitchWithRules = (props: ProFormSwitchProps & {onChange?: () => void}) => {
            return <ProFormSwitch {...props} />
    }

    useEffect(() => {
        const fetchData = async () => {
            // let userAnswers = await getAnswers(user)
            let updatedUser = user;
            // console.log("User Load", userAnswers)
            if (checkInData.length > 0 && !!checkInData[0].weight) {
                updatedUser = {...updatedUser, weight: checkInData[0]?.weight}
            }

            const data = {
                ...updatedUser,
                LowMacro: {
                    ...updatedUser.LowMacro,
                    ...getMacroTypeRatio('LowMacro',updatedUser)
                },
                HighMacro: {
                    ...updatedUser.HighMacro,
                    ...getMacroTypeRatio('HighMacro', updatedUser)
                },
                StandardMacro: {
                    ...updatedUser.StandardMacro,
                    ...getMacroTypeRatio( 'StandardMacro', updatedUser)
                },
            }
            macroForm.setFieldsValue({...data, AverageMacro: getAverageCalories(data)})
        }
        if (!!user?.id && macroModalVisible) {
            fetchData()
        }

    }, [macroModalVisible])

    const submitter = {submitButtonProps: {loading: submitting}}

    return (
        <ModalForm width="80" form={macroForm} visible={macroModalVisible} title={"Change Macros: " + user?.fullName}
                   onFinish={onFinish} onReset={onReset} modalProps={modalProps} submitter={submitter}>
            <Descriptions title={'Macros'} bordered>
                <Descriptions.Item label="Average Macro" span={3}>
                    <Space direction="horizontal">
                        <ProFormDigitWithRules name={['AverageMacro', 'calories']} disabled label="Average Calories"
                                               rules={[({setFieldsValue}) => ({
                                                   async validator({field}: FixMeLater, value) {
                                                       await validatePositive(value)
                                                       await validateWithoutNull(field, value, setFieldsValue)
                                                       return Promise.resolve(value);
                                                   },
                                               }),]}
                                               max={999999999999}
                        />
                        <RatioGauge_Average />
                    </Space>
                </Descriptions.Item>
                <Descriptions.Item label="Low Macros" span={3}>
                    <Space direction="horizontal">
                        <ProFormDigitWithRules label="Protein" onChange={() => setMacroTypeRatio('LowMacro')} name={['LowMacro', 'protein']}/>
                        <ProFormDigitWithRules onChange={() => setMacroTypeRatio('LowMacro')} label="Carb" name={['LowMacro', 'carb']}/>
                        <ProFormDigitWithRules label="Fat" onChange={() => setMacroTypeRatio('LowMacro')} name={['LowMacro', 'fat']}/>
                        <ProFormDigitWithRules
                            onChange={() => {
                                setMacroTypeRatio('LowMacro')
                                macroForm.validateFields([...getMacrosInUse(), ['LowMacro', 'days']])
                            }}
                            rules={[({setFieldsValue, getFieldValue}) => ({
                                async validator({field}: FixMeLater, value) {
                                    await validateLength(value)
                                    await validatePositive(value)
                                    await validateWithoutNull(field, value, setFieldsValue)
                                    // await validateEnableSwitchDays(getFieldValue(['LowMacro', 'inUse']))
                                    await validateSumOfDays(
                                        [
                                            getFieldValue(['LowMacro']),
                                            getFieldValue(['StandardMacro']),
                                            getFieldValue(['HighMacro'])
                                        ],getFieldValue(['LowMacro', 'inUse']) )
                                    await validateEnableSwitchDays(getFieldValue(['LowMacro', 'inUse']))
                                    return Promise.resolve(value);
                                },
                            }),]}
                            max={7} label="Days" name={['LowMacro', 'days']}/>
                        <ProFormDigitWithRules  disabled label="Calories" name={['LowMacro', 'calories']}
                                                rules={[({setFieldsValue}) => ({
                                                    async validator({field}: FixMeLater, value) {
                                                        // await validateLength(value)
                                                        await validatePositive(value)
                                                        await validateWithoutNull(field, value, setFieldsValue)
                                                        return Promise.resolve(value);
                                                    },
                                                }),]}
                                                max={999999999999}
                        />
                        <ProFormText label="Comments" name={['LowMacro', 'notes']} placeholder=""/>
                        <ProFormSwitchWithRules
                            rules={[({getFieldValue}) => ({
                                async validator(_, value) {
                                    await validateSumOfDays_Switch(
                                        [
                                            getFieldValue(['LowMacro']),
                                            getFieldValue(['StandardMacro']),
                                            getFieldValue(['HighMacro'])
                                        ], getFieldValue(['LowMacro', 'inUse']))
                                    // await macroForm.validateFields()
                                    await validateEnableSwitchDays(getFieldValue(['LowMacro', 'inUse']))
                                    return Promise.resolve(value);
                                },
                            }),]}
                            label="Enable" name={['LowMacro', 'inUse']} placeholder="" onChange={() => {
                            macroForm.setFieldsValue({AverageMacro:getAverageCalories(macroForm.getFieldsValue())})
                            macroForm.validateFields([...getMacrosInUse(), ['LowMacro', 'days']])

                        }}/>
                        <RatioGauge_LowMacro />
                    </Space>
                </Descriptions.Item>
                <Descriptions.Item label="Mid Macros" span={3}>
                    <Space direction="horizontal">
                        <ProFormDigitWithRules label="Protein" onChange={() => setMacroTypeRatio('StandardMacro')} name={['StandardMacro', 'protein']}/>
                        <ProFormDigitWithRules label="Carb" onChange={() => setMacroTypeRatio('StandardMacro')} name={['StandardMacro', 'carb']}/>
                        <ProFormDigitWithRules label="Fat" onChange={() => setMacroTypeRatio('StandardMacro')} name={['StandardMacro', 'fat']}/>
                        <ProFormDigitWithRules
                            onChange={() => {
                                setMacroTypeRatio('StandardMacro')
                                macroForm.validateFields([...getMacrosInUse(), ['StandardMacro', 'days']])
                            }}
                            rules={[({setFieldsValue, getFieldValue}) => ({
                                async validator({field}: FixMeLater, value) {
                                    await validateLength(value)
                                    await validatePositive(value)
                                    await validateWithoutNull(field, value, setFieldsValue)
                                    await validateSumOfDays(
                                        [
                                            getFieldValue(['LowMacro']),
                                            getFieldValue(['StandardMacro']),
                                            getFieldValue(['HighMacro'])
                                        ], getFieldValue(['StandardMacro', 'inUse']))
                                    await validateEnableSwitchDays(getFieldValue(['StandardMacro', 'inUse']))
                                    return Promise.resolve(value);
                                },
                            }),]}
                            max={7} label="Days" name={['StandardMacro', 'days']}/>
                        <ProFormDigitWithRules disabled label="Calories" name={['StandardMacro', 'calories']}
                                               rules={[({setFieldsValue}) => ({
                                                   async validator({field}: FixMeLater, value) {
                                                       // await validateLength(value)
                                                       await validatePositive(value)
                                                       await validateWithoutNull(field, value, setFieldsValue)
                                                       return Promise.resolve(value);
                                                   },
                                               }),]}
                                               max={999999999999}
                        />
                        <ProFormText label="Comments" name={['StandardMacro', 'notes']} placeholder=""/>
                        <ProFormSwitchWithRules
                            rules={[({getFieldValue}) => ({
                                async validator(_, value) {
                                    await validateSumOfDays_Switch(
                                        [
                                            getFieldValue(['LowMacro']),
                                            getFieldValue(['StandardMacro']),
                                            getFieldValue(['HighMacro'])
                                        ], getFieldValue(['StandardMacro', 'inUse']))
                                    await validateEnableSwitchDays(getFieldValue(['StandardMacro', 'inUse']))
                                    // await macroForm.validateFields([['LowMacro', 'days'], ['StandardMacro', 'days'], ['HighMacro', 'days']])
                                    return Promise.resolve(value);
                                },
                            }),]}
                            label="Enable" name={['StandardMacro', 'inUse']} placeholder="" onChange={() => {
                            macroForm.setFieldsValue({AverageMacro:getAverageCalories(macroForm.getFieldsValue())})
                            macroForm.validateFields([...getMacrosInUse(), ['StandardMacro', 'days']])
                        }}/>
                        <RatioGauge_Standard />
                    </Space>
                    {/*<MacroRatio_Slider sliders={MacroRatio_sliders('StandardMacro')} ratio={['StandardMacro', 'ratio']} />*/}
                </Descriptions.Item>
                <Descriptions.Item label="High Macros" span={3}>
                    <Space direction="horizontal">
                        <ProFormDigitWithRules label="Protein" onChange={() => setMacroTypeRatio('HighMacro')} name={['HighMacro', 'protein']}/>
                        <ProFormDigitWithRules label="Carb" onChange={() => setMacroTypeRatio('HighMacro')} name={['HighMacro', 'carb']}/>
                        <ProFormDigitWithRules label="Fat" onChange={() => setMacroTypeRatio('HighMacro')} name={['HighMacro', 'fat']}/>
                        <ProFormDigitWithRules
                            onChange={() => {
                                setMacroTypeRatio('HighMacro')
                                macroForm.validateFields([...getMacrosInUse(), ['HighMacro', 'days']])
                            }}
                            rules={[({setFieldsValue, getFieldValue}) => ({
                                async validator({field}: FixMeLater, value) {
                                    await validateLength(value)
                                    await validatePositive(value)
                                    await validateWithoutNull(field, value, setFieldsValue)
                                    await validateSumOfDays(
                                        [
                                            getFieldValue(['LowMacro']),
                                            getFieldValue(['StandardMacro']),
                                            getFieldValue(['HighMacro'])
                                        ], getFieldValue(['HighMacro', 'inUse']))
                                    await validateEnableSwitchDays(getFieldValue(['HighMacro', 'inUse']))
                                    return Promise.resolve(value);
                                },
                            }),]}
                            max={7} label="Days" name={['HighMacro', 'days']}/>
                        <ProFormDigitWithRules disabled label="Calories" name={['HighMacro', 'calories']}
                                               rules={[({setFieldsValue}) => ({
                                                   async validator({field}: FixMeLater, value) {
                                                       // await validateLength(value)
                                                       await validatePositive(value)
                                                       await validateWithoutNull(field, value, setFieldsValue)
                                                       return Promise.resolve(value);
                                                   },
                                               }),]}
                                               max={999999999999}
                        />
                        <ProFormText label="Comments" name={['HighMacro', 'notes']} placeholder=""/>
                        <ProFormSwitchWithRules
                            rules={[({getFieldValue}) => ({
                                async validator(_, value) {
                                    await validateSumOfDays_Switch(
                                        [
                                            getFieldValue(['LowMacro']),
                                            getFieldValue(['StandardMacro']),
                                            getFieldValue(['HighMacro'])
                                        ], getFieldValue(['HighMacro', 'inUse']))
                                    await validateEnableSwitchDays(getFieldValue(['HighMacro', 'inUse']))
                                    return Promise.resolve(value);
                                },
                            }),]}
                            label="Enable" name={['HighMacro', 'inUse']} placeholder="" onChange={() => {
                                macroForm.setFieldsValue({AverageMacro:getAverageCalories(macroForm.getFieldsValue())})
                                macroForm.validateFields([...getMacrosInUse(), ['HighMacro', 'days']])
                            }}
                        />
                        <RatioGauge_High />
                    </Space>
                </Descriptions.Item>

                <Descriptions.Item label="Calculator" span={3}>
                    <Space direction="vertical">
                        <Space direction="horizontal" wrap={true}>
                            <ProFormText label="Weight (kgs)" name="weight" placeholder="" rules={[() => ({
                                async validator(_, value) {
                                    await validateLength(value, 3)
                                    await validatePositive(value)
                                    return Promise.resolve(value);
                                },
                            }),]}/>
                            <ProFormText label="Age" name="age" placeholder="" rules={[() => ({
                                async validator(_, value) {
                                    await validateLength(value, 3)
                                    await validatePositive(value)
                                    return Promise.resolve(value);
                                },
                            }),]}/>
                            <ProFormText label="Height (cms)" name="height" placeholder="" rules={[() => ({
                                async validator(_, value) {
                                    await validateLength(value, 3)
                                    await validatePositive(value)
                                    return Promise.resolve(value);
                                },
                            }),]}/>
                            <ProFormSelect
                                name="sex"
                                label="Sex"
                                valueEnum={{
                                    M: 'M',
                                    F: 'F',
                                }}
                                initialValue="F"
                                fieldProps={{allowClear: false}}
                            />

                            <ProFormSelect
                                name="activityLevel"
                                label="Activity Level"
                                placeholder=""
                                request={async () => [
                                    {label: "Sedentary", value: 1.2},
                                    {label: "Light Activity", value: 1.375},
                                    {label: "Moderate Activity", value: 1.55},
                                    {label: "Very Active", value: 1.725},
                                    {label: "Super Active", value: 1.9}]}
                                initialValue={1.2}
                                width="sm"
                                fieldProps={{allowClear: false}}
                            />
                            <ProFormSelect
                                name="defecitLevel"
                                placeholder=""
                                label="Defecit"
                                request={async () => [
                                    {label: '0%', value: 0},
                                    {label: '5%', value: -0.05},
                                    {label: '10%', value: -0.10},
                                    {label: '15%', value: -0.15},
                                    {label: '20%', value: -0.20},
                                    {label: '25%', value: -0.25},
                                    {label: '30%', value: -0.30},
                                    {label: '5% Surplus', value: 0.05},
                                    {label: '10% Surplus', value: 0.1},
                                    {label: '15% Surplus', value: 0.15},
                                    {label: '20% Surplus', value: 0.20}]}
                                initialValue={0}
                                width="sm"
                                fieldProps={{allowClear: false}}

                            />
                            <Button className={'macro_modal_button_margin'} type="primary"
                                    onClick={calculateMacros}>Calculate</Button>
                        </Space>
                        <Space direction="horizontal">
                            <ProFormDigitWithoutRules label="Protein" onChange={() => setMacroTypeRatio('Calculator')} name={['Calculator', 'protein']} placeholder=""/>
                            <ProFormDigitWithoutRules label="Carb" onChange={() => setMacroTypeRatio('Calculator')} name={['Calculator', 'carb']} placeholder=""/>
                            <ProFormDigitWithoutRules label="Fat" onChange={() => setMacroTypeRatio('Calculator')} name={['Calculator', 'fat']} placeholder=""/>
                            <ProFormDigit label="Calories" disabled name={['Calculator', 'calories']} placeholder=""   max={999999999999}/>
                            <RatioGauge_Calculator />
                        </Space>
                        {/*<MacroRatio_Slider sliders={MacroRatio_sliders('Calculator')} ratio={['Calculator', 'ratio']} />*/}
                    </Space>
                </Descriptions.Item>
                <Descriptions.Item label="Actions">
                    <Space direction="horizontal">
                        <Button key="submit" type="primary" onClick={copyToLow}>Copy To Low</Button>
                        <Button key="submit" type="primary" onClick={copyToMid}>Copy To Mid</Button>
                        <Button key="submit" type="primary" onClick={copyToHigh}>Copy To High</Button>
                    </Space>
                </Descriptions.Item>
            </Descriptions>
        </ModalForm>
    )

}