import { PayloadAction } from '@reduxjs/toolkit';
import { IQuestionaire, IQuestionaire_Answer } from './../../Interfaces/Questionaire/index';
import {createSlice} from '@reduxjs/toolkit'

interface InitialState {
    questionnaireData: IQuestionaire[],
    quizAnswersData: IQuestionaire_Answer[],
};

const initialState = {
    questionnaireData: [],
    quizAnswersData: [],
} as InitialState;

const questionnaireReducer = createSlice({
    name: 'questionnaireReducer',
    initialState,
    reducers: {
        setQuestionnaireData: (state, action: PayloadAction<{questionnaireData: IQuestionaire[]}>) => {
            state.questionnaireData = action.payload.questionnaireData
        },
        setQuizAnswersData: (state, action: PayloadAction<{quizAnswersData: IQuestionaire_Answer[]}>) => {
            state.quizAnswersData = action.payload.quizAnswersData
        },
        questionnaireReducerReset: state => initialState
    }
})

export const {
    setQuestionnaireData,
    setQuizAnswersData,
    questionnaireReducerReset
} = questionnaireReducer.actions

export default questionnaireReducer.reducer


