import React from 'react';
import {Button, Menu, Space, Typography} from "antd";
import {filterButtonData} from "../../vars";
import {DownOutlined} from "@ant-design/icons";
import { useAppSelector } from 'redux/store';
import { Dropdown } from 'components/Antd/DropDown';
import {MenuClickEventHandler} from "rc-menu/lib/interface"
import {F_extra} from "Types"
import { ModalType } from 'services/modals-service';
interface Props {
    isLoading: boolean,
    setExtraFilter: (value: F_extra) => void,
    showFeedback: ModalType,
    selectedUsers: React.Key[],
};

interface IDashboardPageCoachUsersTableHeaderButtonProps {
    onClick: (value: F_extra) => void,
    disabled: boolean,
    text: string,
    currentValue: F_extra | null | undefined,
    value: F_extra,
};

export default function DashboardPageCoachUsersTableHeader({
    isLoading,
    setExtraFilter,
    showFeedback,
    selectedUsers = []
}: Props) {
    const {Title} = Typography;

    const f_extra = useAppSelector(state => state.users.usersTableSettings.f_extra);

    const DashboardPageCoachUsersTableHeaderButton = ({onClick, value, text, currentValue, disabled}: IDashboardPageCoachUsersTableHeaderButtonProps) => (
        <Button
            onClick={() => onClick(value)}
            type={currentValue === value ? 'primary' : undefined}
            disabled={disabled}
        >
            {text}
        </Button>
    )

    const handleMenuClick: MenuClickEventHandler = ({key}) => {
        switch (key){
            case "feedback":
                showFeedback(true);
                break;
            default: {
                break;
            }
        }
    }

    const menu = (
        <Menu onClick={handleMenuClick}>
            <Menu.Item key="feedback">
               Assign feedback
            </Menu.Item>
        </Menu>
    )

    return (
        <Space size="large" className={'dashboard_user_table_header'}>
            <Space direction="vertical" align="center">
                <Title level={5}>Table Filter Options</Title>
                <Space className="dashboard_user_table_header_item" direction="horizontal" wrap={true} align="center">
                    {filterButtonData.map((data, index) => {
                        const props = {
                            onClick: setExtraFilter,
                            currentValue: f_extra,
                            disabled: isLoading,
                            ...data
                        }
                        return <DashboardPageCoachUsersTableHeaderButton key={index} {...props}/>
                    })}
                  <Dropdown placement="bottomRight" overlay={menu} disabled={selectedUsers.length === 0} trigger={['click']}>
                      <Button>
                          Actions <DownOutlined />
                      </Button>
                  </Dropdown>
                </Space>
            </Space>
        </Space>
    )
}