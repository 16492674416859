import React, {useState, useEffect} from 'react';
import {Table, Tag, Space, Button, Form, Input, PageHeader, Menu} from 'antd';
import {EditOutlined,} from '@ant-design/icons';
import axios from 'axios';
import  {
    ModalForm,
    ProFormText,
    ProFormSelect,
    ProFormSwitch,
    ProFormDigit,
    ProFormTextArea
} from '@ant-design/pro-form';
import {getFormatDate, getPopoverTextWithIcon, isOnlySpaces, openNotification} from "utils/helpers";
import withSidebarNavigation from "HOCs/withSidebarNavigation";
import moment from "moment";
import { IMessageTemplate } from 'Interfaces/MessageTemplates';
import { ColumnProps, ColumnsType } from 'antd/es/table';
import useApi from 'hooks/useApi';

//NOTES: Based on a demo table, added a pop-up editor via modal and form.
//I used functions here rather than classes/extend React.Component as this causes issues getting a reference to the form in order to populate it.
//Looking to upgrade table to ProTable (has back-end linkages and search and stuff... but I was having troubles getting it to work)
//Data is populated locally, but shouldn't be much of a hassle to change to using a fetch to get the objects.

function MessageTemplate() {
    const {API} = useApi();
    const {Column} = Table;
    const {Search} = Input;
    const [editing, setEditing] = useState(false)
    const [editingTitle, setEditingTitle] = useState(false)
    const [editItem, setEditItem] = useState<IMessageTemplate | null>(null)
    const [editIndex, setEditIndex] = useState<number | null>(null)
    const [rawData, setRawData] = useState<IMessageTemplate[]>([])
    const [loading, setLoading] = useState(true)
    const [lastUpdated, setLastUpdated] = useState(Date.now())
    const editTitle = "Edit Message Template"
    const createTitle= "Create Message Template"
    const [form] = Form.useForm<IMessageTemplate>();

    const updateTable = () => setLastUpdated(Date.now())


    const boolRender = (value: boolean) => (
            <Tag color={(value === true) ? "green" : "volcano"}>{(value === true) ? "ACTIVE" : "DISABLED"}</Tag>
    )

    const actionsRender: ColumnProps<IMessageTemplate>['render'] = (text, record, index) => (
        <Space size="middle">
            <Button shape="circle" type="primary" icon={<EditOutlined/>}
                    onClick={() => {
                        editRecord(text, record, index)
                    }}/>
        </Space>
    )

    let [data, setData] = useState<IMessageTemplate[]>([])

    const newRecord = () => {
        form.resetFields()
        setEditing(true)
        setEditItem({level: 0} as IMessageTemplate)
        form.setFieldsValue({level: 0})
    }

    const editRecord = (text: any, record: IMessageTemplate, index: number) => {
        setEditingTitle(true)
        setEditing(true)
        console.log("Editing", editing)
        setEditItem(text)
        setEditIndex(index)
        form.setFieldsValue(text)
    }

    const doSubmit = async () => {
        //Do save
        const requestFormData = {
            ...editItem,
            ...form.getFieldsValue(),
        };
        requestFormData.active = !!requestFormData.active
        try {
            const response = await axios.post('/api/message_templates/save', requestFormData)

            if (requestFormData.hasOwnProperty("id")) {
                editIndex && data.splice(editIndex, 1, requestFormData)
                setData(data)
            } else {
                data.push(response.data)
                setData(data)
            }

            doReset()
            updateTable()
            openNotification("Saved", 'success', 'topRight')

            return true;
        } catch (err) {
            openNotification("Failed to save", 'error', 'topRight')
            console.warn(err);
            return false;
        }
    }

    const doReset = () => {
        console.log("Do ResetPassword")
        form.resetFields()
        setEditing(false)
        setEditingTitle(false)
    }

    const toggleModal = () => {

    }

    const loadData = async () => {
        const resp = await API.get<IMessageTemplate[]>("/api/message_templates/list")
        return resp.data
    }

    const search = async (value: string) => {
        //String filter
        if (value !== "") {
            setData(rawData.filter(it => (it.name.includes(value))))
        } else {
            setData(rawData)
        }
    }

    const modalProps = {onCancel: doReset, onOk: doSubmit}

    const searchConfig = {labelWidth: 'auto'}

    const menu = (
        <Menu>
            <Menu.Item key="1">1st item</Menu.Item>
            <Menu.Item key="2">2nd item</Menu.Item>
            <Menu.Item key="3">3rd item</Menu.Item>
        </Menu>
    );

    const extras = (
        <Space size="large" align="end">
            <Button type="primary" onClick={newRecord}>New</Button>
            <Search placeholder="Search" onSearch={search} enterButton/>
        </Space>
    )

    const columns: ColumnsType<IMessageTemplate> = [
        {
            title: "Name",
            dataIndex: "name",
            sorter: (a,b) => a.name.localeCompare(b.name)
        },
        {
            title: "Description",
            dataIndex: "description",
            sorter: (a,b) => ("" + b.description).localeCompare("" + a?.description),
            className: "table-column-title-no-break",
        },
        {
            title: "Level",
            dataIndex: "level",
            sorter: (a,b) => a.level - b.level,
            className: "table-column-title-no-break",
        },
        {
            title: "Frequency",
            dataIndex: "usingFrequency",
            sorter: (a,b) => a.usingFrequency - b.usingFrequency,
            className: "table-column-title-no-break",
        },
        {
            title: "Type",
            dataIndex: "type",
            className: "table-column-title-no-break",
        },
        {
            title: "Created",
            dataIndex: "createdAt",
            sorter: (a,b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime(),
            render: getFormatDate
        },
        {
            title: "Author",
            dataIndex: ["User", "fullName"],
            render: (_, r) => getPopoverTextWithIcon(r.User?.fullName),
            sorter: (a,b) => a.User!?.fullName.localeCompare(b.User!?.fullName as string),
            // sorter: true,
            className: "table-column-title-no-break",
        },
        {
            title: "Active",
            dataIndex: "active",
            render: boolRender,
            className: "table-column-title-no-break",
        },
        {
            title: "Action",
            fixed: "right",
            render: actionsRender,
            className: "table-column-title-no-break",
        },
    ]

    useEffect(() => {
        let cleanupFunction = false;

        const fetchData = async () => {
            try {
                console.log("Loading Data")
                const messagesData = await loadData()
                if (!cleanupFunction) {
                    setLoading(true)
                    setData(messagesData.sort((a,b)=> +new Date(b.createdAt) - +new Date(a.createdAt)));
                    setRawData(messagesData);
                    setLoading(false)
                }
            } catch (e) {
                if(e instanceof Error){
                    console.error(e.message)
                }
            }
        }

        fetchData();

        return () => {cleanupFunction = true};
    }, [lastUpdated]);

    return (
        <Space className={'page_wrapper_style'} direction="vertical">
            <PageHeader className="site-layout-background" title="Message Template"
                        onBack={() => window.history.back()} extra={extras}/>
            <Table
                scroll={{x: 'auto', y:'65vh', scrollToFirstRowOnChange:true}}
                dataSource={data}
                columns={columns}
                pagination={{
                    pageSize: 10
                }}
                loading={loading}
                rowKey={(rec) => rec.id}
            />

            <ModalForm form={form} visible={editing} title={editingTitle?editTitle:createTitle} onFinish={doSubmit} onReset={doReset}
                       modalProps={modalProps} onVisibleChange={toggleModal}>
                <ProFormText name="name" label="Name" placeholder="Name"
                             rules={[{required: true, message: 'Name is required'},
                                 ({getFieldValue}) => ({
                                     validator(_, value) {
                                         if (!value || !isOnlySpaces(getFieldValue('name'))) {
                                             return Promise.resolve();
                                         }
                                         return Promise.reject(new Error('This field is required'));
                                     },
                                 }),]}/>
                <ProFormText name="description" label="Description" placeholder="Description"/>
                <ProFormTextArea name="text" label="Text" placeholder="Enter the message text here"
                                 rules={[{required: true, message: 'Text is required'},
                                     () => ({
                                         validator(_, value) {
                                             if (!value || !isOnlySpaces(value)) {
                                                 return Promise.resolve();
                                             }
                                             return Promise.reject(new Error('This field is required'));
                                         },
                                     }),]}
                />
                <ProFormDigit name="level" label="level" min={0} max={10} placeholder=""/>
                <ProFormSelect
                    name="type"
                    label="Type"
                    valueEnum={{
                        food: 'Food',
                        training: 'Training',
                        stress: 'Stress',
                        sleep: 'Sleep'
                    }}
                    placeholder="Please select a type"
                />
                <ProFormSwitch name="active" label="Active"/>
            </ModalForm>
        </Space>
    )
}

export default withSidebarNavigation(MessageTemplate, "/messageTemplates");