import { IClientType } from './../../Interfaces/ClientType/index';
import {createSlice, PayloadAction} from "@reduxjs/toolkit";

interface InitialState {
    clientTypes: IClientType[],
    filteredTypes: IClientType[],
    currentType: IClientType | null,
    lastUpdate: number | null
}

const initialState = {
    clientTypes: [],
    filteredTypes: [],
    currentType: null,
    lastUpdate: null,
} as InitialState;



const clientTypesReducer = createSlice({
    name: 'ClientTypes',
    initialState,
    reducers: {
        setClientTypes: (state, action: PayloadAction<IClientType[]>) => {
            state.clientTypes = action.payload;
        },
        setUpdateClientTypes: (state, action: PayloadAction<number>) => {
            state.lastUpdate = action.payload
        },
        setCurrentClientType: (state, action: PayloadAction<IClientType | null>) => {
            state.currentType = action.payload;
        },
        setFilteredTypes: (state, action: PayloadAction<IClientType[]>) => {
            state.filteredTypes = action.payload;
        }
    }
})


export default clientTypesReducer.reducer;

export const {setClientTypes, setUpdateClientTypes,setCurrentClientType,setFilteredTypes} =  clientTypesReducer.actions