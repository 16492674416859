import React from 'react';
import DashboardPageCardFirstCol from "./DashboardPageCardFirstCol";
import DashboardPageCardSecondCol from "./DashboardPageCardSecondCol";
import withDashboardCol from "HOCs/withDashboardCol";


const DashboardPageCardsWithTwoCols = () => {

    const DashboardPageCardFirstColWithCol = withDashboardCol(DashboardPageCardFirstCol)
    const DashboardPageCardSecondColWithCol = withDashboardCol(DashboardPageCardSecondCol);

    return (
        <>
            <DashboardPageCardFirstColWithCol />
            <DashboardPageCardSecondColWithCol />
        </>
    )

};



export default DashboardPageCardsWithTwoCols;