import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import { ISMS } from "Interfaces/SMS";

interface InitialState {
    messages: ISMS[],
    lastUpdate: number,
};

const smsReducer = createSlice({
    name: 'sms',
    initialState: {
        messages: [],
        lastUpdate: 0
    } as InitialState,
    reducers: {
        setSMSMessages: (state, {payload}: PayloadAction<ISMS[]>) => {
            state.messages = payload
        },
        setUpdateSMS: (state, {payload}: PayloadAction<number>) => {
            state.lastUpdate = payload
        }
    }
})


export const {setSMSMessages, setUpdateSMS} = smsReducer.actions;
export default smsReducer.reducer;