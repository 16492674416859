import React from 'react';
import { Button, Menu, Popover, Row, Tag } from 'antd';
import { Dropdown } from 'components/Antd/DropDown';
import { SearchOutlined } from '@ant-design/icons';
import { useAppSelector } from 'redux/store';
import { ITask, StatusTask } from 'Interfaces/TasksCategories';
import moment from 'moment';
import { DATE_FORMAT } from 'vars';
import useModals from 'services/modals-service';
import useTasksCategories from 'services/tasksCategories-service';
import antNotificationComponent from 'utils/notification-ant-utils';

const getSameOrBeforeDate = (reminderDate: string | null) => moment(reminderDate).isSameOrBefore(moment());
const getCurrentWeek = (reminderDate: string | null) => moment(reminderDate).week() === moment().week();

const getColorPinTask = (task: ITask) => {
    const isDueDate = getSameOrBeforeDate(task.dueDate);
    const isReminderDate = getCurrentWeek(task.reminderDate);
    if(isReminderDate) return "volcano";
    if(isDueDate) return "red";
    return task.taskCategory.color;
}

const PinnedTasks = () => {
    const tasks = useAppSelector((state) => state.tasks.tasks);
    const pinTasks = tasks.filter((task) => {
        if (task.status !== StatusTask.opened) return false;
        if (task.pin) return true;
        // Add tasks to the pinned, if the reminderDate or dueDate are current or before
        if (getSameOrBeforeDate(task.reminderDate)) return true;
        if (getSameOrBeforeDate(task.dueDate)) return true;
    });
    const { setEditTaskVisible } = useModals();
    const { handleSetCurrentTask, onUpdateUserTask, handleUpdateTask } = useTasksCategories();

    const overlay = (task: ITask) => {
        const handleOpenEditTask = () => {
            handleSetCurrentTask(task);
            setEditTaskVisible(true);
        };

        const handleUnPinUserTask = async () => {
            const response = await onUpdateUserTask({ ...task, pin: false });
            if(response){
                handleUpdateTask(response);
                antNotificationComponent({ type: "success", message: "Saved" });
            };
        };

        const handleMarkAsCompleted = async () => {
            const response = await onUpdateUserTask({ ...task, status: StatusTask.completed, pin: false });
            if(response){
                handleUpdateTask(response);
                antNotificationComponent({ type: "success", message: "Saved" });
            };
        };

        return (
        <Menu>
            <Menu.Item onClick={handleMarkAsCompleted}>
                Mark as Completed
            </Menu.Item>
            <Menu.Item onClick={handleUnPinUserTask}>
                Unpin Task
            </Menu.Item>
            <Menu.Item onClick={handleOpenEditTask}>
                Edit Task
            </Menu.Item>
        </Menu>
        )
    };
    
    const dropdown = (task: ITask) => {
        return (
        <Dropdown overlay={overlay(task)}>
            <Button size="small" type="link"><SearchOutlined style={{ color: "#3B3B3B" }} /></Button>
        </Dropdown>
        )
    };

    return (
        <Row gutter={[8,8]} justify="end">
        {
            pinTasks.length > 0 ?
            pinTasks.map(task =>              
                    <Tag 
                        key={task.id}
                        color={getColorPinTask(task)} 
                        icon={dropdown(task)}
                    >
                        <Popover content={task.name}>
                            {task.dueDate ? `${moment(task.dueDate).format(DATE_FORMAT)}-` : ''}{task.taskCategory.name.length > 20 ? `${task.taskCategory.name.substring(0, 20)}...` : task.taskCategory.name}
                        </Popover>
                    </Tag>
            ) 
            : null
        }
        </Row>
    )
};

export default PinnedTasks;



