import React, {useState} from 'react';
import logo from '../../img/smp-logo.png'
import {Image, Space} from "antd";
import Form from "antd/lib/form";
import Input from "antd/lib/input";
import {UserOutlined} from "@ant-design/icons";
import {useHistory} from "react-router-dom";
import ResetPasswordNotification from "./resetPasswordNotification";
import Button from "antd/lib/button";
// import API from "../../services/api-service";
import {validateRequired, validateEmail} from "../../utils/helpers";
import useApi from 'hooks/useApi';
import { IMessageStatus } from 'Interfaces/Auth';

const ResetPassword = () => {
    const history = useHistory();
    const {API} = useApi();
    const [isValidEmail, setIsValidEmail] = useState(false);
    const [userEmail, setUserEmail] = useState<string | null>(null);
   

    const onSubmitReset = async ({email}: {email: string}) => {
        const response = await API.post<IMessageStatus>('/api/auth/reset-password', {email});
        if(response?.data?.message === 'Success'){
            setUserEmail(email);
            return setIsValidEmail(true);
        }
    };

    if(isValidEmail){
        return <ResetPasswordNotification email={userEmail as string}  />
    }

    return (
        <div className="login__form">
            <Space align={"center"} style={{width: '100%', justifyContent: 'center'}}>
                <Image width={200} src={logo} preview={false}/>
            </Space>

            <div className="container-fluid-sm">
                <h1>Reset password:</h1>
                <Form name="normal_login" className="login-form" initialValues={{email: ''}} onFinish={onSubmitReset}>
                    <Form.Item
                        name="email"
                        validateTrigger={['onBlur']}
                        rules={[() => ({
                            async validator(_,value){
                                await validateRequired(value)
                                await validateEmail(value)
                            }
                        })]
                        }>
                        <Input size="large" prefix={<UserOutlined className="site-form-item-icon"/>}
                               placeholder="Email"/>
                    </Form.Item>
                    <Form.Item>
                        <Button htmlType="submit" className="btn">Reset Password</Button>
                    </Form.Item>
                    <Button className="btn" onClick={() => history.push('/login')}>Back to Login</Button>
                </Form>
            </div>
        </div>
    )


};




export default ResetPassword;