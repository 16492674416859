import React, { FC } from 'react';
import {Image, Space} from "antd";
import logo from "../../img/smp-logo.png";
import Button from "antd/lib/button";
import {useHistory} from "react-router-dom";

interface Props {
    email: string
}

const ResetPasswordNotification: FC<Props> = ({email}) => {
    const history = useHistory();
    return (
        <div className="login__form">
            <Space align={"center"} style={{width: '100%', justifyContent: 'center'}}>
                <Image width={200} src={logo} preview={false}/>
            </Space>
            <div
                style={{
                    textAlign: 'center'
                }}
                className="container__fluid-sm">
                <h1>An email with instructions has been sent to your mail {email}</h1>
                <Button
                    className="btn"
                    onClick={() => history.push('/login')}
                >
                    Back to Login
                </Button>
            </div>
        </div>
    )
};



export default ResetPasswordNotification;